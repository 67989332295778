import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CloseIcon from '_assets/icons/close.svg'
import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

const AlertMessage = ({ className, isOpen, onCloseClick, content, callToAction, url }) => (
    <div className={classnames(styles.container, { [styles.open]: isOpen }, className)}>
      <div className={styles.content}>
        <p className={styles.message}>{content}</p>
        <div className={styles.action}>
          <a className={styles.button} href={url} target="_blank" rel="noopener noreferrer">
            {callToAction}
          </a>
        </div>
        <button
          type="button"
          onClick={onCloseClick}
          onMouseDown={onMouseDown}
          className={styles.close}
        >
          <svg
            className={styles['close-icon']}
            viewBox={CloseIcon.viewBox}
            role="img"
            aria-label="Close icon"
          >
            <use xlinkHref={`#${CloseIcon.id}`} />
          </svg>
        </button>
      </div>
    </div>
  )
AlertMessage.propTypes = {
  className: PropTypes.string,
  isOpen: PropTypes.bool,
  onCloseClick: PropTypes.func,
  content: PropTypes.string,
  callToAction: PropTypes.string,
  url: PropTypes.string,
}

AlertMessage.defaultProps = {
  className: '',
  isOpen: false,
  onCloseClick: () => {},
  content: '',
  callToAction: '',
  url: '',
}

export default AlertMessage
