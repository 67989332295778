import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import useWindowSize from '_hooks/use-window-size'
import Card, { CardSize } from '_components/card'
import DefaultAvatar from '_assets/icons/empty-photo.svg'
import { MOBILE_WIDTH } from '_utils/constants'
import ReadMoreText from '_components/landing-components/read-more-text'

import styles from './styles.css'

export const TestimonialTheme = {
  DEFAULT: 'default',
  CARD: 'card',
}
const Testimonial = ({ className, testimonial, theme }) => {
  const size = useWindowSize()
  const isMobile = useMemo(() => size.width <= MOBILE_WIDTH, [size.width])

  const cardContent = useMemo(
    () => (
      <>
        {theme === TestimonialTheme.CARD || (!isMobile && theme === TestimonialTheme.DEFAULT) ? (
          <p className={styles.testimonial}>{testimonial.testimonial}</p>
        ) : (
          <ReadMoreText maxLines={7} text={testimonial.testimonial} />
        )}
        {testimonial.userAvatar.url ? (
          <img
            src={testimonial.userAvatar.url || DefaultAvatar}
            className={styles.avatar}
            alt={`${testimonial.userName} avatar`}
          />
        ) : (
          <svg
            className={styles.avatar}
            viewBox={DefaultAvatar.viewBox}
            aria-label={`${testimonial.userName} avatar`}
            role="img"
          >
            <use xlinkHref={`#${DefaultAvatar.id}`} />
          </svg>
        )}
        <div className={styles.author}>
          {theme === TestimonialTheme.CARD ? (
            <>
              <p className={classnames(styles.username, styles.text)}>{testimonial.userName}</p>
              <p className={styles.text}>{testimonial.userJob}</p>
            </>
          ) : (
            <p className={styles.text}>
                <span className={styles.username}>- {testimonial.userName}</span>
                {testimonial.userJob ? `, ${testimonial.userJob}` : ''}
              </p>
          )}
        </div>
      </>
    ),
    [
      isMobile,
      testimonial.testimonial,
      testimonial.userAvatar.url,
      testimonial.userJob,
      testimonial.userName,
      theme,
    ]
  )

  if (theme === TestimonialTheme.CARD) {
    return (
      <Card
        size={CardSize.LARGE}
        className={classnames(className, styles['testimonial-card'], styles[theme])}
      >
        {cardContent}
      </Card>
    )
  }

  return (
    <article className={classnames(className, styles['testimonial-card'], styles[theme])}>
      {cardContent}
    </article>
  )
}

Testimonial.propTypes = {
  className: PropTypes.string,
  testimonial: PropTypes.shape({
    testimonial: PropTypes.string,
    userAvatar: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
    userName: PropTypes.string,
    userJob: PropTypes.string,
  }).isRequired,
  theme: PropTypes.string,
}

Testimonial.defaultProps = {
  className: '',
  theme: TestimonialTheme.DEFAULT,
}

export default React.memo(Testimonial)
