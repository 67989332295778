import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { navigate } from '@reach/router'
import { connect } from 'react-redux'
import { Waypoint } from 'react-waypoint'

import { onMouseDown } from '_utils/aria'
import ArrowLeftIcon from '_assets/icons/arrow-left.svg'
import Footer from '_components/footer'
import ParticipantCard from '_components/participant-card'
import { getClassById, isGetParticipantsLoading } from '_modules/class/selectors'
import { getClass, getAllParticipants } from '_modules/class/actions'
import { sendDirectMessage, SEND_DIRECT_MESSAGE } from '_modules/chat/actions'
import Modal from '_components/modal'
import ModalTextarea from '_components/modal-textarea'
import { classShape, participantShape } from '_utils/proptypes'
import { usePrevious } from '_utils/hooks'
import RocketLoader from '_components/rocket-loader'
import { linearGradientBg } from '_utils/constants'

import styles from './styles.css'

const mapStateToProps = (state, { classId }) => ({
  myClass: getClassById(state, classId),
  peopleEnrolled: state.classes.get('peopleEnrolled').toJS(),
  isSendingMessage: !!state.loading.get(SEND_DIRECT_MESSAGE.ACTION),
  isGettingParticipants: isGetParticipantsLoading(state),
})

const mapDispatchToProps = {
  getMyClass: getClass,
  getParticipants: getAllParticipants,
  sendMessage: sendDirectMessage,
}

const Participants = ({
  myClass,
  classId,
  getMyClass,
  sendMessage,
  peopleEnrolled,
  getParticipants,
  isSendingMessage,
  sendMessageError,
  isGettingParticipants,
}) => {
  const [isModalOpen, setModalStatus] = useState(false)
  const [participantInfo, setParticipant] = useState({
    id: null,
    username: null,
    name: null,
    photo: null,
  })

  const classInstructors = useMemo(() => myClass?.instructors?.map(instructor => instructor?.id), [
    myClass,
  ])

  const wasSendingMessage = usePrevious(isSendingMessage)

  const onBackClick = useCallback(
    event => {
      event.preventDefault()
      navigate(`/class/${classId}`)
    },
    [classId]
  )

  const openNewMessageModal = useCallback(participant => {
    setModalStatus(true)
    setParticipant(participant)
  }, [])

  const closeNewMessage = useCallback(() => setModalStatus(false), [])

  const onModalSubmit = useCallback(message => sendMessage({ message, ...participantInfo }), [
    participantInfo,
    sendMessage,
  ])

  const hasClass = useMemo(() => !!myClass.id, [myClass.id])

  const onLoadMoreParticipants = useCallback(
    () => {
      if (peopleEnrolled.next) {
        getParticipants(classId, peopleEnrolled.next)
      }
    },
    [classId, getParticipants, peopleEnrolled.next]
  )

  useEffect(
    () => {
      if (!hasClass) {
        getMyClass(classId)
      }
    },
    [classId, getMyClass, hasClass]
  )

  useEffect(
    () => {
      getParticipants(classId)
      if (typeof window !== 'undefined') {
        window.scrollTo(0, 0)
      }
    },
    [classId, getParticipants]
  )

  useEffect(
    () => {
      if (wasSendingMessage && !isSendingMessage && sendMessageError.size === 0) {
        navigate(`/messages/?directMessage=${participantInfo.username}`)
      }
    },
    [isSendingMessage, participantInfo.username, sendMessageError.size, wasSendingMessage]
  )

  const courseBackground = useMemo(
    () =>
      myClass && myClass.coursePicture
        ? {
            backgroundImage: `${linearGradientBg}, url('${myClass.coursePicture}')`,
          }
        : {},
    [myClass]
  )

  return (
    <>
      <section className={styles.container}>
        <div
          className={classnames(styles.header, { [styles.loading]: isGettingParticipants })}
          style={courseBackground}
        >
          <div className={styles['title-wrapper']}>
            <button
              type="button"
              className={styles['back-button']}
              onClick={onBackClick}
              onMouseDown={onMouseDown}
            >
              <svg
                aria-label="Back icon"
                role="img"
                viewBox={ArrowLeftIcon.viewBox}
                className={styles['back-icon']}
              >
                <use xlinkHref={`#${ArrowLeftIcon.id}`} />
              </svg>
            </button>
            <h3 className={styles.title}>{myClass.courseName}</h3>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles['content-title']}>
            <h1>Class Participants</h1>
            <h5 className={styles.total}>
              {peopleEnrolled.participants.length === 1
                ? '1 PARTICIPANT'
                : `${peopleEnrolled.count} PARTICIPANTS`}
            </h5>
          </div>
          <div className={styles.participants}>
            {peopleEnrolled.participants.map((participant, participantIndex) => (
              <>
                <ParticipantCard
                  key={participant.user.id}
                  participant={participant.user}
                  isLeader={classInstructors?.includes(participant.user.id)}
                  intention={participant.intention}
                  onMessageClick={openNewMessageModal}
                />
                {participantIndex + 1 === peopleEnrolled.participants.length && (
                  <Waypoint key="waypoint" onEnter={onLoadMoreParticipants} />
                )}
              </>
            ))}
          </div>
          {isGettingParticipants && (
            <div className={styles.loader}>
              <RocketLoader />
            </div>
          )}
        </div>
      </section>
      <Footer />
      {isModalOpen && (
        <Modal isOpen onClose={closeNewMessage}>
          <ModalTextarea
            title="SEND A MESSAGE"
            buttonLabel="SEND"
            onSubmit={onModalSubmit}
            onClose={closeNewMessage}
            value=""
            isLoading={isSendingMessage}
          />
        </Modal>
      )}
    </>
  )
}

Participants.propTypes = {
  classId: PropTypes.string,
  myClass: classShape.isRequired,
  getMyClass: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  getParticipants: PropTypes.func.isRequired,
  isSendingMessage: PropTypes.bool,
  peopleEnrolled: PropTypes.arrayOf(participantShape).isRequired,
  sendMessageError: ImmutablePropTypes.map,
  isGettingParticipants: PropTypes.bool,
}

Participants.defaultProps = {
  classId: '',
  isSendingMessage: false,
  sendMessageError: Map(),
  isGettingParticipants: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Participants))
