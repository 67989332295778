import { Map, List } from 'immutable'

import Model from './model'

class User extends Model({
  id: undefined,
  belongsToACompany: undefined,
  completedProfile: undefined,
  completedOnboarding: undefined,
  createdAt: undefined,
  email: undefined,
  location: undefined,
  name: undefined,
  photo: undefined,
  coverPhoto: undefined,
  title: undefined,
  updatedAt: undefined,
  username: undefined,
  authToken: undefined,
  company: undefined,
  suspended: undefined,
  blocking: undefined,
  isBlockedBy: undefined,
  following: undefined,
  online: undefined,
  role: undefined,
  ownGoals: undefined,
  isInstructor: undefined,
  hasZoom: undefined,
  skipSubscription: undefined,
  emailDisableAll: undefined,
  emailMessages: undefined,
  emailCourseAdd: undefined,
  emailSummaryAddedToChannels: undefined,
  emailSummaryMessagesInChannels: undefined,
  emailSummaryPostComments: undefined,
  emailSummaryPostLikes: undefined,
  emailSummaryGoalLikes: undefined,
  emailSummaryNewFollowers: undefined,
  emailSummaryFriendSuggestions: undefined,
  emailSummaryFrequency: undefined,
  subscriptionId: undefined,
  subscriptionPlanPeriod: undefined,
  subscriptionStatus: undefined,
  subscriptionIsCanceling: undefined,
  subscriptionTrialEnd: undefined,
  subscriptionStart: undefined,
  subscriptionEndedAt: undefined,
  subscriptionCurrentPeriodEnd: undefined,
  payments: Map({
    count: undefined,
    next: undefined,
    previous: undefined,
    paymentHistory: List(),
  }),
  hiddenUsers: List(),
  isSocialLogin: undefined,
  membershipPrice: undefined,
  membershipOptions: undefined,
}) {}

export { User as default }
