import React, { useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CloseIcon from '_assets/icons/close.svg'
import Button, { ButtonTheme } from '_components/landing-components/button'
import useScroll from '_hooks/use-window-scroll'
import IconButton from '_components/icon-button'

import styles from './styles.css'

const HighlightMessage = ({ prismic, onClose }) => {
  const [hasScrolled, setHasScrolled] = useState(false)
  const scroll = useScroll()

  const highlightBackgroundStyles = useMemo(
    () => ({
      ...(!prismic.highlightWarning
        ? {
            backgroundColor: prismic.highlightBackground || 'var(--shamrock)',
          }
        : {}),
    }),
    [prismic.highlightBackground, prismic.highlightWarning]
  )

  useEffect(
    () => {
      setHasScrolled(scroll !== 0)
    },
    [scroll]
  )

  return (
    <article
      className={classnames(styles['highlight-message-container'], {
        [styles.fixed]: hasScrolled,
        [styles.warning]: prismic.highlightWarning,
      })}
      style={highlightBackgroundStyles}
    >
      <div className={styles['message-wrapper']}>
        {prismic.highlightTitle &&
          !prismic.highlightWarning && (
            <h1 className={styles['highlight-title']}>{prismic.highlightTitle}</h1>
          )}
        <p className={styles['highlight-message']}>
          {prismic.highlightWarning || prismic.highlightText}
        </p>
        {prismic.highlightLink &&
          !prismic.highlightWarning && (
            <Button
              theme={ButtonTheme.MALIBU}
              className={styles['highlight-link']}
              url={prismic.highlightLink}
            >
              {prismic.highlightButtonText}
            </Button>
          )}
      </div>
      <IconButton
        className={styles['close-button']}
        iconClassName={styles['close-icon']}
        icon={CloseIcon}
        onClick={onClose}
      />
    </article>
  )
}

HighlightMessage.propTypes = {
  prismic: PropTypes.shape({
    highlightTitle: PropTypes.string,
    highlightText: PropTypes.string,
    highlightWarning: PropTypes.string,
    highlightLink: PropTypes.string,
    highlightBackground: PropTypes.string,
    highlightButtonText: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func,
}

HighlightMessage.defaultProps = {
  onClose: () => {},
}

export default React.memo(HighlightMessage)
