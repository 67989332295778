import React, { useCallback, useReducer, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate, Link } from '@reach/router'
import { useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import { RoleType } from '_utils/constants'
import Button, { ButtonTheme } from '_components/button'
import EllipsisIcon from '_assets/icons/ellipsis.svg'
import VideoCallIcon from '_assets/icons/videocall.svg'
import BackIcon from '_assets/icons/arrow-left.svg'
import AddParticipantIcon from '_assets/icons/add_participant.svg'
import { onMouseDown, isKeyboardOrClick } from '_utils/aria'
import { channelShape, directMessageShape } from '_utils/proptypes'
import DefaultAvatar from '_assets/icons/empty-photo.svg'
import ConfirmationModal, { MODAL_TEXT } from '_components/confirmation-modal'
import Modal from '_components/modal'
import { iconSelector } from '_components/sidebar-chat/channel-list'
import { usePrevious } from '_utils/hooks'
import {
  checkIsRemovingFromChannel,
  errorRemovingFromChannel,
  errorCreatingChannelCall,
  errorCreatingDirectMessagelCall,
} from '_modules/chat/selectors'
import Toastr, { ToastrTheme } from '_components/toastr'

import { reducer, ACTIONS, initialState } from './reducer'
import styles from './styles.css'
import ModalNewVideo from './modal-new-video'
import ModalLinkZoom from './modal-link-zoom'

const ChatHeader = ({
  className,
  onBackClick,
  isChannel,
  content,
  onChannelInfoClick,
  onAddParticipantClick,
  isAdmin,
  disableChannel,
  enableChannel,
  leaveChannel,
  updateChannel,
  blockUser,
  hasGoals,
  isLinkingZoom,
  onCreateVideoCall,
  isCreatingVideoCall,
  pathname,
  removeZoomParams,
  hasZoom,
}) => {
  const [
    { isNewVideoModalOpen, isLinkZoomModalOpen, isLeaveModalOpen, hasMoreOptions },
    dispatch,
  ] = useReducer(reducer, { ...initialState, isNewVideoModalOpen: false })

  const isRemovingFromChannel = useSelector(checkIsRemovingFromChannel)
  const wasRemovingFromChannel = usePrevious(isRemovingFromChannel)
  const removingFromChannelError = useSelector(errorRemovingFromChannel)
  const wasLinkingZoom = usePrevious(isLinkingZoom)
  const wasCreatingVideoCall = usePrevious(isCreatingVideoCall)
  const createChannelCallError = useSelector(errorCreatingChannelCall)
  const createDirectMessageCallError = useSelector(errorCreatingDirectMessagelCall)

  const channelIcon = useMemo(() => iconSelector(content.private, content.disabled), [
    content.disabled,
    content.private,
  ])

  const onChannelDetailClick = useCallback(
    event => {
      const { key, id } = event.currentTarget
      if (isKeyboardOrClick(key)) {
        onChannelInfoClick(Number(id))
      }
    },
    [onChannelInfoClick]
  )

  const userRef = useRef(null)

  const setRef = useCallback(ref => {
    userRef.current = ref
  }, [])

  const handleOutsideClick = useCallback(event => {
    event.stopPropagation()
    // ignore clicks on the component itself
    if (userRef.current && userRef.current.contains(event.target)) {
      return
    }
    dispatch({ type: ACTIONS.TOGGLE_MORE_OPTIONS })
  }, [])

  const toggleLeaveModal = useCallback(() => {
    dispatch({ type: ACTIONS.TOGGLE_LEAVE_CHANNEL })
  }, [])

  useEffect(
    () => {
      if (
        wasRemovingFromChannel &&
        !isRemovingFromChannel &&
        (!removingFromChannelError ||
          (removingFromChannelError && removingFromChannelError.size === 0))
      ) {
        toggleLeaveModal()
      }
    },
    [isRemovingFromChannel, removingFromChannelError, toggleLeaveModal, wasRemovingFromChannel]
  )

  const toggleStartVideoChatModal = useCallback(
    () => {
      if (hasZoom) {
        dispatch({ type: ACTIONS.TOGGLE_NEW_VIDEO })
        return
      }

      dispatch({ type: ACTIONS.TOGGLE_LINK_ZOOM })
    },
    [hasZoom]
  )

  const onCloseNewVideoModal = useCallback(
    () => {
      dispatch({ type: ACTIONS.CLOSE_MODAL })
      removeZoomParams()
    },
    [removeZoomParams]
  )

  const toggleMoreOptions = useCallback(
    () => {
      if (hasMoreOptions) {
        document.removeEventListener('click', handleOutsideClick, false)
      }

      dispatch({ type: ACTIONS.TOGGLE_MORE_OPTIONS })
    },
    [handleOutsideClick, hasMoreOptions]
  )

  const onLeaveChannelClick = useCallback(
    () => {
      leaveChannel({ channelId: content.id })
    },
    [content.id, leaveChannel]
  )

  useEffect(
    () => {
      if (hasMoreOptions) {
        document.addEventListener('click', handleOutsideClick, false)
      } else {
        document.removeEventListener('click', handleOutsideClick, false)
      }

      return () => {
        document.removeEventListener('click', handleOutsideClick, false)
      }
    },
    [handleOutsideClick, hasMoreOptions]
  )

  useEffect(
    () => {
      if (wasCreatingVideoCall && !isCreatingVideoCall) {
        if (createChannelCallError || createDirectMessageCallError) {
          toast(
            <Toastr theme={ToastrTheme.ERROR} content="Error while creating a new video call" />
          )
          return
        }

        dispatch({ type: ACTIONS.TOGGLE_NEW_VIDEO })
      }
    },
    [
      createChannelCallError,
      createDirectMessageCallError,
      isCreatingVideoCall,
      isLinkingZoom,
      wasCreatingVideoCall,
      wasLinkingZoom,
    ]
  )

  const onDisableChannelClick = useCallback(
    event => {
      event.preventDefault()
      if (content.disabled) {
        enableChannel(content.id)
        return
      }
      disableChannel(content.id)
    },
    [content.disabled, content.id, disableChannel, enableChannel]
  )

  const onPublicPrivateClick = useCallback(
    event => {
      event.preventDefault()
      updateChannel({
        channelId: content.id,
        private: !content.private,
      })
    },
    [content.id, content.private, updateChannel]
  )

  const renderOptionsChannel = useCallback(
    () => (
      <div ref={setRef} className={styles['main-container']}>
        <div className={styles['options-container']}>
          <button type="button" className={styles.button} onClick={toggleLeaveModal}>
            <h5>LEAVE CHANNEL</h5>
            <p>You can&apos;t undo this action</p>
          </button>
          {isAdmin && (
            <>
              <button type="button" className={styles.button} onClick={onPublicPrivateClick}>
                <h5>
                  <span role="img" aria-label="hidden">
                    👑
                  </span>
                  {`MAKE CHANNEL ${content.private ? 'PUBLIC' : 'PRIVATE'}`}
                </h5>
                <p>{`It will be visible for ${content.private ? 'everyone' : 'members'}`}</p>
              </button>
              <button type="button" className={styles.button} onClick={onDisableChannelClick}>
                <h5>
                  <span role="img" aria-label="hidden">
                    👑
                  </span>
                  {`${content.disabled ? 'DISABLE' : 'ENABLE'} CHANNEL`}
                </h5>
                <p>You can change this later</p>
              </button>
            </>
          )}
        </div>
      </div>
    ),
    [
      content.disabled,
      content.private,
      isAdmin,
      onDisableChannelClick,
      onPublicPrivateClick,
      setRef,
      toggleLeaveModal,
    ]
  )

  const onSeeTheFutureClick = useCallback(
    () => {
      if (content && content.user) {
        navigate(`/user/${content.user.username}`)
      }
    },
    [content]
  )

  const onBlockUserClick = useCallback(
    () => {
      if (content) {
        blockUser({
          isDirectMessage: true,
          username: content.user.username,
          userId: content.user.id,
        })
      }
    },
    [blockUser, content]
  )

  const renderOptionsDM = useCallback(
    () => (
      <div className={styles['main-container']}>
        <div className={styles['options-container']}>
          <Link to={`/user/${content.user.username}`} className={styles.button}>
            <h5>SEE FUTURE</h5>
            <p>See their posts, goals and more</p>
          </Link>
          <button type="button" className={styles.button} onClick={onBlockUserClick}>
            <h5>Block this user</h5>
            <p>They won&apos;t see your content</p>
          </button>
        </div>
      </div>
    ),
    [content, onBlockUserClick]
  )

  const renderChannelInfo = useCallback(
    () => {
      const members = content.membersPreview.slice(0, 3)
      const restMembers = content.numberOfMembers - members.length

      return (
        <div className={styles['channel-info']}>
            <div className={styles['channel-name']}>
              <svg
                className={styles.icon}
                viewBox={channelIcon.viewBox}
                role="img"
                aria-label={`${content.private ? 'Private' : 'Public'} channel`}
              >
                <use xlinkHref={`#${channelIcon.id}`} />
              </svg>
              <h4>{content.name}</h4>
            </div>
            <div
              className={styles.members}
              role="button"
              tabIndex="0"
              onClick={onChannelDetailClick}
              onKeyPress={onChannelDetailClick}
              onMouseDown={onMouseDown}
              id={content.id}
            >
              <div className={styles['avatar-list']}>
                {members.map(
                  member =>
                    member.photo ? (
                      <img
                        key={member.id}
                        src={member.photo}
                        alt={`${member.name} avatar`}
                        className={styles['member-avatar']}
                      />
                    ) : (
                      <svg
                        aria-label={`${member.name} avatar`}
                        role="img"
                        viewBox={DefaultAvatar.viewBox}
                        className={styles['member-avatar']}
                        key={member.id}
                      >
                        <use xlinkHref={`#${DefaultAvatar.id}`} />
                      </svg>
                    )
                )}
              </div>
              <p className={styles['members-list']}>
                {members.map((member, index) => {
                  if (index !== members.length - 1) {
                    return `${member.name}, `
                  }

                  if (content.numberOfMembers <= 3) {
                    return member.name
                  }

                  return `${member.name} and more ${restMembers} ${
                    restMembers === 1 ? 'member' : 'members'
                  }`
                })}
              </p>
              <p className={styles['total-members']}>
                {content.numberOfMembers === 1 ? '1 member' : `${content.numberOfMembers} members`}
              </p>
            </div>
          </div>
      )
    },
    [channelIcon.id, channelIcon.viewBox, content, onChannelDetailClick]
  )

  const renderDirectMessageActions = useCallback(
    () => (
      <>
        {/* TODO: Uncomment this when zoom is working again */}
        <Button
          theme={ButtonTheme.PRIMARY}
          label="START VIDEO CHAT"
          icon={VideoCallIcon}
          onClick={toggleStartVideoChatModal}
        />
        <Button
          theme={ButtonTheme.PRIMARY}
          className={styles['lg-only']}
          label="SEE FUTURE"
          onClick={onSeeTheFutureClick}
        />
        <Button
          theme={ButtonTheme.DEFAULT}
          className={styles['lg-only']}
          label="BLOCK USER"
          onClick={onBlockUserClick}
        />
        <Button
          theme={ButtonTheme.DEFAULT}
          icon={EllipsisIcon}
          iconOnly
          label="More Options"
          onClick={toggleMoreOptions}
          className={styles.dropdown}
        />
        {hasMoreOptions && renderOptionsDM()}
      </>
    ),
    [
      hasMoreOptions,
      onBlockUserClick,
      onSeeTheFutureClick,
      renderOptionsDM,
      toggleMoreOptions,
      toggleStartVideoChatModal,
    ]
  )

  const renderDirectMessageInfo = useCallback(
    () => (
      <>
        <ProfileAvatar
          className={styles.avatar}
          username={content.user.username}
          src={content.user.photo}
          roleType={RoleType[content.user.role]}
          size={AvatarSize.MEDIUM}
          isResponsive
        />
        <div className={styles.profile}>
          <h4>{content.user.name}</h4>
          <p className={styles.username}>@{content.user.username}</p>
          <p>{content.user.online ? 'online' : 'offline'}</p>
        </div>
      </>
    ),
    [content]
  )

  const renderChannelActions = useCallback(
    () => (
      <>
        {/* DESKTOP ACTIONS */}
        {/* TODO: Uncomment this when zoom is working again */}
        <Button
          theme={ButtonTheme.PRIMARY}
          label="CREATE VIDEO CALL"
          onClick={toggleStartVideoChatModal}
          className={styles['lg-only']}
          disabled={content.disabled}
        />
        {isAdmin && (
          <Button
            theme={ButtonTheme.PRIMARY}
            label="ADD PARTICIPANT"
            className={styles['lg-only']}
            onClick={onAddParticipantClick}
            disabled={content.disabled}
          />
        )}
        {isAdmin ? (
          <Button
            theme={ButtonTheme.DEFAULT}
            label="Options"
            icon={EllipsisIcon}
            iconOnly
            className={styles['lg-only']}
            onClick={toggleMoreOptions}
          />
        ) : (
          <Button
            theme={ButtonTheme.DEFAULT}
            label="LEAVE CHANNEL"
            className={styles['lg-only']}
            onClick={toggleLeaveModal}
          />
        )}
        {/* MOBILE/TABLET ACTIONS */}
        {/* TODO: Uncomment this when zoom is working again */}
        <Button
          theme={ButtonTheme.PRIMARY}
          label="CREATE VIDEO CALL"
          className={styles['lg-hidden']}
          icon={VideoCallIcon}
          iconOnly
          onClick={toggleStartVideoChatModal}
          disabled={content.disabled}
        />
        {isAdmin && (
          <Button
            theme={ButtonTheme.PRIMARY}
            label="ADD PARTICIPANT"
            className={styles['lg-hidden']}
            icon={AddParticipantIcon}
            iconOnly
            onClick={onAddParticipantClick}
            disabled={content.disabled}
          />
        )}
        {isAdmin ? (
          <Button
            theme={ButtonTheme.DEFAULT}
            label="Options"
            className={styles['lg-hidden']}
            icon={EllipsisIcon}
            iconOnly
            onClick={toggleMoreOptions}
          />
        ) : (
          <Button
            theme={ButtonTheme.DEFAULT}
            label="LEAVE CHANNEL"
            className={styles['lg-hidden']}
            icon={EllipsisIcon}
            iconOnly
            onClick={toggleMoreOptions}
          />
        )}
        {hasMoreOptions && renderOptionsChannel()}
      </>
    ),
    [
      content.disabled,
      hasMoreOptions,
      isAdmin,
      onAddParticipantClick,
      renderOptionsChannel,
      toggleLeaveModal,
      toggleMoreOptions,
      toggleStartVideoChatModal,
    ]
  )

  const onStartVideoCall = useCallback(payload => onCreateVideoCall(payload), [onCreateVideoCall])

  return (
    <>
      <div
        className={classnames(styles.container, className, {
          [styles.channel]: isChannel,
          [styles['has-goals']]: hasGoals,
        })}
      >
        <button
          type="button"
          onClick={onBackClick}
          onMouseDown={onMouseDown}
          className={styles['back-action']}
        >
          <svg aria-label="Back icon" className={styles.back} viewBox={BackIcon.viewBox} role="img">
            <use xlinkHref={`#${BackIcon.id}`} />
          </svg>
        </button>
        {isChannel ? renderChannelInfo() : renderDirectMessageInfo()}
        <div className={styles.actions}>
          {isChannel ? renderChannelActions() : renderDirectMessageActions()}
        </div>
      </div>
      {isLeaveModalOpen && (
        <Modal isOpen onClose={toggleLeaveModal}>
          <ConfirmationModal
            onClose={toggleLeaveModal}
            text={MODAL_TEXT.LEAVE_CHANNEL}
            onActionClick={onLeaveChannelClick}
          />
        </Modal>
      )}
      {isNewVideoModalOpen && (
        <ModalNewVideo
          isOpen
          onCloseButton={onCloseNewVideoModal}
          onButtonClick={onStartVideoCall}
          isLoading={isCreatingVideoCall}
        />
      )}
      {isLinkZoomModalOpen && (
        <ModalLinkZoom isOpen onCloseButton={toggleStartVideoChatModal} pathname={pathname} />
      )}
    </>
  )
}

ChatHeader.propTypes = {
  className: PropTypes.string,
  onBackClick: PropTypes.func,
  isChannel: PropTypes.bool,
  isAdmin: PropTypes.bool,
  content: PropTypes.oneOfType([channelShape, directMessageShape]).isRequired,
  onChannelInfoClick: PropTypes.func,
  onAddParticipantClick: PropTypes.func,
  disableChannel: PropTypes.func,
  enableChannel: PropTypes.func,
  leaveChannel: PropTypes.func,
  updateChannel: PropTypes.func,
  blockUser: PropTypes.func,
  hasGoals: PropTypes.bool,
  onCreateVideoCall: PropTypes.func,
  isLinkingZoom: PropTypes.bool,
  isCreatingVideoCall: PropTypes.bool,
  pathname: PropTypes.string.isRequired,
  removeZoomParams: PropTypes.func,
  hasZoom: PropTypes.bool,
}

ChatHeader.defaultProps = {
  className: '',
  onBackClick: () => {},
  onChannelInfoClick: () => {},
  isChannel: false,
  isAdmin: false,
  onAddParticipantClick: () => {},
  disableChannel: () => {},
  enableChannel: () => {},
  leaveChannel: () => {},
  updateChannel: () => {},
  blockUser: () => {},
  hasGoals: false,
  onCreateVideoCall: () => {},
  isLinkingZoom: false,
  isCreatingVideoCall: false,
  removeZoomParams: () => {},
  hasZoom: false,
}

export default React.memo(ChatHeader)
