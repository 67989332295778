import React, { useState, useRef, useEffect, useCallback, useMemo, Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import Linkify from 'react-linkify'
import { Link } from '@reach/router'

import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import TrashIcon from '_assets/icons/trash-16-px.svg'
import { onMouseDown, isKeyboardOrClick } from '_utils/aria'
import { MEDIUM_WIDTH, tagsRegex } from '_utils/constants'
import Modal from '_components/modal'
import ConfirmationModal, { MODAL_TEXT } from '_components/confirmation-modal'
import { messageShape } from '_utils/proptypes'

import ZoomMessage from '../zoom-message'
import FileMessage from '../file-message'

import styles from './styles.css'

export const ChannelMessageTheme = {
  USER_MESSAGE: 'user-message',
  OWN_MESSAGE: 'own-message',
}

const ChannelMessage = ({
  message,
  theme,
  className,
  withAvatar,
  isChannelAdmin,
  onDeleteClick,
  endMeeting,
  id,
  isLoadingEndMeeting,
}) => {
  const [isSelected, setSelected] = useState(false)

  const messageRef = useRef(null)

  const toggleSelected = useCallback(
    event => {
      const { key } = event
      if (window.innerWidth <= MEDIUM_WIDTH && isKeyboardOrClick(key)) {
        setSelected(!isSelected)
      }
    },
    [isSelected]
  )

  const [isModalOpen, setModalOpen] = useState(false)
  const toggleModal = useCallback(() => setModalOpen(!isModalOpen), [isModalOpen])

  const handleOutsideClick = useCallback(
    e => {
      // ignore clicks on the component itself
      if (messageRef.current.contains(e.target)) {
        toggleSelected(e)
      }
      setSelected(false)
    },
    [toggleSelected]
  )

  const isPhoto = useMemo(
    () =>
      message.filename
        ? ['jpg', 'jpeg', 'png', 'tif', 'tiff', 'gif'].includes(message.filename.split('.').pop())
        : false,
    [message.filename]
  )

  const isZoomURL = useMemo(() => message.meeting && !!message.meeting.meetingId, [message.meeting])

  const renderMessageWithTags = useCallback(mes => {
    const splittedComment = mes.split(' ')

    return (
      <p className={styles.text}>
        {splittedComment.map((word, index) => {
          if (tagsRegex.test(word) && word.indexOf('@') === 0) {
            return (
              <Fragment key={`${word}-${index + 1}`}>
                {' '}
                <Link to={`/user/${word.split('@')[1]}`} className={styles.tag}>
                  {`${word}`}
                </Link>
              </Fragment>
            )
          }
          return ` ${word}`
        })}
      </p>
    )
  }, [])

  const renderMessage = useMemo(
    () => {
      if (isZoomURL) {
        return (
          <ZoomMessage
            meeting={message.meeting}
            theme={theme}
            endMeeting={endMeeting}
            loading={isLoadingEndMeeting}
          />
        )
      }

      if (isPhoto) {
        return (
          <a href={message.file} target="_blank" rel="noopener noreferrer">
            <img
              src={message.file}
              alt={`Uploaded media by ${message.author.username}`}
              className={styles.picture}
            />
          </a>
        )
      }

      if (message.message) {
        return renderMessageWithTags(message.message)
      }

      return <FileMessage fileName={message.filename} file={message.file} theme={theme} />
    },
    [
      endMeeting,
      isLoadingEndMeeting,
      isPhoto,
      isZoomURL,
      message.author.username,
      message.file,
      message.filename,
      message.meeting,
      message.message,
      renderMessageWithTags,
      theme,
    ]
  )

  const onDeleteMessageClick = useCallback(() => onDeleteClick({ messageId: message.id }), [
    message.id,
    onDeleteClick,
  ])

  useEffect(
    () => {
      if (isSelected) {
        document.addEventListener('click', handleOutsideClick, false)
      }

      return () => {
        document.removeEventListener('click', handleOutsideClick, false)
      }
    },
    [handleOutsideClick, isSelected]
  )

  const linkColor = theme === ChannelMessageTheme.USER_MESSAGE ? '#7387ff' : 'white'

  return (
    <div
        role="button"
        onClick={toggleSelected}
        onKeyPress={toggleSelected}
        tabIndex="0"
        onMouseDown={onMouseDown}
        className={classnames(styles.container, styles[theme], className)}
        ref={messageRef}
        id={id}
      >
        {withAvatar && (
          <>
            <ProfileAvatar
              username={message.author.username}
              src={message.author.photo}
              size={AvatarSize.SMALL}
              className={styles.avatar}
            />
            <div className={styles['user-details']}>
              <p className={styles.name}>{message.author.name}</p>
              <p className={styles.username}>@{message.author.username}</p>
            </div>
          </>
        )}
        <div
          className={classnames(styles.message, {
            [styles.media]: isPhoto,
            [styles.admin]: isChannelAdmin,
            [styles['user-message-sizing']]: theme === ChannelMessageTheme.USER_MESSAGE,
          })}
        >
          <Linkify
            properties={{
              style: {
                color: linkColor,
              },
              target: '_blank',
            }}
          >
            {renderMessage}
          </Linkify>
        </div>
        <p className={styles.date}>{moment(message.createdAt).format('MMMM Do YYYY, LTS')}</p>
        {isChannelAdmin && (
          <button
            className={classnames(styles.delete, {
              [styles['user-message-delete']]:
                theme === ChannelMessageTheme.USER_MESSAGE && isChannelAdmin,
              [styles['own-message-delete']]:
                theme === ChannelMessageTheme.OWN_MESSAGE && isChannelAdmin,
              [styles.selected]: isSelected && isChannelAdmin,
            })}
            onMouseDown={onMouseDown}
            type="button"
            onClick={toggleModal}
          >
            <svg viewBox={TrashIcon.viewBox} role="img" aria-label="Trash icon">
              <use xlinkHref={`#${TrashIcon.id}`} />
            </svg>
          </button>
        )}
        {isModalOpen && (
          <Modal isOpen onClose={toggleModal}>
            <ConfirmationModal
              onClose={toggleModal}
              text={MODAL_TEXT.DELETE_MESSAGE}
              onActionClick={onDeleteMessageClick}
            />
          </Modal>
        )}
      </div>
  )
}

ChannelMessage.propTypes = {
  message: messageShape.isRequired,
  theme: PropTypes.oneOf(Object.keys(ChannelMessageTheme).map(theme => ChannelMessageTheme[theme])),
  className: PropTypes.string,
  withAvatar: PropTypes.bool.isRequired,
  isChannelAdmin: PropTypes.bool,
  onDeleteClick: PropTypes.func,
  endMeeting: PropTypes.func,
  id: PropTypes.number.isRequired,
  isLoadingEndMeeting: PropTypes.bool,
}

ChannelMessage.defaultProps = {
  theme: ChannelMessageTheme.USER_MESSAGE,
  className: '',
  isChannelAdmin: false,
  onDeleteClick: () => {},
  endMeeting: () => {},
  isLoadingEndMeeting: false,
}

export default React.memo(ChannelMessage)
