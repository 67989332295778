import React, { useCallback, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWindowSize } from '@reach/window-size'

import Modal from '_components/modal'
import { MOBILE_THRESHOLD } from '_config/media-queries'

import TooltipContent from './tooltip-content'
import styles from './styles.css'

export const TOOLTIP_POSITION = {
  BOTTOM: 'bottom',
  RIGHT: 'right',
}
const DisabledTooltipButton = ({ className, children, tooltipPosition, cardInfo }) => {
  const { width } = useWindowSize()
  const tooltipRef = useRef(null)
  const modalRef = useRef(null)
  const isMobileView = width <= MOBILE_THRESHOLD

  const [isTooltipOpen, setTooltipOpen] = useState(false)

  const onCloseTooltip = useCallback(() => {
    setTooltipOpen(false)
  }, [])

  const handleClick = event => {
    if (
      (isMobileView && modalRef.current && !modalRef.current.contains(event.target)) ||
      (!isMobileView && tooltipRef.current && !tooltipRef.current.contains(event.target))
    ) {
      onCloseTooltip()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClick)

    return () => {
      document.removeEventListener('click', handleClick)
    }
  })

  const onButtonClick = useCallback(() => {
    setTooltipOpen(true)
  }, [])

  return (
    <div className={styles.container} ref={tooltipRef}>
      <button onClick={onButtonClick} className={classnames(styles.button, className)}>
        {children}
      </button>
      {isTooltipOpen &&
        (isMobileView ? (
          <Modal isOpen onClose={onCloseTooltip}>
            <TooltipContent cardInfo={cardInfo} ref={modalRef} onCloseClick={onCloseTooltip} />
          </Modal>
        ) : (
          <article className={classnames(styles.tooltip, styles[tooltipPosition])}>
            <TooltipContent cardInfo={cardInfo} onCloseClick={onCloseTooltip} />
          </article>
        ))}
    </div>
  )
}

DisabledTooltipButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  cardInfo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.shape({
      viewBox: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  tooltipPosition: PropTypes.oneOf(Object.values(TOOLTIP_POSITION).map(item => item)),
}

DisabledTooltipButton.defaultProps = {
  className: '',

  tooltipPosition: TOOLTIP_POSITION.RIGHT,
}
export default React.memo(DisabledTooltipButton)
