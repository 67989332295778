import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import Shiitake from 'shiitake'
import Linkify from 'react-linkify'

import styles from './styles.css'

const MAX_CHAR = 280

const ProfileInformationCard = ({ className, title, content, myProfile, onEditClick, type }) => {
  const [showAllContent, setShowAllContent] = useState(content && content.length > MAX_CHAR)

  const edit = useCallback(
    () => {
      onEditClick(type)
    },
    [onEditClick, type]
  )

  const showAll = useCallback(() => {
    setShowAllContent(false)
  }, [])

  const hideAll = useCallback(() => {
    setShowAllContent(true)
  }, [])

  const renderContent = () =>
    showAllContent ? (
      <p className={styles.content}>
        <Shiitake lines={4} throttleRate={200} className={className}>
          {content}
        </Shiitake>
        <button type="button" className={styles.more} onClick={showAll}>
          More
        </button>
      </p>
    ) : (
      <p className={styles.content}>
        {content}
        {content.length > MAX_CHAR && (
          <button type="button" className={styles.more} onClick={hideAll}>
            Less
          </button>
        )}
      </p>
    )

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.header}>
        <p className={styles.title}>{title}</p>
        {myProfile && (
          <button type="button" className={styles.edit} onClick={edit}>
            EDIT
          </button>
        )}
      </div>
      {content && content.length ? (
        <Linkify
          properties={{
            style: {
              color: '#7387ff',
              wordBreak: 'break-word',
            },
            target: '_blank',
          }}
        >
          {renderContent()}
        </Linkify>
      ) : (
        <div className={styles['empty-state']}>
          {myProfile ? (
            <>
              <p>You have not entered any text here.</p>
              <p>Why not do it now?</p>
            </>
          ) : (
            <p>No statement yet.</p>
          )}
        </div>
      )}
    </div>
  )
}

ProfileInformationCard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  content: PropTypes.string,
  myProfile: PropTypes.bool,
  onEditClick: PropTypes.func,
  type: PropTypes.string,
}

ProfileInformationCard.defaultProps = {
  className: '',
  type: '',
  myProfile: false,
  onEditClick: () => {},
  title: 'Legacy Statement',
  content: null,
}

export default ProfileInformationCard
