import { Map, List, fromJS } from 'immutable'
import { normalize } from 'normalizr'
import humps from 'humps'
import queryString from 'query-string'

import { createReducer } from '_utils/redux'
import { GET_PUBLIC_COACHES } from '_modules/public-coaches/actions'
import { publicCoachSchema } from '_models/public-coach'

const INITIAL_STATE = Map({
  coaches: Map(),
  search: Map({
    next: undefined,
    previous: undefined,
    results: List(),
  }),
})

export default createReducer(INITIAL_STATE, {
  [GET_PUBLIC_COACHES.FULFILLED]: (state, { payload }) => {
    const publicCoachesIdsList = payload.results?.map(publicCoach => publicCoach.id)
    const normalizedPublicCoaches = normalize(payload.results, publicCoachSchema)?.entities?.results

    const nextUrl = payload.next ? new URL(payload.next) : {}
    const nextPage = fromJS(
      humps.camelizeKeys(queryString.parse(nextUrl.search, { arrayFormat: 'bracket' }))
    )

    if (payload.previous) {
      return state
        .mergeIn(['coaches'], normalizedPublicCoaches)
        .setIn(['search', 'next'], nextPage)
        .mergeIn(['search', 'results'], publicCoachesIdsList)
    }

    return state
      .set('coaches', Map(normalizedPublicCoaches))
      .setIn(['search', 'next'], nextPage)
      .setIn(['search', 'results'], publicCoachesIdsList)
  },
})
