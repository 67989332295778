/* eslint-disable import/no-unresolved */
/* eslint-disable import/no-extraneous-dependencies */
import React, { useCallback, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { positionMatchWidth } from '@reach/popover'
import { Menu, MenuButton, MenuItems, MenuItem, MenuPopover } from '@reach/menu-button'
import { Link } from '@reach/router'
import { List } from 'immutable'
import { Waypoint } from 'react-waypoint'

import ArrowIcon from '_assets/icons/angle-down.svg'
import Svg from '_components/svg'
import Spinner from '_components/spinner'
import { iconPropTypes } from '_utils/proptypes'

import styles from './styles.css'

export const DROPDOWN_THEME = {
  CHIP: 'chip',
  DEFAULT: 'default',
  BORDERLESS: 'borderless',
  SELECT: 'select',
  // TODO: Add other themes
}

// Mock function just to fill MenuItem required prop
const ON_SELECT = () => {}

const Dropdown = ({
  label,
  icon,
  dropdownOptions,
  theme,
  className,
  onSelect,
  onEnter,
  isPaginatedList,
  isGettingOptions,
  shouldMatchWidth,
}) => {
  const optionsSize = dropdownOptions.length || dropdownOptions.size

  const onSelectValue = useCallback(
    value => () => {
      onSelect(value)
    },
    [onSelect]
  )

  const renderItemImage = useCallback(item => {
    if (item.icon) {
      return <Svg icon={item.icon} className={styles['item-icon']} />
    }

    if (item.picture) {
      return <img src={item.picture} className={styles['item-image']} alt={item.label} />
    }

    return null
  }, [])

  const menuItemContent = useCallback(
    item => (
      <>
        {renderItemImage(item)}
        {item.label}
      </>
    ),
    [renderItemImage]
  )

  const renderMenuItem = useCallback(
    item => {
      if (item.href) {
        return (
          <MenuItem
            key={`${item.label}-${item.value}`}
            value={item.value}
            className={styles['menu-item']}
            as="a"
            href={item.href}
            target="_blank"
            rel="noopener noreferrer"
            onSelect={ON_SELECT}
          >
            {menuItemContent(item)}
          </MenuItem>
        )
      }

      if (item.to) {
        return (
          <MenuItem
            key={`${item.label}-${item.value}`}
            value={item.value}
            className={styles['menu-item']}
            as={Link}
            to={item.to}
            onSelect={ON_SELECT}
          >
            {menuItemContent(item)}
          </MenuItem>
        )
      }

      if (item.onClick) {
        return (
          <MenuItem
            as="button"
            key={`${item.label}-${item.value}`}
            value={item.value}
            className={styles['menu-item']}
            onClick={item.onClick}
            onSelect={ON_SELECT}
          >
            {menuItemContent(item)}
          </MenuItem>
        )
      }

      return (
        <MenuItem
          key={`${item.label}-${item.value}`}
          value={item.value}
          className={styles['menu-item']}
          onSelect={onSelectValue(item.value)}
        >
          {menuItemContent(item)}
        </MenuItem>
      )
    },
    [menuItemContent, onSelectValue]
  )

  const popoverProps = useMemo(() => (shouldMatchWidth ? { position: positionMatchWidth } : {}), [
    shouldMatchWidth,
  ])
  return (
    <Menu className={className}>
      {({ isExpanded }) => (
        <>
          <MenuButton
            className={classnames(styles.button, styles[theme], {
              [styles['button-menu-open']]: isExpanded,
              [styles['button-with-icon']]: icon,
            })}
          >
            {icon && <Svg icon={icon} className={styles.icon} />}
            {label}
            <Svg
              icon={ArrowIcon}
              className={classnames(styles.icon, { [styles['flipped-icon']]: isExpanded })}
            />
          </MenuButton>
          <MenuPopover {...popoverProps}>
            <MenuItems className={styles['menu-list']}>
              {dropdownOptions.map(
                (item, index) =>
                  isPaginatedList && index + 1 === optionsSize ? (
                    <Waypoint key={item.label} onEnter={onEnter}>
                      {renderMenuItem(item)}
                    </Waypoint>
                  ) : (
                    renderMenuItem(item)
                  )
              )}
              {isGettingOptions && (
                <div className={styles.spinner}>
                  <Spinner color="#7387ff" />
                </div>
              )}
            </MenuItems>
          </MenuPopover>
        </>
      )}
    </Menu>
  )
}

Dropdown.propTypes = {
  label: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  icon: iconPropTypes,
  dropdownOptions: PropTypes.oneOfType([
    PropTypes.instanceOf(List),
    PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        icon: iconPropTypes,
        onClick: PropTypes.func,
        to: PropTypes.string,
        href: PropTypes.string,
      })
    ),
  ]).isRequired,
  theme: PropTypes.oneOf(Object.values(DROPDOWN_THEME)),
  className: PropTypes.string,
  onSelect: PropTypes.func,
  isPaginatedList: PropTypes.bool,
  onEnter: PropTypes.func,
  isGettingOptions: PropTypes.bool,
  shouldMatchWidth: PropTypes.bool,
}

Dropdown.defaultProps = {
  icon: '',
  theme: DROPDOWN_THEME.CHIP,
  className: '',
  onSelect: () => {},
  isPaginatedList: false,
  onEnter: () => {},
  isGettingOptions: false,
  shouldMatchWidth: true,
}

export default React.memo(Dropdown)
