import { createSelector } from 'reselect'
import { Map } from 'immutable'

import { Course } from '_models/'

import { GET_COURSE, GET_COMPANY_COURSES } from './actions'

const getCourses = state => state.courses
const getParam = (state, param) => param

const transformToJS = (acc, curr) => {
  acc.push(curr.toJS())
  return acc
}

const checkCourseWithSameSlug = slug => course => course.slug === slug

export const getCourseBySlug = createSelector([getCourses, getParam], (courses, courseSlug) => {
  const course =
    courses.getIn(['discoverCourses', 'courses']).find(checkCourseWithSameSlug(courseSlug)) ||
    courses.getIn(['companyCourses', 'courses']).find(checkCourseWithSameSlug(courseSlug))
  return course ? course.toJS() : new Course().toJS()
})

export const getFormattedDiscoverCourses = createSelector([getCourses], courses => {
  const discoverCourses = courses.get('discoverCourses')
  return {
    next: discoverCourses.get('next'),
    previous: discoverCourses.get('previous'),
    count: discoverCourses.get('count'),
    courses: discoverCourses.get('courses').reduce(transformToJS, []),
  }
})

export const getOnboardingCourses = createSelector([getCourses, getParam], (courses, isCompany) =>
  courses
    .getIn([isCompany ? 'companyCourses' : 'discoverCourses', 'courses'])
    .reduce(transformToJS, [])
)

export const getFormattedCompanyCourses = createSelector([getCourses], courses =>
  courses.getIn(['companyCourses', 'courses']).reduce(transformToJS, [])
)

export const getCourseClasses = createSelector([getCourses], courses => {
  const discoverCourses = courses.get('discoverCourses')
  return discoverCourses
    .get('courses')
    .reduce(transformToJS, [])?.[0]
    ?.classes?.filter(item => item.enrolled)
})

export const isGettingCoursesSelector = state => !!state.loading.get(GET_COURSE.ACTION)

export const getCoursesErrorSelector = state => state.error.get(GET_COURSE.ACTION, Map())

export const isGettingCompanyCoursesSelector = state =>
  !!state.loading.get(GET_COMPANY_COURSES.ACTION)

export const companyClassSelector = (state, id) =>
  state.courses.getIn(['companyCourses', 'courses', Number(id)])
