import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { isKeyboardOrClick, onMouseDown } from '_utils/aria'

import styles from './styles.css'

const Tabs = ({ className, tabs, activeTab, onTabClick }) => {
  const activeTabIndex = useMemo(() => tabs.indexOf(activeTab), [activeTab, tabs])

  const onClick = useCallback(
    event => {
      event.preventDefault()
      if (isKeyboardOrClick(event.key)) {
        onTabClick(event.currentTarget.id)
      }
    },
    [onTabClick]
  )

  return (
    <div className={classnames(styles.tabs, className)}>
      {tabs.map((tab, index) => (
        <div
          key={`${tab}-${index + 1}`}
          role="button"
          tabIndex="0"
          className={classnames(styles.tab, {
            [styles.active]: activeTab === tab,
            [styles['next-tab']]: index === activeTabIndex + 1,
            [styles['previous-tab']]: activeTabIndex !== 0 && index === activeTabIndex - 1,
          })}
          onClick={onClick}
          onKeyPress={onClick}
          onMouseDown={onMouseDown}
          id={tab}
        >
          <p>{tab?.replace('_', '-')}</p>
        </div>
      ))}
      <div
        className={classnames(styles['last-tab'], {
          [styles['next-tab']]: tabs.length === activeTabIndex + 1,
        })}
      />
    </div>
  )
}

Tabs.propTypes = {
  className: PropTypes.string,
  onTabClick: PropTypes.func,
  activeTab: PropTypes.string,
  tabs: PropTypes.arrayOf(PropTypes.string),
}

Tabs.defaultProps = {
  className: '',
  onTabClick: () => {},
  activeTab: 'Personal',
  tabs: ['Personal, Health'],
}

export default Tabs
