import { createSelector } from 'reselect'
import { Map } from 'immutable'

import Class from '_models/class'

import { GET_AVAILABLE_CLASSES, GET_PUBLIC_CLASS } from './actions'

export const classesSelector = state => state.publicClasses.get('classes')

export const getPublicClassById = (state, classId) =>
  state.publicClasses.getIn(['classes', `${classId}`])

export const getClassesListOrderSelector = state => state.publicClasses.getIn(['search', 'results'])

export const getAvailableClassesListSelector = createSelector(
  classesSelector,
  getClassesListOrderSelector,
  (classes, classesIdList) => classesIdList.map(id => classes.get(`${id}`))
)

export const getPublicClassSelector = (state, classId) =>
  state.publicClasses.getIn(['classes', `${classId}`]) || new Class()

export const isGetPublicClassLoadingSelector = state => state.loading.get(GET_PUBLIC_CLASS.ACTION)

export const getPublicClassErrorSelector = state => state.error.get(GET_PUBLIC_CLASS.ACTION, Map())

export const isGetAvailableClassesLoadingSelector = state =>
  state.loading.get(GET_AVAILABLE_CLASSES.ACTION)

export const getTotalAvailableClassesSelector = state =>
  state.publicClasses.getIn(['search', 'count'])

export const availableClassesNextPageSelector = state =>
  state.publicClasses.getIn(['search', 'next'])
