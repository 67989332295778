import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

export const CardSize = {
  DEFAULT: 'default',
  MEDIUM: 'medium',
  LARGE: 'large',
}

const Card = ({ className, children, size, id }) => (
  <article id={id} className={classnames(styles.container, styles[size], className)}>
    {children}
  </article>
)

Card.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.keys(CardSize).map(size => CardSize[size])),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Card.defaultProps = {
  className: '',
  size: CardSize.DEFAULT,
  id: undefined,
}

export default Card
