import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'

import Card from '_components/card'
import Button from '_components/button'
import Modal from '_components/modal'
import ModalTextarea from '_components/modal-textarea'
import useModal from '_hooks/use-modal'
import { updateIntention, UPDATE_INTENTION } from '_modules/class/actions'
import useFetchCall from '_hooks/use-fetch-call'

import styles from './styles.css'

export const COURSE_INTENTION_THEME = {
  DEFAULT: 'default',
  CLASS_HUB: 'class-hub',
}

const CourseIntention = ({ className, theme, intention, classId }) => {
  const [isIntentionModalOpen, onToggleIntentionModal] = useModal()
  const dispatch = useDispatch()

  const onSubmitIntention = useCallback(
    value => {
      dispatch(updateIntention({ classId, intention: value }))
    },
    [classId, dispatch]
  )

  const [isLoading] = useFetchCall(UPDATE_INTENTION, onToggleIntentionModal)

  return (
    <Card className={className}>
      <div className={classnames(styles.container, styles[theme])}>
        <h3 className={styles.title}>My course intention</h3>
        <p className={styles.intention}>
          {intention || 'You have not set a course intention yet. Why not do it now?'}
        </p>
        <Button label="EDIT" className={styles.action} onClick={onToggleIntentionModal} />
      </div>
      {isIntentionModalOpen && (
        <Modal isOpen onClose={onToggleIntentionModal}>
          <ModalTextarea
            title="Edit course intention"
            buttonLabel="Save"
            onSubmit={onSubmitIntention}
            onClose={onToggleIntentionModal}
            isLoading={isLoading}
            value={intention}
          />
        </Modal>
      )}
    </Card>
  )
}

CourseIntention.propTypes = {
  classId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(COURSE_INTENTION_THEME)),
  intention: PropTypes.string,
}

CourseIntention.defaultProps = {
  classId: '',
  className: '',
  theme: COURSE_INTENTION_THEME.DEFAULT,
  intention: '',
}

export default CourseIntention
