import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import { Menu, MenuButton, MenuList, MenuLink } from '@reach/menu-button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGoogle, faApple } from '@fortawesome/free-brands-svg-icons'

import ColoredCalendarIcon from '_assets/icons/colored-calendar-icon.svg'
import { buildGoogleCalendarUrl, buildCalendarFile, isIE } from '_utils/add-to-calendar'

import styles from './styles.css'

const AddToCalendar = ({ event }) => {
  const eventObject = useMemo(
    () => {
      const newEvent = event

      if (event.meetingUrl) {
        if (!event.description) {
          newEvent.description = `${event.meetingText || 'Join the meeting'}: ${event.meetingUrl}`
        } else {
          newEvent.description = `${event.description}\n\n${event.meetingText ||
            'Join the meeting'}: ${event.meetingUrl}`
        }
      }

      return newEvent
    },
    [event]
  )

  const onAppleCalendarClick = useCallback(
    () => {
      const calendarUrl = buildCalendarFile(eventObject)
      const filename = 'download.ics'
      const blob = new Blob([calendarUrl], { type: 'text/calendar;charset=utf-8' })

      if (isIE) {
        window.navigator.msSaveOrOpenBlob(blob, filename)
      } else {
        const link = document.createElement('a')
        link.href = window.URL.createObjectURL(blob)
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
        document.body.removeChild(link)
      }
    },
    [eventObject]
  )

  const CALENDAR_OPTIONS = useMemo(
    () => ({
      GOOGLE: {
        label: 'Google',
        icon: <FontAwesomeIcon icon={faGoogle} />,
        action: {
          href: buildGoogleCalendarUrl(eventObject),
          target: '_blank',
          rel: 'noopener noreferrer',
        },
      },
      APPLE: {
        label: 'Apple Calendar',
        icon: <FontAwesomeIcon className={styles.icon} icon={faApple} />,
        action: { onClick: onAppleCalendarClick },
      },
    }),
    [eventObject, onAppleCalendarClick]
  )
  return (
    <Menu className={styles.menu}>
      <MenuButton className={styles['dropdown-button']}>
        <svg
          className={styles['calendar-icon']}
          viewBox={ColoredCalendarIcon.viewBox}
          aria-hidden="true"
        >
          <use xlinkHref={`#${ColoredCalendarIcon.id}`} />
        </svg>
        ADD TO CALENDAR
      </MenuButton>
      <MenuList>
        {Object.values(CALENDAR_OPTIONS).map(option => (
          <MenuLink className={styles['menu-option']} key={option.label} {...option.action}>
            <span className={styles['icon-wrapper']}>{option.icon}</span>
            {option.label}
          </MenuLink>
        ))}
      </MenuList>
    </Menu>
  )
}

AddToCalendar.propTypes = {
  event: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    startTime: PropTypes.string,
    endTime: PropTypes.string,
    recurring: PropTypes.shape({ repeat: PropTypes.string }),
    meetingUrl: PropTypes.string,
    meetingText: PropTypes.string,
  }).isRequired,
}
export default React.memo(AddToCalendar)
