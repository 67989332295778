import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Svg from '_components/svg'
import { iconPropTypes } from '_utils/proptypes'

import styles from './styles.css'

export const COURSE_TAG_THEME = {
  DEFAULT: 'default',
  DARK: 'dark',
  LIGHT: 'light',
  MALIBU: 'malibu',
}

const CourseTag = ({ text, icon, theme, className }) => (
    <div className={classnames(styles['course-tag-container'], styles[theme], className)}>
      {icon && <Svg className={styles['course-tag-icon']} icon={icon} />}
      <p>{text}</p>
    </div>
  )

CourseTag.propTypes = {
  text: PropTypes.string,
  icon: iconPropTypes,
  theme: PropTypes.oneOf(Object.values(COURSE_TAG_THEME)),
  className: PropTypes.string,
}

CourseTag.defaultProps = {
  text: '',
  icon: null,
  theme: COURSE_TAG_THEME.LIGHT,
  className: '',
}

export default React.memo(CourseTag)
