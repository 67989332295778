import React from 'react'
import PropTypes from 'prop-types'

import ExpandableComponent from '_components/landing-components/expandable-component'
import SectionTitle from '_components/landing-components/section-title'
import { prismicContentPropType, prismicTitlePropType } from '_utils/proptypes'

import styles from './styles.css'

const QuestionsSection = ({ slice, id }) => (
  <section className={styles.section} id={id}>
    <div className={styles.container}>
      {slice.sectionTitle && (
        <SectionTitle
          title={slice.sectionTitle}
          className={styles.title}
          boldClassName={styles['bold-text']}
        />
      )}
      <div className={styles.content}>
        {slice.items && (
          <ExpandableComponent className={styles['expandable-section']} content={slice.items} />
        )}
      </div>
    </div>
  </section>
)
QuestionsSection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    actionButton: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        question: PropTypes.string,
        answer: prismicContentPropType,
      })
    ),
    sectionText: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(QuestionsSection)
