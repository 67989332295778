import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { TOGGLE_FILTER_OPTIONS } from '_utils/constants'

import styles from './styles.css'

const ToggleButton = ({ filterList, selectedValue, onToggleClick, className }) => {
  const handleToggleClick = useCallback(
    event => {
      const { value } = event.currentTarget
      onToggleClick(value)
    },
    [onToggleClick]
  )

  return (
    <div className={classnames(styles['toggle-button-container'], className)}>
      <div
        className={classnames(styles.filter, {
          [styles['with-schedule']]: selectedValue === TOGGLE_FILTER_OPTIONS.COACH_LED.value,
          [styles['self-paced']]: selectedValue === TOGGLE_FILTER_OPTIONS.SELF_PACED.value,
        })}
      />
      {filterList.map(filter => (
          <button
            key={filter.label}
            className={classnames(styles['button-container'], {
              [styles.selected]: filter.value === selectedValue,
            })}
            value={filter.value}
            onClick={handleToggleClick}
          >
            {filter.label}
          </button>
        ))}
    </div>
  )
}

ToggleButton.propTypes = {
  filterList: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.arrayOf(PropTypes.number),
  ]).isRequired,
  selectedValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onToggleClick: PropTypes.func,
  className: PropTypes.string,
}

ToggleButton.defaultProps = {
  selectedValue: '',
  className: '',
  onToggleClick: () => {},
}

export default React.memo(ToggleButton)
