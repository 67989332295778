import React, { useCallback, useState, useRef, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import InputTrigger from 'react-input-trigger'
import debounce from 'lodash.debounce'
import { useSelector, useDispatch } from 'react-redux'
import classnames from 'classnames'
import Textarea from 'react-textarea-autosize'
import { Picker } from 'emoji-mart'
import 'emoji-mart/css/emoji-mart.css'

import AttachIcon from '_assets/icons/attach.svg'
import SendIcon from '_assets/icons/send.svg'
import TaggingUserList from '_components/tagging-user'
import { usePrevious } from '_utils/hooks'
import { searchSuggestions } from '_modules/suggestion/actions'
import { suggestionsSelector } from '_modules/suggestion/selectors'
import { MEDIUM_WIDTH } from '_utils/constants'
import { onMouseDown } from '_utils/aria'
import Emoji from '_assets/icons/emoji.svg'

import FileUploadToast from './file-upload-toast'
import styles from './styles.css'

const BACKSPACE = 8

const trigger = {
  keyCode: 50,
  shiftKey: typeof window !== 'undefined' && window.innerWidth > MEDIUM_WIDTH,
}

const ChatFooter = ({ className, onSubmit, isDisabled, isSendingMessage, hasGoals }) => {
  const dispatch = useDispatch()
  const suggestedUser = useSelector(suggestionsSelector)
  const [message, setMessage] = useState('')
  const [characterPosition, setCharacterPosition] = useState(0)
  const [file, selectFile] = useState(null)
  const [showSuggester, setShowSuggester] = useState(false)
  const prevShowSuggester = usePrevious(showSuggester)
  const fileInput = useRef(null)
  const messageRef = useRef(null)
  const suggestionRef = useRef(null)
  const buttonRef = useRef(null)
  const endHandler = useRef(null)
  const [username, setUsername] = useState('')
  const [startTagging, setStartTagging] = useState(null)
  const previousUsername = usePrevious(username)
  const wasSendingMessage = usePrevious(isSendingMessage)
  const [showEmoji, setEmoji] = useState(false)

  const setInputRef = useCallback(ref => {
    messageRef.current = ref
  }, [])

  const setEndHandler = useCallback(ref => {
    endHandler.current = ref
  }, [])

  const handleEmoji = useCallback(() => {
    setEmoji(lastEmoji => !lastEmoji)
  }, [])

  const emojiStyle = useMemo(
    () => ({
      position: 'absolute',
      right: '0px',
      fontFamily: 'Poppins',
      fontSize: '14px',
      color: 'var(--gray-24)',
      bottom: window.innerWidth > MEDIUM_WIDTH ? '58px' : '100px',
    }),
    []
  )

  const handleOutsideClick = useCallback(e => {
    if (suggestionRef.current === e.target) {
      return
    }

    setShowSuggester(false)

    endHandler.current()
  }, [])

  const debounceShowSuggestion = debounce(() => {
    dispatch(searchSuggestions(username))
  }, 100)

  const toggleSuggester = useCallback(
    metaInformation => {
      const {
        hookType,
        cursor: { selectionStart },
      } = metaInformation

      if (hookType === 'start') {
        const previousWordCharacter = selectionStart - 2
        if (message.charAt(previousWordCharacter) === ' ' || characterPosition === 0) {
          setShowSuggester(true)
          setStartTagging(characterPosition)
          dispatch(searchSuggestions())
        } else {
          endHandler.current()
          setShowSuggester(false)
        }
      } else if (hookType === 'cancel') {
        setShowSuggester(false)
        setStartTagging(null)
      }
    },
    [characterPosition, dispatch, message]
  )

  const handleTextareaInput = useCallback(
    event => {
      const { value } = event.currentTarget

      setMessage(
        `${message.substring(0, startTagging + 1)}${value} ${message.substring(characterPosition)}`
      )

      if (messageRef.current) {
        messageRef.current.selectionStart = characterPosition + value.length + 1
      }

      setCharacterPosition(characterPosition + value.length + 1)
      setShowSuggester(false)
      endHandler.current()
    },
    [characterPosition, message, startTagging]
  )

  const checkBackSpace = useCallback(
    event => {
      if (event.keyCode === BACKSPACE && showSuggester) {
        const newUsername = username.substring(0, username.length - 1)
        setUsername(newUsername)
      }
    },
    [showSuggester, username]
  )

  useEffect(
    () => {
      if (prevShowSuggester && !showSuggester) {
        if (messageRef.current) {
          messageRef.current.focus()
        }
        document.removeEventListener('click', handleOutsideClick, false)
      }
      if (!prevShowSuggester && showSuggester) {
        document.addEventListener('click', handleOutsideClick, false)
      }
      if (previousUsername !== username && showSuggester) {
        debounceShowSuggestion()
      }
    },
    [
      debounceShowSuggestion,
      handleOutsideClick,
      prevShowSuggester,
      previousUsername,
      showSuggester,
      username,
    ]
  )

  const onFormSubmit = useCallback(
    event => {
      event.preventDefault()
      if (message.length > 0) {
        onSubmit({ message })
        setMessage('')
      }
    },
    [message, onSubmit]
  )

  const onChange = useCallback(
    event => {
      const { value } = event.currentTarget
      const character = event.target.selectionStart
      setMessage(value)
      setCharacterPosition(character)

      if (showSuggester) {
        if (value.charAt(characterPosition) !== '@' && message.length !== character) {
          setUsername(prevUsername => {
            if (value[characterPosition]) {
              return prevUsername + value[characterPosition]
            }

            return prevUsername
          })
        }
      } else if (!showSuggester) {
        setUsername('')
      }
    },
    [characterPosition, message.length, showSuggester],
    []
  )

  const onSelectFile = useCallback(event => {
    selectFile(event.currentTarget.files[0])
  }, [])

  useEffect(
    () => {
      if (file) {
        onSubmit({ file })
      }
    },
    [file, onSubmit]
  )

  useEffect(
    () => {
      if (wasSendingMessage && !isSendingMessage) {
        selectFile(null)
        fileInput.current.value = null
      }
    },
    [isSendingMessage, wasSendingMessage]
  )

  const onAddFileClick = useCallback(
    () => {
      fileInput.current.click()
    },
    [fileInput]
  )

  const onTextAreaKeyPress = useCallback(
    event => {
      if (event.key === 'Enter' && !event.shiftKey) {
        onFormSubmit(event)
      }
    },
    [onFormSubmit]
  )

  const inputTriggerRef = useCallback(() => messageRef.current, [messageRef])
  const addEmoji = useCallback(
    event => {
      const { native } = event
      const { selectionStart } = messageRef.current
      setMessage(message.substring(0, selectionStart) + native + message.substring(selectionStart))
      setEmoji(false)
      messageRef.current.focus()
    },
    [message]
  )

  return (
    <div
      className={classnames(styles.wrapper, className, {
        [styles['has-goals']]: hasGoals,
      })}
    >
      <div className={styles.container}>
        <button
          type="button"
          className={styles['attach-button']}
          onMouseDown={onMouseDown}
          disabled={isDisabled}
          onClick={onAddFileClick}
        >
          <svg
            className={styles.upload}
            aria-label="Upload file"
            role="img"
            viewBox={AttachIcon.viewBox}
          >
            <use xlinkHref={`#${AttachIcon.id}`} />
          </svg>
        </button>
        <form onSubmit={onFormSubmit} className={styles.form}>
          <InputTrigger
            trigger={trigger}
            onStart={toggleSuggester}
            onCancel={toggleSuggester}
            endTrigger={setEndHandler}
            className={styles.content}
            inputRef={inputTriggerRef}
          >
            <Textarea
              className={styles['text-message']}
              placeholder="Write a message here"
              onChange={onChange}
              value={message}
              inputRef={setInputRef}
              onKeyDown={checkBackSpace}
              onKeyPress={onTextAreaKeyPress}
            />
          </InputTrigger>
          {showSuggester && (
            <TaggingUserList
              suggestedUser={suggestedUser}
              buttonRef={buttonRef}
              handleTextareaInput={handleTextareaInput}
              suggestionRef={suggestionRef}
              className={styles['tagging-user']}
            />
          )}
          {showEmoji && (
            <Picker
              title="Pick your emoji"
              emoji="point_up"
              set="apple"
              color="var(--shamrock-2)"
              style={emojiStyle}
              onSelect={addEmoji}
              html
            />
          )}
          <div className={styles['button-container']}>
            <button
              type="button"
              className={styles['attach-emoji']}
              onMouseDown={onMouseDown}
              onClick={handleEmoji}
            >
              <svg
                className={classnames(styles.emoji, { [styles.clicked]: showEmoji })}
                aria-label="Choose Emoji"
                role="img"
                viewBox={Emoji.viewBox}
              >
                <use xlinkHref={`#${Emoji.id}`} />
              </svg>
            </button>
            <button
              type="submit"
              className={styles['send-button']}
              onMouseDown={onMouseDown}
              disabled={isDisabled}
            >
              <svg
                className={styles.send}
                aria-label="Send message"
                role="img"
                viewBox={SendIcon.viewBox}
              >
                <use xlinkHref={`#${SendIcon.id}`} />
              </svg>
            </button>
          </div>
        </form>
        <input type="file" style={{ display: 'none' }} ref={fileInput} onChange={onSelectFile} />
        {isSendingMessage &&
          file && (
            <FileUploadToast
              fileName={file.name || ''}
              className={classnames(styles['upload-toast'], { [styles.show]: file })}
            />
          )}
      </div>
    </div>
  )
}

ChatFooter.propTypes = {
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  isDisabled: PropTypes.bool,
  isSendingMessage: PropTypes.bool,
  hasGoals: PropTypes.bool,
}

ChatFooter.defaultProps = {
  className: '',
  onSubmit: () => {},
  isDisabled: false,
  isSendingMessage: false,
  hasGoals: false,
}

export default ChatFooter
