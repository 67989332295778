import React from 'react'
import PropTypes from 'prop-types'

const Svg = ({ icon, className, ...svgProps }) => (
    <svg aria-hidden="true" className={className} viewBox={icon.viewBox} {...svgProps}>
      <use xlinkHref={`#${icon.id}`} />
    </svg>
  )

Svg.propTypes = {
  icon: PropTypes.shape({
    viewBox: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
}

Svg.defaultProps = {
  className: '',
}

export default Svg
