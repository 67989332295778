import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ArrowRightIcon from '_assets/icons/arrow-right-24-px.svg'
import Svg from '_components/svg'
import { Channel } from '_models/'
import ChatIcon from '_assets/icons/chat-icon.svg'
import IconButton from '_components/icon-button'

import styles from './styles.css'

const ClassChannel = ({ channel, className }) => (
    <div className={classnames(styles['class-channel-container'], className)}>
      <Svg className={styles['chat-icon']} icon={ChatIcon} />
      <p>Channel</p>
      <p className={styles['channel-name']}>{channel.name}</p>
      <IconButton
        href={`/messages?channelId=${channel.id}`}
        className={styles['access-channel-button']}
        aria-label="Access channel arrow right"
        iconClassName={styles['arrow-icon']}
        icon={ArrowRightIcon}
      />
    </div>
  )

ClassChannel.propTypes = {
  channel: PropTypes.instanceOf(Channel).isRequired,
  className: PropTypes.string,
}

ClassChannel.defaultProps = {
  className: '',
}

export default ClassChannel
