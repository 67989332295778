import React, { Fragment, useCallback, useState, useMemo, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Waypoint } from 'react-waypoint'
import { useSelector, useDispatch } from 'react-redux'
import download from 'downloadjs'
import debounce from 'lodash.debounce'

import ClassCard from '_components/class-card-with-next-assignment'
import Filter from '_components/filter'
import SearchInput, { SearchType } from '_components/search-input'
import Button, { ButtonTheme } from '_components/button'
import { classShape, userShape } from '_utils/proptypes'
import EmptyCourses from '_assets/icons/il-course.svg'
import { getMoreClasses, getClasses } from '_modules/class/actions'
import {
  isGettingClassesSelector,
  isGettingMoreClassesSelector,
  getClassesErrorSelector,
} from '_modules/class/selectors'
import RocketLoader from '_components/rocket-loader'
import usePrevious from '_hooks/use-previous'
import { exportCourses } from '_services/course'
import { CLASS_FILTERS } from '_utils/constants'
import { FLAG_LOCAL_WAYPOINT } from '_config/environment'

import styles from './styles.css'

const LAST_BUT_ONE = 2

const MyClassesSection = ({
  className,
  title,
  classes,
  onFilterChange,
  nextPage,
  filter,
  user,
}) => {
  const isGettingClasses = useSelector(isGettingClassesSelector)
  const wasGettingClasses = usePrevious(isGettingClasses)
  const getClassesError = useSelector(getClassesErrorSelector)
  const isGettingMoreClasses = useSelector(isGettingMoreClassesSelector)
  const [query, setQuery] = useState('')

  const dispatch = useDispatch()
  const classesRef = useRef(null)

  const scrollableAncestor = useMemo(() => (FLAG_LOCAL_WAYPOINT ? window : 'window'), [])

  useEffect(
    () => {
      if (wasGettingClasses && !isGettingClasses && !getClassesError.size && classesRef?.current) {
        classesRef.current.scrollLeft = 0
      }
    },
    [getClassesError.size, isGettingClasses, wasGettingClasses]
  )

  const onDownloadClick = useCallback(
    () => {
      exportCourses(user.authToken)
        .then(blob => {
          download(blob, `${user.username}-report.csv`)
        })
        .catch(res => {
          console.warn(res.statusText)
        })
    },
    [user.authToken, user.username]
  )

  const onFilterClick = useCallback(
    ordering => {
      onFilterChange({ status: ordering, search: query })
    },
    [onFilterChange, query]
  )

  const handleLoadMore = useCallback(
    () => {
      dispatch(getMoreClasses({ status: filter, page: nextPage, search: query }))
    },
    [dispatch, filter, nextPage, query]
  )

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const searchClasses = useCallback(
    debounce(search => {
      const params = { status: filter, search }

      dispatch(getClasses(params))
    }, 250),
    [filter]
  )

  const onChange = useCallback(
    event => {
      const { value } = event.target
      setQuery(value)
      searchClasses(value)
    },
    [searchClasses]
  )
  const renderClasses = useCallback(
    (content, index) => (
      <Fragment key={content?.id}>
        <ClassCard className={styles.card} course={content} />
        {!!nextPage &&
          index === classes.length - LAST_BUT_ONE && (
            <Waypoint onEnter={handleLoadMore} scrollableAncestor={scrollableAncestor} />
          )}
      </Fragment>
    ),
    [classes.length, handleLoadMore, nextPage, scrollableAncestor]
  )

  const renderContent = useMemo(
    () => {
      if (isGettingClasses) {
        return (
          <div className={styles.loading}>
            <RocketLoader />
          </div>
        )
      }

      if (classes.length === 0) {
        return (
          <div className={styles['empty-state']}>
            <svg role="img" viewBox={EmptyCourses.viewBox} aria-label="Empty classes icon">
              <use xlinkHref={`#${EmptyCourses.id}`} />
            </svg>
            {filter === 'all' ? (
              <p>You don&apos;t have any classes.</p>
            ) : (
              <p>You don&apos;t have any {`${filter}`} classes.</p>
            )}
          </div>
        )
      }

      return (
        <div ref={classesRef} className={styles.content}>
          {classes.map(renderClasses)}
          {isGettingMoreClasses && <RocketLoader />}
        </div>
      )
    },
    [classes, filter, isGettingClasses, isGettingMoreClasses, renderClasses]
  )

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles['header-wrapper']}>
        <div className={styles.header}>
          <h1 className={styles.title}>{title}</h1>
          <SearchInput
            className={styles['search-form']}
            type={SearchType.MODAL}
            placeholder="Search"
            onChange={onChange}
            value={query}
          />
        </div>
        <div className={styles['sub-header']}>
          {user.isInstructor && (
            <Button
              className={styles['report-button']}
              onClick={onDownloadClick}
              theme={ButtonTheme.DEFAULT}
              label="Download report"
            />
          )}
          <Filter
            className={styles.filter}
            filters={CLASS_FILTERS}
            activeFilter={filter}
            onClick={onFilterClick}
          />
        </div>
      </div>
      {renderContent}
    </div>
  )
}

MyClassesSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  classes: PropTypes.arrayOf(classShape).isRequired,
  onFilterChange: PropTypes.func,
  nextPage: PropTypes.number,
  user: userShape.isRequired,
  filter: PropTypes.string.isRequired,
}

MyClassesSection.defaultProps = {
  className: '',
  title: '',
  onFilterChange: () => {},
  nextPage: null,
}

export default React.memo(MyClassesSection)
