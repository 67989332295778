import React, { useCallback, useReducer, useMemo, useEffect } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { extension } from 'mime-types'

import Button, { ButtonTheme } from '_components/button'
import UserPlaceholder from '_assets/icons/user-placeholder.svg'
import ErrorTooltip from '_components/error-tooltip'
import {
  isUpdatingUserSelector,
  updateUserErrorSelector,
  getUserSelector,
} from '_modules/user/selectors'
import usePrevious from '_hooks/use-previous'

import styles from './styles.css'
import { reducer, INITIAL_STATE, UPDATE_STATE } from './reducer'

const extensions = ['.jpg', '.jpeg', '.png', '.bmp', '.gif']
const SecondStep = ({
  onSelectedPictureFile,
  pictureBlob,
  onSaveProfile,
  formValues,
  closeModal,
}) => {
  const isLoading = useSelector(isUpdatingUserSelector)
  const wasLoading = usePrevious(isLoading)
  const updateUserError = useSelector(updateUserErrorSelector)
  const { username, name: userName } = useSelector(getUserSelector)

  const [state, dispatchState] = useReducer(reducer, INITIAL_STATE)

  useEffect(() => {
    const initialState = {
      name: formValues.name || userName,
      username: formValues.username || username,
    }
    if (pictureBlob?.blob) {
      // Remove the object url from the current id
      if (state.picture.blob) {
        URL.revokeObjectURL(state.picture.blob)
      }

      const fileType = pictureBlob.blob.type
      const fileName = `avatar.${extension(fileType)}`
      const newFile = new File([pictureBlob.blob], fileName, { type: fileType })
      initialState.picture = {
        file: newFile,
        blob: URL.createObjectURL(newFile),
      }
    }
    dispatchState({
      type: UPDATE_STATE,
      payload: initialState,
    })

    // eslint-disable-next-line
  },[])

  const onChooseFile = useCallback(
    event => {
      const file = event.target.files[0]

      onSelectedPictureFile(file, {
        name: state.name,
        username: state.username,
      })
      /* eslint-disable no-param-reassign */
      event.target.value = null
    },
    [onSelectedPictureFile, state.name, state.username]
  )

  const onInputChange = useCallback(event => {
    const { value, name } = event.target

    dispatchState({
      type: UPDATE_STATE,
      payload: {
        [name]: value,
      },
    })
  }, [])

  const getErrors = useMemo(
    () => {
      const errors = {}

      if (!state.name) {
        errors.name = 'This field is required'
      }

      if (!state.username) {
        errors.username = 'This field is required'
      }

      return errors
    },
    [state.name, state.username]
  )

  const onSaveClick = useCallback(
    event => {
      event.preventDefault()

      if (Object.keys(getErrors).length) {
        dispatchState({
          type: UPDATE_STATE,
          payload: { errors: getErrors },
        })
      } else {
        dispatchState({
          type: UPDATE_STATE,
          payload: { errors: INITIAL_STATE.errors },
        })
        onSaveProfile(state)
      }
    },
    [getErrors, onSaveProfile, state]
  )

  const nameError = useMemo(() => state.errors.name && { 'aria-describedby': state.errors.name }, [
    state.errors.name,
  ])

  const usernameError = useMemo(
    () => state.errors.username && { 'aria-describedby': state.errors.username },
    [state.errors.username]
  )

  useEffect(
    () => {
      if (wasLoading && !isLoading && !updateUserError.size) {
        closeModal()
      }
    },
    [closeModal, isLoading, updateUserError.size, wasLoading]
  )

  return (
    <div className={styles.section}>
      <p className={styles.title}>You&apos;re almost there!</p>
      <h1 className={styles.description}>
        Add a profile picture and confirm your name and username
      </h1>
      <form className={styles.form} onSubmit={onSaveClick}>
        <div className={styles['picture-container']}>
          <p className={styles['picture-label']}>PROFILE PICTURE</p>
          {state.picture?.blob ? (
            <img
              src={state.picture.blob}
              alt="user avatar"
              className={styles['placeholder-icon']}
            />
          ) : (
            <svg
              className={styles['placeholder-icon']}
              aria-hidden="true"
              viewBox={UserPlaceholder.viewBox}
            >
              <use xlinkHref={`#${UserPlaceholder.id}`} />
            </svg>
          )}
          <label htmlFor="avatar" className={styles['upload-button']}>
            <input
              id="avatar"
              className={styles['file-input']}
              type="file"
              onChange={onChooseFile}
              accept={extensions.toString()}
            />
            Upload
          </label>
        </div>
        <div className={styles['user-name']}>
          <label htmlFor="name" className={styles.label}>
            NAME
            <input
              className={classnames(styles.input, { [styles.error]: state.errors.name })}
              id="name"
              value={state.name}
              name="name"
              onChange={onInputChange}
              {...nameError}
              required
            />
            {!!state.errors.name && (
              <ErrorTooltip className={styles['error-icon']} message={state.errors.name} />
            )}
          </label>
          <label htmlFor="username" className={styles.label}>
            USERNAME
            <input
              className={classnames(styles.input, { [styles.error]: state.errors.username })}
              id="username"
              value={state.username}
              name="username"
              onChange={onInputChange}
              required
              {...usernameError}
            />
            {!!state.errors.username && (
              <ErrorTooltip className={styles['error-icon']} message={state.errors.username} />
            )}
          </label>
        </div>
        <Button
          className={styles['save-button']}
          theme={ButtonTheme.PRIMARY}
          onClick={onSaveClick}
          label="Save"
          loading={isLoading}
        />
      </form>
    </div>
  )
}

SecondStep.propTypes = {
  onSelectedPictureFile: PropTypes.func.isRequired,
  onSaveProfile: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  pictureBlob: PropTypes.shape({
    blob: PropTypes.shape({ type: PropTypes.string, size: PropTypes.number }),
  }),
  formValues: PropTypes.shape({
    name: PropTypes.string,
    username: PropTypes.string,
  }),
}

SecondStep.defaultProps = {
  pictureBlob: {},
  formValues: {},
}

export default React.memo(SecondStep)
