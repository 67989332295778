import * as blockedService from '_services/blocked'
import { defineAction } from '_utils/redux'

export const GET_BLOCKED_USERS = defineAction('GET_BLOCKED_USERS')
export const UNBLOCK_USER = defineAction('UNBLOCK_USER')

export const getBlockedUsers = () => (dispatch, getState) => {
  dispatch({
    type: GET_BLOCKED_USERS.ACTION,
    payload: blockedService.getBlockedUsers(getState().user.authToken),
  })
}

export const unblockUser = username => (dispatch, getState) => {
  dispatch({
    type: UNBLOCK_USER.ACTION,
    payload: blockedService.unblockUser(getState().user.authToken)(username),
    meta: {
      username,
    },
  })
}
