import React, { useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Waypoint } from 'react-waypoint'
import { navigate } from '@reach/router'

import Button from '_components/button'
import CloseIcon from '_assets/icons/close.svg'
import Modal from '_components/modal'
import SearchInput, { SearchType } from '_components/search-input'
import { channelShape } from '_utils/proptypes'
import RocketLoader from '_components/rocket-loader'
import { usePrevious } from '_utils/hooks'

import NewChannelCard from '../create-new-channel-card'

import styles from './styles.css'
import ChannelJoin from './channel-join'

const BrowseChannelModal = ({
  isOpen,
  onClose,
  className,
  availableChannels,
  isLoading,
  getChannels,
  newChannel,
  createChannelError,
  joinChannel,
  inviteChannel,
  isAdmin,
  onChannelClick,
}) => {
  const [search, setSearch] = useState('')
  const [isCreateNewChannel, setNewChannel] = useState(false)

  const onSearchChange = useCallback(event => setSearch(event.target.value), [])

  const onSearchSubmit = useCallback(
    event => {
      event.preventDefault()
      getChannels({ search: search || null })
    },
    [getChannels, search]
  )

  const onNewChannel = useCallback(() => setNewChannel(channel => !channel), [])

  const onLoadMore = useCallback(
    () => {
      getChannels({ search: search || null, page: availableChannels.next })
    },
    [availableChannels.next, getChannels, search]
  )

  const hasMore = useMemo(() => !!availableChannels.next, [availableChannels.next])

  const [selectedChannel, setSelectedChannel] = useState()

  const joinChannelAndNavigate = useCallback(
    channelId => {
      joinChannel(channelId) // API call to Join channel
      setSelectedChannel(channelId) // navigate to selected screen
    },
    [joinChannel]
  )

  const wasLoading = usePrevious(isLoading)

  useEffect(
    () => {
      if (!isLoading && wasLoading && selectedChannel) {
        if (onChannelClick) {
          onChannelClick(selectedChannel)
          return
        }
        navigate([`/messages?channelId=${selectedChannel}`])
      }
    },
    [isLoading, onChannelClick, selectedChannel, wasLoading]
  )

  const renderChannels = useCallback(
    (channel, index) => {
      if (index + 1 === availableChannels.channels.length && hasMore) {
        return (
          <Waypoint key={channel.id} onEnter={onLoadMore}>
            <ChannelJoin
              id={channel.id}
              name={channel.name}
              joined={channel.isMember}
              className={styles.channel}
              numberOfMembers={channel.numberOfMembers}
              onJoinClick={joinChannelAndNavigate}
              isLoading={isLoading}
            />
          </Waypoint>
        )
      }

      return (
        <ChannelJoin
          id={channel.id}
          name={channel.name}
          joined={channel.isMember}
          className={styles.channel}
          key={channel.id}
          numberOfMembers={channel.numberOfMembers}
          onJoinClick={joinChannelAndNavigate}
          isLoading={isLoading}
        />
      )
    },
    [availableChannels.channels.length, hasMore, isLoading, joinChannelAndNavigate, onLoadMore]
  )

  return isCreateNewChannel ? (
    <NewChannelCard
      isOpen
      onClose={onClose}
      newChannel={newChannel}
      isLoading={isLoading}
      createChannelError={createChannelError}
      joinChannel={joinChannel}
      inviteChannel={inviteChannel}
      isAdmin={isAdmin}
    />
  ) : (
    <Modal isOpen={isOpen} onClose={onClose} className={classnames(styles.modal, className)}>
      <div className={styles.card}>
        <div className={styles.header}>
          <div className={styles.heading}>
            <h2 className={styles.title}>Browse channels</h2>
            <Button
              className={styles.close}
              iconOnly
              icon={CloseIcon}
              onClick={onClose}
              label="Close modal button"
            />
          </div>
          <div className={styles.options}>
            or{' '}
            <button type="button" className={styles.button} onClick={onNewChannel}>
              Create a new Channel
            </button>
          </div>
        </div>
        <div className={styles['search-container']}>
          <form className={styles['search-form']} onSubmit={onSearchSubmit}>
            <SearchInput
              type={SearchType.MODAL}
              placeholder="Search"
              onChange={onSearchChange}
              value={search}
              className={styles.search}
            />
          </form>
        </div>
        <div className={styles['all-channels']}>
          {isLoading && availableChannels.channels.length === 0 ? (
            <RocketLoader />
          ) : (
            availableChannels.channels.map(renderChannels)
          )}
        </div>
        <div className={styles.footer} />
      </div>
    </Modal>
  )
}
BrowseChannelModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  availableChannels: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.number,
    previous: PropTypes.number,
    channels: PropTypes.arrayOf(channelShape),
  }).isRequired,
  isLoading: PropTypes.bool,
  getChannels: PropTypes.func,
  newChannel: PropTypes.func,
  createChannelError: PropTypes.string,
  joinChannel: PropTypes.func,
  inviteChannel: PropTypes.func,
  isAdmin: PropTypes.bool,
  onChannelClick: PropTypes.func,
}

BrowseChannelModal.defaultProps = {
  isOpen: false,
  onChannelClick: null,
  onClose: () => {},
  className: '',
  isLoading: false,
  getChannels: () => {},
  newChannel: () => {},
  createChannelError: '',
  joinChannel: () => {},
  inviteChannel: () => {},
  isAdmin: false,
}

export default React.memo(BrowseChannelModal)
