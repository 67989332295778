import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useWindowSize } from '@reach/window-size'

import { MEDIUM_WIDTH, MOBILE_WIDTH, PLANET_PATHS } from '_utils/constants'
import DotPlanetPathIcon from '_assets/icons/dot-planet-path-icon.svg'
import { isReverseLine } from '_utils/helpers'

import styles from './styles.css'

const PlanetsPath = ({ lines, className }) => {
  const { width } = useWindowSize()

  const pathIcon = useMemo(
    () => {
      if (typeof window === 'undefined') {
        return null
      }

      if (width > MEDIUM_WIDTH) {
        return PLANET_PATHS.DESKTOP
      }

      return PLANET_PATHS.TABLET
    },
    [width]
  )

  const renderPathIcons = useMemo(
    () => Array.from({ length: lines }, (_, index) => (
        <svg
          key={index}
          aria-label="Planet path"
          role="img"
          viewBox={DotPlanetPathIcon.viewBox}
          className={classnames(styles['planets-mobile-path'], {
            [styles.reverse]: isReverseLine(index),
          })}
        >
          <use xlinkHref={`#${DotPlanetPathIcon.id}`} />
        </svg>
      )),
    [lines]
  )

  return (
    <div className={className}>
      {width < MOBILE_WIDTH ? (
        renderPathIcons.map(item => item)
      ) : (
        <svg
          aria-label="Planet path"
          role="img"
          viewBox={pathIcon.viewBox}
          className={styles['planets-path']}
        >
          <use xlinkHref={`#${pathIcon.id}`} />
        </svg>
      )}
    </div>
  )
}

PlanetsPath.propTypes = {
  lines: PropTypes.number,
  className: PropTypes.string,
}

PlanetsPath.defaultProps = {
  lines: null,
  className: '',
}

export default PlanetsPath
