import React, { useEffect, useMemo, useState, useReducer, useCallback } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { useLocation, navigate } from '@reach/router'
import cookies from 'react-cookies'

import Header from '_components/landing-components/header'
import Footer from '_components/landing-components/footer'
import { dismissHighlightMessage, getPrismicInformation } from '_modules/prismic/actions'
import {
  baseAppSelector,
  prismicLoadingSelector,
  prismicErrorSelector,
  hasHighlightMessageSelector,
  highlightLinkSelector,
} from '_modules/prismic/selectors'
import usePrevious from '_hooks/use-previous'
import RocketLoader from '_components/rocket-loader'
import { getCourseLoadingSelector } from '_modules/public-courses/selectors'
import LoginModal from '_components/authentication-modals/login'
import SignUpModal from '_components/authentication-modals/sign-up'
import ForgotPasswordModal from '_components/authentication-modals/forgot-password'
import PasswordResetConfirmationModal from '_components/authentication-modals/password-reset-confirmation'
import CheckoutModal from '_components/checkout-modal'
import HighlightMessage from '_components/landing-components/highlight-message'

import styles from './styles.css'
import {
  reducer,
  getCurrentModalThroughUrl,
  INITIAL_STATE,
  TOGGLE_MODAL,
  ON_CLOSE_MODAL,
  AUTHENTICATION_URLS,
} from './reducer'

const BaseApp = ({ children }) => {
  const baseApp = useSelector(baseAppSelector)?.toJS()
  const hasHighlightMessage = useSelector(hasHighlightMessageSelector)
  const highlightLink = useSelector(highlightLinkSelector)
  const [isAlertMessageVisible, setIsAlertMessageVisible] = useState(true)
  const { pathname, search } = useLocation()
  const dispatch = useDispatch()

  const [
    {
      isLoginModalOpen,
      isSignUpModalOpen,
      isForgotPasswordModalOpen,
      isPasswordResetConfirmationModalOpen,
      isCheckoutModalOpen,
    },
    dispatchModalState,
  ] = useReducer(reducer, INITIAL_STATE)

  const showHighlightMessage = useMemo(
    () => hasHighlightMessage && !!highlightLink && !pathname.includes(highlightLink),
    [hasHighlightMessage, highlightLink, pathname]
  )

  useEffect(
    () => {
      if (!baseApp) {
        dispatch(
          getPrismicInformation({
            documentType: 'base_app',
          })
        )
      }
    },
    [baseApp, dispatch]
  )

  const onCloseModal = useCallback(
    () => {
      dispatchModalState({
        type: ON_CLOSE_MODAL,
      })
      if (Object.values(AUTHENTICATION_URLS).some(value => pathname.includes(value))) {
        navigate('/')
      } else {
        navigate(pathname)
      }
    },
    [pathname]
  )

  const showPrismicAlert = useCallback(
    () => {
      const message = cookies.load('alertMessage', { path: '/' })
      const currentPrismicMessage =
        baseApp?.highlights?.highlightWarning || baseApp?.highlights?.highlightText

      setIsAlertMessageVisible(message !== currentPrismicMessage)
    },
    [baseApp]
  )

  useEffect(
    () => {
      showPrismicAlert()
    },
    [showPrismicAlert]
  )

  const onDismissHighlightMessage = useCallback(
    () => {
      dispatch(
        dismissHighlightMessage(
          baseApp?.highlights?.highlightWarning || baseApp.highlights.highlightText
        )
      )
      showPrismicAlert()
    },
    [baseApp, dispatch, showPrismicAlert]
  )

  useEffect(
    () => {
      const payload = getCurrentModalThroughUrl(pathname, search)
      dispatchModalState({
        type: TOGGLE_MODAL,
        payload,
      })
    },
    [pathname, search]
  )

  return (
    <div className={styles.body}>
      <div className={styles['header-wrapper']}>
        <Header prismic={baseApp?.header} />
        {showHighlightMessage &&
          isAlertMessageVisible && (
            <HighlightMessage prismic={baseApp.highlights} onClose={onDismissHighlightMessage} />
          )}
      </div>
      <main>{children}</main>
      <Footer prismic={baseApp?.footer} />
      {isLoginModalOpen && <LoginModal isOpen={isLoginModalOpen} onClose={onCloseModal} />}
      {isSignUpModalOpen && <SignUpModal isOpen={isSignUpModalOpen} onClose={onCloseModal} />}
      {isForgotPasswordModalOpen && (
        <ForgotPasswordModal isOpen={isForgotPasswordModalOpen} onClose={onCloseModal} />
      )}
      {isPasswordResetConfirmationModalOpen && (
        <PasswordResetConfirmationModal
          isOpen={isPasswordResetConfirmationModalOpen}
          onClose={onCloseModal}
        />
      )}
      {isCheckoutModalOpen && <CheckoutModal isOpen={isCheckoutModalOpen} onClose={onCloseModal} />}
    </div>
  )
}

BaseApp.propTypes = {
  children: PropTypes.node,
}

BaseApp.defaultProps = {
  children: <div />,
}

export default BaseApp
