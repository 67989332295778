import cookies from 'react-cookies'
import { format, isValid, parseISO } from 'date-fns'
import isBefore from 'date-fns/isBefore'
import isAfter from 'date-fns/isAfter'

import { MINIMUM_PASSWORD_ERROR } from '_constants/authentication'
import { COURSE_TYPES_INFO } from '_constants/course'
import { COURSE_TAG_THEME } from '_components/course-tag'
import BookmarkFilledIcon from '_assets/icons/bookmark-filled-icon.svg'

import { IMAGE_TYPES } from './constants'

export const groupAssignmentBySection = (items, key) =>
  items.reduce(
    (acc, curr) => ({
      ...acc,
      [curr.assignment[key]]: [...(acc[curr.assignment[key]] || []), curr],
    }),
    {}
  )

export const queryParam = (location, param) => {
  const params = new URLSearchParams(location.search)
  return params.has(param) ? params.get(param) : null
}

export const getPage = query =>
  query && (query.includes('?page=') || query.includes('&page='))
    ? Number(query.match(/[?&]page=([^&#]*)/)[1])
    : undefined

export const changeUrl = ({ channelId = null, directMessage = null }) => {
  if (window.history) {
    let param = null

    if (channelId) {
      param = `?channelId=${channelId}`
    }

    if (directMessage) {
      param = `?directMessage=${directMessage}`
    }

    window.history.pushState(null, '', param || window.location.href.split('?')[0])
  }
}

export const toArray = data =>
  data.reduce((acc, curr) => {
    acc.push(curr.toJS())
    return acc
  }, [])

const testQueryParam = (query, param) => {
  const result = query.match(`[?&]${param}=([^&#]*)`)
  return result ? result[1] : undefined
}

// ZOOM HELPERS
export const verifyZoom = query => {
  const zoomStateVerification = cookies.load('lightyear-zoom-state', { path: '/' })
  cookies.remove('lightyear-zoom-state', { path: '/' })

  if (!zoomStateVerification) {
    return false
  }

  const zoomReturnedState = testQueryParam(query, 'state')

  return zoomStateVerification === zoomReturnedState
}

export const parseZoomCode = query => testQueryParam(query, 'code')

export const validateEmail = email => {
  const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email.toLowerCase())
}

export const validatePassword = password => password.length >= 8

export const getPresetPasswordErrors = (password, passwordConfirmation) => {
  let error = {
    passwordError: '',
    passwordConfirmationError: '',
  }

  if (!validatePassword(password)) {
    error = {
      ...error,
      passwordError: MINIMUM_PASSWORD_ERROR,
    }
  }

  if (!validatePassword(passwordConfirmation)) {
    error = {
      ...error,
      passwordConfirmationError: MINIMUM_PASSWORD_ERROR,
    }
  }

  if (password !== passwordConfirmation) {
    error = {
      ...error,
      passwordConfirmationError: 'Passwords must match',
    }
  }

  return error
}

export const isExternalUrl = url => url.includes('http')

export const formatUrl = url => {
  if (!url) {
    return ''
  }

  if (url?.[0] === '/' || url?.[0] === '?') {
    return url
  }

  if (url.includes('lightyear.co')) {
    return url.split('lightyear.co')[1]
  }

  if (url.includes('http')) {
    return url
  }

  return `http://${url}`
}

export const compareClassDates = (a, b) => {
  if (a.get('completedDate') === null) {
    return 0
  }

  if (b.get('completedDate') === null) {
    return 0
  }

  if (isAfter(new Date(a.get('completedDate')), new Date(b.get('completedDate')))) {
    return -1
  }

  if (isBefore(new Date(a.get('completedDate')), new Date(b.get('completedDate')))) {
    return 1
  }

  return 0
}

export const isReverseLine = value => !!(value % 2)

export const formatDate = (date, dateFormat = 'MM/dd/yyyy') => {
  let currentDate

  if (typeof date === 'string') {
    currentDate = new Date(parseISO(date))
  }

  if (!isValid(currentDate)) {
    return ''
  }

  const formattedDate = format(currentDate, dateFormat)
  return formattedDate
}

export const sortByMainCoach = coach => {
  if (coach.isMainCoach) {
    return -1
  }

  if (!coach.isMainCoach) {
    return 1
  }

  return 0
}

export const isImageType = file => IMAGE_TYPES.test(file)

export const sliceGetAvatarAndText = (users, maxRange, usersCount) => {
  // const filteredUsers = users.filter(user => user.id !==)
  const slicedUsers = users.slice(0, maxRange)

  const listUsersName = slicedUsers.map((user, index) => {
    if (index !== slicedUsers.size - 1) {
      return index !== usersCount - 2 && usersCount > 2 ? `${user.name}, ` : `${user.name} and `
    }

    return user.name
  })

  const formattedText = `${listUsersName?.join('')} ${
    usersCount > 2 ? ` and ${usersCount - 2} more` : ''
  }`

  return [slicedUsers, formattedText]
}

export const formattedSections = currentClass => {
  const secs = groupAssignmentBySection(currentClass.assignments, 'sectionName')

  return Object.keys(secs).reduce((acc, curr, index) => {
    acc.push({
      title: String(curr),
      order: index,
      assignments: secs[curr],
    })

    return acc
  }, [])
}

export const formatCourseUrl = courseSlug => {
  let formattedCourseUrl = courseSlug.replace(/([A-Z]+)/g, ' $1')
  formattedCourseUrl = formattedCourseUrl.charAt(0).toUpperCase() + formattedCourseUrl.slice(1)
  return formattedCourseUrl
}

export const handleCourseTagThemeAndIcon = (listSize, position) => {
  if (listSize === 1) {
    return {
      theme: COURSE_TAG_THEME.LIGHT,
    }
  }

  return position === 0
    ? {
        theme: COURSE_TAG_THEME.MALIBU,
        icon: BookmarkFilledIcon,
      }
    : {
        theme: COURSE_TAG_THEME.LIGHT,
      }
}

export const getCourseByCategory = category => {
  const course = Object.values(COURSE_TYPES_INFO).find(
    currentCourse => currentCourse.name === category
  )
  return course
}

export const creditCardNameValidation = value =>
  /\d/.test(value) ? 'Enter a valid card holder name.' : ''

export const formatClassCalls = sections => {
  const assignmentCalls = sections.reduce((acc, currentSection) => {
    const assignments = currentSection.get('assignments')?.filter(assignment => assignment.get('type') === 'VIDEO')

    if (assignments.size) {
      return [...acc, ...assignments]
    }

    return acc
  }, [])

  const orderByNewest = assignmentCalls.sort((a, b) => {
    if (b.getIn(['meeting', 'startTime']) > a.getIn(['meeting', 'startTime'])) {
      return -1
    }

    if (b.getIn(['meeting', 'startTime']) < a.getIn(['meeting', 'startTime'])) {
      return 1
    }

    return 0
  })

  return orderByNewest
}

export const checkIfPasswordInputsAreValid = ({
  confirmPassword,
  oldPassword,
  newPassword,
  callback,
}) => {
  const PASSWORD_MINIMUM_LENGTH = 8
  const passwordErrors = {
    confirm: '',
    new: '',
    old: '',
  }

  if (oldPassword.length < PASSWORD_MINIMUM_LENGTH) {
    passwordErrors.old = 'Your old password must be at least 8 characters.'
  }

  if (newPassword.length < PASSWORD_MINIMUM_LENGTH) {
    passwordErrors.new = 'Your new password must be at least 8 characters.'
  }

  if (confirmPassword.length < PASSWORD_MINIMUM_LENGTH) {
    passwordErrors.confirm = 'Your password must have a minimum of 8 characters.'
  }

  if (newPassword !== confirmPassword || confirmPassword === '') {
    passwordErrors.new = 'Your password confirmation must match.'
    passwordErrors.confirm = 'Your password confirmation must match.'
  }

  if (callback) {
    callback(passwordErrors)
  }
  return Object.values(passwordErrors).every(value => value.length === 0)
}

export const checkIfNameIsValid = (name, callback) => {
  if (name.split(' ').length < 2) {
    callback({ errorMessage: 'Please enter your full name' })
    return false
  }
  callback({ errorMessage: '' })
  return true
}
