import { List } from 'immutable'

import Model from './model'

class Assignment extends Model({
  id: undefined,
  name: undefined,
  order: undefined,
  assignment: undefined,
  completed: undefined,
  attachments: undefined,
  questions: List(),
  posts: List(),
}) {}

export { Assignment as default }
