import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const Checkbox = ({
  id,
  className,
  label,
  name,
  onChange,
  value,
  labelClassName,
  hiddenLabel,
  ...restProps
}) => (
  <div className={classnames(styles.wrapper, className)}>
    <input
      onChange={onChange}
      id={`checkbox-${id}-${label}`}
      name={name}
      type="checkbox"
      value={value}
      {...restProps}
      className={styles.input}
    />
    {/* eslint-disable-next-line jsx-a11y/label-has-for */}
    <label
      className={classnames(styles.label, labelClassName, {
        [styles['hidden-label']]: hiddenLabel,
      })}
      htmlFor={`checkbox-${id}-${label}`}
    >
      <span className={styles['label-content']}>{label}</span>
    </label>
  </div>
)

Checkbox.propTypes = {
  className: PropTypes.string,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  value: PropTypes.string,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  hiddenLabel: PropTypes.bool,
  labelClassName: PropTypes.string,
}

Checkbox.defaultProps = {
  className: '',
  value: '',
  name: '',
  onChange: () => {},
  hiddenLabel: false,
  labelClassName: '',
}
export default React.memo(Checkbox)
