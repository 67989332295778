export const INITIAL_STATE = {
  oldPassword: '',
  newPassword: '',
  confirmPassword: '',
  passwordErrors: {
    confirm: '',
    new: '',
    old: '',
  },
}

export const UPDATE_STATE = 'UPDATE_STATE'
export const RESET_STATE = 'RESET_STATE'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE:
      return { ...state, ...action.payload }
    case RESET_STATE:
      return { ...INITIAL_STATE }
    default:
      return state
  }
}
