import React, { useMemo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import Typist from 'react-typist'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, navigate } from '@reach/router'

import SectionTitle from '_components/landing-components/section-title'
import { prismicTitlePropType } from '_utils/proptypes'
import { FLAG_NEW_LANDING_PAGES } from '_config/environment'
import Modal from '_components/modal'
import ModalCard from '_components/modal/modal-card'
import ScrollableButton from '_views/landing/scrollable-button'
import usePrevious from '_hooks/use-previous'
import useScroll from '_hooks/use-window-scroll'
import {
  getUserSelector,
  isStartingMembershipSelector,
  startMembershipErrorSelector,
  isGettingUserData,
  isLoginLoadingSelector,
  loginErrorSelector,
  isSignUpLoadingSelector,
  signUpErrorSelector,
} from '_modules/user/selectors'
import { getUser } from '_modules/user/actions'
import { listCards } from '_modules/card/actions'
import RocketLoader from '_components/rocket-loader'
import MembershipsCheckoutModal from '_components/memberships-checkout-modal'
import CardButton, { CardButtonSize } from '_components/card-button'

import styles from './styles.css'

const membershipOptions = {
  monthly: {
    type: 'monthly',
    price: 30,
    defaultPrice: 30,
  },
  yearly: {
    type: 'yearly',
    price: 300,
    defaultPrice: 360,
  },
}

const MembershipHeroSection = ({ slice, id }) => {
  const location = useLocation()
  const dispatch = useDispatch()
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [isCheckoutModalOpen, setCheckoutModalOpen] = useState(false)
  const [isScrollingUp, setIsScrollingUp] = useState(false)
  const [selectedMembership, setSelectedMembership] = useState(membershipOptions.monthly)
  const scroll = useScroll()
  const lastScroll = usePrevious(scroll)

  const isLoading = useSelector(isStartingMembershipSelector)
  const user = useSelector(getUserSelector)

  const isStartingMembershipPurchase = useSelector(isStartingMembershipSelector)
  const wasStartingMembershipPurchase = usePrevious(isStartingMembershipPurchase)
  const membershipPurchaseError = useSelector(startMembershipErrorSelector)

  const isLoggingIn = useSelector(isLoginLoadingSelector)
  const wasLoggingIn = usePrevious(isLoggingIn)
  const hasLoginError = useSelector(loginErrorSelector)

  const isSigningUp = useSelector(isSignUpLoadingSelector)
  const wasSigningUp = usePrevious(isSigningUp)
  const hasSignUpError = useSelector(signUpErrorSelector)

  const [isAlreadyAMemberModalOpen, setAlreadyAMemberModalOpen] = useState(
    user.authToken && !(user.subscriptionIsCanceling || !user.subscriptionStart)
  )
  const [successfullyPurchasedMembership, setSuccessfullyPurchasedMembership] = useState(false)

  const isGettingUserDataNow = useSelector(isGettingUserData)
  const wasGettingUserData = usePrevious(isGettingUserDataNow)

  const navigateToDashboard = useCallback(() => {
    navigate(`/dashboard`)
  }, [])

  // After login effect
  useEffect(
    () => {
      if (
        wasLoggingIn &&
        !isLoggingIn &&
        !hasLoginError.size &&
        (user.subscriptionIsCanceling || !user.subscriptionStart)
      ) {
        setIsModalOpen(true)
      }
    },
    [
      wasLoggingIn,
      isLoggingIn,
      hasLoginError.size,
      user.subscriptionIsCanceling,
      user.subscriptionStart,
    ]
  )

  // After signup effect
  useEffect(
    () => {
      if (wasSigningUp && !isSigningUp && !hasSignUpError.size) {
        setIsModalOpen(true)
      }
    },
    [wasSigningUp, isSigningUp, hasSignUpError.size]
  )

  useEffect(() => {
    if (successfullyPurchasedMembership && wasGettingUserData) {
      navigateToDashboard()
    }
  })

  useEffect(
    () => {
      const membershipPurchaseFulfilled =
        wasStartingMembershipPurchase &&
        !isStartingMembershipPurchase &&
        !membershipPurchaseError.size

      if (membershipPurchaseFulfilled) {
        dispatch(getUser())
        setSuccessfullyPurchasedMembership(true)
        setCheckoutModalOpen(false)
        setAlreadyAMemberModalOpen(false)
      }
    },
    [
      navigateToDashboard,
      wasStartingMembershipPurchase,
      isStartingMembershipPurchase,
      membershipPurchaseError,
      dispatch,
    ]
  )

  useEffect(
    () => {
      if (lastScroll < scroll) {
        setIsScrollingUp(false)
      }

      if (scroll < lastScroll) {
        setIsScrollingUp(true)
      }
    },
    [lastScroll, scroll]
  )

  useEffect(
    () => {
      if (user.authToken) {
        dispatch(getUser())
      }
    },
    [user.authToken, dispatch]
  )

  useEffect(
    () => {
      dispatch(listCards())
    },
    [dispatch]
  )

  const [currentIndex, setCurrentIndex] = useState(0)

  const nextIndex = useMemo(
    () => {
      const listLength = slice.items?.length

      return currentIndex + 1 < listLength ? currentIndex + 1 : 0
    },
    [currentIndex, slice.items]
  )

  useEffect(
    () => {
      const interval = setInterval(() => {
        setCurrentIndex(nextIndex)
      }, 5000)
      return () => clearInterval(interval)
    },
    [nextIndex]
  )

  const backgroundStyles = useMemo(
    () => {
      if (slice.backgroundImage?.thumbnail) {
        return {
          backgroundImage: `url(${slice.backgroundImage.thumbnail})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }
      }

      if (slice?.backgroundColor) {
        return {
          background: slice.backgroundColor,
        }
      }
      return {}
    },
    [slice]
  )

  const renderItems = useMemo(
    () => {
      if (slice.items.length === 1) {
        return <p className={styles.item}>{slice.items?.[0]} </p>
      }

      return slice.items.map(
        (item, index) =>
          index === currentIndex && (
            <Typist cursor={{ show: false }} key={item}>
              <p className={styles.item}>{item} </p>
            </Typist>
          )
      )
    },
    [currentIndex, slice]
  )

  const openModal = useCallback(
    () => {
      if (user.subscriptionIsCanceling || !user.subscriptionStart) {
        setIsModalOpen(true)
        return
      }
      setAlreadyAMemberModalOpen(true)
    },
    [user.subscriptionIsCanceling, user.subscriptionStart]
  )

  const closeModal = useCallback(() => {
    setIsModalOpen(false)
  }, [])

  const openCheckoutModal = useCallback(() => {
    setIsModalOpen(false)
    setCheckoutModalOpen(true)
  }, [])

  const closeCheckoutModal = useCallback(() => {
    setCheckoutModalOpen(false)
  }, [])

  const onBackToDashboard = useCallback(
    () => {
      navigateToDashboard()
    },
    [navigateToDashboard]
  )

  const onBackToMemberships = useCallback(() => {
    setAlreadyAMemberModalOpen(false)
    navigate(`/memberships`)
  }, [])

  const onSelectMembership = useCallback(
    event => {
      setSelectedMembership(membershipOptions[event.currentTarget.id])
    },
    [setSelectedMembership]
  )

  // --------------------------------------------------------------------------------
  const renderActionButton = useMemo(
    () => {
      if (user.authToken) {
        if (successfullyPurchasedMembership) {
          return <RocketLoader text="Purchasing membership" />
        }

        return (
          <ScrollableButton
            text="Start Your Membership!"
            onClick={openModal}
            className={classnames(styles['scrollable-button'], {
              [styles['scrollable-button-fixed']]: scroll > 20,
              [styles['scroll-animation']]: !isScrollingUp,
            })}
          />
        )
      }

      return (
        <ScrollableButton
          text="Start Your Membership!"
          url={`${location.pathname}?sign-up`}
          newWindow={false}
          className={classnames(styles['scrollable-button'], {
            [styles['scrollable-button-fixed']]: scroll > 20,
            [styles['scroll-animation']]: !isScrollingUp,
          })}
        />
      )
    },
    [
      user.authToken,
      location.pathname,
      scroll,
      isScrollingUp,
      successfullyPurchasedMembership,
      openModal,
    ]
  )

  return (
    <section className={classnames(styles.section)} style={backgroundStyles} id={id}>
      <div className={styles.content}>
        {slice.sectionTitle && <SectionTitle title={slice.sectionTitle} />}
        {slice.items?.length && (
          <div
            className={classnames(styles['item-container'], {
              [styles['single-item-container']]: slice.items.length === 1,
            })}
          >
            {renderItems}
          </div>
        )}
        {slice.sectionText && (
          <p
            className={classnames(styles['section-text'], {
              [styles['section-text-gray']]: FLAG_NEW_LANDING_PAGES,
            })}
          >
            {slice.sectionText}
          </p>
        )}

        {successfullyPurchasedMembership && <RocketLoader text="Purchasing membership" />}

        {isAlreadyAMemberModalOpen && (
          <Modal isOpen={isAlreadyAMemberModalOpen} onClose={onBackToMemberships}>
            <ModalCard
              isLoading={isLoading}
              isOpen={isAlreadyAMemberModalOpen}
              onClose={onBackToMemberships}
              onCloseButton={onBackToMemberships}
              title={isLoading ? '' : 'Already a member.'}
              buttonLabel="Go to Dashboard"
              onButtonClick={onBackToDashboard}
              className={styles['modal-button']}
            >
              <h3>Woohoo! You already have an active Lightyear Membership.</h3>
            </ModalCard>
          </Modal>
        )}

        {renderActionButton}
        {isCheckoutModalOpen && (
          <MembershipsCheckoutModal
            membership={selectedMembership}
            isOpen={isModalOpen}
            onClose={closeCheckoutModal}
          />
        )}
        {isModalOpen && (
          <Modal isOpen={isModalOpen} onClose={closeModal}>
            <ModalCard
              isLoading={isLoading}
              isOpen={isModalOpen}
              onClose={closeModal}
              onCloseButton={closeModal}
              title={isLoading ? '' : 'Select a membership option'}
              buttonLabel="Next"
              onButtonClick={openCheckoutModal}
              className={styles['modal-button']}
            >
              {isLoading && <RocketLoader text="Purchasing membership" />}

              {!isLoading && (
                <>
                  <CardButton
                    className={
                      selectedMembership.type === membershipOptions.monthly.type
                        ? styles['membership-card-active']
                        : styles['membership-card']
                    }
                    size={CardButtonSize.LARGE}
                    onClick={onSelectMembership}
                    id={membershipOptions.monthly.type}
                  >
                    <span className={styles['button-card-title']}>Monthly membership</span>
                    <span className={styles['button-card-content']}>
                      {membershipOptions.monthly.defaultPrice ===
                      membershipOptions.monthly.price ? (
                        <span className={styles['membership-description']}>
                          $<strong>{membershipOptions.monthly.price}</strong> /month
                        </span>
                      ) : (
                        <span className={styles['membership-description']}>
                          <del>${membershipOptions.monthly.defaultPrice}</del> $
                          <strong>{membershipOptions.monthly.price}</strong>
                          (save $
                          {membershipOptions.monthly.defaultPrice - membershipOptions.monthly.price}
                          !)
                        </span>
                      )}
                    </span>
                  </CardButton>

                  <CardButton
                    className={
                      selectedMembership.type === membershipOptions.yearly.type
                        ? styles['membership-card-active']
                        : styles['membership-card']
                    }
                    size={CardButtonSize.LARGE}
                    onClick={onSelectMembership}
                    id={membershipOptions.yearly.type}
                  >
                    <span className={styles['button-card-title']}>Yearly membership</span>
                    <span className={styles['button-card-content']}>
                      {membershipOptions.yearly.defaultPrice === membershipOptions.yearly.price ? (
                        <p className={styles['membership-description']}>
                          $<strong>{membershipOptions.yearly.price}</strong> /year
                        </p>
                      ) : (
                        <p className={styles['membership-description']}>
                          <del>${membershipOptions.yearly.defaultPrice}</del> $
                          <strong>{membershipOptions.yearly.price}</strong>
                          (save $
                          {membershipOptions.yearly.defaultPrice - membershipOptions.yearly.price}!)
                        </p>
                      )}
                    </span>
                  </CardButton>
                </>
              )}
            </ModalCard>
          </Modal>
        )}
      </div>
    </section>
  )
}

MembershipHeroSection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    actionButton: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
    actionButtonScrollToSlice: PropTypes.shape({
      slice: PropTypes.string,
      label: PropTypes.string,
    }),
    items: PropTypes.arrayOf(PropTypes.string),
    sectionText: PropTypes.string,
    backgroundImage: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
    backgroundColor: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
  courseSlug: PropTypes.string,
}

MembershipHeroSection.defaultProps = {
  courseSlug: '',
}
export default React.memo(MembershipHeroSection)
