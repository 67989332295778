import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import ArrowDownIcon from '_assets/icons/angle-down.svg'
import useToggle from '_hooks/use-modal'

import styles from './styles.css'

const AccordionElement = ({ sectionNumber, sectionTitle, elementContent }) => {
  const [isContentExpanded, onToggleContent] = useToggle()

  const expandableProps = useMemo(
    () => ({
      ariaLabel: isContentExpanded ? 'contract section' : 'expand section',
      icon: isContentExpanded ? 'rotate(180deg)' : '',
    }),
    [isContentExpanded]
  )

  return (
    <article className={styles['section-container']}>
      <div className={styles['section-wrapper']}>
        {sectionNumber && (
          <div className={styles.session}>
            <span>Session {sectionNumber}</span>
          </div>
        )}

        <h1 className={styles.title}>{sectionTitle}</h1>
        {elementContent.length && (
          <p className={styles.description}>
            {elementContent.length} assignment{elementContent.length > 1 ? 's' : ''}
          </p>
        )}

        <button
          className={styles.button}
          aria-label={expandableProps.ariaLabel}
          onClick={onToggleContent}
        >
          <svg
            className={styles['button-icon']}
            viewBox={ArrowDownIcon.viewBox}
            style={{ transform: expandableProps.icon }}
          >
            <use xlinkHref={`#${ArrowDownIcon.id}`} />
          </svg>
        </button>
      </div>

      {isContentExpanded && <div className={styles.content}>{elementContent}</div>}
    </article>
  )
}

AccordionElement.propTypes = {
  sectionNumber: PropTypes.number,
  sectionTitle: PropTypes.string.isRequired,
  elementContent: PropTypes.arrayOf(PropTypes.shape({})),
}

AccordionElement.defaultProps = {
  sectionNumber: null,
  elementContent: [],
}

export default React.memo(AccordionElement)
