import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react'
import { useLocation, Link, navigate } from '@reach/router'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import GetInTouchModal from '_components/landing-components/get-in-touch-modal'
import LightyearLogo from '_assets/full-logo.svg'
import useWindowSize from '_hooks/use-window-size'
import Button, { ButtonTheme, ButtonSize } from '_components/landing-components/button'
import useScroll from '_hooks/use-window-scroll'
import MenuIcon from '_assets/icons/ic-menu-24-px.svg'
import CloseIcon from '_assets/icons/close.svg'
import ArrowRightIcon from '_assets/icons/arrow-right-24-px.svg'
import useBodyOverflow from '_hooks/use-body-overflow'
import { onMouseDown } from '_utils/aria'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import {
  getUserSelector,
  isLoginLoadingSelector,
  loginErrorSelector,
  isSignUpLoadingSelector,
  signUpErrorSelector,
  userBelongsToACompanySelector,
  hasCompletedOnboardingSelector,
} from '_modules/user/selectors'
import { getUser, logout } from '_modules/user/actions'
import ArrowIcon from '_assets/icons/angle-down.svg'
import { SUBSCRIPTION_STATUS } from '_utils/constants'
import DefaultAvatar from '_assets/icons/empty-photo.svg'
import usePrevious from '_hooks/use-previous'
import SearchIcon from '_assets/icons/search.svg'
import useModal from '_hooks/use-modal'
import Svg from '_components/svg'
import IconButton from '_components/icon-button'

import styles from './styles.css'
import SearchHeader from './search-header'

const HEADER_SIZE = 65

const BUTTON_LINK_STYLE = { theme: ButtonTheme.SIMPLE_LINK, size: ButtonSize.MINIMAL }
const BUTTON_GREEN_THEME = {
  theme: ButtonTheme.TRANSPARENT_BACKGROUND_GREEN_TEXT,
  size: ButtonSize.MINIMAL,
}

const ALL_CLASSES_PATH = '/classes/search'

const ACTIVATE_SUBSCRIPTION_STATE = { billing: true }
const Header = ({ prismic }) => {
  const user = useSelector(getUserSelector)
  const { pathname } = useLocation()
  const userBelongsToACompany = useSelector(userBelongsToACompanySelector)
  const userCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)
  const isLoginLoading = useSelector(isLoginLoadingSelector)
  const wasLoginLoading = usePrevious(isLoginLoading)
  const loginError = useSelector(loginErrorSelector)
  const isSignUpLoading = useSelector(isSignUpLoadingSelector)
  const wasSignUpLoading = usePrevious(isSignUpLoading)
  const signUpError = useSelector(signUpErrorSelector)
  const [isGetInTouchModalOpen, onToggleGetInTouchModal] = useModal()
  const [isSearchBarOpen, onToggleSearchBar, setSearchBarOpen] = useModal(true)
  const ref = useRef()
  const scroll = useScroll()
  const location = useLocation()
  const { width, height, isMobile, isMedium, isDesktop } = useWindowSize()
  const showSearchBar = useMemo(
    () => pathname !== ALL_CLASSES_PATH || (pathname === ALL_CLASSES_PATH && isMobile),
    [isMobile, pathname]
  )

  const dispatch = useDispatch()
  const hasUserFinishedOnboarding = user.get('completedOnboarding')
  // TODO: Update this to the correct value
  const userHasInactiveSubscription =
    SUBSCRIPTION_STATUS.CANCELED === user.get('subscriptionStatus') &&
    user.get('subscriptionStatus') &&
    !user.get('skipSubscription') &&
    !user.get('belongsToACompany')

  const [hasScrolled, setHasScrolled] = useState(false)
  const [isResponsiveMenuOpen, setResponsiveMenuOpen] = useState(false)
  const [isUserMenuOpen, setUserMenuStatus] = useState(false)

  useBodyOverflow(isResponsiveMenuOpen)

  useEffect(
    () => {
      if (user.authToken) {
        dispatch(getUser())
      }
    },
    [dispatch, user.authToken]
  )

  useEffect(
    () => {
      setHasScrolled(scroll !== 0)
    },
    [scroll]
  )

  useEffect(
    () => {
      if (width >= MOBILE_THRESHOLD + 1) {
        setResponsiveMenuOpen(false)
      } else {
        setUserMenuStatus(false)
      }
    },
    [width]
  )

  useEffect(
    () => {
      const loginSuccessful = wasLoginLoading && !isLoginLoading && !loginError.size
      const signUpSuccessful = wasSignUpLoading && !isSignUpLoading && !signUpError

      if (loginSuccessful || signUpSuccessful) {
        setResponsiveMenuOpen(false)
        setUserMenuStatus(false)
      }
    },
    [
      isLoginLoading,
      isSignUpLoading,
      loginError.size,
      signUpError,
      wasLoginLoading,
      wasSignUpLoading,
    ]
  )
  const onResponsiveMenuClick = useCallback(() => {
    setResponsiveMenuOpen(prevResponsiveMenuOpen => !prevResponsiveMenuOpen)
  }, [])

  const hasUserMenu = useMemo(() => width >= MOBILE_THRESHOLD + 1, [width])

  const handleButtonClick = useCallback(
    () => {
      if (hasUserMenu) {
        setUserMenuStatus(prevState => !prevState)
      }
    },
    [hasUserMenu]
  )

  const handleOutsideClick = useCallback(event => {
    if (!ref.current || ref.current.contains(event.target)) {
      return
    }

    setUserMenuStatus(false)
  }, [])

  const handleLogoutClick = useCallback(
    () => {
      setUserMenuStatus(false)
      setResponsiveMenuOpen(false)
      dispatch(logout())
    },
    [dispatch]
  )

  const onFetchClass = useCallback(value => {
    navigate('/classes/search', { state: { search: value } })
  }, [])

  useEffect(
    () => {
      if (isUserMenuOpen) {
        document.addEventListener('click', handleOutsideClick, false)
      }

      return () => {
        document.removeEventListener('click', handleOutsideClick, false)
      }
    },
    [handleOutsideClick, isUserMenuOpen]
  )

  // Responsive menu inline styles
  const responsiveMenuStyles = useMemo(
    () => {
      if (width <= MOBILE_THRESHOLD + 1) {
        // Mobile
        return {
          minHeight: height - HEADER_SIZE,
        }
      }

      // Tablet
      return {}
    },
    [height, width]
  )

  // Display hamburger menu
  const isDisplayingHamburgerMenu = useMemo(
    () => {
      if (width <= MOBILE_THRESHOLD) {
        // Mobile
        return true
      }

      // Tablet
      return prismic?.links?.length > 0
    },
    [prismic, width]
  )

  const isMember = useMemo(
    () =>
      (user.subscriptionStatus !== SUBSCRIPTION_STATUS.CANCELED ||
        user.skipSubscription ||
        user.belongsToACompany ||
        user.completedOnboarding) &&
      !user.suspended,

    [
      user.belongsToACompany,
      user.completedOnboarding,
      user.skipSubscription,
      user.subscriptionStatus,
      user.suspended,
    ]
  )

  const renderMenuOptions = useMemo(
    () => {
      if (!hasUserFinishedOnboarding && !user.skipSubscription) {
        return (
          <Button
            url={
              userBelongsToACompany && !userCompletedOnboarding ? '/onboarding' : '/explore-courses'
            }
            className={styles.button}
            {...isMobile && BUTTON_LINK_STYLE}
          >
            Discover Courses
          </Button>
        )
      }

      if (userHasInactiveSubscription && !user.skipSubscription) {
        return (
          <>
            <Button
              url="/account"
              state={ACTIVATE_SUBSCRIPTION_STATE}
              className={styles.button}
              {...isMobile && BUTTON_LINK_STYLE}
            >
              REACTIVATE MEMBERSHIP
            </Button>
            <Button
              url={
                userBelongsToACompany && !userCompletedOnboarding
                  ? '/onboarding'
                  : '/explore-courses'
              }
              {...(isMobile ? BUTTON_LINK_STYLE : BUTTON_GREEN_THEME)}
            >
              DISCOVER COURSES
            </Button>
          </>
        )
      }

      return (
        <>
          <Button url="/dashboard" className={styles.button} {...isMobile && BUTTON_LINK_STYLE}>
            Go to Dashboard
          </Button>
          <Button
            url={`/user/${user.username}`}
            {...(isMobile ? BUTTON_LINK_STYLE : BUTTON_GREEN_THEME)}
          >
            View my future
          </Button>
        </>
      )
    },
    [
      hasUserFinishedOnboarding,
      isMobile,
      user.skipSubscription,
      user.username,
      userBelongsToACompany,
      userCompletedOnboarding,
      userHasInactiveSubscription,
    ]
  )

  const renderModal = useMemo(
    () => (
        <div
          className={classnames(styles['user-modal'], {
            [styles['inactive-user-modal']]: userHasInactiveSubscription,
          })}
        >
          <div className={styles['top-content']}>{renderMenuOptions}</div>
          <div className={styles['bottom-content']}>
            {hasUserFinishedOnboarding && (
              <>
                {userHasInactiveSubscription && (
                  <Button url="/courses" {...BUTTON_LINK_STYLE}>
                    View my classes
                  </Button>
                )}
                <Button url="/account" {...BUTTON_LINK_STYLE}>
                  Account settings
                </Button>
              </>
            )}
            <Button {...BUTTON_LINK_STYLE} onClick={handleLogoutClick}>
              Log out
            </Button>
          </div>
        </div>
      ),
    [handleLogoutClick, hasUserFinishedOnboarding, renderMenuOptions, userHasInactiveSubscription]
  )

  const renderUserPictureAndName = useMemo(
    () => (
        <div className={styles['user-wrapper']}>
          {user.photo ? (
            <img
              src={user.photo}
              alt="User Avatar"
              className={classnames(styles.picture, { [styles.member]: isMember })}
            />
          ) : (
            <svg
              aria-hidden="true"
              viewBox={DefaultAvatar.viewBox}
              className={classnames(styles.picture, { [styles.member]: isMember })}
            >
              <use xlinkHref={`#${DefaultAvatar.id}`} />
            </svg>
          )}
          <div className={styles['name-wrapper']}>
            <p className={styles.name}>{user.name || user.email?.split('@')[0]}</p>
            {isMember &&
              hasUserFinishedOnboarding &&
              !userHasInactiveSubscription && <p className={styles.membership}>Member</p>}
          </div>
          <svg className={styles.arrow} viewBox={ArrowIcon.viewBox}>
            <use xlinkHref={`#${ArrowIcon.id}`} />
          </svg>
        </div>
      ),
    [
      hasUserFinishedOnboarding,
      isMember,
      user.email,
      user.name,
      user.photo,
      userHasInactiveSubscription,
    ]
  )

  const renderUserMenu = useMemo(
    () => {
      if (user.authToken) {
        return (
          <div className={styles['user-details']} ref={ref}>
            <div className={classnames(styles['vertical-line'], styles['left-side'])} />
            <button
              type="button"
              className={classnames(styles['action-button'], {
                [styles.open]: isUserMenuOpen,
              })}
              onClick={handleButtonClick}
              onMouseDown={onMouseDown}
            >
              {renderUserPictureAndName}
            </button>
            {isUserMenuOpen && renderModal}
            {isDisplayingHamburgerMenu && (
              <div className={classnames(styles['vertical-line'], styles['right-side'])} />
            )}
          </div>
        )
      }

      return (
        <div className={styles['user-actions']}>
          <Button
            url={`${location.pathname}?login`}
            className={classnames(styles.link, styles.centered, styles.login)}
          >
            Login
          </Button>
          <Button
            theme={ButtonTheme.MALIBU}
            url={`${location.pathname}?sign-up`}
            className={styles['sign-up']}
          >
            Sign up
          </Button>
        </div>
      )
    },
    [
      handleButtonClick,
      isDisplayingHamburgerMenu,
      isUserMenuOpen,
      location.pathname,
      renderModal,
      renderUserPictureAndName,
      user.authToken,
    ]
  )

  const renderMobileUserMenu = useMemo(
    () => {
      if (user.authToken) {
        return (
          <div className={styles['mobile-user-details']}>
            <div />
            {renderUserPictureAndName}
            {renderMenuOptions}
            {hasUserFinishedOnboarding && (
              <>
                {userHasInactiveSubscription && (
                  <Button url="/courses" {...BUTTON_LINK_STYLE}>
                    View my courses
                  </Button>
                )}
                <Button url="/account" {...BUTTON_LINK_STYLE}>
                  Account settings
                </Button>
              </>
            )}
            <Button onClick={handleLogoutClick} {...BUTTON_LINK_STYLE}>
              Log out
            </Button>
          </div>
        )
      }

      return (
        <div className={styles['user-actions']}>
          <Button
            url={`${location.pathname}?login`}
            className={classnames(styles.link, styles.centered, styles.login)}
          >
            Login
          </Button>
          <Button url={`${location.pathname}?sign-up`} className={styles['sign-up']}>
            Sign Up
          </Button>
        </div>
      )
    },
    [
      handleLogoutClick,
      hasUserFinishedOnboarding,
      location.pathname,
      renderMenuOptions,
      renderUserPictureAndName,
      user.authToken,
      userHasInactiveSubscription,
    ]
  )

  const hasPages = useMemo(() => prismic?.links?.length > 0, [prismic])

  useEffect(
    () => {
      if (isMobile || isMedium) {
        setSearchBarOpen(false)
      }

      if (isDesktop) {
        setSearchBarOpen(true)
      }
    },
    [isDesktop, isMedium, isMobile, setSearchBarOpen]
  )

  return (
    <header
      className={classnames(styles.header, {
        [styles.fixed]: hasScrolled || isResponsiveMenuOpen,
      })}
    >
      <nav
        className={classnames(styles.nav, {
          [styles.border]: isResponsiveMenuOpen,
          [styles['extra-column']]: hasPages,
        })}
      >
        <Link to="/" aria-label="Landing Page" className={styles.centered}>
          <svg className={styles.logo} viewBox={LightyearLogo.viewBox} aria-hidden="true">
            <use xlinkHref={`#${LightyearLogo.id}`} />
          </svg>
        </Link>

        {isSearchBarOpen &&
          showSearchBar && (
            <SearchHeader
              onFetchClass={onFetchClass}
              placeholder="Search for a class"
              isSearchBarOpen={isSearchBarOpen}
            />
          )}
        <div className={styles['menu-options']}>
          {prismic?.links?.map(
            link =>
              link.url ? (
                <Link
                  key={link.label}
                  to={link.url}
                  className={classnames(styles.link, styles.centered, styles.courses, {
                    [styles['current-view-link']]: location.pathname === link.url,
                  })}
                >
                  {link.label}
                </Link>
              ) : (
                <Button
                  className={classnames(
                    styles.link,
                    styles.centered,
                    styles.courses,
                    styles['business-modal-button']
                  )}
                  theme={ButtonTheme.TRANSPARENT}
                  key={link.label}
                  onClick={onToggleGetInTouchModal}
                >
                  {link.label}
                </Button>
              )
          )}
          <a
            className={classnames(styles.link, styles.centered, styles.courses)}
            href="https://blog.lightyear.co"
            target="_blank"
            rel="noopener noreferrer"
          >
            Blog
          </a>
        </div>
        {MOBILE_THRESHOLD < width && renderUserMenu}
        {!isResponsiveMenuOpen && (
          <IconButton
            icon={isSearchBarOpen ? CloseIcon : SearchIcon}
            className={classnames(styles['search-button'], {
              [styles['close-button']]: isSearchBarOpen,
            })}
            iconClassName={styles['search-bar-toggle-icon']}
            onClick={onToggleSearchBar}
          />
        )}
        {isDisplayingHamburgerMenu &&
          !isSearchBarOpen && (
            <button
              type="button"
              onClick={onResponsiveMenuClick}
              className={styles['menu-button']}
              aria-label="menu"
              onMouseDown={onMouseDown}
            >
              <svg
                className={styles['menu-icon']}
                viewBox={isResponsiveMenuOpen ? CloseIcon.viewBox : MenuIcon.viewBox}
                aria-hidden="true"
              >
                <use xlinkHref={`#${isResponsiveMenuOpen ? CloseIcon.id : MenuIcon.id}`} />
              </svg>
            </button>
          )}
      </nav>
      <nav
        className={classnames(styles['responsive-menu'], {
          [styles.closed]: !isResponsiveMenuOpen,
        })}
        style={responsiveMenuStyles}
      >
        <div className={styles['navigation-links']}>
          {prismic?.links?.map(
            link =>
              link.url ? (
                <Link
                  key={link.label}
                  to={link.url}
                  className={classnames(styles.link, {
                    [styles.selected]: location.pathname === link.url,
                  })}
                >
                  {link.label}
                  <Svg icon={ArrowRightIcon} className={styles['menu-icon']} />
                </Link>
              ) : (
                <Button
                  className={classnames(styles.link, styles['business-modal-button'])}
                  theme={ButtonTheme.TRANSPARENT}
                  key={link.label}
                  onClick={onToggleGetInTouchModal}
                  endAdornment={ArrowRightIcon}
                >
                  {link.label}
                </Button>
              )
          )}
          <a
            className={classnames(styles.link)}
            href="https://blog.lightyear.co"
            target="_blank"
            rel="noopener noreferrer"
          >
            BLOG
            <svg
              className={styles['menu-icon']}
              viewBox={ArrowRightIcon.viewBox}
              aria-hidden="true"
            >
              <use xlinkHref={`#${ArrowRightIcon.id}`} />
            </svg>
          </a>
        </div>
        {!hasUserMenu && renderMobileUserMenu}
        {isGetInTouchModalOpen && <GetInTouchModal isOpen onClose={onToggleGetInTouchModal} />}
      </nav>
    </header>
  )
}

Header.propTypes = {
  prismic: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string,
      })
    ),
  }),
}

Header.defaultProps = {
  prismic: {},
}

export default React.memo(Header)
