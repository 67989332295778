export const calculateRadius = (currentHalfSize, strokeWidthValue) =>
  currentHalfSize - strokeWidthValue * 0.5

export const calculateCircumference = radiusValue => 2 * Math.PI * radiusValue

export const calculateStrokeVal = (strokeCurrentValue, circumferenceValue) =>
  (strokeCurrentValue * circumferenceValue) / 100

export const calculateDashVal = (strokeVal, circumference) => `${strokeVal} ${circumference}`

export const getTrackStyle = strokeWidthValue => ({
  strokeWidth: strokeWidthValue,
})

export const getIndicatorStyle = (strokeWidthValue, strokeDasharray) => ({
  strokeWidth: strokeWidthValue,
  strokeDasharray,
})

export const calculateRotateVal = currentHalfSize =>
  `rotate(-90, ${currentHalfSize}, ${currentHalfSize})`
