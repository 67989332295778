import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import CourseCard from '_components/course-card'
import { courseShape } from '_utils/proptypes'
import { isGettingCompanyCoursesSelector } from '_modules/course/selectors'
import RocketLoader from '_components/rocket-loader'

import styles from './styles.css'

const CompanyCoursesSection = ({ className, title, courses }) => {
  const isGettingCompanyCourses = useSelector(isGettingCompanyCoursesSelector)

  return (
    <div className={classnames(styles.container, className)}>
      <h1 className={styles.header}>{title}</h1>
      {isGettingCompanyCourses ? (
        <div className={styles.loading}>
          <RocketLoader />
        </div>
      ) : (
        <div className={styles.content}>
          {courses.map(course => (
            <CourseCard
              target={`/course/${course.id}`}
              key={course.id}
              className={styles.card}
              course={course}
              isCompany
            />
          ))}
        </div>
      )}
    </div>
  )
}
CompanyCoursesSection.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  courses: PropTypes.arrayOf(courseShape).isRequired,
}

CompanyCoursesSection.defaultProps = {
  className: '',
  title: '',
}

export default CompanyCoursesSection
