import React, { useRef, useCallback, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { toast } from 'react-toastify'

import Toastr, { ToastrTheme } from '_components/toastr'
import CameraIcon from '_assets/icons/camera.svg'
import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

const UploadPicture = ({ className, onChangeAvatar }) => {
  const photoRef = useRef(null)
  const inputRef = useRef(null)
  const [photo, setPhoto] = useState(null)

  const removeFile = useCallback(
    () => {
      setPhoto(null)
      onChangeAvatar(null)
    },
    [onChangeAvatar]
  )

  const uploadFile = useCallback(() => inputRef.current.click(), [inputRef])

  const onChangePicture = useCallback(
    event => {
      const { files, value } = event.target

      const filePath = value
      const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif)$/i

      if (!allowedExtensions.exec(filePath)) {
        toast(<Toastr theme={ToastrTheme.ERROR} content="Please select an image file." />)
        removeFile()
        /* eslint-disable no-param-reassign */
        event.target.value = null
      } else if (files && files[0]) {
        setPhoto(files[0])
        onChangeAvatar(files[0])

        const reader = new FileReader()

        reader.onload = data => {
          photoRef.current.src = data.target.result
        }

        reader.readAsDataURL(files[0])

        /* eslint-disable no-param-reassign */
        event.target.value = null // this resets the image inside <input /> to prevent bug in changing pictures
      }
    },
    [onChangeAvatar, removeFile]
  )

  return (
    <div className={classnames(styles.container, className)}>
      <button
        type="button"
        className={styles['profile-picture-wrapper']}
        onClick={uploadFile}
        onMouseDown={onMouseDown}
      >
        {photo ? (
          <img ref={photoRef} alt="Profile avatar" className={styles.picture} />
        ) : (
          <svg className={styles.icon} aria-hidden="true" viewBox={CameraIcon.viewBox}>
            <use xlinkHref={`#${CameraIcon.id}`} />
          </svg>
        )}
      </button>
      <button
        type="button"
        onClick={photo ? removeFile : uploadFile}
        className={styles.text}
        onMouseDown={onMouseDown}
      >
        {photo ? 'Remove' : 'Add'} photo
      </button>
      <input
        className={styles.input}
        ref={inputRef}
        type="file"
        accept="image/*"
        onChange={onChangePicture}
      />
    </div>
  )
}

UploadPicture.propTypes = {
  className: PropTypes.string,
  onChangeAvatar: PropTypes.func.isRequired,
}

UploadPicture.defaultProps = {
  className: '',
}

export default UploadPicture
