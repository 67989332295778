import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import LikeIcon from '_assets/icons/like.svg'
import noOutlineAction from '_hocs/no-outline-action'

import styles from './styles.css'

const Like = ({
  count,
  onClick,
  hideShazamButton,
  onMouseDown,
  shazamedByYou,
  isShazamLoading,
}) => {
  const onShazamClick = useCallback(() => onClick(), [onClick])

  return (
    <div className={styles.wrapper}>
      {!hideShazamButton && (
        <button
          disabled={isShazamLoading}
          type="button"
          className={classnames(styles.like, { [styles.selected]: shazamedByYou })}
          onClick={onShazamClick}
          onMouseDown={onMouseDown}
        >
          SHAZAM
        </button>
      )}
      <svg className={styles.icon} viewBox={LikeIcon.viewBox} aria-label="Shazam icon" role="img">
        <use xlinkHref={`#${LikeIcon.id}`} />
      </svg>
      {count}
    </div>
  )
}

Like.propTypes = {
  count: PropTypes.number,
  onClick: PropTypes.func,
  hideShazamButton: PropTypes.bool,
  shazamedByYou: PropTypes.bool,
  onMouseDown: PropTypes.func.isRequired,
  isShazamLoading: PropTypes.bool,
}

Like.defaultProps = {
  count: 0,
  onClick: () => {},
  hideShazamButton: false,
  shazamedByYou: false,
  isShazamLoading: false,
}

export default noOutlineAction(Like)
