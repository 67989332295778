import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Svg from '_components/svg'
import Button, { ButtonTheme } from '_components/landing-components/button'
import { COURSE_TYPES, CORE_PROGRAMS } from '_constants/course'
import { iconPropTypes } from '_utils/proptypes'

import SliderCourseCard from './slider-course-card'
import styles from './styles.css'

export const SLIDE_TYPE = {
  CONTENT: 'CONTENT',
  HOME_CONTENT: 'HOME',
  LAST_CONTENT: 'LAST',
}

const SlideContent = ({
  title,
  subtitle,
  tags,
  iconTitleDetails,
  titleDetails,
  description,
  url,
  icon,
  slideType,
}) => {
  const renderEdgeContents = useMemo(
    () => slideType === SLIDE_TYPE.HOME_CONTENT ? (
        <>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.description}>{description}</p>
          {!!icon && <Svg icon={icon} className={styles.icon} />}
        </>
      ) : (
        <>
          <h1 className={styles.title}>Start your Lightyear journey</h1>
          <div className={styles['courses-wrapper']}>
            {Object.values(CORE_PROGRAMS).map(
              course =>
                course.slug !== COURSE_TYPES.CORE_WORKSHOPS && (
                  <SliderCourseCard
                    tags={course.courseTags.GENERAL_TAGS}
                    key={course.name}
                    icon={course.icon}
                    title={course.name}
                    description={course.descriptionGuide}
                    url={course.path}
                  />
                )
            )}
          </div>
        </>
      ),
    [description, icon, slideType, title]
  )

  return slideType === SLIDE_TYPE.CONTENT ? (
    <section className={styles['slider-content']}>
      <div className={styles.intro}>
        {icon && <Svg icon={icon} className={styles.icon} />}
        <div className={styles['tag-wrapper']}>
          {tags?.map(tag => (
            <div
              key={`tag-${tag.name}`}
              className={classnames({
                [styles['tag-secondary']]: tag.theme,
                [styles.tag]: !tag.theme,
              })}
            >
              {tag.name}
            </div>
          ))}
        </div>
        <h1 className={styles.title}>{title}</h1>
        <h2 className={styles.subtitle}>{subtitle}</h2>
        <p className={styles['title-details']}>
          {iconTitleDetails && <Svg icon={iconTitleDetails} className={styles.icon} />}
          {titleDetails}
        </p>
        <p className={styles.details}>
          Includes <strong className={styles.underline}>1 free month</strong> of Lightyear
          Membership for first-time members!
        </p>
      </div>
      <span className={styles.divider} />
      <div className={styles.description}>
        <p className={styles['description-text']}>{description}</p>
        <Button className={styles.button} theme={ButtonTheme.MALIBU} url={url}>
          See more
        </Button>
      </div>
    </section>
  ) : (
    <section
      className={classnames({
        [styles['home-slider-content']]: slideType === SLIDE_TYPE.HOME_CONTENT,
        [styles['last-slider-content']]: slideType === SLIDE_TYPE.LAST_CONTENT,
      })}
    >
      {renderEdgeContents}
    </section>
  )
}

SlideContent.propTypes = {
  title: PropTypes.node.isRequired,
  subtitle: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.shape({})),
  iconTitleDetails: PropTypes.node,
  titleDetails: PropTypes.string,
  description: PropTypes.node,
  url: PropTypes.string,
  icon: iconPropTypes,
  slideType: PropTypes.oneOf(Object.values(SLIDE_TYPE)),
}

SlideContent.defaultProps = {
  subtitle: '',
  tags: [],
  iconTitleDetails: '',
  titleDetails: '',
  description: '',
  url: '',
  icon: null,
  slideType: SLIDE_TYPE.CONTENT,
}

export default React.memo(SlideContent)
