import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { toast } from 'react-toastify'
import LazyLoad from 'react-lazyload'

import Toastr, { ToastrTheme } from '_components/toastr'
import POST_ACTIONS from '_modules/post/actions'
import { hidePosts, follow, FOLLOW_USER, blockUser } from '_modules/user/actions'
import { getPeopleYouMayKnow, GET_PEOPLE_YOU_MAY_KNOW } from '_modules/dashboard/actions'
import Filter from '_components/filter'
import PostCard from '_components/post-card'
import Button, { ButtonTheme } from '_components/button'
import { postShape, userShape } from '_utils/proptypes'
import { usePrevious } from '_utils/hooks'
import RocketLoader from '_components/rocket-loader'

import FollowingEmptyState from './following-empty-state'
import styles from './styles.css'

const {
  getFollowedPosts,
  getPosts,
  flagPost,
  shazam,
  unshazam,
  deletePost,
  FLAG_POST,
  GET_FOLLOWING_POSTS,
  GET_POSTS,
} = POST_ACTIONS

const FILTERS = {
  ALL: 'newest',
  FOLLOWING: 'following',
}

const mapStateToProps = ({ posts, user, loading, error, dashboard }) => ({
  posts: posts.toJS(),
  user,
  isFlagging: !!loading.get(FLAG_POST.ACTION),
  flagError: error.get(FLAG_POST.ACTION),
  isGettingFollowedPosts: !!loading.get(GET_FOLLOWING_POSTS.ACTION),
  isGettingPeopleYouMayKnow: !!loading.get(GET_PEOPLE_YOU_MAY_KNOW.ACTION),
  peopleYouMayKnow: dashboard.get('peopleYouMayKnow').toJS(),
  isFollowing: !!loading.get(FOLLOW_USER.ACTION),
  followError: error.get(FOLLOW_USER.ACTION),
  isLoading: loading.get(GET_POSTS.ACTION),
})

const mapDispatchToProps = {
  getFollowingPosts: getFollowedPosts,
  getTimelinePosts: getPosts,
  onFlagPost: flagPost,
  shazamPost: shazam,
  unshazamPost: unshazam,
  postDelete: deletePost,
  hidePostsUser: hidePosts,
  getPeopleToFollow: getPeopleYouMayKnow,
  onFollowUser: follow,
  userBlock: blockUser,
}

const TimelineSection = ({
  className,
  getFollowingPosts,
  getTimelinePosts,
  posts,
  user,
  shazamPost,
  unshazamPost,
  postDelete,
  hidePostsUser,
  onEditPost,
  onCreatePost,
  isFlagging,
  flagError,
  onFlagPost,
  isGettingFollowedPosts,
  getPeopleToFollow,
  isGettingPeopleYouMayKnow,
  peopleYouMayKnow,
  onFollowUser,
  isFollowing,
  followError,
  userBlock,
  isLoading,
}) => {
  const [activeFilter, setActiveFilter] = useState(FILTERS.ALL)

  const wasFlagging = usePrevious(isFlagging)

  const wasFollowing = usePrevious(isFollowing)

  const hasMore = useMemo(() => !!posts.next, [posts.next])

  useEffect(
    () => {
      if (wasFlagging && !isFlagging && flagError.size === 0) {
        toast(<Toastr theme={ToastrTheme.SUCCESS} content="The content has been flagged." />)
      }
    },
    [flagError.size, isFlagging, wasFlagging]
  )

  useEffect(
    () => {
      if (activeFilter === FILTERS.FOLLOWING) {
        getFollowingPosts({ following: true })
        getPeopleToFollow()
        return
      }

      getTimelinePosts({ following: false })
    },
    [activeFilter, getFollowingPosts, getPeopleToFollow, getTimelinePosts]
  )

  useEffect(
    () => {
      if (wasFollowing && !isFollowing && followError.size === 0) {
        getFollowingPosts({ following: true })
      }
    },
    [followError.size, getFollowingPosts, isFollowing, wasFollowing]
  )

  const onFilterOptionClick = useCallback(
    ordering => {
      if (activeFilter !== ordering) {
        setActiveFilter(ordering)
      }
    },
    [activeFilter]
  )

  const onShowMoreClick = useCallback(
    following => {
      const payload = { page: posts.next, following }

      if (following) {
        getFollowingPosts(payload)
        return
      }

      getTimelinePosts(payload)
    },
    [getFollowingPosts, getTimelinePosts, posts.next]
  )

  const onShazamClick = useCallback(
    ({ postId, fatherPostId, shazams }) => shazamPost({ postId, fatherPostId, shazams }),
    [shazamPost]
  )

  const onUnShazamClick = useCallback(
    ({ postId, fatherPostId, shazams }) => unshazamPost({ postId, fatherPostId, shazams }),
    [unshazamPost]
  )

  const onDeletePost = useCallback(
    postId => postDelete({ postId, classId: null, assignmentId: null }),
    [postDelete]
  )

  const onHidePosts = useCallback((username, userId) => hidePostsUser(username, userId), [
    hidePostsUser,
  ])

  const onShowOlderPostsClick = useCallback(
    event => {
      event.preventDefault()
      onShowMoreClick(activeFilter === FILTERS.FOLLOWING)
    },
    [activeFilter, onShowMoreClick]
  )

  const onFollowUserClick = useCallback(username => onFollowUser({ username }), [onFollowUser])

  const onBlockUserClick = useCallback(payload => userBlock({ ...payload, isTimeline: true }), [
    userBlock,
  ])

  const renderPosts = useCallback(
    () => {
      if (activeFilter === FILTERS.FOLLOWING) {
        return posts.following.length === 0 ? (
          <FollowingEmptyState
            peopleYouMayKnow={peopleYouMayKnow}
            onFollowUser={onFollowUserClick}
            isFollowing={isFollowing}
          />
        ) : (
          posts.following.map(post => (
            <LazyLoad offset={100} key={post.id}>
              <PostCard
                id={post.id}
                post={post}
                user={user}
                onSubmit={onCreatePost}
                isOwnPost={user.id === post.author.id}
                onEditPost={onEditPost}
                shazam={onShazamClick}
                unshazam={onUnShazamClick}
                onDeletePost={onDeletePost}
                onHidePosts={onHidePosts}
                onFlagPost={onFlagPost}
                onBlockUser={onBlockUserClick}
              />
            </LazyLoad>
          ))
        )
      }

      return posts.posts.map(post => (
        <LazyLoad offset={100} key={post.id}>
          <PostCard
            id={post.id}
            post={post}
            user={user}
            onSubmit={onCreatePost}
            isOwnPost={user.id === post.author.id}
            onEditPost={onEditPost}
            shazam={onShazamClick}
            unshazam={onUnShazamClick}
            onDeletePost={onDeletePost}
            onHidePosts={onHidePosts}
            onFlagPost={onFlagPost}
            onBlockUser={onBlockUserClick}
          />
        </LazyLoad>
      ))
    },
    [
      activeFilter,
      isFollowing,
      onBlockUserClick,
      onCreatePost,
      onDeletePost,
      onEditPost,
      onFlagPost,
      onFollowUserClick,
      onHidePosts,
      onShazamClick,
      onUnShazamClick,
      peopleYouMayKnow,
      posts.following,
      posts.posts,
      user,
    ]
  )

  return (
    <div className={classnames(styles.container, className)}>
      <Filter
        className={styles.filters}
        filters={Object.keys(FILTERS).map(key => ({
          label: key,
          ordering: FILTERS[key],
        }))}
        onClick={onFilterOptionClick}
        activeFilter={activeFilter}
      />
      <div className={styles.timeline}>
        {renderPosts()}
        {(isGettingFollowedPosts || isGettingPeopleYouMayKnow || isLoading) && (
          <RocketLoader className={styles.loading} />
        )}
      </div>
      {hasMore &&
        !isGettingFollowedPosts && (
          <Button
            label="SHOW OLDER POSTS"
            theme={ButtonTheme.PRIMARY}
            onClick={onShowOlderPostsClick}
          />
        )}
    </div>
  )
}

TimelineSection.propTypes = {
  className: PropTypes.string,
  onEditPost: PropTypes.func.isRequired,
  onCreatePost: PropTypes.func.isRequired,
  getFollowingPosts: PropTypes.func.isRequired,
  getTimelinePosts: PropTypes.func.isRequired,
  getPeopleToFollow: PropTypes.func.isRequired,
  onFlagPost: PropTypes.func.isRequired,
  shazamPost: PropTypes.func.isRequired,
  unshazamPost: PropTypes.func.isRequired,
  postDelete: PropTypes.func.isRequired,
  hidePostsUser: PropTypes.func.isRequired,
  onFollowUser: PropTypes.func.isRequired,
  posts: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.number,
    previous: PropTypes.number,
    posts: PropTypes.arrayOf(postShape),
    following: PropTypes.arrayOf(postShape),
  }).isRequired,
  user: userShape.isRequired,
  isFlagging: PropTypes.bool.isRequired,
  isGettingFollowedPosts: PropTypes.bool.isRequired,
  isGettingPeopleYouMayKnow: PropTypes.bool.isRequired,
  isFollowing: PropTypes.bool.isRequired,
  flagError: ImmutablePropTypes.map,
  followError: ImmutablePropTypes.map,
  peopleYouMayKnow: PropTypes.arrayOf(userShape).isRequired,
  userBlock: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
}

TimelineSection.defaultProps = {
  className: '',
  flagError: Map(),
  followError: Map(),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TimelineSection)
