import { Map, List } from 'immutable'

import Model from './model'

class Channel extends Model({
  id: undefined,
  name: undefined,
  private: undefined,
  numberOfMembers: undefined,
  disabled: undefined,
  membersPreview: List(),
  members: Map({
    count: null,
    next: null,
    previous: null,
    users: List(),
  }),
  messages: List(),
  count: undefined,
  next: undefined,
  previous: undefined,
  read: undefined,
  admin: undefined,
  isMember: undefined,
  createdAt: undefined,
}) {}

export default Channel
