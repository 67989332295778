import React, { useReducer, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'

import { listCards, makeCardDefault, removeCard, addCard } from '_modules/card/actions'
import { classShape, cardShape } from '_utils/proptypes'
import { ENROLL } from '_modules/class/actions'
import { CLASS_TYPE } from '_constants/class'

import ConfirmEnrollment from './confirm-enrollment'
import EnrollCourse from './enroll-course'
import styles from './styles.css'

const mapStateToProps = ({ cards, loading }) => ({
  cards: cards.reduce((acc, curr) => {
    acc.push(curr)
    return acc
  }, []),
  isEnrolling: !!loading.get(ENROLL.ACTION),
})

const mapDispatchToProps = {
  getCardsList: listCards,
  createCard: addCard,
  makeDefault: makeCardDefault,
  removeSelectedCard: removeCard,
}

const initialState = {
  addNewCard: false,
  enrollPage: false,
  cardId: null,
}

const MODAL_FLAGS_CHANGE = 'MODAL_FLAGS_CHANGE'
const TOGGLE_ADD_NEW_CARD = 'TOGGLE_ADD_NEW_CARD'

const reducer = (state, action) => {
  switch (action.type) {
    case MODAL_FLAGS_CHANGE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case TOGGLE_ADD_NEW_CARD: {
      return {
        ...state,
        addNewCard: !state.addNewCard,
      }
    }
    default:
      return state
  }
}

const PaymentContainer = ({
  onClose,
  getCardsList,
  courseClass,
  cards,
  removeSelectedCard,
  makeDefault,
  createCard,
  onEnroll,
  isEnrolling,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(
    () => {
      getCardsList()
    },
    [getCardsList]
  )

  const onCloseModal = useCallback(
    event => {
      event.preventDefault()
      dispatch({
        type: MODAL_FLAGS_CHANGE,
        payload: {
          addNewCard: false,
          enrollPage: false,
          cardId: null,
        },
      })
      onClose()
    },
    [onClose]
  )

  const onAddNewCard = useCallback(
    card => {
      dispatch({
        type: MODAL_FLAGS_CHANGE,
        payload: {
          addNewCard: false,
        },
      })
      createCard(card)
    },
    [createCard]
  )

  const onCardClick = useCallback(cardId => {
    dispatch({
      type: MODAL_FLAGS_CHANGE,
      payload: {
        addNewCard: false,
        enrollPage: true,
        cardId,
      },
    })
  }, [])

  const onRemoveCard = useCallback(id => removeSelectedCard(id), [removeSelectedCard])

  const onDefaultClick = useCallback(id => makeDefault(id), [makeDefault])

  const toggleAddNewCard = useCallback(event => {
    event.preventDefault()
    dispatch({ type: TOGGLE_ADD_NEW_CARD })
  }, [])

  const onClassEnroll = useCallback(
    event => {
      event.preventDefault()
      onEnroll(courseClass.id, { cardToken: state.cardId })
    },
    [courseClass.id, onEnroll, state.cardId]
  )

  const renderContent = useCallback(
    () => state.enrollPage ? (
        <ConfirmEnrollment
          onEnrollClick={onClassEnroll}
          onCancelClick={onCloseModal}
          courseName={courseClass.name}
          price={`${courseClass.price}`}
          type={`${courseClass.type.replace('_', '-')} Course`}
          availability={
            courseClass.type === CLASS_TYPE.SELF_LED?.slug ||
            courseClass.type === CLASS_TYPE.SELF_PACED?.slug
              ? 'Start Immediately'
              : moment(courseClass.startDate).format('l LT')
          }
          isEnrolling={isEnrolling}
        />
      ) : (
        <EnrollCourse
          isFormOpen={state.addNewCard}
          cards={cards}
          onAddNewCard={onAddNewCard}
          onClose={onCloseModal}
          toggleAddNewCard={toggleAddNewCard}
          onCardClick={onCardClick}
          onRemoveCard={onRemoveCard}
          onDefaultCard={onDefaultClick}
        />
      ),
    [
      cards,
      courseClass.name,
      courseClass.price,
      courseClass.startDate,
      courseClass.type,
      isEnrolling,
      onAddNewCard,
      onCardClick,
      onClassEnroll,
      onCloseModal,
      onDefaultClick,
      onRemoveCard,
      state.addNewCard,
      state.enrollPage,
      toggleAddNewCard,
    ]
  )

  return <div className={styles.container}>{renderContent()}</div>
}

PaymentContainer.propTypes = {
  onClose: PropTypes.func,
  getCardsList: PropTypes.func.isRequired,
  createCard: PropTypes.func.isRequired,
  removeSelectedCard: PropTypes.func.isRequired,
  makeDefault: PropTypes.func.isRequired,
  courseClass: classShape.isRequired,
  cards: PropTypes.arrayOf(cardShape).isRequired,
  onEnroll: PropTypes.func.isRequired,
  isEnrolling: PropTypes.bool.isRequired,
}

PaymentContainer.defaultProps = {
  onClose: () => {},
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PaymentContainer)
