import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { ClipLoader } from 'react-spinners'

import styles from './styles.css'

const spinnerStyle = {
  gridArea: 'spinner',
}

const UploadToast = ({ className, fileName }) => (
  <div className={classnames(styles.container, className)}>
    <div className={styles['percentage-wrapper']}>
      <ClipLoader css={spinnerStyle} sizeUnit="px" size={15} color="#fff" loading />
      {/* TO-DO: Enable the progressPercentage */}
    </div>
    <p className={styles.content}>
      Uploading <span>{fileName}</span>
    </p>
  </div>
)
UploadToast.propTypes = {
  className: PropTypes.string,
  fileName: PropTypes.string,
}

UploadToast.defaultProps = {
  className: '',
  fileName: 'file',
}

export default UploadToast
