import { useState, useCallback } from 'react'

export default (isOpen = false) => {
  const [isModalOpen, setModalOpen] = useState(isOpen)

  const onToggleModal = useCallback(() => {
    setModalOpen(prevState => !prevState)
  }, [])

  return [isModalOpen, onToggleModal, setModalOpen]
}
