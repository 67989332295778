import Model from './model'

class Alert extends Model({
  id: undefined,
  content: undefined,
  callToAction: undefined,
  url: undefined,
}) {}

export default Alert
