import cookies from 'react-cookies'

import { UNAUTHORIZED } from '_modules/user/actions'

const ERRORS = {
  INVALID_TOKEN: 'Invalid token.',
}

export default function errorMiddleware() {
  return next => action => {
    const result = next(action)

    if (!(result instanceof Promise)) {
      return action
    }

    return result.catch(error => {
      if (error.detail === ERRORS.INVALID_TOKEN) {
        cookies.remove('user', { path: '/' })

        return next({
          type: UNAUTHORIZED,
          error: false,
        })
      }

      return action
    })
  }
}
