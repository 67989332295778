import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

const Filter = ({ className, filters, onClick, activeFilter }) => {
  const onFilterClick = useCallback(
    event => {
      event.preventDefault()
      onClick(event.currentTarget.id)
    },
    [onClick]
  )

  return (
    <div className={classnames(styles.container, className)}>
      {filters.map(filter => (
        <button
          key={filter.label}
          type="button"
          className={classnames(styles.item, { [styles.active]: filter.ordering === activeFilter })}
          onClick={onFilterClick}
          onMouseDown={onMouseDown}
          id={filter.ordering}
        >
          {filter.label}
        </button>
      ))}
    </div>
  )
}

Filter.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      ordering: PropTypes.string,
    })
  ).isRequired,
  onClick: PropTypes.func,
  activeFilter: PropTypes.string.isRequired,
}

Filter.defaultProps = {
  className: '',
  onClick: () => {},
}

export default React.memo(Filter)
