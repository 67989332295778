import { Map, fromJS } from 'immutable'
import humps from 'humps'

import { createReducer } from '_utils/redux'

import { GET_AVAILABLE_CLASSES_BY_COURSE, GET_CATEGORY_AVAILABLE_CLASSES } from './actions'

const INITIAL_STATE = Map()

export default createReducer(INITIAL_STATE, {
  [GET_CATEGORY_AVAILABLE_CLASSES.FULFILLED]: (state, { payload, meta }) => {
    const { count, next, previous, results } = payload

    const availableClasses = state.getIn([`${meta.category}`, 'totalAvailableClasses'])

    const category = {
      name: meta.category,
      availableClasses,
      search: {
        classes: results.map(item => item.id),
        next,
        previous,
        count,
      },
    }

    return state.mergeIn([meta.category], fromJS(category))
  },
  [GET_AVAILABLE_CLASSES_BY_COURSE.FULFILLED]: (state, { payload }) => {
    const formattedPayload = humps.camelizeKeys(payload.results)
    let newState = state

    formattedPayload.forEach(category => {
      const formattedCategory = Map({
        totalAvailableClasses: category.availableClasses,
        availableCoachLedClasses: category.availableCoachLedClasses,
        availableSelfPacedClasses: category.availableSelfLedClasses,
      })

      newState = newState.mergeIn([`${category.name}`], formattedCategory)
    })

    return newState
  },
})
