import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from '@reach/window-size'

import CourseCard from '_components/landing-components/course-card'
import { prismicContentPropType } from '_utils/proptypes'
import { MOBILE_THRESHOLD } from '_config/media-queries'

import styles from './styles.css'

const MOBILE_HEADER_SIZE = 72
const HEADER_SIZE = 80

const CoursesSection = ({ slice: { course, actionButtonScrollToSlice }, id }) => {
  const { width } = useWindowSize()

  const handleScrollToSliceClick = useCallback(
    () => {
      const element = document.querySelector(`section[id^=${actionButtonScrollToSlice?.slice}`)
      const { top } = element.getBoundingClientRect()
      const headerSize = width <= MOBILE_THRESHOLD ? MOBILE_HEADER_SIZE : HEADER_SIZE
      const slicePosition = top + window.scrollY - headerSize
      window.scrollTo({
        top: slicePosition,
        behavior: 'smooth',
      })
    },
    [actionButtonScrollToSlice, width]
  )

  return (
    <section className={styles.section} id={id}>
      <h1 className={styles.hidden}>Lightyear&apos;s Pick Course</h1>
      <div className={styles.content}>
        <CourseCard
          key={`course-${course.courseTitle}`}
          course={course}
          isLightyearsPick
          handleScrollToSliceClick={handleScrollToSliceClick}
        />
      </div>
    </section>
  )
}
CoursesSection.propTypes = {
  slice: PropTypes.shape({
    course: PropTypes.shape({
      coursePicture: PropTypes.shape({
        url: PropTypes.string,
        alt: PropTypes.string,
      }),
      courseLevel: PropTypes.string,
      courseTitle: PropTypes.string,
      courseDescription: PropTypes.string,
      courseAdditionalInfo: prismicContentPropType,
      courseUrl: PropTypes.string,
      isFree: PropTypes.bool,
    }),
    actionButtonScrollToSlice: PropTypes.shape({
      slice: PropTypes.string,
    }),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(CoursesSection)
