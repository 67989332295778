import humps from 'humps'

import { SUGGESTIONS } from '_modules/suggestion/actions'
import { Suggestion } from '_models/'
import { createReducer } from '_utils/redux'

const INITIAL_STATE = new Suggestion()

const suggestion = createReducer(INITIAL_STATE, {
  [SUGGESTIONS.FULFILLED]: (state, { payload }) => new Suggestion(humps.camelizeKeys(payload)),
})

export default suggestion
