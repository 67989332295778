import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { navigate } from '@reach/router'

import Input from '_components/input'
import SearchIcon from '_assets/icons/search.svg'
import Button, { ButtonTheme } from '_components/landing-components/button'
import { CORE_PROGRAMS, MAIN_PROGRAM_COURSES_NAMES } from '_constants/course'
import AstronautIcon from '_assets/icons/personal-development-icon.svg'
import Card from '_components/card'
import { EXPLORE_COURSES_SECTION_TITLE } from '_utils/constants'
import SectionTitle from '_components/landing-components/section-title'
import { getCategoryClassesTotalCount } from '_modules/public-categories/actions'
import { getAvailableClasses } from '_modules/public-classes/actions'
import UpcomingClassesScrollList from '_components/upcoming-classes-scroll-list'
import useModal from '_hooks/use-modal'
import { getAvailableClassesListSelector } from '_modules/public-classes/selectors'
import { hasHighlightMessageSelector } from '_modules/prismic/selectors'

import styles from './styles.css'
import PlanetClasses from './planet-classes'
import PlanetsPath from './planets-path'
import GuideModal from './guide-modal'

const ENTER_KEY = 'Enter'

const courses = CORE_PROGRAMS.map(course => ({
  title: course.name,
  theme: course,
  path: course.path,
  initialSearchState: course.state,
  courseTags: course.courseTags,
  description: course.description,
}))

const ExploreCourses = ({ id, slice }) => {
  const classesList = useSelector(getAvailableClassesListSelector)
  const hasHighlightMessage = useSelector(hasHighlightMessageSelector)
  const dispatch = useDispatch()
  const [searchClass, setSearchClass] = useState('')
  const [isGuideModalOpen, onToggleGuideModal] = useModal()

  const onSearchClass = useCallback(({ target }) => {
    const { value } = target
    setSearchClass(value)
  }, [])

  const onSearchIconClick = useCallback(
    () => {
      navigate('/classes/search', { state: { search: searchClass } })
    },
    [searchClass]
  )

  const onSearchKeyPress = useCallback(
    event => {
      if (event.key === ENTER_KEY) {
        navigate('/classes/search', { state: { search: searchClass } })
      }
    },
    [searchClass]
  )

  useEffect(
    () => {
      dispatch(getAvailableClasses({}))
      dispatch(getCategoryClassesTotalCount({ names: MAIN_PROGRAM_COURSES_NAMES }))
    },
    [dispatch]
  )

  return (
    <section id={id}>
      <div
        className={classnames(styles['search-section'], {
          [styles['highlight-message']]: hasHighlightMessage,
        })}
      >
        <SectionTitle
          title={EXPLORE_COURSES_SECTION_TITLE}
          className={styles['search-section-title']}
        />

        <Input
          id="search-class-input"
          placeholder="Search for a class, leader or program"
          inputClassName={styles['search-input']}
          className={styles['input-wrapper']}
          value={searchClass}
          onChange={onSearchClass}
          endAdornment={SearchIcon}
          onAdornmentClick={onSearchIconClick}
          onKeyPress={onSearchKeyPress}
          adornmentClassName={styles.adornment}
        />
      </div>
      <div className={styles['upcoming-classes-section']}>
        <h2 className={styles.title}>Upcoming classes</h2>
        <p className={styles.description}>
          Start your Lightyear journey now! Check out these classes starting soon:
        </p>

        <Button className={styles.button} url="/classes/search">
          View all classes
        </Button>
        <UpcomingClassesScrollList
          className={styles['scroll-list']}
          classesList={classesList}
          defaultArrows
        />
      </div>
      <div className={styles['discover-section']}>
        <h2 className={styles.title}>Our programs</h2>
        <p className={styles.description}>{slice?.sectionText}</p>
        <div className={styles['planet-space']}>
          <PlanetsPath lines={3} className={styles.path} />

          {courses.map(course => (
            <PlanetClasses
              courseTags={course.courseTags.GENERAL_TAGS}
              key={course.title}
              title={course.title}
              theme={course.theme}
              url={course.path}
              description={course.description}
              className={styles[course.theme.className]}
              initialSearchState={course.initialSearchState}
            />
          ))}
        </div>

        <Card className={styles['guide-card']}>
          <svg
            className={styles['guide-icon']}
            viewBox={AstronautIcon.viewBox}
            role="img"
            aria-label="Astronaut"
          >
            <use xlinkHref={`#${AstronautIcon.id}`} />
          </svg>

          <h2 className={styles['guide-title']}>Not sure where to start?</h2>
          <p className={styles['guide-description']}>
            A guide to help you understand and choose the best program for you!
          </p>

          <Button
            className={styles['guide-button']}
            theme={ButtonTheme.MALIBU}
            onClick={onToggleGuideModal}
          >
            See our guide
          </Button>
        </Card>
      </div>
      {isGuideModalOpen && <GuideModal onClose={onToggleGuideModal} isOpen />}
    </section>
  )
}

ExploreCourses.propTypes = {
  id: PropTypes.string.isRequired,
  slice: PropTypes.shape({
    sectionText: PropTypes.string,
    sliceType: PropTypes.string,
  }).isRequired,
}

export default ExploreCourses
