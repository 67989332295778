import '@babel/polyfill'
import '@reach/menu-button/styles.css'
import React from 'react'
import { render } from 'react-dom'
import { Provider } from 'react-redux'
import * as Sentry from '@sentry/browser'
import cookies from 'react-cookies'
import ReactGA from 'react-ga4'
import { fromJS } from 'immutable'
import { GoogleOAuthProvider } from '@react-oauth/google'

import { User } from '_models/'
import { SENTRY_DSN, GA_ID, GOOGLE_CLIENT_ID } from '_config/environment'
import ToastrContainer from '_components/toastr-container'

import './bootstrap'
import configureStore from './store/configure-store'
import Router from './router-with-tracker'

// eslint-disable-next-line no-underscore-dangle
const windowInitialState = fromJS(window.__INITIAL_STATE__)?.toObject()

const root = async () => {
  const authToken = cookies.load('authToken', { path: '/' })
  const user = cookies.load('user', { path: '/' })

  const initialState = {
    user: user
      ? new User({
          ...JSON.parse(user),
          ...windowInitialState,
          authToken: windowInitialState?.user?.authToken || authToken || user.authToken,
        })
      : new User(),
  }

  const store = configureStore({ ...windowInitialState, ...initialState })

  if (SENTRY_DSN) {
    Sentry.init({ dsn: SENTRY_DSN, ignoreErrors: ['Object Not Found Matching Id:'] })
  }

  if (GA_ID) {
    ReactGA.initialize(GA_ID)
  }

  render(
    <Provider store={store}>
      <GoogleOAuthProvider clientId={GOOGLE_CLIENT_ID}>
        <Router />
        <ToastrContainer />
      </GoogleOAuthProvider>
    </Provider>,
    document.getElementById('root')
  )
}

root()
