import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const CompanyCard = ({ className, company }) => (
  <article className={classnames(styles.card, className)}>
    <img src={company.logo} alt={`Logo from ${company.name}`} className={styles.logo} />
  </article>
)

CompanyCard.propTypes = {
  company: PropTypes.shape({
    name: PropTypes.string,
    logo: PropTypes.string,
  }).isRequired,
  className: PropTypes.string,
}

CompanyCard.defaultProps = {
  className: '',
}
export default React.memo(CompanyCard)
