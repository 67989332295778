import { Map } from 'immutable'

import ASSIGNMENT_ACTIONS from '_modules/assignment/actions'

const { CREATE_POST, FLAG_POST } = ASSIGNMENT_ACTIONS

export const isCreatingAssignmentPostSelector = state => !!state.loading.get(CREATE_POST.ACTION)

export const isFlaggingPostSelector = state => !!state.loading.get(FLAG_POST.ACTION)

export const flagPostErrorSelector = state => state.error.get(FLAG_POST.ACTION, Map())
