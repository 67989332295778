import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Map } from 'immutable'

import CalendarIcon from '_assets/icons/calendar.svg'
import ClockIcon from '_assets/icons/clock.svg'
import PriceIcon from '_assets/icons/price.svg'
import Button from '_components/button'
import { getDateAndTime, getFormattedDate, getFormattedTime } from '_utils/get-date-and-time'
import AddToCalendarDropdown from '_components/add-to-calendar-dropdown'

import styles from './styles.css'

const AnnouncementsCard = ({ className, announcement }) => {
  const hasTopSection = !!(
    announcement.get('tag') ||
    announcement.get('title') ||
    announcement.get('description')
  )
  const hasMiddleSection = !!(
    announcement.get('sentence') ||
    announcement.get('startTime') ||
    announcement.get('endTime') ||
    announcement.get('price')
  )

  const calendarEvent = useMemo(
    () => ({
      title: announcement.get('title'),
      description: announcement.get('description'),
      startTime: announcement.get('startTime'),
      endTime: announcement.get('endTime'),
      meetingUrl: announcement.get('buttonLink'),
      meetingText: announcement.get('buttonText'),
      ...(announcement.get('weekly') && { recurring: { repeat: 'weekly' } }),
    }),
    [announcement]
  )

  const backgroundStyles = useMemo(
    () => ({
      backgroundImage: `linear-gradient(84.83deg, #FFFFFF 7.14%, rgba(255, 255, 255, 0) 95.85%), linear-gradient(359.65deg, #FFFFFF 0%, rgba(255, 255, 255, 0.72) 73.72%), url(${announcement.get(
        'background'
      )})`,
    }),
    [announcement]
  )

  const topSection = useMemo(
    () => (
      <div className={classnames({ [styles.section]: !!hasMiddleSection })}>
        {announcement.get('tag') && <p className={styles.tag}>{announcement.get('tag')}</p>}
        {announcement.get('title') && <h1 className={styles.title}>{announcement.get('title')}</h1>}
        {announcement.get('description') && (
          <p className={classnames(styles.text, styles.description)}>
            {announcement.get('description')}
          </p>
        )}
      </div>
    ),
    [announcement, hasMiddleSection]
  )

  const middleSection = useMemo(
    () => {
      const startDate = getDateAndTime(announcement.get('startTime'))
      const endDate = getDateAndTime(announcement.get('endTime'))

      const date = getFormattedDate(startDate.date, endDate.date)
      const time = getFormattedTime(startDate.time, endDate.time)
      return (
        <div className={classnames({ [styles.section]: !!announcement.get('buttonLink') })}>
          {announcement.get('sentence') && (
            <p
              className={classnames(styles.text, styles['highlight-sentence'], {
                [styles['bottom-gutter']]: !!(
                  announcement.get('price') ||
                  announcement.get('startTime') ||
                  announcement.get('endTime')
                ),
              })}
            >
              {announcement.get('sentence')}
            </p>
          )}
          {date && (
            <div className={styles['field-container']}>
              <svg className={styles.icon} viewBox={CalendarIcon.viewBox} aria-hidden="true">
                <use xlinkHref={`#${CalendarIcon.id}`} />
              </svg>
              <p className={styles.text}>{date}</p>
            </div>
          )}
          {time && (
            <div className={styles['field-container']}>
              <svg className={styles.icon} viewBox={ClockIcon.viewBox} aria-hidden="true">
                <use xlinkHref={`#${ClockIcon.id}`} />
              </svg>
              <p className={styles.text}>{time} - Your local time </p>
            </div>
          )}
          {!!announcement.get('price') && (
            <div className={styles['field-container']}>
              <svg className={styles.icon} viewBox={PriceIcon.viewBox} aria-hidden="true">
                <use xlinkHref={`#${PriceIcon.id}`} />
              </svg>
              <p className={styles.text}>{announcement.get('price')}</p>
            </div>
          )}
          {date &&
            time && (
              <div className={styles.calendar}>
                <AddToCalendarDropdown event={calendarEvent} />
              </div>
            )}
        </div>
      )
    },
    [announcement, calendarEvent]
  )

  return (
    <article className={classnames(styles['announcements-container'], className)}>
      {announcement.get('background') && <div style={backgroundStyles} className={styles.image} />}
      {hasTopSection && topSection}
      {hasMiddleSection && middleSection}
      {announcement.get('buttonLink') && (
        <Button
          className={styles.button}
          label={announcement.get('buttonText')}
          to={announcement.get('buttonLink')}
        />
      )}
    </article>
  )
}

AnnouncementsCard.propTypes = {
  className: PropTypes.string,
  announcement: PropTypes.instanceOf(Map),
}

AnnouncementsCard.defaultProps = {
  className: '',
  announcement: Map(),
}

export default React.memo(AnnouncementsCard)
