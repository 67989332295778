import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Redirect } from '@reach/router'

import { userShape } from '_utils/proptypes'
import { INITIAL_ENROLL } from '_modules/class/actions'
import { checkSubscriptionExpired } from '_utils/subscription'

const authRoute = WrappedComponent => {
  const mapStateToProps = ({ user, loading }) => ({
    user: user.toJS(),
    isPaying: !!loading.get(INITIAL_ENROLL.ACTION),
  })
  const AuthRoute = ({ user, location, ...props }) => {
    const hasUserCompletedProfile = user.completedProfile
    const hasUserCompletedOnboarding = user.completedOnboarding
    const userBelongsToACompany = user.belongsToACompany

    const isOnboardingRoute = useMemo(() => location.pathname.includes('/onboarding'), [
      location.pathname,
    ])

    const isProfileRoute = useMemo(() => location.pathname.includes('/onboarding/profile'), [
      location.pathname,
    ])

    const isDashboardRoute = useMemo(() => location.pathname.includes('/dashboard'), [
      location.pathname,
    ])

    const isSubscriptionExpiredRoute = useMemo(
      () => location.pathname.includes('/subscription-expired'),
      [location.pathname]
    )

    const isSuspendedRoute = useMemo(() => location.pathname.includes('/suspended'), [
      location.pathname,
    ])

    const subscriptionExpired = useMemo(() => checkSubscriptionExpired(user), [user])

    const redirectState = useMemo(() => location.state?.redirectTo, [location.state])

    if (!user.authToken) {
      return <Redirect to="/" noThrow />
    }

    if (!hasUserCompletedOnboarding && !!redirectState) {
      return <Redirect to={redirectState} noThrow />
    }

    if (
      !user.skipSubscription &&
      !hasUserCompletedOnboarding &&
      (isProfileRoute || !isOnboardingRoute)
    ) {
      if (userBelongsToACompany) {
        return <Redirect to="/onboarding" noThrow />
      }

      if (isDashboardRoute) {
        return <Redirect to="/dashboard" noThrow />
      }

      return <Redirect to="/" noThrow />
    }

    if (
      hasUserCompletedOnboarding &&
      !hasUserCompletedProfile &&
      userBelongsToACompany &&
      !isProfileRoute
    ) {
      return <Redirect to="/onboarding/profile" noThrow />
    }

    if (isOnboardingRoute && !userBelongsToACompany) {
      return <Redirect to="/" noThrow />
    }

    if (hasUserCompletedProfile && isOnboardingRoute && hasUserCompletedOnboarding) {
      return <Redirect to="/dashboard" noThrow />
    }

    if (isSubscriptionExpiredRoute && !subscriptionExpired) {
      return <Redirect to="/dashboard" noThrow />
    }

    if (user.suspended && !isSuspendedRoute) {
      return <Redirect to="/suspended" noThrow />
    }

    return (
      <WrappedComponent location={location} {...props} subscriptionExpired={subscriptionExpired} />
    )
  }

  AuthRoute.propTypes = {
    user: userShape.isRequired,
    location: PropTypes.shape().isRequired,
  }

  return connect(mapStateToProps)(AuthRoute)
}

export default authRoute
