import React, { useEffect, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import PeopleIcon from '_assets/icons/people-icon.svg'
import ArrowIcon from '_assets/icons/angle-down.svg'
import CalendarIcon from '_assets/icons/calendar.svg'
import Button from '_components/landing-components/button'
import Svg from '_components/svg'
import useScroll from '_hooks/use-window-scroll'
import { getSlicesPosition } from '_modules/prismic/selectors'

import styles from './styles.css'

const PADDING_BOTTOM = 1000

const ScrollableButton = ({ text, className, onClick, url, newWindow }) => {
  const scroll = useScroll()
  const prismicSlicesPosition = useSelector(getSlicesPosition)
  const classesSlicePosition = prismicSlicesPosition?.get('classesList')
  const aboveSection = scroll < classesSlicePosition?.top
  const belowSection = scroll > classesSlicePosition?.bottom
  const pageEnd =
    typeof window !== 'undefined'
      ? scroll < window?.document?.body?.clientHeight - PADDING_BOTTOM
      : false

  const [isClassesButtonVisible, setClassesButtonVisible] = useState(true)

  useEffect(
    () => {
      setClassesButtonVisible(aboveSection || (belowSection && pageEnd))
    },
    [aboveSection, belowSection, classesSlicePosition, pageEnd, scroll]
  )

  if (url) {
    if (newWindow) {
      return (
        <a
          href={url}
          target="_blank"
          rel="noopener noreferrer"
          className={classnames(styles['scrollable-button-container'], className)}
        >
          {text}
        </a>
      )
    }
    return (
      <a href={url} className={classnames(styles['scrollable-button-container'], className)}>
        {text}
      </a>
    )
  }

  if (isClassesButtonVisible) {
    return (
      <Button
        onClick={onClick}
        className={classnames(styles['scrollable-button-container'], className)}
      >
        <div className={styles['icon-wrapper']}>
          <Svg icon={PeopleIcon} className={classnames(styles['button-icon'], styles.icon)} />
        </div>
        <div className={classnames(styles['icon-wrapper'], styles['hover-icon'])}>
          <Svg
            icon={CalendarIcon}
            className={classnames(styles['button-icon'], styles.icon, styles['calendar-icon'])}
          />
        </div>
        {text}
        <Svg
          icon={ArrowIcon}
          className={classnames(styles['button-icon'], styles['arrow-icon'], {
            [styles['arrow-icon-below']]: belowSection,
          })}
        />
      </Button>
    )
  }

  if (!url && !!onClick) {
    return (
      <Button
        onClick={onClick}
        className={classnames(styles['scrollable-button-container'], className)}
      >
        {text}
      </Button>
    )
  }

  return null
}

ScrollableButton.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func,
  url: PropTypes.string,
  newWindow: PropTypes.bool,
}

ScrollableButton.defaultProps = {
  className: '',
  onClick: () => {},
  url: '',
  newWindow: true,
}

export default React.memo(ScrollableButton)
