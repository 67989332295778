import React, { useCallback, useMemo, useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'
import moment from 'moment'

import Card from '_components/card'
import WarningIcon from '_assets/icons/warning.svg'
import CheckIcon from '_assets/icons/check.svg'
import CallIcon from '_assets/icons/call.svg'
import { assignmentShape } from '_utils/proptypes'
import Button, { ButtonTheme } from '_components/button'
import { usePrevious } from '_utils/hooks'
import { ASSIGNMENT_TYPE } from '_constants/assignment'
import { formatUrl } from '_utils/helpers'
import useCallCountdown from '_hooks/use-call-countdown'

import styles from './styles.css'

const EARLIER_START_MINUTES = 10

const transformDuration = (duration, type) => {
  if (type === ASSIGNMENT_TYPE.VIDEO) {
    return duration === 1 ? '1 minute' : `${duration} minutes`
  }

  return duration === 1 ? '1 day' : `${duration} days`
}

const Assignment = ({
  className,
  content,
  readOnly,
  isInstructor,
  onStartMeetingClick,
  isLeaderLed,
  isSelfLed,
  isStartingCall,
  enableAssignmentVideoCall,
  cardId,
}) => {
  const joinCallRef = useRef(null)
  const [isStartCallClicked, setCallStatus] = useState(false)

  const wasStartingCall = usePrevious(isStartingCall)
  const [minuteCountdown, timeToEndMeeting] = useCallCountdown(content)

  const isCompleted = useMemo(() => !readOnly && content.completed, [content, readOnly])

  const isEnded = useMemo(
    () => !readOnly && content?.assignment?.meeting?.ended,
    [content, readOnly]
  )

  const isWarning = useMemo(
    () => !readOnly && moment(content.assignment?.dueDate).add(1, 'days').isBefore(moment()),
    [content, readOnly]
  )

  const isVideo = useMemo(() => {
    if (readOnly) {
      return content.type === ASSIGNMENT_TYPE.VIDEO
    }

    return content.assignment.type === ASSIGNMENT_TYPE.VIDEO
  }, [content, readOnly])

  const getIcon = useCallback(() => {
    if (isVideo) {
      return (
        <svg
          className={styles.icon}
          aria-label="Assignment status"
          role="img"
          viewBox={CallIcon.viewBox}
        >
          <use xlinkHref={`#${CallIcon.id}`} />
        </svg>
      )
    }

    if (isCompleted || (!isCompleted && isEnded)) {
      return (
        <svg
          className={styles.icon}
          aria-label="Assignment status"
          role="img"
          viewBox={CheckIcon.viewBox}
        >
          <use xlinkHref={`#${CheckIcon.id}`} />
        </svg>
      )
    }

    if (isWarning) {
      return (
        <svg
          className={styles.icon}
          aria-label="Assignment status"
          role="img"
          viewBox={WarningIcon.viewBox}
        >
          <use xlinkHref={`#${WarningIcon.id}`} />
        </svg>
      )
    }
    return null
  }, [isCompleted, isEnded, isVideo, isWarning])

  const duration = useMemo(() => {
    if (readOnly) {
      if (!content.duration) {
        return ''
      }
      return transformDuration(content.duration, content.type)
    }

    return transformDuration(content.assignment?.duration, content.assignment?.type)
  }, [content, readOnly])

  const description = useMemo(() => {
    if (readOnly) {
      return content?.subtitle || 'No description'
    }

    if (isVideo) {
      return content.assignment?.subtitle
    }

    return content.assignment?.subtitle || 'No description'
  }, [content, isVideo, readOnly])

  const hasMeeting = useMemo(
    () => content && content.assignment && content.assignment?.meeting,
    [content]
  )

  const meetingEnded = useMemo(
    () => hasMeeting && content.assignment?.meeting.ended,
    [content, hasMeeting]
  )

  const meetingStarted = useMemo(
    () => hasMeeting && content?.assignment?.meeting?.started,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [content, hasMeeting]
  )

  const meetingHasRecordings = useMemo(
    () => hasMeeting && content?.assignment?.meeting?.recordings?.length > 0,
    [content, hasMeeting]
  )

  const onStartCallClick = useCallback(() => {
    setCallStatus(true)
    onStartMeetingClick({
      meetingId: content.assignment?.meeting.id,
      assignmentId: content.id,
    })
  }, [content, onStartMeetingClick])

  const renderButtonOrRecordings = useCallback(() => {
    if (meetingEnded) {
      return meetingHasRecordings ? null : (
        <p className={styles.disabled}> Video recording coming soon </p>
      )
    }

    const today = moment()
    const dueDate = moment(content.assignment?.meeting.startTime).add(
      content.assignment?.duration,
      'minutes'
    )
    const earlierStartTime = moment(content.assignment.meeting.startTime).subtract(
      EARLIER_START_MINUTES,
      'minutes'
    )
    // if is a leader-led class
    // only the instructor can start the call
    // members have access only if the call has started
    if (isLeaderLed && hasMeeting && !meetingStarted) {
      let isButtonDisabled = true

      if (isInstructor) {
        isButtonDisabled =
          today.isBefore(earlierStartTime) ||
          (today.isAfter(dueDate) &&
            (minuteCountdown > EARLIER_START_MINUTES || timeToEndMeeting <= 0))
      } else {
        isButtonDisabled = !meetingStarted || meetingEnded
      }

      return (
        <Button
          className={styles.action}
          label={isInstructor && timeToEndMeeting >= 0 ? 'START CALL' : 'JOIN CALL'}
          theme={ButtonTheme.PRIMARY}
          disabled={isButtonDisabled}
          onClick={onStartCallClick}
        />
      )
    }

    // it's a community-led class
    if (hasMeeting && (today.isBefore(earlierStartTime) || today.isAfter(dueDate))) {
      return (
        <Button className={styles.action} label="JOIN CALL" theme={ButtonTheme.PRIMARY} disabled />
      )
    }

    const callLink = formatUrl(content.assignment.meeting?.joinUrl)
    return (
      <a
        className={styles.action}
        href={callLink}
        target="_blank"
        rel="noopener noreferrer"
        ref={joinCallRef}
      >
        JOIN CALL
      </a>
    )
    // We don't want to get the nested deps, only the parent (content)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    content.assignment?.duration,
    content.assignment?.meeting?.joinUrl,
    content.assignment?.meeting?.startTime,
    hasMeeting,
    isInstructor,
    isLeaderLed,
    meetingEnded,
    meetingHasRecordings,
    meetingStarted,
    minuteCountdown,
    onStartCallClick,
    timeToEndMeeting,
  ])

  const assignmentName = useMemo(() => {
    if (readOnly) {
      return content.name
    }

    if (content.assignment?.type === ASSIGNMENT_TYPE.VIDEO) {
      return content.assignment?.name || 'Live call'
    }
    return content.assignment?.name
  }, [content.assignment, content.name, readOnly])

  const assignmentDetails = useMemo(() => {
    const formatTime = `MMMM Do YYYY${isVideo ? ', LT' : ''}`

    if ((isSelfLed && !isVideo) || !duration) {
      return null
    }

    if (isVideo && content.assignment?.meeting?.startTime) {
      return (
        <p className={styles.duration}>
          Starts in {moment(content.assignment?.meeting?.startTime).format(formatTime)}{' '}
          {isVideo && `• For ${duration}`} - Your local date and time
        </p>
      )
    }

    if (content.assignment?.dueDate) {
      return (
        content.assignment?.dueDate && (
          <p className={styles.duration}>
            Due {moment(content.assignment.dueDate).format(formatTime)}{' '}
            {isVideo && `• For ${duration}`} - Your local date and time
          </p>
        )
      )
    }

    if (readOnly) {
      return <p className={styles.duration}>{duration}</p>
    }

    return null
  }, [content.assignment, duration, isSelfLed, isVideo, readOnly])

  const renderCardContent = useCallback(
    () => (
      <>
        <div
          className={classnames(styles.status, {
            [styles.video]: isVideo,
            [styles.completed]: (isCompleted || (!isCompleted && isEnded)) && !readOnly && !isVideo,
            [styles.warning]: isWarning && !isEnded && !isCompleted && !readOnly && !isVideo,
            [styles['read-only']]: readOnly,
          })}
        >
          {!readOnly &&
            (isCompleted || (!isCompleted && isEnded) || isWarning || isVideo) &&
            getIcon()}
        </div>
        <div
          className={classnames(styles.details, {
            [styles['read-only']]: readOnly,
          })}
        >
          <h4 className={styles.title}>{assignmentName}</h4>
          {assignmentDetails}
          {isVideo && hasMeeting && renderButtonOrRecordings()}
          <p className={styles.description}>{description}</p>
          <div className={styles.recordings}>
            {hasMeeting
              ? meetingHasRecordings &&
                content.assignment?.meeting?.recordings.map(recording => (
                  <a
                    key={recording.recordingId}
                    href={recording.file}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.recording}
                    aria-label={`recording-${recording.filename}`}
                  >
                    {recording.filename}
                  </a>
                ))
              : null}
          </div>
        </div>
      </>
    ),
    [
      assignmentDetails,
      assignmentName,
      content.assignment,
      description,
      getIcon,
      hasMeeting,
      isCompleted,
      isEnded,
      isVideo,
      isWarning,
      meetingHasRecordings,
      readOnly,
      renderButtonOrRecordings,
    ]
  )

  useEffect(() => {
    if (wasStartingCall && !isStartingCall && isStartCallClicked && joinCallRef.current) {
      setCallStatus(false)
      joinCallRef.current.click()
    }
  }, [isStartCallClicked, isStartingCall, wasStartingCall])

  const shouldLeaderLedStart = useMemo(
    () => isLeaderLed && hasMeeting && !content.assignment?.meeting.started,
    [
      // We don't want to get the nested deps, only the parent (content)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      content,
      hasMeeting,
      isLeaderLed,
    ]
  )
  const shouldCommunityLedStart = useMemo(
    () =>
      !isLeaderLed &&
      hasMeeting &&
      content.assignment?.meeting.started &&
      !content.assignment?.meeting.ended,
    [
      // We don't want to get the nested deps, only the parent (content)
      // eslint-disable-next-line react-hooks/exhaustive-deps
      content,
      hasMeeting,
      isLeaderLed,
    ]
  )

  useEffect(() => {
    // is not course assignment and is not a self led class
    // readOnly = course assignment
    // isVideo = only for leader-led and community-led classes
    let checkVideoAssignment = null

    if (
      !readOnly &&
      isVideo &&
      !content.assignment?.meeting?.canStart &&
      (shouldLeaderLedStart || shouldCommunityLedStart)
    ) {
      const dueDate = moment(content.assignment?.meeting.startTime).add(
        content.assignment?.duration,
        'minutes'
      )
      const earlierStartTime = moment(content.assignment?.meeting.startTime).subtract(10, 'minutes')

      checkVideoAssignment = setInterval(() => {
        const today = moment()

        if (today.isAfter(dueDate)) {
          clearInterval(checkVideoAssignment)
          return
        }

        if (today.isSameOrAfter(earlierStartTime) && today.isSameOrBefore(dueDate)) {
          enableAssignmentVideoCall(content.id)
          clearInterval(checkVideoAssignment)
        }
      }, 60000)
    }

    return () => {
      clearInterval(checkVideoAssignment)
    }
  }, [
    content,
    enableAssignmentVideoCall,
    hasMeeting,
    isInstructor,
    isLeaderLed,
    isVideo,
    readOnly,
    shouldCommunityLedStart,
    shouldLeaderLedStart,
  ])

  return readOnly ? (
    <Card
      className={classnames(styles.container, className, {
        [styles['read-only']]: readOnly,
      })}
      id={cardId}
    >
      {renderCardContent()}
    </Card>
  ) : (
    <Card
      className={classnames(styles.container, className, { [styles['read-only']]: readOnly })}
      id={cardId}
    >
      {renderCardContent()}
      <Link to={`assignment/${content.id}`} className={styles.link} />
    </Card>
  )
}

Assignment.propTypes = {
  className: PropTypes.string,
  content: assignmentShape.isRequired,
  readOnly: PropTypes.bool,
  isInstructor: PropTypes.bool,
  onStartMeetingClick: PropTypes.func,
  isLeaderLed: PropTypes.bool,
  isSelfLed: PropTypes.bool,
  isStartingCall: PropTypes.bool,
  enableAssignmentVideoCall: PropTypes.func,
  cardId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Assignment.defaultProps = {
  className: '',
  readOnly: false,
  isInstructor: false,
  onStartMeetingClick: () => {},
  isLeaderLed: false,
  isSelfLed: false,
  isStartingCall: false,
  enableAssignmentVideoCall: () => {},
  cardId: undefined,
}

export default React.memo(Assignment)
