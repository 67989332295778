import humps from 'humps'
import { fromJS } from 'immutable'

import { Search } from '_models/'
import { createReducer } from '_utils/redux'
import { FAVORITE_COURSE, UNFAVORITE_COURSE } from '_modules/course/actions'
import { HIDE_POSTS, BLOCK_USER } from '_modules/user/actions'
import {
  WEBSOCKET_POST_SHAZAMED,
  WEBSOCKET_POST_UNSHAZAMED,
  WEBSOCKET_COMMENT_SHAZAMED,
  WEBSOCKET_COMMENT_UNSHAZAMED,
  WEBSOCKET_POST_UPDATED,
  WEBSOCKET_COMMENT_UPDATED,
  WEBSOCKET_NEW_COMMENT,
} from '_modules/websocket/actions'
import POST_ACTIONS from '_modules/post/actions'

import { SEARCH, CLEAR_QUERY, SHAZAM_SEARCH_POST, UNSHAZAM_SEARCH_POST } from './actions'

const INITIAL_STATE = new Search()

const search = createReducer(INITIAL_STATE, {
  [SEARCH.FULFILLED]: (state, { payload }) => new Search(payload),

  [CLEAR_QUERY]: () => INITIAL_STATE,

  [FAVORITE_COURSE.FULFILLED]: (state, { meta }) => {
    const courseIndex = state.get('courses').findIndex(course => course.get('id') === meta.courseId)
    return state.setIn(['courses', courseIndex, 'favorite'], true)
  },

  [UNFAVORITE_COURSE.FULFILLED]: (state, { meta }) => {
    const courseIndex = state.get('courses').findIndex(course => course.get('id') === meta.courseId)
    return state.setIn(['courses', courseIndex, 'favorite'], false)
  },

  [BLOCK_USER.FULFILLED]: (state, { meta }) => state
      .set(
        'posts',
        state.get('posts').filter(post => {
          if (post.getIn(['author', 'id']) !== meta.userId) {
            return post
              .get('comments')
              .filter(comment => comment.getIn(['author', 'id']) !== meta.userId)
          }
          return false
        })
      )
      .set(
        'users',
        state.get('users').filter(user => {
          if (user.get('id') !== meta.userId) {
            return user
          }
          return false
        })
      )
      .set(
        'messages',
        state.get('messages').filter(message => {
          if (message.getIn(['author', 'id']) !== meta.userId) {
            return message
          }
          return false
        })
      ),

  [HIDE_POSTS.FULFILLED]: (state, { meta }) => state.set(
      'posts',
      state.get('posts').filter(post => {
        if (post.getIn(['author', 'username']) !== meta.username) {
          return post
        }
        return false
      })
    ),

  [POST_ACTIONS.EDIT_POST.FULFILLED]: (state, { payload }) => {
    const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.id)

    return state.setIn(['posts', postIndex], fromJS(humps.camelizeKeys(payload)))
  },

  // it's always a comment
  [POST_ACTIONS.CREATE_POST.FULFILLED]: (state, { payload }) => {
    const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.id)
    return state.setIn(['posts', postIndex], fromJS(humps.camelizeKeys(payload)))
  },

  [SHAZAM_SEARCH_POST.FULFILLED]: (state, { meta }) => {
    const postId = meta.fatherPostId || meta.postId
    const postIndex = state.get('posts').findIndex(post => post.get('id') === postId)

    if (meta.fatherPostId) {
      const commentIndex = state
        .getIn(['posts', postIndex, 'comments'])
        .findIndex(comment => comment.get('id') === meta.postId)

      return state.updateIn(['posts', postIndex, 'comments', commentIndex], post =>
        post.set('shazams', post.get('shazams') + 1).set('shazamedByYou', true)
      )
    }

    return state.updateIn(['posts', postIndex], post =>
      post.set('shazams', post.get('shazams') + 1).set('shazamedByYou', true)
    )
  },

  [UNSHAZAM_SEARCH_POST.FULFILLED]: (state, { meta }) => {
    const postId = meta.fatherPostId || meta.postId
    const postIndex = state.get('posts').findIndex(post => post.get('id') === postId)

    if (meta.fatherPostId) {
      const commentIndex = state
        .getIn(['posts', postIndex, 'comments'])
        .findIndex(comment => comment.get('id') === meta.postId)

      return state.updateIn(['posts', postIndex, 'comments', commentIndex], post =>
        post.set('shazams', post.get('shazams') - 1).set('shazamedByYou', false)
      )
    }

    return state.updateIn(['posts', postIndex], post =>
      post.set('shazams', post.get('shazams') - 1).set('shazamedByYou', false)
    )
  },

  [WEBSOCKET_POST_SHAZAMED]: (state, { payload }) => {
    try {
      const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.post.id)
      return state.updateIn(['posts', postIndex], post =>
        post.set('shazams', post.get('shazams') + 1)
      )
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_POST_UNSHAZAMED]: (state, { payload }) => {
    try {
      const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.post.id)
      return state.updateIn(['posts', postIndex], post =>
        post.set('shazams', post.get('shazams') - 1)
      )
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_COMMENT_SHAZAMED]: (state, { payload }) => {
    try {
      const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.postId)

      const commentIndex = state
        .getIn(['posts', postIndex, 'comments'])
        .findIndex(comment => comment.get('id') === payload.comment.id)

      return state.updateIn(['posts', postIndex, 'comments', commentIndex], comment =>
        comment.set('shazams', comment.get('shazams') + 1)
      )
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_COMMENT_UNSHAZAMED]: (state, { payload }) => {
    try {
      const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.postId)

      const commentIndex = state
        .getIn(['posts', postIndex, 'comments'])
        .findIndex(comment => comment.get('id') === payload.comment.id)

      return state.updateIn(['posts', postIndex, 'comments', commentIndex], comment =>
        comment.set('shazams', comment.get('shazams') - 1)
      )
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_POST_UPDATED]: (state, { payload }) => {
    try {
      const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.post.id)
      return state.setIn(['posts', postIndex], fromJS(payload.post))
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_COMMENT_UPDATED]: (state, { payload }) => {
    try {
      const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.postId)

      const commentIndex = state
        .getIn(['posts', postIndex, 'comments'])
        .findIndex(comment => comment.get('id') === payload.comment.id)

      return state.setIn(['posts', postIndex, 'comments', commentIndex], fromJS(payload.comment))
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_NEW_COMMENT]: (state, { payload }) => {
    try {
      const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.postId)

      return state.setIn(
        ['posts', postIndex, 'comments'],
        state.getIn(['posts', postIndex, 'comments']).push(fromJS(payload.comment))
      )
    } catch (err) {
      return state
    }
  },
})

export default search
