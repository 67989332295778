import React from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import AccordionElement from './accordion-element'
import styles from './styles.css'

const Accordion = ({ accordionList, className }) => (
    <div className={classNames(styles['accordion-container'], className)}>
      {accordionList.map((item, index) => (
        <AccordionElement
          key={item.id}
          sectionNumber={index + 1}
          sectionTitle={item.name}
          assignments={item.assignments}
          elementContent={item.elementContent}
        />
      ))}
    </div>
  )

Accordion.propTypes = {
  accordionList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  className: PropTypes.string,
}

Accordion.defaultProps = {
  className: '',
}

export default Accordion
