import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import { prismicTitlePropType } from '_utils/proptypes'
import SectionTitle from '_components/landing-components/section-title'

import styles from './styles.css'

const ListItemSection = ({ slice, id }) => {
  const backgroundStyles = useMemo(
    () =>
      slice.backgroundImage?.thumbnail
        ? {
            backgroundImage: `url(${slice.backgroundImage.thumbnail})`,
          }
        : {},
    [slice.backgroundImage]
  )

  return (
    <section className={styles.section} id={id} style={backgroundStyles}>
      <div className={styles.content}>
        {slice.sectionTitle && <SectionTitle title={slice.sectionTitle} className={styles.title} />}
        {slice.items?.length > 0 && (
          <div className={styles['descriptions-container']}>
            {slice.items.map(item => (
              <div
                className={styles['description-content']}
                key={`list-item-${item.itemDescription}`}
              >
                <img
                  className={styles.icon}
                  alt={item.itemIcon.alt || 'List Bullet'}
                  src={item.itemIcon.url}
                />
                <p className={styles.description}>{item.itemDescription}</p>
              </div>
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

ListItemSection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    backgroundImage: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
    items: PropTypes.arrayOf(
      PropTypes.shape({
        itemDescription: PropTypes.string,
        itemIcon: PropTypes.shape({
          alt: PropTypes.string,
          url: PropTypes.string,
        }),
      })
    ),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(ListItemSection)
