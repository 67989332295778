import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'

import { userShape, profileShape, postShape } from '_utils/proptypes'
import PROFILE_ACTIONS from '_modules/profile/actions'
import AllGoals from '_views/all-goals'
import RocketLoader from '_components/rocket-loader'
import { follow, unfollow } from '_modules/user/actions'
import { sendDirectMessage, SEND_DIRECT_MESSAGE } from '_modules/chat/actions'
import { usePrevious } from '_utils/hooks'
import { NOT_FOUND } from '_utils/constants'
import { getSelectedPost } from '_modules/profile/selectors'
import { checkSubscriptionExpired } from '_utils/subscription'

import Profile from './profile'
import styles from './styles.css'

const {
  GET_USER_POSTS,
  myFuture,
  listPosts,
  getSystemUser,
  getUserPosts,
  GET_SYSTEM_USER,
  getPost,
  GET_POST,
} = PROFILE_ACTIONS

const mapStateToProps = ({ user, profile, loading, error }, { postId }) => ({
  user,
  profile: profile.toJS(),
  isLoadingSystemUser: !!loading.get(GET_USER_POSTS.ACTION),
  isSendingMessage: !!loading.get(SEND_DIRECT_MESSAGE.ACTION),
  sendMessageError: error.get(SEND_DIRECT_MESSAGE.ACTION),
  isGettingUserProfile: !!loading.get(GET_SYSTEM_USER.ACTION),
  getSystemUserError: error.get(GET_SYSTEM_USER.ACTION, Map()),
  selectedPost: getSelectedPost(profile, Number(postId)),
  isLoadingSelectedPost: !!loading.get(GET_POST.ACTION),
  loadingSelectedPostError: error.get(GET_POST.ACTION),
})

const mapDispatchToProps = {
  getMyFuture: myFuture,
  getAllPosts: listPosts,
  getUserProfile: getSystemUser,
  getUserAllPosts: getUserPosts,
  followUser: follow,
  unfollowUser: unfollow,
  sendMessage: sendDirectMessage,
  getUserPost: getPost,
}

const onClickManageAccount = () => navigate('/account')

const UserProfile = ({
  location,
  user,
  username,
  getMyFuture,
  getAllPosts,
  profile,
  getUserProfile,
  getUserAllPosts,
  isLoadingSystemUser,
  followUser,
  unfollowUser,
  sendMessage,
  isSendingMessage,
  sendMessageError,
  isGettingUserProfile,
  getSystemUserError,
  postId,
  getUserPost,
  selectedPost,
  isLoadingSelectedPost,
  loadingSelectedPostError,
}) => {
  const myProfile = useMemo(() => username === user.username, [user.username, username])
  const isGoalsView = useMemo(() => location.pathname.includes('/goals'), [location.pathname])
  const isSubscriptionExpired = checkSubscriptionExpired(user)

  const wasGettingUserProfile = usePrevious(isGettingUserProfile)
  const wasLoadingSelectedPost = usePrevious(isLoadingSelectedPost)

  useEffect(
    () => {
      if (postId && !isSubscriptionExpired) {
        getUserPost(postId)
      } else {
        if (myProfile) {
          getMyFuture()
          if (!isSubscriptionExpired) {
            getAllPosts(true)
          }
          return
        }

        if (!isGoalsView && !isSubscriptionExpired) {
          getUserAllPosts(username)
        }
      }

      getUserProfile(username)
    },
    [
      getAllPosts,
      getMyFuture,
      getUserAllPosts,
      getUserPost,
      getUserProfile,
      postId,
      myProfile,
      username,
      location.pathname,
      isGoalsView,
      isSubscriptionExpired,
    ]
  )

  useEffect(
    () => {
      const hasError =
        (wasGettingUserProfile &&
          !isGettingUserProfile &&
          getSystemUserError.get('detail') === NOT_FOUND) ||
        (wasLoadingSelectedPost &&
          !isLoadingSelectedPost &&
          loadingSelectedPostError.get('detail') === NOT_FOUND)

      if (hasError) {
        navigate('/404')
      }
    },
    [
      getSystemUserError,
      isGettingUserProfile,
      isLoadingSelectedPost,
      loadingSelectedPostError,
      wasGettingUserProfile,
      wasLoadingSelectedPost,
    ]
  )

  const selectedUser = useMemo(() => (myProfile ? user : profile.user), [
    myProfile,
    profile.user,
    user,
  ])

  const isLoading = useMemo(
    () => isLoadingSystemUser || (postId && !selectedPost) || (!selectedUser && !myProfile),
    [isLoadingSystemUser, myProfile, postId, selectedPost, selectedUser]
  )

  if (isLoading) {
    return (
      <div className={styles.loading}>
        <RocketLoader />
      </div>
    )
  }

  if (isGoalsView) {
    return (
      <AllGoals
        username={username}
        user={selectedUser}
        goalCategories={profile.goalCategories}
        myProfile={myProfile}
        shouldScrollToGoal={
          wasGettingUserProfile && !isGettingUserProfile && !getSystemUserError.size
        }
      />
    )
  }

  if (postId) {
    return (
      <Profile
        onClickMessage={sendMessage}
        onClickManageAccount={onClickManageAccount}
        username={username}
        user={selectedUser}
        profile={profile}
        myProfile={myProfile}
        onClickFollow={followUser}
        onClickUnfollow={unfollowUser}
        sendDirectMessage={sendMessage}
        sendMessageError={sendMessageError}
        isLoading={isSendingMessage}
        selectedPost={selectedPost}
      />
    )
  }

  return (
    <Profile
      onClickMessage={sendMessage}
      onClickManageAccount={onClickManageAccount}
      username={username}
      user={selectedUser}
      profile={profile}
      myProfile={myProfile}
      onClickFollow={followUser}
      onClickUnfollow={unfollowUser}
      sendDirectMessage={sendMessage}
      sendMessageError={sendMessageError}
      isLoading={isSendingMessage}
    />
  )
}

UserProfile.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  user: userShape.isRequired,
  profile: profileShape.isRequired,
  username: PropTypes.string.isRequired,
  getMyFuture: PropTypes.func.isRequired,
  getAllPosts: PropTypes.func.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  getUserAllPosts: PropTypes.func.isRequired,
  isLoadingSystemUser: PropTypes.bool.isRequired,
  followUser: PropTypes.func.isRequired,
  unfollowUser: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  isSendingMessage: PropTypes.bool.isRequired,
  sendMessageError: PropTypes.instanceOf(Map),
  isGettingUserProfile: PropTypes.bool.isRequired,
  getSystemUserError: PropTypes.instanceOf(Map),
  getUserPost: PropTypes.func.isRequired,
  postId: PropTypes.string,
  selectedPost: postShape,
  isLoadingSelectedPost: PropTypes.bool.isRequired,
  loadingSelectedPostError: PropTypes.instanceOf(Map),
}

UserProfile.defaultProps = {
  sendMessageError: Map(),
  getSystemUserError: Map(),
  postId: null,
  selectedPost: null,
  loadingSelectedPostError: Map(),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(UserProfile))
