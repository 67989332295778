import React from 'react'
import PropTypes from 'prop-types'

import CloseIcon from '_assets/icons/close.svg'
import Card, { CardSize } from '_components/card'

import styles from './styles.css'

const RemovedGoalModal = ({ onClose }) => (
  <Card size={CardSize.LARGE} className={styles.container}>
    <h1 className={styles.title}>Whoops!</h1>
    <button
      type="button"
      className={styles.close}
      icon={CloseIcon}
      onClick={onClose}
      aria-label="Close modal"
    >
      <svg aria-label="Close icon" role="img" className={styles.icon} viewBox={CloseIcon.viewBox}>
        <use xlinkHref={`#${CloseIcon.id}`} />
      </svg>
    </button>
    <p className={styles.content}>
      That goal has been removed, in the meantime, check out all these awesome goals
      {' \uD83D\uDE42'}
    </p>
  </Card>
)

RemovedGoalModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}
export default React.memo(RemovedGoalModal)
