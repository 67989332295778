import * as chatService from '_services/chat'
import { defineAction } from '_utils/redux'

export const GET_MY_CHATS = defineAction('GET_MY_CHATS')
export const GET_CHANNEL_MESSAGES = defineAction('GET_CHANNEL_MESSAGES')
export const GET_DIRECT_MESSAGES = defineAction('GET_DIRECT_MESSAGES')
export const SEND_DIRECT_MESSAGE = defineAction('SEND_DIRECT_MESSAGE')
export const SEND_CHANNEL_MESSAGE = defineAction('SEND_CHANNEL_MESSAGE')
export const READ_CHANNEL_MESSAGE = defineAction('READ_CHANNEL_MESSAGE')
export const READ_USER_MESSAGE = defineAction('READ_USER_MESSAGE')
export const GET_CHANNEL_USERS = defineAction('GET_CHANNEL_USERS')
export const DELETE_CHANNEL_MESSAGE = defineAction('DELETE_CHANNEL_MESSAGE')
export const CREATE_PRE_DIRECT_MESSAGE = 'CREATE_PRE_DIRECT_MESSAGE'
export const DISABLE_CHANNEL = defineAction('DISABLE_CHANNEL')
export const ENABLE_CHANNEL = defineAction('ENABLE_CHANNEL')
export const REMOVE_USER_FROM_CHANNEL = defineAction('REMOVE_USER_FROM_CHANNEL')
export const UPDATE_CHANNEL = defineAction('UPDATE_CHANNEL')
export const MAKE_ADMIN = defineAction('MAKE_ADMIN')
export const REVOKE_ADMIN = defineAction('REVOKE_ADMIN')
export const GET_CHANNELS = defineAction('GET_CHANNELS')
export const CREATE_CHANNEL = defineAction('CREATE_CHANNEL')
export const END_CHANNEL_MEETING = defineAction('END_CHANNEL_MEETING')
export const END_DIRECT_MESSAGE_MEETING = defineAction('END_DIRECT_MESSAGE_MEETING')
export const JOIN_CHANNEL = defineAction('JOIN_CHANNEL')
export const INVITE_TO_CHANNEL = defineAction('INVITE_TO_CHANNEL')
export const GET_USERS_AVAILABLE_TO_INVITE = defineAction('GET_USERS_AVAILABLE_TO_INVITE')
export const CLEAN_INITIAL_CHANNEL = 'CLEAN_INITIAL_CHANNEL'
export const START_CHANNEL_VIDEO_CHAT = defineAction('START_CHANNEL_VIDEO_CHAT')
export const START_DIRECT_MESSAGE_VIDEO_CHAT = defineAction('START_DIRECT_MESSAGE_VIDEO_CHAT')
export const STORE_LAST_CHECKPOINT = 'STORE_LAST_CHECKPOINT'

export const getMyChats = limit => (dispatch, getState) => {
  dispatch({
    type: GET_MY_CHATS.ACTION,
    payload: chatService.getMyChats(getState().user.authToken, limit),
  })
}

export const getChannelMessages = (channelId, page = null) => (dispatch, getState) => {
  dispatch({
    type: GET_CHANNEL_MESSAGES.ACTION,
    payload: chatService.getChannelMessages(getState().user.authToken)(channelId, page),
    meta: {
      channelId,
      page,
    },
  })
}

export const getDirectMessages = (username, page = null) => (dispatch, getState) => {
  dispatch({
    type: GET_DIRECT_MESSAGES.ACTION,
    payload: chatService.getDirectMessages(getState().user.authToken)(username, page),
    meta: {
      username,
      page,
    },
  })
}

export const sendDirectMessage = ({ isNewDirectMessage, name, photo, ...payload }) => (
  dispatch,
  getState
) => {
  dispatch({
    type: SEND_DIRECT_MESSAGE.ACTION,
    payload: chatService.sendDirectMessage(getState().user.authToken)(payload),
    meta: {
      username: payload.username,
      isNewDirectMessage,
      recipient: {
        name,
        photo,
        id: payload.id,
        username: payload.username,
      },
    },
  })
}

export const sendChannelMessage = (channelId, payload) => (dispatch, getState) => {
  dispatch({
    type: SEND_CHANNEL_MESSAGE.ACTION,
    payload: chatService.sendChannelMessage(getState().user.authToken)(channelId, payload),
    meta: {
      channelId,
    },
  })
}

export const readChannelMessages = channelId => (dispatch, getState) => {
  dispatch({
    type: READ_CHANNEL_MESSAGE.ACTION,
    payload: chatService.readChannelMessages(getState().user.authToken)(channelId),
    meta: {
      channelId,
    },
  })
}

export const readUserMessages = username => (dispatch, getState) => {
  dispatch({
    type: READ_USER_MESSAGE.ACTION,
    payload: chatService.readUserMessages(getState().user.authToken)(username),
    meta: {
      username,
    },
  })
}

export const getUsersFromChannel = ({ channelId, page = null, search = null }) => (
  dispatch,
  getState
) => {
  dispatch({
    type: GET_CHANNEL_USERS.ACTION,
    payload: chatService.getUsersFromChannel(getState().user.authToken)(channelId, {
      page,
      search,
    }),
    meta: {
      channelId,
      page,
    },
  })
}

export const deleteMessageFromChannel = ({ channelId, messageId }) => (dispatch, getState) => {
  dispatch({
    type: DELETE_CHANNEL_MESSAGE.ACTION,
    payload: chatService.deleteMessageFromChannel(getState().user.authToken)(channelId, messageId),
    meta: {
      channelId,
      messageId,
    },
  })
}

export const createPreDirectMessage = payload => ({
  type: CREATE_PRE_DIRECT_MESSAGE,
  payload,
})

export const disableChannel = channelId => (dispatch, getState) => {
  dispatch({
    type: DISABLE_CHANNEL.ACTION,
    payload: chatService.disableChannel(getState().user.authToken)(channelId),
    meta: {
      channelId,
    },
  })
}

export const enableChannel = channelId => (dispatch, getState) => {
  dispatch({
    type: ENABLE_CHANNEL.ACTION,
    payload: chatService.enableChannel(getState().user.authToken)(channelId),
    meta: {
      channelId,
    },
  })
}

export const removeUserFromChannel = ({ channelId, userId }) => (dispatch, getState) => {
  dispatch({
    type: REMOVE_USER_FROM_CHANNEL.ACTION,
    payload: chatService.removeUserFromChannel(getState().user.authToken)(channelId, userId),
  })
}

export const updateChannel = payload => (dispatch, getState) => {
  dispatch({
    type: UPDATE_CHANNEL.ACTION,
    payload: chatService.updateChannel(getState().user.authToken)(payload),
    meta: {
      ...payload,
    },
  })
}

export const makeAdmin = payload => (dispatch, getState) => {
  dispatch({
    type: MAKE_ADMIN.ACTION,
    payload: chatService.makeAdmin(getState().user.authToken)(payload),
    meta: {
      ...payload,
    },
  })
}

export const revokeAdmin = payload => (dispatch, getState) => {
  dispatch({
    type: REVOKE_ADMIN.ACTION,
    payload: chatService.revokeAdmin(getState().user.authToken)(payload),
    meta: {
      ...payload,
    },
  })
}

export const getChannels = params => (dispatch, getState) => {
  dispatch({
    type: GET_CHANNELS.ACTION,
    payload: chatService.getChannels(getState().user.authToken)(params),
    meta: {
      ...params,
    },
  })
}

export const createChannel = payload => (dispatch, getState) => {
  dispatch({
    type: CREATE_CHANNEL.ACTION,
    payload: chatService.createChannel(getState().user.authToken)(payload),
  })
}

export const endChannelZoomMeeting = ({ channelId, meetingId }) => (dispatch, getState) => {
  dispatch({
    type: END_CHANNEL_MEETING.ACTION,
    payload: chatService.endZoomMeeting(getState().user.authToken)(meetingId),
    meta: {
      meetingId,
      channelId,
    },
  })
}

export const endDirectMessageZoomMeeting = ({ userId, meetingId }) => (dispatch, getState) => {
  dispatch({
    type: END_DIRECT_MESSAGE_MEETING.ACTION,
    payload: chatService.endZoomMeeting(getState().user.authToken)(meetingId),
    meta: {
      meetingId,
      userId,
    },
  })
}

export const joinToChannel = channelId => (dispatch, getState) => {
  dispatch({
    type: JOIN_CHANNEL.ACTION,
    payload: chatService.joinToChannel(getState().user.authToken)(channelId),
  })
}

export const inviteToChannel = ({ channelId, user }) => (dispatch, getState) => {
  dispatch({
    type: INVITE_TO_CHANNEL.ACTION,
    payload: chatService.inviteToChannel(getState().user.authToken)({ channelId, user }),
  })
}

export const getAvailableToInvite = ({ channelId, page = null, search = null }) => (
  dispatch,
  getState
) => {
  dispatch({
    type: GET_USERS_AVAILABLE_TO_INVITE.ACTION,
    payload: chatService.getAvailableToInvite(getState().user.authToken)({
      channelId,
      page,
      search,
    }),
    meta: {
      page,
    },
  })
}

export const cleanInitialMessage = () => ({
  type: CLEAN_INITIAL_CHANNEL,
})

export const startVideoChannelCall = payload => (dispatch, getState) =>
  dispatch({
    type: START_CHANNEL_VIDEO_CHAT.ACTION,
    payload: chatService.startVideoChannelCall(getState().user.authToken)(payload),
    meta: {
      channelId: payload.channelId,
    },
  })

export const startVideoDirectMessageCall = payload => (dispatch, getState) =>
  dispatch({
    type: START_DIRECT_MESSAGE_VIDEO_CHAT.ACTION,
    payload: chatService.startVideoDirectMessageCall(getState().user.authToken)(payload),
    meta: {
      userId: payload.userId,
      username: payload.username,
    },
  })

export const storeLastCheckpoint = payload => ({
  type: STORE_LAST_CHECKPOINT,
  payload,
})
