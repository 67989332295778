import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'

import { paymentHistoryShape } from '_utils/proptypes'

import styles from './styles.css'

const PaymentHistory = ({ className, paymentHistory }) => (
  <div className={classnames(styles.container, className)}>
    {paymentHistory.length === 0 ? (
      <p>You haven’t purchased anything yet.</p>
    ) : (
      paymentHistory.map(payment => (
        <div key={payment.id} className={styles.payment}>
          <p className={styles.bold}>${(payment.amount / 100).toFixed(2)}</p>
          <p>{moment(payment.createdAt).format('MM/DD/YYYY')}</p>
          <div className={styles['credit-card-info']}>
            <p>Payment</p>
            <div className={styles.dot} />
            <p className={styles.capitalize}>{payment.cardBrand}</p>{' '}
            <p>ending {payment.cardLast4}</p>
          </div>
          <p>{payment.description}</p>
        </div>
      ))
    )}
  </div>
)

PaymentHistory.propTypes = {
  className: PropTypes.string,
  paymentHistory: PropTypes.arrayOf(paymentHistoryShape).isRequired,
}

PaymentHistory.defaultProps = {
  className: '',
}

export default PaymentHistory
