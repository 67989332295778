import * as userService from '_services/user'
import { defineAction } from '_utils/redux'

export const LOGIN = defineAction('LOGIN')
export const SIGN_UP = defineAction('SIGN_UP')
export const FACEBOOK_LOGIN = defineAction('FACEBOOK_LOGIN')
export const GOOGLE_LOGIN = defineAction('GOOGLE_LOGIN')
export const CHANGE_PASSWORD = defineAction('CHANGE_PASSWORD')
export const CHANGE_EMAIL = defineAction('CHANGE_EMAIL')
export const RESET_PASSWORD = defineAction('RESET_PASSWORD')
export const CONFIRM_RESET_PASSWORD = defineAction('CONFIRM_RESET_PASSWORD')
export const UPDATE_USER = defineAction('UPDATE_USER')
export const START_MEMBERSHIP = defineAction('START_MEMBERSHIP')
export const GET_AVAILABLE_MEMBERSHIPS = defineAction('GET_AVAILABLE_MEMBERSHIPS')
export const GET_USER = defineAction('GET_USER')
export const FOLLOW_USER = defineAction('FOLLOW_USER')
export const FOLLOW_LEADER = defineAction('FOLLOW_LEADER')
export const UNFOLLOW_USER = defineAction('UNFOLLOW_USER')
export const GET_PAYMENT_HISTORY = defineAction('GET_PAYMENT_HISTORY')
export const CANCEL_SUBSCRIPTION = defineAction('CANCEL_SUBSCRIPTION')
export const RESTART_SUBSCRIPTION = defineAction('RESTART_SUBSCRIPTION')
export const GET_MY_FUTURE = defineAction('GET_MY_FUTURE')
export const UPDATE_MY_FUTURE = defineAction('UPDATE_MY_FUTURE')
export const LOGOUT_USER = 'LOGOUT_USER'
export const HIDE_POSTS = defineAction('HIDE_POSTS')
export const GET_USERS_AVAILABLE_TO_CHAT = defineAction('GET_USERS_AVAILABLE_TO_CHAT')
export const BLOCK_USER = defineAction('BLOCK_USER')
export const LINK_ZOOM = defineAction('LINK_ZOOM')
export const UNAUTHORIZED = 'UNAUTHORIZED'
export const SHAZAM_GOAL = defineAction('SHAZAM_GOAL')
export const UNSHAZAM_GOAL = defineAction('UNSHAZAM_GOAL')
export const GET_HIDDEN_USERS = defineAction('GET_HIDDEN_USERS')
export const SHOW_POSTS = defineAction('SHOW_POSTS')
export const DEACTIVATE_ZOOM = defineAction('DEACTIVATE_ZOOM')
export const CHECK_SOCIAL_SIGN_UP = 'CHECK_SOCIAL_SIGN_UP'

export const login = payload => dispatch =>
  dispatch({
    type: LOGIN.ACTION,
    payload: userService.login(payload),
  })

export const signUp = payload => dispatch =>
  dispatch({
    type: SIGN_UP.ACTION,
    payload: userService.signUp(payload),
  })

export const facebookLogin = payload => dispatch =>
  dispatch({
    type: FACEBOOK_LOGIN.ACTION,
    payload: userService.facebookLogin(payload),
  })

export const googleLogin = payload => dispatch =>
  dispatch({
    type: GOOGLE_LOGIN.ACTION,
    payload: userService.googleLogin(payload),
  })

export const changePassword = payload => (dispatch, getState) =>
  dispatch({
    type: CHANGE_PASSWORD.ACTION,
    payload: userService.changePassword(getState().user.authToken)(payload),
  })

export const changeEmail = payload => (dispatch, getState) =>
  dispatch({
    type: CHANGE_EMAIL.ACTION,
    payload: userService.changeEmail(getState().user.authToken)(payload),
  })

export const resetPassword = payload => dispatch =>
  dispatch({
    type: RESET_PASSWORD.ACTION,
    payload: userService.resetPassword(payload),
  })

export const confirmResetPassword = payload => dispatch =>
  dispatch({
    type: CONFIRM_RESET_PASSWORD.ACTION,
    payload: userService.confirmResetPassword(payload),
  })

export const getUser = () => (dispatch, getState) => {
  dispatch({
    type: GET_USER.ACTION,
    payload: userService.getUser(getState().user.authToken),
  })
}

export const paymentHistory = (page = null) => (dispatch, getState) => {
  dispatch({
    type: GET_PAYMENT_HISTORY.ACTION,
    payload: userService.paymentHistory(getState().user.authToken)(page),
    meta: {
      page,
    },
  })
}

export const cancelSubscription = () => (dispatch, getState) => {
  dispatch({
    type: CANCEL_SUBSCRIPTION.ACTION,
    payload: userService.cancelSubscription(getState().user.authToken),
  })
}

export const restartSubscription = () => (dispatch, getState) => {
  dispatch({
    type: RESTART_SUBSCRIPTION.ACTION,
    payload: userService.restartSubscription(getState().user.authToken),
  })
}

export const myFuture = () => (dispatch, getState) => {
  dispatch({
    type: GET_MY_FUTURE.ACTION,
    payload: userService.myFuture(getState().user.authToken),
  })
}

export const updateMyFuture = payload => (dispatch, getState) =>
  dispatch({
    type: UPDATE_MY_FUTURE.ACTION,
    payload: userService.updateMyFuture(getState().user.authToken)(payload),
  })

export const updateUser = payload => (dispatch, getState) =>
  dispatch({
    type: UPDATE_USER.ACTION,
    payload: userService.updateUser(getState().user.authToken)(payload),
  })

export const startMembership = payload => (dispatch, getState) =>
  dispatch({
    type: START_MEMBERSHIP.ACTION,
    payload: userService.startMembership(getState().user.authToken)(payload),
  })

export const getAvailableMemberships = () => (dispatch, getState) =>
  dispatch({
    type: GET_AVAILABLE_MEMBERSHIPS.ACTION,
    payload: userService.getAvailableMemberships(getState().user.authToken),
  })

export const follow = ({ username, userId, classId, peopleYouMayKnow = null }) => (
  dispatch,
  getState
) =>
  dispatch({
    type: FOLLOW_USER.ACTION,
    payload: userService.follow(getState().user.authToken)(username),
    meta: {
      username,
      peopleYouMayKnow,
      userId,
      classId,
    },
  })

export const followLeader = ({ username, userId, classId }) => (dispatch, getState) =>
  dispatch({
    type: FOLLOW_LEADER.ACTION,
    payload: userService.follow(getState().user.authToken)(username),
    meta: {
      username,
      userId,
      classId,
    },
  })

export const unfollow = ({ username, peopleYouMayKnow = null }) => (dispatch, getState) =>
  dispatch({
    type: UNFOLLOW_USER.ACTION,
    payload: userService.unfollow(getState().user.authToken)(username),
    meta: {
      username,
      peopleYouMayKnow,
    },
  })

export const logout = () => ({
  type: LOGOUT_USER,
})

export const hidePosts = username => (dispatch, getState) =>
  dispatch({
    type: HIDE_POSTS.ACTION,
    payload: userService.hidePosts(getState().user.authToken)(username),
    meta: {
      username,
    },
  })

export const getUsersAvailableToChat = ({ page, search }) => (dispatch, getState) =>
  dispatch({
    type: GET_USERS_AVAILABLE_TO_CHAT.ACTION,
    payload: userService.getUsersAvailableToChat(getState().user.authToken)(page, search),
    meta: {
      page,
    },
  })

export const blockUser = ({ username, ...params }) => (dispatch, getState) =>
  dispatch({
    type: BLOCK_USER.ACTION,
    payload: userService.blockUser(getState().user.authToken)(username),
    meta: {
      username,
      ...params,
    },
  })

export const linkZoom = payload => (dispatch, getState) => {
  dispatch({
    type: LINK_ZOOM.ACTION,
    payload: userService.linkZoom(getState().user.authToken)(payload),
  })
}

export const shazamGoal = payload => (dispatch, getState) =>
  dispatch({
    type: SHAZAM_GOAL.ACTION,
    payload: userService.shazamGoal(getState().user.authToken)(payload),
    meta: {
      ...payload,
    },
  })

export const unshazamGoal = payload => (dispatch, getState) =>
  dispatch({
    type: UNSHAZAM_GOAL.ACTION,
    payload: userService.unshazamGoal(getState().user.authToken)(payload),
    meta: {
      ...payload,
    },
  })

export const getHiddenUsers = () => (dispatch, getState) =>
  dispatch({
    type: GET_HIDDEN_USERS.ACTION,
    payload: userService.getHiddenUsers(getState().user.authToken),
  })

export const showUserPosts = username => (dispatch, getState) => {
  dispatch({
    type: SHOW_POSTS.ACTION,
    payload: userService.showUserPosts(getState().user.authToken)(username),
    meta: {
      username,
    },
  })
}

export const deactivateZoom = () => (dispatch, getState) =>
  dispatch({
    type: DEACTIVATE_ZOOM.ACTION,
    payload: userService.deactivateZoom(getState().user.authToken),
  })

export const checkSocialSignUp = () => ({
  type: CHECK_SOCIAL_SIGN_UP,
})
