import createActionCreators from '_utils/create-action-creators'
import * as userService from '_services/user'
import { defineAction } from '_utils/redux'

const GET_MY_FUTURE = defineAction('GET_MY_FUTURE')
const LIST_POSTS = defineAction('LIST_POSTS')
const UPDATE_MY_FUTURE = defineAction('UPDATE_MY_FUTURE')
const CREATE_GOAL_CATEGORY = defineAction('CREATE_GOAL_CATEGORY')
const UPDATE_GOAL_CATEGORY = defineAction('UPDATE_GOAL_CATEGORY')
const DELETE_GOAL_CATEGORY = defineAction('DELETE_GOAL_CATEGORY')
const CREATE_GOAL = defineAction('CREATE_GOAL')
const UPDATE_GOAL = defineAction('UPDATE_GOAL')
const DELETE_GOAL = defineAction('DELETE_GOAL')
const GET_SYSTEM_USER = defineAction('GET_SYSTEM_USER')
const GET_USER_POSTS = defineAction('GET_USER_POSTS')
const GET_POST = defineAction('GET_POST')

const actionCreators = {
  ...createActionCreators('PROFILE'),
  GET_MY_FUTURE,
  myFuture: () => (dispatch, getState) => {
    dispatch({
      type: GET_MY_FUTURE.ACTION,
      payload: userService.myFuture(getState().user.authToken),
    })
  },
  LIST_POSTS,
  listPosts: (mine = null, page = null) => (dispatch, getState) => {
    dispatch({
      type: LIST_POSTS.ACTION,
      payload: userService.listPosts(getState().user.authToken)(mine, page),
      meta: {
        mine,
        page,
      },
    })
  },
  UPDATE_MY_FUTURE,
  updateMyFuture: payload => (dispatch, getState) =>
    dispatch({
      type: UPDATE_MY_FUTURE.ACTION,
      payload: userService.updateMyFuture(getState().user.authToken)(payload),
    }),
  CREATE_GOAL_CATEGORY,
  createGoalCategory: payload => (dispatch, getState) =>
    dispatch({
      type: CREATE_GOAL_CATEGORY.ACTION,
      payload: userService.createGoalCategory(getState().user.authToken)(payload),
    }),
  UPDATE_GOAL_CATEGORY,
  updateGoalCategory: (payload, id) => (dispatch, getState) =>
    dispatch({
      type: UPDATE_GOAL_CATEGORY.ACTION,
      payload: userService.updateGoalCategory(getState().user.authToken)(payload, id),
    }),
  DELETE_GOAL_CATEGORY,
  deleteGoalCategory: id => (dispatch, getState) =>
    dispatch({
      type: DELETE_GOAL_CATEGORY.ACTION,
      payload: userService.deleteGoalCategory(getState().user.authToken)(id),
      meta: {
        id,
      },
    }),
  CREATE_GOAL,
  createGoal: (categoryId, payload) => (dispatch, getState) =>
    dispatch({
      type: CREATE_GOAL.ACTION,
      payload: userService.createGoal(getState().user.authToken)(categoryId, payload),
    }),
  UPDATE_GOAL,
  updateGoal: (categoryId, goalId, payload) => (dispatch, getState) =>
    dispatch({
      type: UPDATE_GOAL.ACTION,
      payload: userService.updateGoal(getState().user.authToken)(categoryId, goalId, payload),
    }),
  DELETE_GOAL,
  deleteGoal: (categoryId, goalId) => (dispatch, getState) =>
    dispatch({
      type: DELETE_GOAL.ACTION,
      payload: userService.deleteGoal(getState().user.authToken)(categoryId, goalId),
    }),
  GET_SYSTEM_USER,
  getSystemUser: username => (dispatch, getState) =>
    dispatch({
      type: GET_SYSTEM_USER.ACTION,
      payload: userService.getSystemUser(getState().user.authToken)(username),
    }),
  GET_USER_POSTS,
  getUserPosts: (username, page) => (dispatch, getState) =>
    dispatch({
      type: GET_USER_POSTS.ACTION,
      payload: userService.getUserPosts(getState().user.authToken)(username, page),
      meta: {
        page,
      },
    }),
  GET_POST,
  getPost: postId => (dispatch, getState) =>
    dispatch({
      type: GET_POST.ACTION,
      payload: userService.getPost(getState().user.authToken)(postId),
    }),
}

export default actionCreators
