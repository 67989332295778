import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import Slider from 'react-slick'
import { List } from 'immutable'

import SliderArrow, { ARROW_DIRECTION } from '_components/slider-arrow'
import ClassCard from '_components/landing-components/class-card'
import useWindowSize from '_hooks/use-window-size'
import Spinner from '_components/spinner'
import { getAvailableClasses } from '_modules/public-classes/actions'
import {
  availableClassesNextPageSelector,
  isGetAvailableClassesLoadingSelector,
} from '_modules/public-classes/selectors'

import styles from './styles.css'

const MEDIA_QUERIES = {
  DESKTOP: 'DESKTOP',
  MEDIUM: 'MEDIUM',
  MOBILE: 'MOBILE',
}

const LAST_BUT_FIVE = 5

const CARDS_TO_SHOW_SCROLL = {
  DESKTOP: 1,
  MEDIUM: 2,
  MOBILE: 1,
}

const UpcomingClassesScrollList = ({ className, classesList, params, defaultArrows }) => {
  const { isDesktop, isMedium } = useWindowSize()
  const nextPage = useSelector(availableClassesNextPageSelector)

  const isLoadingMoreClasses = useSelector(isGetAvailableClassesLoadingSelector)
  const showArrow = typeof window !== 'undefined'
  const shouldUserLibClassName = useMemo(() => defaultArrows && !isMedium, [
    defaultArrows,
    isMedium,
  ])

  const handleMediaType = useMemo(
    () => {
      switch (true) {
        case isDesktop:
          return MEDIA_QUERIES.DESKTOP
        case isMedium:
          return MEDIA_QUERIES.MEDIUM
        default:
          return MEDIA_QUERIES.MOBILE
      }
    },
    [isDesktop, isMedium]
  )

  const dispatch = useDispatch()

  const onGetMoreClasses = useCallback(
    () => {
      dispatch(getAvailableClasses({ ...params, page: nextPage }, true))
    },
    [dispatch, nextPage, params]
  )

  const onSlideEnd = useCallback(
    index => index >= classesList?.size - LAST_BUT_FIVE ? onGetMoreClasses() : null,
    [classesList, onGetMoreClasses]
  )

  const settings = useMemo(
    () => ({
      speed: 500,
      infinite: false,
      afterChange: onSlideEnd,
      slidesToShow: CARDS_TO_SHOW_SCROLL[handleMediaType],
      slidesToScroll: CARDS_TO_SHOW_SCROLL[handleMediaType],
      variableWidth: true,
      prevArrow: showArrow ? (
        <SliderArrow
          disabled={isLoadingMoreClasses}
          arrowDirection={ARROW_DIRECTION.LEFT}
          shouldUseLibClassName={shouldUserLibClassName}
        />
      ) : null,
      nextArrow: showArrow ? (
        <SliderArrow
          disabled={isLoadingMoreClasses}
          arrowDirection={ARROW_DIRECTION.RIGHT}
          shouldUseLibClassName={shouldUserLibClassName}
        />
      ) : null,
    }),
    [handleMediaType, isLoadingMoreClasses, onSlideEnd, shouldUserLibClassName, showArrow]
  )

  return (
    <div className={classnames(styles['upcoming-classes-container'], className)}>
      <Slider {...settings} className={styles['cards-carousel']}>
        {classesList.map(currentClass => (
            <ClassCard
              key={currentClass?.id}
              currentClass={currentClass}
              className={styles['class-card']}
            />
          ))}
        {isLoadingMoreClasses && (
          <div className={styles['spinner-wrapper']}>
            <Spinner size={50} color="var(--malibu)" />
          </div>
        )}
      </Slider>
    </div>
  )
}

UpcomingClassesScrollList.propTypes = {
  className: PropTypes.string,
  classesList: PropTypes.instanceOf(List),
  params: PropTypes.shape({}),
  defaultArrows: PropTypes.bool,
}

UpcomingClassesScrollList.defaultProps = {
  className: '',
  classesList: [],
  params: {},
  defaultArrows: false,
}

export default React.memo(UpcomingClassesScrollList)
