import React from 'react'
import PropTypes, { arrayOf } from 'prop-types'
import { Link } from '@reach/router'

import styles from './styles.css'

const ConductItem = ({
  title,
  paragraphBefore,
  items,
  dotItems,
  paragraphAfter,
  codeOfConduct,
  termsOfUse,
  privacyPolicy,
  contactUs,
  subitems,
  children,
}) => (
    <div className={styles.item}>
      <h2>{title}</h2>
      <div className={styles.textbox}>
        {paragraphBefore && paragraphBefore.map(paragraph => <p>{paragraph}</p>)}
        {subitems &&
          subitems.map(subitem => (
            <div className={styles.subitems} key={subitem.title}>
              <h4>{subitem.title}</h4>
              {subitem.paragraph.map(p => <p>{p}</p>)}
            </div>
          ))}
        {(privacyPolicy || codeOfConduct || termsOfUse) && (
          <div className={styles['links-container']}>
            {privacyPolicy && (
              <Link to="/policy/privacy" className={styles.links}>
                Privacy Policy
              </Link>
            )}
            {codeOfConduct && (
              <Link to="/policy/code" className={styles.links}>
                Code of Conduct
              </Link>
            )}
            {termsOfUse && (
              <Link to="/policy/terms" className={styles.links}>
                Terms of Use
              </Link>
            )}
          </div>
        )}
        {items && (
          <div className={styles['list-elements']}>
            <ol>
              {items.map(item => (
                <li>
                  <p>{item}</p>
                </li>
              ))}
            </ol>
          </div>
        )}
        {dotItems && (
          <div className={styles['list-elements']}>
            <ul>
              {dotItems.map(item => (
                <li>
                  <p>{item}</p>
                </li>
              ))}
            </ul>
          </div>
        )}
        {children}
        {paragraphAfter && paragraphAfter.map(paragraph => <p>{paragraph}</p>)}
        {contactUs && (
          <div className={styles['contact-us']}>
            <h4>ConradGroup Inc.</h4>
            <h4>1312 Cedar St.</h4>
            <h4>Santa Monica, CA 90405</h4>
            <h4>admin@lightyear.co</h4>
          </div>
        )}
      </div>
    </div>
  )

ConductItem.propTypes = {
  title: PropTypes.string.isRequired,
  paragraphBefore: arrayOf(PropTypes.string),
  items: arrayOf(PropTypes.string),
  dotItems: arrayOf(PropTypes.string),
  subitems: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string.isRequired,
      paragraph: PropTypes.number.isRequired,
    })
  ),
  paragraphAfter: arrayOf(PropTypes.string),
  codeOfConduct: PropTypes.bool,
  termsOfUse: PropTypes.bool,
  privacyPolicy: PropTypes.bool,
  contactUs: PropTypes.bool,
  children: PropTypes.node,
}

ConductItem.defaultProps = {
  items: null,
  subitems: null,
  dotItems: null,
  paragraphBefore: null,
  paragraphAfter: null,
  children: null,
  codeOfConduct: false,
  termsOfUse: false,
  privacyPolicy: false,
  contactUs: false,
}

export default ConductItem
