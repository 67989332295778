import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import styles from './styles.css'

const ChipCheckbox = ({
  id,
  name,
  value,
  label,
  onChange,
  className,
  labelClassName,
  checked,
  ...restProps
}) => (
    <div
      className={classnames(styles.wrapper, { [styles['checked-wrapper']]: checked }, className)}
    >
      <input
        id={`checkbox-${id}-${label}`}
        onChange={onChange}
        name={name}
        type="checkbox"
        value={value}
        checked={checked}
        {...restProps}
      />
      {/* eslint-disable-next-line jsx-a11y/label-has-for */}
      <label className={labelClassName} htmlFor={`checkbox-${id}-${label}`}>
        <span className={styles.label}>{label}</span>
      </label>
    </div>
  )

ChipCheckbox.propTypes = {
  id: PropTypes.string.isRequired,
  name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func,
  labelClassName: PropTypes.string,
  checked: PropTypes.bool,
}

ChipCheckbox.defaultProps = {
  className: '',
  onChange: () => {},
  labelClassName: '',
  checked: false,
}

export default ChipCheckbox
