import React, { useReducer, useCallback, useEffect, useState, useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { navigate, useLocation } from '@reach/router'
import classnames from 'classnames'

import Input from '_components/landing-components/input'
import Button, { ButtonTheme, ButtonSize } from '_components/landing-components/button'
import CardModal from '_components/modal-card'
import GoogleLogin from '_components/google-login-button'
import MailIcon from '_assets/icons/mail-icon.svg'
import LockerICon from '_assets/icons/password-locker.svg'
import { login } from '_modules/user/actions'
import {
  isLoginLoadingSelector,
  loginErrorSelector,
  isGoogleLoginLoadingSelector,
  googleLoginErrorSelector,
  hasCompletedOnboardingSelector,
  userBelongsToACompanySelector,
} from '_modules/user/selectors'
import {
  getCourseClasses,
  isGettingCoursesSelector,
  getCoursesErrorSelector,
} from '_modules/course/selectors'
import usePrevious from '_hooks/use-previous'
import {
  GOOGLE_LOGIN_EMAIL_ALREADY_EXISTS_ERROR,
  ALREADY_SIGN_UP_WITH_GOOGLE_ERROR,
  SIGN_UP_WITH_GOOGLE_MESSAGE,
  emailAccountAlert,
} from '_constants/authentication'
import ErrorAlertComponent from '_components/error-alert-component'
import SignUpWithGoogle from '_components/authentication-modals/sign-up-with-google'

import styles from './styles.css'
import {
  reducer,
  INITIAL_STATE,
  UPDATE_STATE,
  VALIDATE_ERRORS,
  TOGGLE_LOGIN,
  LOGIN_ERROR,
} from './reducer'

const ALERT_MESSAGE_INITIAL_STATE = { message: '', isError: false }

const LoginModal = ({ isOpen, onClose }) => {
  const isLoginLoading = useSelector(isLoginLoadingSelector)
  const wasLoginLoading = usePrevious(isLoginLoading)
  const isGoogleLoginLoading = useSelector(isGoogleLoginLoadingSelector)
  const wasGoogleLoginLoading = usePrevious(isGoogleLoginLoading)
  const loginError = useSelector(loginErrorSelector)
  const googleLoginError = useSelector(googleLoginErrorSelector)
  const userEnrolledCourses = useSelector(getCourseClasses)
  const isGettingCourses = useSelector(isGettingCoursesSelector)
  const wasGettingCourses = usePrevious(isGettingCourses)
  const getCoursesError = useSelector(getCoursesErrorSelector)
  const hasCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)
  const userBelongsToACompany = useSelector(userBelongsToACompanySelector)

  const [state, localDispatch] = useReducer(reducer, INITIAL_STATE)
  const [isEnrollingCourse, setEnrollingCourse] = useState(false)
  const [alertMessage, setAlertMessage] = useState(ALERT_MESSAGE_INITIAL_STATE)
  const [isSignUpWithGoogle, setSignUpWithGoogle] = useState(false)
  const [isEmailLogin, setIsEmailLogin] = useState(false)
  const dispatch = useDispatch()
  const location = useLocation()
  const modalRef = useRef(null)

  const onInputChange = useCallback(event => {
    const { name, value } = event.target

    localDispatch({
      type: UPDATE_STATE,
      payload: {
        name,
        value,
      },
    })
  }, [])

  const onHandleLogin = useCallback(
    event => {
      event.preventDefault()

      localDispatch({
        type: VALIDATE_ERRORS,
      })

      if (alertMessage.message) {
        setAlertMessage(ALERT_MESSAGE_INITIAL_STATE)
      }
    },
    [alertMessage.message]
  )

  useEffect(
    () => {
      if (state.canUserLogin) {
        localDispatch({
          type: TOGGLE_LOGIN,
        })

        dispatch(login({ email: state.email, password: state.password }))
      }
    },
    [dispatch, state.canUserLogin, state.email, state.password]
  )

  const loginFulfilled = useCallback(
    () => {
      if (location.state) {
        const { classId, isEnrolling } = location.state

        if (classId && isEnrolling) {
          return
        }
      }

      if (!hasCompletedOnboarding) {
        if (userBelongsToACompany) {
          navigate('/onboarding')
          return
        }
        navigate('/explore-courses')
        return
      }

      navigate('/dashboard')
    },
    [hasCompletedOnboarding, location.state, userBelongsToACompany]
  )

  useEffect(
    () => {
      if (!isLoginLoading && wasLoginLoading) {
        if (!loginError.size) {
          loginFulfilled()
        }

        if (loginError.get('user')?.first() === ALREADY_SIGN_UP_WITH_GOOGLE_ERROR) {
          setSignUpWithGoogle(true)
          return
        }
        const errorPayload = {}

        if (loginError.get('password')?.first()) {
          errorPayload.passwordError = loginError.get('password').first()
        }

        if (loginError.get('email')?.first()) {
          errorPayload.emailError = loginError.get('email').first()
        }

        if (loginError.get('non_field_errors')?.first) {
          const message = loginError.get('non_field_errors').first()
          setAlertMessage({ message, isError: true })
          modalRef.current.scrollTo(0, 0)
        }

        localDispatch({
          type: LOGIN_ERROR,
          payload: errorPayload,
        })
      }
    },
    [
      dispatch,
      hasCompletedOnboarding,
      isLoginLoading,
      location.state,
      loginError,
      loginError.size,
      loginFulfilled,
      onClose,
      userBelongsToACompany,
      userEnrolledCourses,
      wasLoginLoading,
    ]
  )

  useEffect(
    () => {
      if (wasGoogleLoginLoading && !isGoogleLoginLoading) {
        if (!googleLoginError.size) {
          loginFulfilled()
        }
      }
    },
    [googleLoginError.size, isGoogleLoginLoading, loginFulfilled, wasGoogleLoginLoading]
  )

  useEffect(() => {
    if (location?.state?.email) {
      setAlertMessage({
        message: emailAccountAlert(location.state.email, styles['error-alert-bold']),
        isError: false,
      })
      setIsEmailLogin(true)
      localDispatch({
        type: UPDATE_STATE,
        payload: {
          name: 'email',
          value: location.state.email,
        },
      })
    }
    // eslint-disable-next-line
  }, [])

  useEffect(
    () => {
      if (wasGettingCourses && !isGettingCourses && !getCoursesError.size && isEnrollingCourse) {
        setEnrollingCourse(false)
        const classId = location?.state?.classId

        const userCourseEnrolled = userEnrolledCourses?.find(item => item.id === classId)

        if (userCourseEnrolled) {
          navigate(`/class/${userCourseEnrolled.enrolled}`)
        } else {
          navigate(`?payments&class=${classId}`)
        }
      }
    },
    [
      getCoursesError.size,
      isEnrollingCourse,
      isGettingCourses,
      location,
      state.redirectTo,
      userEnrolledCourses,
      wasGettingCourses,
    ]
  )

  const onGoogleLoginError = useCallback(error => {
    if (
      GOOGLE_LOGIN_EMAIL_ALREADY_EXISTS_ERROR.every(item =>
        error
          ?.get?.('error')
          ?.first?.()
          ?.includes(item)
      )
    ) {
      const errorMessage = emailAccountAlert(
        error?.get?.('email')?.first(),
        styles['error-alert-bold']
      )

      setAlertMessage({ message: errorMessage, isError: false })
      modalRef.current.scrollTo(0, 0)
    }

    if (error.get('isGoogleError')) {
      setAlertMessage({ message: error.get('message'), isError: true })
    }
  }, [])

  return (
    <CardModal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      childrenClassName={classnames(styles.modal, {
        [styles['google-sign-up-modal']]: isSignUpWithGoogle,
      })}
      ref={modalRef}
    >
      <h1 className={styles['modal-title']}>Login to Lightyear</h1>
      {isSignUpWithGoogle ? (
        <SignUpWithGoogle email={state.email} error={SIGN_UP_WITH_GOOGLE_MESSAGE} />
      ) : (
        <>
          {!!alertMessage.message && (
            <ErrorAlertComponent
              className={styles['alert-message']}
              errorMessage={alertMessage.message}
              isError={alertMessage.isError}
            />
          )}
          <form className={styles.form} onSubmit={onHandleLogin}>
            <Input
              className={styles['email-input']}
              value={state.email}
              onChange={onInputChange}
              name="email"
              placeholder="Email"
              icon={MailIcon}
              error={!!state.emailError}
              helperText={state.emailError}
              hasHelperText
            />
            <Input
              className={styles['password-input']}
              value={state.password}
              onChange={onInputChange}
              name="password"
              placeholder="Password"
              icon={LockerICon}
              type="password"
              error={!!state.passwordError?.length}
              helperText={state.passwordError}
              hasHelperText
            />
            <Button
              className={styles['forgot-password-button']}
              theme={ButtonTheme.TRANSPARENT_BACKGROUND_MALIBU_TEXT}
              size={ButtonSize.SMALL}
              url="?forgot-password"
            >
              Forgot password?
            </Button>
            <Button
              type="submit"
              theme={ButtonTheme.MALIBU}
              size={ButtonSize.BIG}
              isLoading={isLoginLoading}
            >
              Login
            </Button>
          </form>
          {!isEmailLogin && (
            <>
              <div className={styles['or-container']}>
                <div className={styles.line} />
                <p className={styles.or}>or</p>
                <div className={styles.line} />
              </div>
              <GoogleLogin onLoginError={onGoogleLoginError} />
              <p className={styles['sign-up']}>
                Not a member?{' '}
                <Button
                  className={styles['sign-up-button']}
                  theme={ButtonTheme.TRANSPARENT_BACKGROUND_MALIBU_TEXT}
                  size={ButtonSize.SMALL}
                  url={`${location.pathname === '/login' ? '/' : `${location.pathname}?`}sign-up`}
                >
                  Sign up now
                </Button>
              </p>
            </>
          )}
        </>
      )}
    </CardModal>
  )
}

LoginModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default React.memo(LoginModal)
