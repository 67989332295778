import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Logo from '_assets/logo.svg'

import styles from './styles.css'

const Footer = ({ className, svgClassName, linkClassName }) => (
  <footer className={classnames(styles.container, className)}>
    <svg
      aria-hidden="true"
      className={classnames(styles.logo, svgClassName)}
      viewBox={Logo.viewBox}
    >
      <use xlinkHref={`#${Logo.id}`} />
    </svg>
    <a
      href="/policy/terms"
      target="_blank"
      rel="noopener noreferrer"
      className={classnames(styles.link, linkClassName)}
    >
      Terms and Conditions
    </a>
    <a
      href="/policy/privacy"
      target="_blank"
      rel="noopener noreferrer"
      className={classnames(styles.link, linkClassName)}
    >
      Privacy Policy
    </a>
    <a
      href="/policy/code"
      target="_blank"
      rel="noopener noreferrer"
      className={classnames(styles.link, linkClassName)}
    >
      Code of Conduct
    </a>
  </footer>
)

Footer.propTypes = {
  className: PropTypes.string,
  svgClassName: PropTypes.string,
  linkClassName: PropTypes.string,
}

Footer.defaultProps = {
  className: '',
  svgClassName: '',
  linkClassName: '',
}

export default Footer
