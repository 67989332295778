import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import Input from '_components/input'
import ModalCard from '_components/modal/modal-card'

import styles from './styles.css'

const ModalNewVideo = ({ onCloseButton, isOpen, onButtonClick, isLoading }) => {
  const [name, setName] = useState('')

  const onNameChange = useCallback(event => {
    const { value } = event.target
    setName(value)
  }, [])

  const [duration, setDuration] = useState('')

  const onDurationChange = useCallback(event => {
    const { value } = event.target
    setDuration(value)
  }, [])

  const [password, setPassword] = useState('')

  const onPasswordChange = useCallback(event => {
    const { value } = event.target
    setPassword(value)
  }, [])

  const onButtonClickHandler = useCallback(() => onButtonClick({ name }), [name, onButtonClick])

  return (
    <ModalCard
      title="Start a new video chat"
      buttonLabel="Start"
      className={styles['messages-modal']}
      onCloseButton={onCloseButton}
      isOpen={isOpen}
      onButtonClick={onButtonClickHandler}
      isLoading={isLoading}
    >
      <div className={styles['input-video']}>
        <Input label="Name" placeholder="i.e. Meeting #3" value={name} onChange={onNameChange} />
        <Input
          label="Duration (in minutes)"
          value={duration}
          onChange={onDurationChange}
          type="number"
          className={styles.hide}
        />
        <Input
          label="Password"
          value={password}
          onChange={onPasswordChange}
          type="password"
          className={styles.hide}
        />
      </div>
    </ModalCard>
  )
}

ModalNewVideo.propTypes = {
  onCloseButton: PropTypes.func.isRequired,
  onButtonClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool,
}

ModalNewVideo.defaultProps = {
  isLoading: false,
}

export default React.memo(ModalNewVideo)
