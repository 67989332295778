import React, { useReducer, useCallback, useMemo, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classnames from 'classnames'
import { navigate } from '@reach/router'
import { Map } from 'immutable'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'

import Toastr, { ToastrTheme } from '_components/toastr'
import ASSIGNMENT_ACTIONS from '_modules/assignment/actions'
import ArrowLeftIcon from '_assets/icons/arrow-left.svg'
import ThreeDots from '_assets/icons/ellipsis.svg'
import Footer from '_components/footer'
import Card, { CardSize } from '_components/card'
import Button, { ButtonTheme } from '_components/button'
import { classShape, assignmentShape } from '_utils/proptypes'
import { onMouseDown } from '_utils/aria'
import AnswerTextArea from '_components/answer-textarea'
import CreatePost from '_components/create-post'
import RocketLoader from '_components/rocket-loader'
import PostCard from '_components/post-card'
import { usePrevious } from '_utils/hooks'
import { getUserSelector } from '_modules/user/selectors'
import { isFlaggingPostSelector, flagPostErrorSelector } from '_modules/assignment/selectors'
import { isGetClassLoadingSelector } from '_modules/class/selectors'
import useModal from '_hooks/use-modal'
import { CLASS_TYPE } from '_constants/class'
import ClassAttachmentsList from '_components/class-attachments/class-attachments-list'
import { linearGradientBg } from '_utils/constants'

import CompletedCourseModal from './completed-course-modal'
import styles from './styles.css'

const { flagPost, shazam, unshazam, deletePost } = ASSIGNMENT_ACTIONS

const INPUT_CHANGE = 'INPUT_CHANGE'
const CLEAN_STATE = 'CLEAN_STATE'
const RESET_STATE = 'RESET_STATE'

const reducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE: {
      return state.set(Number(action.payload.id), action.payload.answer)
    }
    case CLEAN_STATE: {
      return Map()
    }
    case RESET_STATE: {
      return action.state
    }
    default: {
      return state
    }
  }
}

const AssignmentInformation = ({
  className,
  myClass,
  assignment,
  onSaveAction,
  isLoading,
  hideNextButton,
  onCreatePost,
  onEditPost,
  isPosting,
  postingError,
  isLastAssignment,
}) => {
  const user = useSelector(getUserSelector)
  const isFlagging = useSelector(isFlaggingPostSelector)
  const flagError = useSelector(flagPostErrorSelector)
  const isGetClassLoading = useSelector(isGetClassLoadingSelector)
  const prevAssignmentId = usePrevious(assignment.id)
  const wasLoading = usePrevious(isLoading)
  const wasFlagging = usePrevious(isFlagging)

  const [isCompletedCourseModalOpen, onToggleCompletedCourseModal] = useModal()

  const initialState = useMemo(
    () => Map(assignment.questions.map(question => [question.id, question.answer || ''])),
    [assignment.questions]
  )

  const [answers, localDispatch] = useReducer(reducer, initialState)
  const [next, goNext] = useState(false)

  const dispatch = useDispatch()

  const onBackClick = useCallback(
    event => {
      event.preventDefault()
      navigate([`/class/${myClass.id}`])
    },
    [myClass.id]
  )

  const onViewResponsesClick = useCallback(
    () => {
      navigate([`/class/${myClass.id}/assignment/${assignment.id}/responses`])
    },
    [assignment.id, myClass.id]
  )

  const onInputChange = useCallback(event => {
    const { id, value } = event.target
    localDispatch({
      type: INPUT_CHANGE,
      payload: {
        id,
        answer: value,
      },
    })
  }, [])

  const renderDescription = useCallback(
    () => ({
        __html: assignment.assignment ? (
          assignment.assignment.description || 'This assignment has no content'
        ) : (
          <p>This assigment has no content</p>
        ),
      }),
    [assignment]
  )

  const onSaveClick = useCallback(
    event => {
      event.preventDefault()
      onSaveAction({
        bulk: answers.reduce((acc, curr, index) => {
          acc.push({
            id: index,
            answer: curr,
          })
          return acc
        }, []),
      })

      if (isLastAssignment) {
        onToggleCompletedCourseModal()
      }
      goNext(false)
    },
    [onSaveAction, answers, isLastAssignment, onToggleCompletedCourseModal]
  )

  const onSaveAndGoNextClick = useCallback(
    event => {
      event.preventDefault()
      onSaveAction(
        {
          bulk: answers.reduce((acc, curr, index) => {
            acc.push({
              id: index,
              answer: curr,
            })
            return acc
          }, []),
        },
        true
      )
      goNext(true)
    },
    [onSaveAction, answers]
  )

  const onPostClick = useCallback(
    payload => {
      onCreatePost({
        assignment: assignment.id,
        classId: myClass.id,
        ...payload,
      })
    },
    [assignment.id, myClass.id, onCreatePost]
  )

  const onShazamClick = useCallback(
    ({ postId, fatherPostId, shazams }) =>
      dispatch(
        shazam({
          postId,
          fatherPostId,
          shazams,
          classId: myClass.id,
          assignmentId: assignment.id,
        })
      ),
    [assignment.id, dispatch, myClass.id]
  )

  const onUnShazamClick = useCallback(
    ({ postId, fatherPostId, shazams }) =>
      dispatch(
        unshazam({
          postId,
          fatherPostId,
          shazams,
          classId: myClass.id,
          assignmentId: assignment.id,
        })
      ),
    [assignment.id, dispatch, myClass.id]
  )

  const onDeletePost = useCallback(
    postId => dispatch(deletePost({ postId, classId: myClass.id, assignmentId: assignment.id })),
    [assignment.id, dispatch, myClass.id]
  )

  const onFlagPost = useCallback(
    postId => {
      dispatch(flagPost(postId))
    },
    [dispatch]
  )

  const backgroundImage = useMemo(
    () => ({
      backgroundImage: `${linearGradientBg}, url('${myClass.coursePicture}')`,
    }),
    [myClass.coursePicture]
  )

  useEffect(
    () => {
      if (wasFlagging && !isFlagging) {
        if (flagError.size === 0) {
          toast(<Toastr theme={ToastrTheme.SUCCESS} content="The content has been flagged." />)
          return
        }

        toast(
          <Toastr theme={ToastrTheme.ERROR} content="The content couldn't be flagged. Try again." />
        )
      }
    },
    [flagError.size, isFlagging, wasFlagging]
  )

  useEffect(
    () => {
      if (assignment.id !== prevAssignmentId) {
        localDispatch({
          type: RESET_STATE,
          state: initialState,
        })
      }
    },
    [assignment.id, initialState, prevAssignmentId]
  )

  useEffect(
    () => {
      if (wasLoading && !isLoading && next) {
        localDispatch({
          type: CLEAN_STATE,
        })
      }
    },
    [initialState, isLoading, next, wasLoading]
  )

  if (!assignment.assignment || isGetClassLoading) {
    return (
      <div className={styles.loading}>
        <RocketLoader />
      </div>
    )
  }

  return (
    <section className={classnames(styles.container, className)}>
      <div className={styles.header} style={myClass.coursePicture && backgroundImage}>
        <div className={styles['title-wrapper']}>
          <button
            type="button"
            onClick={onBackClick}
            onMouseDown={onMouseDown}
            className={styles['back-button']}
          >
            <svg
              className={styles['back-icon']}
              aria-label="Back icon"
              role="img"
              viewBox={ArrowLeftIcon.viewBox}
            >
              <use xlinkHref={`#${ArrowLeftIcon.id}`} />
            </svg>
          </button>
          <h3 className={styles.title}>{myClass.courseName}</h3>
        </div>
      </div>
      <div className={styles.content}>
        <div className={styles['step-container']}>
          <h1 className={styles.title}>{assignment.assignment.name}</h1>
          <Card size={CardSize.MEDIUM}>
            {/* eslint-disable-next-line react/no-danger */}
            <div dangerouslySetInnerHTML={renderDescription()} className={styles.description} />
          </Card>
          {assignment.questions.length > 0 && (
            <Card className={styles['question-container']} size={CardSize.MEDIUM}>
              {assignment.questions.map(question => (
                <AnswerTextArea
                  question={question.question}
                  onChange={onInputChange}
                  value={answers.get(question.id)}
                  key={question.id}
                  id={question.id}
                  rows={3}
                />
              ))}
            </Card>
          )}
          <div className={styles['buttons-container']}>
            {(myClass.type !== CLASS_TYPE.SELF_LED?.slug ||
              myClass.type !== CLASS_TYPE.SELF_PACED?.slug) &&
              assignment.questions.length > 0 && (
                <div className={styles['response-buttons']}>
                  <Button
                    label="View other responses"
                    theme={ButtonTheme.SECONDARY}
                    className={styles['view-button']}
                    onMouseDown={onMouseDown}
                    onClick={onViewResponsesClick}
                    disabled={isLoading}
                  />
                  <Button
                    label="more options"
                    theme={ButtonTheme.DEFAULT}
                    className={styles['three-dots']}
                    onMouseDown={onMouseDown}
                    onClick={onViewResponsesClick}
                    disabled={isLoading}
                    iconOnly
                    icon={ThreeDots}
                  />
                </div>
              )}
            <div className={styles['save-buttons']}>
              <Button
                label={assignment.questions.length ? 'Save changes' : 'Mark as complete'}
                theme={ButtonTheme.PRIMARY}
                onClick={onSaveClick}
                onMouseDown={onMouseDown}
                disabled={isLoading}
                className={styles.button}
              />
              {!hideNextButton && (
                <Button
                  label={
                    assignment.questions.length
                      ? 'Save changes and move to next assignment'
                      : 'Mark as complete and move to next assignment'
                  }
                  theme={ButtonTheme.TRANSPARENT_BACKGROUND_MALIBU_TEXT}
                  className={styles.label}
                  onMouseDown={onMouseDown}
                  onClick={onSaveAndGoNextClick}
                  disabled={isLoading}
                />
              )}
            </div>
          </div>
          {assignment.attachments.length !== 0 && (
            <div className={styles['attachment-list-container']}>
              <p className={styles['attachments-title']}>Attachments</p>
              <p className={styles['attachment-quantity']}>
                {assignment?.attachments?.length} file
                {assignment?.attachments?.length > 1 ? 's' : ''}
              </p>
              <ClassAttachmentsList
                fileList={assignment?.attachments}
                className={styles['attachments-list']}
              />
            </div>
          )}
        </div>
        {(myClass.type !== CLASS_TYPE.SELF_LED?.slug ||
          myClass.type !== CLASS_TYPE.SELF_PACED?.slug) && (
          <>
            <div className={styles.divider} />
            <CreatePost
              className={styles['discuss-assignment']}
              title="Discuss this assignment"
              placeholder="Share additional thoughts or questions with your course participants"
              onPostClick={onPostClick}
            />
            {assignment.posts.length > 0 && (
              <div className={styles.discussion}>
                {assignment.posts.map(post => (
                  <PostCard
                    key={post.id}
                    id={post.id}
                    post={post}
                    isOwnPost={post.author.id === user.id}
                    user={user}
                    onSubmit={onPostClick}
                    onEditPost={onEditPost}
                    shazam={onShazamClick}
                    unshazam={onUnShazamClick}
                    onDeletePost={onDeletePost}
                    onFlagPost={onFlagPost}
                    isPosting={isPosting}
                    postingError={postingError}
                    removeHideOption
                  />
                ))}
              </div>
            )}
          </>
        )}
      </div>
      <Footer />
      {isCompletedCourseModalOpen && (
        <CompletedCourseModal
          courseName={myClass.courseName}
          onClose={onToggleCompletedCourseModal}
        />
      )}
    </section>
  )
}

AssignmentInformation.propTypes = {
  className: PropTypes.string,
  myClass: classShape.isRequired,
  assignment: assignmentShape.isRequired,
  onSaveAction: PropTypes.func,
  isLoading: PropTypes.bool,
  hideNextButton: PropTypes.bool,
  onCreatePost: PropTypes.func.isRequired,
  onEditPost: PropTypes.func.isRequired,
  isPosting: PropTypes.bool.isRequired,
  postingError: ImmutablePropTypes.map,
  isLastAssignment: PropTypes.bool,
}

AssignmentInformation.defaultProps = {
  className: '',
  onSaveAction: () => {},
  isLoading: false,
  hideNextButton: false,
  postingError: Map(),
  isLastAssignment: false,
}

export default React.memo(AssignmentInformation)
