import { Map, fromJS } from 'immutable'

import { getActionName } from '_utils/redux'

import { CLEAN_AUTH_ERROR } from './actions'

export const INITIAL_STATE = Map()

export default (state = INITIAL_STATE, action) => {
  if (action.error) {
    return state.set(
      getActionName(action.type),
      fromJS(typeof action.payload === 'string' ? { error: action.payload } : action.payload)
    )
  }

  if (action.type === CLEAN_AUTH_ERROR) {
    return state.set('SIGN_UP', INITIAL_STATE).set('LOGIN', INITIAL_STATE)
  }

  return state.delete(getActionName(action.type))
}
