import React from 'react'
import PropTypes from 'prop-types'
import ReactModal from 'react-modal'
import classnames from 'classnames'

import styles from './styles.css'

const Modal = ({ isOpen, onClose, children, className }) => (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onClose}
      className={classnames(styles.modal, className)}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick={false}
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  )

Modal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
}

Modal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  className: '',
}

export default Modal
