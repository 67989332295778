import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { navigate } from '@reach/router'

import CardModal from '_components/modal-card'
import Input from '_components/landing-components/input'
import Button, { ButtonSize } from '_components/landing-components/button'
import { validateEmail } from '_utils/helpers'
import { resetPassword } from '_modules/user/actions'
import { isResettingPasswordSelector, resetPasswordErrorSelector } from '_modules/user/selectors'
import usePrevious from '_hooks/use-previous'
import SignUpWithGoogle from '_components/authentication-modals/sign-up-with-google'
import {
  ALREADY_SIGN_UP_WITH_GOOGLE_ERROR,
  SIGN_UP_WITH_GOOGLE_MESSAGE,
} from '_constants/authentication'

import styles from './styles.css'

const ForgotYourPasswordModal = ({ isOpen, onClose }) => {
  const isLoading = useSelector(isResettingPasswordSelector)
  const wasLoading = usePrevious(isLoading)
  const resetPasswordError = useSelector(resetPasswordErrorSelector)

  const dispatch = useDispatch()

  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const [isSignUpWithGoogle, setSignUpWithGoogle] = useState(false)

  const error = useMemo(() => emailError || resetPasswordError?.first()?.first() || '', [
    emailError,
    resetPasswordError,
  ])

  const onInputChange = useCallback(event => {
    const { value } = event.target
    setEmail(value)
  }, [])

  const onHandleForgotPassword = useCallback(
    event => {
      event.preventDefault()
      if (!validateEmail(email)) {
        setEmailError('Email is invalid')
      } else {
        dispatch(resetPassword({ email }))
      }
    },
    [dispatch, email]
  )

  useEffect(
    () => {
      if (wasLoading && !isLoading) {
        if (!resetPasswordError.size) {
          navigate('?email-sent')
          return
        }

        if (resetPasswordError.getIn(['email', 'user']) === ALREADY_SIGN_UP_WITH_GOOGLE_ERROR) {
          setSignUpWithGoogle(true)
        }
      }
    },
    [isLoading, resetPasswordError, resetPasswordError.size, wasLoading]
  )

  return (
    <CardModal isOpen={isOpen} onClose={onClose} childrenClassName={styles.modal}>
      <h1 className={styles['modal-title']}>Forgot your password?</h1>
      {isSignUpWithGoogle ? (
        <SignUpWithGoogle email={email} error={SIGN_UP_WITH_GOOGLE_MESSAGE} />
      ) : (
        <>
          <p className={styles.description}>Please add the email you use to login on Lightyear</p>
          <form className={styles.form} onSubmit={onHandleForgotPassword}>
            <Input
              value={email}
              onChange={onInputChange}
              placeholder="Email address"
              error={!!error}
              helperText={error}
              hasHelperText
            />
            <Button
              className={styles.button}
              type="submit"
              size={ButtonSize.BIG}
              isLoading={isLoading}
            >
              Done
            </Button>
          </form>
        </>
      )}
    </CardModal>
  )
}

ForgotYourPasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(ForgotYourPasswordModal)
