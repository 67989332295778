import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classnames from 'classnames'
import { navigate } from '@reach/router'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { toast } from 'react-toastify'

import PeopleEnrolledSection from '_components/people-enrolled-section'
import ArrowLeftIcon from '_assets/icons/arrow-left.svg'
import CourseIntention from '_components/course-intention'
import Footer from '_components/footer'
import CourseSection from '_components/course-section'
import Modal from '_components/modal'
import { classShape } from '_utils/proptypes'
import { groupAssignmentBySection } from '_utils/helpers'
import {
  updateAssignment,
  UPDATE_ASSIGNMENT,
  updateIntention,
  UPDATE_INTENTION,
  enableAssignmentVideoCall,
} from '_modules/class/actions'
import Toastr, { ToastrTheme } from '_components/toastr'
import { usePrevious } from '_utils/hooks'
import { onMouseDown } from '_utils/aria'
import ModalTextarea from '_components/modal-textarea'
import { CLASS_TYPE } from '_constants/class'
import { linearGradientBg } from '_utils/constants'

import styles from './styles.css'

const onBackClick = event => {
  event.preventDefault()
  navigate('/courses')
}

const mapStateToProps = ({ loading, error }) => ({
  isUpdatingAssignment: !!loading.get(UPDATE_ASSIGNMENT.ACTION),
  updateAssignmentError: error.get(UPDATE_ASSIGNMENT.ACTION),
  isUpdatingIntention: !!loading.get(UPDATE_INTENTION.ACTION),
  updateIntentionError: error.get(UPDATE_INTENTION.ACTION),
})

const mapDispatchToProps = {
  updateAssignmentStatus: updateAssignment,
  updateIntentionClass: updateIntention,
  enableVideoCall: enableAssignmentVideoCall,
}

const CommunitySelfLedClass = ({
  className,
  myClass,
  userId,
  updateAssignmentStatus,
  isUpdatingAssignment,
  updateAssignmentError,
  onFollow,
  onUnfollow,
  updateIntentionClass,
  isUpdatingIntention,
  updateIntentionError,
  enableVideoCall,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false)

  const toggleModal = useCallback(() => setIsModalOpen(!isModalOpen), [isModalOpen])

  const wasUpdatingAssignment = usePrevious(isUpdatingAssignment)
  const wasUpdatingIntention = usePrevious(isUpdatingIntention)

  const formattedSections = useMemo(
    () => {
      const secs = groupAssignmentBySection(myClass.assignments, 'sectionName')

      return Object.keys(secs).reduce((acc, curr, index) => {
        acc.push({
          title: String(curr),
          order: index,
          assignments: secs[curr],
        })
        return acc
      }, [])
    },
    [myClass.assignments]
  )

  const peopleEnrolled = useMemo(() => myClass.peopleEnrolled.filter(user => user.id !== userId), [
    myClass.peopleEnrolled,
    userId,
  ])

  const isSelfLed = useMemo(
    () =>
      myClass.type === CLASS_TYPE.SELF_LED?.slug || myClass.type === CLASS_TYPE.SELF_PACED?.slug,
    [myClass.type]
  )

  const onCompleteTask = useCallback(
    (assignmentId, payload) => {
      updateAssignmentStatus(myClass.id, assignmentId, payload)
    },
    [myClass.id, updateAssignmentStatus]
  )

  const onIntentionSubmit = useCallback(
    newIntention => updateIntentionClass({ classId: myClass.id, intention: newIntention }),
    [myClass.id, updateIntentionClass]
  )

  const coursePicture = useMemo(
    () => ({
      backgroundImage: `${linearGradientBg}, url('${myClass.coverPhoto}')`,
    }),
    [myClass.coverPhoto]
  )

  useEffect(
    () => {
      if (wasUpdatingAssignment && !isUpdatingAssignment && updateAssignmentError.size !== 0) {
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content="The assignment couldn't be updated, please, try again."
          />
        )
      }

      if (wasUpdatingIntention && !isUpdatingIntention && updateIntentionError.size === 0) {
        toggleModal()
      }
    },
    [
      isUpdatingAssignment,
      isUpdatingIntention,
      myClass.intention,
      toggleModal,
      updateAssignmentError.size,
      updateIntentionError.size,
      wasUpdatingAssignment,
      wasUpdatingIntention,
    ]
  )

  const isSectionBlocked = useCallback((section, prevSection) => {
    if (prevSection && prevSection.assignments[prevSection.assignments.length - 1].completed) {
      return false
    }
    if (!prevSection) {
      return false
    }
    return true
  }, [])

  const onEnableAssignmentVideoCall = useCallback(
    assignmentId => enableVideoCall({ assignmentId, classId: myClass.id }),
    [enableVideoCall, myClass.id]
  )

  return (
    <>
      <section className={classnames(styles.container, className)}>
        <div className={styles.header} style={myClass && myClass.coverPhoto && coursePicture}>
          <div className={styles['title-wrapper']}>
            <button
              type="button"
              className={styles['back-button']}
              onClick={onBackClick}
              onMouseDown={onMouseDown}
              title="Back"
            >
              <svg
                aria-label="Back icon"
                role="img"
                viewBox={ArrowLeftIcon.viewBox}
                className={styles['back-icon']}
              >
                <use xlinkHref={`#${ArrowLeftIcon.id}`} />
              </svg>
            </button>
            <h1 className={styles.title}>{myClass.courseName}</h1>
          </div>
        </div>
        <div className={styles.content}>
          <p className={styles.description}>{myClass.courseDescription}</p>
          <CourseIntention
            className={styles['course-intention']}
            onClick={toggleModal}
            intention={myClass.intention}
          />
          {myClass.type === CLASS_TYPE.COMMUNITY_LED.slug && (
            <PeopleEnrolledSection
              className={styles['enrolled-people']}
              isEnrolled
              usersEnrolled={peopleEnrolled}
              onFollow={onFollow}
              onUnfollow={onUnfollow}
              classId={myClass.id}
            />
          )}
          {formattedSections.map((section, index, array) => (
            <CourseSection
              key={`${section.title}-${section.order}`}
              className={styles['course-assignments']}
              title={section.title}
              assignments={section.assignments}
              onCompleteTask={onCompleteTask}
              blockedSection={isSectionBlocked(section, array[index - 1])}
              enableAssignmentVideoCall={onEnableAssignmentVideoCall}
              isSelfLed={isSelfLed}
            />
          ))}
        </div>
        <Footer />
      </section>
      {isModalOpen && (
        <Modal isOpen onClose={toggleModal}>
          <ModalTextarea
            title="Edit course intention"
            buttonLabel="Save"
            onSubmit={onIntentionSubmit}
            onClose={toggleModal}
            value={myClass.intention}
            isLoading={isUpdatingIntention}
          />
        </Modal>
      )}
    </>
  )
}

CommunitySelfLedClass.propTypes = {
  className: PropTypes.string,
  myClass: classShape.isRequired,
  userId: PropTypes.number.isRequired,
  updateAssignmentStatus: PropTypes.func.isRequired,
  updateIntentionClass: PropTypes.func.isRequired,
  isUpdatingAssignment: PropTypes.bool.isRequired,
  isUpdatingIntention: PropTypes.bool.isRequired,
  updateAssignmentError: ImmutablePropTypes.map,
  updateIntentionError: ImmutablePropTypes.map,
  onFollow: PropTypes.func,
  onUnfollow: PropTypes.func,
  enableVideoCall: PropTypes.func,
}

CommunitySelfLedClass.defaultProps = {
  className: '',
  updateAssignmentError: Map(),
  updateIntentionError: Map(),
  onFollow: () => {},
  onUnfollow: () => {},
  enableVideoCall: () => {},
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(CommunitySelfLedClass))
