import React, { useCallback, useReducer, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { toast } from 'react-toastify'

import Toastr, { ToastrTheme } from '_components/toastr'
import Button, { ButtonTheme } from '_components/button'
import { CHANGE_EMAIL, changeEmail } from '_modules/user/actions'
import Card, { CardSize } from '_components/card'
import Input from '_components/input'
import { usePrevious } from '_utils/hooks'

import styles from './styles.css'

const mapStateToProps = ({ loading, error }) => ({
  loading: !!loading.get(CHANGE_EMAIL.ACTION),
  changeEmailError: error.get(CHANGE_EMAIL.ACTION),
})

const mapDispatchToProps = {
  onClickChangeEmail: changeEmail,
}

const INPUT_CHANGE = 'INPUT_CHANGE'

const reducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE: {
      return {
        ...state,
        [action.name]: action.value,
        errors: {
          ...state.errors,
          ...action.errors,
        },
      }
    }
    default:
      return state
  }
}

const ChangeEmailCard = ({ email, loading, changeEmailError, onClickChangeEmail }) => {
  const [state, dispatch] = useReducer(reducer, {
    currentEmail: email,
    newEmail: '',
    confirmEmail: '',
    errors: { newEmail: '', confirmEmail: '' },
  })
  const previousLoading = usePrevious(loading)

  const validateEmail = useCallback(emailString => {
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(emailString).toLowerCase())
  }, [])

  const checkIfEmailInputsAreValid = useCallback(
    () => {
      const { newEmail, confirmEmail } = state

      const errors = { newEmail: '', confirmEmail: '' }

      if (!validateEmail(newEmail) || newEmail === '') {
        errors.newEmail = 'Please enter a valid email.'
      }

      if (confirmEmail !== newEmail) {
        errors.confirmEmail = 'Please ensure that your emails match.'
      }

      dispatch({ type: INPUT_CHANGE, errors })

      return Object.values(errors).every(value => value.length === 0)
    },
    [state, validateEmail]
  )

  useEffect(
    () => {
      if (changeEmailError.size !== 0) {
        toast(<Toastr theme={ToastrTheme.ERROR} content={changeEmailError.first().get(0)} />)
        return
      }
      if (previousLoading && !loading && changeEmailError.size === 0) {
        toast(
          <Toastr
            theme={ToastrTheme.SUCCESS}
            content="We’ve sent you an email to confirm your new email address. Your email will only be changed after confirmation"
          />
        )
      }
    },
    [changeEmailError, changeEmailError.size, loading, previousLoading, state.newEmail]
  )

  const onInputChange = useCallback(event => {
    event.preventDefault()
    const { name, value } = event.target
    dispatch({ type: INPUT_CHANGE, name, value })
  }, [])

  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      if (checkIfEmailInputsAreValid()) {
        onClickChangeEmail({ email: state.newEmail })
      }
    },
    [checkIfEmailInputsAreValid, onClickChangeEmail, state.newEmail]
  )

  return (
    <Card className={styles.card} size={CardSize.LARGE}>
      <h2>Change Email</h2>
      <form onSubmit={onSubmit} className={styles['form-container']}>
        <Input
          disabled
          onChange={onInputChange}
          value={state.currentEmail}
          name="currentEmail"
          label="Current Email"
          type="email"
          labelClassName={styles['label-title']}
        />
        <Input
          disabled={loading}
          onChange={onInputChange}
          value={state.newEmail}
          name="newEmail"
          label="New Email"
          type="email"
          labelClassName={styles['label-title']}
          errorMessage={state.errors.newEmail}
        />
        <Input
          disabled={loading}
          onChange={onInputChange}
          value={state.confirmEmail}
          name="confirmEmail"
          label="Confirm new Email"
          type="email"
          labelClassName={styles['label-title']}
          errorMessage={state.errors.confirmEmail}
        />
        <Button
          loading={loading}
          label="Save changes"
          theme={ButtonTheme.PRIMARY}
          className={styles.button}
          type="submit"
        />
      </form>
    </Card>
  )
}

ChangeEmailCard.propTypes = {
  email: PropTypes.string.isRequired,
  loading: PropTypes.bool,
  onClickChangeEmail: PropTypes.func.isRequired,
  changeEmailError: ImmutablePropTypes.map,
}

ChangeEmailCard.defaultProps = {
  changeEmailError: new Map(),
  loading: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ChangeEmailCard)
