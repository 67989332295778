import { createSelector } from 'reselect'
import { Map } from 'immutable'

import { Class } from '_models/'
import { compareClassDates } from '_utils/helpers'

import {
  GET_ALL_PARTICIPANTS,
  GET_MORE_CLASSES,
  GET_CLASSES,
  ENROLL,
  INITIAL_ENROLL,
  VALIDATE_DISCOUNT_COUPON,
  BULK_UPDATE_ANSWER,
  GET_CLASS,
  START_CALL,
  GET_CLASSES_NEW,
} from './actions'

const getClasses = state => state.classes
const getParam = (state, param) => param

export const getMyClasses = createSelector([getClasses], classes => ({
  count: classes.get('count'),
  next: classes.get('next'),
  previous: classes.get('previous'),
  classes: classes.get('classes').reduce((acc, curr) => {
    acc.push(curr.toJS())
    return acc
  }, []),
}))

export const getClassesListSelector = state => state.classes.get('classes')

export const classesIdsListSelector = state => state.classes.getIn(['search', 'results'])

export const classesListSelector = createSelector(
  getClassesListSelector,
  classesIdsListSelector,
  (classes, classesIdsList) => classesIdsList?.map(id => classes.get(id?.toString()))
)

export const getClassById = createSelector([getClasses, getParam], (classes, classId) => {
  const myClass = classes.getIn(['classes', Number(classId)])
  return myClass ? myClass.toJS() : new Class().toJS()
})

export const getClassesOrderedByOldest = createSelector([getClasses], classes => {
  const oldestOrderClasses = classes
    .getIn(['classes'])
    .valueSeq()
    .toArray()
    .sort((a, b) => compareClassDates(a, b))
  return oldestOrderClasses.map(currentClass => currentClass.toJS())
})

export const getPublicClassesNextPage = state => state.classes.get('next')

export const getTotalParticipantsSelector = state =>
  state.classes.getIn(['peopleEnrolled', 'count'])

export const getParticipantsSelector = state =>
  state.classes.getIn(['peopleEnrolled', 'participants'])

export const participantsSelector = createSelector(getParticipantsSelector, participants => participants?.map(participant => participant.user))

export const isGetParticipantsLoading = state => !!state.loading.get(GET_ALL_PARTICIPANTS.ACTION)

export const isInitialEnrollLoadingSelector = state => !!state.loading.get(INITIAL_ENROLL.ACTION)

export const initialEnrollErrorSelector = state => state.error.get(INITIAL_ENROLL.ACTION, Map())

export const isEnrollLoadingSelector = state => !!state.loading.get(ENROLL.ACTION)

export const enrollErrorSelector = state => state.error.get(ENROLL.ACTION, Map())

export const isGettingDiscountSelector = state =>
  !!state.loading.get(VALIDATE_DISCOUNT_COUPON.ACTION)

export const getDiscountErrorSelector = state =>
  state.error.get(VALIDATE_DISCOUNT_COUPON.ACTION, Map())

export const discountValueSelector = (state, classId) =>
  state.classes.getIn(['couponDiscount', classId])

export const discountTypeSelector = (state, classId) => state.classes.getIn(['couponType', classId])

export const isGettingMoreClassesSelector = state => !!state.loading.get(GET_MORE_CLASSES.ACTION)

export const isGettingClassesSelector = state => !!state.loading.get(GET_CLASSES.ACTION)

export const getClassesErrorSelector = state => state.error.get(GET_CLASSES.ACTION, Map())

export const isBulkUpdateAnswerLoadingSelector = state =>
  !!state.loading.get(BULK_UPDATE_ANSWER.ACTION)

export const bulkUpdateAnswerErrorSelector = state =>
  state.error.get(BULK_UPDATE_ANSWER.ACTION, Map())

export const isGetClassLoadingSelector = state => !!state.loading.get(GET_CLASS.ACTION)

export const isStartingCallSelector = state => !!state.loading.get(START_CALL.ACTION)

export const startCallErrorSelector = state => state.error.get(START_CALL.ACTION, Map())

export const myClassesSelector = state => state.classes.get('classes')

export const getClassesNewLoadingSelector = state => state.loading.get(GET_CLASSES_NEW.ACTION)
