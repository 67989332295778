import Model from './model'

class Notification extends Model({
  id: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  type: undefined,
  data: undefined,
  read: undefined,
}) {}

export default Notification
