import { useEffect } from 'react'

export default isModalOpen => {
  useEffect(
    () => {
      if (isModalOpen) {
        document.body.style.overflow = 'hidden'
      } else {
        document.body.removeAttribute('style')
        document.documentElement.removeAttribute('style')
      }

      return () => {
        document.body.removeAttribute('style')
        document.documentElement.removeAttribute('style')
      }
    },
    [isModalOpen]
  )
}
