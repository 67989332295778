export const enrollRedirectUrl = ({ classId }) => `?payments&class=${classId}`
export const redirectUrl = url => {
  if (!url) {
    return {}
  }

  const courseSlug = url.includes('checkout')
    ? url.split('checkout/')?.[1]?.split('/')?.[0]
    : url.split('course/')?.[1]
  const classId = url.split('/class/')?.[1]

  return {
    redirectTo: `/course/${courseSlug}`,
    classId,
    courseSlug,
  }
}
