import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { List } from 'immutable'
import moment from 'moment'

import useBodyOverflow from '_hooks/use-body-overflow'
import Modal from '_components/modal'
import CloseIcon from '_assets/icons/close.svg'
import { onMouseDown } from '_utils/aria'
import Button from '_components/landing-components/button'
import { enrollRedirectUrl } from '_utils/enroll-course-redirect'

import styles from './styles.css'

const DATE_FORMAT = 'YYYY-MM-DD'

const formatDate = date => moment(date).format('MMM DD')

const getClassDateAndTime = payload => moment(payload.get('date')).format('dddd hh:mm A')

const getClassTime = payload => moment(payload.get('date')).format('h:mm A')

const AvailableClassesModal = ({
  isOpen,
  onClose,
  name,
  content,
  authToken,
  hasUserCompletedOnboarding,
}) => {
  useBodyOverflow(isOpen)

  const getFormattedStartDate = useCallback(date => date ? `Starting on ${moment(date, DATE_FORMAT).format('MMMM D, YYYY')}` : '', [])

  const redirectUrl = useCallback(
    course => {
      if (course.get('enrolled')) {
        return `/class/${course.get('enrolled')}`
      }

      if (authToken) {
        return `?payments&class=${course.get('id')}`
      }
      return '?sign-up'
    },
    [authToken]
  )

  const redirectState = useCallback(
    id => ({
      redirectTo: enrollRedirectUrl({
        classId: id,
        authToken,
        hasCompletedOnboarding: hasUserCompletedOnboarding,
      }),
      classId: id,
    }),
    [authToken, hasUserCompletedOnboarding]
  )

  const renderClassPeriodicity = useCallback(course => {
    const hasOnlyOneLiveSession = course.get('liveSessions')?.size === 1
    if (hasOnlyOneLiveSession) {
      return getClassDateAndTime(course.getIn(['liveSessions', 0]))
    }
    return getClassTime(course.getIn(['liveSessions', 0]))
  }, [])

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <header>
        <div className={styles.header}>
          <button
            type="button"
            className={styles['close-button']}
            onClick={onClose}
            aria-label="Close Syllabus"
            onMouseDown={onMouseDown}
          >
            <svg className={styles['close-icon']} viewBox={CloseIcon.viewBox} aria-hidden="true">
              <use xlinkHref={`#${CloseIcon.id}`} />
            </svg>
          </button>
          <p className={styles['course-name']}>{name}</p>
          <h1 className={styles.title}>Available classes</h1>
        </div>
      </header>
      <div className={styles.classes}>
        {content?.map(course => (
          <article className={styles.class} key={course.get('id')}>
            <h1 className={styles['class-title']}>{course.get('name')}</h1>
            <p className={styles['class-date']}>{getFormattedStartDate(course.get('startDate'))}</p>
            {!!course.get('liveSessions').size && (
              <>
                {renderClassPeriodicity(course) && (
                  <p className={styles['class-schedule']}>
                    {renderClassPeriodicity(course)} - Your local date and time
                  </p>
                )}
                <p className={styles['light-gray']}>Live video session dates</p>
                <p className={styles['dark-gray']}>
                  {course.get('liveSessions').map((session, index, array) => {
                    if (index === array.size - 1) {
                      return formatDate(session.get('date'))
                    }

                    return `${formatDate(session.get('date'))} • `
                  })}
                </p>
                {course.get('description') && (
                  <p className={styles['class-schedule']}>{course.get('description')}</p>
                )}
              </>
            )}
            {!!course.get('instructor') && (
              <div className={styles.instructor}>
                <img
                  src={course.getIn(['instructor', 'photo'])}
                  alt={course.getIn(['instructor', 'name'])}
                  className={styles.picture}
                />
                <div>
                  <p className={styles['dark-gray']}>{course.getIn(['instructor', 'name'])}</p>
                  <p className={styles['light-gray']}>{course.getIn(['instructor', 'title'])}</p>
                </div>
              </div>
            )}
            <Button
              url={redirectUrl(course)}
              className={styles['enroll-button']}
              state={redirectState(course.get('id'))}
            >
              {course.get('enrolled') ? 'View Course' : `$${course.get('price')} • Enroll Now`}
            </Button>
          </article>
        ))}
      </div>
    </Modal>
  )
}

AvailableClassesModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  name: PropTypes.string,
  content: PropTypes.instanceOf(List),
  authToken: PropTypes.string,
  hasUserCompletedOnboarding: PropTypes.bool,
}

AvailableClassesModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
  name: '',
  content: List(),
  authToken: '',
  hasUserCompletedOnboarding: false,
}

export default React.memo(AvailableClassesModal)
