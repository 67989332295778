import React from 'react'
import PropTypes from 'prop-types'
import Shiitake from 'shiitake'

const CourseCardContent = ({ className, content }) =>
  content ? (
    <Shiitake lines={4} throttleRate={200} className={className}>
      {content}
    </Shiitake>
  ) : (
    ''
  )

CourseCardContent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.string.isRequired,
}

CourseCardContent.defaultProps = {
  className: '',
}

export default React.memo(CourseCardContent)
