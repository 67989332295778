import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'

import CourseSection from '_components/course-section'
import Card from '_components/card'
import CourseClasses from '_components/course-classes'
import Footer from '_components/footer'
import { getCompanyClassById } from '_modules/course/actions'
import { companyClassSelector } from '_modules/course/selectors'
import { initialEnroll, INITIAL_ENROLL } from '_modules/class/actions'
import { usePrevious } from '_utils/hooks'
import { classShape } from '_utils/proptypes'
import { Class } from '_models/'

import StepHeader from '../step-header'

import styles from './styles.css'

const mapStateToProps = (state, { id }) => ({
  course: companyClassSelector(state, id),
  belongsToACompany: state.user.belongsToACompany,
  isPaying: !!state.loading.get(INITIAL_ENROLL.ACTION),
})

const mapDispatchToProps = {
  getCourseDetails: getCompanyClassById,
  enroll: initialEnroll,
}

const Course = ({ id, course, getCourseDetails, belongsToACompany, enroll, isPaying }) => {
  const prevLoading = usePrevious(isPaying)

  useEffect(
    () => {
      getCourseDetails(id)
    },
    [belongsToACompany, getCourseDetails, id]
  )

  const onClassEnroll = useCallback(
    (classId, payload = null) => {
      enroll({ classId, ...payload })
    },
    [enroll]
  )

  useEffect(
    () => {
      if (prevLoading && !isPaying) {
        navigate(['/onboarding/profile'])
      }
    },
    [prevLoading, isPaying]
  )

  if (!course || Object.keys(course).length === 0) {
    return null
  }

  return (
    <div className={styles.container}>
      <StepHeader
        belongsToACompany={belongsToACompany}
        className={styles.header}
        hasBackButton
        step={2}
        stepTitle={course.name}
        slug={id}
      />
      <Card className={styles.about}>
        <h3 className={styles.heading}>About this course</h3>
        <p className={styles.description}>{course?.description}</p>
      </Card>
      <CourseClasses
        className={styles['course-classes']}
        course={course}
        target={`/onboarding/checkout/${course.id}`}
        isOnboarding
        isCompany={belongsToACompany}
        onClassEnroll={onClassEnroll}
      />
      <h2 className={styles['course-content']}>Course content</h2>
      {course.sections.map(section => (
        <CourseSection
          readOnly
          titleClassName={styles['course-title']}
          key={`${section.get('name')}-${section.get('order')}`}
          className={styles['course-assignments']}
          title={section.get('name')}
          assignments={section.get('assignments').toJS()}
        />
      ))}
      <Footer className={styles.footer} svgClassName={styles.logo} linkClassName={styles.link} />
    </div>
  )
}

Course.propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  course: classShape,
  getCourseDetails: PropTypes.func.isRequired,
  belongsToACompany: PropTypes.bool.isRequired,
  enroll: PropTypes.func.isRequired,
  isPaying: PropTypes.bool.isRequired,
}

Course.defaultProps = {
  course: new Class(),
}
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Course)
