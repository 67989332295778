import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import IconButton from '_components/icon-button'
import CloseIcon from '_assets/icons/close.svg'

import styles from './styles.css'

const SelectedItemBox = ({ value, onRemoveClick, id }) => {
  const handleOnRemoveClick = useCallback(
    () => {
      onRemoveClick(value, id)
    },
    [id, onRemoveClick, value]
  )

  return (
    <div className={styles['selected-item-box-container']}>
      {value}
      <IconButton
        className={styles['remove-item-button']}
        icon={CloseIcon}
        iconClassName={styles['remove-item-button-icon']}
        onClick={handleOnRemoveClick}
      />
    </div>
  )
}

SelectedItemBox.propTypes = {
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  value: PropTypes.node.isRequired,
  onRemoveClick: PropTypes.func,
}

SelectedItemBox.defaultProps = {
  onRemoveClick: () => {},
}

export default React.memo(SelectedItemBox)
