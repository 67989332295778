import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import debounce from 'lodash.debounce'
import InputTrigger from 'react-input-trigger'

import TaggingUserList from '_components/tagging-user'
import Button, { ButtonTheme } from '_components/button'
import CloseIcon from '_assets/icons/close.svg'
import ErrorIcon from '_assets/icons/error.svg'
import { searchSuggestions } from '_modules/suggestion/actions'
import { MEDIUM_WIDTH } from '_utils/constants'
import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

const trigger = {
  keyCode: 50,
  shiftKey: typeof window !== 'undefined' && window.innerWidth > MEDIUM_WIDTH,
}

const BACKSPACE = 8

const mapStateToProps = ({ suggestion }) => ({
  suggestedUser: suggestion.results.toJS(),
})

const mapDispatchToProps = {
  showSuggestions: searchSuggestions,
}

class EditPostModal extends PureComponent {
  static propTypes = {
    onCloseModal: PropTypes.func,
    onSaveClick: PropTypes.func,
    editableMessage: PropTypes.string.isRequired,
    // eslint-disable-next-line react/forbid-prop-types
    suggestedUser: PropTypes.array.isRequired,
    showSuggestions: PropTypes.func.isRequired,
  }

  static defaultProps = {
    onCloseModal: () => {},
    onSaveClick: () => {},
  }

  constructor(props) {
    super(props)

    this.messageRef = createRef()
    this.suggestionRef = createRef()
    this.buttonRef = createRef()

    this.setUsernameDebounced = debounce(this.props.showSuggestions, 250)

    this.state = {
      text: props.editableMessage, // this will be updated next time we open the modal
      textError: false,
      showSuggestor: false,
      characterPosition: 0,
      username: '',
    }
  }

  componentDidUpdate(preProps, prevState) {
    if (prevState.showSuggestor && !this.state.showSuggestor) {
      this.messageRef.current.focus()
      document.removeEventListener('click', this.handleOutsideClick, false)
    }

    if (!prevState.showSuggestor && this.state.showSuggestor) {
      document.addEventListener('click', this.handleOutsideClick, false)
    }
    if (this.state.showSuggestor && prevState.username !== this.state.username) {
      this.setUsernameDebounced(this.state.username)
    }
  }

  onChange = event => {
    const { value, selectionStart } = event.target
    const { showSuggestor, text, characterPosition } = this.state
    this.setState({
      text: value,
      textError: false,
      characterPosition: selectionStart,
    })

    if (showSuggestor) {
      if (value.charAt(characterPosition) !== '@' && text.length !== selectionStart) {
        this.setState(prevState => {
          if (value[characterPosition]) {
            return {
              username: prevState.username + value[characterPosition],
            }
          }

          return {
            username: prevState.username,
          }
        })
      }
    } else if (!showSuggestor) {
      this.setState({
        username: '',
      })
    }
  }

  onSaveClick = event => {
    if (this.state.text) {
      this.props.onSaveClick({ message: this.state.text })
      this.props.onCloseModal(event)
    } else {
      this.setState({
        textError: true,
      })
    }
  }

  setInputTriggerRef = () => this.messageRef

  setSuggestionRef = ref => {
    this.suggestionRef.current = ref
  }

  setButtonRef = ref => {
    this.buttonRef.current = ref
  }

  setRef = ref => {
    this.messageRef.current = ref
  }

  handleOutsideClick = e => {
    if (this.suggestionRef.current && this.suggestionRef.current.contains(e.target)) {
      return
    }

    this.setState({
      showSuggestor: false,
    })

    this.endHandler()
  }

  checkBackSpace = event => {
    const { username, showSuggestor, text, characterPosition } = this.state

    if (event.keyCode === BACKSPACE && showSuggestor) {
      const deletedValue = username.substring(0, username.length - 1)
      this.setState({
        username: deletedValue,
      })

      if (text[characterPosition - 2] === '@') {
        this.setState({
          username: deletedValue,
        })
      }
    }
  }

  handleEndTrigger = ref => {
    this.endHandler = ref
  }

  handleTextareaInput = event => {
    event.preventDefault()
    const { value } = event.currentTarget
    const { characterPosition, text, username } = this.state
    const startTagging = characterPosition - username.length
    const newMessage = `${text.substring(0, startTagging)}${value} ${text.substring(
      characterPosition
    )}`

    if (this.messageRef.current) {
      this.messageRef.current.selectionStart = characterPosition + value.length + 1
    }

    this.setState(prevState => ({
      text: newMessage,
      showSuggestor: false,
      username: '',
      characterPosition: prevState.characterPosition + value.length + 1,
    }))
    this.endHandler()
  }

  toggleSuggestor = metaInformation => {
    const { hookType } = metaInformation
    const { text, characterPosition } = this.state
    const mobileOrDesktop = window.innerWidth < MEDIUM_WIDTH ? 0 : 1

    if (hookType === 'start') {
      if (
        text[characterPosition - mobileOrDesktop - 1] === ' ' ||
        characterPosition === mobileOrDesktop
      ) {
        this.props.showSuggestions()
        this.setState({
          showSuggestor: true,
        })
      } else {
        this.endHandler()
        this.setState({
          showSuggestor: false,
        })
      }
    } else if (hookType === 'cancel') {
      this.setState({
        showSuggestor: false,
      })
    }
  }

  render() {
    const { onCloseModal, suggestedUser } = this.props
    const { text, textError, showSuggestor } = this.state
    return (
      <div className={styles.card}>
        <p className={styles.title}>EDIT YOUR POST</p>
        <button
          onClick={onCloseModal}
          onMouseDown={onMouseDown}
          aria-label="close button"
          className={styles.close}
          type="button"
        >
          <svg viewBox={CloseIcon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${CloseIcon.id}`} />
          </svg>
        </button>
        <InputTrigger
          trigger={trigger}
          onStart={this.toggleSuggestor}
          onCancel={this.toggleSuggestor}
          endTrigger={this.handleEndTrigger}
          className={styles.content}
          inputRef={this.setInputTriggerRef}
        >
          <textarea
            className={classnames(styles.textarea, { [styles['text-error']]: textError })}
            placeholder="Edit your post here"
            onChange={this.onChange}
            value={text}
            ref={this.setRef}
            onKeyDown={this.checkBackSpace}
          />
        </InputTrigger>
        {showSuggestor && (
          <TaggingUserList
            suggestedUser={suggestedUser}
            buttonRef={this.setButtonRef}
            handleTextareaInput={this.handleTextareaInput}
            suggestionRef={this.setSuggestionRef}
            className={styles['tagging-user']}
          />
        )}
        {textError && (
          <div className={styles.error}>
            <svg
              className={styles.icon}
              viewBox={ErrorIcon.viewBox}
              aria-label="Error icon"
              role="img"
            >
              <use xlinkHref={`#${ErrorIcon.id}`} />
            </svg>
            <p className={styles.message}>Invalid post content</p>
          </div>
        )}
        <Button
          theme={ButtonTheme.PRIMARY}
          label="save changes"
          onClick={this.onSaveClick}
          className={styles['desktop-button']}
        />
        <Button
          theme={ButtonTheme.PRIMARY}
          label="save"
          onClick={this.onSaveClick}
          className={styles['mobile-button']}
        />
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditPostModal)
