import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWindowSize } from '@reach/window-size'

import CourseCard from '_components/landing-components/course-card'
import SectionTitle from '_components/landing-components/section-title'
import Button from '_components/landing-components/button'
import { prismicTitlePropType } from '_utils/proptypes'
import { MOBILE_THRESHOLD } from '_config/media-queries'

import styles from './styles.css'

const MOBILE_HEADER_SIZE = 72
const HEADER_SIZE = 80

const CoursesSection = ({ slice, id }) => {
  const { width } = useWindowSize()

  const featuredCourse = useMemo(
    () => slice.items?.length > 0 && slice.items.find(course => course.featuredCourse),
    [slice.items]
  )

  const courses = useMemo(
    () => slice.items?.length > 0 && slice.items.filter(course => !course.featuredCourse),
    [slice.items]
  )

  const handleScrollToSliceClick = useCallback(
    section => {
      const element = document.querySelector(`section[id^=${section}`)
      const { top } = element.getBoundingClientRect()
      const headerSize = width <= MOBILE_THRESHOLD ? MOBILE_HEADER_SIZE : HEADER_SIZE
      const slicePosition = top + window.scrollY - headerSize
      window.scrollTo({
        top: slicePosition,
        behavior: 'smooth',
      })
    },
    [width]
  )

  return (
    <section
      className={classnames(styles.section, { [styles['is-list']]: slice.isVerticalList })}
      id={id}
    >
      <div className={styles.content}>
        {slice.sectionTitle && <SectionTitle title={slice.sectionTitle} className={styles.title} />}
        {featuredCourse && (
          <CourseCard
            key="featured-course"
            course={featuredCourse}
            isList={slice.isVerticalList}
            handleScrollToSliceClick={handleScrollToSliceClick}
          />
        )}
        {courses.length > 0 && (
          <div className={styles['courses-cards']}>
            {courses.map(course => (
              <CourseCard
                key={`course-${course.courseTitle}`}
                course={course}
                isList={slice.isVerticalList}
                handleScrollToSliceClick={handleScrollToSliceClick}
              />
            ))}
          </div>
        )}
        {slice.actionButton?.url && (
          <Button url={slice.actionButton.url} className={styles['action-buttons']}>
            {slice.actionButton?.label}
          </Button>
        )}
      </div>
    </section>
  )
}

CoursesSection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    isVerticalList: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        coursePicture: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        }),
        courseLevel: PropTypes.string,
        courseTitle: PropTypes.string,
        courseDescription: PropTypes.string,
        courseUrl: PropTypes.string,
        featuredProgram: PropTypes.bool,
        featuredCourse: PropTypes.bool,
        isFree: PropTypes.bool,
        actionButtonScrollToSlice: PropTypes.string,
      })
    ),
    actionButton: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(CoursesSection)
