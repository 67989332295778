import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import CommunityIcon from '_assets/icons/ic_community.svg'
import LocketIcon from '_assets/icons/locker.svg'
import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

const BlockedDashboard = ({ className, title, heading, description }) => {
  const memberShipScrollToState = useMemo(
    () => ({
      billing: true,
    }),
    []
  )

  const learnMoreScrollToState = useMemo(
    () => ({
      ScrollToSectionId: 'mini-course',
    }),
    []
  )
  return (
    <section className={className}>
      {title && <h1 className={classnames(styles.text, styles.title)}>{title}</h1>}
      <div className={styles['blocked-dashboard']}>
        <svg className={styles['locker-icon']} aria-hidden="true" viewBox={LocketIcon.viewBox}>
          <use xlinkHref={`#${LocketIcon.id}`} />
        </svg>
        <h2 className={classnames(styles.text, styles.heading)}>{heading}</h2>
        <p className={classnames(styles.text, styles.description)}>{description}</p>
        <div className={styles['bottom-section']}>
          <svg
            className={styles['community-icon']}
            aria-hidden="true"
            viewBox={CommunityIcon.viewBox}
          >
            <use xlinkHref={`#${CommunityIcon.id}`} />
          </svg>
          <h3 className={styles.subtitle}>Join the Lightyear community!</h3>
          <p className={classnames(styles.text, styles.description, styles['share-your-goals'])}>
            Share your goals, support your collegues and get access to exclusive content from
            Lightyear.
          </p>
          <Button
            label="REACTIVATE MEMBERSHIP"
            theme={ButtonTheme.PRIMARY}
            state={memberShipScrollToState}
            to="/account"
            className={styles.button}
          />
          <Button
            label="Learn more"
            theme={ButtonTheme.TRANSPARENT_BACKGROUND}
            to="/explore-courses"
            state={learnMoreScrollToState}
          />
        </div>
      </div>
    </section>
  )
}

BlockedDashboard.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
}

BlockedDashboard.defaultProps = {
  className: '',
  title: '',
}

export default React.memo(BlockedDashboard)
