import { defineAction } from '_utils/redux'
import createActionCreators from '_utils/create-action-creators'
import { getAllAssignmentResponses } from '_services/class'

const GET_ALL_ASSIGNMENT_RESPONSES = defineAction('GET_ALL_ASSIGNMENT_RESPONSES')

const actionCreators = {
  ...createActionCreators('ASSIGNMENT'),
  GET_ALL_ASSIGNMENT_RESPONSES,
  getAllAssignmentResponses: ({ classId, assignmentId }) => (dispatch, getState) => {
    dispatch({
      type: GET_ALL_ASSIGNMENT_RESPONSES.ACTION,
      payload: getAllAssignmentResponses(getState().user.authToken)(classId, assignmentId),
    })
  },
}

export default actionCreators
