import { authTokenSelector } from '_modules/user/selectors'
import * as publicClassService from '_services/public-classes'
import { defineAction } from '_utils/redux'

export const GET_AVAILABLE_CLASSES = defineAction('GET_AVAILABLE_CLASSES')
export const GET_PUBLIC_CLASS = defineAction('GET_PUBLIC_CLASS')

export const getAvailableClasses = (params, isInfiniteScroll = false) => (dispatch, getState) => {
  dispatch({
    type: GET_AVAILABLE_CLASSES.ACTION,
    payload: publicClassService.getAvailableClasses(authTokenSelector(getState()), params),
    meta: {
      isInfiniteScroll,
    },
  })
}

export const getPublicClass = classId => (dispatch, getState) => {
  dispatch({
    type: GET_PUBLIC_CLASS.ACTION,
    payload: publicClassService.getPublicClass(authTokenSelector(getState()), classId),
  })
}
