import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

export const CardButtonSize = {
  DEFAULT: 'default',
  MEDIUM: 'medium',
  LARGE: 'large',
}

const CardButton = ({ className, children, size, id, onClick }) => (
  <button
    id={id}
    className={classnames(styles.container, styles[size], className)}
    onClick={onClick}
    type="button"
  >
    {children}
  </button>
)

CardButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  size: PropTypes.oneOf(Object.keys(CardButtonSize).map(size => CardButtonSize[size])),
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onClick: PropTypes.func,
}

CardButton.defaultProps = {
  className: '',
  size: CardButtonSize.DEFAULT,
  id: undefined,
  onClick: () => {},
}

export default CardButton
