import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

import FreeMembershipIcon from '_assets/icons/ic_free_membership.svg'
import MembershipIcon from '_assets/icons/ic_membership.svg'
import CommunityIcon from '_assets/icons/ic_community.svg'
import MentoringIcon from '_assets/icons/ic_mentoring.svg'
import Modal, { MOBILE_THEME } from '_components/modal-card'
import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

const MEMBERSHIP_FEATURES = [
  { text: 'Access to exclusive content on our gallery', icon: MembershipIcon },
  { text: 'Support from the Lightyear Community', icon: CommunityIcon },
  { text: 'Free Group Calls with a Mentor', icon: MentoringIcon },
]

const FreeMembershipTrialModal = ({ onClose, membershipTrialTime = 30, courseName }) => {
  const scrollToState = useMemo(
    () => ({
      ScrollToSectionId: 'mini-course',
    }),
    []
  )

  const formattedMembershipTrialTime = useMemo(
    () => {
      if (membershipTrialTime % 30) {
        return `${membershipTrialTime} free days`
      }

      const months = membershipTrialTime / 30
      return `${months} free month${months > 1 ? 's' : ''}`
    },
    [membershipTrialTime]
  )

  return (
    <Modal
      isOpen
      onClose={onClose}
      hasCloseButton={false}
      className={styles.modal}
      childrenClassName={styles.content}
      theme={MOBILE_THEME.FIXED_HEIGHT}
    >
      <svg
        className={styles['free-membership-icon']}
        aria-hidden="true"
        viewBox={FreeMembershipIcon.viewBox}
      >
        <use xlinkHref={`#${FreeMembershipIcon.id}`} />
      </svg>
      <h1 className={styles.title}>
        You have {formattedMembershipTrialTime} of <br />
        <span className={styles['lightyear-membership']}>Lightyear Membership!</span>
      </h1>
      <p className={styles.description}>
        Your course <span className={styles.bold}>{courseName}</span> comes with{' '}
        {formattedMembershipTrialTime} of access to the Lightyear Membership! You can now access:
      </p>
      <div className={styles['features-container']}>
        {MEMBERSHIP_FEATURES.map(feature => (
          <div key={feature.text} className={styles.feature}>
            <svg
              className={styles['feature-icon']}
              aria-hidden="true"
              viewBox={feature.icon.viewBox}
            >
              <use xlinkHref={`#${feature.icon.id}`} />
            </svg>
            <p className={styles['feature-text']}>{feature.text}</p>
          </div>
        ))}
      </div>
      <Button
        className={styles['close-button']}
        theme={ButtonTheme.PRIMARY}
        onClick={onClose}
        label="OK, GOT IT!"
      />
      <Link to="/explore-courses" state={scrollToState} className={styles['learn-more']}>
        Learn More
      </Link>
    </Modal>
  )
}

FreeMembershipTrialModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  membershipTrialTime: PropTypes.number.isRequired,
  courseName: PropTypes.string.isRequired,
}

export default React.memo(FreeMembershipTrialModal)
