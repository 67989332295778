/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// I had to disable those since eslint can't figure out I have a nested input inside the Input component. But the issues are being taken care of
import React, { useReducer, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { CardNumberElement, CardExpiryElement, CardCvcElement } from '@stripe/react-stripe-js'

import Input from '_components/landing-components/input'
import { StripeNewOptions } from '_utils/constants'
import { creditCardNameValidation } from '_utils/helpers'

import styles from './styles.css'
import { ACTION, reducer, INITIAL_STATE } from './reducer'

const PaymentFormFields = ({ onNameChange, setError, className }) => {
  const [{ errors, ...state }, dispatchReducer] = useReducer(reducer, INITIAL_STATE)

  const onChange = useCallback(
    event => {
      const { value } = event.target

      const nameError = creditCardNameValidation(value)
      if (nameError) {
        setError(true)
      }
      dispatchReducer({
        type: ACTION.INPUT_CHANGE,
        name: 'name',
        value,
        error: nameError,
      })

      onNameChange(value)
    },
    [onNameChange, setError]
  )

  const onStripeElementChange = useCallback(
    ({ elementType, error }) => {
      if (error) {
        dispatchReducer({
          type: ACTION.INPUT_CHANGE,
          name: elementType,
          error: error.message,
        })

        setError(true)
      } else {
        setError(false)
        dispatchReducer({ type: ACTION.RESET_ERRORS })
      }
    },
    [setError]
  )
  return (
    <div className={classnames(styles['payments-form'], className)}>
      <label htmlFor="nameInput" className={styles['input-label']}>
        Full name
        <Input
          id="nameInput"
          onChange={onChange}
          value={state.name}
          className={styles.input}
          error={!!errors.name}
          helperText={errors.name}
          hasHelperText
        />
      </label>
      <label htmlFor="number" className={styles['input-label']}>
        Card number
        <CardNumberElement
          className={classnames(styles['stripe-input'], {
            [styles.error]: errors.cardNumber,
          })}
          {...StripeNewOptions}
          placeholder=" "
          id="number"
          onChange={onStripeElementChange}
        />
        {errors.cardNumber && <p className={styles['field-error']}>{errors.cardNumber}</p>}
      </label>
      <div className={styles['cvc-expiry-container']}>
        <label htmlFor="expiry" className={styles['input-label']}>
          Expiration
          <CardExpiryElement
            className={classnames(styles['stripe-input'], {
              [styles.error]: errors.cardExpiry,
            })}
            {...StripeNewOptions}
            placeholder=""
            id="expiry"
            onChange={onStripeElementChange}
          />
          {errors.cardExpiry && <p className={styles['field-error']}>{errors.cardExpiry}</p>}
        </label>
        <label htmlFor="cvc" className={classnames(styles['input-label'], styles.cvc)}>
          Security code
          <CardCvcElement
            className={classnames(styles['stripe-input'], {
              [styles.error]: errors.cardCvc,
            })}
            options={StripeNewOptions}
            placeholder=""
            id="cvc"
          />
          {errors.cardCvc && <p className={styles['field-error']}>{errors.cardCvc}</p>}
        </label>
      </div>
    </div>
  )
}

PaymentFormFields.propTypes = {
  onNameChange: PropTypes.func,
  className: PropTypes.string,
  setError: PropTypes.func,
}

PaymentFormFields.defaultProps = {
  onNameChange: () => {},
  className: '',
  setError: () => {},
}

export default React.memo(PaymentFormFields)
