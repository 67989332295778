import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

const Chips = ({ className, label, active, disabled, onClick, id }) => {
  const onButtonClick = useCallback(
    event => {
      event.preventDefault()
      onClick(id)
    },
    [id, onClick]
  )

  return (
    <button
      type="button"
      className={classnames(styles.chips, { [styles.active]: active }, className)}
      onClick={onButtonClick}
      onMouseDown={onMouseDown}
      disabled={disabled}
    >
      {label}
    </button>
  )
}

Chips.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

Chips.defaultProps = {
  className: '',
  label: 'click me',
  onClick: () => {},
  active: false,
  disabled: false,
  id: null,
}

export default Chips
