import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import ArrowIcon from '_assets/icons/angle-down.svg'

import styles from './styles.css'

export const ARROW_DIRECTION = {
  RIGHT: 'right',
  LEFT: 'left',
}

const SliderArrow = ({ onClick, arrowDirection, arrowClassName }) => (
    <button
      className={classnames(
        arrowClassName,
        styles['custom-arrow'],
        styles[`${arrowDirection}-arrow`]
      )}
      aria-label={arrowDirection === ARROW_DIRECTION.LEFT ? 'Previous' : 'Next'}
      onClick={onClick}
    >
      <svg className={classnames(styles.icon, styles[arrowDirection])} viewBox={ArrowIcon.viewBox}>
        <use xlinkHref={`#${ArrowIcon.id}`} />
      </svg>
    </button>
  )

SliderArrow.propTypes = {
  onClick: PropTypes.func,
  arrowDirection: PropTypes.oneOf(Object.values(ARROW_DIRECTION)),
  arrowClassName: PropTypes.string,
}

SliderArrow.defaultProps = {
  onClick: () => {},
  arrowDirection: ARROW_DIRECTION.RIGHT,
  arrowClassName: null,
}

export default SliderArrow
