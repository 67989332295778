import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'

import Placeholder1 from '_assets/images/placeholder-1.png'
import { isKeyboardOrClick } from '_utils/aria'
import { favorite, unfavorite } from '_modules/course/actions'
import { classShape } from '_utils/proptypes'

import CourseImage from './course-image'
import CourseCardHeader from './course-card-header'
import CourseCardContent from './course-card-content'
import CourseCardFooter from './course-card-footer'
import styles from './styles.css'

const mapDispatchToProps = {
  unFavoriteCourse: unfavorite,
  favoriteCourse: favorite,
}

const CourseCard = ({
  className,
  isOnboarding,
  target,
  course,
  isFavoritable,
  favoriteCourse,
  unFavoriteCourse,
  isDiscoverCard,
  isCompany,
}) => {
  const [isFavorite, setFavorite] = useState(course.favorite)

  const onFavoriteClick = useCallback(
    event => {
      if (!isKeyboardOrClick(event.key)) {
        return
      }

      event.preventDefault()
      if (!course.favorite) {
        favoriteCourse(course.slug, course.id, course.company)
        return
      }

      unFavoriteCourse(course.slug, course.id, course.company)
    },
    [course.company, course.favorite, course.id, course.slug, favoriteCourse, unFavoriteCourse]
  )

  useEffect(
    () => {
      setFavorite(course.favorite)
    },
    [course.favorite]
  )

  return (
    <div className={classnames(styles.container, className, { [styles.onboarding]: isOnboarding })}>
      {!isOnboarding && (
        <CourseImage
          isOnboarding={isOnboarding}
          className={styles.image}
          picture={course.coverPhoto || Placeholder1}
          name={course.name}
        />
      )}
      <div className={styles['content-wrapper']}>
        <CourseCardHeader
          className={styles.header}
          isOnboarding={isOnboarding}
          target={target}
          isFavoritable={isFavoritable}
          course={course}
          onFavoriteClick={onFavoriteClick}
          isFavorite={isFavorite}
        />
        <CourseCardContent content={course.description} className={styles.content} />
        <CourseCardFooter
          className={styles.footer}
          target={target}
          isOnboarding={isOnboarding}
          isDiscoverCard={isDiscoverCard}
          course={course}
          isCompany={isCompany}
        />
      </div>
    </div>
  )
}

CourseCard.propTypes = {
  className: PropTypes.string,
  isOnboarding: PropTypes.bool,
  target: PropTypes.string.isRequired,
  course: classShape.isRequired,
  isFavoritable: PropTypes.bool,
  isDiscoverCard: PropTypes.bool,
  favoriteCourse: PropTypes.func.isRequired,
  unFavoriteCourse: PropTypes.func.isRequired,
  isCompany: PropTypes.bool,
}

CourseCard.defaultProps = {
  className: '',
  isFavoritable: false,
  isDiscoverCard: false,
  isOnboarding: false,
  isCompany: false,
}

export default connect(
  null,
  mapDispatchToProps
)(React.memo(CourseCard))
