import { List, Map } from 'immutable'

import Model from './model'

class PublicCourse extends Model({
  id: undefined,
  name: undefined,
  picture: undefined,
  creator: undefined,
  classes: Map(),
  sections: List(),
  description: undefined,
}) {}

export default PublicCourse
