import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { navigate, Link } from '@reach/router'
import moment from 'moment'
import Linkify from 'react-linkify'

import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import Button, { ButtonTheme } from '_components/button'
import LockerIcon from '_assets/icons/locker.svg'
import HashtagIcon from '_assets/icons/hashtag.svg'
import { searchMessageShape } from '_utils/proptypes'

import styles from './styles.css'

const MessageChannelCard = ({ className, target, message, isPrivate }) => {
  const onClick = useCallback(() => navigate(target), [target])

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.information}>
        <ProfileAvatar
          username={message.author.username}
          src={message.author.photo}
          size={AvatarSize.SMALL}
          className={styles.avatar}
        />
        <div className={styles.details}>
          <div className={styles['author-info']}>
            <Link className={styles.author} to={`/user/${message.author.username}`}>
              {message.author.name}
            </Link>
            <Link className={styles.username} to={`/user/${message.author.username}`}>
              @{message.author.username}
            </Link>
          </div>
          <div className={styles.date}>
            <p>in</p>
            <svg
              className={styles.icon}
              viewBox={isPrivate ? LockerIcon.viewBox : HashtagIcon.viewBox}
              role="img"
              aria-label={`${isPrivate ? 'Private' : 'Public'} channel`}
            >
              <use xlinkHref={`#${isPrivate ? LockerIcon.id : HashtagIcon.id}`} />
            </svg>
            <p className={styles.bold}>{message.channelName}</p>
            <p>on {moment(message.createdAt).format('MMMM DD [at] LT')}</p>
          </div>
        </div>
      </div>
      <div className={styles['post-content']}>
        <Linkify
          properties={{
            style: {
              color: 'white',
            },
            target: '_blank',
          }}
        >
          <p className={styles.content}>{message.message}</p>
        </Linkify>
      </div>
      <Button
        onClick={onClick}
        theme={ButtonTheme.PRIMARY}
        label="GO TO CHANNEL"
        className={styles.action}
      />
    </div>
  )
}

MessageChannelCard.propTypes = {
  className: PropTypes.string,
  target: PropTypes.string.isRequired,
  message: searchMessageShape.isRequired,
  isPrivate: PropTypes.bool,
}

MessageChannelCard.defaultProps = {
  className: '',
  isPrivate: false,
}

export default MessageChannelCard
