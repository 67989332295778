import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import { RoleType } from '_utils/constants'
import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import Button, { ButtonTheme } from '_components/button'
import Card from '_components/card'
import { userShape } from '_utils/proptypes'

import styles from './styles.css'

const UserCard = ({ className, target, user }) => {
  const onClick = useCallback(() => navigate(target), [target])

  return (
    <Card className={classnames(styles.container, className)}>
      <div className={styles.information}>
        <ProfileAvatar
          username={user.username}
          src={user.photo}
          roleType={RoleType[user.role]}
          size={AvatarSize.SMALL}
        />
        <div className={styles.details}>
          <p className={styles.author}>{user.name}</p>
          <p className={styles.username}>@{user.username}</p>
        </div>
      </div>
      <Button
        onClick={onClick}
        theme={ButtonTheme.PRIMARY}
        label="VIEW FUTURE"
        className={styles.action}
      />
    </Card>
  )
}

UserCard.propTypes = {
  className: PropTypes.string,
  target: PropTypes.string.isRequired,
  user: userShape.isRequired,
}

UserCard.defaultProps = {
  className: '',
}

export default UserCard
