import PropTypes from 'prop-types'
import { List } from 'immutable'

export const paymentHistoryShape = PropTypes.shape({
  id: PropTypes.number,
  amount: PropTypes.number,
  cardBrand: PropTypes.string,
  cardExpMonth: PropTypes.number,
  cardExpYear: PropTypes.number,
  createdAt: PropTypes.string,
  description: PropTypes.string,
  receiptUrl: PropTypes.string,
  refunded: PropTypes.bool,
  stripeCharge: PropTypes.string,
  updatedAt: PropTypes.string,
  user: PropTypes.number,
  cardLast4: PropTypes.string,
})

export const userShape = PropTypes.shape({
  id: PropTypes.number,
  belongsToACompany: PropTypes.bool,
  completedOnboarding: PropTypes.bool,
  createdAt: PropTypes.string,
  email: PropTypes.string,
  location: PropTypes.string,
  name: PropTypes.string,
  photo: PropTypes.string,
  title: PropTypes.string,
  updatedAt: PropTypes.string,
  username: PropTypes.string,
  authToken: PropTypes.string,
  company: PropTypes.string,
  following: PropTypes.bool,
  online: PropTypes.bool,
  role: PropTypes.string,
  suspended: PropTypes.bool,
  hasZoom: PropTypes.bool,
  skipSubscription: PropTypes.bool,
  emailDisableAll: PropTypes.bool,
  emailMessages: PropTypes.bool,
  emailCourseAdd: PropTypes.bool,
  emailSummaryAddedToChannels: PropTypes.bool,
  emailSummaryMessagesInChannels: PropTypes.bool,
  emailSummaryPostComments: PropTypes.bool,
  emailSummaryPostLikes: PropTypes.bool,
  emailSummaryGoalLikes: PropTypes.bool,
  emailSummaryNewFollowers: PropTypes.bool,
  emailSummaryFriendSuggestions: PropTypes.bool,
  emailSummaryFrequency: PropTypes.string,
  subscriptionId: PropTypes.string,
  subscriptionStatus: PropTypes.string,
  subscriptionIsCanceling: PropTypes.bool,
  subscriptionTrialEnd: PropTypes.string,
  subscriptionStart: PropTypes.string,
  subscriptionEndedAt: PropTypes.string,
  payments: PropTypes.shape({
    next: PropTypes.number,
    previous: PropTypes.number,
    paymentHistory: PropTypes.arrayOf(paymentHistoryShape),
  }),
})

export const questionShape = PropTypes.shape({ question: PropTypes.string, id: PropTypes.number })

export const assignmentShape = PropTypes.shape({
  id: PropTypes.number,
  order: PropTypes.number,
  name: PropTypes.string,
  questions: PropTypes.arrayOf(questionShape),
  assignment: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    sectionName: PropTypes.string,
    sectionId: PropTypes.number,
    sectionOrder: PropTypes.number,
    description: PropTypes.string,
    shortDescription: PropTypes.string,
    duration: PropTypes.number,
    dueDate: PropTypes.string,
    order: PropTypes.number,
  }),
  attachments: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
    })
  ),
})

export const sectionShape = PropTypes.shape({
  name: PropTypes.string,
  order: PropTypes.number,
  assignments: PropTypes.arrayOf(assignmentShape),
})

export const classShape = PropTypes.shape({
  type: PropTypes.string,
  courseName: PropTypes.string,
  coursePicture: PropTypes.string,
  courseSlug: PropTypes.string,
  id: PropTypes.number,
  instructor: userShape,
  intention: PropTypes.string,
  completePercentage: PropTypes.number,
  courseDescription: PropTypes.string,
  courseDuration: PropTypes.number,
  startDate: PropTypes.string,
  peopleEnrolled: PropTypes.oneOfType([PropTypes.arrayOf(userShape), PropTypes.instanceOf(List)]),
  assignments: PropTypes.oneOfType([
    PropTypes.arrayOf(assignmentShape),
    PropTypes.instanceOf(List),
  ]),
  company: PropTypes.bool,
})

export const courseShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  picture: PropTypes.string,
  slug: PropTypes.string,
  rank: PropTypes.number,
  duration: PropTypes.number,
  creator: userShape,
  categories: PropTypes.arrayOf(PropTypes.string),
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  classes: PropTypes.arrayOf(classShape),
  sections: PropTypes.arrayOf(sectionShape),
  favorite: PropTypes.bool,
  peopleEnrolled: PropTypes.oneOfType([PropTypes.arrayOf(userShape), PropTypes.instanceOf(List)]),
  description: PropTypes.string,
})

export const cardShape = PropTypes.shape({
  id: PropTypes.string,
  brand: PropTypes.string,
  country: PropTypes.string,
  expMonth: PropTypes.number,
  expYear: PropTypes.number,
  funding: PropTypes.string,
  last4: PropTypes.string,
  default: PropTypes.bool,
})

export const messageShape = PropTypes.shape({
  id: PropTypes.number,
  message: PropTypes.string,
  file: PropTypes.string,
  author: userShape,
  createdAt: PropTypes.string,
  userName: PropTypes.string,
  meeting: PropTypes.shape({
    id: PropTypes.number,
    ended: PropTypes.bool,
    joinUrl: PropTypes.string,
    startTime: PropTypes.string,
    started: PropTypes.bool,
    topic: PropTypes.string,
  }),
})

export const channelShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  numberOfMembers: PropTypes.number,
  private: PropTypes.bool,
  disabled: PropTypes.bool,
  messages: PropTypes.arrayOf(messageShape),
  next: PropTypes.number,
  previous: PropTypes.number,
  count: PropTypes.number,
  read: PropTypes.bool,
  isMember: PropTypes.bool,
  admin: PropTypes.bool,
  membersPreview: PropTypes.arrayOf(userShape),
})

export const directMessageShape = PropTypes.shape({
  user: userShape,
  lastMessage: messageShape,
  messages: PropTypes.arrayOf(messageShape),
  next: PropTypes.number,
  previous: PropTypes.number,
  count: PropTypes.number,
  read: PropTypes.bool,
})

export const goalShape = PropTypes.shape({
  id: PropTypes.number,
  description: PropTypes.string,
  dueDate: PropTypes.string,
  private: PropTypes.bool,
  completed: PropTypes.bool,
})

export const goalCategoryShape = PropTypes.shape({
  id: PropTypes.number,
  title: PropTypes.string,
  declaration: PropTypes.string,
  goals: PropTypes.arrayOf(goalShape),
})

export const searchChannelShape = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  private: PropTypes.bool,
  numberOfMembers: PropTypes.number,
  disabled: PropTypes.bool,
  read: PropTypes.bool,
  members: PropTypes.arrayOf(userShape),
})

export const searchMessageShape = PropTypes.shape({
  id: PropTypes.number,
  message: PropTypes.string,
  file: PropTypes.string,
  author: userShape,
  filename: PropTypes.string,
  createdAt: PropTypes.string,
  userName: PropTypes.string,
  meeting: PropTypes.string,
})

export const commentShape = PropTypes.shape({
  message: PropTypes.string,
  author: userShape,
  updatedAt: PropTypes.string,
  photo: PropTypes.string,
  videoLink: PropTypes.string,
  shazam: PropTypes.number,
  flagged: PropTypes.bool,
  id: PropTypes.number,
  createdAt: PropTypes.string,
})

export const postShape = PropTypes.shape({
  message: PropTypes.string,
  author: userShape,
  updatedAt: PropTypes.string,
  photo: PropTypes.string,
  comments: PropTypes.arrayOf(commentShape),
  videoLink: PropTypes.string,
  shazam: PropTypes.number,
  flagged: PropTypes.bool,
  id: PropTypes.number,
  createdAt: PropTypes.string,
  shazamedByYou: PropTypes.bool,
})

export const summaryShape = PropTypes.shape({
  takingCourses: PropTypes.number,
  totalAssignments: PropTypes.number,
  completedAssignments: PropTypes.number,
  upcomingAssignments: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      dueDate: PropTypes.string,
    })
  ),
})

export const upcomingGoalsShape = PropTypes.arrayOf(
  PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string,
    dueDate: PropTypes.string,
    private: PropTypes.bool,
    completed: PropTypes.bool,
  })
)

export const dashboardShape = PropTypes.shape({
  summary: summaryShape,
  upcomingGoals: upcomingGoalsShape,
  peopleYouMayKnow: PropTypes.arrayOf(userShape),
})

export const profileShape = PropTypes.shape({
  visionStatement: PropTypes.string,
  legacyStatement: PropTypes.string,
  goals: PropTypes.arrayOf(goalCategoryShape),
  posts: PropTypes.arrayOf(postShape),
  peopleYouMayKnow: PropTypes.arrayOf(
    PropTypes.shape({
      userShape,
      following: PropTypes.bool,
    })
  ),
})

export const notificationShape = PropTypes.shape({
  id: PropTypes.number,
  createdAt: PropTypes.string,
  updatedAt: PropTypes.string,
  type: PropTypes.string,
  data: PropTypes.shape({
    user: userShape,
  }),
  read: PropTypes.bool,
})

export const notificationsShape = PropTypes.shape({
  count: PropTypes.number,
  next: PropTypes.number,
  previous: PropTypes.previous,
  notifications: PropTypes.arrayOf(notificationShape),
})

export const alertShape = PropTypes.shape({
  id: PropTypes.number,
  content: PropTypes.string,
  callToAction: PropTypes.string,
  url: PropTypes.string,
})

export const participantShape = PropTypes.shape({
  userShape,
  courseIntention: PropTypes.string,
})

const landingTitleShape = PropTypes.shape({
  text: PropTypes.string,
  type: PropTypes.string,
})

export const prismicTitlePropType = PropTypes.oneOfType([
  PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.oneOfType([landingTitleShape, PropTypes.arrayOf(landingTitleShape)])
    )
  ),
  PropTypes.string,
])

export const prismicContentPropType = PropTypes.arrayOf(
  PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.string,
      })
    )
  )
)

export const prismicImagePropTypes = PropTypes.shape({
  url: PropTypes.string,
  alt: PropTypes.string,
})

export const prismicMemberProTypes = PropTypes.shape({
  name: PropTypes.string,
  position: PropTypes.string,
  picture: prismicImagePropTypes,
  pictureMobile: prismicImagePropTypes,
  pictureModal: prismicImagePropTypes,
  information: prismicContentPropType,
})

export const leaderPropTypes = PropTypes.shape({
  id: PropTypes.number,
  name: PropTypes.string,
  avatar: PropTypes.string,
})

export const coachesPropTypes = PropTypes.oneOfType([
  PropTypes.arrayOf(leaderPropTypes),
  leaderPropTypes,
])

export const iconPropTypes = PropTypes.oneOfType([
  PropTypes.shape({
    viewBox: PropTypes.string,
    id: PropTypes.string,
  }),
  PropTypes.string,
])
