import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import Button, { ButtonTheme } from '_components/landing-components/button'
import { sliceGetAvatarAndText } from '_utils/helpers'
import UserPlaceholder from '_assets/icons/user-placeholder.svg'
import PersonFilledIcon from '_assets/icons/person-filled-icon.svg'
import Svg from '_components/svg'
import useWindowSize from '_hooks/use-window-size'
import ActionCard from '_components/action-card'
import { userShape } from '_utils/proptypes'
import { getTotalParticipantsSelector } from '_modules/class/selectors'

import styles from './styles.css'

const MAX_PARTICIPANTS_INFO = 2

const ClassParticipants = ({ classId, participantsList }) => {
  const totalParticipants = useSelector(getTotalParticipantsSelector)

  const [participants, participantsText] = sliceGetAvatarAndText(
    participantsList,
    MAX_PARTICIPANTS_INFO,
    totalParticipants
  )
  const { isMobile } = useWindowSize()

  const participantsViewPath = useMemo(() => `/class/${classId}/participants`, [classId])
  const actionCardInformation = useMemo(
    () => `${participantsList?.size} participant${participantsList?.size > 1 ? 's' : ''}`,
    [participantsList]
  )

  if (isMobile) {
    return (
      <ActionCard
        to={participantsViewPath}
        icon={PersonFilledIcon}
        title="Participants"
        information={actionCardInformation}
        iconClassName={styles['card-icon']}
      />
    )
  }

  return (
    <section className={styles['class-participants-container']}>
      <h1 className={styles['class-participants-title']}>Participants</h1>
      <div className={styles['class-participants-content']}>
        {participants.map(
          participant =>
            participant.photo ? (
              <img
                className={styles['participant-avatar']}
                key={participant.id}
                src={participant.photo}
                alt={`${participant.name}'s avatar`}
              />
            ) : (
              <Svg
                key={participant.id}
                className={styles['participant-avatar']}
                icon={UserPlaceholder}
              />
            )
        )}
        <p className={styles['participants-names']}>{participantsText}</p>
      </div>
      <Button
        className={styles['view-all-button']}
        theme={ButtonTheme.TRANSPARENT_GRAY}
        url={participantsViewPath}
      >
        View all
      </Button>
    </section>
  )
}

ClassParticipants.propTypes = {
  classId: PropTypes.number.isRequired,
  participantsList: userShape.isRequired,
}

export default ClassParticipants
