import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { isImageType } from '_utils/helpers'
import NewTabIcon from '_assets/icons/new-tab-icon.svg'
import FileIcon from '_assets/icons/file.svg'
import AddVideoIcon from '_assets/icons/add_video.svg'
import Svg from '_components/svg'
import IconButton, { ICON_BUTTON_SIZE, ICON_BUTTON_THEME } from '_components/icon-button'

import styles from './styles.css'

const MP4 = 'MP4'

const ClassAttachmentsList = ({ fileList, className }) => {
  const handleIcon = useCallback(filename => filename.slice(filename.length - 3) === MP4 ? AddVideoIcon : FileIcon, [])

  return (
    <ul className={classnames(styles['attachment-list'], className)}>
      {fileList.map((attachment, index) => (
        <li className={styles['attachment-item']} key={attachment.file}>
          {isImageType(attachment.filename) ? (
            <img
              className={styles['attachment-file']}
              src={attachment.file}
              alt={`Class Attachment ${index + 1}`}
            />
          ) : (
            <div className={styles['file-icon-wrapper']}>
              <Svg className={styles['file-icon']} icon={handleIcon(attachment.filename)} />
            </div>
          )}
          <p className={styles['attachment-filename']}>{attachment.filename}</p>
          <IconButton
            className={styles['open-new-tab-button']}
            iconClassName={styles['open-new-tab-icon']}
            size={ICON_BUTTON_SIZE.LARGE}
            icon={NewTabIcon}
            href={attachment.file}
            target="_blank"
            theme={ICON_BUTTON_THEME.DARK_TRANSPARENT}
            arial-label="An arrow down for download"
            download
          />
        </li>
      ))}
    </ul>
  )
}

ClassAttachmentsList.propTypes = {
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      filename: PropTypes.string,
    })
  ).isRequired,
  className: PropTypes.string,
}

ClassAttachmentsList.defaultProps = {
  className: '',
}

export default React.memo(ClassAttachmentsList)
