import { differenceInMinutes } from 'date-fns'
import { useEffect, useState, useMemo } from 'react'

export default assignment => {
  const startTimeDifferenceFromNow = useMemo(
    () => differenceInMinutes(new Date(assignment?.assignment?.meeting?.startTime), new Date()),
    [assignment]
  )
  const [minuteCountdown, setMinuteCountdown] = useState(startTimeDifferenceFromNow)
  const timeToEndMeeting = useMemo(
    () => minuteCountdown + assignment?.assignment?.meeting?.duration,
    [assignment, minuteCountdown]
  )

  useEffect(
    () => {
      setMinuteCountdown(startTimeDifferenceFromNow)
    },
    [startTimeDifferenceFromNow]
  )

  useEffect(() => {
    let countdown = null

    countdown = setInterval(() => {
      if (timeToEndMeeting > 0) {
        setMinuteCountdown(prevValue => prevValue - 1)
      } else {
        clearInterval(countdown)
      }
    }, 60000)

    return () => {
      clearInterval(countdown)
    }
  }, []) //eslint-disable-line

  return [minuteCountdown, timeToEndMeeting]
}
