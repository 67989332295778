import React from 'react'

import { TYPES } from '_constants/prismic'
import { formatPathname } from '_utils/location'
import CourseTypes from '_components/landing-components/course-types'
import MembershipHeroSection from '_views/memberships/membership-hero-section'

import TestimonialsSection from './testimonials-section'
import CoursesSection from './courses-section'
import CompaniesSection from './companies-section'
import CompaniesCarouselSection from './companies-carousel-section'
import HeroSection from './hero-section'
import NewToLightyearSection from './new-to-lightyear-section'
import PersonalDevelopmentCoursesSection from './personal-development-courses-section'
import QuoteSection from './quote-section'
import MultipleQuotesSection from './multiple-quotes-section'
import VideoSection from './video-section'
import ClaritySection from './clarity-section'
import QuestionsSection from './questions-section'
import MiniCourseSection from './mini-course-section'
import SubscriptionTypeSection from './subscription-type-section'
import GetNotifiedSection from './get-notified-section'
import LightyearsPick from './lightyears-pick-section'
import JoinUsToday from './join-us-today'
import ListItemSection from './list-item-section'
import OurShareback from './our-shareback-section'
import AboutSusanne from './about-susanne'
import TheLightyearTeam from './the-lightyear-team'
import ClassesList from './classes-list'
import ExploreCourses from './explore-courses'

export const getSlices = (
  slice,
  index,
  courseSlug,
  hasLeaderLedClasses,
  isMemberships = false
) => ({
  [TYPES.HERO_SECTION]: isMemberships ? (
    <MembershipHeroSection
      key={`hero-${index}`}
      id={`hero-${index}`}
      slice={slice}
      courseSlug={courseSlug}
    />
  ) : (
    <HeroSection key={`hero-${index}`} id={`hero-${index}`} slice={slice} courseSlug={courseSlug} />
  ),
  [TYPES.CLASSES_SLICE]: <ClassesList key={`classes-list-${index}`} id={`classes-list-${index}`} />,
  [TYPES.PERSONAL_DEVELOPMENT_COURSES]: (
    <PersonalDevelopmentCoursesSection
      key={`personal-development-courses-${index}`}
      id={`personal-development-courses-${index}`}
      slice={slice}
    />
  ),
  [TYPES.QUOTE_SECTION]: (
    <QuoteSection key={`quote-${index}`} id={`quote-${index}`} slice={slice} />
  ),
  [TYPES.MULTIPLE_QUOTES_SECTION]: (
    <MultipleQuotesSection key={`quote-${index}`} id={`quote-${index}`} slice={slice} />
  ),
  [TYPES.COURSES]: (
    <CoursesSection key={`courses-${index}`} id={`courses-${index}`} slice={slice} />
  ),
  [TYPES.COURSE_TYPES]: (
    <CourseTypes key={`courses-types-${index}`} id={`courses-types${index}`} slice={slice} />
  ),
  [TYPES.ITEMS_LIST]: (
    <ListItemSection key={`items-list-${index}`} id={`items-list-${index}`} slice={slice} />
  ),
  [TYPES.JOIN_US_TODAY]: (
    <JoinUsToday key={`join-us-today-${index}`} id={`join-us-today-${index}`} slice={slice} />
  ),
  [TYPES.ABOUT_OUR_FOUNDER]: (
    <VideoSection key={`about-${index}`} id={`about-${index}`} slice={slice} />
  ),
  [TYPES.VIDEO_SECTION]: (
    <VideoSection key={`about-${index}`} id={`about-${index}`} slice={slice} />
  ),
  [TYPES.TESTIMONIAL]: (
    <TestimonialsSection key={`testimonial-${index}`} id={`testimonial-${index}`} slice={slice} />
  ),
  [TYPES.COMPANIES_CAROUSEL]: (
    <CompaniesCarouselSection key={`carousel-${index}`} id={`carousel-${index}`} slice={slice} />
  ),
  [TYPES.COMPANIES]: (
    <CompaniesSection key={`companies-${index}`} id={`companies-${index}`} slice={slice} />
  ),
  [TYPES.NEW_TO_LIGHTYEAR]: (
    <NewToLightyearSection
      key={`new-to-lightyear-${index}`}
      id={`new-to-lightyear-${index}`}
      slice={slice}
    />
  ),
  [TYPES.CLARITY_SECTION]: (
    <ClaritySection key={`clarity-${index}`} id={`clarity-${index}`} slice={slice} />
  ),
  [TYPES.QUESTIONS_SECTION]: (
    <QuestionsSection key={`questions-${index}`} id={`questions-${index}`} slice={slice} />
  ),
  [TYPES.MINI_COURSE_SECTION]: (
    <MiniCourseSection key={`mini-course-${index}`} id={`mini-course-${index}`} slice={slice} />
  ),
  [TYPES.SUBSCRIPTION_TYPE_SECTION]: (
    <SubscriptionTypeSection
      key={`subscription-type-${index}`}
      id={`subscription-type-${index}`}
      slice={slice}
    />
  ),
  [TYPES.GET_NOTIFIED_SECTION]: !hasLeaderLedClasses && (
    <GetNotifiedSection key={`get-notified-${index}`} id={`get-notified-${index}`} slice={slice} />
  ),
  [TYPES.LIGHTYEARS_PICK]: (
    <LightyearsPick
      key={`lightyears-pick-${index}`}
      id={`lightyears-pick-${index}`}
      slice={slice}
    />
  ),
  [TYPES.OUR_SHAREBACK]: (
    <OurShareback key={`our-shareback-${index}`} id={`our-shareback-${index}`} slice={slice} />
  ),
  [TYPES.ABOUT_SUSANNE]: (
    <AboutSusanne key={`about-susanne-${index}`} id={`about-susanne-${index}`} slice={slice} />
  ),
  [TYPES.EXPLORE_COURSES]: (
    <ExploreCourses
      key={`explore-courses-${index}`}
      id={`explore-courses-${index}`}
      slice={slice}
    />
  ),
  [TYPES.THE_LIGHTYEAR_TEAM]: (
    <TheLightyearTeam
      key={`the-lightyear-team-${index}`}
      id={`the-lightyear-team-${index}`}
      slice={slice}
    />
  ),
})

export const views = {
  landing: 'landing',
  courses: 'courses',
  exploreCourses: 'explore_courses',
  powerMyFuture: 'power_my_future',
  visionPlusGoals: 'vision_plus_goals',
  integratedLeadership: 'integrated_leadership',
  lightyearCoachTraining: 'lightyear_coach_training',
  workshops: 'workshops',
  wholeProsperity: 'whole_prosperity',
  getThereNow: 'get_there_now',
  legadoPersonal: 'legado_personal',
  aboutUs: 'about_us',
  memberships: 'memberships',
}

export const getCurrentView = pathname => {
  switch (pathname) {
    case '/':
    case '/login':
    case '/sign-up':
    case '/forgot-password':
    case '/email-sent':
    case '/payments':
    case '/classes':
      return views.landing
    case '/course':
      return views.courses
    default:
      return formatPathname(pathname)
  }
}
