import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { coachesPropTypes } from '_utils/proptypes'
import { COACH_LIST_SIZE } from '_utils/constants'
import UserPlaceholderIcon from '_assets/icons/user-placeholder.svg'
import Svg from '_components/svg'

import styles from './styles.css'

export const COACH_AVATAR_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

const SLICED_COACHES_FROM_LIST = 2

const CoachAvatar = ({ coaches, dynamicSize, centralize }) => {
  const avatarSize = useMemo(
    () => {
      if (coaches.size >= COACH_LIST_SIZE.THREE) {
        return COACH_AVATAR_SIZE.SMALL
      }

      if (coaches.size === COACH_LIST_SIZE.TWO) {
        return COACH_AVATAR_SIZE.MEDIUM
      }

      return COACH_AVATAR_SIZE.LARGE
    },
    [coaches]
  )

  const slicedCoaches = useMemo(
    () => coaches.size <= COACH_LIST_SIZE.TWO
        ? coaches.slice(0, coaches.size)
        : coaches.slice(0, SLICED_COACHES_FROM_LIST),
    [coaches]
  )

  if (coaches.size < COACH_LIST_SIZE.THREE) {
    return slicedCoaches.map(
      (coach, index) =>
        coach.get('photo') ? (
          <img
            key={coach.get('id')}
            className={classnames(styles['coach-avatar'], {
              [styles[avatarSize]]: dynamicSize,
              [styles.centralize]: centralize,
              [styles['not-first']]: index !== 0,
            })}
            src={coach.get('photo')}
            alt={`${coach.get('name')}'s avatar`}
          />
        ) : (
          <Svg
            key={coach.get('id')}
            className={classnames(styles['coach-avatar'], styles['coach-avatar-placeholder'], {
              [styles[avatarSize]]: dynamicSize,
              [styles.centralize]: centralize,
              [styles['not-first']]: index !== 0,
            })}
            icon={UserPlaceholderIcon}
          />
        )
    )
  }

  return (
    <>
      {slicedCoaches.map(
        (coach, index) =>
          coach.get('photo') ? (
            <img
              key={coach.get('id')}
              className={classnames(styles['coach-avatar'], {
                [styles[avatarSize]]: dynamicSize,
                [styles.centralize]: centralize,
                [styles['not-first']]: index !== 0,
              })}
              src={coach.get('photo')}
              alt={`${coach.get('name')}'s avatar`}
            />
          ) : (
            <Svg
              className={classnames(styles['coach-avatar'], styles['coach-avatar-placeholder'], {
                [styles[avatarSize]]: dynamicSize,
                [styles.centralize]: centralize,
                [styles['not-first']]: index !== 0,
              })}
              icon={UserPlaceholderIcon}
            />
          )
      )}
      <div
        className={classnames(styles['coach-avatar'], styles['plus-coaches'], styles['not-first'], {
          [styles[avatarSize]]: dynamicSize,
          [styles.centralize]: centralize,
        })}
      >
        + {coaches.size - SLICED_COACHES_FROM_LIST}
      </div>
    </>
  )
}

CoachAvatar.propTypes = {
  coaches: coachesPropTypes,
  dynamicSize: PropTypes.bool,
  centralize: PropTypes.bool,
}

CoachAvatar.defaultProps = {
  coaches: [],
  dynamicSize: false,
  centralize: false,
}

export default React.memo(CoachAvatar)
