import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import IconButton from '_components/icon-button'
import ErrorTooltip from '_components/error-tooltip'
import { iconPropTypes } from '_utils/proptypes'

import styles from './styles.css'

const Input = ({
  type,
  className,
  labelClassName,
  inputClassName,
  label,
  placeholder,
  errorMessage,
  disabled,
  onChange,
  value,
  name,
  id,
  autocomplete,
  endAdornment,
  adornmentClassName,
  onAdornmentClick,
  ...inputProps
}) => {
  const messageSelector = useMemo(
    () => {
      switch (errorMessage) {
        case 'Invalid coupon.':
          return 'Invalid referral code.'
        case 'A user with that username already exists.':
          return 'This username already exists, please add a different one.'
        default:
          return errorMessage
      }
    },
    [errorMessage]
  )

  return (
    <div className={classnames(styles.container, className)}>
      {!!label && (
        <p
          className={classnames(styles.label, labelClassName, {
            [styles['disabled-label']]: disabled,
          })}
        >
          {label}
        </p>
      )}
      <div className={styles['tooltip-wrapper']}>
        {!!errorMessage && (
          <ErrorTooltip className={styles['error-icon']} message={messageSelector} />
        )}
        <input
          disabled={disabled}
          name={name}
          type={type}
          className={classnames(styles.input, inputClassName, {
            [styles.error]: errorMessage,
          })}
          placeholder={placeholder}
          onChange={onChange}
          value={value}
          id={id}
          autoComplete={autocomplete}
          {...inputProps}
        />
        {endAdornment && (
          <IconButton
            icon={endAdornment}
            className={classnames(styles['end-adornment'], adornmentClassName)}
            onClick={onAdornmentClick}
          />
        )}
      </div>
    </div>
  )
}

Input.propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  disabled: PropTypes.bool,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  value: PropTypes.string,
  errorMessage: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  autocomplete: PropTypes.string,
  endAdornment: iconPropTypes,
  adornmentClassName: PropTypes.string,
  onAdornmentClick: PropTypes.func,
}

Input.defaultProps = {
  type: 'text',
  name: '',
  disabled: false,
  className: '',
  labelClassName: '',
  inputClassName: '',
  placeholder: '',
  label: '',
  value: '',
  errorMessage: '',
  id: null,
  onChange: () => {},
  autocomplete: '',
  endAdornment: null,
  adornmentClassName: '',
  onAdornmentClick: () => {},
}

export default Input
