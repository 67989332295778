import React, { Fragment, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import Linkify from 'react-linkify'
import { Link } from '@reach/router'

import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import { tagsRegex } from '_utils/constants'
import { messageShape } from '_utils/proptypes'

import ZoomMessage from '../zoom-message'
// Todo: Fix this error
// eslint-disable-next-line
import FileMessage from '../file-message'

import styles from './styles.css'

export const MessageTheme = {
  USER_MESSAGE: 'user-message',
  OWN_MESSAGE: 'own-message',
}

const DirectMessage = ({ theme, content, className, withAvatar, id, endMeeting, loading }) => {
  const isPhoto = useMemo(
    () =>
      content.file
        ? ['jpg', 'jpeg', 'png', 'tif', 'gif'].includes(content.file.split('.').pop())
        : false,
    [content.file]
  )

  const isZoomURL = useMemo(() => content.meeting && !!content.meeting.meetingId, [content.meeting])

  const renderMessageWithTags = useCallback(message => {
    const splittedComment = message.split(' ')
    return (
      <p className={styles.content}>
        {splittedComment.map((word, index) => {
          if (tagsRegex.test(word) && word.indexOf('@') === 0) {
            return (
              // eslint-disable-next-line
              <Fragment key={`${word}-index${index}`}>
                {' '}
                <Link to={`/user/${word.split('@')[1]}`} className={styles.tag}>
                  {`${word}`}
                </Link>
              </Fragment>
            )
          }
          return ` ${word}`
        })}
      </p>
    )
  }, [])

  const renderMessage = useMemo(
    () => {
      if (isZoomURL) {
        return (
          <span className={styles.content}>
            <ZoomMessage
              meeting={content.meeting}
              name="Meeting now"
              theme={theme}
              endMeeting={endMeeting}
              loading={loading}
            />
          </span>
        )
      }

      if (isPhoto) {
        return (
          <div className={classnames(styles.photo, styles.content)}>
            <a href={content.file} target="_blank" rel="noopener noreferrer">
              <img
                src={content.file}
                alt={`Uploaded media by ${content.author.username}`}
                className={styles.picture}
              />
            </a>
          </div>
        )
      }

      if (content.message) {
        return renderMessageWithTags(content.message)
      }

      return (
        <div className={styles.content}>
          <FileMessage fileName={content.filename} file={content.file} theme={theme} />
        </div>
      )
    },
    [
      content.author.username,
      content.file,
      content.filename,
      content.meeting,
      content.message,
      endMeeting,
      isPhoto,
      isZoomURL,
      loading,
      renderMessageWithTags,
      theme,
    ]
  )

  const linkColor = theme === MessageTheme.USER_MESSAGE ? '#7387ff' : 'white'

  return (
    <div className={classnames(styles.container, styles[theme], className)} id={id}>
      <Linkify
        properties={{
          style: {
            color: linkColor,
          },
          target: '_blank',
        }}
      >
        <div className={styles.message} key={content.author.id}>
          {theme === MessageTheme.USER_MESSAGE &&
            withAvatar && (
              <ProfileAvatar
                username={content.author.username}
                src={content.author.photo}
                size={AvatarSize.SMALL}
                className={styles.avatar}
              />
            )}
          {renderMessage}
          <p className={styles.date}>{moment(content.createdAt).format('MMMM Do YYYY, LTS')}</p>
        </div>
      </Linkify>
    </div>
  )
}

DirectMessage.propTypes = {
  theme: PropTypes.oneOf(Object.keys(MessageTheme).map(theme => MessageTheme[theme])),
  content: messageShape.isRequired,
  className: PropTypes.string,
  withAvatar: PropTypes.bool,
  id: PropTypes.number.isRequired,
  endMeeting: PropTypes.func,
  loading: PropTypes.bool,
}

DirectMessage.defaultProps = {
  theme: MessageTheme.RECIPIENT_MESSAGE,
  className: '',
  withAvatar: false,
  endMeeting: () => {},
  loading: false,
}

export default DirectMessage
