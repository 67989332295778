export const ACTIONS = {
  TOGGLE_NEW_VIDEO: 'toggle-new-video-modal',
  TOGGLE_LINK_ZOOM: 'toggle-link-zoom-modal',
  TOGGLE_LEAVE_CHANNEL: 'toggle-leave-channel',
  TOGGLE_MORE_OPTIONS: 'toggle-more-options',
  CLOSE_MODAL: 'close-modal',
}

export const initialState = {
  isNewVideoModalOpen: false,
  isLinkZoomModalOpen: false,
  isLeaveModalOpen: false,
  hasMoreOptions: false,
}

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTIONS.TOGGLE_NEW_VIDEO:
      return {
        ...state,
        isNewVideoModalOpen: !state.isNewVideoModalOpen,
        isLinkZoomModalOpen: false,
      }
    case ACTIONS.TOGGLE_LINK_ZOOM:
      return {
        ...state,
        isNewVideoModalOpen: false,
        isLinkZoomModalOpen: !state.isLinkZoomModalOpen,
      }
    case ACTIONS.TOGGLE_LEAVE_CHANNEL:
      return { ...state, isLeaveModalOpen: !state.isLeaveModalOpen }
    case ACTIONS.TOGGLE_MORE_OPTIONS:
      return { ...state, hasMoreOptions: !state.hasMoreOptions }
    case ACTIONS.CLOSE_MODAL:
      return initialState
    default:
      return state
  }
}
