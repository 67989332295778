export const INITIAL_STATE = {
  isLoginModalOpen: false,
  isSignUpModalOpen: false,
  isForgotPasswordModalOpen: false,
  isPasswordResetConfirmationModalOpen: false,
  isCheckoutModalOpen: false,
}

export const TOGGLE_MODAL = 'TOGGLE_MODAL'
export const ON_CLOSE_MODAL = 'ON_CLOSE_MODAL'

export const AUTHENTICATION_URLS = {
  LOGIN: 'login',
  SIGN_UP: 'sign-up',
  FORGOT_PASSWORD: 'forgot-password',
  EMAIL_SENT: 'email-sent',
  //  TODO: Update this to checkout once the flow is fully integrated
  CHECKOUT: 'payments',
}

export const getCurrentModalThroughUrl = (pathname, search) => {
  if (
    pathname.includes(AUTHENTICATION_URLS.LOGIN) ||
    (!!search && search.includes(AUTHENTICATION_URLS.LOGIN))
  ) {
    return { isLoginModalOpen: true }
  }

  if (
    pathname.includes(AUTHENTICATION_URLS.SIGN_UP) ||
    (!!search && search.includes(AUTHENTICATION_URLS.SIGN_UP))
  ) {
    return { isSignUpModalOpen: true }
  }

  if (
    pathname.includes(AUTHENTICATION_URLS.FORGOT_PASSWORD) ||
    (!!search && search.includes(AUTHENTICATION_URLS.FORGOT_PASSWORD))
  ) {
    return { isForgotPasswordModalOpen: true }
  }

  if (
    pathname.includes(AUTHENTICATION_URLS.EMAIL_SENT) ||
    (!!search && search.includes(AUTHENTICATION_URLS.EMAIL_SENT))
  ) {
    return { isPasswordResetConfirmationModalOpen: true }
  }

  if (
    pathname.includes(AUTHENTICATION_URLS.CHECKOUT) ||
    (!!search && search.includes(AUTHENTICATION_URLS.CHECKOUT))
  ) {
    return { isCheckoutModalOpen: true }
  }

  return {}
}

export const reducer = (state, action) => {
  switch (action.type) {
    case TOGGLE_MODAL: {
      const newObject = state
      Object.keys(state).forEach(key => {
        if (key in action.payload) {
          newObject[key] = action.payload[key]
          return
        }
        newObject[key] = false
      })
      return { ...newObject }
    }
    case ON_CLOSE_MODAL: {
      const newObject = state
      Object.keys(state).forEach(key => {
        newObject[key] = false
      })

      return { ...newObject }
    }
    default:
      return state
  }
}
