import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useLocation } from '@reach/router'

import usePrevious from '_hooks/use-previous'
import LightyearLogo from '_assets/full-logo.svg'
import favicon16 from '_assets/public/favicon-16x16.png'

import styles from './styles.css'

const TermPrivacyCode = ({ children }) => {
  const location = useLocation()
  const lastLocation = usePrevious(location.key)

  useEffect(
    () => {
      if (location.key !== lastLocation) {
        window.scrollTo(0, 0)
      }
    },
    [lastLocation, location.key, location.pathname.key]
  )

  return (
    <div className={styles.container}>
      <Helmet
        title="Lightyear"
        link={[{ rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16 }]}
      />
      <div className={styles.header}>
        <svg viewBox={LightyearLogo.viewBox} aria-label="Lightyear logo" role="img" id="Screens">
          <use xlinkHref={`#${LightyearLogo.id}`} />
        </svg>
      </div>
      <div className={styles.main}>{children}</div>
    </div>
  )
}

TermPrivacyCode.propTypes = {
  children: PropTypes.node.isRequired,
}

export default React.memo(TermPrivacyCode)
