import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import CourseTag from '_components/course-tag'
import ViewClassesLink from '_components/view-classes-link'
import Svg from '_components/svg'
import { COURSE_TYPES_INFO, COURSE_TYPES } from '_constants/course'
import { getCategoryClassesTotalCountSelector } from '_modules/public-categories/selectors'

import styles from './styles.css'

const CourseTypeCard = ({ title, description, tags, type, className, url, isSliderItem }) => {
  const availableClasses = useSelector(state => getCategoryClassesTotalCountSelector(state, title))

  const iconSize = useMemo(
    () => {
      switch (type) {
        case COURSE_TYPES.POWER_MY_FUTURE:
          return {
            height: '10.4rem',
            width: '10.4rem',
          }
        case COURSE_TYPES.VISION_PLUS_GOALS:
          return {
            height: '14.4rem',
            width: '14.4rem',
          }
        case COURSE_TYPES.INTEGRATED_LEADERSHIP:
          return {
            height: '12rem',
            width: '12rem',
          }
        default:
          return {
            height: '16rem',
            width: '16rem',
          }
      }
    },
    [type]
  )

  return (
    <dl className={classnames(styles['course-type-card'], className)}>
      <Svg className={styles['course-icon']} icon={COURSE_TYPES_INFO[type].icon} style={iconSize} />

      <div className={styles['tag-wrapper']}>
        {tags.map(tag => <CourseTag key={tag} text={tag} />)}
      </div>

      <dt className={styles['course-type-title']}>{title}</dt>
      <dd className={styles['course-type-description']}>{description}</dd>

      <ViewClassesLink
        className={styles['view-classes-link']}
        availableClasses={availableClasses}
        url={url}
        isSliderItem={isSliderItem}
      />
    </dl>
  )
}

CourseTypeCard.propTypes = {
  type: PropTypes.string.isRequired,
  title: PropTypes.string,
  description: PropTypes.string,
  tags: PropTypes.arrayOf(PropTypes.string),
  className: PropTypes.string,
  url: PropTypes.string,
  isSliderItem: PropTypes.bool,
}

CourseTypeCard.defaultProps = {
  title: '',
  description: '',
  tags: [],
  className: '',
  url: '',
  isSliderItem: false,
}

export default React.memo(CourseTypeCard)
