import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import Button, { ButtonTheme } from '_components/button'
import { classShape } from '_utils/proptypes'

import styles from './styles.css'

const LeaderLedClassSection = ({
  courseClass,
  isOnboarding,
  onUserUnfollow,
  onUserFollow,
  onMessageClick,
}) => {
  const onMessageInstructorClick = useCallback(
    instructor => () => {
      onMessageClick({
        username: instructor.get('username'),
        receiverId: instructor.get('id'),
      })
    },
    [onMessageClick]
  )

  const onFollowClick = useCallback(
    instructor => () => {
      onUserFollow({ username: instructor.get('username') })
    },
    [onUserFollow]
  )

  const onUnfollowClick = useCallback(
    instructor => () => onUserUnfollow({ username: instructor.get('username') }),
    [onUserUnfollow]
  )

  return courseClass.instructors?.map(instructor => (
    <div className={styles.leader}>
      <ProfileAvatar
        className={styles.profile}
        src={instructor.get('photo')}
        roleType={instructor.get('role')}
        size={AvatarSize.MEDIUM}
      />
      <h2 className={styles.name}>{instructor?.get('name')}</h2>
      {!isOnboarding && (
        <div className={styles.actions}>
          <Button
            label={instructor.get('following') ? 'FOLLOWING' : 'FOLLOW'}
            theme={instructor.get('following') ? ButtonTheme.SECONDARY : ButtonTheme.PRIMARY}
            onClick={
              instructor.get('following') ? onUnfollowClick(instructor) : onFollowClick(instructor)
            }
          />
          <Button
            label="Message"
            theme={ButtonTheme.PRIMARY}
            onClick={onMessageInstructorClick(instructor)}
          />
        </div>
      )}
    </div>
  ))
}

LeaderLedClassSection.propTypes = {
  courseClass: classShape.isRequired,
  isOnboarding: PropTypes.bool,
  onUserUnfollow: PropTypes.func,
  onUserFollow: PropTypes.func,
  onMessageClick: PropTypes.func,
}

LeaderLedClassSection.defaultProps = {
  isOnboarding: false,
  onUserFollow: () => {},
  onUserUnfollow: () => {},
  onMessageClick: () => {},
}

export default React.memo(LeaderLedClassSection)
