import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate } from '@reach/router'

import PeopleCard from '_components/people-card'
import Button from '_components/button'
import { userShape } from '_utils/proptypes'

import styles from './styles.css'

const PeopleEnrolledSection = ({
  className,
  isEnrolled,
  usersEnrolled,
  onFollow,
  onUnfollow,
  classId,
  title,
}) => {
  const onSeeAllClick = useCallback(
    event => {
      event.preventDefault()
      navigate(`/class/${classId}/participants`)
    },
    [classId]
  )

  return (
    <div className={classnames(styles.container, className)}>
      <div
        className={classnames(styles.content, {
          [styles.enrolled]: isEnrolled,
        })}
      >
        <h2 className={styles.title}>{title}</h2>
        {isEnrolled &&
          usersEnrolled.length > 0 && (
            <Button
              className={styles.action}
              label="SEE ALL PARTICIPANTS"
              onClick={onSeeAllClick}
            />
          )}
        <div className={styles.people}>
          {usersEnrolled
            .slice(0, 4)
            .map(user => (
              <PeopleCard
                key={user.id}
                name={user.name}
                username={user.username}
                src={user.photo}
                organization={user.organization}
                following={user.following}
                onFollowAction={onFollow}
                onUnfollowAction={onUnfollow}
              />
            ))}
        </div>
      </div>
    </div>
  )
}

PeopleEnrolledSection.propTypes = {
  className: PropTypes.string,
  isEnrolled: PropTypes.bool,
  usersEnrolled: PropTypes.arrayOf(
    PropTypes.shape({
      userShape,
      following: PropTypes.bool,
    })
  ),
  onFollow: PropTypes.func,
  onUnfollow: PropTypes.func,
  classId: PropTypes.number,
  title: PropTypes.string,
}

PeopleEnrolledSection.defaultProps = {
  className: '',
  isEnrolled: false,
  usersEnrolled: [],
  onFollow: () => {},
  onUnfollow: () => {},
  classId: null,
  title: 'People enrolled in this course',
}

export default PeopleEnrolledSection
