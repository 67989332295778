import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import { cardShape } from '_utils/proptypes'
import { BrandIcon } from '_constants/credit-card'

import styles from './styles.css'

const CreditCard = ({ card, className, onChange, id, ...restProps }) => {
  const icon = useMemo(() => BrandIcon(card.brand), [card.brand])

  return (
    <div className={classnames(styles.card, className)}>
      <input className={styles.input} type="radio" id={id} onChange={onChange} {...restProps} />
      {/* eslint-disable-next-line */}
      <label htmlFor={id} className={styles.label}>
        <img src={icon} alt="Card brand icon" className={styles.brand} />
        <span className={styles['card-info']}>
          <span className={styles.number}>
            {card.brand} •••• {card.last4}
          </span>
          <span className={styles.expiry}>
            Expires on {card.expMonth}/{card.expYear}
          </span>
        </span>
      </label>
    </div>
  )
}

CreditCard.propTypes = {
  card: cardShape.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
}

CreditCard.defaultProps = {
  className: '',
}

export default React.memo(CreditCard)
