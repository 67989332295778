import React from 'react'

export const GOOGLE_LOGIN_EMAIL_ALREADY_EXISTS_ERROR = [
  'You have previously created your account with your email',
  'Please sign in with your email',
]

export const ALREADY_SIGN_UP_WITH_GOOGLE_ERROR =
  'You signed up with Google, please use the button below to sign in.'

export const SIGN_UP_WITH_GOOGLE_MESSAGE =
  'You signed up with Google using this email, please use the button below to sign in.'

export const MINIMUM_PASSWORD_ERROR = 'Please enter a password that has at least 8 characters.'

export const ALREADY_HAS_LIGHTYEAR_ACCOUNT = `You've already created an account with Lightyear, please login to your account.`

export const emailAccountAlert = (email, boldClassName) => (
  <>
    You have previously created your account with your email{' '}
    <span className={boldClassName}>{email}</span>. Please sign in with your email.
  </>
)
