import React, { useCallback, useState, useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Input from '_components/input'
import Button, { ButtonTheme } from '_components/button'
import { userShape } from '_utils/proptypes'

import styles from './styles.css'

const ProfileForm = ({ loading, user, className, onSubmit, usernameError }) => {
  const [state, setState] = useState({
    name: user.name || '',
    username: user.username || '',
    company: user.belongsToACompany ? user.company : '',
    location: '',
    title: '',
  })

  const onInputChange = useCallback(
    event => {
      const { name, value } = event.target
      setState({
        ...state,
        [name]: value,
      })
    },
    [state]
  )

  const onFormSubmit = useCallback(
    event => {
      event.preventDefault()
      const data = { ...state }
      onSubmit(data)
    },
    [onSubmit, state]
  )

  const isDisabled = useMemo(() => state.name.length === 0 || state.username.length === 0, [
    state.name.length,
    state.username.length,
  ])

  return (
    <form className={classnames(styles.container, className)} onSubmit={onFormSubmit}>
      <Input
        disabled={loading}
        label="Full name*"
        name="name"
        placeholder="Jane Doe"
        onChange={onInputChange}
        value={state.name}
      />
      <div className={styles.wrapper}>
        <Input
          disabled={loading}
          className={styles.input}
          label="Username*"
          name="username"
          placeholder="janedoe"
          onChange={onInputChange}
          value={state.username}
          errorMessage={usernameError}
        />
        <Input
          disabled={loading || user.belongsToACompany}
          className={styles.input}
          label="Company"
          name="company"
          onChange={onInputChange}
          value={state.company}
        />
      </div>
      <div className={styles.wrapper}>
        <Input
          disabled={loading}
          className={styles.input}
          label="Location"
          name="location"
          onChange={onInputChange}
          value={state.location}
        />
        <Input
          disabled={loading}
          className={styles.input}
          label="Title"
          name="title"
          onChange={onInputChange}
          value={state.title}
        />
      </div>
      <Button
        loading={loading}
        className={styles.button}
        theme={ButtonTheme.PRIMARY}
        label="EXPLORE"
        type="submit"
        disabled={isDisabled}
      />
    </form>
  )
}

ProfileForm.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  user: userShape.isRequired,
  onSubmit: PropTypes.func.isRequired,
  usernameError: PropTypes.string,
}

ProfileForm.defaultProps = {
  className: '',
  loading: false,
  usernameError: '',
}

export default React.memo(ProfileForm)
