import React, { Fragment, useCallback, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate, useLocation } from '@reach/router'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { toast } from 'react-toastify'
import download from 'downloadjs'
import { useWindowSize } from '@reach/window-size'

import Toastr, { ToastrTheme } from '_components/toastr'
import PROFILE_ACTIONS from '_modules/profile/actions'
import Button, { ButtonTheme } from '_components/button'
import Footer from '_components/footer'
import ArrowLeftIcon from '_assets/icons/arrow-left.svg'
import Modal from '_components/modal'
import UserGoalInformation from '_components/user-goal-information'
import UserGoalModal from '_components/user-goal-modal'
import { goalCategoryShape, userShape } from '_utils/proptypes'
import { usePrevious } from '_utils/hooks'
import { exportGoals } from '_services/user'
import { shazamGoal, unshazamGoal } from '_modules/user/actions'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import CoursesCover from '_assets/images/course-cover.png'
import { linearGradientBg } from '_utils/constants'

import styles from './styles.css'
import ManageGoalCategories from './manage-goal-categories'
import AddOrEditGoals from './add-edit-goals'
import RemovedGoalModal from './removed-goal-modal'

export const GoalsType = {
  PERSONAL: 'PERSONAL',
  HEALTH: 'HEALTH',
  CAREER: 'CAREER',
}

const MOBILE_HEADER_SIZE = 72
const HEADER_SIZE = 80
const DESKTOP_OFFSET = 200
const {
  CREATE_GOAL_CATEGORY,
  UPDATE_GOAL_CATEGORY,
  DELETE_GOAL_CATEGORY,
  CREATE_GOAL,
  UPDATE_GOAL,
  DELETE_GOAL,
  GET_MY_FUTURE,
  createGoalCategory,
  updateGoalCategory,
  deleteGoalCategory,
  createGoal,
  updateGoal,
  deleteGoal,
} = PROFILE_ACTIONS

const mapStateToProps = ({ loading, error, user }) => ({
  isUpdatingCategory:
    !!loading.get(CREATE_GOAL_CATEGORY.ACTION) ||
    !!loading.get(UPDATE_GOAL_CATEGORY.ACTION) ||
    !!loading.get(DELETE_GOAL_CATEGORY.ACTION),
  updatingCategoryError:
    error.get(CREATE_GOAL_CATEGORY.ACTION) ||
    error.get(UPDATE_GOAL_CATEGORY.ACTION) ||
    error.get(DELETE_GOAL_CATEGORY.ACTION),
  isUpdatingGoal:
    !!loading.get(CREATE_GOAL.ACTION) ||
    !!loading.get(UPDATE_GOAL.ACTION) ||
    !!loading.get(DELETE_GOAL.ACTION),
  updatingGoalError:
    error.get(CREATE_GOAL.ACTION) || error.get(UPDATE_GOAL.ACTION) || error.get(DELETE_GOAL.ACTION),
  authToken: user.authToken,
  isGetMyFutureLoading: !!loading.get(GET_MY_FUTURE.ACTION),
  getMyFutureError: error.get(GET_MY_FUTURE, Map()),
})

const mapDispatchToProps = {
  newGoalCategory: createGoalCategory,
  editGoalCategory: updateGoalCategory,
  removeGoalCategory: deleteGoalCategory,
  newGoal: createGoal,
  editGoal: updateGoal,
  removeGoal: deleteGoal,
  shazamUserGoal: shazamGoal,
  unshazamUserGoal: unshazamGoal,
}

const initialState = {
  modalType: null,
  editedGoal: null,
  title: '',
  content: '',
  buttonLabel: '',
}

const CLOSE_MODAL = 'CLOSE_MODAL'
const RESET = 'RESET'
const MODAL_TYPE = {
  CATEGORIES: 'CATEGORIES',
  REMOVE: 'REMOVE',
  COMPLETE: 'COMPLETE',
  UNCOMPLETE: 'UNCOMPLETE',
  EDIT: 'EDIT',
  ADD: 'ADD',
  REMOVED_GOAL: 'REMOVED_GOAL',
}

const reducer = (state, action) => {
  switch (action.type) {
    case MODAL_TYPE.CATEGORIES:
      return { ...state, modalType: action.modalType }
    case MODAL_TYPE.REMOVE:
      return {
        ...state,
        modalType: action.modalType,
        submit: action.onRemove,
        goalCategoryId: action.goalCategoryId,
        editedGoal: action.editedGoal,
        title: 'Remove goal',
        buttonLabel: 'Remove goal',
        content:
          'Once a goal is removed, it can no longer be viewed, updated, or restored. Please confirm that you wish to remove it.',
      }
    case MODAL_TYPE.COMPLETE:
      return {
        ...state,
        modalType: action.modalType,
        submit: action.onComplete,
        goalCategoryId: action.goalCategoryId,
        editedGoal: action.editedGoal,
        title: 'WOOHOO!!',
        buttonLabel: 'View all completed goals',
        content:
          "Goal celebration time! You've completed a goal. You are creating your future, now! Take a moment to congratulate yourself and reflect on how far you've come. We are here for you every step of the way \uD83D\uDE0A.",
      }
    case MODAL_TYPE.UNCOMPLETE:
      return {
        ...state,
        modalType: action.modalType,
        submit: action.onUncomplete,
        goalCategoryId: action.goalCategoryId,
        editedGoal: action.editedGoal,
        title: 'MARK GOAL AS UNCOMPLETE',
        buttonLabel: 'Mark as uncomplete',
        content: 'Do you wish to mark goal as uncomplete?',
      }
    case MODAL_TYPE.EDIT:
      return {
        ...state,
        modalType: action.modalType,
        editedGoal: action.editedGoal,
        goalCategoryId: action.goalCategoryId,
      }
    case MODAL_TYPE.ADD:
      return {
        ...state,
        modalType: action.modalType,
        editGoal: null,
        goalCategoryId: action.goalCategoryId,
      }
    case MODAL_TYPE.REMOVED_GOAL:
      return {
        ...state,
        modalType: action.modalType,
      }
    case CLOSE_MODAL: {
      return { ...state, modalType: null, editedGoal: null }
    }
    case RESET: {
      return initialState
    }
    default:
      return state
  }
}

const AllGoals = ({
  className,
  goalCategories,
  username,
  myProfile,
  user,
  newGoalCategory,
  editGoalCategory,
  removeGoalCategory,
  newGoal,
  editGoal,
  removeGoal,
  isUpdatingCategory,
  updatingCategoryError,
  isUpdatingGoal,
  updatingGoalError,
  authToken,
  shazamUserGoal,
  unshazamUserGoal,
  isGetMyFutureLoading,
  getMyFutureError,
  shouldScrollToGoal,
}) => {
  const prevCategoryLoading = usePrevious(isUpdatingCategory)
  const prevGoalLoading = usePrevious(isUpdatingGoal)
  const wasGetMyFutureLoading = usePrevious(isGetMyFutureLoading)
  const [state, dispatch] = useReducer(reducer, initialState)

  const location = useLocation()
  const { width } = useWindowSize()

  const onCloseModal = useCallback(() => dispatch({ type: CLOSE_MODAL }), [])

  const scrollToGoal = useCallback(
    () => {
      const element = document.getElementById(location.state.goalId)

      if (!element) {
        dispatch({
          type: MODAL_TYPE.REMOVED_GOAL,
          modalType: MODAL_TYPE.REMOVED_GOAL,
        })
        return
      }
      const { top } = element?.getBoundingClientRect()

      const headerSize =
        width <= MOBILE_THRESHOLD ? MOBILE_HEADER_SIZE : HEADER_SIZE + DESKTOP_OFFSET

      const slicePosition = top + window.scrollY - headerSize

      window.scrollTo({
        top: slicePosition,
        behavior: 'smooth',
      })
    },
    [location, width]
  )

  useEffect(
    () => {
      if (location.state?.goalId && goalCategories.some(category => category.goals?.length)) {
        scrollToGoal()
      }
    },
    [goalCategories, location.state, scrollToGoal, shouldScrollToGoal]
  )

  useEffect(
    () => {
      if (
        location.state?.goalId &&
        wasGetMyFutureLoading &&
        !isGetMyFutureLoading &&
        !getMyFutureError.size
      ) {
        scrollToGoal()
      }
    },
    [
      getMyFutureError.size,
      goalCategories,
      goalCategories.length,
      isGetMyFutureLoading,
      location.state,
      scrollToGoal,
      wasGetMyFutureLoading,
      width,
    ]
  )

  useEffect(
    () => {
      if (updatingCategoryError.size !== 0) {
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content={
              updatingCategoryError?.first?.() ||
              "Something went wrong. It might be related to your browser's version"
            }
          />
        )
        return
      }
      if (prevCategoryLoading && !isUpdatingCategory && updatingCategoryError.size === 0) {
        toast(<Toastr theme={ToastrTheme.SUCCESS} content="Goal categories updated successfully" />)
        onCloseModal()
      }
    },
    [
      isUpdatingCategory,
      onCloseModal,
      prevCategoryLoading,
      updatingCategoryError,
      updatingCategoryError.size,
    ]
  )

  useEffect(
    () => {
      if (updatingGoalError.size !== 0) {
        toast(<Toastr theme={ToastrTheme.ERROR} content={updatingGoalError.first()} />)
        return
      }
      if (prevGoalLoading && !isUpdatingGoal && updatingGoalError.size === 0) {
        toast(<Toastr theme={ToastrTheme.SUCCESS} content="Goal updated successfully" />)
        if (state.modalType !== MODAL_TYPE.COMPLETE) {
          onCloseModal()
        }
      }
    },
    [
      isUpdatingGoal,
      onCloseModal,
      prevGoalLoading,
      state.modalType,
      updatingCategoryError.size,
      updatingGoalError,
    ]
  )

  const onRemoveSubmit = useCallback(
    (categoryId, goalId) => {
      removeGoal(categoryId, goalId)
    },
    [removeGoal]
  )

  const onCompleteSubmit = useCallback(
    (categoryId, goalId) => editGoal(categoryId, goalId, { completed: true }),
    [editGoal]
  )

  const onUncompleteSubmit = useCallback(
    (categoryId, goalId) => editGoal(categoryId, goalId, { completed: false }),
    [editGoal]
  )

  const toggleModal = useCallback(
    (modalType, goalCategoryId, goal = null) => event => {
      event.preventDefault()
      dispatch({
        type: modalType,
        modalType,
        onUncomplete: onUncompleteSubmit,
        onComplete: onCompleteSubmit,
        onRemove: onRemoveSubmit,
        editedGoal: goal,
        goalCategoryId,
      })
    },
    [onCompleteSubmit, onRemoveSubmit, onUncompleteSubmit]
  )

  const onBackClick = useCallback(
    () => {
      navigate([`/user/${username}`])
    },
    [username]
  )

  const addOrEditGoal = useCallback(
    (categoryId, data, edit = false) => {
      if (edit) {
        editGoal(categoryId, data.id, data)
        return
      }
      newGoal(categoryId, data)
    },
    [editGoal, newGoal]
  )
  const onShazamClick = useCallback(
    (goal, categoryId) => {
      const payload = {
        goalId: goal.id,
        goalCategoryId: categoryId,
        username,
      }
      if (!goal.shazamedByYou) {
        shazamUserGoal(payload)
        return
      }

      unshazamUserGoal(payload)
    },
    [shazamUserGoal, unshazamUserGoal, username]
  )

  const categoryIsDefault = category =>
    Object.values(GoalsType).find(type => type === category.title)

  const renderModal = useCallback(
    () => {
      switch (state.modalType) {
        case MODAL_TYPE.CATEGORIES:
          return (
            <ManageGoalCategories
              categories={goalCategories}
              defaultCategories={Object.values(GoalsType)}
              onClose={onCloseModal}
              newGoalCategory={newGoalCategory}
              editGoalCategory={editGoalCategory}
              removeGoalCategory={removeGoalCategory}
              loading={isUpdatingCategory}
            />
          )
        case MODAL_TYPE.ADD:
        case MODAL_TYPE.EDIT:
          return (
            <AddOrEditGoals
              edit={!!state.editedGoal}
              editedGoal={state.editedGoal}
              categoryId={state.goalCategoryId}
              onClose={onCloseModal}
              onSubmit={addOrEditGoal}
              loading={isUpdatingGoal}
            />
          )
        case MODAL_TYPE.REMOVE:
        case MODAL_TYPE.UNCOMPLETE:
          return (
            <UserGoalModal
              categoryId={state.goalCategoryId}
              goalId={state.editedGoal.id}
              onClose={onCloseModal}
              title={state.title}
              content={state.content}
              buttonLabel={state.buttonLabel}
              onSubmit={state.submit}
              loading={isUpdatingGoal}
            />
          )
        case MODAL_TYPE.COMPLETE:
          return (
            <UserGoalModal
              categoryId={state.goalCategoryId}
              goalId={state.editedGoal.id}
              onClose={onCloseModal}
              title={state.title}
              content={state.content}
              buttonLabel={state.buttonLabel}
              loading={isUpdatingGoal}
              closeOnActionClick
              hasConfetti
            />
          )
        case MODAL_TYPE.REMOVED_GOAL:
          return <RemovedGoalModal onClose={onCloseModal} />
        default:
          return null
      }
    },
    [
      addOrEditGoal,
      editGoalCategory,
      goalCategories,
      isUpdatingCategory,
      isUpdatingGoal,
      newGoalCategory,
      onCloseModal,
      removeGoalCategory,
      state.buttonLabel,
      state.content,
      state.editedGoal,
      state.goalCategoryId,
      state.modalType,
      state.submit,
      state.title,
    ]
  )

  const onCompleteGoal = useCallback(
    (id, goal) => () => {
      onCompleteSubmit(id, goal.id)
      dispatch({
        type: MODAL_TYPE.COMPLETE,
        modalType: MODAL_TYPE.COMPLETE,
        onUncomplete: onUncompleteSubmit,
        onComplete: onCompleteSubmit,
        onRemove: onRemoveSubmit,
        editedGoal: goal,
        goalCategoryId: id,
      })
    },
    [onCompleteSubmit, onRemoveSubmit, onUncompleteSubmit]
  )

  const renderGoalSection = useCallback(
    category => (
      <div className={styles.section} key={category.title}>
        <div className={styles['goal-header']}>
          <h2 className={styles['goal-header-title']}>{category.title}</h2>
          {myProfile && (
            <Button
              theme={ButtonTheme.PRIMARY}
              onClick={toggleModal(MODAL_TYPE.ADD, category.id)}
              label="ADD A GOAL"
            />
          )}
        </div>
        <div className={styles.goals}>
          {category.goals.length ? (
            category.goals.map((goal, index) => (
              <Fragment key={goal.id}>
                {myProfile ? (
                  <div
                    className={classnames(styles['my-goals'], {
                      [styles['highlighted-goal']]: goal.id === location?.state?.goalId,
                    })}
                    id={goal.id}
                  >
                    <UserGoalInformation
                      myProfile={myProfile}
                      goal={goal}
                      onEdit={toggleModal(MODAL_TYPE.EDIT, category.id, goal)}
                      onRemove={toggleModal(MODAL_TYPE.REMOVE, category.id, goal)}
                      categoryId={category.id}
                      onShazam={onShazamClick}
                    />
                    <Button
                      className={classnames(styles['complete-goal'], styles['my-profile-buttons'])}
                      theme={goal.completed ? ButtonTheme.PRIMARY : ButtonTheme.DEFAULT}
                      onClick={
                        goal.completed
                          ? toggleModal(MODAL_TYPE.UNCOMPLETE, category.id, goal)
                          : onCompleteGoal(category.id, goal)
                      }
                      label={goal.completed ? 'COMPLETED' : 'MARK AS COMPLETE'}
                      disabled={goal.completed}
                    />
                  </div>
                ) : (
                  <div
                    className={classnames(styles['my-goals'], {
                      [styles['highlighted-goal']]: goal.id === location?.state?.goalId,
                    })}
                    id={goal.id}
                  >
                    <UserGoalInformation
                      onShazam={onShazamClick}
                      categoryId={category.id}
                      goal={goal}
                    />
                  </div>
                )}
                {category.goals.length - 1 > index && <div className={styles.divider} />}
              </Fragment>
            ))
          ) : (
            <div className={styles['empty-goal']}>
              <p className={styles['main-text']}>
                {myProfile
                  ? `You have no goals in this section yet`
                  : `This person has not set goals in this section yet`}
              </p>
              <p>{myProfile ? `Why not add your first goal?` : `Please come back another time`}</p>
            </div>
          )}
        </div>
      </div>
    ),
    [location, myProfile, onCompleteGoal, onShazamClick, toggleModal]
  )

  const onExportGoalToPDFClick = useCallback(
    () => {
      exportGoals(authToken)
        .then(blob => {
          download(blob, 'my-future.pdf')
        })
        .catch(res => {
          console.warn(res.statusText)
        })
    },
    [authToken]
  )

  const backgroundStyles = {
    backgroundImage: `${linearGradientBg}, url(${user.coverPhoto || CoursesCover})`,
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
  }

  return (
    <>
      <section className={classnames(styles.container, className)}>
        <div className={styles.background} style={backgroundStyles} />
        <div className={styles.content}>
          <div className={styles.header}>
            <Button
              label=""
              aria-label="Back icon"
              className={styles['back-icon']}
              iconOnly
              icon={ArrowLeftIcon}
              onClick={onBackClick}
            />
            <h3 className={styles.text}>Back to {myProfile ? 'your' : `${user.name}’s`} future</h3>
          </div>
          <div className={styles['all-goals']}>
            <h1 className={styles.title}>All goals</h1>
            {myProfile && (
              <div className={styles.action}>
                <Button
                  onClick={onExportGoalToPDFClick}
                  theme={ButtonTheme.DEFAULT}
                  label="VIEW GOALS PDF"
                />
                <Button
                  theme={ButtonTheme.DEFAULT}
                  onClick={toggleModal(MODAL_TYPE.CATEGORIES)}
                  label="ADD DECLARATIONS"
                />
              </div>
            )}
          </div>
          {goalCategories.map(category => {
            if (myProfile) return renderGoalSection(category)
            return (
              (category.goals.length !== 0 || categoryIsDefault(category)) &&
              renderGoalSection(category)
            )
          })}
        </div>
        <Footer />
      </section>
      {!!state.modalType && (
        <Modal className={styles.modal} isOpen onClose={onCloseModal}>
          {renderModal()}
        </Modal>
      )}
    </>
  )
}

AllGoals.propTypes = {
  className: PropTypes.string,
  myProfile: PropTypes.bool,
  username: PropTypes.string.isRequired,
  user: userShape.isRequired,
  goalCategories: PropTypes.arrayOf(goalCategoryShape).isRequired,
  newGoalCategory: PropTypes.func,
  editGoalCategory: PropTypes.func,
  removeGoalCategory: PropTypes.func,
  newGoal: PropTypes.func,
  editGoal: PropTypes.func,
  removeGoal: PropTypes.func,
  isUpdatingCategory: PropTypes.bool.isRequired,
  updatingCategoryError: ImmutablePropTypes.map,
  isUpdatingGoal: PropTypes.bool.isRequired,
  updatingGoalError: ImmutablePropTypes.map,
  authToken: PropTypes.string.isRequired,
  shazamUserGoal: PropTypes.func.isRequired,
  unshazamUserGoal: PropTypes.func.isRequired,
  isGetMyFutureLoading: PropTypes.bool.isRequired,
  getMyFutureError: PropTypes.instanceOf(Map).isRequired,
  shouldScrollToGoal: PropTypes.bool,
}

AllGoals.defaultProps = {
  className: '',
  myProfile: false,
  newGoalCategory: () => {},
  editGoalCategory: () => {},
  removeGoalCategory: () => {},
  newGoal: () => {},
  editGoal: () => {},
  removeGoal: () => {},
  updatingCategoryError: Map(),
  updatingGoalError: Map(),
  shouldScrollToGoal: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AllGoals)
