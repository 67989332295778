/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// I had to disable those since eslint can't figure out I have a nested input inside the Input component. But the issues are being taken care of
import React, { useCallback, useEffect, useState } from 'react'
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { navigate, useLocation } from '@reach/router'

import Button, { ButtonSize } from '_components/landing-components/button'
import { addCard } from '_modules/card/actions'
import { isAddingNewCardSelector } from '_modules/card/selectors'
import {
  hasCompletedOnboardingSelector,
  isStartingMembershipSelector,
  startMembershipErrorSelector,
} from '_modules/user/selectors'
import ErrorAlertComponent from '_components/error-alert-component'
import { creditCardNameValidation } from '_utils/helpers'
import { startMembership } from '_modules/user/actions'
import usePrevious from '_hooks/use-previous'

import FormFields from './form-fields'
import styles from './styles.css'

const MembershipPaymentsForm = ({ selectedMembership }) => {
  const isAddingNewCard = useSelector(isAddingNewCardSelector)
  const hasUserCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)

  const [hasError, setHasError] = useState(true)
  const [isAddNewCardLoading, setNewCardLoading] = useState(false)
  const [cardholderName, setCardholderName] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [generalError, setGeneralError] = useState('')

  const elements = useElements()
  const stripe = useStripe()
  const dispatch = useDispatch()

  const isStartingMembershipPurchase = useSelector(isStartingMembershipSelector)
  const wasStartingMembershipPurchase = usePrevious(isStartingMembershipPurchase)
  const membershipPurchaseError = useSelector(startMembershipErrorSelector)

  const onCardholderNameChange = useCallback(
    name => {
      setCardholderName(name)
      if (hasError && !creditCardNameValidation(name)) {
        setHasError(false)
      }
    },
    [hasError]
  )

  const setError = useCallback(value => {
    setHasError(value)
  }, [])

  const onSubmitPaymentMethod = useCallback(
    event => {
      event.preventDefault()

      if (cardholderName.length === 0) {
        setHasError(true)
        return
      }
      const cardElement = elements.getElement(CardNumberElement)
      setNewCardLoading(true)
      stripe
        .createToken(cardElement, { name: cardholderName })
        .then(({ token }) => {
          setNewCardLoading(false)
          if (token?.id) {
            setLoading(true)
            if (!hasUserCompletedOnboarding) {
              dispatch(
                startMembership({
                  card_token: token.id,
                  plan_period: selectedMembership.type,
                })
              )
            } else {
              dispatch(addCard({ cardToken: token.id }))
            }
          } else {
            setNewCardLoading(false)
          }
        })
        .catch(() => {
          setNewCardLoading(false)
          setLoading(false)
        })
    },
    [
      cardholderName,
      dispatch,
      elements,
      hasUserCompletedOnboarding,
      selectedMembership.type,
      stripe,
    ]
  )

  useEffect(
    () => {
      if (
        !membershipPurchaseError.size &&
        wasStartingMembershipPurchase &&
        !isStartingMembershipPurchase
      ) {
        setLoading(false)
        navigate('/memberships')
      } else if (membershipPurchaseError.size) {
        setLoading(false)
        setGeneralError(membershipPurchaseError.first())
      }
    },
    [
      wasStartingMembershipPurchase,
      isStartingMembershipPurchase,
      membershipPurchaseError,
      membershipPurchaseError.size,
    ]
  )

  return (
    <form className={styles['payments-form']} onSubmit={onSubmitPaymentMethod}>
      {!!generalError && (
        <ErrorAlertComponent
          className={styles['error-alert']}
          errorMessage={generalError}
          isError
        />
      )}
      <FormFields onNameChange={onCardholderNameChange} setError={setError} />
      <Button
        type="submit"
        size={ButtonSize.BIG}
        className={styles.button}
        isLoading={isAddingNewCard || isAddNewCardLoading || isLoading}
        disabled={hasError}
      >
        PURCHASE MEMBERSHIP
      </Button>
    </form>
  )
}

MembershipPaymentsForm.propTypes = {
  selectedMembership: PropTypes.shape({
    type: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
}

export default React.memo(MembershipPaymentsForm)
