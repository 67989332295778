import React, { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import cookies from 'react-cookies'

import { deactivateZoom, DEACTIVATE_ZOOM } from '_modules/user/actions'
import Card, { CardSize } from '_components/card'
import Button, { ButtonTheme } from '_components/button'
import { ZOOM_CLIENT_ID } from '_config/environment'
import { usePrevious } from '_utils/hooks'
import Modal from '_components/modal'
import ConfirmationModal, { MODAL_TEXT } from '_components/confirmation-modal'

import styles from './styles.css'

const now = Date.now().toString()

const ZoomSettings = ({ hasZoom, zoomRedirectURI }) => {
  const [isOpen, setModal] = useState(false)
  const isDeactivatingZoom = useSelector(({ loading }) => !!loading.get(DEACTIVATE_ZOOM.ACTION))
  const errorDeactivatingZoom = useSelector(({ error }) => error.get(DEACTIVATE_ZOOM.ACTION))
  const wasDeactivatingZoom = usePrevious(isDeactivatingZoom)
  const dispatch = useDispatch()

  const toggleModal = useCallback(() => setModal(!isOpen), [isOpen])

  useEffect(
    () => {
      if (
        !isDeactivatingZoom &&
        wasDeactivatingZoom &&
        (!errorDeactivatingZoom || (errorDeactivatingZoom && errorDeactivatingZoom.size === 0))
      ) {
        toggleModal()
      }
    },
    [errorDeactivatingZoom, isDeactivatingZoom, toggleModal, wasDeactivatingZoom]
  )

  useEffect(
    () => {
      if (!hasZoom) {
        cookies.save('lightyear-zoom-state', now, { path: '/' })
      }
    },
    [hasZoom]
  )

  const handleDeactivate = useCallback(
    () => {
      dispatch(deactivateZoom())
    },
    [dispatch]
  )

  return (
    <>
      <Card size={CardSize.LARGE} className={styles.container}>
        <h2 className={styles.title}>Zoom settings</h2>
        <p className={styles.description}>
          {hasZoom
            ? 'If you no longer want to use the Zoom application within Lightyear to make video calls, deactivate here.'
            : 'Link your zoom account to Lightyear to be able to create video calls.'}
        </p>
        {hasZoom ? (
          <Button
            label={hasZoom ? 'deactivate' : 'link zoom'}
            theme={ButtonTheme.PRIMARY}
            loading={isDeactivatingZoom}
            onClick={toggleModal}
          />
        ) : (
          <a
            className={styles.link}
            href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${ZOOM_CLIENT_ID}&redirect_uri=${zoomRedirectURI}&state=${now}`}
          >
            LINK ZOOM
          </a>
        )}
      </Card>
      <Modal isOpen={isOpen} onClose={toggleModal}>
        <ConfirmationModal
          text={MODAL_TEXT.DEACTIVATE_ZOOM}
          content="Zoom can be reactivated at any time by retuning to this page and activating or starting a video call from the messages. Please note, we do not recommend deactivation, as it will prevent you from video conferencing on Lightyear"
          onClose={toggleModal}
          onActionClick={handleDeactivate}
          loading={isDeactivatingZoom}
        />
      </Modal>
    </>
  )
}

ZoomSettings.propTypes = {
  hasZoom: PropTypes.bool.isRequired,
  zoomRedirectURI: PropTypes.string.isRequired,
}

export default React.memo(ZoomSettings)
