import { combineReducers } from 'redux'
import cookies from 'react-cookies'

import { LOGOUT_USER } from '_modules/user/actions'

import user from './user/reducer'
import profile from './profile/reducer'
import courses from './course/reducer'
import classes from './class/reducer'
import company from './company/reducer'
import cards from './card/reducer'
import search from './search/reducer'
import chat from './chat/reducer'
import dashboard from './dashboard/reducer'
import posts from './post/reducer'
import assignments from './assignment/reducer'
import error from './error/reducer'
import loading from './loading/reducer'
import notifications from './notification/reducer'
import blockedUsers from './blocked/reducer'
import alert from './alert/reducer'
import suggestion from './suggestion/reducer'
import server from './server/reducer'
import prismic from './prismic/reducer'
import publicCourse from './public-courses/reducer'
import publicClasses from './public-classes/reducer'
import publicCoaches from './public-coaches/reducer'
import general from './general/reducer'
import publicCategories from './public-categories/reducer'

const appReducer = combineReducers({
  user,
  profile,
  courses,
  classes,
  company,
  cards,
  search,
  chat,
  dashboard,
  posts,
  assignments,
  error,
  loading,
  notifications,
  blockedUsers,
  alert,
  suggestion,
  server,
  prismic,
  publicCourse,
  publicCoaches,
  publicClasses,
  general,
  publicCategories,
})

const rootReducer = (state, action) => {
  if (action.type === LOGOUT_USER) {
    // eslint-disable-next-line no-param-reassign
    state = {
      prismic: state.prismic,
      publicCourse: state.publicCourse,
      publicClasses: state.publicClasses,
      publicCoaches: state.publicCoaches,
      publicCategories: state.publicCategories,
      server: state.server,
    }
    cookies.remove('user', { path: '/' })
    cookies.remove('authToken', { path: '/' })
    cookies.remove('subscriptionExpiredDismissed', { path: '/' })
    cookies.remove('subscriptionExpiringDays', { path: '/' })
  }
  return appReducer(state, action)
}

export default rootReducer
