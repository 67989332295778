import { createSelector } from 'reselect'

import { GET_PRISMIC_INFORMATION } from './actions'

export const baseAppSelector = state => state.prismic.get('baseApp')

export const landingPageSelector = (state, view) => state.prismic.get(view)

export const prismicLoadingSelector = state => !!state.loading.get(GET_PRISMIC_INFORMATION.ACTION)

export const prismicErrorSelector = state => !!state.error.get(GET_PRISMIC_INFORMATION.ACTION)

export const getSlugSelector = createSelector(landingPageSelector, landing =>
  landing?.get('courseSlug')
)

export const getPathSelector = state => state.prismic.get('path')

export const getSlicesPosition = state => state.prismic.get('slicesPosition')

export const hasHighlightMessageSelector = createSelector(baseAppSelector, baseApp => {
  const baseAppHighlights = baseApp?.get('highlights')
  return baseAppHighlights ? Boolean(baseAppHighlights.size) : false
})

export const highlightLinkSelector = state =>
  state.prismic.getIn(['baseApp', 'highlights', 'highlightLink'])
