import React from 'react'

import StarIcon from '_assets/icons/star-icon.svg'
import TelescopeIcon from '_assets/icons/started-guide-telescope-icon.svg'
import { COURSE_TYPES_INFO } from '_constants/course'

export const pages = [
  {
    title: (
      <>
        Lightyear <br /> starter guide!
      </>
    ),
    description:
      'Understand our programs and our types of classes to have an insight into which one is a good starting point to our Lightyear journey',
    icon: TelescopeIcon,
  },
  {
    title: 'Power My Future',
    subtitle: '30 days to launch a life you love',
    tags: [
      {
        name: 'Free',
      },
      {
        name: 'Intro to Lightyear',
        theme: 'secondary',
      },
    ],
    iconTitleDetails: StarIcon,
    titleDetails: 'Lightyears Pick',
    description: (
      <>
        This free entry level course gives you a taste of Lightyear and the Lightyear Membership so
        you can start imagining your future and moving toward it today.
        <br />
        <br />
        In this no obligation trial, you will experience the transformative nature of some of
        Lightyear’s foundational principles and discover the robust and wide community of support on
        Lightyear.co
      </>
    ),
    icon: COURSE_TYPES_INFO.POWER_MY_FUTURE.icon,
    url: COURSE_TYPES_INFO.POWER_MY_FUTURE.path,
  },
  {
    title: 'Vision + Goals',
    subtitle: 'Design a future you love',
    tags: [
      {
        name: 'Level 1',
      },
    ],
    description: (
      <>
        You have control over your future, and you get to choose, design and create it today. Vision
        + Goals, the foundational course of Lightyear, will teach you self development tools to
        empower you to take control of your life’s trajectory.
        <br />
        <br />
        By envisioning the future you’ve always wanted, and learning the Lightyear strategies to
        keep you on track that future can be yours.
      </>
    ),
    icon: COURSE_TYPES_INFO.VISION_PLUS_GOALS.icon,
    url: COURSE_TYPES_INFO.VISION_PLUS_GOALS.path,
  },
  {
    title: 'Integrated Leadership',
    subtitle: 'Turn your big ideas into a reality',
    tags: [
      {
        name: 'Level 2',
      },
    ],
    description: (
      <>
        Integrated Leadership gives you practical day-to-day tools for effective communication, so
        you can get where you’re going faster.
        <br />
        <br />
        At its core, Integrated Leadership will teach you how to communicate better with others, no
        matter who they are or where they come from. Integrated Leadership helps you live more
        intentionally, presently and live the life you’ve always envisioned.
      </>
    ),
    icon: COURSE_TYPES_INFO.INTEGRATED_LEADERSHIP.icon,
    url: COURSE_TYPES_INFO.INTEGRATED_LEADERSHIP.path,
  },
  {
    title: 'Whole Prosperity',
    subtitle: 'Upgrade your self-worth',
    tags: [
      {
        name: 'Level 3',
      },
    ],
    description: (
      <>
        Whole prosperity requires us to be in conscious choice of who we are and how we create and
        expand relationships and wealth of all types in the world.
        <br />
        <br />
        Lightyear&lsquo;s Level 3: Whole Prosperity course will empower you with a new and deeper
        understanding of wealth and value exchange as it relates to your self-worth, your decisions,
        your money choices, your vision and goals, your relationships, and your impact on the
        planet.
      </>
    ),
    icon: COURSE_TYPES_INFO.WHOLE_PROSPERITY.icon,
    url: COURSE_TYPES_INFO.WHOLE_PROSPERITY.path,
  },
  {
    title: 'Start your Lightyear journey',
  },
]
