export const WEBSOCKET_USER_ONLINE = 'WEBSOCKET_USER_ONLINE'
export const WEBSOCKET_USER_OFFLINE = 'WEBSOCKET_USER_OFFLINE'
export const WEBSOCKET_DIRECT_MESSAGE_CREATED = 'WEBSOCKET_DIRECT_MESSAGE_CREATED'
export const WEBSOCKET_CHANNEL_MESSAGE_CREATED = 'WEBSOCKET_CHANNEL_MESSAGE_CREATED'
export const WEBSOCKET_CHANNEL_DISABLED = 'WEBSOCKET_CHANNEL_DISABLED'
export const WEBSOCKET_CHANNEL_ENABLED = 'WEBSOCKET_CHANNEL_ENABLED'
export const WEBSOCKET_CHANNEL_MESSAGE_DELETED = 'WEBSOCKET_CHANNEL_MESSAGE_DELETED'
export const WEBSOCKET_NEW_NOTIFICATION = 'WEBSOCKET_NEW_NOTIFICATION'
export const WEBSOCKET_NEW_ALERT = 'WEBSOCKET_NEW_ALERT'
export const WEBSOCKET_EXPIRING_ALERT = 'WEBSOCKET_EXPIRING_ALERT'
export const WEBSOCKET_SUSPENDED = 'WEBSOCKET_SUSPENDED'
export const WEBSOCKET_UNSUSPENDED = 'WEBSOCKET_UNSUSPENDED'
export const WEBSOCKET_USER_REMOVED_FROM_CHANNEL = 'WEBSOCKET_USER_REMOVED_FROM_CHANNEL'
export const WEBSOCKET_MAKE_ADMIN = 'WEBSOCKET_MAKE_ADMIN'
export const WEBSOCKET_REVOKE_ADMIN = 'WEBSOCKET_REVOKE_ADMIN'
export const WEBSOCKET_CHANNEL_MEETING_UPDATED = 'WEBSOCKET_CHANNEL_MEETING_UPDATED'
export const WEBSOCKET_MESSAGE_MEETING_UPDATED = 'WEBSOCKET_MESSAGE_MEETING_UPDATED'
export const WEBSOCKET_ADDED_TO_CHANNEL = 'WEBSOCKET_ADDED_TO_CHANNEL'
export const WEBSOCKET_BLOCKED_USER = 'WEBSOCKET_BLOCKED_USER'
export const WEBSOCKET_DELETE_NOTIFICATION = 'WEBSOCKET_DELETE_NOTIFICATION'
export const WEBSOCKET_VIDEO_ASSIGNMENT_STARTING = 'WEBSOCKET_VIDEO_ASSIGNMENT_STARTING'
export const WEBSOCKET_VIDEO_ASSIGNMENT_STARTED = 'WEBSOCKET_VIDEO_ASSIGNMENT_STARTED'
export const WEBSOCKET_VIDEO_ASSIGNMENT_ENDED = 'WEBSOCKET_VIDEO_ASSIGNMENT_ENDED'
export const WEBSOCKET_NEW_POST = 'WEBSOCKET_NEW_POST'
export const WEBSOCKET_NEW_COMMENT = 'WEBSOCKET_NEW_COMMENT'
export const WEBSOCKET_POST_UPDATED = 'WEBSOCKET_POST_UPDATED'
export const WEBSOCKET_COMMENT_UPDATED = 'WEBSOCKET_COMMENT_UPDATED'
export const WEBSOCKET_POST_SHAZAMED = 'WEBSOCKET_POST_SHAZAMED'
export const WEBSOCKET_POST_UNSHAZAMED = 'WEBSOCKET_POST_UNSHAZAMED'
export const WEBSOCKET_COMMENT_SHAZAMED = 'WEBSOCKET_COMMENT_SHAZAMED'
export const WEBSOCKET_COMMENT_UNSHAZAMED = 'WEBSOCKET_COMMENT_UNSHAZAMED'
export const WEBSOCKET_NEW_ASSIGNMENT_POST = 'WEBSOCKET_NEW_ASSIGNMENT_POST'
export const WEBSOCKET_NEW_ASSIGNMENT_COMMENT = 'WEBSOCKET_NEW_ASSIGNMENT_COMMENT'
export const WEBSOCKET_ASSIGNMENT_POST_UPDATED = 'WEBSOCKET_ASSIGNMENT_POST_UPDATED'
export const WEBSOCKET_ASSIGNMENT_COMMENT_UPDATED = 'WEBSOCKET_ASSIGNMENT_COMMENT_UPDATED'
export const WEBSOCKET_ASSIGNMENT_POST_SHAZAMED = 'WEBSOCKET_ASSIGNMENT_POST_SHAZAMED'
export const WEBSOCKET_ASSIGNMENT_POST_UNSHAZAMED = 'WEBSOCKET_ASSIGNMENT_POST_UNSHAZAMED'
export const WEBSOCKET_ASSIGNMENT_COMMENT_SHAZAMED = 'WEBSOCKET_ASSIGNMENT_COMMENT_SHAZAMED'
export const WEBSOCKET_ASSIGNMENT_COMMENT_UNSHAZAMED = 'WEBSOCKET_ASSIGNMENT_COMMENT_UNSHAZAMED'
export const WEBSOCKET_FINISH_LIVE_CALL = 'WEBSOCKET_FINISH_LIVE_CALL'

export const userConnected = payload => ({
  type: WEBSOCKET_USER_ONLINE,
  payload,
})

export const userDisconnected = payload => ({
  type: WEBSOCKET_USER_OFFLINE,
  payload,
})

export const directMessageCreated = payload => ({
  type: WEBSOCKET_DIRECT_MESSAGE_CREATED,
  payload,
})

export const channelMessageCreated = payload => ({
  type: WEBSOCKET_CHANNEL_MESSAGE_CREATED,
  payload,
})

export const channelDisabled = payload => ({
  type: WEBSOCKET_CHANNEL_DISABLED,
  payload,
})

export const channelEnabled = payload => ({
  type: WEBSOCKET_CHANNEL_ENABLED,
  payload,
})

export const channelMessageDeleted = payload => ({
  type: WEBSOCKET_CHANNEL_MESSAGE_DELETED,
  payload,
})

export const newNotification = payload => ({
  type: WEBSOCKET_NEW_NOTIFICATION,
  payload,
})

export const newAlert = payload => ({
  type: WEBSOCKET_NEW_ALERT,
  payload,
})

export const expiringAlert = payload => ({
  type: WEBSOCKET_EXPIRING_ALERT,
  payload,
})

export const userSuspended = payload => ({
  type: WEBSOCKET_SUSPENDED,
  payload,
})

export const userUnsuspended = payload => ({
  type: WEBSOCKET_UNSUSPENDED,
  payload,
})

export const userRemovedFromChannel = payload => ({
  type: WEBSOCKET_USER_REMOVED_FROM_CHANNEL,
  payload,
})

export const makeAdmin = payload => ({
  type: WEBSOCKET_MAKE_ADMIN,
  payload,
})

export const revokeAdmin = payload => ({
  type: WEBSOCKET_REVOKE_ADMIN,
  payload,
})

export const updateChannelMeeting = payload => ({
  type: WEBSOCKET_CHANNEL_MEETING_UPDATED,
  payload,
})

export const updateMessageMeeting = payload => ({
  type: WEBSOCKET_MESSAGE_MEETING_UPDATED,
  payload,
})

export const addUserToChannel = payload => ({
  type: WEBSOCKET_ADDED_TO_CHANNEL,
  payload,
})

export const blockUser = payload => ({
  type: WEBSOCKET_BLOCKED_USER,
  payload,
})

export const deleteNotification = payload => ({
  type: WEBSOCKET_DELETE_NOTIFICATION,
  payload,
})

export const videoAssignmentStarting = payload => ({
  type: WEBSOCKET_VIDEO_ASSIGNMENT_STARTING,
  payload,
})

export const videoAssignmentStarted = payload => ({
  type: WEBSOCKET_VIDEO_ASSIGNMENT_STARTED,
  payload,
})

export const videoAssignmentEnded = payload => ({
  type: WEBSOCKET_VIDEO_ASSIGNMENT_ENDED,
  payload,
})

export const newPost = payload => ({
  type: WEBSOCKET_NEW_POST,
  payload,
})

export const newComment = payload => ({
  type: WEBSOCKET_NEW_COMMENT,
  payload,
})

export const postUpdate = payload => ({
  type: WEBSOCKET_POST_UPDATED,
  payload,
})

export const commentUpdate = payload => ({
  type: WEBSOCKET_COMMENT_UPDATED,
  payload,
})

export const shazamPost = payload => ({
  type: WEBSOCKET_POST_SHAZAMED,
  payload,
})

export const unshazamPost = payload => ({
  type: WEBSOCKET_POST_UNSHAZAMED,
  payload,
})

export const shazamComment = payload => ({
  type: WEBSOCKET_COMMENT_SHAZAMED,
  payload,
})

export const unshazamComment = payload => ({
  type: WEBSOCKET_COMMENT_UNSHAZAMED,
  payload,
})

export const newAssignmentPost = payload => ({
  type: WEBSOCKET_NEW_ASSIGNMENT_POST,
  payload,
})

export const newAssignmentComment = payload => ({
  type: WEBSOCKET_NEW_ASSIGNMENT_COMMENT,
  payload,
})

export const assignmentPostUpdate = payload => ({
  type: WEBSOCKET_ASSIGNMENT_POST_UPDATED,
  payload,
})

export const assignmentCommentUpdate = payload => ({
  type: WEBSOCKET_ASSIGNMENT_COMMENT_UPDATED,
  payload,
})

export const assignmentPostShazam = payload => ({
  type: WEBSOCKET_ASSIGNMENT_POST_SHAZAMED,
  payload,
})

export const assignmentPostUnshazam = payload => ({
  type: WEBSOCKET_ASSIGNMENT_POST_UNSHAZAMED,
  payload,
})

export const assignmentCommentShazam = payload => ({
  type: WEBSOCKET_ASSIGNMENT_COMMENT_SHAZAMED,
  payload,
})

export const assignmentCommentUnshazam = payload => ({
  type: WEBSOCKET_ASSIGNMENT_COMMENT_UNSHAZAMED,
  payload,
})

export const finishLiveCall = payload => ({
  type: WEBSOCKET_FINISH_LIVE_CALL,
  payload,
})
