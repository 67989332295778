import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

import Input from '_components/input'
import Button, { ButtonTheme } from '_components/button'
import CloseIcon from '_assets/icons/close.svg'
import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

const AddVideoModal = ({ onCloseModal, onAddClick }) => {
  const [video, setVideo] = useState('')
  const [error, setError] = useState('')

  const onChange = useCallback(event => {
    const { value } = event.target
    setVideo(value)
  }, [])

  const handleAddClick = useCallback(
    () => {
      if (!ReactPlayer.canPlay(video)) {
        setError('Invalid video url')
      } else {
        onAddClick(video)
        onCloseModal()
      }
    },
    [onAddClick, onCloseModal, video]
  )

  return (
    <div className={styles.card}>
      <p className={styles.title}>ADD A VIDEO</p>
      <button
        onClick={onCloseModal}
        onMouseDown={onMouseDown}
        aria-label="close button"
        className={styles.close}
      >
        <svg viewBox={CloseIcon.viewBox} aria-hidden="true">
          <use xlinkHref={`#${CloseIcon.id}`} />
        </svg>
      </button>
      <Input
        className={styles.input}
        placeholder="Paste video link here"
        value={video}
        onChange={onChange}
        errorMessage={error}
      />
      <Button theme={ButtonTheme.PRIMARY} label="add" onClick={handleAddClick} />
    </div>
  )
}

AddVideoModal.propTypes = {
  onCloseModal: PropTypes.func,
  onAddClick: PropTypes.func,
}

AddVideoModal.defaultProps = {
  onCloseModal: () => {},
  onAddClick: () => {},
}

export default AddVideoModal
