import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import noScroll from 'no-scroll'
import { useDispatch } from 'react-redux'

import { readNotifications } from '_modules/notification/actions'
import { MOBILE_WIDTH } from '_utils/constants'
import { directMessageShape, iconPropTypes, notificationsShape, userShape } from '_utils/proptypes'
import { User } from '_models/'
import { isKeyboardOrClick, onMouseDown } from '_utils/aria'
import LockedIcon from '_assets/icons/menu-locked.svg'
import DisabledTooltipButton, { TOOLTIP_POSITION } from '_components/disabled-tooltip-button'
import NotificationsInfoIcon from '_assets/icons/info-notification.svg'
import useModal from '_hooks/use-modal'

import NotificationCard from '../notification-card'
import MessagesCard from '../messages-card'

import styles from './styles.css'

const NOTIFICATION_INFO = {
  title: 'Receive notifications',
  description: 'Activate your membership to have access to notifications.',
  icon: NotificationsInfoIcon,
}

const HeaderDropdown = ({
  className,
  icon,
  unread,
  isMessage,
  messages,
  notifications,
  user,
  loadMore,
  hasMore,
  disabled,
}) => {
  const [isOpen, onToggle] = useModal()
  const dispatch = useDispatch()
  const [dropdownRef, setDropdownRef] = useState()

  const setRef = useCallback(ref => setDropdownRef(ref), [])

  const handleOutsideClick = useCallback(
    event => {
      if (dropdownRef.contains(event.target) && !event.target.href) {
        return
      }

      onToggle()
      document.removeEventListener('click', handleOutsideClick, false)
    },
    [dropdownRef, onToggle]
  )

  const handleToggle = useCallback(
    event => {
      const { key } = event

      if (isKeyboardOrClick(key)) {
        onToggle()
        if (!isOpen) {
          document.addEventListener('click', handleOutsideClick, false)
          if (window.innerWidth <= MOBILE_WIDTH) {
            noScroll.on()
          }
        } else {
          document.removeEventListener('click', handleOutsideClick, false)
          noScroll.off()
        }

        if (unread > 0 && notifications.length) {
          dispatch(readNotifications())
        }
      }
    },
    [dispatch, handleOutsideClick, isOpen, notifications.length, onToggle, unread]
  )

  useEffect(
    () => () => {
        document.removeEventListener('click', handleToggle, false)
      },
    [handleToggle]
  )

  return (
    <div
      className={classnames(styles.container, className, { [styles.open]: isOpen })}
      ref={setRef}
    >
      {disabled ? (
        <DisabledTooltipButton
          tooltipPosition={TOOLTIP_POSITION.BOTTOM}
          cardInfo={NOTIFICATION_INFO}
          className={styles['disabled-dropdown']}
        >
          <svg className={styles.icon} viewBox={icon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${icon.id}`} />
          </svg>
          <svg className={styles['locked-icon']} viewBox={LockedIcon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${LockedIcon.id}`} />
          </svg>
        </DisabledTooltipButton>
      ) : (
        <div
          role="button"
          tabIndex="0"
          onClick={handleToggle}
          onKeyPress={handleToggle}
          onMouseDown={onMouseDown}
          className={styles['dropdown-wrapper']}
        >
          <svg className={styles.icon} viewBox={icon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${icon.id}`} />
          </svg>
          {!!unread && <div className={styles['unread-badge']}>{unread}</div>}
        </div>
      )}
      <div className={styles.dropdown}>
        {isMessage ? (
          <MessagesCard messages={messages} onCloseClick={handleToggle} />
        ) : (
          <NotificationCard
            user={user}
            onCloseClick={handleToggle}
            notifications={notifications}
            loadMore={loadMore}
            hasMore={hasMore}
          />
        )}
      </div>
    </div>
  )
}

HeaderDropdown.propTypes = {
  className: PropTypes.string,
  icon: iconPropTypes.isRequired,
  unread: PropTypes.number,
  isMessage: PropTypes.bool,
  messages: PropTypes.arrayOf(directMessageShape),
  notifications: PropTypes.arrayOf(notificationsShape),
  user: userShape,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  disabled: PropTypes.bool,
}

HeaderDropdown.defaultProps = {
  className: '',
  unread: 0,
  isMessage: false,
  messages: [],
  notifications: [],
  user: new User().toJS(),
  loadMore: () => {},
  hasMore: false,
  disabled: false,
}

export default HeaderDropdown
