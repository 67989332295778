import { Map } from 'immutable'

import { createReducer } from '_utils/redux'

import { SET_GOOGLE_LOGIN_ERROR } from './actions'

const INITIAL_STATE = new Map({
  googleLoginError: undefined,
})

const notifications = createReducer(INITIAL_STATE, {
  [SET_GOOGLE_LOGIN_ERROR]: (state, { payload }) => state.set('googleLoginError', payload),
})

export default notifications
