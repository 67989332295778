import React, { useMemo, forwardRef } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWindowSize } from '@reach/window-size'

import CloseIcon from '_assets/icons/close.svg'
import Modal from '_components/modal'
import useBodyOverflow from '_hooks/use-body-overflow'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

export const MOBILE_THEME = {
  FULL_HEIGHT: 'full-height',
  FIXED_HEIGHT: 'fixed-height',
}

const CardModal = forwardRef(
  (
    { isOpen, onClose, children, className, childrenClassName, footer, hasCloseButton, theme },
    ref
  ) => {
    useBodyOverflow(isOpen)
    const { width, height } = useWindowSize()

    const modalStyle = useMemo(
      () => {
        if (width <= MOBILE_THRESHOLD) {
          if (theme === MOBILE_THEME.FIXED_HEIGHT) {
            return {
              maxHeight: `${height * 0.93 - 8}px`,
              width: `${width - 16}px`,
            }
          }
          return {
            height,
            width,
          }
        }

        return {
          maxHeight: `${height * 0.9}px`,
        }
      },
      [height, theme, width]
    )

    return (
      <Modal
        isOpen
        onClose={onClose}
        className={classnames(styles['modal-container'], styles[`wrapper-${theme}`])}
      >
        <section
          style={modalStyle}
          className={classnames(styles['modal-wrapper'], styles[theme], className, {
            [styles['has-footer']]: !!footer,
          })}
          ref={ref}
        >
          {hasCloseButton && (
            <button
              type="button"
              onClick={onClose}
              className={styles['close-button']}
              aria-label="Close Modal"
              onMouseDown={onMouseDown}
            >
              <svg className={styles['close-icon']} viewBox={CloseIcon.viewBox} aria-hidden="true">
                <use xlinkHref={`#${CloseIcon.id}`} />
              </svg>
            </button>
          )}
          <div className={classnames(styles.content, childrenClassName)}>{children}</div>
          {footer && <div className={styles['footer-wrapper']}>{footer}</div>}
        </section>
      </Modal>
    )
  }
)

CardModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  childrenClassName: PropTypes.string,
  footer: PropTypes.node,
  hasCloseButton: PropTypes.bool,
  theme: PropTypes.oneOf(Object.values(MOBILE_THEME)),
}

CardModal.defaultProps = {
  className: '',
  childrenClassName: '',
  footer: undefined,
  hasCloseButton: true,
  theme: MOBILE_THEME.FULL_HEIGHT,
}

export default React.memo(CardModal)
