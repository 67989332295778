import React, { useCallback, useMemo } from 'react'
import { useWindowSize } from '@reach/window-size'
import ReactModal from 'react-modal'
import PropTypes from 'prop-types'

import useBodyOverflow from '_hooks/use-body-overflow'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import CropPicture from '_components/crop-picture'

import styles from './styles.css'

const EditImageModal = ({ closeModal, originalImage, onSavePicture, isLoadingPicture }) => {
  useBodyOverflow(true)
  const { height, width } = useWindowSize()

  const modalStyles = useMemo(
    () => {
      if (width <= MOBILE_THRESHOLD) {
        return {
          maxHeight: height * 0.9,
        }
      }
      return {}
    },
    [height, width]
  )

  const savePicture = useCallback(
    blob => {
      onSavePicture(blob.file)
    },
    [onSavePicture]
  )

  return (
    <ReactModal
      isOpen
      className={styles.modal}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick
      ariaHideApp={false}
    >
      <section style={modalStyles} className={styles['modal-content']}>
        <CropPicture
          handleCancelClick={closeModal}
          originalImage={originalImage}
          onSavePicture={savePicture}
          isLoadingPicture={isLoadingPicture}
        />
      </section>
    </ReactModal>
  )
}

EditImageModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
  originalImage: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  onSavePicture: PropTypes.func.isRequired,
  isLoadingPicture: PropTypes.bool,
}

EditImageModal.defaultProps = {
  isLoadingPicture: false,
}

export default React.memo(EditImageModal)
