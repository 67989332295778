import React from 'react'
import { ToastContainer, toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'
import styles from './styles.css'

const ToastrContainer = () => (
    <ToastContainer
      autoClose={5000}
      draggable
      newestOnTop
      hideProgressBar
      position={toast.POSITION.TOP_CENTER}
      closeButton={false}
      closeOnClick={false}
      className={styles.default}
    />
  )

export default React.memo(ToastrContainer)
