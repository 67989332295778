import { useCallback, useState, useEffect } from 'react'

export default () => {
  const hasWindow = typeof window !== 'undefined'

  const getScroll = useCallback(() => (hasWindow ? window.scrollY : 0), [hasWindow])

  const [scroll, setScroll] = useState(getScroll)

  useEffect(
    () => {
      if (!hasWindow) {
        return false
      }

      const handleScroll = () => {
        setScroll(getScroll())
      }

      window.addEventListener('scroll', handleScroll)

      return () => window.removeEventListener('scroll', handleScroll)
    },
    [getScroll, hasWindow]
  )

  return scroll
}
