import React from 'react'

import LeaderProfileModal from '_components/landing-components/leader-profile-modal'
import useModal from '_hooks/use-modal'
import { leaderPropTypes } from '_utils/proptypes'

import styles from './styles.css'

const LeaderCard = ({ leader }) => {
  const [isModalOpen, onToggleModal] = useModal()

  return (
    <>
      <button className={styles['leader-button-container']} onClick={onToggleModal}>
        <img
          className={styles['leader-avatar']}
          src={leader.picture.url}
          alt={`${leader.name}'s avatar`}
        />
        <span className={styles['leader-name']}>{leader.name}</span>
        {leader.position}
      </button>
      <LeaderProfileModal isOpen={isModalOpen} onClose={onToggleModal} member={leader} />
    </>
  )
}

LeaderCard.propTypes = {
  leader: leaderPropTypes.isRequired,
}

export default LeaderCard
