import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ErrorIcon from '_assets/icons/error.svg'
import SuccessIcon from '_assets/icons/success.svg'
import CloseIcon from '_assets/icons/close.svg'

import styles from './styles.css'

export const ToastrTheme = {
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
}

const isSuccess = theme => theme === ToastrTheme.SUCCESS

const Toastr = ({ theme, content, closeToast }) => (
  <div className={classnames(styles.container, styles[theme])}>
    <svg
      role="img"
      aria-label={`${isSuccess(theme) ? 'Success' : 'Error'} icon`}
      viewBox={isSuccess(theme) ? SuccessIcon.viewBox : ErrorIcon.viewBox}
      className={styles.icon}
    >
      <use xlinkHref={`#${isSuccess(theme) ? SuccessIcon.id : ErrorIcon.id}`} />
    </svg>
    <p className={styles.content}>{content}</p>
    <svg
      role="button"
      aria-label="Close notification"
      viewBox={CloseIcon.viewBox}
      className={styles.close}
      onClick={closeToast}
    >
      <use xlinkHref={`#${CloseIcon.id}`} />
    </svg>
  </div>
)

Toastr.propTypes = {
  theme: PropTypes.oneOf(Object.keys(ToastrTheme).map(theme => ToastrTheme[theme])),
  content: PropTypes.string.isRequired,
  closeToast: PropTypes.func.isRequired,
}

Toastr.defaultProps = {
  theme: ToastrTheme.SUCCESS,
}

export default React.memo(Toastr)
