import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import moment from 'moment'
import { FacebookShareButton } from 'react-share'
import { useDispatch } from 'react-redux'

import Like from '_components/like'
import { goalShape } from '_utils/proptypes'
import PROFILE_ACTIONS from '_modules/profile/actions'

import styles from './styles.css'

const { updateGoal } = PROFILE_ACTIONS

const formatDate = date => `by ${moment(date).format('MMMM Do YYYY')}`

const UserGoalInformation = ({
  goal,
  myProfile,
  onEdit,
  onRemove,
  className,
  onShazam,
  isShazamLoading,
  categoryId,
}) => {
  const dispatch = useDispatch()

  const onShazamClick = useCallback(() => onShazam(goal, categoryId), [categoryId, goal, onShazam])

  const onReactivate = useCallback(
    () => {
      dispatch(updateGoal(categoryId, goal.id, { completed: false }))
    },
    [categoryId, dispatch, goal.id]
  )
  return (
    <div className={classnames(styles.container, className)}>
      <p className={styles['goal-content']}>{goal.description}</p>
      <div className={styles['goal-footer']}>
        <div className={styles.details}>
          <Like
            hideShazamButton={myProfile}
            count={goal.shazams}
            shazamedByYou={goal.shazamedByYou}
            onClick={onShazamClick}
            isShazamLoading={isShazamLoading}
          />{' '}
          <div className={styles.dot} />
          <p className={styles.date}>{formatDate(goal.dueDate)}</p>
        </div>
        {myProfile && (
          <div className={styles['my-actions']}>
            <div className={classnames(styles['light-dot'], styles['hide-sm'])} />
            <button type="button" className={styles['my-actions-button']} onClick={onEdit}>
              Edit
            </button>
            <div className={styles['light-dot']} />
            {goal.completed && (
              <>
                <button
                  type="button"
                  className={styles['my-actions-button']}
                  onClick={onReactivate}
                >
                  Reactivate
                </button>
                <div className={styles['light-dot']} />
              </>
            )}
            <button type="button" className={styles['my-actions-button']} onClick={onRemove}>
              Remove
            </button>
            <div className={styles['light-dot']} />
            <FacebookShareButton
              url="https://lightyear.co"
              quote={goal.description}
              className={styles['facebook-share-button']}
            >
              <p className={styles['my-actions-button']}>Share on Facebook</p>
            </FacebookShareButton>
          </div>
        )}
      </div>
    </div>
  )
}

UserGoalInformation.propTypes = {
  className: PropTypes.string,
  goal: goalShape.isRequired,
  myProfile: PropTypes.bool,
  onRemove: PropTypes.func,
  onEdit: PropTypes.func,
  onShazam: PropTypes.func,
  isShazamLoading: PropTypes.bool,
  categoryId: PropTypes.number,
}

UserGoalInformation.defaultProps = {
  className: '',
  onRemove: () => {},
  onEdit: () => {},
  myProfile: false,
  onShazam: () => {},
  isShazamLoading: false,
  categoryId: undefined,
}

export default UserGoalInformation
