import React from 'react'

import icon from '_assets/images/image-404.svg'
import Footer from '_components/footer'

import styles from './styles.css'

const NotFoundPage = () => (
    <div className={styles.container}>
      <div className={styles.upper}>
        <svg className={styles.icon} viewBox={icon.viewBox}>
          <use xlinkHref={`#${icon.id}`} />
        </svg>
        <h1>Page not found</h1>
        <p>The page you’re looking for can’t be found.</p>
      </div>
      <Footer className={styles.footer} svgClassName={styles.logo} linkClassName={styles.link} />
    </div>
  )

export default NotFoundPage
