import React, { useEffect, useCallback } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { toArray } from '_utils/helpers'
import { getBlockedUsers, unblockUser } from '_modules/blocked/actions'
import { userShape } from '_utils/proptypes'

import styles from './styles.css'
import BlockedProfile from './blocked-profile'

const BlockedMembers = ({ getBlockedUsersInfo, blockedUsersInfo, unblock }) => {
  useEffect(
    () => {
      getBlockedUsersInfo()
    },
    [getBlockedUsersInfo]
  )

  const onUnblockClick = useCallback(
    username => {
      unblock(username)
    },
    [unblock]
  )

  return (
    <div className={styles.container}>
      <h2>BLOCKED MEMBERS</h2>
      {blockedUsersInfo.length === 0 ? (
        <p>You haven’t blocked any member yet.</p>
      ) : (
        <div className={styles['list-users']}>
          {blockedUsersInfo.map(blockedUser => (
            <BlockedProfile
              key={blockedUser.id}
              profilePicture={blockedUser.photo}
              name={blockedUser.name}
              company={blockedUser.company}
              role={blockedUser.role}
              className={styles.profile}
              onClick={onUnblockClick}
              username={blockedUser.username}
            />
          ))}
        </div>
      )}
    </div>
  )
}

BlockedMembers.propTypes = {
  blockedUsersInfo: PropTypes.arrayOf(userShape).isRequired,
  getBlockedUsersInfo: PropTypes.func.isRequired,
  unblock: PropTypes.func.isRequired,
}

const mapStateToProps = ({ blockedUsers }) => ({
  blockedUsersInfo: toArray(blockedUsers),
})

const mapDispatchToProps = {
  getBlockedUsersInfo: getBlockedUsers,
  unblock: unblockUser,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BlockedMembers)
