import React from 'react'
import PropTypes from 'prop-types'

const CourseImage = ({ className, picture, name }) => <img src={picture} alt={`Course ${name}`} className={className} />
CourseImage.propTypes = {
  className: PropTypes.string,
  picture: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
}

CourseImage.defaultProps = {
  className: '',
}

export default React.memo(CourseImage)
