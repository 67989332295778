import * as courseService from '_services/public-courses'
import { defineAction } from '_utils/redux'

export const GET_PUBLIC_COURSE = defineAction('GET_PUBLIC_COURSE')

export const getPublicCourse = courseSlug => (dispatch, getState) => {
  dispatch({
    type: GET_PUBLIC_COURSE.ACTION,
    payload: courseService.getPublicCourse(getState().user.get('authToken'))(courseSlug),
  })
}
