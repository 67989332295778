import { useEffect, useState } from 'react'

import { STRIPE_PUBLISHABLE_KEY } from '_config/environment'

export default () => {
  const [stripe, setStripe] = useState({
    stripe: null,
  })

  useEffect(() => {
    if (window.Stripe) {
      setStripe({
        stripe: window.Stripe(STRIPE_PUBLISHABLE_KEY),
      })
    } else if (document?.querySelector('#stripe-js')) {
      document.querySelector('#stripe-js').addEventListener('load', () => {
        // Create Stripe instance once Stripe.js loads
        setStripe({
          stripe: window.Stripe(STRIPE_PUBLISHABLE_KEY),
        })
      })
    }
  }, [])

  return { stripe }
}
