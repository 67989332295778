import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'

import FavoriteIcon from '_assets/icons/favorite.svg'
import FavoritedIcon from '_assets/icons/favorited.svg'
import { courseShape } from '_utils/proptypes'
import { onMouseDown } from '_utils/aria'
import { CLASS_TYPE } from '_constants/class'

import styles from './styles.css'

const CourseCardHeader = ({
  className,
  isOnboarding,
  isFavoritable,
  course,
  target,
  onFavoriteClick,
  isFavorite,
}) => {
  const renderCoaches = useMemo(
    () => {
      if (course.type !== CLASS_TYPE.COACH_LED.slug || !course?.instructors?.length) {
        return ''
      }

      if (course?.instructors?.length === 1) {
        return course?.instructors[0]?.name
      }

      return `${course?.instructors[0]?.name} and ${course?.instructors.length - 1} more`
    },
    [course]
  )

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles['title-wrapper']}>
        {isOnboarding ? (
          <h2 className={styles.title}>{course.name}</h2>
        ) : (
          <Link to={target}>
            <h2 className={styles.title}>{course.name}</h2>
          </Link>
        )}
        {isFavoritable && (
          <button
            type="button"
            onClick={onFavoriteClick}
            onMouseDown={onMouseDown}
            className={styles.favorite}
          >
            <svg
              className={styles.icon}
              aria-label="Favorite icon"
              viewBox={isFavorite ? FavoritedIcon.viewBox : FavoriteIcon.viewBox}
              role="img"
            >
              <use xlinkHref={`#${isFavorite ? FavoritedIcon.id : FavoriteIcon.id}`} />
            </svg>
          </button>
        )}
      </div>
      {course?.type && (
        <p className={styles['class-type']}>
          {course.type === CLASS_TYPE.COACH_LED.slug
            ? `With ${renderCoaches}`
            : CLASS_TYPE[(course?.type)]?.name}
        </p>
      )}
    </div>
  )
}

CourseCardHeader.propTypes = {
  className: PropTypes.string,
  isOnboarding: PropTypes.bool.isRequired,
  isFavoritable: PropTypes.bool.isRequired,
  target: PropTypes.string,
  course: courseShape.isRequired,
  isFavorite: PropTypes.bool,
  onFavoriteClick: PropTypes.func,
}

CourseCardHeader.defaultProps = {
  className: '',
  target: '',
  isFavorite: false,
  onFavoriteClick: () => {},
}

export default React.memo(CourseCardHeader)
