import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWindowSize } from '@reach/window-size'

import Button, { ButtonTheme, ButtonSize } from '_components/landing-components/button'
import { DESKTOP_THRESHOLD } from '_config/media-queries'

import styles from './styles.css'

const MembershipPaymentsDetails = ({ membershipDetails }) => {
  const { width } = useWindowSize()
  const isDesktop = useMemo(() => width > DESKTOP_THRESHOLD, [width])

  const [showDetails, setShowDetails] = useState(!!isDesktop)

  const onShowDetailsClick = useCallback(() => {
    setShowDetails(prevState => !prevState)
  }, [])

  const totalValue = useMemo(
    () => {
      if (membershipDetails.price === 0) {
        return 0
      }

      return membershipDetails?.price?.toFixed(2)
    },
    [membershipDetails]
  )

  return (
    <div className={styles.checkout}>
      <h1 className={styles.title}>Checkout</h1>
      <h2 className={styles['course-name']}>{membershipDetails.type} subscription</h2>
      <div className={styles.details}>
          <p className={styles['class-details']}>
            Includes 30 days of Lightyear Membership for first-time members!
          </p>
          <div className={styles['price-container']}>
            <div className={styles.total}>
              <p className={styles.field}>Subtotal</p>
              <p className={classnames(styles.field, styles.amount)}>
                ${membershipDetails.price?.toFixed(2)}
              </p>
            </div>

            <div className={styles.total}>
              <p className={styles.field}>Total</p>
              <p className={classnames(styles.field, styles.amount)}>${totalValue}</p>
            </div>
          </div>
        </div>
      {!isDesktop && (
        <Button
          className={styles['discount-button']}
          onClick={onShowDetailsClick}
          theme={ButtonTheme.EXPAND_GREEN}
          size={ButtonSize.SMALL}
          flipIcon={showDetails}
        >
          {showDetails ? 'HIDE DETAILS' : 'SHOW DETAILS'}
        </Button>
      )}
    </div>
  )
}

MembershipPaymentsDetails.propTypes = {
  membershipDetails: PropTypes.shape({
    type: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
}
export default React.memo(MembershipPaymentsDetails)
