import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'

import Button, { ButtonTheme } from '_components/button'
import { goalShape } from '_utils/proptypes'

import styles from './styles.css'

const UpcomingGoals = ({ className, goals, username }) => (
  <article className={classnames(styles.container, className)}>
    <p className={styles.title}>Upcoming Goals</p>
    <div className={styles.goals}>
      {goals.length ? (
        goals.map(goal => (
          <div key={goal.id}>
            <p className={styles.goal}>{goal.description}</p>
            <p className={styles['goal-due-to']}>{`By ${moment(goal.dueDate).format(
              'MMMM D, YYYY'
            )}`}</p>
          </div>
        ))
      ) : (
        <p className={styles.goal}>You can share your goals with everyone through your profile.</p>
      )}
    </div>
    <div className={styles.actions}>
      <Button
        theme={ButtonTheme.PRIMARY}
        label={goals.length ? 'SEE ALL GOALS' : 'VIEW YOUR FUTURE'}
        to={`/user/${username}/goals`}
      />
    </div>
  </article>
)
UpcomingGoals.propTypes = {
  className: PropTypes.string,
  goals: PropTypes.arrayOf(goalShape).isRequired,
  username: PropTypes.string,
}

UpcomingGoals.defaultProps = {
  className: '',
  username: '',
}

export default UpcomingGoals
