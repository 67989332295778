import React from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'

import Input from '_components/input'

import styles from './styles.css'

const FormLogin = ({
  signUp,
  loading,
  handleInput,
  email,
  confirmEmail,
  name,
  errors,
  password,
  apiError,
  isCompany,
}) => (
  <>
    <Input
      disabled={loading || isCompany}
      className={styles.input}
      label="Name"
      name="name"
      onChange={handleInput}
      value={name}
      errorMessage={errors.name || (apiError.size > 0 ? apiError.first().get(0) : '')}
      autocomplete="section-login email"
    />
    <Input
      disabled={loading || isCompany}
      className={styles.input}
      label="Email address"
      name="email"
      type="email"
      onChange={handleInput}
      value={email}
      errorMessage={errors.email || (apiError.size > 0 ? apiError.first().get(0) : '')}
      autocomplete="section-login email"
    />
    <Input
      disabled={loading || isCompany}
      className={styles.input}
      label="Confirm email address"
      name="confirmEmail"
      type="email"
      onChange={handleInput}
      value={confirmEmail}
      errorMessage={errors.confirmEmail || (apiError.size > 0 ? apiError.first().get(0) : '')}
      autocomplete="section-login email"
    />
    <Input
      disabled={loading}
      className={styles.input}
      label="Password"
      name="password"
      type="password"
      onChange={handleInput}
      value={password}
      errorMessage={errors.password || (apiError.size > 0 ? apiError.first().get(0) : '')}
      placeholder={`${signUp ? 'Minimum 8 characters' : ''}`}
      autocomplete={`section-login ${signUp ? 'new-password' : 'current-password'}`}
    />
  </>
)

FormLogin.propTypes = {
  loading: PropTypes.bool,
  signUp: PropTypes.bool,
  handleInput: PropTypes.func,
  email: PropTypes.string,
  name: PropTypes.string,
  errors: PropTypes.shape({
    email: PropTypes.string,
    password: PropTypes.string,
    confirmEmail: PropTypes.string,
    name: PropTypes.string,
  }),
  password: PropTypes.string,
  confirmEmail: PropTypes.string,
  apiError: PropTypes.instanceOf(Map),
  isCompany: PropTypes.bool,
}

FormLogin.defaultProps = {
  loading: false,
  signUp: false,
  handleInput: () => {},
  email: '',
  confirmEmail: '',
  name: '',
  errors: {
    email: '',
    password: '',
    confirmEmail: '',
    name: '',
  },
  password: '',
  apiError: Map(),
  isCompany: false,
}

export default FormLogin
