import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const Spinner = ({ size, color, className }) => {
  const spinnerStyle = useMemo(
    () => ({
      width: `${size / 10}rem`,
      height: `${size / 10}rem`,
      fontSize: `${size / 10}rem`,
      borderWidth: `${size / 100}rem`,
      borderLeftColor: color,
    }),
    [color, size]
  )

  return <div style={spinnerStyle} className={classnames(styles.loader, className)} />
}

Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
  className: PropTypes.string,
}

Spinner.defaultProps = {
  size: 20,
  color: 'white',
  className: '',
}

export default Spinner
