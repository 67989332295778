import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { navigate, Link } from '@reach/router'

import { onMouseDown } from '_utils/aria'
import Button, { ButtonTheme } from '_components/button'
import Card from '_components/card'
import DefaultAvatar from '_assets/icons/empty-photo.svg'

import styles from './styles.css'

const PeopleCard = ({
  name,
  src,
  organization,
  following,
  username,
  onFollowAction,
  onUnfollowAction,
}) => {
  const onUnfollowClick = useCallback(
    () => {
      onUnfollowAction({ username })
    },
    [onUnfollowAction, username]
  )

  const onFollowClick = useCallback(
    () => {
      onFollowAction({ username })
    },
    [onFollowAction, username]
  )

  const onUserClick = useCallback(
    () => {
      navigate(`/user/${username}`)
    },
    [username]
  )

  return (
    <Card className={styles.card}>
      <div className={styles.container}>
        {src ? (
          <button
            className={styles.button}
            type="button"
            onClick={onUserClick}
            onMouseDown={onMouseDown}
          >
            <img src={src} alt={`${name} avatar`} className={styles.avatar} />
          </button>
        ) : (
          <button
            className={styles.button}
            type="button"
            onClick={onUserClick}
            onMouseDown={onMouseDown}
          >
            <svg
              aria-label={`${name} avatar`}
              role="img"
              viewBox={DefaultAvatar.viewBox}
              className={styles.avatar}
            >
              <use xlinkHref={`#${DefaultAvatar.id}`} />
            </svg>
          </button>
        )}
        <div className={styles['user-info']}>
          <Link className={styles.name} to={`/user/${username}`}>
            {name}
          </Link>
          <p className={styles.organization}>{organization}</p>
        </div>
        <Button
          label={following ? 'FOLLOWING' : 'FOLLOW'}
          theme={following ? ButtonTheme.SECONDARY : ButtonTheme.PRIMARY}
          onClick={following ? onUnfollowClick : onFollowClick}
        />
      </div>
    </Card>
  )
}

PeopleCard.propTypes = {
  name: PropTypes.string,
  username: PropTypes.string,
  src: PropTypes.string,
  organization: PropTypes.string,
  following: PropTypes.bool,
  onFollowAction: PropTypes.func,
  onUnfollowAction: PropTypes.func,
}

PeopleCard.defaultProps = {
  name: '',
  username: '',
  src: '',
  organization: '',
  following: false,
  onFollowAction: () => {},
  onUnfollowAction: () => {},
}

export default PeopleCard
