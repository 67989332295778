import { validateEmail } from '_utils/helpers'

export const INITIAL_STATE = {
  name: '',
  email: '',
  errors: {
    name: '',
    email: '',
  },
}

export const UPDATE_STATE = 'UPDATE_STATE'
export const RESET_STATE = 'RESET_STATE'
export const VALIDATE_ERRORS = 'VALIDATE_ERRORS'
export const RESET_ERRORS = 'RESET_ERRORS'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE: {
      return {
        ...state,
        ...action?.payload,
      }
    }
    case VALIDATE_ERRORS: {
      if (action?.payload) {
        return {
          ...state,
          errors: {
            ...state.errors,
            ...action.payload,
          },
        }
      }

      const emailError = validateEmail(state.email)
        ? ''
        : 'Please put a valid email before subscribing'
      const nameError = state.name ? '' : 'Field is required'

      return {
        ...state,
        errors: {
          email: emailError,
          name: nameError,
        },
      }
    }
    case RESET_ERRORS: {
      return {
        ...state,
        errors: INITIAL_STATE.errors,
      }
    }
    case RESET_STATE: {
      return INITIAL_STATE
    }
    default:
      return state
  }
}
