/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
// I had to disable those since eslint can't figure out I have a nested input inside the Input component. But the issues are being taken care of
import React, { useCallback, useState, useEffect } from 'react'
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import Button, { ButtonSize } from '_components/landing-components/button'
import { addCard } from '_modules/card/actions'
import { isAddingNewCardSelector } from '_modules/card/selectors'
import { hasCompletedOnboardingSelector } from '_modules/user/selectors'
import { initialEnroll } from '_modules/class/actions'
import {
  initialEnrollErrorSelector,
  isInitialEnrollLoadingSelector,
} from '_modules/class/selectors'
import usePrevious from '_hooks/use-previous'
import ErrorAlertComponent from '_components/error-alert-component'
import { creditCardNameValidation } from '_utils/helpers'

import styles from './styles.css'
import FormFields from './form-fields'

const PaymentsForm = ({ isEnrolling, classId, discountCode }) => {
  const isAddingNewCard = useSelector(isAddingNewCardSelector)
  const hasUserCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)
  const isInitialEnrollLoading = useSelector(isInitialEnrollLoadingSelector)
  const initialEnrollError = useSelector(initialEnrollErrorSelector)
  const wasInitialEnrollLoading = usePrevious(isInitialEnrollLoading)

  const [hasError, setHasError] = useState(true)
  const [isAddNewCardLoading, setNewCardLoading] = useState(false)
  const [cardholderName, setCardholderName] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [generalError, setGeneralError] = useState('')

  const elements = useElements()
  const stripe = useStripe()
  const dispatch = useDispatch()

  const onCardholderNameChange = useCallback(
    name => {
      setCardholderName(name)
      if (hasError && !creditCardNameValidation(name)) {
        setHasError(false)
      }
    },
    [hasError]
  )

  const setError = useCallback(value => {
    setHasError(value)
  }, [])

  const onSubmitPaymentMethod = useCallback(
    event => {
      event.preventDefault()

      if (cardholderName.length === 0) {
        setHasError(true)
        return
      }
      const cardElement = elements.getElement(CardNumberElement)
      setNewCardLoading(true)
      stripe
        .createToken(cardElement, { name: cardholderName })
        .then(({ token }) => {
          setNewCardLoading(false)
          if (token?.id) {
            setLoading(true)
            if (!hasUserCompletedOnboarding) {
              dispatch(
                initialEnroll({
                  classId,
                  cardToken: token.id,
                  coupon: discountCode.length ? discountCode : undefined,
                })
              )
            } else {
              dispatch(addCard({ cardToken: token.id }))
            }
          } else {
            setNewCardLoading(false)
          }
        })
        .catch(() => {
          setNewCardLoading(false)
          setLoading(false)
        })
    },
    [cardholderName, classId, discountCode, dispatch, elements, hasUserCompletedOnboarding, stripe]
  )

  useEffect(
    () => {
      if (wasInitialEnrollLoading && !isInitialEnrollLoading) {
        setLoading(false)
        if (initialEnrollError.size) {
          setGeneralError(initialEnrollError.first())
        }
      }
    },
    [initialEnrollError, initialEnrollError.size, isInitialEnrollLoading, wasInitialEnrollLoading]
  )

  return (
    <form className={styles['payments-form']} onSubmit={onSubmitPaymentMethod}>
      {!!generalError && (
        <ErrorAlertComponent
          className={styles['error-alert']}
          errorMessage={generalError}
          isError
        />
      )}
      <FormFields onNameChange={onCardholderNameChange} setError={setError} />
      <Button
        type="submit"
        size={ButtonSize.BIG}
        className={styles.button}
        isLoading={isAddingNewCard || isEnrolling || isAddNewCardLoading || isLoading}
        disabled={hasError}
      >
        ENROLL NOW
      </Button>
    </form>
  )
}

PaymentsForm.propTypes = {
  isEnrolling: PropTypes.bool,
  classId: PropTypes.number.isRequired,
  discountCode: PropTypes.string,
}

PaymentsForm.defaultProps = {
  isEnrolling: false,
  discountCode: '',
}

export default React.memo(PaymentsForm)
