import { List, Map } from 'immutable'

import Model from './model'

class Profile extends Model({
  user: undefined,
  following: undefined,
  online: undefined,
  suspended: undefined,
  blocking: undefined,
  isBlockedBy: undefined,
  visionStatement: undefined,
  legacyStatement: undefined,
  goalCategories: List(),
  postsDetails: Map({
    count: undefined,
    next: undefined,
    previous: undefined,
    posts: List(),
  }),
  peopleYouMayKnow: List(),
}) {}

export { Profile as default }
