import React, { useMemo, useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'

import CameraIcon from '_assets/icons/videocall.svg'
import PhoneIcon from '_assets/icons/phone-icon.svg'
import Button, { ButtonTheme } from '_components/button'
import { getUserSelector } from '_modules/user/selectors'
import { startAssignmentVideo } from '_modules/class/actions'
import { isStartingCallSelector, startCallErrorSelector } from '_modules/class/selectors'
import usePrevious from '_hooks/use-previous'

import styles from './styles.css'

const LiveCallBanner = ({
  className,
  callTime,
  callLink,
  classStarted,
  call,
  classId,
  leaderId,
}) => {
  const { id } = useSelector(getUserSelector)
  const isStartingCall = useSelector(isStartingCallSelector)
  const wasStartingCall = usePrevious(isStartingCall)
  const startCallError = useSelector(startCallErrorSelector)

  const [minuteCountdown, setMinuteCountdown] = useState(Math.floor(callTime))

  const dispatch = useDispatch()

  useEffect(() => {
    let countdown = null

    countdown = setInterval(() => {
      if (minuteCountdown > 0) {
        setMinuteCountdown(prevState => prevState - 1)
      } else {
        clearInterval(countdown)
      }
    }, 60000)

    return () => {
      clearInterval(countdown)
    }
  }, []) //eslint-disable-line

  const timeRemaining = useMemo(
    () => {
      if (minuteCountdown < 1) {
        return 'now'
      }

      if (minuteCountdown === 1) {
        return '1 minute'
      }

      if (minuteCountdown > 10) {
        return '10 minutes'
      }

      return `${minuteCountdown} minutes`
    },
    [minuteCountdown]
  )

  const onStartCall = useCallback(
    () => {
      dispatch(
        startAssignmentVideo({
          meetingId: call.id,
          assignmentId: classId,
        })
      )
    },
    [call.id, classId, dispatch]
  )

  useEffect(
    () => {
      if (wasStartingCall && !isStartingCall && !startCallError.size) {
        window.open(callLink)
      }
    },
    [callLink, isStartingCall, startCallError.size, wasStartingCall]
  )

  const renderButton = useMemo(
    () => {
      if (id === leaderId && !classStarted) {
        return (
          <Button
            className={styles.button}
            icon={PhoneIcon}
            theme={ButtonTheme.WHITE_OUTLINE}
            label="START CALL"
            onClick={onStartCall}
            loading={isStartingCall}
          />
        )
      }

      if (classStarted) {
        return (
          <Button
            className={styles.button}
            icon={PhoneIcon}
            theme={ButtonTheme.WHITE_OUTLINE}
            label="JOIN CALL"
            to={callLink}
          />
        )
      }

      return null
    },
    [callLink, classStarted, id, isStartingCall, leaderId, onStartCall]
  )

  return (
    <section className={classnames(styles['banner-wrapper'], className)}>
      <span className={styles['icon-wrapper']}>
        <svg className={styles.icon} aria-hidden="true" viewBox={CameraIcon.viewBox}>
          <use xlinkHref={`#${CameraIcon.id}`} />
        </svg>
      </span>
      <h1 className={styles.title}>
        Hey there! You have a live call in <b className={styles.bold}>{timeRemaining}</b>, ready to
        join?
      </h1>
      {renderButton}
    </section>
  )
}

LiveCallBanner.propTypes = {
  className: PropTypes.string,
  callTime: PropTypes.number.isRequired,
  callLink: PropTypes.string.isRequired,
  classStarted: PropTypes.bool.isRequired,
  call: PropTypes.shape({
    id: PropTypes.number,
    leaderId: PropTypes.number,
  }).isRequired,
  classId: PropTypes.number.isRequired,
  leaderId: PropTypes.number.isRequired,
}

LiveCallBanner.defaultProps = {
  className: '',
}

export default React.memo(LiveCallBanner)
