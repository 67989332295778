import humps from 'humps'
import { Map, fromJS } from 'immutable'
import cookies from 'react-cookies'

import { getFormattedLanding } from '_constants/prismic'
import { createReducer } from '_utils/redux'

import {
  GET_CLASSES_LIST_SLICE_POSITION,
  GET_PRISMIC_INFORMATION,
  DISMISS_HIGHLIGHT_MESSAGE,
} from './actions'

const INITIAL_STATE = Map()

export default createReducer(INITIAL_STATE, {
  [GET_PRISMIC_INFORMATION.FULFILLED]: (state, { payload }) => {
    const { path, ...restPayload } = payload
    const camelizedPayload = humps.camelizeKeys(restPayload)
    const { content } = camelizedPayload
    const landing = fromJS(getFormattedLanding(content))
    const documentType = humps.camelize(camelizedPayload.documentType)

    return state.setIn([documentType], landing).setIn(['path'], path)
  },
  [GET_CLASSES_LIST_SLICE_POSITION]: (state, { payload }) => state.setIn(['slicesPosition', 'classesList'], payload),
  [DISMISS_HIGHLIGHT_MESSAGE]: (state, { payload }) => {
    cookies.save('alertMessage', payload, { path: '/' })
    return state
  },
})
