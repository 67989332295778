import React, { useReducer, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'
import { useWindowSize } from '@reach/window-size'

import Toastr, { ToastrTheme } from '_components/toastr'
import { usePrevious } from '_utils/hooks'
import * as chatSelectors from '_modules/chat/selectors'
import {
  checkIsLinkingZoom,
  checkIsBlockingUser,
  errorBlockUser,
  errorLinkingZoom,
} from '_modules/user/selectors'
import ParticipantsModal, { ParticipantModalTheme } from '_components/participants-modal'
import SidebarChat from '_components/sidebar-chat'
import Modal from '_components/modal'
import { isKeyboardOrClick } from '_utils/aria'
import RocketLoader from '_components/rocket-loader'
import { RoleType } from '_utils/constants'
import { queryParam, changeUrl, verifyZoom, parseZoomCode } from '_utils/helpers'
import { blockUser, linkZoom } from '_modules/user/actions'
import {
  getChannelMessages,
  getDirectMessages,
  sendDirectMessage,
  sendChannelMessage,
  readChannelMessages,
  readUserMessages,
  getUsersFromChannel,
  disableChannel,
  enableChannel,
  removeUserFromChannel,
  updateChannel,
  makeAdmin,
  revokeAdmin,
  createPreDirectMessage,
  getAvailableToInvite,
  inviteToChannel,
  startVideoChannelCall,
  startVideoDirectMessageCall,
  storeLastCheckpoint,
  getMyChats,
} from '_modules/chat/actions'
import { MOBILE_THRESHOLD } from '_config/media-queries'

import EmptyMessage from './empty-messages'
import ChatHeader from './chat-header'
import ChatContent from './chat-content'
import ChatFooter from './chat-footer'
import styles from './styles.css'
import SidebarGoals from './sidebar-goals'
import messageReducer, { initialState, DIRECT_MESSAGE, CHANNEL, MESSAGES_ACTIONS } from './reducer'

const Messages = props => {
  const dispatch = useDispatch()
  const { width } = useWindowSize()
  const isMobile = width <= MOBILE_THRESHOLD

  const {
    channels,
    directMessages,
    hasNewDirectMessage,
    preDirectMessage,
    usersAvailableToInvite,
  } = useSelector(chatSelectors.getAllChats)
  const prevLocation = usePrevious(props.location)
  const prevChannels = usePrevious(channels)
  const isLoadingChats = useSelector(chatSelectors.isLoadingChats)
  const wasLoadingChats = usePrevious(isLoadingChats)
  const loadingChatsError = useSelector(chatSelectors.loadingChatsError)
  const isCreatingPreDirectMessage = useSelector(chatSelectors.isCreatingPreDirectMessage)
  const wasCreatingPreDirectMessage = usePrevious(isCreatingPreDirectMessage)
  const creatingPreDirectMessageError = useSelector(chatSelectors.creatingPreDirectMessageError)
  const isLoadingMessages = useSelector(chatSelectors.isLoadingMessages)
  const wasLoadingMessages = usePrevious(isLoadingMessages)
  const isLoadingDirect = useSelector(chatSelectors.isLoadingDirect)
  const wasLoadingDirect = usePrevious(isLoadingDirect)
  const isSendingMessage = useSelector(chatSelectors.isSendingMessage)
  const wasSendingMessage = usePrevious(isSendingMessage)
  const sendMessageError = useSelector(chatSelectors.sendMessageError)
  const isLoadingChatUsers = useSelector(chatSelectors.isLoadingChatUsers)
  const isLoadingUsersToInvite = useSelector(chatSelectors.isLoadingUsersToInvite)
  const isInvitingToChannel = useSelector(chatSelectors.isInvitingToChannel)
  const wasInvitingToChannel = usePrevious(isInvitingToChannel)
  const inviteToChannelError = useSelector(chatSelectors.inviteToChannelError)
  const isStartingVideoCall = useSelector(chatSelectors.isStartingVideoCall)
  const userRole = useSelector(state => state.user.role)
  const userId = useSelector(state => state.user.id)
  const hasZoom = useSelector(state => state.user.hasZoom)
  const isBlockingUser = useSelector(checkIsBlockingUser)
  const wasBlockingUser = usePrevious(isBlockingUser)
  const blockUserError = useSelector(errorBlockUser)
  const linkingZoomError = useSelector(errorLinkingZoom)
  const isLinkingZoom = useSelector(checkIsLinkingZoom)
  const wasLinkingZoom = usePrevious(isLinkingZoom)

  const channelIdInitialState = useMemo(
    () => {
      if (isMobile) {
        return undefined
      }

      return (
        parseInt(queryParam(props.location, 'channelId'), 10) ||
        (!!channels.length && channels[0].id)
      )
    },
    [channels, isMobile, props.location]
  )

  const [state, dispatchState] = useReducer(messageReducer, {
    ...initialState,
    selected:
      (!!queryParam(props.location, 'directMessage') && DIRECT_MESSAGE) ||
      (!!queryParam(props.location, 'channelId') && CHANNEL) ||
      (!!channels.length && CHANNEL),
    directChatUsername: queryParam(props.location, 'directMessage')?.replace(' ', '+'),
    channelId: channelIdInitialState,
    isChatOpened: !isMobile,
  })

  const updateSelectedResetUrlAndGetMessages = useCallback(
    () => {
      const selected = (!!channels.length && CHANNEL) || (!!directMessages.length && DIRECT_MESSAGE)
      if (selected) {
        changeUrl({
          channelId: selected === CHANNEL ? channels[0].id : null,
          directMessage: selected === DIRECT_MESSAGE ? directMessages[0].user.username : null,
        })
      }

      if (selected === CHANNEL) {
        dispatch(getChannelMessages(channels[0].id))
      }
      if (selected === DIRECT_MESSAGE) {
        dispatch(getDirectMessages(directMessages[0].user.username))
      }

      dispatchState({
        type: MESSAGES_ACTIONS.CHANGE_MESSAGE,
        channelId: !!channels.length && channels[0].id,
        directChatUsername: !!directMessages.length && directMessages[0].user.username,
        selected,
        isEmpty: !selected,
      })
    },
    [channels, directMessages, dispatch]
  )

  useEffect(
    () => {
      if (
        (state.selected !== CHANNEL && state.selected !== DIRECT_MESSAGE) ||
        (prevChannels && prevChannels.length > channels.length && state.selected === CHANNEL)
      ) {
        updateSelectedResetUrlAndGetMessages()
      }
    },
    [
      channels.length,
      directMessages.length,
      prevChannels,
      state.selected,
      updateSelectedResetUrlAndGetMessages,
    ]
  )

  useEffect(
    () => {
      if (
        prevLocation &&
        queryParam(prevLocation, 'directMessage') !== queryParam(props.location, 'directMessage')
      )
        dispatchState({
          type: MESSAGES_ACTIONS.CHANGE_MESSAGE,
          selected:
            (!!queryParam(props.location, 'channelId') && CHANNEL) ||
            (!!queryParam(props.location, 'directMessage') && DIRECT_MESSAGE),
          directChatUsername: queryParam(props.location, 'directMessage'),
          channelId: parseInt(queryParam(props.location, 'channelId'), 10),
        })
    },
    [prevLocation, props.location]
  )

  useEffect(
    () => {
      if (state.selected === CHANNEL) {
        const selectedChannel = channels.filter(channel => channel.id === state.channelId)

        if (prevChannels && (!channels.length || (channels.length && !selectedChannel.length))) {
          updateSelectedResetUrlAndGetMessages()
        }

        if (selectedChannel.length) {
          if ((isMobile && state.isChatOpened) || !isMobile) {
            dispatch(getChannelMessages(state.channelId))
            dispatch(readChannelMessages(state.channelId))
          }
        }
      }

      if (state.selected === DIRECT_MESSAGE) {
        const directMessage = directMessages.filter(
          dm => dm.user.username === state.directChatUsername
        )

        const isNewDirectMessage =
          !!preDirectMessage.user.username &&
          state.directChatUsername === preDirectMessage.user.username

        if (directMessage.length && !isNewDirectMessage) {
          dispatch(getDirectMessages(state.directChatUsername))
          dispatch(readUserMessages(state.directChatUsername))
        }

        if (directMessages.length && !directMessage.length) {
          updateSelectedResetUrlAndGetMessages()
        }
      }

      return () => {
        dispatch(
          storeLastCheckpoint({
            channelId: state.selected === CHANNEL ? state.channelId : null,
            directChatUsername: state.selected === DIRECT_MESSAGE ? state.directChatUsername : null,
          })
        )
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      state.channelId,
      state.directChatUsername,
      state.isChatOpened,
      state.selected,
      directMessages.length,
      channels.length,
      isMobile,
      dispatch,
      preDirectMessage.user.username,
    ]
  )

  useEffect(
    () => {
      if (state.isEmpty) {
        updateSelectedResetUrlAndGetMessages()
      }
    },
    [state.isEmpty, updateSelectedResetUrlAndGetMessages]
  )

  useEffect(
    () => {
      if (hasNewDirectMessage) {
        dispatchState({
          type: MESSAGES_ACTIONS.NEW_DIRECT_MESSAGE,
          directChatUsername: preDirectMessage.user.username,
        })
        changeUrl({ directMessage: preDirectMessage.user.username })
      }
    },
    [hasNewDirectMessage, preDirectMessage.user.username]
  )

  useEffect(
    () => {
      if (
        wasSendingMessage &&
        !isSendingMessage &&
        (!sendMessageError || (sendMessageError && sendMessageError.size === 0))
      ) {
        window.scrollTo(0, document.documentElement.scrollHeight)
      }
    },
    [isSendingMessage, wasSendingMessage, sendMessageError]
  )

  useEffect(
    () => {
      if (
        wasLoadingChats &&
        !isLoadingChats &&
        (!loadingChatsError || (loadingChatsError && loadingChatsError.size === 0)) &&
        !channels.length &&
        !directMessages.length
      ) {
        dispatchState({ type: MESSAGES_ACTIONS.EMPTY })
      }
    },
    [isLoadingChats, wasLoadingChats, loadingChatsError, channels.length, directMessages.length]
  )

  useEffect(
    () => {
      if ((wasLoadingDirect && !isLoadingDirect) || (wasLoadingMessages && !isLoadingMessages)) {
        dispatchState({
          type: MESSAGES_ACTIONS.LOADING_MORE,
          isLoadingMoreMessages: false,
        })
      }
    },
    [isLoadingDirect, isLoadingMessages, wasLoadingDirect, wasLoadingMessages]
  )

  useEffect(
    () => {
      if (
        !isInvitingToChannel &&
        wasInvitingToChannel &&
        (!inviteToChannelError || (inviteToChannelError && inviteToChannelError.size === 0))
      )
        dispatchState({
          type: MESSAGES_ACTIONS.OPEN_PARTICIPANT_MODAL,
          isAddParticipantModalOpen: false,
        })
    },
    [inviteToChannelError, isInvitingToChannel, wasInvitingToChannel]
  )

  useEffect(
    () => {
      if (
        !isBlockingUser &&
        wasBlockingUser &&
        (!blockUserError || (blockUserError && blockUserError.size === 0))
      ) {
        updateSelectedResetUrlAndGetMessages()
      }
    },
    [blockUserError, isBlockingUser, updateSelectedResetUrlAndGetMessages, wasBlockingUser]
  )

  useEffect(
    () => {
      if (
        !isCreatingPreDirectMessage &&
        wasCreatingPreDirectMessage &&
        (!creatingPreDirectMessageError ||
          (creatingPreDirectMessageError && creatingPreDirectMessageError.size === 0))
      ) {
        dispatchState({
          type: MESSAGES_ACTIONS.TOOGLE_MODAL_OPEN,
          isModalOpen: false,
        })
      }
    },
    [
      isCreatingPreDirectMessage,
      wasCreatingPreDirectMessage,
      creatingPreDirectMessageError,
      updateSelectedResetUrlAndGetMessages,
    ]
  )

  const onDirectMessageClick = useCallback(directChatUsername => {
    dispatchState({
      type: MESSAGES_ACTIONS.CHANGE_MESSAGE,
      directChatUsername,
      selected: DIRECT_MESSAGE,
    })
    changeUrl({ directMessage: directChatUsername })
  }, [])

  const onChannelClick = useCallback(channelId => {
    dispatchState({
      type: MESSAGES_ACTIONS.CHANGE_MESSAGE,
      channelId,
      selected: CHANNEL,
    })
    changeUrl({ channelId })
  }, [])

  const updateChannelSettings = useCallback(
    payload => {
      dispatch(updateChannel(payload))
    },
    [dispatch]
  )

  const disableChannelSettings = useCallback(
    payload => {
      dispatch(disableChannel(payload))
    },
    [dispatch]
  )

  const enableChannelSettings = useCallback(
    payload => {
      dispatch(enableChannel(payload))
    },
    [dispatch]
  )

  const linkZoomAccount = useCallback(
    payload => {
      dispatch(linkZoom(payload))
    },
    [dispatch]
  )

  const newPreDirectMessage = useCallback(
    payload => {
      dispatch(createPreDirectMessage(payload))
    },
    [dispatch]
  )

  const onBackClick = useCallback(event => {
    event.preventDefault()
    const { key } = event
    if (isKeyboardOrClick(key)) {
      dispatchState({ type: MESSAGES_ACTIONS.CLOSE_CHAT })
    }
  }, [])

  const onMessageSubmit = useCallback(
    ({ message = null, file = null }) => {
      const { directChatUsername, channelId, selected } = state
      const payload = {}

      if (message) {
        payload.message = message
      }

      if (file) {
        payload.file = file
      }

      if (selected === DIRECT_MESSAGE) {
        const data = {
          username: directChatUsername,
          ...payload,
          isNewDirectMessage:
            hasNewDirectMessage && directChatUsername === preDirectMessage.user.username,
        }

        dispatch(sendDirectMessage(data))
        return
      }

      if (selected === CHANNEL) {
        dispatch(sendChannelMessage(channelId, payload))
      }
    },
    [dispatch, hasNewDirectMessage, preDirectMessage.user.username, state]
  )

  const onChannelInfoClick = useCallback(
    () => {
      dispatchState({
        type: MESSAGES_ACTIONS.TOOGLE_MODAL_OPEN,
        isModalOpen: true,
      })
      dispatch(getUsersFromChannel({ channelId: state.channelId }))
    },
    [dispatch, state.channelId]
  )

  const onModalClose = useCallback(() => {
    dispatchState({ type: MESSAGES_ACTIONS.MODAL_CLOSE })
  }, [])

  const onAddParticipantClick = useCallback(
    () => {
      dispatchState({
        type: MESSAGES_ACTIONS.OPEN_PARTICIPANT_MODAL,
        isAddParticipantModalOpen: true,
      })
      dispatch(
        getAvailableToInvite({
          channelId: state.channelId,
          page: null,
        })
      )
    },
    [dispatch, state.channelId]
  )

  const onCreateNewPreDirectMessage = useCallback(
    payload => {
      if (directMessages.find(dm => dm.user.username === payload.username)) {
        dispatchState({
          type: MESSAGES_ACTIONS.TOOGLE_MODAL_OPEN,
          isModalOpen: false,
        })
        dispatchState({
          type: MESSAGES_ACTIONS.CHANGE_MESSAGE,
          directChatUsername: payload.username,
          selected: DIRECT_MESSAGE,
        })
      } else {
        newPreDirectMessage(payload)
        dispatchState({
          type: MESSAGES_ACTIONS.TOOGLE_MODAL_OPEN,
          isModalOpen: false,
        })
      }
    },
    [directMessages, newPreDirectMessage]
  )

  const loadMoreMessages = useCallback(
    () => {
      const { directChatUsername, channelId, selected } = state

      dispatchState({
        type: MESSAGES_ACTIONS.LOADING_MORE,
        isLoadingMoreMessages: true,
      })

      if (selected === CHANNEL) {
        dispatch(
          getChannelMessages(channelId, channels.find(channel => channel.id === channelId).next)
        )
      }

      if (selected === DIRECT_MESSAGE) {
        dispatch(
          getDirectMessages(
            directChatUsername,
            directMessages.find(dm => dm.user.username === directChatUsername).next
          )
        )
      }
    },
    [channels, directMessages, dispatch, state]
  )

  const loadMoreUsers = useCallback(
    () => {
      const { channelId } = state
      const { members } = channels.find(channel => channel.id === channelId)

      if (members.next) {
        dispatch(getUsersFromChannel({ channelId, page: members.next }))
      }
    },
    [channels, dispatch, state]
  )

  const loadMoreUsersToInvite = useCallback(
    () => {
      const { channelId } = state
      if (usersAvailableToInvite.next) {
        dispatch(
          getAvailableToInvite({
            channelId,
            page: usersAvailableToInvite.next,
          })
        )
      }
    },
    [dispatch, state, usersAvailableToInvite.next]
  )

  const onRemoveUserFromChannelClick = useCallback(
    payload => {
      dispatch(
        removeUserFromChannel({
          ...payload,
          userId: payload.userId || userId,
          channelId: payload.channelId || state.channelId,
        })
      )
    },
    [dispatch, state.channelId, userId]
  )

  const makeChannelAdmin = useCallback(
    selectedUserId => {
      dispatch(makeAdmin({ userId: selectedUserId, channelId: state.channelId }))
    },
    [dispatch, state.channelId]
  )

  const revokeChannelAdmin = useCallback(
    selectedUserId => {
      dispatch(revokeAdmin({ userId: selectedUserId, channelId: state.channelId }))
    },
    [dispatch, state.channelId]
  )

  const onSearchSubmit = useCallback(
    search => {
      const { isModalOpen, channelId } = state

      if (isModalOpen) {
        dispatch(getUsersFromChannel({ channelId, search }))
        return
      }
      dispatch(getAvailableToInvite({ channelId, search }))
    },
    [dispatch, state]
  )

  const onInviteUser = useCallback(
    payload => {
      dispatch(inviteToChannel({ ...payload, channelId: state.channelId }))
    },
    [dispatch, state.channelId]
  )

  const onBlockUserClick = useCallback(
    payload => {
      dispatch(blockUser(payload))
    },
    [dispatch]
  )

  const onStartVideoCall = useCallback(
    payload => {
      const { channelId, directChatUsername, selected } = state

      if (selected === CHANNEL) {
        dispatch(startVideoChannelCall({ ...payload, channelId }))
        return
      }

      dispatch(
        startVideoDirectMessageCall({
          ...payload,
          username: directChatUsername,
          userId: directMessages.find(
            directMessage => directMessage.user.username === directChatUsername
          ).user.id,
        })
      )
    },
    [directMessages, dispatch, state]
  )

  const removeZoomParams = useCallback(
    () => {
      const { channelId, directChatUsername } = state

      changeUrl({
        channelId,
        directMessage: directChatUsername,
      })
    },
    [state]
  )

  const selectedChannel = useMemo(
    () => (state.channelId ? channels.find(channel => channel.id === state.channelId) : null),
    [channels, state.channelId]
  )

  const selectedDirectMessage = useMemo(
    () =>
      state.directChatUsername
        ? directMessages.find(
            directMessage => directMessage.user.username === state.directChatUsername
          )
        : null,
    [directMessages, state.directChatUsername]
  )

  const wasRedirectedByZoom = useMemo(() => verifyZoom(props.location.search), [
    props.location.search,
  ])

  const zoomRedirectURI = useMemo(() => `${props.location.origin}/messages`, [
    props.location.origin,
  ])

  useEffect(
    () => {
      if (wasLinkingZoom && !isLinkingZoom) {
        if (!linkingZoomError || (linkingZoomError && linkingZoomError.size === 0)) {
          toast(<Toastr theme={ToastrTheme.SUCCESS} content="Zoom linked successfully" />)
          return
        }
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content="Something went wrong, please try again later."
          />
        )
      }
    },
    [linkingZoomError, isLinkingZoom, wasLinkingZoom]
  )

  useEffect(
    () => {
      if (wasLinkingZoom === undefined && wasRedirectedByZoom && !isLinkingZoom) {
        linkZoomAccount({
          code: parseZoomCode(props.location.search),
          redirect_uri: zoomRedirectURI,
        })
      }
    },
    [
      isLinkingZoom,
      linkZoomAccount,
      props.location.search,
      wasLinkingZoom,
      wasRedirectedByZoom,
      zoomRedirectURI,
    ]
  )

  useEffect(
    () => {
      dispatch(getMyChats())
    },
    [dispatch]
  )

  if (state.isEmpty) {
    return (
      <EmptyMessage
        newPreDirectMessage={newPreDirectMessage}
        isAdmin={userRole === RoleType.ADMIN || userRole === RoleType.LEADER}
      />
    )
  }

  if ((!isMobile && !selectedChannel && !selectedDirectMessage) || isLoadingChats) {
    return (
      <div className={styles.loading}>
        <RocketLoader />
      </div>
    )
  }

  const isChannelOpened = state.selected === CHANNEL && channels.length

  const content = isChannelOpened
    ? channels.find(channel => channel.id === state.channelId)
    : directMessages.find(dm => dm.user.username === state.directChatUsername)

  const isAdmin =
    (content && content?.admin) || userRole === RoleType.ADMIN || userRole === RoleType.LEADER

  const hasGoals = !isChannelOpened && content?.user?.goals?.length

  return (
    <>
      <div
        className={classnames(styles.container, {
          [styles['chat-opened']]: state.isChatOpened,
          [styles['has-goals']]: !isChannelOpened && hasGoals,
        })}
      >
        <SidebarChat
          className={styles['sidebar-chat']}
          onDirectMessageClick={onDirectMessageClick}
          onChannelClick={onChannelClick}
          directMessage={state.selected === DIRECT_MESSAGE ? state.directChatUsername : ''}
          channelId={isChannelOpened ? state.channelId : undefined}
          directMessages={directMessages}
          channels={channels}
          isOnline={props.isOnline}
          isLoading={isLoadingChats}
          newPreDirectMessage={onCreateNewPreDirectMessage}
          isAdmin={isAdmin}
        />
        {!!content && (
          <div className={styles['content-wrapper']}>
            <ChatHeader
              className={styles.chat}
              onBackClick={onBackClick}
              content={content}
              isChannel={!!isChannelOpened}
              onChannelInfoClick={onChannelInfoClick}
              onAddParticipantClick={onAddParticipantClick}
              isAdmin={isAdmin}
              disableChannel={disableChannelSettings}
              enableChannel={enableChannelSettings}
              leaveChannel={onRemoveUserFromChannelClick}
              updateChannel={updateChannelSettings}
              blockUser={onBlockUserClick}
              hasGoals={!!hasGoals}
              hasZoom={hasZoom}
              onCreateVideoCall={onStartVideoCall}
              isCreatingVideoCall={isStartingVideoCall}
              pathname={zoomRedirectURI}
              removeZoomParams={removeZoomParams}
            />
            <ChatContent
              className={styles.chat}
              isChannel={!!isChannelOpened}
              content={content?.messages}
              isGettingMessages={isLoadingDirect || isLoadingMessages}
              loadMore={loadMoreMessages}
              hasMore={!!content?.next}
              selectedChat={
                (isChannelOpened && state.channelId) ||
                (state.selected === DIRECT_MESSAGE && state.directChatUsername)
              }
              loading={(isLoadingDirect || isLoadingMessages) && !state.isLoadingMoreMessages}
              isChannelAdmin={isAdmin}
              hasGoals={!!hasGoals}
            />
            <ChatFooter
              onSubmit={onMessageSubmit}
              isDisabled={content?.disabled}
              isSendingMessage={isSendingMessage}
              isChannel={!!isChannelOpened}
              hasGoals={!!hasGoals}
            />
          </div>
        )}
        {!isChannelOpened && hasGoals ? (
          <SidebarGoals className={styles.goals} user={content?.user} />
        ) : null}
      </div>
      {state.isModalOpen && (
        <Modal isOpen className={styles['messages-modal']}>
          <ParticipantsModal
            onClose={onModalClose}
            members={content?.members?.users}
            hasMore={!!content?.members?.next}
            onLoadMore={loadMoreUsers}
            isAdmin={isAdmin}
            onSubmit={onSearchSubmit}
            onMakeAdmin={makeChannelAdmin}
            onRevokeAdmin={revokeChannelAdmin}
            onRemoveFromChannel={onRemoveUserFromChannelClick}
            onNewDirectMessage={onCreateNewPreDirectMessage}
            isLoading={isLoadingChatUsers}
            numberOfMembers={content?.numberOfMembers}
          />
        </Modal>
      )}
      {state.isAddParticipantModalOpen && (
        <Modal isOpen className={styles['messages-modal']}>
          <ParticipantsModal
            onClose={onModalClose}
            members={usersAvailableToInvite.users}
            hasMore={!!usersAvailableToInvite.next}
            onLoadMore={loadMoreUsersToInvite}
            theme={ParticipantModalTheme.ADD_PARTICIPANT}
            isLoading={isLoadingUsersToInvite || isInvitingToChannel}
            onSubmit={onSearchSubmit}
            onInviteToChannel={onInviteUser}
          />
        </Modal>
      )}
    </>
  )
}

Messages.propTypes = {
  isOnline: PropTypes.bool,
  location: PropTypes.shape({
    search: PropTypes.string,
    href: PropTypes.string,
    origin: PropTypes.string,
  }).isRequired,
}

Messages.defaultProps = {
  isOnline: false,
}

export default React.memo(Messages)
