import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CourseTag from '_components/course-tag'
import Button, { ButtonTheme } from '_components/landing-components/button'
import Svg from '_components/svg'
import useWindowSize from '_hooks/use-window-size'
import { iconPropTypes } from '_utils/proptypes'

import styles from './styles.css'

const SliderCourseCard = ({ title, icon, tags, url, description, className }) => {
  const { isMobile } = useWindowSize()

  const buttonTheme = useMemo(
    () => isMobile ? ButtonTheme.TRANSPARENT : ButtonTheme.GHOST,
    [isMobile]
  )

  return (
    <dl className={classnames(styles['slider-course-card-container'], className)}>
      <Svg className={styles['course-icon']} icon={icon} />
      <div className={styles['tags-wrapper']}>
        {tags.map(tag => <CourseTag key={tag} text={tag} className={styles.tags} />)}
      </div>
      <dt className={styles['course-title']}>{title}</dt>
      <dd className={styles['course-description']}>{description}</dd>
      <Button
        className={classnames(styles['see-more-button'], { [styles['text-malibu']]: isMobile })}
        theme={buttonTheme}
        url={url}
      >
        See more
      </Button>
    </dl>
  )
}

SliderCourseCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  icon: iconPropTypes,
  tags: PropTypes.arrayOf(PropTypes.string),
  url: PropTypes.string,
  className: PropTypes.string,
}

SliderCourseCard.defaultProps = {
  title: '',
  description: '',
  icon: '',
  tags: [],
  url: '',
  className: '',
}

export default SliderCourseCard
