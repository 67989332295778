// SRC: https://usehooks.com/useWindowSize/

import { useCallback, useState, useEffect } from 'react'

import { MEDIUM_WIDTH, MOBILE_WIDTH } from '_utils/constants'

export default () => {
  const isClient = typeof window === 'object'

  const getSize = useCallback(
    () => ({
      width: isClient ? window.innerWidth : undefined,
      height: isClient ? window.innerHeight : undefined,
    }),
    [isClient]
  )

  const [windowSize, setWindowSize] = useState(getSize)

  useEffect(
    () => {
      if (!isClient) {
        return false
      }

      const handleResize = () => {
        setWindowSize(getSize())
      }

      window.addEventListener('resize', handleResize)

      return () => window.removeEventListener('resize', handleResize)
    },
    [getSize, isClient]
  )

  const { width, height } = windowSize
  const isMobile = width < MOBILE_WIDTH
  const isMedium = width <= MEDIUM_WIDTH && width > MOBILE_WIDTH
  const isDesktop = width > MEDIUM_WIDTH

  return { width, height, isMobile, isMedium, isDesktop }
}
