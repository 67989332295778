import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'

import CalendarIcon from '_assets/icons/calendar-filled-icon.svg'
import CoachesChip from '_components/coaches-chip'
import LightyearLogo from '_assets/full-logo.svg'
import Svg from '_components/svg'
import { formatDate } from '_utils/helpers'
import ClockIcon from '_assets/icons/clock.svg'
import { classShape } from '_utils/proptypes'
import { FLAG_MULTIPLE_COACHES } from '_config/environment'

import styles from './styles.css'

const ClassCard = ({ currentClass, className, isSliderCard }) => {
  const parseInstructor = useMemo(
    () => {
      if (!FLAG_MULTIPLE_COACHES && currentClass?.instructor) {
        return [currentClass?.get('instructor')]
      }
      if (currentClass?.instructors) {
        return currentClass?.instructors
      }

      return []
    },
    [currentClass]
  )

  const enrolled = useMemo(
    () => ({
      enrolled: currentClass?.enrolled,
    }),
    [currentClass]
  )

  return (
    <div
      key={currentClass?.id}
      className={classnames(
        styles['class-card'],
        { [styles['slider-class-card']]: isSliderCard },
        className
      )}
    >
      {currentClass?.coverPhoto ? (
        <img
          className={styles['class-card-cover-photo']}
          src={currentClass?.coverPhoto}
          alt={`${currentClass?.title}'s cover`}
        />
      ) : (
        <Svg icon={LightyearLogo} className={styles['class-card-cover-photo']} />
      )}
      <div className={styles['class-card-content']}>
        <p className={styles.tag}>{currentClass?.category}</p>
        <p className={styles['class-title']}>{currentClass?.name}</p>
        {parseInstructor.size > 0 && <CoachesChip coachesList={parseInstructor} />}
      </div>
      <div className={styles['class-card-footer']}>
        {currentClass?.startDate ? (
          <>
            <Svg icon={CalendarIcon} className={styles['class-card-footer-icon']} />
            <p>
              Starts on{' '}
              <span className={styles.date}>
                {formatDate(currentClass?.startDate, 'dd MMM, yyyy')}
              </span>
            </p>
          </>
        ) : (
          <>
            <Svg icon={ClockIcon} className={styles['class-card-footer-icon']} />
            <p>Self-paced</p>
          </>
        )}
      </div>
      <Link
        to={`/classes/${currentClass?.id}`}
        state={enrolled}
        className={styles['card-link-wrapper']}
      />
    </div>
  )
}

ClassCard.propTypes = {
  currentClass: classShape.isRequired,
  className: PropTypes.string,
  isSliderCard: PropTypes.bool,
}

ClassCard.defaultProps = {
  className: '',
  isSliderCard: false,
}

export default React.memo(ClassCard)
