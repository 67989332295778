import React from 'react'
import PropTypes from 'prop-types'

import Testimonial, { TestimonialTheme } from '_components/landing-components/testimonial'
import SectionTitle from '_components/landing-components/section-title'
import { prismicTitlePropType } from '_utils/proptypes'

import styles from './styles.css'

const TestimonialsSection = ({ slice, id }) => (
  <section className={styles['testimonials-section']} id={id}>
    <div className={styles['testimonials-content']}>
      {slice.sectionTitle && <SectionTitle title={slice.sectionTitle} className={styles.title} />}
      {slice.sectionDescription && <p className={styles.subtitle}>{slice.sectionDescription}</p>}
      {slice.items?.length > 0 && (
        <div className={styles['testimonials-grid']}>
          {slice.items.map(testimonial => (
            <Testimonial
              key={`testimonial-${testimonial.userName}`}
              testimonial={testimonial}
              theme={TestimonialTheme.CARD}
            />
          ))}
        </div>
      )}
    </div>
  </section>
)

TestimonialsSection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    sectionDescription: PropTypes.string,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        testimonial: PropTypes.string,
        userAvatar: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        }),
        userName: PropTypes.string,
        userJob: PropTypes.string,
      })
    ),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(TestimonialsSection)
