import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import { Link } from '@reach/router'
import moment from 'moment'
import Linkify from 'react-linkify'
import LazyLoad from 'react-lazyload'

import Like from '_components/like'
import { postShape } from '_utils/proptypes'
import { tagsRegex } from '_utils/constants'

import styles from './styles.css'

const PostContent = ({ post, authorName, shazam, isShazamLoading }) => {
  const renderCommentWithTags = useCallback(
    () => {
      const splittedComment = post.message.split(' ')

      return (
        <p className={styles.content}>
          {splittedComment.map(word => {
            if (tagsRegex.test(word) && word.indexOf('@') === 0) {
              return (
                <Link to={`/user/${word.split('@')[1]}`} className={styles.tag}>
                  {` ${word}`}
                </Link>
              )
            }
            return ` ${word}`
          })}
        </p>
      )
    },
    [post.message]
  )

  const onShazamClick = useCallback(
    () => {
      shazam({
        postId: post.id,
        shazamedByYou: post.shazamedByYou,
        isComment: false,
        shazams: post.shazamedByYou ? post.shazams - 1 : post.shazams + 1,
      })
    },
    [post.id, post.shazamedByYou, post.shazams, shazam]
  )

  return (
    <Linkify
      properties={{
        style: {
          color: '#7387ff',
          wordBreak: 'break-word',
        },
        target: '_blank',
      }}
    >
      <div className={styles.main}>
        {post.message && renderCommentWithTags()}
        <div className={styles.details}>
          <Like
            count={post.shazams}
            shazamedByYou={post.shazamedByYou}
            onClick={onShazamClick}
            isShazamLoading={isShazamLoading}
          />
          {moment(post.createdAt).isBefore(post.updatedAt, 'second') && (
            <>
              <div className={styles.dot} /> <p className={styles.edited}>Edited</p>
            </>
          )}
        </div>
        {!post.videoLink && !post.photo && <div className={styles.line} />}
        {post.videoLink && (
          <div className={styles.video}>
            <LazyLoad offset={200}>
              <ReactPlayer
                config={{ vimeo: { preload: true } }}
                url={post.videoLink}
                width="100%"
                controls
              />
            </LazyLoad>
          </div>
        )}
        {post.photo && (
          <div className={styles['photo-wrapper']}>
            <LazyLoad offset={200}>
              <img className={styles.photo} src={post.photo} alt={`Post of ${authorName}`} />
            </LazyLoad>
          </div>
        )}
      </div>
    </Linkify>
  )
}

PostContent.propTypes = {
  post: postShape.isRequired,
  authorName: PropTypes.string.isRequired,
  shazam: PropTypes.func.isRequired,
  isShazamLoading: PropTypes.bool,
}

PostContent.defaultProps = {
  isShazamLoading: false,
}

export default React.memo(PostContent)
