import * as alertService from '_services/alert'
import { defineAction } from '_utils/redux'

export const GET_ALERT = defineAction('GET_ALERT')
export const READ_ALERT = defineAction('READ_ALERT')

export const getAlert = (page = null) => (dispatch, getState) => {
  dispatch({
    type: GET_ALERT.ACTION,
    payload: alertService.getAlert(getState().user.get('authToken')),
    meta: {
      page,
    },
  })
}

export const readAlert = alertId => (dispatch, getState) => {
  dispatch({
    type: READ_ALERT.ACTION,
    payload: alertService.readAlert(getState().user.get('authToken'))(alertId),
  })
}
