import React, { useCallback, useReducer, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import CloseIcon from '_assets/icons/close.svg'
import Modal from '_components/modal'
import Button, { ButtonTheme } from '_components/landing-components/button'
import CheckboxGroup, { CHECKBOX_GROUP_VARIANT } from '_components/checkbox-group'
import ChipCheckboxGroup from '_components/chip-checkbox-group'
import useBodyOverflow from '_hooks/use-body-overflow'
import { COURSE_TYPES_INFO } from '_constants/course'
import IconButton from '_components/icon-button'
import { PACE_TYPES } from '_utils/constants'
import { FLAG_NEW_LANDING_PAGES } from '_config/environment'
import { CLASS_TYPE } from '_constants/class'
import SelectDropdown from '_components/select-dropdown'
import { getAvailableCoaches } from '_modules/public-coaches/actions'
import useWindowSize from '_hooks/use-window-size'
import {
  isPublicCoachesLoadingSelector,
  publicCoachesList,
} from '_modules/public-coaches/selectors'

import styles from './styles.css'
import { reducer, UPDATE_CATEGORY, UPDATE_TYPE, CLEAR_STATE, UPDATE_COACHES } from './reducer'

export const ALL_CLASSES = 'ALL'

const handleClassTypeFilter = () => {
  const options = {
    ALL: 'All',
    ...(FLAG_NEW_LANDING_PAGES ? PACE_TYPES : CLASS_TYPE),
  }

  const filter = {
    options,
    legend: 'Class',
  }

  return {
    filterType: Object.values(filter.options).map(item => ({
      name: FLAG_NEW_LANDING_PAGES ? item : item.name,
      id: FLAG_NEW_LANDING_PAGES ? item.toUpperCase().replace('-', '_') : item.slug,
    })),
    legend: filter.legend,
  }
}

// TODO: Fix initial state if filters already selected (from parent component)
// eslint-disable-next-line
const FiltersModal = ({ onClose, initialState, onApplyFilters, className }) => {
  const dispatch = useDispatch()
  useBodyOverflow(true)
  const classTypeFilter = handleClassTypeFilter()
  const { isMobile } = useWindowSize()
  const availableCoachesList = useSelector(publicCoachesList)
  const isPublicCoachesLoading = useSelector(isPublicCoachesLoadingSelector)

  const [state, localDispatch] = useReducer(reducer, initialState)

  const categoriesOptions = useMemo(
    () => [
      {
        id: 1,
        name: COURSE_TYPES_INFO.VISION_PLUS_GOALS.name,
        checked: state.categories.includes(COURSE_TYPES_INFO.VISION_PLUS_GOALS.name),
      },
      {
        id: 2,
        name: COURSE_TYPES_INFO.POWER_MY_FUTURE.name,
        checked: state.categories.includes(COURSE_TYPES_INFO.POWER_MY_FUTURE.name),
      },
      {
        id: 3,
        name: COURSE_TYPES_INFO.WHOLE_PROSPERITY.name,
        checked: state.categories.includes(COURSE_TYPES_INFO.WHOLE_PROSPERITY.name),
      },
      {
        id: 4,
        name: COURSE_TYPES_INFO.INTEGRATED_LEADERSHIP.name,
        checked: state.categories.includes(COURSE_TYPES_INFO.INTEGRATED_LEADERSHIP.name),
      },
    ],
    [state.categories]
  )

  const categoryCheckBoxGroupVariant = useMemo(
    () => isMobile ? CHECKBOX_GROUP_VARIANT.COLUMNS : CHECKBOX_GROUP_VARIANT.DOUBLE_COLUMNS,
    [isMobile]
  )

  const handleClassFilter = useCallback(event => {
    const {
      dataset: { id },
    } = event.target

    localDispatch({
      type: UPDATE_TYPE,
      payload: { id },
    })
  }, [])

  const onChangeCategory = useCallback(event => {
    localDispatch({
      type: UPDATE_CATEGORY,
      payload: event.target.name,
    })
  }, [])
  const onClearFilters = useCallback(() => {
    localDispatch({
      type: CLEAR_STATE,
    })
  }, [])

  const onGetCoaches = useCallback(
    value => {
      const queryParam = {
        name: value,
      }
      dispatch(getAvailableCoaches(queryParam))
    },
    [dispatch]
  )

  const onSelectDropdownItems = useCallback(payload => {
    localDispatch({
      type: UPDATE_COACHES,
      payload,
    })
  }, [])

  const onApplyFiltersClick = useCallback(
    () => {
      const formattedCategory = state.categories.join(',')
      const formattedTypes = state.types.join(',')

      const payload = {
        ...(formattedCategory.length > 0 && { categories: formattedCategory }),
        ...(!formattedTypes.includes(ALL_CLASSES) && {
          types: formattedTypes.includes(ALL_CLASSES) ? '' : formattedTypes,
        }),
        leaders: state.leaders,
      }

      onApplyFilters(payload)
      onClose(payload)
    },
    [onApplyFilters, onClose, state.categories, state.leaders, state.types]
  )

  const handleOnClose = useCallback(
    () => {
      const formattedCategory = state.categories.join(',')
      const formattedTypes = state.types.join(',')
      const formattedLeaders = state.leaders?.map(coach => coach.name).join(',')
      const payload = {
        categories: formattedCategory,
        types: formattedTypes.includes(ALL_CLASSES) ? ALL_CLASSES : formattedTypes,
        leaders: formattedLeaders,
      }
      onClose(payload)
    },
    [onClose, state]
  )

  return (
    <Modal isOpen onClose={handleOnClose} className={className}>
      <section className={styles.modal}>
        <div className={styles['modal-header']}>
          <h1 className={styles.title}>Filters</h1>
          <IconButton
            iconClassName={styles['close-icon']}
            icon={CloseIcon}
            onClick={handleOnClose}
            className={styles['close-button']}
          />
        </div>
        <div className={styles['modal-content']}>
          {/* TODO - Finish dropdown logic */}
          <SelectDropdown
            label="Coaches"
            onSelectValues={onSelectDropdownItems}
            onGetValues={onGetCoaches}
            selectedValues={state.leaders}
            valuesList={availableCoachesList}
            placeholder="Search for a coach"
            id="search-coach"
            className={styles['coach-filter']}
            isLoading={isPublicCoachesLoading}
          />

          <CheckboxGroup
            id="category-checkbox"
            optionsList={categoriesOptions}
            onChange={onChangeCategory}
            legend="Categories"
            variant={categoryCheckBoxGroupVariant}
            className={styles['category-filter']}
          />
          <ChipCheckboxGroup
            id="type-chips"
            legend="Type"
            onChange={handleClassFilter}
            optionsList={classTypeFilter.filterType}
            groupName="types"
            selectedValues={state.types}
            className={styles['type-filter']}
          />
        </div>
        <div className={styles['modal-action-buttons']}>
          <Button
            onClick={onClearFilters}
            theme={ButtonTheme.GHOST}
            className={styles['action-button']}
          >
            Clear
          </Button>
          <Button
            onClick={onApplyFiltersClick}
            theme={ButtonTheme.MALIBU}
            className={styles['action-button']}
          >
            Apply filters
          </Button>
        </div>
      </section>
    </Modal>
  )
}

FiltersModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  initialState: PropTypes.shape({
    search: PropTypes.string,
    order: PropTypes.shape({
      MOST_RECENT: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
      LEAST_RECENT: PropTypes.shape({
        label: PropTypes.string,
        value: PropTypes.string,
      }),
    }),
    leaders: PropTypes.arrayOf(PropTypes.string),
    categories: PropTypes.arrayOf(PropTypes.string),
    types: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  className: PropTypes.string,
}

FiltersModal.defaultProps = {
  className: '',
}

export default React.memo(FiltersModal)
