import * as suggestionService from '_services/suggestion'
import { defineAction } from '_utils/redux'

export const SUGGESTIONS = defineAction('SUGGESTIONS')

export const searchSuggestions = search => (dispatch, getState) =>
  dispatch({
    type: SUGGESTIONS.ACTION,
    payload: suggestionService.searchSuggestions(getState().user.authToken)(search),
  })
