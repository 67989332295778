import { Map, List } from 'immutable'
import { normalize } from 'normalizr'

import Class, { classSchema } from '_models/class'
import { createReducer } from '_utils/redux'
import { getPage } from '_utils/helpers'
import { GET_CATEGORY_AVAILABLE_CLASSES } from '_modules/public-categories/actions'
import { LOGOUT_USER } from '_modules/user/actions'

import { GET_AVAILABLE_CLASSES, GET_PUBLIC_CLASS } from './actions'

const INITIAL_STATE = Map({
  classes: Map(),
  search: Map({
    results: List(),
    count: undefined,
    next: undefined,
    previous: undefined,
  }),
})

export default createReducer(INITIAL_STATE, {
  [GET_AVAILABLE_CLASSES.FULFILLED]: (state, { payload, meta }) => {
    const normalizedClasses = normalize(payload.results, classSchema)
    const classes = normalizedClasses?.entities?.result
    const classIdList = List(normalizedClasses?.result)

    const nextPage = getPage(payload.next)
    const previousPage = getPage(payload.previous)

    if (meta.isInfiniteScroll) {
      return state
        .mergeIn(['classes'], classes)
        .mergeIn(['search', 'results'], classIdList)
        .setIn(['search', 'next'], nextPage)
        .setIn(['search', 'previous'], previousPage)
    }

    return state
      .mergeIn(['classes'], classes)
      .setIn(['search', 'results'], classIdList)
      .setIn(['search', 'count'], payload.count)
      .setIn(['search', 'next'], nextPage)
      .setIn(['search', 'previous'], previousPage)
  },

  [GET_PUBLIC_CLASS.FULFILLED]: (state, { payload }) => state.setIn(['classes', `${payload.id}`], new Class(payload)),

  [GET_CATEGORY_AVAILABLE_CLASSES.FULFILLED]: (state, { payload }) => {
    const classes = normalize(payload.results, classSchema)?.entities?.result

    return state.mergeIn(['classes'], classes)
  },
  [LOGOUT_USER]: state => {
    const currentClasses = state.get('classes')

    let newState = state
    currentClasses.forEach(currentClass => {
      newState = newState.setIn(['classes', `${currentClass.id}`, 'enrolled'], null)
    })

    return newState
  },
})
