import React, { useState, useEffect, useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import Confetti from 'react-dom-confetti'
import { useLocation } from '@reach/router'

import Button, { ButtonTheme } from '_components/button'
import CloseIcon from '_assets/icons/close.svg'
import Card, { CardSize } from '_components/card'
import { CONFETTI_CONFIG } from '_constants/config'

import styles from './styles.css'

const UserGoalModal = ({
  title,
  content,
  buttonLabel,
  onClose,
  goalId,
  closeOnActionClick,
  categoryId,
  onSubmit,
  hasConfetti,
}) => {
  const { pathname } = useLocation()

  const [isCelebrating, setCelebrating] = useState(false)

  const onActionClick = useCallback(
    () => {
      onSubmit(categoryId, goalId)
    },
    [categoryId, goalId, onSubmit]
  )
  const buttonAction = useMemo(
    () => {
      if (closeOnActionClick) {
        return {
          onClick: onClose,
        }
      }

      if (onSubmit) {
        return {
          onClick: onActionClick,
        }
      }

      return {
        to: `${pathname}/goals`,
        state: {
          goalId,
        },
      }
    },
    [closeOnActionClick, goalId, onActionClick, onClose, onSubmit, pathname]
  )

  useEffect(() => {
    setTimeout(() => {
      setCelebrating(true)
    }, 100)
  }, [])

  return (
    <Card size={CardSize.LARGE} className={styles.container}>
      <div className={styles.header}>
        <h1 className={styles.title}>{title}</h1>
        <Button
          className={styles.close}
          iconOnly
          icon={CloseIcon}
          onClick={onClose}
          label="Close modal"
        />
      </div>
      <p className={styles.content}>{content}</p>
      <div className={styles.submit}>
        <Button theme={ButtonTheme.PRIMARY} {...buttonAction} label={buttonLabel} />
        {hasConfetti && (
          <div className={styles.confetti}>
            <Confetti active={isCelebrating} config={CONFETTI_CONFIG} />
          </div>
        )}
      </div>
    </Card>
  )
}

UserGoalModal.propTypes = {
  buttonLabel: PropTypes.string,
  content: PropTypes.string,
  title: PropTypes.string,
  onClose: PropTypes.func,
  goalId: PropTypes.number.isRequired,
  categoryId: PropTypes.number.isRequired,
  closeOnActionClick: PropTypes.bool,
  onSubmit: PropTypes.func,
  hasConfetti: PropTypes.bool,
}

UserGoalModal.defaultProps = {
  buttonLabel: 'Remove goal',
  content:
    'Once a goal is removed, it can no longer be viewed, updated, or restored. Please confirm that you wish to remove it.',
  title: 'Remove goal',
  onClose: () => {},
  closeOnActionClick: false,
  onSubmit: null,
  hasConfetti: false,
}

export default UserGoalModal
