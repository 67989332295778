import { Map } from 'immutable'

import { ADD_NEW_CARD, LIST_CARDS } from './actions'

export const isAddingNewCardSelector = state => !!state.loading.get(ADD_NEW_CARD.ACTION)

export const addNewCardErrorSelector = state => state.error.get(ADD_NEW_CARD.ACTION, Map())

export const cardListSelector = state =>
  state.cards.reduce((acc, curr) => {
    acc.push(curr)
    return acc
  }, [])

export const isGettingCardListSelector = state => !!state.loading.get(LIST_CARDS.ACTION)

export const getCardListErrorSelector = state => state.error.get(LIST_CARDS.ACTION)
