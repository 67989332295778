import { createSelector } from 'reselect'
import { List } from 'immutable'

import { classesSelector } from '_modules/public-classes/selectors'

import { GET_CATEGORY_AVAILABLE_CLASSES } from './actions'

export const getPublicCategoriesSelector = state => state.publicCategories

export const publicCategorySelector = (state, categoryName) =>
  state.publicCategories.get(categoryName)

export const availableCategoryClassesSelector = createSelector(
  publicCategorySelector,
  classesSelector,
  (category, publicClasses) => {
    if (!category) {
      return List()
    }
    return category.getIn(['search', 'classes'])?.map(classId => publicClasses.get(`${classId}`))
  }
)

export const isgetListCategoryAvailableClassesLoadingSelector = state =>
  state.loading.get(GET_CATEGORY_AVAILABLE_CLASSES.ACTION)

export const getCategoryClassesTotalCountSelector = (state, courseName) =>
  state.publicCategories.getIn([`${courseName}`, 'totalAvailableClasses'])

export const categoryTypeAvailableClasses = createSelector(
  [getPublicCategoriesSelector, (state, courseName) => courseName],
  (publicCategories, courseName) => {
    const availableTypes = {
      coachLed: publicCategories.getIn([`${courseName}`, 'availableCoachLedClasses']),
      selfPaced: publicCategories.getIn([`${courseName}`, 'availableSelfPacedClasses']),
    }

    return availableTypes
  }
)
