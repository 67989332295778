import { Map, List } from 'immutable'

import Model from './model'

import { User } from '.'

class DirectMessage extends Model({
  user: new User(),
  lastMessage: Map(),
  messages: List(),
  read: undefined,
  count: undefined,
  next: undefined,
  previous: undefined,
}) {}

export default DirectMessage
