export const SEARCH_ORDER = {
  MOST_RECENT: {
    label: 'Most recent',
    value: 'most_recent',
  },
  LEAST_RECENT: {
    label: 'Least recent',
    value: 'least_recent',
  },
}

export const INITIAL_STATE = {
  search: '',
  order: SEARCH_ORDER.MOST_RECENT,
  leaders: [],
  categories: [],
  types: ['ALL'],
}

export const UPDATE_STATE = 'UPDATE_STATE'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE: {
      return { ...state, ...action.payload }
    }

    default:
      return state
  }
}
