export const formatPathname = pathname => {
  let strippedPathname = pathname.split('/')[1]
  if (pathname.includes('courses/')) {
    strippedPathname = pathname
      .split('/')
      .slice(-1)
      .pop()
  }

  const formatted = strippedPathname.replace(/-(.)/g, (match, group1) => group1.toUpperCase())
  return formatted
}
