import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import ArrowIcon from '_assets/icons/angle-down.svg'
import Svg from '_components/svg'

import styles from './styles.css'

export const ARROW_DIRECTION = {
  RIGHT: 'right',
  LEFT: 'left',
}

const SliderArrow = ({
  onClick,
  disabled,
  arrowDirection,
  className,
  shouldUseLibClassName,
  iconClassName,
}) => {
  const isArrowDisabled = useMemo(() => className.includes('slick-disabled'), [className])

  return (
    <button
      className={classnames(styles['custom-arrow'], styles[`${arrowDirection}-arrow`], {
        [className]: shouldUseLibClassName,
        [styles['arrow-disabled']]: isArrowDisabled,
        [styles.default]: shouldUseLibClassName,
      })}
      disabled={disabled}
      aria-label={arrowDirection === ARROW_DIRECTION.LEFT ? 'Previous' : 'Next'}
      onClick={onClick}
    >
      <Svg
        className={classnames(styles.icon, styles[arrowDirection], iconClassName)}
        icon={ArrowIcon}
      />
    </button>
  )
}

SliderArrow.propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  arrowDirection: PropTypes.oneOf(Object.values(ARROW_DIRECTION)),
  className: PropTypes.string,
  shouldUseLibClassName: PropTypes.bool,
  iconClassName: PropTypes.string,
}

SliderArrow.defaultProps = {
  onClick: () => {},
  disabled: false,
  arrowDirection: ARROW_DIRECTION.RIGHT,
  className: '',
  shouldUseLibClassName: false,
  iconClassName: '',
}

export default SliderArrow
