export const API_URL = process.env.REACT_APP_API_URL
export const SENTRY_DSN = process.env.REACT_APP_SENTRY_DSN
export const FACEBOOK_APP_ID = process.env.REACT_APP_FACEBOOK_APP_ID
export const GOOGLE_CLIENT_ID = process.env.REACT_APP_GOOGLE_CLIENT_ID
export const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY
export const WSS_URL = process.env.REACT_APP_WSS_URL || 'wss://api-staging.lightyear.co/ws'
export const GA_ID = process.env.REACT_APP_GA_ID
export const ZOOM_CLIENT_ID = process.env.REACT_APP_ZOOM_CLIENT_ID
export const FLAG_MULTIPLE_COACHES = process.env.REACT_APP_FLAG_MULTIPLE_COACHES === 'true'
export const FLAG_NEW_LANDING_PAGES = process.env.REACT_APP_FLAG_NEW_LANDING_PAGES === 'true'
export const FLAG_CLASS_HUB_DETAILS = process.env.REACT_APP_FLAG_CLASS_HUB_DETAILS === 'true'
export const FLAG_LOCAL_WAYPOINT = process.env.REACT_APP_FLAG_LOCAL_WAYPOINT === 'true'
