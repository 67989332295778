import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from '@reach/router'

import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import { postShape } from '_utils/proptypes'

import PostDropdown from '../options-dropdown'

import styles from './styles.css'

const PostHeader = ({ post, isOwnPost, onPostEdit, openModal, onFlagPost, removeHideOption }) => (
  <div className={styles.container}>
    <ProfileAvatar
      className={styles.avatar}
      username={post.author.username}
      src={post.author.photo}
      size={AvatarSize.SMALL}
    />
    <div className={styles.details}>
      <div className={styles['author-info']}>
        <Link className={styles.author} to={`/user/${post.author.username}`}>
          {post.author.name}
        </Link>
        <Link className={styles.username} to={`/user/${post.author.username}`}>
          @{post.author.username}
        </Link>
      </div>
      <p className={styles.date}>
        {moment(post.createdAt).format('MMMM D [at] LT')} - Your local date and time
      </p>
    </div>
    <PostDropdown
      className={styles.options}
      isOwnPost={isOwnPost}
      post={post}
      onPostEdit={onPostEdit}
      openModal={openModal}
      flagPost={onFlagPost}
      removeHideOption={removeHideOption}
    />
  </div>
)

PostHeader.propTypes = {
  post: postShape.isRequired,
  isOwnPost: PropTypes.bool,
  removeHideOption: PropTypes.bool,
  onPostEdit: PropTypes.func,
  openModal: PropTypes.func.isRequired,
  onFlagPost: PropTypes.func.isRequired,
}

PostHeader.defaultProps = {
  isOwnPost: false,
  removeHideOption: false,
  onPostEdit: () => {},
}

export default PostHeader
