import React, { useMemo } from 'react'
import classnames from 'classnames'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'

import Placeholder1 from '_assets/images/placeholder-1.png'
import ArrowIcon from '_assets/icons/arrow-right-24-px.svg'
import DonutChart from '_components/donut-chart'
import { CLASS_TYPE } from '_constants/class'
import { Class } from '_models/'
import { ASSIGNMENT_TYPE } from '_constants/assignment'

import styles from './styles.css'

const ClassCard = ({ course }) => {
  const classUrl = `/class/${course.id}`
  const formattedType = useMemo(
    () => Object.values(CLASS_TYPE).find(item => item.type === course.type)?.name,
    [course.type]
  )

  const nextAssignment = course.getIn(['nextAssignment', 'assignment'])

  const nextAssignmentProps = useMemo(
    () => {
      if (nextAssignment?.get('type') === ASSIGNMENT_TYPE.ASSIGNMENT) {
        return { to: `/class/${course.id}/assignment/${course.getIn(['nextAssignment', 'id'])}` }
      }

      return {
        to: `/class/${course.id}`,
        state: { scrollTo: course.getIn(['nextAssignment', 'id']) },
      }
    },
    [course, nextAssignment]
  )

  return (
    <article className={styles['class-wrapper']}>
      <Link to={classUrl} className={styles['content-wrapper']}>
        <h1 className={classnames(styles.text, styles['class-name'])}>{course.name}</h1>
        <p className={classnames(styles.text, styles['class-type'])}>{formattedType}</p>
        <p className={classnames(styles.text, styles['class-percentage'])}>
          <DonutChart
            className={styles.chart}
            trackClassName={styles['chart-track']}
            value={course.completePercentage}
            size={12}
            strokeWidth={2}
            showValue={false}
            keepSize
          />
          {course.completePercentage}% complete
        </p>
      </Link>
      <Link to={classUrl} className={styles['image-container']}>
        <img
          alt="Course Thumbnail"
          src={course.coverPhoto || Placeholder1}
          className={styles.image}
        />
      </Link>
      <Link {...nextAssignmentProps} className={styles['next-assignment-wrapper']}>
        <span className={styles.next}>Next Assignment</span>
        <span className={styles.assignment}>
          {course.getIn(['nextAssignment', 'assignment', 'name'])}
        </span>
        <svg className={styles['arrow-icon']} viewBox={ArrowIcon.viewBox} aria-hidden="true">
          <use xlinkHref={`#${ArrowIcon.id}`} />
        </svg>
      </Link>
    </article>
  )
}

ClassCard.propTypes = {
  course: PropTypes.instanceOf(Class).isRequired,
}
export default React.memo(ClassCard)
