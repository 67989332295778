import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'
import { Link } from '@reach/router'

import { onMouseDown } from '_utils/aria'
import { formatUrl, isExternalUrl } from '_utils/helpers'

import styles from './styles.css'

export const ButtonTheme = {
  DEFAULT: 'default',
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  ALERT: 'alert',
  WHITE_OUTLINE: 'white-outline',
  SECONDARY_TRANSPARENT_BACKGROUND: 'secondary-transparent-background',
  TRANSPARENT_BACKGROUND_MALIBU_TEXT: 'transparent-background-malibu-text',
  TRANSPARENT_BACKGROUND: 'transparent-background',
}

export const ButtonSize = {
  SMALL: 'small',
  NORMAL: 'normal',
}

class Button extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    theme: PropTypes.oneOf(Object.keys(ButtonTheme).map(key => ButtonTheme[key])),
    size: PropTypes.oneOf(Object.keys(ButtonSize).map(key => ButtonSize[key])),
    // eslint-disable-next-line react/forbid-prop-types
    icon: PropTypes.object,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    loading: PropTypes.bool,
    iconOnly: PropTypes.bool,
    tooltip: PropTypes.bool,
    type: PropTypes.string,
    uploadImage: PropTypes.bool,
    onUploadImage: PropTypes.func,
    to: PropTypes.string,
    state: PropTypes.shape(),
    externalLink: PropTypes.string,
  }

  static defaultProps = {
    className: '',
    theme: ButtonTheme.DEFAULT,
    size: ButtonSize.NORMAL,
    icon: null,
    loading: false,
    onClick: () => {},
    disabled: false,
    iconOnly: false,
    type: 'button',
    tooltip: false,
    uploadImage: false,
    onUploadImage: () => {},
    to: '',
    state: {},
    externalLink: '',
  }

  state = {
    fileRef: undefined,
  }

  componentDidUpdate(prevProps) {
    if (prevProps?.label?.length > 0) {
      ReactTooltip.rebuild()
    }
  }

  onClick = event => {
    if (this.props.uploadImage) {
      this.selectImage()
    }
    this.props.onClick(event)
  }

  setFileRef = ref => {
    this.setState({
      fileRef: ref,
    })
  }

  selectImage = () => {
    this.state.fileRef.click()
  }

  renderContent = (isLoading, iconOnly, icon, label, uploadImage) => {
    const extensions = ['.jpg', '.jpeg', '.png', '.bmp', '.gif']
    if (isLoading) {
      return <div className={styles.loading} />
    }

    if (uploadImage) {
      return (
        <>
          <svg aria-label="Button icon" role="img" viewBox={icon.viewBox}>
            <use xlinkHref={`#${icon.id}`} />
          </svg>
          <input
            type="file"
            accept={extensions.toString()}
            ref={this.setFileRef}
            className={styles.input}
            onChange={this.props.onUploadImage}
          />
        </>
      )
    }

    if (iconOnly) {
      return (
        <svg aria-label="Button icon" role="img" viewBox={icon.viewBox}>
          <use xlinkHref={`#${icon.id}`} />
        </svg>
      )
    }
    return (
      <>
        {icon && (
          <svg aria-label="Button icon" role="img" viewBox={icon.viewBox} className={styles.icon}>
            <use xlinkHref={`#${icon.id}`} />
          </svg>
        )}
        <span>{label}</span>
      </>
    )
  }

  renderElement = () => {
    const {
      theme,
      size,
      className,
      icon,
      label,
      loading,
      disabled,
      iconOnly,
      type,
      uploadImage,
      tooltip,
      to,
      state,
      onClick,
    } = this.props

    if (to) {
      const formattedUrl = formatUrl(to)

      if (isExternalUrl(formattedUrl)) {
        return (
          <a
            href={formattedUrl}
            target="_blank"
            rel="noopener noreferrer"
            disabled={loading || disabled}
            onClick={onClick}
            className={classnames(styles.button, styles[theme], styles[size], className, {
              [styles['icon-only']]: iconOnly,
              [styles['has-icon']]: icon,
              [styles['upload-image']]: uploadImage,
              [styles.disabled]: disabled,
            })}
            aria-label={`Button ${label}`}
            {...(tooltip ? { 'data-tip': label } : null)}
          >
            {this.renderContent(loading, iconOnly, icon, label, uploadImage)}
          </a>
        )
      }

      return (
        <Link
          to={formattedUrl}
          state={state}
          disabled={loading || disabled}
          onClick={onClick}
          className={classnames(styles.button, styles[theme], styles[size], className, {
            [styles['icon-only']]: iconOnly,
            [styles['has-icon']]: icon,
            [styles['upload-image']]: uploadImage,
            [styles.disabled]: disabled,
          })}
          aria-label={`Button ${label}`}
          {...(tooltip ? { 'data-tip': label } : null)}
        >
          {this.renderContent(loading, iconOnly, icon, label, uploadImage)}
        </Link>
      )
    }
    return (
      <button
        disabled={loading || disabled}
        type={type}
        className={classnames(styles.button, styles[theme], styles[size], className, {
          [styles['icon-only']]: iconOnly,
          [styles['has-icon']]: icon,
          [styles['upload-image']]: uploadImage,
          [styles.disabled]: disabled,
        })}
        onClick={this.onClick}
        onMouseDown={onMouseDown}
        aria-label={`Button ${label}`}
        {...(tooltip ? { 'data-tip': label } : null)}
      >
        {this.renderContent(loading, iconOnly, icon, label, uploadImage)}
      </button>
    )
  }

  render() {
    const { tooltip } = this.props

    return (
      <>
        {tooltip && (
          <ReactTooltip className={styles.tooltip} place="top" type="dark" effect="solid" />
        )}
        {this.renderElement()}
      </>
    )
  }
}

export default Button
