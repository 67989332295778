import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'
import { ClipLoader } from 'react-spinners'

import { LoadingColor } from '_utils/constants'
import DefaultAvatar from '_assets/icons/empty-photo.svg'
import RoleSticker, { RoleStickerSize } from '_components/role-sticker'

import styles from './styles.css'

export const AvatarSize = {
  EXTRA_SMALL: 'extra-small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  HEADER: 'header',
}

const getRoleStickerSize = size => {
  if (size === AvatarSize.EXTRA_SMALL) {
    return RoleStickerSize.EXTRA_SMALL
  }
  if (size === AvatarSize.SMALL) {
    return RoleStickerSize.SMALL
  }
  if (size === AvatarSize.MEDIUM) {
    return RoleStickerSize.MEDIUM
  }

  return RoleStickerSize.LARGE
}

const profileLoading = () => (
  <div className={styles.loading}>
    <ClipLoader color={LoadingColor} />
  </div>
)

const ProfileAvatar = ({
  className,
  avatarClassName,
  username,
  src,
  roleType,
  size,
  isResponsive,
  disabledLink,
  isLoading,
}) => {
  const avatarContent = (
    <div className={styles['avatar-wrapper']}>
      {isLoading && profileLoading()}
      {src ? (
        <img
          src={src}
          alt="User avatar"
          className={classnames(styles.avatar, styles[size], avatarClassName, {
            [styles.responsive]: isResponsive,
          })}
        />
      ) : (
        <svg
          aria-label="User avatar"
          role="img"
          viewBox={DefaultAvatar.viewBox}
          className={classnames(styles.avatar, styles[size], avatarClassName, {
            [styles.responsive]: isResponsive,
          })}
        >
          <use xlinkHref={`#${DefaultAvatar.id}`} />
        </svg>
      )}
      {roleType && (
        <RoleSticker
          className={styles['role-sticker']}
          size={getRoleStickerSize(size)}
          type={roleType}
          isResponsive={isResponsive}
        />
      )}
    </div>
  )

  return (
    <div className={classnames(styles.container, className)}>
      {username && !disabledLink ? (
        <Link to={`/user/${username}`} className={styles.link}>
          {avatarContent}
        </Link>
      ) : (
        avatarContent
      )}
    </div>
  )
}

ProfileAvatar.propTypes = {
  className: PropTypes.string,
  avatarClassName: PropTypes.string,
  username: PropTypes.string,
  src: PropTypes.string,
  size: PropTypes.string,
  roleType: PropTypes.string,
  isResponsive: PropTypes.bool,
  disabledLink: PropTypes.bool,
  isLoading: PropTypes.bool,
}

ProfileAvatar.defaultProps = {
  className: '',
  avatarClassName: '',
  username: undefined,
  src: null,
  roleType: '',
  size: AvatarSize.MEDIUM,
  isResponsive: false,
  disabledLink: false,
  isLoading: false,
}

export default ProfileAvatar
