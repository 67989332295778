import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const StatusIcon = ({ className, isOnline }) => (
  <div className={classnames(styles.status, className, { [styles.online]: isOnline })} />
)

StatusIcon.propTypes = {
  className: PropTypes.string,
  isOnline: PropTypes.bool,
}

StatusIcon.defaultProps = {
  className: '',
  isOnline: false,
}

export default React.memo(StatusIcon)
