import React from 'react'
import PropTypes from 'prop-types'

import Card, { CardSize } from '_components/card'
import Button, { ButtonTheme } from '_components/button'
import CloseIcon from '_assets/icons/close.svg'
import Modal from '_components/modal'

import styles from './styles.css'

const ModalCard = ({
  onCloseButton,
  title,
  children,
  buttonLabel,
  onButtonClick,
  isOpen,
  onClose,
  className,
  isLoading,
  showFooter,
}) => (
  <Modal isOpen={isOpen} onClose={onClose} className={className}>
    <Card size={CardSize.LARGE} className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        <Button
          className={styles.close}
          iconOnly
          icon={CloseIcon}
          onClick={onCloseButton}
          label="Close modal button"
        />
      </div>
      {children}
      {showFooter && (
        <div className={styles.submit}>
          <Button
            theme={ButtonTheme.PRIMARY}
            label={buttonLabel}
            onClick={onButtonClick}
            loading={isLoading}
          />
        </div>
      )}
    </Card>
  </Modal>
)

ModalCard.propTypes = {
  onCloseButton: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  children: PropTypes.node,
  buttonLabel: PropTypes.string.isRequired,
  onButtonClick: PropTypes.func,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  showFooter: PropTypes.bool,
}

ModalCard.defaultProps = {
  children: null,
  onButtonClick: () => {},
  isOpen: false,
  onClose: () => {},
  className: '',
  isLoading: false,
  showFooter: true,
}

export default React.memo(ModalCard)
