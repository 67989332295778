import * as publicCoachesService from '_services/public-coaches'
import { defineAction } from '_utils/redux'

export const GET_PUBLIC_COACHES = defineAction('GET_PUBLIC_COACHES')

export const getAvailableCoaches = params => (dispatch, getState) =>
  dispatch({
    type: GET_PUBLIC_COACHES.ACTION,
    payload: publicCoachesService.getAvailableCoaches(getState().user.get('authToken'), params),
  })
