import React, { useReducer, useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Modal from '_components/modal'
import useBodyOverflow from '_hooks/use-body-overflow'
import CloseIcon from '_assets/icons/close.svg'
import Input from '_components/input'
import Button from '_components/landing-components/button'
import RocketIcon from '_assets/icons/rocket-icon.svg'
import { validateEmail } from '_utils/helpers'
import ErrorTooltip from '_components/error-tooltip'
import { getInTouch } from '_services/company'

import reducer, { INITIAL_STATE, INPUT_CHANGE, CLEAR_STATE } from './reducer'
import styles from './styles.css'

const FORM_STEP = 1
const CONFIRMATION_STEP = 2

const GetInTouchModal = ({ onClose }) => {
  useBodyOverflow(true)

  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const [step, setStep] = useState(FORM_STEP)
  const [requestError, setRequestError] = useState('')
  const [isLoading, setIsLoading] = useState(false)

  const validateForm = useCallback(
    () => {
      let isValid = true
      const { error, ...fieldsToValidate } = state

      Object.keys(fieldsToValidate).forEach(fieldName => {
        if (fieldName === 'email' && !validateEmail(state.email)) {
          isValid = false
          dispatch({
            type: INPUT_CHANGE,
            name: fieldName,
            error: 'Please insert a valid email.',
          })
        } else if (!state[fieldName]) {
          isValid = false
          dispatch({
            type: INPUT_CHANGE,
            name: fieldName,
            error: 'This field is required.',
          })
        }
      })
      return isValid
    },
    [state]
  )

  const handleInputChange = useCallback(event => {
    setRequestError('')
    dispatch({
      type: INPUT_CHANGE,
      name: event.target.name,
      value: event.target.value,
      error: '',
    })
  }, [])

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault()
      setRequestError('')
      if (!validateForm()) {
        return
      }

      const { firstName, lastName, email, business, message } = state
      const payload = {
        first_name: firstName,
        last_name: lastName,
        email,
        business,
        message,
      }

      try {
        setIsLoading(true)
        await getInTouch(payload)
        setStep(CONFIRMATION_STEP)
        dispatch({
          type: CLEAR_STATE,
        })
        setIsLoading(false)
      } catch (e) {
        const msg = e[Object.keys(e)[0]]
        setRequestError(msg)
        setIsLoading(false)
      }
    },
    [state, validateForm]
  )

  const renderHeader = useMemo(
    () => {
      if (step === FORM_STEP) {
        return <h1 className={styles.title}>Get in touch with Lightyear</h1>
      }

      return (
        <svg aria-hidden="true" viewBox={RocketIcon.viewBox} className={styles.icon}>
          <use xlinkHref={`#${RocketIcon.id}`} />
        </svg>
      )
    },
    [step]
  )

  const renderModalContent = useMemo(
    () => {
      if (step === FORM_STEP) {
        return (
          <form onSubmit={handleSubmit} className={styles.form}>
            <Input
              onChange={handleInputChange}
              inputClassName={styles.input}
              value={state.firstName}
              name="firstName"
              placeholder="First name"
              errorMessage={state.errors.firstName}
            />
            <Input
              onChange={handleInputChange}
              inputClassName={styles.input}
              value={state.lastName}
              name="lastName"
              placeholder="Last name"
              errorMessage={state.errors.lastName}
            />
            <Input
              onChange={handleInputChange}
              inputClassName={styles.input}
              value={state.email}
              type="email"
              name="email"
              placeholder="Email address"
              errorMessage={state.errors.email}
            />
            <Input
              onChange={handleInputChange}
              inputClassName={styles.input}
              value={state.business}
              name="business"
              placeholder="Business"
              errorMessage={state.errors.business}
            />
            <div className={styles['tooltip-wrapper']}>
              {!!state.errors.message && (
                <ErrorTooltip className={styles['error-icon']} message={state.errors.message} />
              )}
              <textarea
                className={classnames(styles.message, {
                  [styles.error]: !!state.errors.message,
                })}
                name="message"
                placeholder="Message"
                onChange={handleInputChange}
                value={state.message}
              />
            </div>
            {requestError && <p className={styles.error}>{requestError}</p>}
            <Button type="submit" disabled={isLoading}>
              Submit
            </Button>
          </form>
        )
      }

      return (
        <div className={styles.wrapper}>
          <h1 className={styles.title}>Thank you for getting in touch with Lightyear!</h1>
          <p className={styles.paragraph}>
            Your message was sent successfully, we will reply soon, keep an eye on your email.
          </p>
        </div>
      )
    },
    [
      handleInputChange,
      handleSubmit,
      isLoading,
      requestError,
      state.business,
      state.email,
      state.errors.business,
      state.errors.email,
      state.errors.firstName,
      state.errors.lastName,
      state.errors.message,
      state.firstName,
      state.lastName,
      state.message,
      step,
    ]
  )

  return (
    <Modal isOpen onClose={onClose} className={styles.modal}>
      <div className={styles['modal-content']}>
        <header>
          <div className={styles.header}>
            {renderHeader}
            <button
              type="button"
              className={styles['close-button']}
              onClick={onClose}
              aria-label="Close Get In Touch"
            >
              <svg className={styles['close-icon']} viewBox={CloseIcon.viewBox} aria-hidden="true">
                <use xlinkHref={`#${CloseIcon.id}`} />
              </svg>
            </button>
          </div>
        </header>
        {renderModalContent}
      </div>
    </Modal>
  )
}

GetInTouchModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default React.memo(GetInTouchModal)
