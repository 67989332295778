export const INITIAL_STATE = {
  leaders: [],
  categories: [],
  types: ['ALL'],
}

export const UPDATE_STATE = 'UPDATE_STATE'
export const UPDATE_CATEGORY = 'UPDATE_CATEGORY'
export const UPDATE_TYPE = 'UPDATE_TYPE'
export const CLEAR_STATE = 'CLEAR_STATE'
export const UPDATE_COACHES = 'UPDATE_COACHES'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE: {
      return {
        ...state,
        ...action?.payload,
      }
    }

    case UPDATE_CATEGORY: {
      const { categories } = state
      const selectedCategory = action.payload

      const payload = {
        ...state,
      }

      const newCategories = [...categories]
      if (categories.includes(selectedCategory)) {
        return {
          ...payload,
          categories: newCategories.filter(currentCategory => currentCategory !== selectedCategory),
        }
      }

      newCategories.push(selectedCategory)
      return {
        ...payload,
        categories: newCategories,
      }
    }
    case UPDATE_TYPE: {
      const { types } = state
      const { id } = action.payload

      const newPayload = [...types]
      if (id === 'ALL') {
        return {
          ...state,
          types: ['ALL'],
        }
      }

      if (newPayload.includes(id)) {
        return {
          ...state,
          types: newPayload.filter(currentType => currentType !== id),
        }
      }

      newPayload.push(id)
      const updatePayload = newPayload.filter(currentType => currentType !== 'ALL')
      return {
        ...state,
        types: updatePayload,
      }
    }

    case UPDATE_COACHES: {
      return {
        ...state,
        leaders: action.payload,
      }
    }

    case CLEAR_STATE: {
      return INITIAL_STATE
    }
    default:
      return state
  }
}
