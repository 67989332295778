import { OrderedMap, Map, List, fromJS } from 'immutable'
import humps from 'humps'

import { Course, Class } from '_models/'
import { createReducer } from '_utils/redux'
import { getPage } from '_utils/helpers'

import {
  GET_COMPANY_COURSES,
  GET_PUBLIC_COURSES,
  GET_COURSE,
  FAVORITE_COURSE,
  UNFAVORITE_COURSE,
  GET_CATEGORIES,
  GET_COMPANY_CLASSES,
  GET_COMPANY_CLASS_BY_ID,
} from './actions'

const INITIAL_STATE = Map({
  categories: List(),
  discoverCourses: Map({
    count: null,
    next: null,
    previous: null,
    courses: OrderedMap(),
  }),
  companyCourses: Map({
    count: null,
    next: null,
    previous: null,
    courses: OrderedMap(),
  }),
})

const transformOrderedMap = data => OrderedMap(data.map(course => [course.id, new Course(course)]))

const transformClassOrderedMap = data =>
  OrderedMap(data.map(course => [course.id, new Class(course)]))

const courses = createReducer(INITIAL_STATE, {
  [GET_COMPANY_COURSES.FULFILLED]: (state, { payload }) =>
    state.set(
      'companyCourses',
      Map({
        count: payload.count,
        next: payload.next ? getPage(payload.next) : undefined,
        previous: payload.previous ? getPage(payload.previous) : undefined,
        courses: transformOrderedMap(payload.results),
      })
    ),

  [GET_PUBLIC_COURSES.FULFILLED]: (state, { payload }) =>
    state.set(
      'discoverCourses',
      Map({
        count: payload.count,
        next: payload.next ? getPage(payload.next) : undefined,
        previous: payload.previous ? getPage(payload.previous) : undefined,
        courses: transformOrderedMap(payload.results),
      })
    ),

  [GET_COURSE.FULFILLED]: (state, { payload, meta }) => {
    const newCourse = new Course(payload)

    if (newCourse.isBothCompanyAndCategory) {
      return state
        .setIn(['companyCourses', 'courses', payload.id], newCourse)
        .setIn(['discoverCourses', 'courses', payload.id], newCourse)
    }

    if (meta.belongsToACompany && payload.company) {
      return state.setIn(['companyCourses', 'courses', payload.id], newCourse)
    }

    return state.setIn(['discoverCourses', 'courses', payload.id], newCourse)
  },

  [FAVORITE_COURSE.FULFILLED]: (state, { meta }) => {
    if (meta.isCompany) {
      return state.setIn(['companyCourses', 'courses', meta.courseId, 'favorite'], true)
    }
    return state.setIn(['discoverCourses', 'courses', meta.courseId, 'favorite'], true)
  },

  [UNFAVORITE_COURSE.FULFILLED]: (state, { meta }) => {
    if (meta.isCompany) {
      return state.setIn(['companyCourses', 'courses', meta.courseId, 'favorite'], false)
    }
    return state.setIn(['discoverCourses', 'courses', meta.courseId, 'favorite'], false)
  },

  [GET_CATEGORIES.FULFILLED]: (state, { payload }) => state.set('categories', fromJS(payload)),

  [GET_COMPANY_CLASSES.FULFILLED]: (state, { payload }) =>
    state.set(
      'companyCourses',
      Map({
        count: payload.count,
        next: payload.next ? getPage(payload.next) : undefined,
        previous: payload.previous ? getPage(payload.previous) : undefined,
        courses: transformClassOrderedMap(payload.results),
      })
    ),

  [GET_COMPANY_CLASS_BY_ID.FULFILLED]: (state, { payload }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    const newCourse = new Class(camelizedPayload)

    return state.setIn(['companyCourses', 'courses', camelizedPayload.id], newCourse)
  },
})

export default courses
