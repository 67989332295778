import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

const SignInFooter = ({
  loading,
  disabled,
  buttonLabel,
  moreDetails,
  to,
  linkLabel,
  show,
  redirectTo,
}) => {
  const redirectState = useMemo(
    () => ({
      redirectTo,
    }),
    [redirectTo]
  )

  return (
    <>
      <Button
        disabled={disabled}
        loading={loading}
        className={styles['sign-button']}
        theme={ButtonTheme.DEFAULT}
        label={buttonLabel}
        type="submit"
      />

      {show && (
        <>
          <p className={styles['more-details']}>
            {moreDetails}{' '}
            <Link className={styles.link} to={to} state={redirectState}>
              {linkLabel}
            </Link>
          </p>

          <p className={styles['more-details']}>
            <Link className={styles.link} to="/forgot-password">
              Forgot password?
            </Link>
          </p>
        </>
      )}
    </>
  )
}

SignInFooter.propTypes = {
  show: PropTypes.bool,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  buttonLabel: PropTypes.string,
  moreDetails: PropTypes.string,
  linkLabel: PropTypes.string,
  to: PropTypes.string,
  redirectTo: PropTypes.string,
}

SignInFooter.defaultProps = {
  show: true,
  loading: false,
  disabled: false,
  buttonLabel: '',
  moreDetails: '',
  linkLabel: '',
  to: '',
  redirectTo: '',
}

export default SignInFooter
