export const INITIAL_STATE = {
  name: '',
  cardNumber: '',
  cardExpiry: '',
  cardCvc: '',
  errors: {
    fullName: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  },
}

export const ACTION = {
  INPUT_CHANGE: 'INPUT_CHANGE',
  RESET: 'RESET',
  RESET_ERRORS: 'RESET_ERRORS',
}

export const reducer = (state, action) => {
  switch (action.type) {
    case ACTION.INPUT_CHANGE: {
      return {
        ...state,
        [action.name]: action.value,
        errors: {
          ...state.errors,
          [action.name]: action.error,
        },
      }
    }
    case ACTION.RESET: {
      return INITIAL_STATE
    }
    case ACTION.RESET_ERRORS:
      return { ...state, errors: INITIAL_STATE.errors }

    default:
      return state
  }
}
