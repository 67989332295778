import React, { Fragment, useState, useCallback, useMemo, useEffect, useReducer } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { navigate, useLocation } from '@reach/router'
import { Map } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { toast } from 'react-toastify'
import download from 'downloadjs'

import Toastr, { ToastrTheme } from '_components/toastr'
import PROFILE_ACTIONS from '_modules/profile/actions'
import Button, { ButtonTheme, ButtonSize } from '_components/button'
import Tabs from '_components/tabs'
import UserGoalInformation from '_components/user-goal-information'
import Modal from '_components/modal'
import ManageGoalCategories from '_views/all-goals/manage-goal-categories'
import AddOrEditGoals from '_views/all-goals/add-edit-goals'
import UserGoalModal from '_components/user-goal-modal'
import { goalCategoryShape } from '_utils/proptypes'
import { usePrevious } from '_utils/hooks'
import { exportGoals } from '_services/user'
import { shazamGoal, unshazamGoal, SHAZAM_GOAL, UNSHAZAM_GOAL } from '_modules/user/actions'

import styles from './styles.css'

export const GoalsType = {
  PERSONAL: 'PERSONAL',
  HEALTH: 'HEALTH',
  CAREER: 'CAREER',
}

const {
  CREATE_GOAL_CATEGORY,
  UPDATE_GOAL_CATEGORY,
  DELETE_GOAL_CATEGORY,
  CREATE_GOAL,
  UPDATE_GOAL,
  DELETE_GOAL,
  createGoalCategory,
  updateGoalCategory,
  deleteGoalCategory,
  createGoal,
  updateGoal,
  deleteGoal,
} = PROFILE_ACTIONS

const mapStateToProps = ({ loading, error, user }) => ({
  isUpdatingCategory:
    !!loading.get(CREATE_GOAL_CATEGORY.ACTION) ||
    !!loading.get(UPDATE_GOAL_CATEGORY.ACTION) ||
    !!loading.get(DELETE_GOAL_CATEGORY.ACTION),
  updatingCategoryError:
    error.get(CREATE_GOAL_CATEGORY.ACTION) ||
    error.get(UPDATE_GOAL_CATEGORY.ACTION) ||
    error.get(DELETE_GOAL_CATEGORY.ACTION),
  isUpdatingGoal:
    !!loading.get(CREATE_GOAL.ACTION) ||
    !!loading.get(UPDATE_GOAL.ACTION) ||
    !!loading.get(DELETE_GOAL.ACTION),
  updatingGoalError:
    error.get(CREATE_GOAL.ACTION) || error.get(UPDATE_GOAL.ACTION) || error.get(DELETE_GOAL.ACTION),
  authToken: user.authToken,
  isShazamLoading: !!loading.get(SHAZAM_GOAL.ACTION) || !!loading.get(UNSHAZAM_GOAL.ACTION),
})

const mapDispatchToProps = {
  newGoalCategory: createGoalCategory,
  editGoalCategory: updateGoalCategory,
  removeGoalCategory: deleteGoalCategory,
  newGoal: createGoal,
  editGoal: updateGoal,
  removeGoal: deleteGoal,
  shazamUserGoal: shazamGoal,
  unshazamUserGoal: unshazamGoal,
}

const initialState = {
  modalType: null,
  editedGoal: null,
  title: '',
  content: '',
  buttonLabel: '',
}
const CLOSE_MODAL = 'CLOSE_MODAL'
const RESET = 'RESET'
const MODAL_TYPE = {
  CATEGORIES: 'CATEGORIES',
  REMOVE: 'REMOVE',
  COMPLETE: 'COMPLETE',
  UNCOMPLETE: 'UNCOMPLETE',
  EDIT: 'EDIT',
  ADD: 'ADD',
}

const reducer = (state, action) => {
  switch (action.type) {
    case MODAL_TYPE.CATEGORIES:
      return { ...state, modalType: action.modalType }
    case MODAL_TYPE.REMOVE:
      return {
        ...state,
        modalType: action.modalType,
        submit: action.onRemove,
        goalCategoryId: action.goalCategoryId,
        editedGoal: action.editedGoal,
        title: 'Remove goal',
        buttonLabel: 'Remove goal',
        content:
          'Once a goal is removed, it can no longer be viewed, updated, or restored. Please confirm that you wish to remove it.',
      }
    case MODAL_TYPE.COMPLETE:
      return {
        ...state,
        modalType: action.modalType,
        submit: action.onComplete,
        goalCategoryId: action.goalCategoryId,
        editedGoal: action.editedGoal,
        title: 'WOOHOO!!',
        buttonLabel: 'View all completed goals',
        content:
          "Goal celebration time! You've completed a goal. You are creating your future, now! Take a moment to congratulate yourself and reflect on how far you've come. We are here for you every step of the way \uD83D\uDE0A.",
      }
    case MODAL_TYPE.UNCOMPLETE:
      return {
        ...state,
        modalType: action.modalType,
        submit: action.onUncomplete,
        goalCategoryId: action.goalCategoryId,
        editedGoal: action.editedGoal,
        title: 'MARK GOAL AS UNCOMPLETE',
        buttonLabel: 'Mark as uncomplete',
        content: 'Do you wish to mark goal as uncomplete?',
      }
    case MODAL_TYPE.EDIT:
      return {
        ...state,
        modalType: action.modalType,
        editedGoal: action.editedGoal,
        goalCategoryId: action.goalCategoryId,
      }
    case MODAL_TYPE.ADD:
      return {
        ...state,
        modalType: action.modalType,
        editGoal: null,
        goalCategoryId: action.goalCategoryId,
      }
    case CLOSE_MODAL: {
      return { ...state, modalType: null, editedGoal: null }
    }
    case RESET: {
      return initialState
    }
    default:
      return state
  }
}

const Goals = ({
  className,
  goalCategories,
  username,
  myProfile,
  newGoalCategory,
  editGoalCategory,
  removeGoalCategory,
  newGoal,
  editGoal,
  removeGoal,
  isUpdatingCategory,
  updatingCategoryError,
  isUpdatingGoal,
  updatingGoalError,
  authToken,
  shazamUserGoal,
  unshazamUserGoal,
  isShazamLoading,
  isSubscriptionExpired,
}) => {
  const prevCategoryLoading = usePrevious(isUpdatingCategory)
  const prevGoalLoading = usePrevious(isUpdatingGoal)
  const [activeTab, setActiveTab] = useState(GoalsType.PERSONAL)
  const [state, dispatch] = useReducer(reducer, initialState)
  const { pathname } = useLocation()

  const onCloseModal = useCallback(() => dispatch({ type: CLOSE_MODAL }), [])

  useEffect(
    () => {
      if (updatingCategoryError.size !== 0) {
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content={
              updatingCategoryError.first()
                ? updatingCategoryError.first?.()
                : 'Something went wrong, please try again later.'
            }
          />
        )
        return
      }
      if (prevCategoryLoading && !isUpdatingCategory && updatingCategoryError.size === 0) {
        toast(<Toastr theme={ToastrTheme.SUCCESS} content="Goal categories updated successfully" />)
        onCloseModal()
      }
    },
    [
      isUpdatingCategory,
      onCloseModal,
      prevCategoryLoading,
      updatingCategoryError,
      updatingCategoryError.size,
    ]
  )

  const onRemoveSubmit = useCallback((categoryId, goalId) => removeGoal(categoryId, goalId), [
    removeGoal,
  ])

  const onCompleteSubmit = useCallback(
    (categoryId, goalId) => editGoal(categoryId, goalId, { completed: true }),
    [editGoal]
  )

  const onUncompleteSubmit = useCallback(
    (categoryId, goalId) => editGoal(categoryId, goalId, { completed: false }),
    [editGoal]
  )

  const toggleModal = useCallback(
    (modalType, goalCategoryId, goal = null) => event => {
      event.preventDefault()
      dispatch({
        type: modalType,
        modalType,
        onUncomplete: onUncompleteSubmit,
        onComplete: onCompleteSubmit,
        onRemove: onRemoveSubmit,
        editedGoal: goal,
        goalCategoryId,
      })
    },
    [onCompleteSubmit, onRemoveSubmit, onUncompleteSubmit]
  )

  const onButtonClick = useCallback(
    () => {
      navigate([`/user/${username}/goals`])
    },
    [username]
  )

  const onTabClick = useCallback(tab => setActiveTab(tab), [])

  const filteredCategory = useMemo(() => goalCategories.find(goal => goal.title === activeTab), [
    activeTab,
    goalCategories,
  ])

  useEffect(
    () => {
      if (updatingGoalError.size !== 0) {
        toast(<Toastr theme={ToastrTheme.ERROR} content={updatingGoalError.first()} />)
        return
      }
      if (prevGoalLoading && !isUpdatingGoal && updatingGoalError.size === 0) {
        toast(<Toastr theme={ToastrTheme.SUCCESS} content="Goal updated successfully" />)

        if (state.modalType !== MODAL_TYPE.COMPLETE) {
          onCloseModal()
        }
      }
    },
    [
      filteredCategory,
      isUpdatingGoal,
      onCloseModal,
      onCompleteSubmit,
      onRemoveSubmit,
      onUncompleteSubmit,
      pathname,
      prevGoalLoading,
      state.editedGoal,
      state.modalType,
      updatingGoalError,
    ]
  )

  const addOrEditGoal = useCallback(
    (categoryId, data, edit = false) => {
      if (edit) {
        editGoal(categoryId, data.id, data)
        return
      }
      newGoal(categoryId, data)
    },
    [editGoal, newGoal]
  )

  const onCompleteGoal = useCallback(
    goal => () => {
      onCompleteSubmit(filteredCategory?.id, goal.id)
      dispatch({
        type: MODAL_TYPE.COMPLETE,
        modalType: MODAL_TYPE.COMPLETE,
        onUncomplete: onUncompleteSubmit,
        onComplete: onCompleteSubmit,
        onRemove: onRemoveSubmit,
        editedGoal: goal,
        goalCategoryId: filteredCategory?.id,
      })
    },
    [filteredCategory, onCompleteSubmit, onRemoveSubmit, onUncompleteSubmit]
  )

  const renderModal = useCallback(
    () => {
      switch (state.modalType) {
        case MODAL_TYPE.CATEGORIES:
          return (
            <ManageGoalCategories
              categories={goalCategories}
              defaultCategories={Object.values(GoalsType)}
              onClose={onCloseModal}
              newGoalCategory={newGoalCategory}
              editGoalCategory={editGoalCategory}
              removeGoalCategory={removeGoalCategory}
              loading={isUpdatingCategory}
            />
          )
        case MODAL_TYPE.ADD:
        case MODAL_TYPE.EDIT:
          return (
            <AddOrEditGoals
              edit={!!state.editedGoal}
              editedGoal={state.editedGoal}
              categoryId={state.goalCategoryId}
              onClose={onCloseModal}
              onSubmit={addOrEditGoal}
              loading={isUpdatingGoal}
            />
          )
        case MODAL_TYPE.REMOVE:
        case MODAL_TYPE.UNCOMPLETE:
          return (
            <UserGoalModal
              categoryId={state.goalCategoryId}
              goalId={state.editedGoal.id}
              onClose={onCloseModal}
              title={state.title}
              content={state.content}
              buttonLabel={state.buttonLabel}
              onSubmit={state.submit}
              loading={isUpdatingGoal}
              editedGoal={state.editGoal}
            />
          )
        case MODAL_TYPE.COMPLETE:
          return (
            <UserGoalModal
              categoryId={state.goalCategoryId}
              goalId={state.editedGoal.id}
              onClose={onCloseModal}
              title={state.title}
              content={state.content}
              buttonLabel={state.buttonLabel}
              loading={isUpdatingGoal}
              hasConfetti
            />
          )
        default:
          return null
      }
    },
    [
      addOrEditGoal,
      editGoalCategory,
      goalCategories,
      isUpdatingCategory,
      isUpdatingGoal,
      newGoalCategory,
      onCloseModal,
      removeGoalCategory,
      state.buttonLabel,
      state.content,
      state.editGoal,
      state.editedGoal,
      state.goalCategoryId,
      state.modalType,
      state.submit,
      state.title,
    ]
  )

  const onExportGoalToPDFClick = useCallback(
    () => {
      exportGoals(authToken)
        .then(blob => {
          download(blob, 'my-future.pdf')
        })
        .catch(res => {
          console.warn(res.statusText)
        })
    },
    [authToken]
  )

  const onShazamClick = useCallback(
    goal => {
      const payload = {
        goalId: goal.id,
        goalCategoryId: filteredCategory.id,
        username,
      }

      if (!goal.shazamedByYou) {
        shazamUserGoal(payload)
        return
      }

      unshazamUserGoal(payload)
    },
    [filteredCategory, shazamUserGoal, unshazamUserGoal, username]
  )

  return (
    <>
      <div className={classnames(styles.wrapper, className)}>
        {isSubscriptionExpired ? (
          <Button
            onClick={onExportGoalToPDFClick}
            theme={ButtonTheme.DEFAULT}
            size={ButtonSize.SMALL}
            label="VIEW GOALS PDF"
          />
        ) : (
          <>
            <div className={styles.section}>
              <h2 className={styles['section-title']}>Goals</h2>
              <div className={styles.actions}>
                {myProfile ? (
                  <>
                    <Button
                      onClick={toggleModal(MODAL_TYPE.CATEGORIES)}
                      className={styles['my-profile-buttons']}
                      theme={ButtonTheme.DEFAULT}
                      size={ButtonSize.SMALL}
                      label="ADD DECLARATIONS"
                    />
                    <Button
                      onClick={onExportGoalToPDFClick}
                      className={styles['my-profile-buttons']}
                      theme={ButtonTheme.DEFAULT}
                      size={ButtonSize.SMALL}
                      label="VIEW GOALS PDF"
                    />
                    <Button
                      onClick={onButtonClick}
                      theme={ButtonTheme.DEFAULT}
                      size={ButtonSize.SMALL}
                      label="SEE ALL GOALS"
                    />
                  </>
                ) : (
                  <Button
                    onClick={onButtonClick}
                    theme={ButtonTheme.DEFAULT}
                    size={ButtonSize.SMALL}
                    label="SEE ALL GOALS"
                  />
                )}
              </div>
            </div>
            <div className={classnames(styles.container)}>
              <Tabs
                tabs={goalCategories.map(goal => goal.title)}
                activeTab={activeTab}
                onTabClick={onTabClick}
              />
              <div className={styles.content}>
                {filteredCategory &&
                  filteredCategory.declaration && (
                    <h3 className={styles['goal-title']}>{filteredCategory.declaration}</h3>
                  )}
                {filteredCategory && filteredCategory.goals.length ? (
                  filteredCategory.goals.filter(goal => !goal.completed).map((goal, index) => (
                    <Fragment key={goal.id}>
                      {myProfile ? (
                        <div className={styles['my-goals']}>
                          <UserGoalInformation
                            myProfile={myProfile}
                            goal={goal}
                            onEdit={toggleModal(MODAL_TYPE.EDIT, filteredCategory.id, goal)}
                            onRemove={toggleModal(MODAL_TYPE.REMOVE, filteredCategory.id, goal)}
                          />
                          <Button
                            className={classnames(
                              styles['complete-goal'],
                              styles['my-profile-buttons']
                            )}
                            theme={goal.completed ? ButtonTheme.PRIMARY : ButtonTheme.DEFAULT}
                            onClick={
                              goal.completed
                                ? toggleModal(MODAL_TYPE.UNCOMPLETE, filteredCategory.id, goal)
                                : onCompleteGoal(goal)
                            }
                            label={goal.completed ? 'COMPLETED' : 'MARK AS COMPLETE'}
                            disabled={goal.completed}
                          />
                        </div>
                      ) : (
                        <UserGoalInformation
                          goal={goal}
                          onShazam={onShazamClick}
                          isShazamLoading={isShazamLoading}
                          categoryId={filteredCategory.id}
                        />
                      )}
                      {filteredCategory.goals.length - 1 > index && (
                        <div className={styles.divider} />
                      )}
                    </Fragment>
                  ))
                ) : (
                  <div className={styles['empty-goal']}>
                    <p className={styles['main-text']}>
                      {myProfile
                        ? `You have no goals in this section yet`
                        : `This person has not set goals in this section yet`}
                    </p>
                    <p>
                      {myProfile ? `Why not add your first goal?` : `Please come back another time`}
                    </p>
                  </div>
                )}
                {myProfile &&
                  filteredCategory && (
                    <Button
                      onClick={toggleModal(MODAL_TYPE.ADD, filteredCategory.id)}
                      className={styles['add-goal-button']}
                      theme={ButtonTheme.PRIMARY}
                      label="ADD A NEW GOAL"
                    />
                  )}
              </div>
            </div>
          </>
        )}
      </div>
      {!!state.modalType && (
        <Modal className={styles.modal} isOpen onClose={onCloseModal}>
          {renderModal()}
        </Modal>
      )}
    </>
  )
}

Goals.propTypes = {
  className: PropTypes.string,
  username: PropTypes.string.isRequired,
  myProfile: PropTypes.bool,
  goalCategories: PropTypes.arrayOf(goalCategoryShape).isRequired,
  newGoalCategory: PropTypes.func,
  editGoalCategory: PropTypes.func,
  removeGoalCategory: PropTypes.func,
  newGoal: PropTypes.func,
  editGoal: PropTypes.func,
  removeGoal: PropTypes.func,
  isUpdatingCategory: PropTypes.bool.isRequired,
  updatingCategoryError: ImmutablePropTypes.map,
  isUpdatingGoal: PropTypes.bool.isRequired,
  updatingGoalError: ImmutablePropTypes.map,
  authToken: PropTypes.string.isRequired,
  shazamUserGoal: PropTypes.func,
  unshazamUserGoal: PropTypes.func,
  isShazamLoading: PropTypes.bool.isRequired,
  isSubscriptionExpired: PropTypes.bool,
}

Goals.defaultProps = {
  className: '',
  myProfile: false,
  newGoalCategory: () => {},
  editGoalCategory: () => {},
  removeGoalCategory: () => {},
  newGoal: () => {},
  editGoal: () => {},
  removeGoal: () => {},
  updatingCategoryError: Map(),
  updatingGoalError: Map(),
  shazamUserGoal: () => {},
  unshazamUserGoal: () => {},
  isSubscriptionExpired: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Goals)
