import humps from 'humps'

import { get, post, patch, del } from './requests'

export const login = payload => post(['login'], {}, humps.decamelizeKeys(payload))

export const signUp = payload => post(['register'], {}, humps.decamelizeKeys(payload))

export const facebookLogin = payload => post(['facebook'], {}, humps.decamelizeKeys(payload))

export const googleLogin = payload => post(['google'], {}, humps.decamelizeKeys(payload))

export const changePassword = key => payload =>
  post(['change-password'], { key }, humps.decamelizeKeys(payload))

export const changeEmail = key => payload =>
  post(['change-email'], { key }, humps.decamelizeKeys(payload))

export const resetPassword = payload =>
  post(['password', 'reset'], {}, humps.decamelizeKeys(payload))

export const confirmResetPassword = payload =>
  post(['password', 'reset', 'confirm'], {}, humps.decamelizeKeys(payload))

export const updateUser = key => payload => {
  const formData = new FormData()

  if (payload.photo) formData.append('photo', payload.photo)
  if (payload.coverPhoto) formData.append('cover_photo', payload.coverPhoto)

  if (payload.emailCourseAdd !== undefined) {
    formData.append('email_course_add', payload.emailCourseAdd)
    formData.append('email_disable_all', payload.emailDisableAll)
    formData.append('email_messages', payload.emailMessages)
    formData.append('email_summary_added_to_channels', payload.emailSummaryAddedToChannels)
    formData.append('email_summary_frequency', payload.emailSummaryFrequency)
    formData.append('email_summary_friend_suggestions', payload.emailSummaryFriendSuggestions)
    formData.append('email_summary_goal_likes', payload.emailSummaryGoalLikes)
    formData.append('email_summary_messages_in_channels', payload.emailSummaryMessagesInChannels)
    formData.append('email_summary_new_followers', payload.emailSummaryNewFollowers)
    formData.append('email_summary_post_comments', payload.emailSummaryPostComments)
    formData.append('email_summary_post_likes', payload.emailSummaryPostLikes)
  }

  if (payload.name) formData.append('name', payload.name)
  if (payload.username) formData.append('username', payload.username)
  if (payload.company) formData.append('company', payload.company)
  if (payload.location) formData.append('location', payload.location)
  if (payload.title) formData.append('title', payload.title)
  if (payload.completedProfile) formData.append('completed_profile', payload.completedProfile)

  return patch(['user'], { key }, formData)
}

export const startMembership = key => payload =>
  post(['user', 'membership-subscription'], { key }, payload)

export const getAvailableMemberships = key => get(['user', 'available-subscriptions'], { key })

export const paymentHistory = key => page => get(['payment-history'], { key, params: { page } })

export const restartSubscription = key => post(['user', 'restart-subscription'], { key })

export const cancelSubscription = key => post(['user', 'cancel-subscription'], { key })

export const listPosts = key => (mine, page) => get(['posts'], { key, params: { mine, page } })

export const myFuture = key => get(['my-future'], { key })

export const updateMyFuture = key => payload =>
  patch(['my-future'], { key }, humps.decamelizeKeys(payload))

export const createGoalCategory = key => payload =>
  post(['goal-categories'], { key }, humps.decamelizeKeys(payload))

export const updateGoalCategory = key => (payload, id) =>
  patch(['goal-categories', id], { key }, humps.decamelizeKeys(payload))

export const deleteGoalCategory = key => id => del(['goal-categories', id], { key })

export const createGoal = key => (categoryId, payload) =>
  post(['goal-categories', categoryId, 'goals'], { key }, humps.decamelizeKeys(payload))

export const updateGoal = key => (categoryId, goalId, payload) =>
  patch(['goal-categories', categoryId, 'goals', goalId], { key }, humps.decamelizeKeys(payload))

export const deleteGoal = key => (categoryId, goalId) =>
  del(['goal-categories', categoryId, 'goals', goalId], { key })

export const listCards = key => get(['user', 'cards'], { key })

export const addCard = key => payload =>
  post(['user', 'cards'], { key }, humps.decamelizeKeys(payload))

export const makeCardDefault = key => cardToken =>
  post(['user', 'cards', cardToken, 'make-default'], { key })

export const removeCard = key => cardToken => del(['user', 'cards', cardToken], { key })

export const follow = key => username => post(['users', username, 'follow'], { key })

export const unfollow = key => username => post(['users', username, 'unfollow'], { key })

export const getUser = key => get(['user'], { key })

export const getSystemUser = key => username => get(['users', username], { key })

export const getUserPosts = key => (username, page) =>
  get(['posts'], { key, params: { from_user: username, page } })

export const hidePosts = key => username => post(['users', username, 'hide-posts'], { key })

export const getUsersAvailableToChat = key => (page, search) =>
  get(['users', 'available-to-chat'], { key, params: { page, search } })

export const exportGoals = key =>
  get(['my-future', 'extract-to-pdf'], { key, responseType: 'blob' })

export const blockUser = key => username => post(['users', username, 'block'], { key })

export const linkZoom = key => payload => post(['link-zoom'], { key }, payload)

export const shazamGoal = key => ({ username, goalId }) =>
  post(['users', username, 'goals', goalId, 'shazam'], { key })

export const unshazamGoal = key => ({ username, goalId }) =>
  post(['users', username, 'goals', goalId, 'unshazam'], { key })

export const getHiddenUsers = key => get(['user', 'hiding'], { key })

export const showUserPosts = key => username => post(['users', username, 'show-posts'], { key })

export const getPost = key => postId => get(['posts', postId], { key })

export const deactivateZoom = key => del(['link-zoom'], { key })

export const subscribeToNewsletter = payload => post(['newsletter'], {}, payload)
