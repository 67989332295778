import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import IconButton from '_components/icon-button'
import ArrowRightIcon from '_assets/icons/arrow-right-24-px.svg'
import Svg from '_components/svg'
import { iconPropTypes } from '_utils/proptypes'

import styles from './styles.css'

export const ACTION_CARD_TYPE = {
  NAVIGATE: 'navigate',
  TOGGLE: 'toggle',
}

const ActionCard = ({
  title,
  information,
  type,
  to,
  onCardClick,
  icon,
  className,
  iconClassName,
}) => {
  const handleIconButton = useMemo(
    () => (type === ACTION_CARD_TYPE.NAVIGATE ? { href: to } : { onClick: onCardClick }),
    [onCardClick, to, type]
  )

  return (
    <div className={classNames(styles['card-container'], className)}>
      <div className={styles['icon-wrapper']}>
        <Svg className={classNames(styles['card-icon'], iconClassName)} icon={icon} />
      </div>
      <div className={styles['card-content']}>
        <p className={styles.title}>{title}</p>
        <p>{information}</p>
      </div>

      <IconButton
        icon={ArrowRightIcon}
        iconClassName={styles['arrow-icon']}
        {...handleIconButton}
      />
    </div>
  )
}

ActionCard.propTypes = {
  title: PropTypes.string.isRequired,
  icon: iconPropTypes.isRequired,
  type: PropTypes.oneOf(Object.values(ACTION_CARD_TYPE)),
  information: PropTypes.string,
  to: PropTypes.string,
  onCardClick: PropTypes.func,
  className: PropTypes.string,
  iconClassName: PropTypes.string,
}

ActionCard.defaultProps = {
  information: '',
  type: ACTION_CARD_TYPE.NAVIGATE,
  to: '',
  onCardClick: () => {},
  className: '',
  iconClassName: '',
}

export default ActionCard
