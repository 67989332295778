import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import cookies from 'react-cookies'

import ModalCard from '_components/modal/modal-card'
import { ZOOM_CLIENT_ID } from '_config/environment'

import styles from './styles.css'

const now = Date.now().toString()

const ModalLinkZoom = ({ onCloseButton, isOpen, pathname }) => {
  useEffect(() => {
    cookies.save('lightyear-zoom-state', now, { path: '/' })
  }, [])

  return (
    <ModalCard
      title="Start a new video chat"
      buttonLabel="link zoom"
      onCloseButton={onCloseButton}
      isOpen={isOpen}
      showFooter={false}
    >
      <h4 className={styles['link-zoom-text']}>
        You will be taken to our Video Chat Service provider - Zoom to sign up. Please follow the
        steps to sign up and we will get you chatting in no time.
      </h4>
      <div className={styles.footer}>
        <a
          className={styles.link}
          href={`https://zoom.us/oauth/authorize?response_type=code&client_id=${ZOOM_CLIENT_ID}&redirect_uri=${pathname}&state=${now}`}
        >
          LINK ZOOM
        </a>
      </div>
    </ModalCard>
  )
}

ModalLinkZoom.propTypes = {
  onCloseButton: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  pathname: PropTypes.string.isRequired,
}

export default React.memo(ModalLinkZoom)
