import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import SectionContent from '_components/landing-components/section-content'
import SectionTitle from '_components/landing-components/section-title'
import { prismicTitlePropType, prismicContentPropType } from '_utils/proptypes'

import styles from './styles.css'

const ClaritySection = ({ slice, id }) => {
  const backgroundStyles = useMemo(
    () => ({
      backgroundImage: `url(${slice.backgroundImage.thumbnail})`,
    }),
    [slice.backgroundImage.thumbnail]
  )

  return (
    <section className={styles.section} id={id}>
      <div className={styles.container}>
        {slice.backgroundImage?.thumbnail && (
          <div className={styles.backgroundImage} style={backgroundStyles} />
        )}
        {slice.sectionTitle && <SectionTitle title={slice.sectionTitle} className={styles.title} />}
        <div className={styles.content}>
          {slice.content?.map((paragraph, index) => (
            <SectionContent
              // setting key as index since this array is not going to be changed
              key={`content-${index + 1}`}
              text={paragraph}
            />
          ))}
        </div>
      </div>
    </section>
  )
}
ClaritySection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    content: prismicContentPropType,
    backgroundImage: PropTypes.shape({
      thumbnail: PropTypes.string,
      alt: PropTypes.string,
    }),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(ClaritySection)
