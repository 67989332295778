import React, { useCallback, useState, useReducer, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import LazyLoad from 'react-lazyload'
import classnames from 'classnames'
import debounce from 'lodash.debounce'

import SendIcon from '_assets/icons/send.svg'
import Commentary from '_components/commentary'
import { postShape, userShape } from '_utils/proptypes'
import PostActionsModal, { PostActionType } from '_components/post-actions-modal'
import Modal from '_components/modal'
import EditPostModal from '_components/edit-post-modal'
import POST_ACTIONS from '_modules/post/actions'
import ASSIGNMENT_ACTIONS from '_modules/assignment/actions'
import PROFILE_ACTIONS from '_modules/profile/actions'
import { usePrevious } from '_utils/hooks'
import { Suggestion } from '_models/'
import { searchSuggestions } from '_modules/suggestion/actions'
import TaggingUserList from '_components/tagging-user'
import { onMouseDown } from '_utils/aria'

import PostHeader from './post-header'
import TaggingInput from './tagging-input'
import PostContent from './post-content'
import { INITIAL_STATE, OPEN_MODAL, CLOSE_MODAL, reducer } from './reducer'
import styles from './styles.css'

const BACKSPACE = 8

const mapStateToProps = ({ loading, error, suggestion, user }) => ({
  isPosting:
    !!loading.get(POST_ACTIONS.CREATE_POST.ACTION) ||
    !!loading.get(ASSIGNMENT_ACTIONS.CREATE_POST.ACTION) ||
    !!loading.get(PROFILE_ACTIONS.CREATE_POST.ACTION),
  postingError:
    error.get(POST_ACTIONS.CREATE_POST.ACTION) ||
    error.get(ASSIGNMENT_ACTIONS.CREATE_POST.ACTION) ||
    error.get(PROFILE_ACTIONS.CREATE_GOAL.ACTION),
  suggestedUser: suggestion.results.toJS(),
  isShazamLoading:
    !!loading.get(POST_ACTIONS.SHAZAM_POST.ACTION) ||
    !!loading.get(POST_ACTIONS.UNSHAZAM_POST.ACTION),
  avatarUrl: user.get('photo'),
})

const mapDispatchToProps = {
  showSuggestion: searchSuggestions,
}

const PostCard = ({
  post,
  isOwnPost,
  user,
  onSubmit,
  onEditPost,
  shazam,
  unshazam,
  onDeletePost,
  onHidePosts,
  onFlagPost,
  isPosting,
  postingError,
  removeHideOption,
  onBlockUser,
  className,
  suggestedUser,
  showSuggestion,
  id,
  isShazamLoading,
  avatarUrl,
}) => {
  const [message, setComment] = useState('')
  const [state, dispatch] = useReducer(reducer, INITIAL_STATE)
  const wasPosting = usePrevious(isPosting)
  const messageRef = useRef(null)
  const suggestionRef = useRef(null)
  const buttonRef = useRef(null)
  const endHandler = useRef(null)
  const [showSuggestor, setShowSuggestor] = useState(false)
  const [characterPosition, setCharacterPosition] = useState(0)
  const showedSuggestor = usePrevious(showSuggestor)
  const [username, setUsername] = useState('')
  const [startTagging, setStartTagging] = useState(null)
  const previousUsername = usePrevious(username)

  const isSubmitDisabled = !message

  const setInputRef = useCallback(ref => {
    messageRef.current = ref
  }, [])

  const setEndHandler = useCallback(ref => {
    endHandler.current = ref
  }, [])

  useEffect(
    () => {
      if (wasPosting && !isPosting && postingError.size === 0) {
        setComment('')
      }
    },
    [isPosting, postingError.size, wasPosting]
  )

  const handleOutsideClick = useCallback(e => {
    if (suggestionRef.current === e.target) {
      return
    }

    setShowSuggestor(false)

    endHandler.current()
  }, [])

  const debounceShowSuggestion = debounce(() => {
    showSuggestion(username)
  }, 100)

  useEffect(() => {
    if (showedSuggestor && !showSuggestor) {
      messageRef.current.focus()
      document.removeEventListener('click', handleOutsideClick, false)
    }
    if (!showedSuggestor && showSuggestor) {
      document.addEventListener('click', handleOutsideClick, false)
    }
    if (previousUsername !== username && showSuggestor) {
      debounceShowSuggestion()
    }
  })

  const onCommentSubmit = useCallback(
    event => {
      event.preventDefault()
      onSubmit({
        message,
        fatherPost: post.id,
      })
    },
    [message, onSubmit, post.id]
  )

  const onCommentChange = useCallback(
    event => {
      const { value } = event.currentTarget
      const character = event.target.selectionStart
      setComment(value)
      setCharacterPosition(character)

      if (showSuggestor) {
        if (value.charAt(characterPosition) !== '@' && message.length !== character) {
          setUsername(prevUsername => {
            if (value[characterPosition]) {
              return prevUsername + value[characterPosition]
            }
            return prevUsername
          })
        }
      } else if (!showSuggestor) {
        setUsername('')
      }
    },
    [characterPosition, message.length, showSuggestor]
  )

  const checkBackSpace = useCallback(
    event => {
      if (event.keyCode === BACKSPACE && showSuggestor) {
        const newUsername = username.substring(0, username.length - 1)
        setUsername(newUsername)
      }
    },
    [showSuggestor, username]
  )

  const onPostEdit = useCallback(payload => onEditPost(payload, state.commentId || post.id), [
    onEditPost,
    post.id,
    state.commentId,
  ])

  const onPostDelete = useCallback(() => onDeletePost(post.id), [onDeletePost, post.id])

  const hideUserPosts = useCallback(() => onHidePosts(post.author.username), [
    onHidePosts,
    post.author.username,
  ])

  const onShazamClick = useCallback(
    payload => {
      const newPayload = {
        ...payload,
        fatherPostId: payload.isComment ? post.id : null,
        shazams: payload.shazams,
      }

      if (payload.shazamedByYou) {
        unshazam(newPayload)
        return
      }

      shazam(newPayload)
    },
    [post.id, shazam, unshazam]
  )

  const openModal = useCallback((modalType, editableMessage = null, commentId = null) => {
    dispatch({
      type: OPEN_MODAL,
      modalType,
      editableMessage,
      commentId,
    })
  }, [])

  const closeModal = useCallback(() => {
    dispatch({
      type: CLOSE_MODAL,
    })
  }, [])

  const flagPost = useCallback(
    () => {
      onFlagPost(post.id)
    },
    [onFlagPost, post.id]
  )

  const onBlockUserClick = useCallback(
    () => {
      onBlockUser({ username: post.author.username, userId: post.author.id })
    },
    [onBlockUser, post.author.id, post.author.username]
  )

  const toggleSuggester = useCallback(
    metaInformation => {
      const {
        hookType,
        cursor: { selectionStart },
      } = metaInformation

      if (hookType === 'start') {
        const previousWordCharacter = selectionStart - 2
        if (message.charAt(previousWordCharacter) === ' ' || characterPosition === 0) {
          setShowSuggestor(true)
          setStartTagging(characterPosition)
          showSuggestion()
        } else {
          endHandler.current()
          setShowSuggestor(false)
        }
      } else if (hookType === 'cancel') {
        setShowSuggestor(false)
        setStartTagging(null)
      }
    },
    [characterPosition, message, showSuggestion]
  )

  const handleTextareaInput = useCallback(
    event => {
      const { value } = event.currentTarget
      setComment(
        `${message.substring(0, startTagging + 1)}${value} ${message.substring(characterPosition)}`
      )
      setCharacterPosition(characterPosition + value.length + 1)
      setShowSuggestor(false)
      endHandler.current()
      messageRef.current.focus()
    },
    [characterPosition, message, startTagging]
  )

  return (
    <>
      <div className={classnames(styles.container, className)}>
        <PostHeader
          post={post}
          date={post.createdAt}
          isOwnPost={isOwnPost}
          onPostEdit={onPostEdit}
          openModal={openModal}
          onFlagPost={flagPost}
          removeHideOption={removeHideOption}
        />
        <PostContent
          isShazamLoading={isShazamLoading}
          post={post}
          authorName={post.author.name}
          shazam={onShazamClick}
        />
        {post.comments.length > 0 && (
          <div className={styles.commentaries}>
            {post.comments.map(comment => (
              <LazyLoad offset={100} key={comment.id}>
                <Commentary
                  commentary={comment}
                  isEditable={user.id === comment.author.id}
                  openModal={openModal}
                  shazam={onShazamClick}
                  isShazamLoading={isShazamLoading}
                />
              </LazyLoad>
            ))}
          </div>
        )}
        <form className={styles['comment-form']} onSubmit={onCommentSubmit}>
          {showSuggestor && (
            <TaggingUserList
              suggestedUser={suggestedUser}
              buttonRef={buttonRef}
              handleTextareaInput={handleTextareaInput}
              suggestionRef={suggestionRef}
              className={styles['tagging-position']}
            />
          )}
          <TaggingInput
            id={id}
            avatarUrl={avatarUrl}
            onChange={onCommentChange}
            value={message}
            isLoading={isPosting}
            toggleSuggester={toggleSuggester}
            endHandler={setEndHandler}
            inputRef={setInputRef}
            disabled={isPosting}
            checkBackSpace={checkBackSpace}
            inputClassName={styles['comment-input']}
          />
          <button
            type="submit"
            className={styles['send-button']}
            onMouseDown={onMouseDown}
            disabled={isSubmitDisabled}
            aria-label="Submit comment"
          >
            <svg className={styles.send} aria-hidden="true" viewBox={SendIcon.viewBox}>
              <use xlinkHref={`#${SendIcon.id}`} />
            </svg>
          </button>
        </form>
      </div>
      {state.modalType && (
        <Modal isOpen onClose={closeModal}>
          {state.modalType === PostActionType.EDIT ? (
            <EditPostModal
              onSaveClick={onPostEdit}
              onCloseModal={closeModal}
              editableMessage={state.editableMessage}
            />
          ) : (
            <PostActionsModal
              type={state.modalType}
              onClose={closeModal}
              onPostDelete={onPostDelete}
              onHidePosts={hideUserPosts}
              onBlockUser={onBlockUserClick}
            />
          )}
        </Modal>
      )}
    </>
  )
}

PostCard.propTypes = {
  post: postShape.isRequired,
  isOwnPost: PropTypes.bool,
  user: userShape.isRequired,
  onSubmit: PropTypes.func,
  onEditPost: PropTypes.func,
  shazam: PropTypes.func,
  unshazam: PropTypes.func,
  onDeletePost: PropTypes.func,
  onHidePosts: PropTypes.func,
  onFlagPost: PropTypes.func,
  isPosting: PropTypes.bool.isRequired,
  postingError: PropTypes.instanceOf(Map),
  removeHideOption: PropTypes.bool,
  onBlockUser: PropTypes.func,
  className: PropTypes.string,
  suggestedUser: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.instanceOf(Suggestion), PropTypes.arrayOf(userShape)])
  ).isRequired,
  showSuggestion: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  isShazamLoading: PropTypes.bool.isRequired,
  avatarUrl: PropTypes.string,
}

PostCard.defaultProps = {
  isOwnPost: false,
  onSubmit: () => {},
  onEditPost: () => {},
  shazam: () => {},
  unshazam: () => {},
  onDeletePost: () => {},
  onHidePosts: () => {},
  onFlagPost: () => {},
  postingError: Map(),
  removeHideOption: false,
  onBlockUser: () => {},
  className: '',
  avatarUrl: '',
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(PostCard))
