import { Map, List, fromJS } from 'immutable'
import humps from 'humps'

import { createReducer } from '_utils/redux'
import {
  WEBSOCKET_VIDEO_ASSIGNMENT_STARTING,
  WEBSOCKET_VIDEO_ASSIGNMENT_STARTED,
  WEBSOCKET_VIDEO_ASSIGNMENT_ENDED,
} from '_modules/websocket/actions'
import { START_CALL } from '_modules/class/actions'

import {
  GET_SUMMARY,
  GET_UPCOMING_GOALS,
  GET_PEOPLE_YOU_MAY_KNOW,
  GET_ANNOUNCEMENTS,
} from './actions'

const INITIAL_STATE = Map({
  summary: Map({
    takingCourses: null,
    totalAssignments: null,
    completedAssignments: null,
    upcomingAssignments: List(),
    upcomingCalls: List(),
  }),
  upcomingGoals: List(),
  peopleYouMayKnow: List(),
  announcements: List(),
})

const dashboard = createReducer(INITIAL_STATE, {
  [GET_SUMMARY.FULFILLED]: (state, { payload }) =>
    state.set('summary', Map(humps.camelizeKeys(payload))),

  [GET_UPCOMING_GOALS.FULFILLED]: (state, { payload }) =>
    state.set('upcomingGoals', List(humps.camelizeKeys(payload))),

  [GET_PEOPLE_YOU_MAY_KNOW.FULFILLED]: (state, { payload }) =>
    state.set('peopleYouMayKnow', List(humps.camelizeKeys(payload))),

  [GET_ANNOUNCEMENTS.FULFILLED]: (state, { payload }) => {
    const formattedPayload = fromJS(humps.camelizeKeys(payload.results))
    return state.set('announcements', formattedPayload)
  },

  [WEBSOCKET_VIDEO_ASSIGNMENT_STARTING]: (state, { payload }) => {
    const { classId, leaderId, meetingJoinUrl, meetingStartTime, meetingId } = payload

    const upcomingCalls = state.getIn(['summary', 'upcomingCalls'])

    const upcomingCall =
      upcomingCalls.length && upcomingCalls[0].classId === classId ? upcomingCalls[0] : {}

    const nextCall = {
      ...upcomingCall,
      classId,
      leaderId,
      meeting: {
        ...upcomingCall.meeting,
        startTime: meetingStartTime,
        id: meetingId,
        joinUrl: meetingJoinUrl,
      },
      joinUrl: meetingJoinUrl,
      startTime: meetingStartTime,
    }

    return state.setIn(['summary', 'upcomingCalls'], [nextCall])
  },

  [WEBSOCKET_VIDEO_ASSIGNMENT_STARTED]: (state, { payload }) => {
    const { classId, leaderId, meetingJoinUrl, meetingStartTime, meetingId } = payload

    const upcomingCall = state
      .getIn(['summary', 'upcomingCalls'])
      ?.find(meeting => meeting.classId === classId)

    if (upcomingCall) {
      upcomingCall.meeting.started = true
      return state.setIn(['summary', 'upcomingCalls'], [upcomingCall])
    }

    const nextCall = {
      classId,
      leaderId,
      meeting: {
        startTime: meetingStartTime,
        id: meetingId,
        joinUrl: meetingJoinUrl,
        started: true,
      },
      joinUrl: meetingJoinUrl,
      startTime: meetingStartTime,
    }

    return state.setIn(['summary', 'upcomingCalls'], [nextCall])
  },

  [WEBSOCKET_VIDEO_ASSIGNMENT_ENDED]: (state, { payload }) => {
    const { classId } = payload

    const upcomingCall = state
      .getIn(['summary', 'upcomingCalls'])
      ?.find(meeting => meeting.classId === classId)
    if (upcomingCall) {
      upcomingCall.meeting.ended = true
      return state.setIn(['summary', 'upcomingCalls'], [upcomingCall])
    }
    return state
  },

  [START_CALL.FULFILLED]: state => {
    const upcomingCall = state.getIn(['summary', 'upcomingCalls'])?.[0]

    if (upcomingCall) {
      upcomingCall.meeting.started = true
      return state.setIn(['summary', 'upcomingCalls'], [upcomingCall])
    }
    return state
  },
})

export default dashboard
