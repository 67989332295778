import React from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'

import SectionTitle from '_components/landing-components/section-title'
import { prismicTitlePropType, prismicMemberProTypes } from '_utils/proptypes'
import MemberCard from '_components/landing-components/member-card'

import LeadersCarousel from '../explore-courses/leaders-carousel'

import styles from './styles.css'

const EXPLORE_COURSES_PATH = '/explore-courses'

const TheLightyearTeam = ({ slice, id }) => {
  const { pathname } = useLocation()
  const isExploreCourses = pathname.includes(EXPLORE_COURSES_PATH)

  return (
    <section className={styles.section} id={id}>
      {isExploreCourses ? (
        <div className={styles['leaders-section']}>
          <LeadersCarousel leadersList={slice.members} label="Our coaches" />
        </div>
      ) : (
        <div className={styles.container}>
          {slice.sectionTitle && (
            <SectionTitle title={slice.sectionTitle} className={styles.title} />
          )}
          {!!slice.members.length && (
            <div className={styles['members-container']}>
              {slice.members.map(member => <MemberCard member={member} key={member.name} />)}
            </div>
          )}
        </div>
      )}
    </section>
  )
}

TheLightyearTeam.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    members: PropTypes.arrayOf(prismicMemberProTypes),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(TheLightyearTeam)
