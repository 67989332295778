import PublicCourse from '_models/public-course'
import { createReducer } from '_utils/redux'

import { GET_PUBLIC_COURSE } from './actions'

const INITIAL_STATE = new PublicCourse()

const courses = createReducer(INITIAL_STATE, {
  [GET_PUBLIC_COURSE.FULFILLED]: (state, { payload }) => {
    const { classes } = payload

    const formattedClasses = {}
    Object.keys(classes).forEach(item => {
      formattedClasses[item.toLocaleLowerCase()] = classes[item]
    })

    const formattedPayload = {
      ...payload,
      classes: formattedClasses,
    }
    const newCourse = new PublicCourse(formattedPayload)

    return newCourse
  },
  [GET_PUBLIC_COURSE.REJECTED]: () => INITIAL_STATE,
})

export default courses
