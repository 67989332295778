import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { navigate } from '@reach/router'

import { NOT_FOUND } from '_utils/constants'
import { getClass, GET_CLASS } from '_modules/class/actions'
import { classShape } from '_utils/proptypes'
import { getClassById } from '_modules/class/selectors'
import { usePrevious } from '_utils/hooks'
import { FOLLOW_USER, follow, UNFOLLOW_USER, unfollow } from '_modules/user/actions'
import RocketLoader from '_components/rocket-loader'
import { CLASS_TYPE } from '_constants/class'

import LeaderLedClass from './leader-led-class'
import CommunitySelfLedClass from './community-self-led-class'
import styles from './styles.css'

const mapStateToProps = (state, { classId }) => ({
  myClass: getClassById(state, classId),
  userId: state.user.id,
  isFollowing: !!state.loading.get(FOLLOW_USER.ACTION),
  followError: state.error.get(FOLLOW_USER.ACTION),
  isUnfollowing: !!state.loading.get(UNFOLLOW_USER.ACTION),
  unfollowError: state.error.get(UNFOLLOW_USER.ACTION),
  isLoadingClass: !!state.loading.get(GET_CLASS.ACTION),
  loadingClassError: state.error.get(GET_CLASS.ACTION),
})

const mapDispatchToProps = {
  getCourseClass: getClass,
  followUser: follow,
  unFollowUser: unfollow,
}

const Class = ({
  classId,
  getCourseClass,
  myClass,
  userId,
  followUser,
  unFollowUser,
  isFollowing,
  followError,
  isUnfollowing,
  unfollowError,
  isLoadingClass,
  loadingClassError,
}) => {
  const wasFollowing = usePrevious(isFollowing)
  const wasUnfollowing = usePrevious(isUnfollowing)
  const wasLoadingClass = usePrevious(isLoadingClass)

  const onFollow = useCallback(username => followUser(username), [followUser])
  const onUnfollow = useCallback(username => unFollowUser(username), [unFollowUser])

  useEffect(
    () => {
      if (myClass.assignments.length === 0) {
        getCourseClass(classId)
      }
    },
    [classId, getCourseClass, myClass.assignments.length]
  )

  useEffect(
    () => {
      if (
        (wasFollowing && !isFollowing && followError.size === 0) ||
        (wasUnfollowing && !isUnfollowing && unfollowError.size === 0)
      ) {
        getCourseClass(classId)
      }
    },
    [
      classId,
      followError.size,
      getCourseClass,
      isFollowing,
      isUnfollowing,
      unfollowError.size,
      wasFollowing,
      wasUnfollowing,
    ]
  )

  useEffect(
    () => {
      if (wasLoadingClass && !isLoadingClass && loadingClassError?.get?.('detail') === NOT_FOUND) {
        navigate('/404')
      }
    },
    [isLoadingClass, loadingClassError, wasLoadingClass]
  )

  if (isLoadingClass) {
    return (
      <div className={styles.loading}>
        <RocketLoader />
      </div>
    )
  }

  if (myClass.type === CLASS_TYPE.COACH_LED.slug) {
    return (
      <LeaderLedClass
        myClass={myClass}
        userId={userId}
        onFollow={onFollow}
        onUnfollow={onUnfollow}
      />
    )
  }

  if (
    myClass.type === CLASS_TYPE.COMMUNITY_LED.slug ||
    myClass.type === CLASS_TYPE.SELF_LED?.slug ||
    myClass.type === CLASS_TYPE.SELF_PACED?.slug
  ) {
    return (
      <CommunitySelfLedClass
        myClass={myClass}
        userId={userId}
        onFollow={onFollow}
        onUnfollow={onUnfollow}
      />
    )
  }

  return null
}

Class.propTypes = {
  classId: PropTypes.string.isRequired,
  getCourseClass: PropTypes.func.isRequired,
  myClass: classShape.isRequired,
  userId: PropTypes.number.isRequired,
  followUser: PropTypes.func.isRequired,
  unFollowUser: PropTypes.func.isRequired,
  isFollowing: PropTypes.bool.isRequired,
  followError: PropTypes.instanceOf(Map),
  isUnfollowing: PropTypes.bool.isRequired,
  unfollowError: PropTypes.instanceOf(Map),
  isLoadingClass: PropTypes.bool.isRequired,
  loadingClassError: PropTypes.instanceOf(Map),
}

Class.defaultProps = {
  followError: Map(),
  unfollowError: Map(),
  loadingClassError: Map(),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(Class))
