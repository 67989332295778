import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import classnames from 'classnames'

import SectionContent from '_components/landing-components/section-content'
import SectionTitle from '_components/landing-components/section-title'
import { prismicTitlePropType, prismicContentPropType } from '_utils/proptypes'

import styles from './styles.css'

const PersonalDevelopmentCoursesSection = ({ slice, id }) => {
  const { pathname } = useLocation()

  const isAboutUs = useMemo(() => pathname === '/about-us', [pathname])

  return (
    <section
      className={classnames(styles.section, { [styles['about-us-section']]: isAboutUs })}
      id={id}
    >
      <div className={styles.content}>
        {slice.sectionTitle && (
          <SectionTitle title={slice.sectionTitle} className={styles.title} isCustom />
        )}
        <div className={styles['section-text']}>
          {slice.content.map((paragraph, index) => (
            <SectionContent
              // setting key as index since this array is not going to be changed
              key={`content-${index + 1}`}
              text={paragraph}
            />
          ))}
        </div>
      </div>
    </section>
  )
}

PersonalDevelopmentCoursesSection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    content: prismicContentPropType,
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(PersonalDevelopmentCoursesSection)
