const MOBILE_THRESHOLD = 767
const DESKTOP_THRESHOLD = 1199

module.exports = {
  customMedia: {
    '--sm-viewport': `(min-width:160px) and (max-width:${MOBILE_THRESHOLD}px)`,
    '--md-viewport': `(min-width:${MOBILE_THRESHOLD + 1}px) and (max-width:${DESKTOP_THRESHOLD}px)`,
    '--lg-viewport': '(min-width:1200px)',
    '--retina-display': '(-webkit-min-device-pixel-ratio: 1.25), (min-resolution: 120dpi)',
  },
  MOBILE_THRESHOLD,
  DESKTOP_THRESHOLD,
}
