import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'

import EmptyMessageSvg from '_assets/icons/messages-empty-16.svg'
import Button, { ButtonTheme } from '_components/button'
import { userShape, channelShape } from '_utils/proptypes'
import Modal from '_components/modal'
import ParticipantsModal, { ParticipantModalTheme } from '_components/participants-modal'
import BrowseChannelsModal from '_components/sidebar-chat/channel-list/browse-channels-modal'
import { getUsersAvailableToChat, GET_USERS_AVAILABLE_TO_CHAT } from '_modules/user/actions'
import {
  getChannels,
  GET_CHANNELS,
  joinToChannel,
  JOIN_CHANNEL,
  createChannel,
  CREATE_CHANNEL,
  inviteToChannel,
  INVITE_TO_CHANNEL,
} from '_modules/chat/actions'
import { usePrevious } from '_utils/hooks'

import styles from './styles.css'

const mapStateToProps = ({ chat, loading, error }) => ({
  availableUsers: chat.get('usersAvailableToChat').toJS(),
  availableChannels: chat.get('channelsAvailable').toJS(),
  isLoadingUsersToChat: !!loading.get(GET_USERS_AVAILABLE_TO_CHAT.ACTION),
  isLoadingChannelList: !!loading.get(GET_CHANNELS.ACTION),
  isCreatingChannel: !!loading.get(CREATE_CHANNEL.ACTION),
  isJoiningChannel: !!loading.get(JOIN_CHANNEL.ACTION),
  isInvitingChannel: !!loading.get(INVITE_TO_CHANNEL.ACTION),
  createChannelError: error.get(CREATE_CHANNEL.ACTION),
})

const mapDispatchToProps = {
  getAvailableUsers: getUsersAvailableToChat,
  getAvailableChannels: getChannels,
  newChannel: createChannel,
  joinChannel: joinToChannel,
  inviteChannel: inviteToChannel,
}

const EmptyMessage = ({
  getAvailableChannels,
  getAvailableUsers,
  availableUsers,
  availableChannels,
  newPreDirectMessage,
  newChannel,
  joinChannel,
  inviteChannel,
  isLoadingUsersToChat,
  isLoadingChannelList,
  isCreatingChannel,
  isInvitingChannel,
  isJoiningChannel,
  createChannelError,
  isAdmin,
}) => {
  const [state, setState] = useState({
    isBrowseChannelModalOpen: false,
    isDirectMessageModalOpen: false,
  })

  const [channelId, setChannel] = useState(null)

  const wasCreatingChannel = usePrevious(isCreatingChannel)
  const wasJoiningChannel = usePrevious(isJoiningChannel)
  const wasInvitingChannel = usePrevious(isInvitingChannel)

  const onBrowseChannels = useCallback(() => {
    setState({
      isBrowseChannelModalOpen: true,
      isDirectMessageModalOpen: false,
    })
  }, [])

  const onNewDirectMessage = useCallback(() => {
    setState({
      isDirectMessageModalOpen: true,
      isBrowseChannelModalOpen: false,
    })
  }, [])

  useEffect(
    () => {
      if (
        (wasCreatingChannel && !isCreatingChannel && !createChannelError.length) ||
        (wasJoiningChannel && !isJoiningChannel) ||
        (wasInvitingChannel && !isInvitingChannel)
      ) {
        setState({
          isBrowseChannelModalOpen: false,
        })
        navigate(`/messages?channelId=${channelId}`)
      }
    },
    [
      channelId,
      createChannelError.length,
      isCreatingChannel,
      isInvitingChannel,
      isJoiningChannel,
      wasCreatingChannel,
      wasInvitingChannel,
      wasJoiningChannel,
    ]
  )

  useEffect(
    () => {
      if (state.isBrowseChannelModalOpen) {
        getAvailableChannels({ search: null })
        return
      }

      if (state.isDirectMessageModalOpen) {
        getAvailableUsers({ page: null, search: null })
      }
    },
    [
      getAvailableChannels,
      getAvailableUsers,
      state.isBrowseChannelModalOpen,
      state.isDirectMessageModalOpen,
    ]
  )

  const closeModal = useCallback(
    () =>
      setState({
        isDirectMessageModalOpen: false,
        isBrowseChannelModalOpen: false,
      }),
    []
  )

  const onLoadMore = useCallback(
    () => {
      if (state.isDirectMessageModalOpen) {
        getAvailableUsers({ page: availableUsers.next, search: null })
      }
    },
    [availableUsers.next, getAvailableUsers, state.isDirectMessageModalOpen]
  )

  const onSubmitSearch = useCallback(
    search => {
      if (state.isDirectMessageModalOpen) {
        getAvailableUsers({ page: null, search })
      }
    },
    [getAvailableUsers, state.isDirectMessageModalOpen]
  )

  const onParticipantClick = useCallback(
    payload => {
      // create a new chat on reducer
      newPreDirectMessage(payload)
      setState({
        isBrowseChannelModalOpen: false,
        isDirectMessageModalOpen: false,
      })
    },
    [newPreDirectMessage]
  )

  const onJoinChannelClick = useCallback(
    id => {
      setChannel(id)
      joinChannel(id)
    },
    [joinChannel]
  )

  return (
    <>
      <div className={styles['empty-messages']}>
        <svg viewBox={EmptyMessageSvg.viewBox} aria-hidden="true" focusable="false">
          <use xlinkHref={`#${EmptyMessageSvg.id}`} />
        </svg>
        <h2>no messages yet</h2>
        <h3>It seems you don’t have any message yet</h3>
        <div className={styles['buttons-container']}>
          <Button label="Browse Channels" theme={ButtonTheme.DEFAULT} onClick={onBrowseChannels} />
          <Button
            label="New direct message"
            theme={ButtonTheme.DEFAULT}
            onClick={onNewDirectMessage}
          />
        </div>
      </div>
      {state.isBrowseChannelModalOpen && (
        <BrowseChannelsModal
          onClose={closeModal}
          isOpen
          availableChannels={availableChannels}
          isLoading={
            isLoadingChannelList || isCreatingChannel || isJoiningChannel || isInvitingChannel
          }
          getChannels={getAvailableChannels}
          newChannel={newChannel}
          createChannelError={createChannelError.getIn(['name', 0])}
          joinChannel={onJoinChannelClick}
          inviteChannel={inviteChannel}
          isAdmin={isAdmin}
        />
      )}
      {state.isDirectMessageModalOpen && (
        <Modal isOpen onClose={closeModal} className={styles.modal}>
          <ParticipantsModal
            members={availableUsers.users}
            onClose={closeModal}
            theme={ParticipantModalTheme.NEW_DIRECT_MESSAGE}
            hasMore={!!availableUsers.next}
            onLoadMore={onLoadMore}
            onSubmit={onSubmitSearch}
            isLoading={isLoadingUsersToChat}
            onParticipantClick={onParticipantClick}
          />
        </Modal>
      )}
    </>
  )
}

EmptyMessage.propTypes = {
  getAvailableChannels: PropTypes.func,
  getAvailableUsers: PropTypes.func,
  availableUsers: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.number,
    previous: PropTypes.number,
    users: PropTypes.arrayOf(userShape),
  }).isRequired,
  availableChannels: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.number,
    previous: PropTypes.number,
    channels: PropTypes.arrayOf(channelShape),
  }).isRequired,
  newPreDirectMessage: PropTypes.func,
  newChannel: PropTypes.func,
  joinChannel: PropTypes.func,
  inviteChannel: PropTypes.func,
  createChannelError: ImmutablePropTypes.map,
  isLoadingUsersToChat: PropTypes.bool,
  isLoadingChannelList: PropTypes.bool,
  isCreatingChannel: PropTypes.bool,
  isInvitingChannel: PropTypes.bool,
  isJoiningChannel: PropTypes.bool,
  isAdmin: PropTypes.bool,
}

EmptyMessage.defaultProps = {
  getAvailableChannels: () => {},
  getAvailableUsers: () => {},
  newPreDirectMessage: () => {},
  newChannel: () => {},
  joinChannel: () => {},
  inviteChannel: () => {},
  createChannelError: Map(),
  isLoadingUsersToChat: false,
  isLoadingChannelList: false,
  isCreatingChannel: false,
  isInvitingChannel: false,
  isJoiningChannel: false,
  isAdmin: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(EmptyMessage))
