import React from 'react'
import PropTypes from 'prop-types'

import useBodyOverflow from '_hooks/use-body-overflow'
import RocketIcon from '_assets/icons/rocket-icon.svg'
import CloseIcon from '_assets/icons/close.svg'
import Modal from '_components/modal'

import styles from './styles.css'

const NewsletterModal = ({ onClose, title, paragraph, paragraphSpan }) => {
  useBodyOverflow(true)

  return (
    <Modal isOpen onClose={onClose} className={styles.modal}>
      <div className={styles['modal-content']}>
        <header>
          <div className={styles.header}>
            <svg aria-hidden="true" viewBox={RocketIcon.viewBox} className={styles.icon}>
              <use xlinkHref={`#${RocketIcon.id}`} />
            </svg>
            <button
              type="button"
              className={styles['close-button']}
              onClick={onClose}
              aria-label="Close Get In Touch"
            >
              <svg className={styles['close-icon']} viewBox={CloseIcon.viewBox} aria-hidden="true">
                <use xlinkHref={`#${CloseIcon.id}`} />
              </svg>
            </button>
          </div>
        </header>
        <div className={styles.wrapper}>
          <h1 className={styles.title}>{title}</h1>
          <p className={styles.paragraph}>
            {paragraph} {paragraphSpan}
          </p>
        </div>
      </div>
    </Modal>
  )
}

NewsletterModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  title: PropTypes.string,
  paragraph: PropTypes.string,
  paragraphSpan: PropTypes.node,
}

NewsletterModal.defaultProps = {
  title: 'Thank you for starting to explore yourself and the world with Lightyear!',
  paragraph:
    'We will be dropping some tools in your inbox so you can start creating your fully-you, feel-good future now ',
  paragraphSpan: (
    <span role="img" aria-label="Sparkles Emoji">
      ✨
    </span>
  ),
}

export default React.memo(NewsletterModal)
