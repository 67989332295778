import { createReducer } from '_utils/redux'
import { Alert } from '_models/'
import { WEBSOCKET_NEW_ALERT, WEBSOCKET_EXPIRING_ALERT } from '_modules/websocket/actions'

import { GET_ALERT, READ_ALERT } from './actions'

const INITIAL_STATE = new Alert({
  id: undefined,
  content: undefined,
  callToAction: undefined,
  url: undefined,
})

const notifications = createReducer(INITIAL_STATE, {
  [GET_ALERT.FULFILLED]: (state, { payload }) => new Alert(payload),

  [READ_ALERT.FULFILLED]: () => INITIAL_STATE,

  [WEBSOCKET_NEW_ALERT]: (state, { payload }) => new Alert(payload.alert),

  [WEBSOCKET_EXPIRING_ALERT]: () => INITIAL_STATE,
})

export default notifications
