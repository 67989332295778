import cookies from 'react-cookies'

export const DAY_LIMITS = {
  NINETY_DAYS: 90,
  THIRTY_DAYS: 30,
  TEN_DAYS: 10,
  FIVE_DAYS: 5,
  THREE_DAYS: 3,
  ONE_DAY: 1,
}

export const setSubscriptionExpiringCookie = daysLeft => {
  if (daysLeft <= DAY_LIMITS.ONE_DAY) {
    cookies.save('subscriptionExpiringDays', DAY_LIMITS.ONE, { path: '/' })

    return
  }

  if (daysLeft < DAY_LIMITS.THREE_DAYS) {
    cookies.save('subscriptionExpiringDays', DAY_LIMITS.THREE_DAYS, { path: '/' })

    return
  }

  if (daysLeft < DAY_LIMITS.FIVE_DAYS) {
    cookies.save('subscriptionExpiringDays', DAY_LIMITS.FIVE_DAYS, { path: '/' })

    return
  }

  if (daysLeft < DAY_LIMITS.TEN_DAYS) {
    cookies.save('subscriptionExpiringDays', DAY_LIMITS.TEN_DAYS, { path: '/' })
    return
  }

  if (daysLeft < DAY_LIMITS.THIRTY_DAYS) {
    cookies.save('subscriptionExpiringDays', DAY_LIMITS.THIRTY_DAYS, { path: '/' })

    return
  }

  cookies.save('subscriptionExpiringDays', DAY_LIMITS.NINETY_DAYS, { path: '/' })
}

const getBannerDisplayIntervals = value => ({
  NINETY_DAYS: value < DAY_LIMITS.NINETY_DAYS && value > DAY_LIMITS.THIRTY_DAYS,
  THIRTY_DAYS: value <= DAY_LIMITS.THIRTY_DAYS && value > DAY_LIMITS.TEN_DAYS,
  TEN_DAYS: value <= DAY_LIMITS.TEN_DAYS && value > DAY_LIMITS.FIVE_DAYS,
  FIVE_DAYS: value <= DAY_LIMITS.FIVE_DAYS && value > DAY_LIMITS.THREE_DAYS,
  THREE_DAYS: value <= DAY_LIMITS.THREE_DAYS && value > DAY_LIMITS.ONE_DAY,
  ONE_DAY: value === DAY_LIMITS.ONE_DAY,
})

export const shouldShowSubscriptionExpiringBanner = (user, daysLeft) => {
  // The user isn't on a trial subscription, don't bother showing.
  if (user.subscriptionStart) {
    return false
  }

  if (typeof daysLeft !== 'number') {
    return false
  }

  const subscriptionExpiringDaysCookie = cookies.load('subscriptionExpiringDays', { path: '/' })

  if (daysLeft <= DAY_LIMITS.NINETY_DAYS && !subscriptionExpiringDaysCookie) {
    return true
  }
  const daysLeftCookie = Number(subscriptionExpiringDaysCookie)
  const intervals = getBannerDisplayIntervals(daysLeft)

  if (
    Object.keys(intervals).some(intervalKey => {
      if (intervals[intervalKey] && DAY_LIMITS[intervalKey] !== daysLeftCookie) {
        return true
      }
      return false
    })
  ) {
    return true
  }

  return false
}
