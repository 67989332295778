import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Card, { CardSize } from '_components/card'
import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

const SearchFilter = ({
  className,
  filters,
  categories,
  activeCategory,
  activeFilter,
  onCategoryClick,
  onFilterClick,
}) => {
  const filterClick = useCallback(
    event => {
      event.preventDefault()
      onFilterClick(event.currentTarget.id)
    },
    [onFilterClick]
  )

  const categoryClick = useCallback(
    event => {
      event.preventDefault()
      onCategoryClick(event.currentTarget.id)
    },
    [onCategoryClick]
  )

  return (
    <Card theme={CardSize.DEFAULT} className={className}>
      <div className={styles.container}>
        <h3 className={styles.title}>FILTER RESULTS</h3>
        <div>
          <p className={styles['filter-group-name']}>Posted by</p>
          <ul className={styles.filters}>
            {filters.map(filter => (
              <li
                key={filter.label}
                className={classnames({ [styles.active]: activeFilter === filter.ordering })}
              >
                <button
                  type="button"
                  onMouseDown={onMouseDown}
                  onClick={filterClick}
                  id={filter.ordering}
                  className={styles.button}
                >
                  {filter.label}
                </button>
              </li>
            ))}
          </ul>
        </div>
        <div>
          <p className={styles['filter-group-name']}>Post type</p>
          <ul className={styles.filters}>
            {categories.map(category => (
              <li
                key={category.title}
                className={classnames({ [styles.active]: activeCategory === category.title })}
              >
                <button
                  type="button"
                  onClick={categoryClick}
                  onMouseDown={onMouseDown}
                  className={styles.button}
                  id={category.title}
                >
                  {category.title}
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Card>
  )
}

SearchFilter.propTypes = {
  className: PropTypes.string,
  filters: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
    })
  ),
  activeCategory: PropTypes.string,
  activeFilter: PropTypes.string,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      ordering: PropTypes.string,
    })
  ),
  onCategoryClick: PropTypes.func,
  onFilterClick: PropTypes.func,
}

SearchFilter.defaultProps = {
  className: '',
  filters: [],
  categories: [],
  activeCategory: null,
  activeFilter: null,
  onCategoryClick: () => {},
  onFilterClick: () => {},
}

export default SearchFilter
