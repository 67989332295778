import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Svg from '_components/svg'
import { iconPropTypes } from '_utils/proptypes'
import Spinner from '_components/spinner'

import styles from './styles.css'

export const ICON_BUTTON_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  X_LARGE: 'x-large',
}

export const ICON_BUTTON_THEME = {
  DEFAULT: 'default',
  DARK_TRANSPARENT: 'dark-transparent',
}

const IconButton = ({
  href,
  icon,
  size,
  theme,
  type,
  className,
  iconClassName,
  isLoading,
  disabled,
  ...buttonProps
}) => {
  const buttonStyles = useMemo(
    () => ({
      className: classnames(
        styles['icon-button'],
        styles[theme],
        styles[size],
        { [styles.disabled]: disabled },
        className
      ),
    }),
    [className, disabled, size, theme]
  )

  if (href) {
    return (
      <a href={href} {...buttonStyles} {...buttonProps}>
        {isLoading ? (
          <Spinner />
        ) : (
          <Svg className={classnames(styles.icon, iconClassName)} icon={icon} />
        )}
      </a>
    )
  }

  return (
    <button type={type} {...buttonStyles} {...buttonProps} disabled={disabled}>
      {isLoading ? (
        <Spinner />
      ) : (
        <Svg className={classnames(styles.icon, iconClassName)} icon={icon} />
      )}
    </button>
  )
}

IconButton.propTypes = {
  href: PropTypes.string,
  icon: iconPropTypes.isRequired,
  size: PropTypes.oneOf(Object.values(ICON_BUTTON_SIZE)),
  theme: PropTypes.oneOf(Object.values(ICON_BUTTON_THEME)),
  type: PropTypes.string,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  iconClassName: PropTypes.string,
}

IconButton.defaultProps = {
  href: '',
  size: ICON_BUTTON_SIZE.MEDIUM,
  theme: ICON_BUTTON_THEME.DEFAULT,
  type: 'button',
  className: '',
  isLoading: false,
  disabled: false,
  iconClassName: '',
}

export default React.memo(IconButton)
