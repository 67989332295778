import React, { useCallback, useState, Fragment } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import Input from '_components/input'
import { goalCategoryShape } from '_utils/proptypes'

import ModalHeader from '../modal-header'
import ModalFooter from '../modal-footer'

import styles from './styles.css'

const ManageGoalCategories = ({
  removeGoalCategory,
  defaultCategories,
  editGoalCategory,
  newGoalCategory,
  categories,
  className,
  loading,
  onClose,
}) => {
  const [state, setState] = useState(() => {
    const allGoals = [...categories, ...Array(5 - categories.length).fill('')]
    return allGoals.map(
      (category, index) =>
        category.id
          ? {
              ...category,
              default: !!defaultCategories.find(item => item === category.title),
              new: false,
              declaration: category.declaration || '',
            }
          : { id: index, default: false, title: '', declaration: '', new: true }
    )
  })

  const onInputChange = useCallback(
    id => event => {
      event.preventDefault()
      const { name, value } = event.target
      setState(
        state.map(category => (category.id === id ? { ...category, [name]: value } : category))
      )
    },
    [state]
  )

  const prepareAndCreateNew = useCallback(
    category => (
        category.title !== '' &&
        newGoalCategory({ title: category.title, declaration: category.declaration })
      ),
    [newGoalCategory]
  )

  const prepareAndUpdate = useCallback(
    category => {
      if (category.default) {
        return editGoalCategory({ declaration: category.declaration }, category.id)
      }
      if (category.title === '') {
        return removeGoalCategory(category.id)
      }
      return editGoalCategory(
        { title: category.title, declaration: category.declaration },
        category.id
      )
    },
    [editGoalCategory, removeGoalCategory]
  )

  const onFormSubmit = useCallback(
    event => {
      event.preventDefault()
      state.map(
        category => (category.new ? prepareAndCreateNew(category) : prepareAndUpdate(category))
      )
    },
    [state, prepareAndCreateNew, prepareAndUpdate]
  )

  return (
    <div className={classnames(styles.container, className)}>
      <ModalHeader className={styles.header} title="ADD DECLARATIONS" onClose={onClose} />
      <form className={styles.form} onSubmit={onFormSubmit}>
        <div className={styles.content}>
          {state.map(
            (category, index) =>
              category.default ? (
                // eslint-disable-next-line react/no-array-index-key
                <Fragment key={`${category.id}+${index}`}>
                  <h3 className={styles.title}>{category.title} DECLARATION</h3>
                  <Input
                    disabled={loading}
                    name="declaration"
                    onChange={onInputChange(category.id)}
                    value={state[index].declaration}
                  />
                  {state.length - 1 > index && <div className={styles.divider} />}
                </Fragment>
              ) : (
                <Fragment key={category.id}>
                  <h3>OPTIONAL CATEGORY {index - 2}</h3>
                  <Input
                    disabled={loading}
                    label="Title"
                    name="title"
                    onChange={onInputChange(category.id)}
                    value={state[index].title}
                  />
                  <Input
                    disabled={loading}
                    label="Declaration"
                    name="declaration"
                    onChange={onInputChange(category.id)}
                    value={state[index].declaration}
                  />
                  {state.length - 1 > index && <div className={styles.divider} />}
                </Fragment>
              )
          )}
        </div>
        <ModalFooter className={styles.footer} loading={loading} buttonLabel="Save Changes" />
      </form>
    </div>
  )
}

ManageGoalCategories.propTypes = {
  className: PropTypes.string,
  categories: PropTypes.arrayOf(goalCategoryShape).isRequired,
  defaultCategories: PropTypes.arrayOf(PropTypes.string),
  onClose: PropTypes.func,
  newGoalCategory: PropTypes.func,
  editGoalCategory: PropTypes.func,
  removeGoalCategory: PropTypes.func,
  loading: PropTypes.bool,
}

ManageGoalCategories.defaultProps = {
  className: '',
  loading: false,
  onClose: () => {},
  newGoalCategory: () => {},
  editGoalCategory: () => {},
  removeGoalCategory: () => {},
  defaultCategories: ['Personal'],
}

export default React.memo(ManageGoalCategories)
