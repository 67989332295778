import { Map } from 'immutable'

import { VALIDATE_TOKEN } from './actions'

export const isValidateTokenLoadingSelector = state => !!state.loading.get(VALIDATE_TOKEN.ACTION)

export const validateTokenErrorSelector = state => state.error.get(VALIDATE_TOKEN.ACTION, Map())

export const companySelector = state => state.company

export const emailSelector = state => state.company.get('email')
