import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'

import { COURSE_TYPES_INFO } from '_constants/course'
import VisionPlusGoalsPlanetIcon from '_assets/icons/vision-plus-goals-planet-icon.svg'
import CourseTag from '_components/course-tag'
import ViewClassesLink from '_components/view-classes-link'
import { handleCourseTagThemeAndIcon } from '_utils/helpers'
import { getCategoryClassesTotalCountSelector } from '_modules/public-categories/selectors'

import styles from './styles.css'

const PlanetClasses = ({
  title,
  description,
  url,
  courseTags,
  theme,
  className,
  initialSearchState,
}) => {
  const availableClasses = useSelector(state => getCategoryClassesTotalCountSelector(state, title))

  return (
    <article className={classnames(styles['planet-container'], styles[theme.className], className)}>
      <div className={classnames(styles['planet-orbit'], styles[`${theme.className}-orbit`])}>
        <svg
          aria-label={`${title} planet`}
          role="img"
          viewBox={VisionPlusGoalsPlanetIcon.viewBox}
          className={classnames(styles['planet-icon'], styles[theme.className])}
        >
          <use xlinkHref={`#${theme.icon.id}`} />
        </svg>
      </div>
      <div className={styles['planet-content']}>
        {courseTags && (
          <div className={styles['tags-wrapper']}>
            {courseTags.map((tag, index) => (
              <CourseTag
                className={styles['course-tag']}
                icon={handleCourseTagThemeAndIcon(courseTags.length, index)?.icon}
                theme={handleCourseTagThemeAndIcon(courseTags.length, index).theme}
                key={tag}
                text={tag}
              />
            ))}
          </div>
        )}
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{description}</p>
      </div>
      <ViewClassesLink
        className={styles['view-classes-container']}
        availableClasses={availableClasses}
        title={title}
        url={url}
        initialSearchState={initialSearchState}
      />
    </article>
  )
}

PlanetClasses.propTypes = {
  title: PropTypes.string,
  url: PropTypes.string,
  description: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(COURSE_TYPES_INFO)),
  courseTags: PropTypes.node,
  className: PropTypes.string,
  initialSearchState: PropTypes.shape({
    search: PropTypes.string,
  }),
}

PlanetClasses.defaultProps = {
  title: '',
  url: '',
  description: '',
  theme: COURSE_TYPES_INFO.WORKSHOPS,
  courseTags: null,
  className: '',
  initialSearchState: null,
}

export default PlanetClasses
