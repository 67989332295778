export const INITIAL_STATE = {
  password: '',
  passwordError: '',
  passwordConfirmation: '',
  passwordConfirmationError: '',
}

export const UPDATE_STATE = 'UPDATE_STATE'
export const RESET_ERRORS = 'RESET_ERRORS'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case RESET_ERRORS:
      return {
        ...state,
        passwordError: '',
        passwordConfirmationError: '',
      }
    default:
      return state
  }
}
