import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'

import { onMouseDown } from '_utils/aria'
import DownloadIcon from 'assets/icons/ic_download_24px.svg'
import MoreIcon from 'assets/icons/ic_arrow_24px.svg'
import ArrowDownIcon from 'assets/icons/arrowdown-16-px.svg'
import Spinner from '_components/spinner'
import { formatUrl, isExternalUrl } from '_utils/helpers'
import Svg from '_components/svg'
import { iconPropTypes } from '_utils/proptypes'

import styles from './styles.css'

export const ButtonTheme = {
  DEFAULT: 'default',
  MALIBU: 'malibu',
  DOWNLOAD: 'download',
  MELROSE: 'melrose',
  MORE: 'more',
  MORE_GREEN: 'more-green',
  MORE_PURPLE: 'more-purple',
  TRANSPARENT_BACKGROUND_MALIBU_TEXT: 'transparent-background-malibu-text',
  EXPAND_GREEN: 'expand-green',
  SIMPLE_LINK: 'simple-link',
  TRANSPARENT_BACKGROUND_GREEN_TEXT: 'transparent-background-green-text',
  TRANSPARENT: 'transparent',
  TRANSPARENT_GRAY: 'transparent-gray',
  GHOST: 'ghost',
}

export const ButtonSize = {
  SMALL: 'small',
  NORMAL: 'normal',
  BIG: 'big',
  MINIMAL: 'minimal',
}

const Button = ({
  theme,
  url,
  size,
  className,
  children,
  type,
  state,
  isLoading,
  disabled,
  flipIcon,
  endAdornment,
  ...buttonProps
}) => {
  const icon = useMemo(
    () => {
      switch (theme) {
        case ButtonTheme.DOWNLOAD:
          return DownloadIcon
        case ButtonTheme.MORE:
        case ButtonTheme.MORE_GREEN:
          return MoreIcon
        case ButtonTheme.EXPAND_GREEN:
          return ArrowDownIcon
        default:
          return null
      }
    },
    [theme]
  )

  if (url) {
    const formattedUrl = formatUrl(url)

    if (isExternalUrl(formattedUrl)) {
      return (
        <a
          href={formattedUrl}
          target="_blank"
          rel="noopener noreferrer"
          disabled={disabled}
          className={classnames(styles.button, styles[size], styles[theme], className)}
          {...buttonProps}
        >
          {children}
          {icon && (
            <svg
              aria-hidden="true"
              viewBox={icon.viewBox}
              className={classnames(styles.icon, { [styles['flipped-icon']]: flipIcon })}
            >
              <use xlinkHref={`#${icon.id}`} />
            </svg>
          )}
        </a>
      )
    }

    return (
      <Link
        to={url}
        className={classnames(styles.button, styles[size], styles[theme], className)}
        onMouseDown={onMouseDown}
        state={state}
        disabled={disabled}
        {...buttonProps}
      >
        {children}
        {icon && (
          <svg
            aria-hidden="true"
            viewBox={icon.viewBox}
            className={classnames(styles.icon, { [styles['flipped-icon']]: flipIcon })}
          >
            <use xlinkHref={`#${icon.id}`} />
          </svg>
        )}
      </Link>
    )
  }

  return (
    <button
      className={classnames(styles.button, styles[size], styles[theme], className)}
      type={type}
      disabled={disabled || isLoading}
      {...buttonProps}
      onMouseDown={onMouseDown}
    >
      {isLoading ? (
        <Spinner size={20} />
      ) : (
        <>
          {children}
          {icon && (
            <svg
              aria-hidden="true"
              viewBox={icon.viewBox}
              className={classnames(styles.icon, { [styles['flipped-icon']]: flipIcon })}
            >
              <use xlinkHref={`#${icon.id}`} />
            </svg>
          )}
          {!!endAdornment && (
            <Svg
              icon={endAdornment}
              className={classnames(styles.icon, { [styles['flipped-icon']]: flipIcon })}
            />
          )}
        </>
      )}
    </button>
  )
}

Button.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.keys(ButtonTheme).map(key => ButtonTheme[key])),
  size: PropTypes.oneOf(Object.keys(ButtonSize).map(key => ButtonSize[key])),
  children: PropTypes.node,
  url: PropTypes.string,
  onClick: PropTypes.func,
  state: PropTypes.shape({
    redirectTo: PropTypes.string,
  }),
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool,
  flipIcon: PropTypes.bool,
  endAdornment: iconPropTypes,
}

Button.defaultProps = {
  type: 'button',
  className: '',
  theme: ButtonTheme.DEFAULT,
  size: ButtonSize.NORMAL,
  url: '',
  onClick: () => {},
  state: {},
  children: null,
  isLoading: false,
  disabled: false,
  flipIcon: false,
  endAdornment: null,
}

export default React.memo(Button)
