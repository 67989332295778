export const DIRECT_MESSAGE = 'DIRECT_MESSAGE'
export const CHANNEL = 'CHANNEL'

export const MESSAGES_ACTIONS = {
  OPEN_PARTICIPANT_MODAL: 'OPEN_PARTICIPANT_MODAL',
  NEW_DIRECT_MESSAGE: 'NEW_DIRECT_MESSAGE',
  TOOGLE_MODAL_OPEN: 'TOOGLE_MODAL_OPEN',
  CHANGE_MESSAGE: 'CHANGE_MESSAGE',
  LOADING_MORE: 'LOADING_MORE',
  MODAL_CLOSE: 'MODAL_CLOSE',
  CLOSE_CHAT: 'CLOSE_CHAT',
  EMPTY: 'EMPTY',
}

export const initialState = {
  isChatOpened: true,
  isModalOpen: false,
  isAddParticipantModalOpen: false,
  directChatUsername: null,
  channelId: null,
  isEmpty: false,
  selected: null,
}

const messagesReducer = (state, action) => {
  switch (action.type) {
    case MESSAGES_ACTIONS.CHANGE_MESSAGE:
      return {
        ...state,
        isChatOpened: true,
        directChatUsername: action.directChatUsername,
        channelId: action.channelId,
        selected: action.selected,
        isEmpty: action.isEmpty || false,
      }
    case MESSAGES_ACTIONS.NEW_DIRECT_MESSAGE:
      return {
        ...state,
        isEmpty: false,
        directChatUsername: action.directChatUsername,
        selected: DIRECT_MESSAGE,
      }
    case MESSAGES_ACTIONS.CLOSE_CHAT:
      return {
        ...state,
        isChatOpened: false,
      }
    case MESSAGES_ACTIONS.TOOGLE_MODAL_OPEN:
      return {
        ...state,
        isModalOpen: action.isModalOpen,
      }
    case MESSAGES_ACTIONS.OPEN_PARTICIPANT_MODAL:
      return {
        ...state,
        isAddParticipantModalOpen: action.isAddParticipantModalOpen,
      }
    case MESSAGES_ACTIONS.MODAL_CLOSE:
      return {
        ...state,
        isModalOpen: false,
        isAddParticipantModalOpen: false,
      }
    case MESSAGES_ACTIONS.LOADING_MORE:
      return {
        ...state,
        isLoadingMoreMessages: action.isLoadingMoreMessages,
      }
    case MESSAGES_ACTIONS.EMPTY:
      return {
        ...state,
        isEmpty: true,
        directChatUsername: null,
        channelId: null,
        selected: null,
      }
    default:
      return state
  }
}

export default messagesReducer
