import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'

import CourseCard from '_components/course-card'
import {
  GET_COMPANY_COURSES,
  GET_PUBLIC_COURSES,
  getPublicCourses,
  getCompanyClasses,
} from '_modules/course/actions'
import { getOnboardingCourses } from '_modules/course/selectors'
import Footer from '_components/footer'
import { classShape } from '_utils/proptypes'
import { COURSE_TYPES_INFO } from '_constants/course'

import StepHeader from '../step-header'

import styles from './styles.css'

const mapStateToProps = state => ({
  loading:
    !!state.loading.get(GET_COMPANY_COURSES.ACTION) ||
    !!state.loading.get(GET_PUBLIC_COURSES.ACTION),
  error: state.error.get(GET_COMPANY_COURSES.ACTION) || state.error.get(GET_PUBLIC_COURSES.ACTION),
  courses: getOnboardingCourses(state, state.user.belongsToACompany),
  belongsToACompany: state.user.belongsToACompany,
})

const mapDispatchToProps = {
  getAllCompanyCourses: getCompanyClasses,
  getDiscoverCourses: getPublicCourses,
}

const CourseList = ({
  getAllCompanyCourses,
  getDiscoverCourses,
  error,
  courses,
  loading,
  belongsToACompany,
}) => {
  useEffect(
    () => {
      if (belongsToACompany) {
        getAllCompanyCourses()
        return
      }
      getDiscoverCourses(null, true)
    },
    [belongsToACompany, getAllCompanyCourses, getDiscoverCourses]
  )

  return (
    <div className={styles.container}>
      <StepHeader
        belongsToACompany={belongsToACompany}
        className={styles.header}
        step={1}
        stepTitle="Choose Your Adventure"
      />
      <div className={styles.content}>
        <p className={styles.text}>
          {COURSE_TYPES_INFO.VISION_PLUS_GOALS.name} is the beginning of this transformative
          adventure. In it, you will understand the underlying principles upon which your Lightyear
          journey is built while exploring the Lightyear Hub. It is your first step in this exciting
          adventure.
        </p>
        <p className={styles.text}>
          Click enroll now to choose your {COURSE_TYPES_INFO.VISION_PLUS_GOALS.name} course option.
        </p>
      </div>
      {!belongsToACompany && (
        <div className={styles['normal-user-section']}>
          <p className={styles['normal-text']}>
            With your purchase of {COURSE_TYPES_INFO.VISION_PLUS_GOALS.name} you ALSO get{' '}
            <b className={styles.bold}>90</b> days of access to the Lightyear Hub. After which you
            will be billed $15 per month* for access to the Hub.
          </p>
          <p className={classnames(styles['normal-text'], styles.bold)}>
            Subscriptions are billed month to month, you can cancel at any time.
          </p>
        </div>
      )}
      <div className={styles.courses}>
        {courses.length >= 1 &&
          !loading &&
          error.size === 0 &&
          courses.map(course => (
            <CourseCard
              target={`/onboarding/class/${course.id}`}
              key={course.id}
              className={styles.card}
              course={course}
              isCompany={belongsToACompany}
            />
          ))}
      </div>
      <Footer className={styles.footer} svgClassName={styles.logo} linkClassName={styles.link} />
    </div>
  )
}

CourseList.propTypes = {
  getAllCompanyCourses: PropTypes.func.isRequired,
  getDiscoverCourses: PropTypes.func.isRequired,
  error: ImmutablePropTypes.map,
  courses: PropTypes.arrayOf(classShape).isRequired,
  loading: PropTypes.bool,
  belongsToACompany: PropTypes.bool.isRequired,
}

CourseList.defaultProps = {
  loading: false,
  error: Map(),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseList)
