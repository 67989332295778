import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { navigate, Link } from '@reach/router'

import Card from '_components/card'
import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import { RoleType } from '_utils/constants'
import Button, { ButtonTheme } from '_components/button'
import { userShape } from '_utils/proptypes'

import styles from './styles.css'

const ParticipantCard = ({ participant, onMessageClick, intention, isLeader }) => {
  const onNewMessageClick = useCallback(
    () =>
      onMessageClick({
        id: participant.id,
        username: participant.username,
        name: participant.name,
        photo: participant.photo,
      }),
    [onMessageClick, participant.id, participant.name, participant.photo, participant.username]
  )

  const onViewFutureClick = useCallback(() => navigate(`/user/${participant.username}`), [
    participant.username,
  ])

  return (
    <Card className={styles.container}>
      <div className={styles['profile-content']}>
        <ProfileAvatar
          className={styles.avatar}
          username={participant.username}
          src={participant.photo}
          roleType={RoleType[participant.role]}
          size={AvatarSize.SMALL}
        />
        <div className={styles.profile}>
          <Link className={styles.name} to={`/user/${participant.username}`}>
            {participant.name}
          </Link>
          <Link className={styles.username} to={`/user/${participant.username}`}>
            @{participant.username}
          </Link>
          {isLeader && <p className={styles['coach-tag']}>Coach</p>}
        </div>
        <div className={styles.actions}>
          <Button theme={ButtonTheme.PRIMARY} label="VIEW FUTURE" onClick={onViewFutureClick} />
          <Button theme={ButtonTheme.PRIMARY} label="MESSAGE" onClick={onNewMessageClick} />
        </div>
      </div>
      {intention && (
        <div className={styles.intention}>
          <p>{intention}</p>
        </div>
      )}
    </Card>
  )
}

ParticipantCard.propTypes = {
  participant: userShape.isRequired,
  onMessageClick: PropTypes.func,
  intention: PropTypes.string,
  isLeader: PropTypes.bool,
}

ParticipantCard.defaultProps = {
  onMessageClick: () => {},
  intention: null,
  isLeader: false,
}

export default React.memo(ParticipantCard)
