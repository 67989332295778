import PlanetsPathIcon from '_assets/icons/planets-path-icon.svg'
import PlanetsPathTabletIcon from '_assets/icons/planets-path-table-icon.svg'
import { FLAG_NEW_LANDING_PAGES } from '_config/environment'

export const MOBILE_WIDTH = 767
export const MEDIUM_WIDTH = 1199

export const linearGradientBg = `linear-gradient(rgba(56, 61, 91, 0.64), rgba(56, 61, 91, 0.64))`

export const RoleType = {
  EXPLORER: 'EXPLORER',
  GUIDER: 'GUIDER',
  ADMIN: 'ADMIN',
  LEADER: 'LEADER',
  SUSPENDED: 'SUSPENDED',
}

export const StripeOptions = {
  style: {
    base: {
      fontFamily: "'Poppins', sans-serif",
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      color: '#55586b',
      '::placeholder': {
        fontSize: '16px',
        color: '#bbbcc3',
      },
    },
    invalid: {
      color: '#55586b',
    },
  },
}

export const StripeNewOptions = {
  option: {
    style: {
      base: {
        fontFamily: "'Poppins', sans-serif",
        fontSize: '14px',
        fontWeight: '500',
        fontStyle: 'normal',
        lineHeight: '1.71',
        color: '#2a2e46',
        '::placeholder': {
          fontSize: '14px',
          color: '#ffffff',
        },
      },
      invalid: {
        color: '#55586b',
      },
    },
  },
}

export const MessageTheme = {
  USER_MESSAGE: 'user-message',
  OWN_MESSAGE: 'own-message',
}

export const tagsRegex = /\B@[a-z0-9_-]+/i

export const LoadingColor = '#7387FF'

export const NOT_FOUND = 'Not found.'

export const SUBSCRIPTION_STATUS = {
  TRIALING: 'TRIALING',
  ACTIVE: 'ACTIVE',
  CANCELED: 'CANCELED',
}

export const PLANET_PATHS = {
  DESKTOP: PlanetsPathIcon,
  TABLET: PlanetsPathTabletIcon,
}

export const EXPLORE_COURSES_SECTION_TITLE = [
  [
    [
      {
        text: 'Meet our',
        type: 'strong',
      },
    ],
  ],
  [
    [
      {
        text: 'Lightyear programs',
        type: 'paragraph',
      },
    ],
  ],
]

export const COACH_LIST_SIZE = {
  ONE: 1,
  TWO: 2,
  THREE: 3,
}

export const PACE_TYPES = {
  COACH_LED: 'Coach-led',
  SELF_PACED: 'Self-paced',
}

export const TOGGLE_FILTER_OPTIONS = {
  COACH_LED: {
    label: PACE_TYPES.COACH_LED,
    value: 'COACH_LED',
  },
  SELF_PACED: {
    label: PACE_TYPES.SELF_PACED,
    value: FLAG_NEW_LANDING_PAGES ? 'SELF_PACED' : 'SELF_LED',
  },
}

export const AVAILABLE_CLASSES_BUTTON_TYPE = {
  FIRST_SHOW: 'firstShow',
  HIDE: 'hide',
  SECOND_SHOW: 'secondShow',
}

export const DEVICE_TYPES = {
  DESKTOP: 'desktop',
  MEDIUM: 'medium',
  MOBILE: 'mobile',
}

export const LANDING_PAGE_SLICES_SIZES = {
  HERO_HEIGHT: {
    SHOW_TYPE: AVAILABLE_CLASSES_BUTTON_TYPE.FIRST_SHOW,
    [DEVICE_TYPES.DESKTOP]: 948,
    [DEVICE_TYPES.MEDIUM]: 920,
    [DEVICE_TYPES.MOBILE]: 1154,
  },
  CLARITY_HEIGHT: {
    SHOW_TYPE: AVAILABLE_CLASSES_BUTTON_TYPE.FIRST_SHOW,
    [DEVICE_TYPES.DESKTOP]: 476,
    [DEVICE_TYPES.MEDIUM]: 834,
    [DEVICE_TYPES.MOBILE]: 881,
  },
  CLASSES_HEIGHT: {
    SHOW_TYPE: AVAILABLE_CLASSES_BUTTON_TYPE.HIDE,
    [DEVICE_TYPES.DESKTOP]: 1126,
    [DEVICE_TYPES.MEDIUM]: 1342,
    [DEVICE_TYPES.MOBILE]: 2084,
  },
  MINI_COURSES_HEIGHT: {
    SHOW_TYPE: AVAILABLE_CLASSES_BUTTON_TYPE.SECOND_SHOW,
    [DEVICE_TYPES.DESKTOP]: 1096,
    [DEVICE_TYPES.MEDIUM]: 1380,
    [DEVICE_TYPES.MOBILE]: 1940,
  },
  VIDEO_HEIGHT: {
    SHOW_TYPE: AVAILABLE_CLASSES_BUTTON_TYPE.SECOND_SHOW,
    [DEVICE_TYPES.DESKTOP]: 977,
    [DEVICE_TYPES.MEDIUM]: 693,
    [DEVICE_TYPES.MOBILE]: 553,
  },
  QUOTE_HEIGHT: {
    SHOW_TYPE: AVAILABLE_CLASSES_BUTTON_TYPE.SECOND_SHOW,
    [DEVICE_TYPES.DESKTOP]: 601,
    [DEVICE_TYPES.MEDIUM]: 598,
    [DEVICE_TYPES.MOBILE]: 868,
  },
  QUESTIONS_HEIGHT: {
    SHOW_TYPE: AVAILABLE_CLASSES_BUTTON_TYPE.SECOND_SHOW,
    [DEVICE_TYPES.DESKTOP]: 730,
    [DEVICE_TYPES.MEDIUM]: 697,
    [DEVICE_TYPES.MOBILE]: 685,
  },
}

export const HIDE_BUTTON_DESKTOP = Object.values(LANDING_PAGE_SLICES_SIZES).reduce((acc, value) => value.SHOW_TYPE === AVAILABLE_CLASSES_BUTTON_TYPE.FIRST_SHOW ? acc + value.DESKTOP : acc, 0)

export const IMAGE_TYPES = /(jpg|png|JPG|PNG|JPEG|jpeg)$/

export const CLASS_FILTERS = [
  {
    label: 'ONGOING',
    ordering: 'ongoing',
  },
  {
    label: 'COMPLETED',
    ordering: 'completed',
  },
  {
    label: 'ALL',
    ordering: 'all',
  },
]
