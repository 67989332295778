import { List } from 'immutable'

import Model from './model'

class Search extends Model({
  classes: List(),
  channels: List(),
  users: List(),
  messages: List(),
  posts: List(),
}) {}

export { Search as default }
