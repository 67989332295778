import { List } from 'immutable'

import { createReducer } from '_utils/redux'

import ASSIGNMENT_ACTIONS from './actions'

const { GET_ALL_ASSIGNMENT_RESPONSES } = ASSIGNMENT_ACTIONS

const INITIAL_STATE = List()

const assignments = createReducer(INITIAL_STATE, {
  [GET_ALL_ASSIGNMENT_RESPONSES.FULFILLED]: (state, { payload }) => List(payload),
})

export default assignments
