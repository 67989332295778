import { List } from 'immutable'

import Model from './model'

import { User } from '.'

class Post extends Model({
  id: undefined,
  shazams: undefined,
  createdAt: undefined,
  updatedAt: undefined,
  message: undefined,
  videoLink: undefined,
  photo: undefined,
  flagged: undefined,
  author: new User(),
  comments: List(),
  shazamedByYou: undefined,
}) {}

export { Post as default }
