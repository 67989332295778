import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { List } from 'immutable'

import UserPlaceholderIcon from '_assets/icons/user-placeholder.svg'
import Svg from '_components/svg'

import styles from './styles.css'

const CoachesChip = ({ coachesList, className }) => {
  if (coachesList?.size > 2) {
    const firstCoaches = coachesList.slice(0, 2)

    return (
      <div className={classnames(styles['coach-container'], className)}>
        {firstCoaches.map(
          coach =>
            coach.get('photo') ? (
              <img
                key={coach?.get('id')}
                src={coach?.get('photo')}
                alt={`${coach?.get('name')}'s avatar`}
                className={styles['coach-avatar']}
              />
            ) : (
              <Svg
                key={coach?.get('id')}
                icon={UserPlaceholderIcon}
                className={styles['coach-avatar']}
              />
            )
        )}
        <p className={classnames(styles['coach-avatar'], styles['other-coaches'])}>
          + {coachesList.size - 2}
        </p>

        <p
          className={classnames(styles['coaches-name'], {
            [styles['coaches-name-space']]: coachesList.size <= 1,
          })}
        >
          with <span className={styles.name}>{firstCoaches?.first()?.get('name')}</span> and{' '}
          {coachesList.size - 1} more
        </p>
      </div>
    )
  }

  if (coachesList?.size > 0) {
    return (
      <div className={classnames(styles['coach-container'], className)}>
        {coachesList.map(
          coach =>
            coach.get('photo') ? (
              <img
                key={coach?.get('id')}
                src={coach?.get('photo')}
                alt={`${coach?.get('name')}'s avatar`}
                className={styles['coach-avatar']}
              />
            ) : (
              <Svg
                key={coach?.get('id')}
                icon={UserPlaceholderIcon}
                className={styles['coach-avatar']}
              />
            )
        )}
        <p
          className={classnames(styles['coaches-name'], {
            [styles['names-margin']]: coachesList.size <= 1,
          })}
        >
          with <span className={styles.name}>{coachesList?.first()?.get('name')}</span>
          {coachesList.size > 1 ? ` and ${coachesList.size - 1} more` : ''}
        </p>
      </div>
    )
  }

  return null
}

CoachesChip.propTypes = {
  coachesList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
        occupation: PropTypes.string,
        avatar: PropTypes.string,
      })
    ),
    PropTypes.instanceOf(List),
  ]),
  className: PropTypes.string,
}

CoachesChip.defaultProps = {
  coachesList: [],
  className: '',
}

export default CoachesChip
