import * as classService from '_services/class'
import { defineAction } from '_utils/redux'

export const GET_CLASSES = defineAction('GET_CLASSES')
export const GET_MORE_CLASSES = defineAction('GET_MORE_CLASSES')
export const GET_CLASSES_BY_COURSE = defineAction('GET_CLASSES_BY_COURSE')
export const GET_CLASS = defineAction('GET_CLASS')
export const GET_PUBLIC_CLASS = defineAction('GET_PUBLIC_CLASS')
export const INITIAL_ENROLL = defineAction('INITIAL_ENROLL')
export const ENROLL = defineAction('ENROLL')
export const UPDATE_ASSIGNMENT = defineAction('UPDATE_ASSIGNMENT')
export const BULK_UPDATE_ANSWER = defineAction('BULK_UPDATE_ANSWER')
export const UPDATE_INTENTION = defineAction('UPDATE_INTENTION')
export const GET_ALL_PARTICIPANTS = defineAction('GET_ALL_PARTICIPANTS')
export const START_CALL = defineAction('START_CALL')
export const ENABLE_ASSIGNMENT_VIDEO_CALL = 'ENABLE_ASSIGNMENT_VIDEO_CALL'
export const VALIDATE_DISCOUNT_COUPON = defineAction('VALIDATE_DISCOUNT_COUPON')
export const CLEAR_COUPON_STATE = 'CLEAR_COUPON_STATE'
// Modular classes endpoints
export const GET_CLASSES_NEW = defineAction('GET_CLASSES_NEW')

export const getClasses = ordering => (dispatch, getState) => {
  dispatch({
    type: GET_CLASSES.ACTION,
    payload: classService.getClasses(getState().user.get('authToken'))(ordering),
  })
}

export const getMoreClasses = ordering => (dispatch, getState) => {
  dispatch({
    type: GET_MORE_CLASSES.ACTION,
    payload: classService.getClasses(getState().user.get('authToken'))(ordering),
  })
}

export const getClass = classId => (dispatch, getState) => {
  dispatch({
    type: GET_CLASS.ACTION,
    payload: classService.getClass(getState().user.get('authToken'))(classId),
  })
}

export const getPublicClass = classId => (dispatch, getState) => {
  dispatch({
    type: GET_PUBLIC_CLASS.ACTION,
    payload: classService.getPublicClass(getState().user.get('authToken'), classId),
  })
}

export const initialEnroll = payload => (dispatch, getState) => {
  dispatch({
    type: INITIAL_ENROLL.ACTION,
    payload: classService.initialEnroll(getState().user.get('authToken'))(payload),
  })
}

export const enroll = (classId, token = null) => (dispatch, getState) => {
  dispatch({
    type: ENROLL.ACTION,
    payload: classService.enroll(getState().user.get('authToken'))(classId, token),
  })
}

export const updateAssignment = (classId, assignmentId, payload) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_ASSIGNMENT.ACTION,
    payload: classService.updateAssignment(getState().user.get('authToken'))(
      classId,
      assignmentId,
      payload
    ),
    meta: {
      classId,
      assignmentId,
    },
  })
}

export const bulkUpdateAnswers = (classId, assignmentId, payload) => (dispatch, getState) => {
  dispatch({
    type: BULK_UPDATE_ANSWER.ACTION,
    payload: classService.bulkUpdateAnswer(getState().user.get('authToken'))(
      classId,
      assignmentId,
      payload
    ),
    meta: {
      classId,
      assignmentId,
    },
  })
}

export const updateIntention = ({ classId, ...payload }) => (dispatch, getState) => {
  dispatch({
    type: UPDATE_INTENTION.ACTION,
    payload: classService.updateIntention(getState().user.authToken)(classId, payload),
    meta: {
      classId,
    },
  })
}

export const getAllParticipants = (classId, page) => (dispatch, getState) => {
  dispatch({
    type: GET_ALL_PARTICIPANTS.ACTION,
    payload: classService.getAllParticipants(getState().user.authToken)(classId, page),
    meta: {
      classId,
      loadMore: false,
      page,
    },
  })
}

export const startAssignmentVideo = ({ classId, assignmentId, meetingId }) => (
  dispatch,
  getState
) => {
  dispatch({
    type: START_CALL.ACTION,
    payload: classService.startAssignmentVideo(getState().user.authToken)(meetingId),
    meta: {
      classId,
      assignmentId,
      meetingId,
    },
  })
}

export const enableAssignmentVideoCall = ({ classId, assignmentId }) => ({
  type: ENABLE_ASSIGNMENT_VIDEO_CALL,
  meta: {
    classId,
    assignmentId,
  },
})

export const validateDiscountCoupon = ({ code, classId }) => (dispatch, getState) =>
  dispatch({
    type: VALIDATE_DISCOUNT_COUPON.ACTION,
    payload: classService.validateDiscountCoupon(getState().user.authToken)(code),
    meta: { classId },
  })

export const clearCouponState = classId => dispatch =>
  dispatch({
    type: CLEAR_COUPON_STATE,
    meta: { classId },
  })

// Modular classes endpoints
export const getClassesNew = ordering => (dispatch, getState) => {
  dispatch({
    type: GET_CLASSES_NEW.ACTION,
    payload: classService.getClassesNew(getState().user.get('authToken'))(ordering),
  })
}
