import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { prismicContentPropType } from '_utils/proptypes'

import Panel from './panel'
import styles from './styles.css'

const ExpandableComponent = ({ className, content }) => (
  <div className={classnames(styles.container, className)}>
    {content.map(({ question, answer }) => (
      <Panel key={question} className={styles.panel} question={question} answer={answer} />
    ))}
  </div>
)

ExpandableComponent.propTypes = {
  className: PropTypes.string,
  content: PropTypes.arrayOf(
    PropTypes.shape({
      question: PropTypes.string,
      answer: prismicContentPropType,
    })
  ).isRequired,
}

ExpandableComponent.defaultProps = {
  className: '',
}

export default React.memo(ExpandableComponent)
