import React, { useCallback, Fragment, useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { connect } from 'react-redux'

import ArrowLeftIcon from '_assets/icons/arrow-left.svg'
import Footer from '_components/footer'
import { classShape, assignmentShape, userShape } from '_utils/proptypes'
import { onMouseDown } from '_utils/aria'
import Card from '_components/card'
import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import ASSIGNMENT_ACTIONS from '_modules/assignment/actions'
import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

const { getAllAssignmentResponses, GET_ALL_ASSIGNMENT_RESPONSES } = ASSIGNMENT_ACTIONS

const mapStateToProps = ({ assignments, loading }) => ({
  responses: assignments.toJS(),
  isLoading: !!loading.get(GET_ALL_ASSIGNMENT_RESPONSES.ACTION),
})

const mapDispatchToProps = {
  getAllResponses: getAllAssignmentResponses,
}

const ViewAllResponses = ({
  className,
  myClass,
  assignment,
  getAllResponses,
  responses,
  isLoading,
}) => {
  const onBackClick = useCallback(
    event => {
      event.preventDefault()
      navigate(`/class/${myClass.id}/assignment/${assignment.id}`)
    },
    [assignment.id, myClass.id]
  )

  useEffect(
    () => {
      getAllResponses({ classId: myClass.id, assignmentId: assignment.id })
    },
    [assignment.id, getAllResponses, myClass.id]
  )

  if (isLoading) {
    return null
  }

  return (
    <section className={className}>
      <div className={styles.content}>
        <div className={styles.header}>
          <button
            type="button"
            onClick={onBackClick}
            onMouseDown={onMouseDown}
            className={styles['back-button']}
          >
            <svg
              className={styles['back-icon']}
              aria-label="Back icon"
              role="img"
              viewBox={ArrowLeftIcon.viewBox}
            >
              <use xlinkHref={`#${ArrowLeftIcon.id}`} />
            </svg>
          </button>
          <h3 className={styles.title}>Back to assignment</h3>
        </div>
        <div className={styles.responses}>
          <h1 className={styles.title}>View All Responses</h1>
          {responses.length !== 0 ? (
            responses.map(({ user, questions }, index) => (
              <Fragment key={user.id}>
                <div className={styles['response-wrapper']}>
                  <div className={styles.user}>
                    <ProfileAvatar
                      className={styles.profile}
                      username={user.username}
                      src={user.photo}
                      roleType={user.role}
                      size={AvatarSize.SMALL}
                    />
                    <div className={styles['user-details']}>
                      <p className={styles.name}>{user.name}</p>
                      <p className={styles.company}>{user.title}</p>
                    </div>
                  </div>
                  <div className={styles.card}>
                    {questions.map(({ question, answer }) => (
                      <Card key={`${question}-${answer}-${user.id}`}>
                        <h3 className={styles.title}>{question}</h3>
                        <p className={styles.comment}>{answer}</p>
                      </Card>
                    ))}
                  </div>
                </div>
                {index + 1 < responses.length && <div className={styles.divider} />}
              </Fragment>
            ))
          ) : (
            <div className={styles.empty}>
              <h2>No one has responded to this assignment yet</h2>
              <p>Be the first to respond.</p>
              <Button
                label="Go back to the assignment"
                theme={ButtonTheme.PRIMARY}
                onClick={onBackClick}
              />
            </div>
          )}
        </div>
      </div>
      <Footer />
    </section>
  )
}

ViewAllResponses.propTypes = {
  className: PropTypes.string,
  myClass: classShape.isRequired,
  assignment: assignmentShape.isRequired,
  responses: PropTypes.arrayOf(
    PropTypes.shape({
      user: userShape,
      questions: PropTypes.arrayOf(
        PropTypes.shape({
          question: PropTypes.string,
          answer: PropTypes.string,
        })
      ),
    })
  ).isRequired,
  getAllResponses: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
}

ViewAllResponses.defaultProps = {
  className: '',
  isLoading: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ViewAllResponses))
