import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SentIcon from '_assets/images/sent-icon.png'

import styles from './styles.css'

const EmailSent = ({ className }) => (
  <div className={classnames(styles.container, className)}>
    <img src={SentIcon} alt="Email sent" className={styles.image} />
    <p className={styles.title}>We just sent you an email</p>
    <p className={styles.text}>Please follow the link in the email to reset your password.</p>
  </div>
)

EmailSent.propTypes = {
  className: PropTypes.string,
}

EmailSent.defaultProps = {
  className: '',
}

export default EmailSent
