import React, { useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'

import { getHiddenUsers, showUserPosts } from '_modules/user/actions'
import { userShape } from '_utils/proptypes'

import styles from './styles.css'
import HiddenProfile from './hidden-profile'

const mapStateToProps = ({ user }) => ({
  hiddenUsers: user.hiddenUsers.toJS(),
})

const mapDispatchToProps = {
  getHiddenUsersInfo: getHiddenUsers,
  unhidePosts: showUserPosts,
}

const HiddenPosts = ({ getHiddenUsersInfo, unhidePosts, hiddenUsers }) => {
  useEffect(
    () => {
      getHiddenUsersInfo()
    },
    [getHiddenUsersInfo]
  )

  const onUnhideClick = useCallback(
    username => {
      unhidePosts(username)
    },
    [unhidePosts]
  )

  return (
    <div className={styles.container}>
      <h2>HIDDEN POSTS</h2>
      {hiddenUsers.length === 0 ? (
        <p>You haven’t hidden any posts yet.</p>
      ) : (
        <div className={styles['list-users']}>
          {hiddenUsers.map(hiddenUser => (
            <HiddenProfile
              key={hiddenUser.id}
              profilePicture={hiddenUser.photo}
              name={hiddenUser.name}
              company={hiddenUser.company}
              role={hiddenUser.role}
              className={styles.profile}
              onClick={onUnhideClick}
              username={hiddenUser.username}
            />
          ))}
        </div>
      )}
    </div>
  )
}

HiddenPosts.propTypes = {
  getHiddenUsersInfo: PropTypes.func.isRequired,
  unhidePosts: PropTypes.func.isRequired,
  hiddenUsers: PropTypes.arrayOf(userShape),
}

HiddenPosts.defaultProps = {
  hiddenUsers: [],
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(HiddenPosts))
