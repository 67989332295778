import React from 'react'

import { onMouseDown } from '_utils/aria'

const noOutlineAction = WrappedComponent => {
  const NoOutlineComponent = props => <WrappedComponent onMouseDown={onMouseDown} {...props} />

  return NoOutlineComponent
}

export default noOutlineAction
