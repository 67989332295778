import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Link, navigate } from '@reach/router'

import { sortByMainCoach } from '_utils/helpers'
import Svg from '_components/svg'
import UserPlaceHolderIcon from '_assets/icons/user-placeholder.svg'
import IconButton, { ICON_BUTTON_SIZE, ICON_BUTTON_THEME } from '_components/icon-button'
import AddParticipantIcon from '_assets/icons/add_participant.svg'
import DirectMessageIcon from '_assets/icons/direct-message-icon.svg'
import { followLeader } from '_modules/user/actions'
import { userIdSelector } from '_modules/user/selectors'
import useModal from '_hooks/use-modal'
import Modal from '_components/modal'
import ModalTextarea from '_components/modal-textarea'
import { sendDirectMessage, SEND_DIRECT_MESSAGE } from '_modules/chat/actions'
import useFetchCall from '_hooks/use-fetch-call'
import useBodyOverflow from '_hooks/use-body-overflow'
import { coachesPropTypes } from '_utils/proptypes'

import styles from './styles.css'

const ClassHubCoaches = ({ coaches, className, classId }) => {
  const sortedCoaches = coaches.sort(coach => sortByMainCoach(coach))
  const userId = useSelector(userIdSelector)

  const [isSendMessageModalOpen, onToggleSendMessageModal] = useModal()
  useBodyOverflow(isSendMessageModalOpen)
  const [selectedCoachUsername, setSelectedCoachUsername] = useState('')

  const dispatch = useDispatch()

  const onFollowCoach = useCallback(
    event => {
      const {
        dataset: { coachusername },
        id,
      } = event.currentTarget

      dispatch(followLeader({ username: coachusername, userId: id, classId }))
    },
    [classId, dispatch]
  )

  const renderFollowButton = useCallback(
    coach => {
      if (coach.following || coach.id === userId) {
        return null
      }

      return (
        <IconButton
          arial-label="A user avatar with an add button"
          className={styles['add-button']}
          theme={ICON_BUTTON_THEME.DARK_TRANSPARENT}
          size={ICON_BUTTON_SIZE.LARGE}
          icon={AddParticipantIcon}
          id={coach.id}
          data-coachusername={coach.username}
          onClick={onFollowCoach}
        />
      )
    },
    [onFollowCoach, userId]
  )

  const handleOnToggleSendMessageModal = useCallback(
    event => {
      const {
        dataset: { coachusername },
      } = event.currentTarget
      setSelectedCoachUsername(coachusername)
      onToggleSendMessageModal()
    },
    [onToggleSendMessageModal]
  )

  const onSendMessage = useCallback(
    value => {
      dispatch(sendDirectMessage({ username: selectedCoachUsername, message: value }))
    },
    [dispatch, selectedCoachUsername]
  )

  const handleNavigate = useCallback(
    () => {
      navigate(`/messages/?directMessage=${selectedCoachUsername}`)
    },
    [selectedCoachUsername]
  )

  const [isSendingMessage] = useFetchCall(SEND_DIRECT_MESSAGE, handleNavigate)

  return (
    <section className={classnames(styles['class-hub-coaches-container'], className)}>
      <h1 className={styles['class-lead-by']}>Lead by</h1>
      {sortedCoaches.map(coach => (
        <div key={coach.id} className={styles['coach-card-wrapper']}>
          <Link to={`/user/${coach.username}`} className={styles['coach-link']}>
            {coach.photo ? (
              <img
                className={styles['coach-picture']}
                src={coach.photo}
                alt={`${coach.name}'s avatar`}
              />
            ) : (
              <Svg className={styles['coach-picture']} icon={UserPlaceHolderIcon} />
            )}
          </Link>
          <p className={styles['coach-name']}>{coach.name}</p>
          <p className={styles['coach-mention']}>@{coach.username}</p>
          {renderFollowButton(coach)}
          <IconButton
            aria-label="A mail letter"
            size={ICON_BUTTON_SIZE.LARGE}
            theme={ICON_BUTTON_THEME.DARK_TRANSPARENT}
            className={styles['mail-button']}
            icon={DirectMessageIcon}
            data-coachusername={coach.username}
            onClick={handleOnToggleSendMessageModal}
          />
          {isSendMessageModalOpen && (
            <Modal isOpen onClose={onToggleSendMessageModal}>
              <ModalTextarea
                title="SEND A MESSAGE"
                buttonLabel="SEND"
                onSubmit={onSendMessage}
                onClose={onToggleSendMessageModal}
                value=""
                isLoading={isSendingMessage}
              />
            </Modal>
          )}
        </div>
      ))}
    </section>
  )
}

ClassHubCoaches.propTypes = {
  coaches: coachesPropTypes.isRequired,
  className: PropTypes.string,
  classId: PropTypes.number.isRequired,
}

ClassHubCoaches.defaultProps = {
  className: '',
}

export default ClassHubCoaches
