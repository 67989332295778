import * as userService from '_services/user'
import { defineAction } from '_utils/redux'

export const LIST_CARDS = defineAction('LIST_CARDS')
export const ADD_NEW_CARD = defineAction('ADD_NEW_CARD')
export const MAKE_DEFAULT = defineAction('MAKE_DEFAULT')
export const REMOVE_CARD = defineAction('REMOVE_CARD')

export const listCards = () => (dispatch, getState) => {
  dispatch({
    type: LIST_CARDS.ACTION,
    payload: userService.listCards(getState().user.get('authToken')),
  })
}

export const addCard = payload => (dispatch, getState) => {
  dispatch({
    type: ADD_NEW_CARD.ACTION,
    payload: userService.addCard(getState().user.get('authToken'))(payload),
  })
}

export const makeCardDefault = cardToken => (dispatch, getState) => {
  dispatch({
    type: MAKE_DEFAULT.ACTION,
    payload: userService.makeCardDefault(getState().user.get('authToken'))(cardToken),
  })
}

export const removeCard = cardToken => (dispatch, getState) => {
  dispatch({
    type: REMOVE_CARD.ACTION,
    payload: userService.removeCard(getState().user.get('authToken'))(cardToken),
  })
}
