import React, { useCallback, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate } from '@reach/router'
import noScroll from 'no-scroll'

import DisabledIcon from '_assets/icons/disabledchannel-16-px.svg'
import AddIcon from '_assets/icons/add.svg'
import LockerIcon from '_assets/icons/locker.svg'
import HashtagIcon from '_assets/icons/hashtag.svg'
import { onMouseDown } from '_utils/aria'
import { channelShape } from '_utils/proptypes'
import { usePrevious } from '_utils/hooks'

import styles from './styles.css'
import BrowseChannelModal from './browse-channels-modal'

export const iconSelector = (privateChannel, disabledChannel) => {
  if (disabledChannel) return DisabledIcon
  if (privateChannel) return LockerIcon
  return HashtagIcon
}

const ChannelList = ({
  channels,
  onChannelClick,
  selectedId,
  isInline,
  availableChannels,
  isLoadingChannelList,
  getChannels,
  newChannel,
  isCreatingChannel,
  createChannelError,
  joinChannel,
  inviteChannel,
  isJoiningChannel,
  isInvitingChannel,
  isAdmin,
}) => {
  const [isModalOpen, setModal] = useState(false)
  const wasCreatingChannel = usePrevious(isCreatingChannel)
  const wasJoiningChannel = usePrevious(isJoiningChannel)
  const wasInvitingChannel = usePrevious(isInvitingChannel)

  const onButtonClick = useCallback(
    event => {
      if (isInline) {
        navigate(`/messages?channelId=${event.currentTarget.id}`)
        return
      }
      onChannelClick(Number(event.currentTarget.id))
    },
    [isInline, onChannelClick]
  )

  const onModalClose = useCallback(() => {
    setModal(false)
  }, [])

  const onAddChannel = useCallback(
    () => {
      setModal(true)
      getChannels({ search: null })
    },
    [getChannels]
  )

  useEffect(
    () => {
      if (isModalOpen) {
        noScroll.on()
      } else {
        noScroll.off()
      }

      return () => {
        noScroll.off()
      }
    },
    [isModalOpen]
  )

  useEffect(
    () => {
      if (
        (wasCreatingChannel && !isCreatingChannel && !createChannelError.length) ||
        (wasJoiningChannel && !isJoiningChannel) ||
        (wasInvitingChannel && !isInvitingChannel)
      ) {
        setModal(false)
      }
    },
    [
      createChannelError,
      createChannelError.lenght,
      createChannelError.length,
      isCreatingChannel,
      isInvitingChannel,
      isJoiningChannel,
      wasCreatingChannel,
      wasInvitingChannel,
      wasJoiningChannel,
    ]
  )

  return (
    <>
      <div className={styles.section}>
        <p>CHANNELS</p>
        <button
          type="button"
          onMouseDown={onMouseDown}
          className={styles.action}
          onClick={onAddChannel}
        >
          <svg viewBox={AddIcon.viewBox} role="img" aria-label="Add Channel icon">
            <use xlinkHref={`#${AddIcon.id}`} />
          </svg>
        </button>
      </div>
      <ul className={styles['chat-list']}>
        {channels.map(channel => (
          <li
            className={classnames(styles['chat-item'], {
              [styles.active]: selectedId === channel.id,
            })}
            key={channel.id}
          >
            <button type="button" onClick={onButtonClick} onMouseDown={onMouseDown} id={channel.id}>
              <svg
                viewBox={iconSelector(channel.private, channel.disabled).viewBox}
                role="img"
                aria-label="Channel icon"
              >
                <use xlinkHref={`#${iconSelector(channel.private, channel.disabled).id}`} />
              </svg>
              <p
                className={classnames({
                  [styles.unread]: selectedId !== channel.id && !channel.read,
                })}
              >
                {channel.name}
              </p>
            </button>
          </li>
        ))}
      </ul>
      {isModalOpen && (
        <BrowseChannelModal
          onChannelClick={onChannelClick}
          onClose={onModalClose}
          isOpen
          availableChannels={availableChannels}
          className={styles['channels-modal']}
          isLoading={
            isLoadingChannelList || isCreatingChannel || isJoiningChannel || isInvitingChannel
          }
          getChannels={getChannels}
          newChannel={newChannel}
          createChannelError={createChannelError}
          joinChannel={joinChannel}
          inviteChannel={inviteChannel}
          isAdmin={isAdmin}
        />
      )}
    </>
  )
}
ChannelList.propTypes = {
  channels: PropTypes.arrayOf(channelShape),
  onChannelClick: PropTypes.func,
  selectedId: PropTypes.number,
  isInline: PropTypes.bool,
  availableChannels: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.number,
    previous: PropTypes.number,
    channels: PropTypes.arrayOf(channelShape),
  }),
  isLoadingChannelList: PropTypes.bool,
  getChannels: PropTypes.func,
  newChannel: PropTypes.func,
  isCreatingChannel: PropTypes.bool,
  createChannelError: PropTypes.string,
  joinChannel: PropTypes.func,
  inviteChannel: PropTypes.func,
  isInvitingChannel: PropTypes.bool,
  isJoiningChannel: PropTypes.bool,
  isAdmin: PropTypes.bool,
}

ChannelList.defaultProps = {
  channels: [],
  onChannelClick: () => {},
  selectedId: null,
  isInline: false,
  availableChannels: [],
  isLoadingChannelList: false,
  getChannels: () => {},
  newChannel: () => {},
  isCreatingChannel: false,
  createChannelError: '',
  joinChannel: () => {},
  inviteChannel: () => {},
  isInvitingChannel: false,
  isJoiningChannel: false,
  isAdmin: false,
}

export default React.memo(ChannelList)
