import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import CloseIcon from '_assets/icons/close.svg'

import styles from './styles.css'

const ModalHeader = ({ onClose, title, className }) => (
  <div className={classnames(styles.header, className)}>
    <h2>{title}</h2>
    <button type="button" onClick={onClose}>
      <svg aria-label="Close" role="img" className={styles.close} viewBox={CloseIcon.viewBox}>
        <use xlinkHref={`#${CloseIcon.id}`} />
      </svg>
    </button>
  </div>
)
ModalHeader.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func,
  className: PropTypes.string,
}

ModalHeader.defaultProps = {
  title: '',
  onClose: () => {},
  className: '',
}

export default ModalHeader
