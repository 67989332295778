import { Map, fromJS } from 'immutable'

import { createReducer } from '_utils/redux'

import { VALIDATE_TOKEN, VALIDATE_HASH } from './actions'

const INITIAL_STATE = Map()

const company = createReducer(INITIAL_STATE, {
  [VALIDATE_TOKEN.FULFILLED]: (state, { payload }) => state.merge(fromJS(payload)),

  [VALIDATE_HASH.FULFILLED]: (state, { payload }) => state.merge(fromJS(payload)),
})

export default company
