import React, { useCallback, useEffect, useState } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useDebouncedCallback } from 'use-debounce/lib'
import { List } from 'immutable'

import Input from '_components/landing-components/input'
import Button, { ButtonTheme } from '_components/landing-components/button'

import SelectedItemBox from './selected-item-box'
import styles from './styles.css'

const LABEL_TO_WRAPPER_SIZE = 32

const SelectDropdown = ({
  id,
  label,
  placeholder,
  onGetValues,
  valuesList,
  onSelectValues,
  selectedValues,
  className,
  isLoading,
}) => {
  const [searchValue, setSearchValue] = useState('')
  const [isSearchFocused, setIsSearchFocused] = useState(false)
  const [selectedItems, setSelectedItems] = useState(selectedValues)
  const [height, setHeight] = useState('')

  useEffect(
    () => {
      setSelectedItems(selectedValues)
    },
    [selectedValues]
  )

  const onSelectItem = useCallback(
    event => {
      const {
        dataset: { value, valueid },
      } = event.target

      setSelectedItems(prevValue => {
        const payload = [...prevValue]
        if (payload.some(item => item.id.includes(valueid))) {
          return prevValue
        }

        payload.push({ name: value, id: valueid })
        onSelectValues(payload)
        return payload
      })
    },
    [onSelectValues]
  )

  const onRemoveItem = useCallback(
    (value, valueId) => {
      setSelectedItems(prevValue => {
        const payload = prevValue.filter(item => item.id !== valueId)
        onSelectValues(payload)
        return payload
      })
    },
    [onSelectValues]
  )

  const onGetList = useCallback(
    values => {
      onGetValues(values)
    },
    [onGetValues]
  )

  const debouncedGetValues = useDebouncedCallback(onGetList, 500)

  const onChangeSearch = useCallback(
    event => {
      const { value } = event.target
      setSearchValue(value)

      debouncedGetValues(value)
    },
    [debouncedGetValues]
  )

  const onFocus = useCallback(() => {
    setIsSearchFocused(true)
  }, [])

  const onBlur = useCallback(() => {
    setIsSearchFocused(false)
  }, [])

  return (
    <div className={classnames(styles['select-dropdown-container'], className)}>
      {label && <p className={styles['select-dropdown-label']}>{label}</p>}
      <div
        className={styles['input-wrapper']}
        // TODO - Refac this logic when have time
        // eslint-disable-next-line react/jsx-no-bind
        ref={inputElement => {
          const inputWrapperHeight = inputElement?.getBoundingClientRect().height
          setHeight(inputWrapperHeight + LABEL_TO_WRAPPER_SIZE)
        }}
      >
        <div className={styles['selected-items-wrapper']}>
          {selectedItems.length > 0 &&
            selectedItems.map(item => (
              <SelectedItemBox
                key={item.id}
                onRemoveClick={onRemoveItem}
                id={item.id}
                value={item.name}
              />
            ))}
        </div>
        <Input
          onFocus={onFocus}
          onBlur={onBlur}
          className={styles['select-dropdown-input']}
          inputClassName={styles.input}
          id={id}
          onChange={onChangeSearch}
          placeholder={placeholder}
          value={searchValue}
          autoComplete="off"
        />
      </div>
      {!!searchValue.length &&
        isSearchFocused > 0 && (
          <ul
            style={{
              top: height,
            }}
            className={styles['select-dropdown-list']}
          >
            {valuesList.length > 0 ? (
              valuesList.map(result => (
                <li className={styles['select-dropdown-item']} key={result.get('id')}>
                  <Button
                    data-value={result.get('name')}
                    data-valueid={result.get('id')}
                    theme={ButtonTheme.TRANSPARENT}
                    className={styles['select-dropdown-button']}
                    onClick={onSelectItem}
                  >
                    {result.get('name')}
                  </Button>
                </li>
              ))
            ) : (
              <li className={classnames(styles['select-dropdown-item'], styles['empty-content'])}>
                <span className={styles['select-dropdown-empty']}>
                  {isLoading
                    ? '...Loading'
                    : `There are no coaches for the search "${searchValue}"`}
                </span>
              </li>
            )}
          </ul>
        )}
    </div>
  )
}

SelectDropdown.propTypes = {
  id: PropTypes.string.isRequired,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  onGetValues: PropTypes.func.isRequired,
  valuesList: PropTypes.oneOfType([
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      })
    ),
    PropTypes.instanceOf(List),
  ]),
  className: PropTypes.string,
  onSelectValues: PropTypes.func,
  selectedValues: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      name: PropTypes.string,
    })
  ),
  isLoading: PropTypes.bool,
}

SelectDropdown.defaultProps = {
  label: '',
  placeholder: '',
  valuesList: List(),
  className: '',
  onSelectValues: () => {},
  selectedValues: [],
  isLoading: false,
}

export default React.memo(SelectDropdown)
