import { OrderedMap } from 'immutable'

import { Card } from '_models/'
import { createReducer } from '_utils/redux'

import { LIST_CARDS, ADD_NEW_CARD, MAKE_DEFAULT, REMOVE_CARD } from './actions'

const INITIAL_STATE = OrderedMap()

const cards = createReducer(INITIAL_STATE, {
  [LIST_CARDS.FULFILLED]: (state, { payload }) =>
    OrderedMap(payload.results.map(card => [card.id, new Card(card)])),

  [ADD_NEW_CARD.FULFILLED]: (state, { payload }) =>
    OrderedMap(payload.results.map(card => [card.id, new Card(card)])),

  [MAKE_DEFAULT.FULFILLED]: (state, { payload }) =>
    OrderedMap(payload.results.map(card => [card.id, new Card(card)])),

  [REMOVE_CARD.FULFILLED]: (state, { payload }) =>
    OrderedMap(payload.results.map(card => [card.id, new Card(card)])),
})

export default cards
