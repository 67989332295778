import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import DefaultAvatar from '_assets/icons/empty-photo.svg'
import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

const PersonCard = ({ username, name, company, photo, onFollowClick, isFollowing }) => {
  const onFollowUserClick = useCallback(() => onFollowClick(username), [onFollowClick, username])

  return (
    <div className={styles.card}>
      {photo ? (
        <img src={photo} alt={`${name}'s avatar`} className={styles.picture} />
      ) : (
        <svg
          aria-label={`${name}'s avatar`}
          role="img"
          viewBox={DefaultAvatar.viewBox}
          className={styles.picture}
        >
          <use xlinkHref={`#${DefaultAvatar.id}`} />
        </svg>
      )}

      <p className={styles.name}>{name}</p>
      <p className={styles.organization}>{company}</p>
      <Button
        label="FOLLOW"
        theme={ButtonTheme.PRIMARY}
        onClick={onFollowUserClick}
        className={styles.follow}
        isLoading={isFollowing}
      />
    </div>
  )
}

PersonCard.propTypes = {
  username: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  company: PropTypes.string,
  photo: PropTypes.string,
  onFollowClick: PropTypes.func,
  isFollowing: PropTypes.bool,
}

PersonCard.defaultProps = {
  onFollowClick: () => {},
  photo: null,
  company: null,
  isFollowing: false,
}

export default React.memo(PersonCard)
