import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import classnames from 'classnames'
import { connect } from 'react-redux'

import { getUsersAvailableToChat, GET_USERS_AVAILABLE_TO_CHAT } from '_modules/user/actions'
import { userShape, channelShape } from '_utils/proptypes'
import {
  getChannels,
  GET_CHANNELS,
  createChannel,
  CREATE_CHANNEL,
  joinToChannel,
  JOIN_CHANNEL,
  inviteToChannel,
  INVITE_TO_CHANNEL,
  GET_MY_CHATS,
} from '_modules/chat/actions'
import RocketLoader from '_components/rocket-loader'
import Button, { ButtonTheme, ButtonSize } from '_components/landing-components/button'

import DirectMessages from './direct-messages'
import ChannelList from './channel-list'
import styles from './styles.css'

const mapStateToProps = ({ chat, loading, error }) => ({
  availableUsers: chat.get('usersAvailableToChat').toJS(),
  isLoadingUsersToChat: !!loading.get(GET_USERS_AVAILABLE_TO_CHAT.ACTION),
  isLoadingChats: !!loading.get(GET_MY_CHATS.ACTION),
  availableChannels: chat.get('channelsAvailable').toJS(),
  isLoadingChannelList: !!loading.get(GET_CHANNELS.ACTION),
  isCreatingChannel: !!loading.get(CREATE_CHANNEL.ACTION),
  createChannelError: error.get(CREATE_CHANNEL.ACTION),
  isJoiningChannel: !!loading.get(JOIN_CHANNEL.ACTION),
  invitingToChannel: !!loading.get(INVITE_TO_CHANNEL.ACTION),
})

const mapDispatchToProps = {
  getAvailableUsers: getUsersAvailableToChat,
  getAvailableChannels: getChannels,
  newChannel: createChannel,
  joinChannel: joinToChannel,
  inviteChannel: inviteToChannel,
}

const SidebarChat = ({
  className,
  isInline,
  onDirectMessageClick,
  onChannelClick,
  directMessage,
  channelId,
  directMessages,
  channels,
  getAvailableUsers,
  availableUsers,
  isLoadingUsersToChat,
  newPreDirectMessage,
  shouldRedirect,
  isLoadingChannelList,
  isLoadingChats,
  availableChannels,
  getAvailableChannels,
  newChannel,
  isCreatingChannel,
  createChannelError,
  joinChannel,
  inviteChannel,
  isJoiningChannel,
  isInvitingChannel,
  isAdmin,
  isLimited,
}) => (
    <aside className={classnames(styles.container, className)}>
      {isLoadingChats ? (
        <div className={styles['loader-wrapper']}>
          <RocketLoader />
        </div>
      ) : (
        <>
          <div className={styles.channels}>
            <ChannelList
              channels={channels}
              onChannelClick={onChannelClick}
              selectedId={channelId}
              isInline={isInline}
              shouldRedirect={shouldRedirect}
              isLoadingChannelList={isLoadingChannelList}
              availableChannels={availableChannels}
              getChannels={getAvailableChannels}
              newChannel={newChannel}
              isCreatingChannel={isCreatingChannel}
              createChannelError={createChannelError.getIn(['name', 0])}
              joinChannel={joinChannel}
              inviteChannel={inviteChannel}
              isJoiningChannel={isJoiningChannel}
              isInvitingChannel={isInvitingChannel}
              isAdmin={isAdmin}
            />
          </div>
          <div className={styles['direct-messages']}>
            <DirectMessages
              isInline={isInline}
              messages={directMessages}
              onDirectMessageClick={onDirectMessageClick}
              selectedUsername={directMessage}
              getAvailableUsers={getAvailableUsers}
              availableUsers={availableUsers}
              isLoadingUsersToChat={isLoadingUsersToChat}
              newPreDirectMessage={newPreDirectMessage}
              shouldRedirect={shouldRedirect}
            />
          </div>
          {isLimited && (
            <Button
              size={ButtonSize.SMALL}
              theme={ButtonTheme.TRANSPARENT_BACKGROUND_MALIBU_TEXT}
              url="/messages"
            >
              SEE ALL
            </Button>
          )}
        </>
      )}
    </aside>
  )

SidebarChat.propTypes = {
  className: PropTypes.string,
  isInline: PropTypes.bool,
  onDirectMessageClick: PropTypes.func,
  onChannelClick: PropTypes.func,
  getAvailableUsers: PropTypes.func,
  directMessage: PropTypes.string,
  channelId: PropTypes.number,
  directMessages: PropTypes.arrayOf(PropTypes.object),
  channels: PropTypes.arrayOf(PropTypes.object),
  availableUsers: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.number,
    previous: PropTypes.number,
    users: PropTypes.arrayOf(userShape),
  }).isRequired,
  isLoadingUsersToChat: PropTypes.bool,
  newPreDirectMessage: PropTypes.func,
  shouldRedirect: PropTypes.bool,
  availableChannels: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.number,
    previous: PropTypes.number,
    channels: PropTypes.arrayOf(channelShape),
  }).isRequired,
  isLoadingChannelList: PropTypes.bool.isRequired,
  isLoadingChats: PropTypes.bool.isRequired,
  getAvailableChannels: PropTypes.func.isRequired,
  newChannel: PropTypes.func.isRequired,
  isCreatingChannel: PropTypes.bool.isRequired,
  createChannelError: ImmutablePropTypes.map,
  joinChannel: PropTypes.func.isRequired,
  inviteChannel: PropTypes.func.isRequired,
  isInvitingChannel: PropTypes.bool,
  isJoiningChannel: PropTypes.bool,
  isAdmin: PropTypes.bool,
  isLimited: PropTypes.bool,
}

SidebarChat.defaultProps = {
  className: '',
  isInline: false,
  onDirectMessageClick: () => {},
  onChannelClick: () => {},
  getAvailableUsers: () => {},
  directMessage: null,
  channelId: null,
  directMessages: [],
  channels: [],
  isLoadingUsersToChat: false,
  newPreDirectMessage: () => {},
  shouldRedirect: false,
  createChannelError: Map(),
  isInvitingChannel: false,
  isJoiningChannel: false,
  isAdmin: false,
  isLimited: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SidebarChat))
