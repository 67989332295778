import * as companyService from '_services/company'
import { defineAction } from '_utils/redux'

export const VALIDATE_TOKEN = defineAction('VALIDATE_TOKEN')
export const VALIDATE_HASH = defineAction('VALIDATE_HASH')

export const validateToken = token => dispatch =>
  dispatch({
    type: VALIDATE_TOKEN.ACTION,
    payload: companyService.validateToken(token),
  })

export const validateOrganization = hash => dispatch =>
  dispatch({
    type: VALIDATE_HASH.ACTION,
    payload: companyService.validateHash(hash),
  })
