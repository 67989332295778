import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SwitchButton from '_components/switch-button'

import styles from './styles.css'

const NotificationSwitch = ({ name, active, onChange, className, category }) => {
  const updateChange = useCallback(
    event => {
      if (category) {
        onChange({ category, name, value: event })
        return
      }
      onChange(event)
    },
    [name, onChange, category]
  )

  return (
    <div className={classnames(styles.container, className)}>
      <p className={styles.name}>{name}</p>
      <SwitchButton checked={active} onChange={updateChange} />
    </div>
  )
}

NotificationSwitch.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string,
  category: PropTypes.string,
  active: PropTypes.bool,
  onChange: PropTypes.func,
}

NotificationSwitch.defaultProps = {
  className: '',
  name: '',
  category: '',
  active: true,
  onChange: () => {},
}

export default NotificationSwitch
