import React, { useEffect, useCallback, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect, useSelector } from 'react-redux'
import { Map } from 'immutable'
import Helmet from 'react-helmet'
import { useWindowSize } from '@reach/window-size'
import moment from 'moment'
import cookies from 'react-cookies'

import Header from '_components/header'
import SidebarMenu from '_components/sidebar-menu'
import SidebarChat from '_components/sidebar-chat'
import authRoute from '_hocs/auth-route'
import withWebSocket from '_hocs/websocket'
import { getMyChats, createPreDirectMessage, cleanInitialMessage } from '_modules/chat/actions'
import { getUser } from '_modules/user/actions'
import {
  channelShape,
  directMessageShape,
  userShape,
  notificationsShape,
  alertShape,
} from '_utils/proptypes'
import { getSummary, getUpcomingGoals } from '_modules/dashboard/actions'
import POST_ACTIONS from '_modules/post/actions'
import { getNotifications } from '_modules/notification/actions'
import { toArray } from '_utils/helpers'
import AlertMessage from '_components/alert-message'
import { getAlert, readAlert } from '_modules/alert/actions'
import { usePrevious } from '_utils/hooks'
import favicon16 from '_assets/public/favicon-16x16.png'
import WelcomeModal from '_components/welcome-modal'
import FreeMembershipTrialModal from '_components/free-membership-trial-modal'
import SubscriptionExpiredModal from '_components/subscription-expired-modal'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import SubscriptionEndingBanner from '_components/subscription-ending-banner'
import { subscriptionStatusSelector, subscriptionTrialEndSelector } from '_modules/user/selectors'
import { SUBSCRIPTION_STATUS } from '_utils/constants'
import useModal from '_hooks/use-modal'
import { shouldShowSubscriptionExpiringBanner } from '_utils/subscription-expire'

import styles from './styles.css'

const { getPosts } = POST_ACTIONS

const isDashboardSection = location => location.pathname === '/dashboard'

const mapStateToProps = ({ chat, user, notifications, alert }) => ({
  channels: toArray(chat.get('channels')),
  directMessages: toArray(chat.get('directMessages')),
  user: user.toJS(),
  notifications: notifications.toJS(),
  alert,
  checkpoint: chat.get('checkpoint'),
})

const mapDispatchToProps = {
  getChatInfo: getMyChats,
  getUserInfo: getUser,
  getSummaryInfo: getSummary,
  getUserUpcomingGoals: getUpcomingGoals,
  getTimelinePosts: getPosts,
  newPreDirectMessage: createPreDirectMessage,
  getNotificationsInfo: getNotifications,
  getAlertInfo: getAlert,
  readUserAlert: readAlert,
  cleanMessage: cleanInitialMessage,
}

const App = ({
  children,
  location,
  channels,
  directMessages,
  getChatInfo,
  user,
  getUserInfo,
  getSummaryInfo,
  getUserUpcomingGoals,
  getTimelinePosts,
  newPreDirectMessage,
  getNotificationsInfo,
  notifications,
  alert,
  getAlertInfo,
  readUserAlert,
  cleanMessage,
  subscriptionExpired,
  checkpoint,
}) => {
  const subscriptionStatus = useSelector(subscriptionStatusSelector)
  const freeSubscriptionEnd = useSelector(subscriptionTrialEndSelector)

  const freeSubscriptionDaysLeft = useMemo(
    () => {
      if (!freeSubscriptionEnd) {
        return null
      }

      const end = moment(freeSubscriptionEnd)
      const now = moment()

      return end.diff(now, 'days')
    },
    [freeSubscriptionEnd]
  )

  const locationKey = usePrevious(location.key)
  const [isWelcomeModalOpen, setWelcomeModalOpen] = useState(false)
  const [isSubscriptionExpiringBannerShowing, setSubscriptionExpiringBannerShowing] = useModal(
    shouldShowSubscriptionExpiringBanner(user, freeSubscriptionDaysLeft)
  )
  const [isFreeMembershipTrialModalOpen, onToggleFreeMembershipTrialModal] = useModal(false)
  const [isSubscriptionExpiredModalOpen, onToggleSubscriptionExpiredModal] = useModal(
    subscriptionExpired && !cookies.load('subscriptionExpiredDismissed')
  )

  const { width } = useWindowSize()

  const isMessageSection = useMemo(() => location.pathname.includes('/messages'), [
    location.pathname,
  ])

  const isSearchSection = useMemo(() => location.pathname.includes('/search'), [location.pathname])

  const showAlert = useMemo(
    () => !isMessageSection && !isSearchSection && !subscriptionExpired && alert.id,
    [alert.id, isMessageSection, isSearchSection, subscriptionExpired]
  )

  useEffect(
    () => {
      if (!user.completedProfile && !user.belongsToACompany) {
        setWelcomeModalOpen(true)
      }
    },
    [user.belongsToACompany, user.completedProfile]
  )

  useEffect(
    () => {
      if (isSubscriptionExpiredModalOpen && !subscriptionExpired) {
        onToggleSubscriptionExpiredModal()
      }
    },
    [isSubscriptionExpiredModalOpen, onToggleSubscriptionExpiredModal, subscriptionExpired]
  )

  const onCloseWelcomeModal = useCallback(
    () => {
      setWelcomeModalOpen(false)
      if (location?.state?.subscriptionTrialDays) {
        setTimeout(() => {
          onToggleFreeMembershipTrialModal()
        }, 2000)
      }
    },
    [location, onToggleFreeMembershipTrialModal]
  )

  useEffect(
    () => {
      if (locationKey !== location.key && !isMessageSection) {
        window.scrollTo(0, 0)
        cleanMessage()
      }
    },
    [cleanMessage, isMessageSection, location, locationKey]
  )

  useEffect(
    () => {
      if (isDashboardSection(location) && !subscriptionExpired) {
        getSummaryInfo()
        getUserUpcomingGoals()
      }
    },
    [
      getChatInfo,
      getSummaryInfo,
      getTimelinePosts,
      getUserInfo,
      getUserUpcomingGoals,
      location,
      subscriptionExpired,
    ]
  )

  const onNewPreDirectMessage = useCallback(payload => newPreDirectMessage(payload), [
    newPreDirectMessage,
  ])

  useEffect(
    () => {
      getUserInfo()
      if (!subscriptionExpired) {
        getNotificationsInfo()
        getAlertInfo()
        getChatInfo(5)
      }
    },
    [getNotificationsInfo, getUserInfo, getAlertInfo, getChatInfo, subscriptionExpired]
  )

  const loadMoreNotifications = useCallback(
    () => {
      getNotificationsInfo(notifications.next)
    },
    [getNotificationsInfo, notifications]
  )

  const closeAlertClick = useCallback(
    () => {
      readUserAlert(alert.id)
    },
    [alert.id, readUserAlert]
  )

  const unreadMessagesCount = useMemo(
    () => (user.suspended ? 0 : directMessages.filter(message => !message.read).length),
    [directMessages, user.suspended]
  )

  const messageRedirectUrl = useMemo(
    () => {
      const hasDirectMessage = directMessages.length
      const url = '/messages'

      if (width <= MOBILE_THRESHOLD) {
        return url
      }

      if (isMessageSection) {
        return `${url}${location.search}`
      }

      if (checkpoint.get('directChatUsername')) {
        return `${url}?directMessage=${checkpoint.get('directChatUsername')}`
      }

      if (checkpoint.get('channelId')) {
        return `${url}?channelId=${checkpoint.get('channelId')}`
      }

      return `${url}${hasDirectMessage ? `?directMessage=${directMessages[0].user.username}` : ''}`
    },
    [checkpoint, directMessages, isMessageSection, location.search, width]
  )

  return (
    <div
      className={classnames(styles.container, {
        [styles.messages]: isMessageSection,
        [styles['subscription-expired-container']]: subscriptionExpired,
      })}
    >
      <Helmet
        title="Lightyear"
        link={[{ rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16 }]}
      />
      <Header
        notifications={notifications.notifications}
        directMessages={directMessages}
        className={styles.header}
        location={location}
        user={user}
        loadMore={loadMoreNotifications}
        hasMore={!!notifications.next}
        suspended={subscriptionExpired}
        messageRedirectUrl={messageRedirectUrl}
      />
      <SidebarMenu
        className={styles.menu}
        unreadMessagesCount={unreadMessagesCount}
        messageRedirectUrl={messageRedirectUrl}
        subscriptionExpired={subscriptionExpired}
      />
      <main className={styles.main} role="main">
        {isSubscriptionExpiringBannerShowing &&
          subscriptionStatus === SUBSCRIPTION_STATUS.TRIALING &&
          !user.skipSubscription && (
            <div className={classnames({ [styles['subscription-ending-banner']]: showAlert })}>
              <SubscriptionEndingBanner
                daysLeft={freeSubscriptionDaysLeft}
                onDismissClick={setSubscriptionExpiringBannerShowing}
              />
            </div>
          )}
        {showAlert && (
          <AlertMessage
            isOpen
            content={alert.content}
            callToAction={alert.callToAction}
            onCloseClick={closeAlertClick}
            url={alert.url}
          />
        )}
        {children}
      </main>
      {!isMessageSection &&
        !subscriptionExpired && (
          <SidebarChat
            className={styles['sidebar-chat']}
            isInline
            channels={channels}
            directMessages={directMessages}
            shouldRedirect
            newPreDirectMessage={onNewPreDirectMessage}
            isLimited
          />
        )}
      {isWelcomeModalOpen && <WelcomeModal closeModal={onCloseWelcomeModal} />}
      {isFreeMembershipTrialModalOpen && (
        <FreeMembershipTrialModal
          onClose={onToggleFreeMembershipTrialModal}
          membershipTrialTime={location?.state?.subscriptionTrialDays}
          courseName={location?.state?.subscriptionTrialCourseName}
        />
      )}
      {isSubscriptionExpiredModalOpen &&
        !user.skipSubscription && (
          <SubscriptionExpiredModal onClose={onToggleSubscriptionExpiredModal} />
        )}
    </div>
  )
}

App.propTypes = {
  children: PropTypes.node.isRequired,
  location: PropTypes.shape().isRequired,
  channels: PropTypes.arrayOf(channelShape).isRequired,
  directMessages: PropTypes.arrayOf(directMessageShape).isRequired,
  getChatInfo: PropTypes.func.isRequired,
  user: userShape.isRequired,
  getUserInfo: PropTypes.func.isRequired,
  getSummaryInfo: PropTypes.func.isRequired,
  getUserUpcomingGoals: PropTypes.func.isRequired,
  getTimelinePosts: PropTypes.func.isRequired,
  newPreDirectMessage: PropTypes.func.isRequired,
  getNotificationsInfo: PropTypes.func.isRequired,
  notifications: notificationsShape.isRequired,
  alert: alertShape.isRequired,
  getAlertInfo: PropTypes.func.isRequired,
  readUserAlert: PropTypes.func.isRequired,
  cleanMessage: PropTypes.func.isRequired,
  subscriptionExpired: PropTypes.bool,
  checkpoint: PropTypes.instanceOf(Map).isRequired,
}

App.defaultProps = {
  subscriptionExpired: false,
}

export default authRoute(
  withWebSocket(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(React.memo(App))
  )
)
