import { List } from 'immutable'

import Model from './model'

class Suggestion extends Model({
  count: undefined,
  results: List(),
}) {}

export default Suggestion
