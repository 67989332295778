import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'

import Input from '_components/input'
import SwitchButton from '_components/switch-button'
import ModalCard from '_components/modal/modal-card'

import styles from './styles.css'

const NewChannelCard = ({
  onClose,
  isOpen,
  newChannel,
  isLoading,
  createChannelError,
  isAdmin,
}) => {
  const [name, setChannelName] = useState('')
  const [isPrivate, setPrivate] = useState(false)

  const onChannelNameChange = useCallback(event => setChannelName(event.target.value), [])

  const onPrivateChange = useCallback(status => setPrivate(status), [])

  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      newChannel({ name, private: isPrivate })
    },
    [isPrivate, name, newChannel]
  )

  const onButtonClick = useCallback(() => newChannel({ name, private: isPrivate }), [
    isPrivate,
    name,
    newChannel,
  ])

  return (
    <ModalCard
      onCloseButton={onClose}
      title="Create a New Channel"
      buttonLabel="Create Channel"
      isOpen={isOpen}
      onClose={onClose}
      onButtonClick={onButtonClick}
      isLoading={isLoading}
    >
      <form onSubmit={onSubmit} className={styles.form}>
        <Input
          label="Channel Name"
          onChange={onChannelNameChange}
          value={name}
          disabled={isLoading}
          errorMessage={createChannelError}
        />
        {isAdmin && (
          <div className={styles.private}>
            <h5>private</h5>
            <SwitchButton onChange={onPrivateChange} checked={isPrivate} disabled={isLoading} />
          </div>
        )}
      </form>
    </ModalCard>
  )
}
NewChannelCard.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  newChannel: PropTypes.func,
  isLoading: PropTypes.bool,
  createChannelError: PropTypes.string,
  isAdmin: PropTypes.bool,
}

NewChannelCard.defaultProps = {
  newChannel: () => {},
  isLoading: false,
  createChannelError: '',
  isAdmin: false,
}

export default React.memo(NewChannelCard)
