import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import Card, { CardSize } from '_components/card'
import Button, { ButtonTheme } from '_components/button'
import CloseIcon from '_assets/icons/close.svg'

import styles from './styles.css'

const ModalTextarea = ({
  title,
  onClose,
  onSubmit,
  buttonLabel,
  placeholder,
  value,
  isLoading,
}) => {
  const [content, setContent] = useState(value)

  const updateText = useCallback(
    event => {
      event.preventDefault()
      setContent(event.target.value)
    },
    [setContent]
  )

  const onFormSubmit = useCallback(
    event => {
      event.preventDefault()
      onSubmit(content)
    },
    [content, onSubmit]
  )

  return (
    <Card size={CardSize.LARGE} className={styles.container}>
      <div className={styles.header}>
        <h2 className={styles.title}>{title}</h2>
        <Button
          className={styles.close}
          iconOnly
          icon={CloseIcon}
          onClick={onClose}
          label="Close"
        />
      </div>
      <form className={styles.form} onSubmit={onFormSubmit}>
        <textarea
          className={styles.content}
          placeholder={placeholder}
          onChange={updateText}
          value={content}
        />
        <div className={styles.submit}>
          <Button
            type="submit"
            theme={ButtonTheme.PRIMARY}
            label={buttonLabel}
            loading={isLoading}
          />
        </div>
      </form>
    </Card>
  )
}

ModalTextarea.propTypes = {
  title: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  buttonLabel: PropTypes.string,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func,
  isLoading: PropTypes.bool,
}

ModalTextarea.defaultProps = {
  title: '',
  value: '',
  placeholder: '',
  buttonLabel: '',
  onClose: () => {},
  onSubmit: () => {},
  isLoading: false,
}

export default ModalTextarea
