import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'

import HomeIcon from '_assets/icons/menu-home.svg'
import CoursesIcon from '_assets/icons/menu-courses.svg'
import MessagesIcon from '_assets/icons/menu-chat.svg'
import SearchIcon from '_assets/icons/menu-search.svg'
import LockedIcon from '_assets/icons/menu-locked.svg'
import ChatInfoIcon from '_assets/icons/info-chat.svg'
import SearchInfoIcon from '_assets/icons/info-search.svg'
import DisabledTooltipButton, { TOOLTIP_POSITION } from '_components/disabled-tooltip-button'

import styles from './styles.css'

const CHAT_INFO = {
  title: 'Talk with our colleagues',
  description: 'Activate your membership to have access to the chat.',
  icon: ChatInfoIcon,
}

const SEARCH_INFO = {
  title: 'Search for people, posts, messages and more',
  description: 'Activate your membership to have access to the search.',
  icon: SearchInfoIcon,
}
const isActive = props => {
  const { isCurrent, href, location } = props
  const activeClass = { className: classnames(styles['menu-item'], styles.active) }

  if (
    (href.includes('/courses') && location.pathname.includes('/class')) ||
    (href.includes('/messages') && location.pathname.includes('/messages'))
  ) {
    return activeClass
  }

  return isCurrent ? activeClass : {}
}

const SidebarMenu = ({
  className,
  style,
  unreadMessagesCount,
  messageRedirectUrl,
  subscriptionExpired,
  isHorizontalMenu,
}) => {
  const menuItems = useMemo(
    () => [
      {
        tooltip: 'Dashboard',
        to: '/dashboard',
        icon: HomeIcon,
        disabled: false,
      },
      {
        tooltip: 'Classes',
        to: '/courses',
        icon: CoursesIcon,
        disabled: false,
      },
      {
        tooltip: 'Messages',
        to: messageRedirectUrl,
        icon: MessagesIcon,
        disabled: subscriptionExpired,
        cardInfo: CHAT_INFO,
        hasMessagesCount: true,
      },
      {
        tooltip: 'Search',
        to: '/search',
        icon: SearchIcon,
        disabled: subscriptionExpired,
        cardInfo: SEARCH_INFO,
      },
    ],
    [messageRedirectUrl, subscriptionExpired]
  )
  return (
    <section className={classnames(styles['sidebar-menu'], className)} style={style}>
      <ul className={styles.menu}>
        {menuItems.map(({ tooltip, to, icon, hasMessagesCount, disabled, cardInfo }) => (
          <li key={tooltip}>
            {disabled ? (
              <DisabledTooltipButton
                tooltipPosition={
                  isHorizontalMenu ? TOOLTIP_POSITION.BOTTOM : TOOLTIP_POSITION.RIGHT
                }
                cardInfo={cardInfo}
                className={styles['blocked-menu-item']}
              >
                <svg
                  aria-label={tooltip}
                  role="img"
                  viewBox={icon.viewBox}
                  className={styles['disabled-icon']}
                >
                  <use xlinkHref={`#${icon.id}`} />
                </svg>
                <svg
                  aria-hidden="true"
                  viewBox={LockedIcon.viewBox}
                  className={styles['locked-icon']}
                >
                  <use xlinkHref={`#${LockedIcon.id}`} />
                </svg>
              </DisabledTooltipButton>
            ) : (
              <Link
                to={to}
                aria-label={tooltip}
                className={styles['menu-item']}
                getProps={isActive}
                tooltip={tooltip}
              >
                <svg aria-hidden="true" viewBox={icon.viewBox}>
                  <use xlinkHref={`#${icon.id}`} />
                </svg>
              </Link>
            )}
            {hasMessagesCount &&
              !!unreadMessagesCount && (
                <div className={styles['unread-badge']}>{unreadMessagesCount}</div>
              )}
          </li>
        ))}
      </ul>
    </section>
  )
}

SidebarMenu.propTypes = {
  className: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  style: PropTypes.object,
  unreadMessagesCount: PropTypes.number,
  messageRedirectUrl: PropTypes.string,
  subscriptionExpired: PropTypes.bool.isRequired,
  isHorizontalMenu: PropTypes.bool,
}

SidebarMenu.defaultProps = {
  className: '',
  style: {},
  unreadMessagesCount: 0,
  messageRedirectUrl: null,
  isHorizontalMenu: false,
}

export default React.memo(SidebarMenu)
