import React, { useEffect, useMemo, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from '@reach/router'

import ClassPicturePlaceholder from '_assets/images/class-placeholder-photo.jpg'
import CourseSection from '_components/course-section'
import ArrowLeft from '_assets/icons/arrow-left.svg'
import IconButton, { ICON_BUTTON_SIZE } from '_components/icon-button'
import CourseTag, { COURSE_TAG_THEME } from '_components/course-tag'
import CourseIntention, { COURSE_INTENTION_THEME } from '_components/course-intention'
import ClassHubCoaches from '_components/class-hub-coaches'
import ClassAttachments, { CLASS_ATTACHMENTS_THEME } from '_components/class-attachments'
import ClassParticipants from '_components/class-participants'
import ClassChannel from '_components/class-channel'
import HubFooter from '_components/hub-footer'
import { getAllParticipants, getClass, startAssignmentVideo } from '_modules/class/actions'
import {
  getClassById,
  isStartingCallSelector,
  participantsSelector,
  isGetClassLoadingSelector,
} from '_modules/class/selectors'
import { formattedSections } from '_utils/helpers'
import RocketLoader from '_components/rocket-loader'
import useWindowSize from '_hooks/use-window-size'
import { FLAG_MULTIPLE_COACHES } from '_config/environment'
import { CLASS_TYPE } from '_constants/class'

import styles from './styles.css'

const ClassHubDetails = () => {
  const dispatch = useDispatch()
  const isStartingCallLoading = useSelector(isStartingCallSelector)
  const { classId } = useParams()
  const isLoadingClass = useSelector(isGetClassLoadingSelector)
  const currentClass = useSelector(state => getClassById(state, classId.toString()))
  const participants = useSelector(participantsSelector)
  const { isMobile } = useWindowSize()

  const onStartMeetingClick = useCallback(
    ({ meetingId, assignmentId }) => {
      dispatch(
        startAssignmentVideo({
          classId,
          meetingId,
          assignmentId,
        })
      )
    },
    [classId, dispatch]
  )

  const handleClassCoaches = useMemo(
    () => {
      if (FLAG_MULTIPLE_COACHES) {
        return currentClass?.instructors
      }

      if (currentClass?.instructor) {
        return [currentClass.instructor]
      }

      return []
    },
    [currentClass]
  )

  const isInstructor = useMemo(() => currentClass.isMainInstructor, [currentClass])

  useEffect(
    () => {
      dispatch(getClass(classId))
    },
    [classId, dispatch]
  )

  useEffect(
    () => {
      if (classId) {
        dispatch(getAllParticipants(classId))
      }
    },
    [classId, dispatch]
  )

  const sectionsFormatted = formattedSections(currentClass)

  const attachmentFiles = useMemo(
    () => {
      if (!currentClass.assignments?.length) {
        return []
      }

      return currentClass?.assignments.flatMap(assignment => assignment?.attachments)
    },
    [currentClass]
  )

  const classCoverPhoto = useMemo(
    () => currentClass?.coverPhoto || ClassPicturePlaceholder,
    [currentClass]
  )

  return (
    <section className={styles['class-hub-details-container']}>
      {isLoadingClass ? (
        <div className={styles['loader-wrapper']}>
          <RocketLoader />
        </div>
      ) : (
        <>
          <img
            className={styles['class-hub-details-cover']}
            src={classCoverPhoto}
            alt="Class background top cover"
          />
          <div className={styles['image-overlay']} />
          <div className={styles['content-wrapper']}>
            <div className={styles['class-hub-header']}>
              <CourseTag
                text={currentClass.category}
                theme={COURSE_TAG_THEME.DARK}
                className={styles['course-tag']}
              />
              <IconButton
                className={styles['back-icon-button']}
                size={ICON_BUTTON_SIZE.X_LARGE}
                href="/courses"
                icon={ArrowLeft}
              />
              <h1 className={styles['course-title']}>{currentClass?.name}</h1>
            </div>
            <div className={styles['class-content']}>
              {currentClass?.description && <p>{currentClass.description}</p>}
              {sectionsFormatted.map(section => (
                <CourseSection
                  key={section.order}
                  isInstructor={isInstructor}
                  onStartMeeting={onStartMeetingClick}
                  isLeaderLed={currentClass?.type === CLASS_TYPE.COACH_LED.slug}
                  className={styles['course-section']}
                  isStartingCall={isStartingCallLoading}
                  assignmentClassName={styles.assignment}
                  assignmentsWrapperClassName={styles['assignments-wrapper']}
                  title={section.title}
                  assignments={section.assignments}
                />
              ))}
            </div>
            <div className={styles['class-details']}>
              <CourseIntention
                className={styles['course-intention-card']}
                intention={currentClass?.intention}
                theme={COURSE_INTENTION_THEME.CLASS_HUB}
                classId={currentClass?.id}
              />
              {handleClassCoaches?.length > 0 && (
                <ClassHubCoaches
                  classId={currentClass?.id}
                  className={styles['class-coaches']}
                  coaches={handleClassCoaches}
                />
              )}
              {!!attachmentFiles?.length && (
                <ClassAttachments
                  attachmentList={attachmentFiles}
                  theme={CLASS_ATTACHMENTS_THEME.CARD_ON_MOBILE}
                />
              )}
              {!!participants?.size && (
                <ClassParticipants classId={currentClass?.id} participantsList={participants} />
              )}
              {currentClass.channel && <ClassChannel channel={currentClass?.channel} />}
              {isMobile && <div className={styles.divider} />}
            </div>
          </div>
          {!isMobile && <HubFooter />}
        </>
      )}
    </section>
  )
}

export default ClassHubDetails
