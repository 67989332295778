import React, { useEffect, useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { navigate } from '@reach/router'

import { getClass, bulkUpdateAnswers } from '_modules/class/actions'
import {
  getClassById,
  isBulkUpdateAnswerLoadingSelector,
  bulkUpdateAnswerErrorSelector,
} from '_modules/class/selectors'
import { usePrevious } from '_utils/hooks'
import Toastr, { ToastrTheme } from '_components/toastr'
import { ASSIGNMENT_TYPE } from '_constants/assignment'
import ASSIGNMENT_ACTIONS from '_modules/assignment/actions'
import { isCreatingAssignmentPostSelector } from '_modules/assignment/selectors'
import RocketLoader from '_components/rocket-loader'
import CallDetails from '_views/call-details'

import AssignmentInformation from './assignment-view'
import ViewAllResponses from './view-all-responses'
import styles from './styles.css'

const { createPost, editPost } = ASSIGNMENT_ACTIONS

const AssignmentDetail = ({ location, classId, assignmentId }) => {
  const courseClass = useSelector(state => getClassById(state, classId))
  const isUpdatingAnswers = useSelector(isBulkUpdateAnswerLoadingSelector)
  const wasUpdating = usePrevious(isUpdatingAnswers)
  const updateAnswerError = useSelector(bulkUpdateAnswerErrorSelector)
  const isPosting = useSelector(isCreatingAssignmentPostSelector)

  const [goNext, setNext] = useState(false)

  const dispatch = useDispatch()

  const assignment = useMemo(
    () =>
      courseClass?.assignments?.find(
        courseAssignment => courseAssignment.id === Number(assignmentId)
      ),
    [assignmentId, courseClass]
  )

  const currentAssignmentIndex = useMemo(
    () =>
      courseClass?.assignments?.findIndex(
        currentAssignment => currentAssignment.id === Number(assignmentId)
      ),
    [assignmentId, courseClass]
  )

  const nextAssignmentIndex = useMemo(
    () =>
      currentAssignmentIndex + 1 <= courseClass.assignments.length - 1
        ? currentAssignmentIndex + 1
        : -1,
    [courseClass.assignments.length, currentAssignmentIndex]
  )

  const isVideo =
    courseClass?.assignments[currentAssignmentIndex]?.assignment?.type === ASSIGNMENT_TYPE.VIDEO
  const isNextAssignmentBlocked = useMemo(() => nextAssignmentIndex === -1, [nextAssignmentIndex])

  const isLastAssignment = useMemo(
    () => currentAssignmentIndex + 1 === courseClass.assignments.length,
    [courseClass.assignments.length, currentAssignmentIndex]
  )

  const nextAssignment = useMemo(
    () => nextAssignmentIndex !== -1 ? courseClass.assignments[nextAssignmentIndex] : undefined,
    [courseClass.assignments, nextAssignmentIndex]
  )

  const onCreatePost = useCallback(payload => dispatch(createPost(payload)), [dispatch])

  const onEditPost = useCallback(
    (payload, postId) =>
      dispatch(
        editPost({
          ...payload,
          postId,
          classId: Number(classId),
          assignmentId: Number(assignmentId),
        })
      ),
    [assignmentId, classId, dispatch]
  )

  useEffect(
    () => {
      dispatch(getClass(classId))
    },
    [classId, dispatch]
  )

  useEffect(
    () => {
      if (wasUpdating && !isUpdatingAnswers) {
        if (updateAnswerError.size !== 0) {
          toast(
            <Toastr
              theme={ToastrTheme.ERROR}
              content="We couldn't update your answers, please, try again."
            />
          )
          return
        }

        if (goNext) {
          setNext(false)

          if (isNextAssignmentBlocked || isLastAssignment || !nextAssignment) {
            navigate([`/class/${classId}`])
            return
          }

          navigate([`/class/${classId}/assignment/${nextAssignment.id}`])
        }
      }
    },
    [
      assignmentId,
      classId,
      courseClass.assignments,
      currentAssignmentIndex,
      goNext,
      isLastAssignment,
      isNextAssignmentBlocked,
      isUpdatingAnswers,
      nextAssignment,
      updateAnswerError.size,
      wasUpdating,
    ]
  )

  const onSaveClick = useCallback(
    (answers, next = false) => {
      setNext(next)
      dispatch(bulkUpdateAnswers(Number(classId), Number(assignmentId), answers))
    },
    [assignmentId, classId, dispatch]
  )

  if (courseClass.assignments.length === 0 || !assignment) {
    return (
      <div className={styles.loading}>
        <RocketLoader />
      </div>
    )
  }

  if (location.pathname.includes('responses')) {
    return <ViewAllResponses myClass={courseClass} assignment={assignment} />
  }

  return isVideo ? (
    <CallDetails assignment={assignment} nextAssignmentId={nextAssignment?.id} />
  ) : (
    <AssignmentInformation
      myClass={courseClass}
      assignment={assignment}
      onSaveAction={onSaveClick}
      isLoading={isUpdatingAnswers}
      hideNextButton={isLastAssignment}
      onCreatePost={onCreatePost}
      isPosting={isPosting}
      onEditPost={onEditPost}
      isLastAssignment={isLastAssignment}
    />
  )
}

AssignmentDetail.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
  }).isRequired,
  classId: PropTypes.string.isRequired,
  assignmentId: PropTypes.string.isRequired,
}

export default React.memo(AssignmentDetail)
