import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import { useLocation } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import { useDebouncedCallback } from 'use-debounce'

import Input, { INPUT_TYPE } from '_components/landing-components/input'
import SearchIcon from '_assets/icons/search.svg'
import ToggleButton from '_components/toggle-button'
import Paginator from '_components/paginator'
import { COURSE_TYPES_INFO } from '_constants/course'
import Spinner from '_components/spinner'
import { TOGGLE_FILTER_OPTIONS } from '_utils/constants'
import {
  publicCategorySelector,
  categoryTypeAvailableClasses,
  availableCategoryClassesSelector,
  isgetListCategoryAvailableClassesLoadingSelector,
} from '_modules/public-categories/selectors'
import NoSearchResultsIcon from '_assets/icons/no-search-results.svg'
import Svg from '_components/svg'
import { classesListSlicePosition } from '_modules/prismic/actions'
import usePrevious from '_hooks/use-previous'
import { getSlicesPosition } from '_modules/prismic/selectors'
import useFetchCall from '_hooks/use-fetch-call'
import {
  getListCategoryAvailableClasses,
  GET_AVAILABLE_CLASSES_BY_COURSE,
  GET_CATEGORY_AVAILABLE_CLASSES,
} from '_modules/public-categories/actions'

import ClassElement from './class-element'
import styles from './styles.css'

const SPINNER_COLOR = 'var(--malibu)'
const CLASSES_PER_PAGE = 5
const FIRST_PAGE = 1

const ClassesList = ({ id }) => {
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const classesListRef = useRef(null)
  const previousClassRef = usePrevious(classesListRef)
  const [selectedToggleFilter, setSelectedToggleFilter] = useState(
    TOGGLE_FILTER_OPTIONS.COACH_LED.value
  )
  const currentCoursePage = Object.values(COURSE_TYPES_INFO).filter(
    course => course.path === pathname
  )[0]

  const { selfPaced, coachLed } = useSelector(state =>
    categoryTypeAvailableClasses(state, currentCoursePage.name)
  )

  const prismicSlicesPosition = useSelector(getSlicesPosition)
  const isLoadingPublicClasses = useSelector(isgetListCategoryAvailableClassesLoadingSelector)

  const publicCategory = useSelector(state => publicCategorySelector(state, currentCoursePage.name))
  const classesList = useSelector(state =>
    availableCategoryClassesSelector(state, currentCoursePage.name)
  )

  const totalClasses = publicCategory?.getIn(['search', 'count'])
  const classesSlicePosition = prismicSlicesPosition?.get('classesList')

  const typeFilter = useMemo(
    () =>
      selfPaced > coachLed
        ? TOGGLE_FILTER_OPTIONS.SELF_PACED.value
        : TOGGLE_FILTER_OPTIONS.COACH_LED.value,
    [coachLed, selfPaced]
  )

  const filterInitialState = useMemo(
    () => {
      setSelectedToggleFilter(typeFilter)

      return {
        slug: currentCoursePage.name,
        types: typeFilter,
        page: FIRST_PAGE,
        pageSize: CLASSES_PER_PAGE,
      }
    },
    [currentCoursePage.name, typeFilter]
  )

  const getAvailableClasses = useCallback(
    () => {
      const params = {
        types: typeFilter,
        pageSize: CLASSES_PER_PAGE,
      }
      dispatch(getListCategoryAvailableClasses(currentCoursePage.name, params))
    },
    [currentCoursePage.name, dispatch, typeFilter]
  )

  useFetchCall(GET_AVAILABLE_CLASSES_BY_COURSE, getAvailableClasses)

  const [isChangingClassType, setIsChangingClassType] = useState(true)
  const [filter, setFilter] = useState(filterInitialState)

  const [searchValue, setSearchValue] = useState('')
  const [tipSearchValue, setTipSearchValue] = useState('')

  const classesListScreenPosition = useCallback(
    () => ({
      top: classesListRef.current?.offsetTop - classesListRef.current?.offsetHeight,
      bottom: classesListRef.current?.offsetTop + classesListRef.current?.offsetHeight,
    }),
    []
  )

  useEffect(
    () => {
      if (classesSlicePosition?.top !== classesListScreenPosition()?.top) {
        dispatch(classesListSlicePosition(classesListScreenPosition()))
      }
    },
    [classesListScreenPosition, classesSlicePosition, dispatch, previousClassRef]
  )

  useEffect(() => 
     () => {
      dispatch(classesListSlicePosition({ top: 0, bottom: 0 }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  , [])

  const onToggleClick = useCallback(
    value => {
      setSelectedToggleFilter(value)
      setFilter(prevValue => ({
        ...prevValue,
        page: FIRST_PAGE,
        types: value,
      }))
      setIsChangingClassType(true)

      const params = {
        ...filter,
        page: FIRST_PAGE,
        types: value,
      }
      dispatch(getListCategoryAvailableClasses(currentCoursePage.name, params))
    },
    [currentCoursePage.name, dispatch, filter]
  )

  useFetchCall(GET_CATEGORY_AVAILABLE_CLASSES, () => setIsChangingClassType(false))

  const toggleButtonFilter = Object.values(TOGGLE_FILTER_OPTIONS)

  const onPageChange = useCallback(
    page => {
      setFilter(prevValue => ({
        ...prevValue,
        page,
      }))
      dispatch(getListCategoryAvailableClasses(currentCoursePage.name, { ...filter, page }))
    },
    [currentCoursePage.name, dispatch, filter]
  )

  const onSearchClass = useCallback(
    value => {
      setTipSearchValue(value.search)
      dispatch(getListCategoryAvailableClasses(currentCoursePage.name, { ...value }))
    },
    [currentCoursePage.name, dispatch]
  )

  const debouncedSearchClass = useDebouncedCallback(onSearchClass, 500)

  const onSearchChange = useCallback(
    event => {
      const { value } = event.target
      setSearchValue(value)
      setFilter(prevValue => ({
        ...prevValue,
        search: value,
      }))
      debouncedSearchClass({ ...filter, search: value })
    },
    [debouncedSearchClass, filter]
  )

  const renderClasses = useCallback(
    () =>
      classesList?.size > 0 ? (
        <ul>
          {classesList.map(currentClass => (
            <ClassElement key={currentClass.id} currentClass={currentClass} />
          ))}
        </ul>
      ) : (
        <div className={styles['empty-state-wrapper']}>
          <Svg icon={NoSearchResultsIcon} className={styles['no-search-results-icon']} />
          <p className={styles['empty-state-text']}>No classes found</p>
          {tipSearchValue && (
            <>
              <p>{`There are no classes for the search ”${tipSearchValue}”`}</p>
              <p className={styles['tip-text']}>Clear search and try again</p>
            </>
          )}
        </div>
      ),
    [classesList, tipSearchValue]
  )

  return (
    <section className={styles['class-list-container']} id={id} ref={classesListRef}>
      <h1 className={styles['class-list-title']}>Classes</h1>
      <div className={styles['class-filter-container']}>
        <ToggleButton
          filterList={toggleButtonFilter}
          selectedValue={selectedToggleFilter}
          onToggleClick={onToggleClick}
          className={styles['class-filter']}
        />

        <Input
          icon={SearchIcon}
          type={INPUT_TYPE.SEARCH}
          onChange={onSearchChange}
          value={searchValue}
          placeholder="Search for a class or a leader"
        />
      </div>
      {isLoadingPublicClasses ? (
        <div className={styles['spinner-wrapper']}>
          <Spinner size={100} color={SPINNER_COLOR} />
        </div>
      ) : (
        renderClasses()
      )}
      {classesList?.size > 0 &&
        !isChangingClassType && (
          <Paginator
            totalItems={totalClasses}
            itemsPerPage={CLASSES_PER_PAGE}
            onPageChange={onPageChange}
            className={styles.paginator}
          />
        )}
    </section>
  )
}

ClassesList.propTypes = {
  id: PropTypes.string.isRequired,
}

export default React.memo(ClassesList)
