import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'
import classnames from 'classnames'
import { useLocation } from '@reach/router'

import SectionContent from '_components/landing-components/section-content'
import SectionTitle from '_components/landing-components/section-title'
import { prismicTitlePropType, prismicContentPropType } from '_utils/proptypes'
import { CORE_PROGRAMS_PATHS } from '_constants/course'

import styles from './styles.css'

const videoConfig = { vimeo: { preload: true } }

const DEFAULT_VIDEO_URL = 'https://player.vimeo.com/video/255116309'

const AboutOurFounder = ({ slice, id }) => {
  const hasContent = useMemo(() => slice.content?.length > 0, [slice.content])
  const { pathname } = useLocation()
  const isCoursePage = CORE_PROGRAMS_PATHS.some(path => path === pathname)

  const backgroundStyles = useMemo(
    () => {
      if (slice.backgroundColor) {
        return {
          background: slice.backgroundColor,
        }
      }

      return {}
    },
    [slice.backgroundColor]
  )

  return (
    <section className={styles.section} id={id} style={backgroundStyles}>
      <div
        className={classnames(styles.container, {
          [styles['video-only']]: !hasContent,
          [styles['title-aligned']]: slice.isTitleAlignedWithText,
        })}
      >
        {slice.sectionTitle && (
          <SectionTitle
            title={slice.sectionTitle}
            boldClassName={classnames(styles['bold-text'], {
              [styles['content-video']]: isCoursePage,
            })}
            className={styles.title}
          />
        )}
        <div className={styles.video}>
          <ReactPlayer
            config={videoConfig}
            url={slice.videoUrl || DEFAULT_VIDEO_URL}
            width="100%"
            height="100%"
            controls
          />
        </div>
        {hasContent && (
          <div className={styles.content}>
            {slice.content.map((paragraph, index) => (
              <SectionContent
                // setting key as index since this array is not going to be changed
                key={`content-${index + 1}`}
                text={paragraph}
                className={classnames({ [styles.break]: index > 0 })}
              />
            ))}
          </div>
        )}
      </div>
    </section>
  )
}

AboutOurFounder.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    content: prismicContentPropType,
    videoUrl: PropTypes.string,
    isTitleAlignedWithText: PropTypes.bool,
    backgroundColor: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(AboutOurFounder)
