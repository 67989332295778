import Model from './model'

class Card extends Model({
  id: undefined,
  brand: undefined,
  country: undefined,
  expMonth: undefined,
  expYear: undefined,
  funding: undefined,
  last4: undefined,
  default: undefined,
}) {}

export default Card
