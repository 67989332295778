import { Map, List } from 'immutable'

import { getPage } from '_utils/helpers'
import { createReducer } from '_utils/redux'
import { Notification } from '_models/'
import {
  WEBSOCKET_NEW_NOTIFICATION,
  WEBSOCKET_DELETE_NOTIFICATION,
} from '_modules/websocket/actions'

import { GET_NOTIFICATIONS, READ_NOTIFICATIONS } from './actions'

const INITIAL_STATE = Map({
  count: undefined,
  next: undefined,
  previous: undefined,
  notifications: List(),
})

const transformListOfNotifications = data =>
  List(data.map(notification => new Notification(notification)))

const reorderNotifications = (state, lastNotification) => {
  const newState = Map({
    ...state.toJS(),
    count: state.get('count') + 1,
    notifications: state.get('notifications').unshift(
      new Notification({
        id: lastNotification.notificationId,
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        type: lastNotification.notificationType,
        data: lastNotification.data,
        read: false,
      })
    ),
  })

  return newState
}

const deleteNotification = (state, { notificationId }) => {
  const newState = Map({
    ...state.toJS(),
    count: state.get('count') - 1,
    notifications: state
      .get('notifications')
      .filter(notification => notification.id !== notificationId),
  })

  return newState
}

const notifications = createReducer(INITIAL_STATE, {
  [GET_NOTIFICATIONS.FULFILLED]: (state, { payload }) => {
    const newState = Map({
      count: payload.count,
      next: payload.next ? getPage(payload.next) : undefined,
      previous: payload.previous ? getPage(payload.previous) : undefined,

      notifications: state
        .get('notifications')
        .concat(transformListOfNotifications(payload.results)),
    })

    return newState
  },

  [READ_NOTIFICATIONS.FULFILLED]: (state, { payload }) => {
    const newState = Map({
      count: payload.count,
      next: payload.next ? getPage(payload.next) : undefined,
      previous: payload.previous ? getPage(payload.previous) : undefined,

      notifications: transformListOfNotifications([...payload.results]),
    })

    return newState
  },

  [WEBSOCKET_NEW_NOTIFICATION]: (state, { payload }) => reorderNotifications(state, payload),

  [WEBSOCKET_DELETE_NOTIFICATION]: (state, { payload }) => deleteNotification(state, payload),
})

export default notifications
