import * as publicCategoriesService from '_services/public-categories'
import { defineAction } from '_utils/redux'

export const GET_CATEGORY_AVAILABLE_CLASSES = defineAction('GET_CATEGORY_AVAILABLE_CLASSES')
export const GET_AVAILABLE_CLASSES_BY_COURSE = defineAction('GET_AVAILABLE_CLASSES_BY_COURSE')

export const getListCategoryAvailableClasses = (category, params) => (dispatch, getState) =>
  dispatch({
    type: GET_CATEGORY_AVAILABLE_CLASSES.ACTION,
    payload: publicCategoriesService.getListCategoryAvailableClasses(
      getState().user.get('authToken'),
      category,
      params
    ),
    meta: { category },
  })

export const getCategoryClassesTotalCount = params => dispatch =>
  dispatch({
    type: GET_AVAILABLE_CLASSES_BY_COURSE.ACTION,
    payload: publicCategoriesService.getCategoryClassesTotalCount(params),
  })
