import React, { useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { useLocation } from '@reach/router'
import { useWindowSize } from '@reach/window-size'

import Assignment from '_components/assignment'
import { assignmentShape } from '_utils/proptypes'
import { MOBILE_THRESHOLD } from '_config/media-queries'

import styles from './styles.css'

const MOBILE_HEADER_SIZE = 72
const HEADER_SIZE = 80
const DESKTOP_OFFSET = 80

const CourseSection = ({
  className,
  titleClassName,
  assignmentClassName,
  assignmentsWrapperClassName,
  title,
  assignments,
  readOnly,
  onCompleteTask,
  isInstructor,
  blockedSection,
  onStartMeeting,
  isLeaderLed,
  isSelfLed,
  isStartingCall,
  enableAssignmentVideoCall,
}) => {
  const { state } = useLocation()
  const { width } = useWindowSize()

  useEffect(
    () => {
      if (state?.scrollTo) {
        const element = document.getElementById(`assignment-${state.scrollTo}`)

        if (element) {
          const { top } = element.getBoundingClientRect()

          const headerSize =
            width <= MOBILE_THRESHOLD ? MOBILE_HEADER_SIZE : HEADER_SIZE + DESKTOP_OFFSET

          const slicePosition = top + window.scrollY - headerSize

          window.scrollTo({
            top: slicePosition,
            behavior: 'smooth',
          })
        }
      }
    },
    [state, width]
  )

  const isBlocked = useCallback(
    (assignment, prevAssignment) => {
      // if is a course detail
      if (readOnly) {
        return false
      }

      if (!assignment.assignment) {
        return true
      }

      const hasStarted = moment(assignment.assignment.dueDate)
        .subtract(assignment.assignment.duration, 'days')
        .isBefore(moment())

      if ((prevAssignment && prevAssignment.completed) || hasStarted) {
        return false
      }

      if (!prevAssignment && !blockedSection) {
        return false
      }

      return true
    },
    [blockedSection, readOnly]
  )

  return (
    <div className={classnames(styles.container, className)}>
      <h2 className={classnames(styles.title, titleClassName)}>{title}</h2>
      <div className={classnames(styles.assignments, assignmentsWrapperClassName)}>
        {assignments.map((assignment, index, array) => {
          const shouldBlock = isBlocked(assignment, array[index - 1])

          return (
            <Assignment
              content={assignment}
              className={assignmentClassName}
              readOnly={readOnly}
              key={assignment.id}
              onCompleteTask={onCompleteTask}
              isInstructor={isInstructor}
              blocked={shouldBlock}
              disabledLink={shouldBlock}
              onStartMeetingClick={onStartMeeting}
              isLeaderLed={isLeaderLed}
              isSelfLed={isSelfLed}
              isStartingCall={isStartingCall}
              enableAssignmentVideoCall={enableAssignmentVideoCall}
              cardId={`assignment-${assignment.id}`}
            />
          )
        })}
      </div>
    </div>
  )
}

CourseSection.propTypes = {
  className: PropTypes.string,
  titleClassName: PropTypes.string,
  assignmentClassName: PropTypes.string,
  assignmentsWrapperClassName: PropTypes.string,
  title: PropTypes.string.isRequired,
  assignments: PropTypes.arrayOf(assignmentShape).isRequired,
  readOnly: PropTypes.bool,
  onCompleteTask: PropTypes.func,
  isInstructor: PropTypes.bool,
  blockedSection: PropTypes.bool,
  onStartMeeting: PropTypes.func,
  isLeaderLed: PropTypes.bool,
  isSelfLed: PropTypes.bool,
  isStartingCall: PropTypes.bool,
  enableAssignmentVideoCall: PropTypes.func,
}

CourseSection.defaultProps = {
  className: '',
  titleClassName: '',
  assignmentClassName: '',
  assignmentsWrapperClassName: '',
  readOnly: false,
  onCompleteTask: () => {},
  isInstructor: false,
  blockedSection: false,
  onStartMeeting: () => {},
  isLeaderLed: false,
  isSelfLed: false,
  isStartingCall: false,
  enableAssignmentVideoCall: () => {},
}

export default React.memo(CourseSection)
