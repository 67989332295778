import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ArrowDown from '_assets/icons/angle-down.svg'
import { onMouseDown } from '_utils/aria'
import SectionContent from '_components/landing-components/section-content'
import { prismicContentPropType } from '_utils/proptypes'

import styles from './styles.css'

const ExpandableComponent = ({ className, question, answer }) => {
  const [isPanelOpen, setIsPanelOpen] = useState(false)
  const onTogglePanel = useCallback(() => {
    setIsPanelOpen(prevState => !prevState)
  }, [])

  return (
    <article className={classnames(styles.panel, className)}>
      <button
        className={styles['question-panel']}
        type="button"
        onClick={onTogglePanel}
        key={question}
        onMouseDown={onMouseDown}
      >
        <span className={styles.question}>{question}</span>
        <svg
          aria-hidden="true"
          className={classnames(styles.icon, { [styles['arrow-up']]: isPanelOpen })}
          viewBox={ArrowDown.viewBox}
        >
          <use xlinkHref={`#${ArrowDown.id}`} />
        </svg>
      </button>
      {isPanelOpen && (
        <div className={styles['answer-container']}>
          {answer.map((item, index) => <SectionContent key={`answer-${index + 1}`} text={item} />)}
        </div>
      )}
    </article>
  )
}

ExpandableComponent.propTypes = {
  className: PropTypes.string,
  question: PropTypes.string.isRequired,
  answer: prismicContentPropType.isRequired,
}
ExpandableComponent.defaultProps = {
  className: '',
}
export default React.memo(ExpandableComponent)
