import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'

import PhoneIcon from '_assets/icons/phone-icon-2.svg'
import SessionAssignments from '_assets/icons/session-assignment.svg'
import CalendarIcon from '_assets/icons/calendar-filled-icon.svg'
import Svg from '_components/svg'
import { ASSIGNMENT_TYPE } from '_constants/assignment'

import styles from './styles.css'

const AccordionAssignment = ({ placeholder, assignment }) => {
  const isVideoAssignment = assignment.type === ASSIGNMENT_TYPE.VIDEO

  const handleAssignmentIcon = useMemo(() => (isVideoAssignment ? PhoneIcon : SessionAssignments), [
    isVideoAssignment,
  ])

  const renderDateAndTime = useMemo(
    () => {
      if (!assignment.dueDate) {
        return null
      }

      if (isVideoAssignment) {
        const startTime = isVideoAssignment
          ? moment(assignment.meeting.startTime).format('MMMM D, YYYY [at] LT')
          : ''
        const duration = isVideoAssignment ? `${assignment.meeting.duration} mins` : ''

        return (
          <div className={styles['assignment-time']}>
            <Svg icon={CalendarIcon} className={styles.calendar} />
            <p>
              {startTime} • {duration} (Your date and time)
            </p>
          </div>
        )
      }

      const formattedDueDate = moment(assignment.dueDate, 'YYYY-MM-DD').format('MMMM D, YYYY')
      return (
        <div className={styles['assignment-time']}>
          <Svg icon={CalendarIcon} className={styles.calendar} />
          <p>{formattedDueDate}</p>
        </div>
      )
    },
    [assignment, isVideoAssignment]
  )

  return (
    <article className={styles['assignment-container']}>
      <div className={styles['assignment-icon']}>
        <Svg icon={handleAssignmentIcon} className={styles.icon} />
      </div>

      <div className={styles['assignment-content']}>
        <h1 className={styles.name}>{assignment.name || placeholder}</h1>
        {assignment?.subtitle && <p className={styles.description}>{assignment.subtitle}</p>}
        {renderDateAndTime}
      </div>
    </article>
  )
}

AccordionAssignment.propTypes = {
  assignment: PropTypes.shape({
    type: PropTypes.string,
    name: PropTypes.string,
    subtitle: PropTypes.string,
    dueDate: PropTypes.string,
    meeting: PropTypes.shape({
      startTime: PropTypes.string,
      duration: PropTypes.number,
    }),
  }).isRequired,
  placeholder: PropTypes.string,
}

AccordionAssignment.defaultProps = {
  placeholder: '',
}

export default React.memo(AccordionAssignment)
