import { schema } from 'normalizr'

import Model from './model'

class PublicCoach extends Model({
  id: undefined,
  company: undefined,
  title: undefined,
  photo: undefined,
  cover_photo: undefined,
  role: undefined,
  name: undefined,
  username: undefined,
  following: undefined,
  online: undefined,
  suspended: undefined,
  blocking: undefined,
  isBlockedBy: undefined,
}) {}

const newPublicCoachSchema = new schema.Entity(
  'results',
  {},
  {
    idAttribute: 'id',
    processStrategy: ({ id, ...entity }) => new PublicCoach({ id, ...entity }),
  }
)

export const publicCoachSchema = [newPublicCoachSchema]

export default PublicCoach
