import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'
import { toast } from 'react-toastify'
import { ClipLoader } from 'react-spinners'

import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import Button, { ButtonTheme } from '_components/button'
import Footer from '_components/footer'
import { MOBILE_WIDTH, LoadingColor } from '_utils/constants'
import { userShape, profileShape, postShape } from '_utils/proptypes'
import PeopleEnrolledSection from '_components/people-enrolled-section'
import Modal from '_components/modal'
import ModalTextarea from '_components/modal-textarea'
import CoverPhotoPlaceHolder from '_assets/images/course-cover.png'
import PROFILE_ACTIONS from '_modules/profile/actions'
import {
  updateUser,
  UPDATE_USER,
  hidePosts,
  HIDE_POSTS,
  blockUser,
  BLOCK_USER,
} from '_modules/user/actions'
import Toastr, { ToastrTheme } from '_components/toastr'
import { checkSubscriptionExpired } from '_utils/subscription'
import BlockedDashboard from '_components/join-lightyear-community'

import ProfileInformationCard from '../profile-info-card'
import PostSection from '../post-section'
import Goals from '../goals'
import SelectedPost from '../selected-posts'

import styles from './styles.css'
import EditImageModal from './edit-image-modal'

const {
  UPDATE_MY_FUTURE,
  updateMyFuture,
  createPost,
  editPost,
  deletePost,
  CREATE_POST,
  EDIT_POST,
  listPosts,
  getUserPosts,
  shazam,
  unshazam,
  flagPost,
  FLAG_POST,
} = PROFILE_ACTIONS

const mapStateToProps = ({ loading, error, user }) => ({
  isUpdatingMyFuture: !!loading.get(UPDATE_MY_FUTURE.ACTION),
  isPosting: !!loading.get(CREATE_POST.ACTION) || !!loading.get(EDIT_POST.ACTION),
  postingError: error.get(CREATE_POST.ACTION),
  isLoadingPicture: !!loading.get(UPDATE_USER.ACTION),
  loadingPictureError: error.get(UPDATE_USER.ACTION, Map()),
  isHidingPost: !!loading.get(HIDE_POSTS.ACTION),
  isBlockingUser: !!loading.get(BLOCK_USER.ACTION),
  hidePostError: error.get(HIDE_POSTS.ACTION),
  blockUserError: error.get(BLOCK_USER.ACTION),
  isFlaggingPost: !!loading.get(FLAG_POST.ACTION),
  flagError: error.get(FLAG_POST.ACTION),
  accountUser: user,
})

const mapDispatchToProps = {
  updateMyFuture,
  createPost,
  editPost,
  deletePost,
  listPosts,
  getUserPosts,
  updateUser,
  shazam,
  unshazam,
  hidePosts,
  blockUser,
  flagPost,
}

const MODAL_TYPE = {
  LEGACY: 'LEGACY',
  VISION: 'VISION',
  MESSAGE: 'MESSAGE',
}

const MODAL_TYPE_TITLE = {
  LEGACY: 'EDIT LEGACY STATEMENT',
  VISION: 'EDIT VISION STATEMENT',
  MESSAGE: 'SEND A MESSAGE',
}

const MODAL_TYPE_NAME = {
  LEGACY: 'legacyStatement',
  VISION: 'visionStatement',
  MESSAGE: '',
}

const isNotMessageModal = type => type === MODAL_TYPE.VISION || type === MODAL_TYPE.LEGACY

class Profile extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    username: PropTypes.string.isRequired,
    myProfile: PropTypes.bool,
    user: userShape.isRequired,
    profile: profileShape.isRequired,
    updateMyFuture: PropTypes.func.isRequired,
    sendDirectMessage: PropTypes.func,
    onClickManageAccount: PropTypes.func,
    isLoading: PropTypes.bool,
    onClickFollow: PropTypes.func,
    onClickUnfollow: PropTypes.func,
    createPost: PropTypes.func.isRequired,
    editPost: PropTypes.func.isRequired,
    deletePost: PropTypes.func.isRequired,
    isPosting: PropTypes.bool.isRequired,
    isLoadingPicture: PropTypes.bool.isRequired,
    loadingPictureError: PropTypes.instanceOf(Map()).isRequired,
    postingError: ImmutablePropTypes.map,
    sendMessageError: ImmutablePropTypes.map.isRequired,
    isUpdatingMyFuture: PropTypes.bool,
    listPosts: PropTypes.func.isRequired,
    getUserPosts: PropTypes.func.isRequired,
    updateUser: PropTypes.func.isRequired,
    shazam: PropTypes.func.isRequired,
    unshazam: PropTypes.func.isRequired,
    selectedPost: postShape,
    hidePostError: PropTypes.instanceOf(Map),
    blockUserError: PropTypes.instanceOf(Map),
    isHidingPost: PropTypes.bool.isRequired,
    isBlockingUser: PropTypes.bool.isRequired,
    blockUser: PropTypes.func.isRequired,
    hidePosts: PropTypes.func.isRequired,
    flagPost: PropTypes.func.isRequired,
    flagError: PropTypes.instanceOf(Map),
    isFlaggingPost: PropTypes.bool.isRequired,
    accountUser: userShape.isRequired,
  }

  static defaultProps = {
    className: '',
    myProfile: false,
    isLoading: false,
    sendDirectMessage: () => {},
    onClickManageAccount: () => {},
    onClickFollow: () => {},
    onClickUnfollow: () => {},
    postingError: Map(),
    isUpdatingMyFuture: false,
    selectedPost: null,
    hidePostError: Map(),
    blockUserError: Map(),
    flagError: Map(),
  }

  constructor(props) {
    super(props)

    this.state = {
      avatarSize:
        typeof window !== 'undefined' && window.innerWidth <= MOBILE_WIDTH
          ? AvatarSize.MEDIUM
          : AvatarSize.LARGE,
      isModalOpen: false,
      title: '',
      buttonLabel: '',
      content: '',
      name: '',
      coverPhoto: '',
      userPhoto: '',
      modalType: null,
      uploadCoverPhoto: false,
      isUploadingProfilePicture: false,
      isEditImageModalOpen: false,
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.isUpdatingMyFuture && !this.props.isUpdatingMyFuture) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isModalOpen: false,
      })
      return
    }

    if (prevProps.isLoading && !this.props.isLoading && this.props.sendMessageError.size === 0) {
      navigate(`/messages?directMessage=${this.props.user.username}`)
      return
    }

    if (prevProps.isLoadingPicture && !this.props.isLoadingPicture && this.state.uploadCoverPhoto) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        uploadCoverPhoto: false,
      })
      return
    }

    if (
      prevProps.isLoadingPicture &&
      !this.props.isLoadingPicture &&
      !this.props.loadingPictureError.size &&
      this.state.isUploadingProfilePicture
    ) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        isUploadingProfilePicture: false,
        isEditImageModalOpen: false,
      })
      return
    }

    if (
      prevProps.isBlockingUser &&
      !this.props.isBlockingUser &&
      this.props.blockUserError.size === 0
    ) {
      navigate('/dashboard')
      return
    }

    if (prevProps.isHidingPost && !this.props.isHidingPost && this.props.hidePostError.size === 0) {
      navigate(`/user/${this.props.profile.user.username}`)
      return
    }

    if (prevProps.isFlaggingPost && !this.props.isFlaggingPost) {
      if (this.props.flagError.size === 0) {
        toast(<Toastr theme={ToastrTheme.SUCCESS} content="The content has been flagged." />)
        return
      }

      toast(
        <Toastr theme={ToastrTheme.ERROR} content="The content couldn't be flagged. Try again." />
      )
    }
  }

  onClose = () => this.setState({ isModalOpen: false })

  onUnfollowClick = () => {
    this.props.onClickUnfollow({
      username: this.props.username,
      peopleYouMayKnow: this.props.myProfile,
    })
  }

  onFollowPeopleYouMayKnow = ({ username }) => {
    this.props.onClickFollow({
      username,
      peopleYouMayKnow: this.props.myProfile,
    })
  }

  onUnfollowPeoplyYouMayKnow = ({ username }) => {
    this.props.onClickUnfollow({
      username,
      peopleYouMayKnow: this.props.myProfile,
    })
  }

  onPostComment = payload => this.props.createPost(payload)

  onPostEdit = (payload, postId) => this.props.editPost({ ...payload, postId })

  onPostDelete = postId => this.props.deletePost({ postId, classId: null, assignmentId: null })

  onHidePosts = username => this.props.hidePosts(username)

  onBlockUser = payload => this.props.blockUser(payload)

  onFlagPost = postId => this.props.flagPost(postId)

  onLoadMorePosts = () => {
    const { myProfile, profile, username, accountUser } = this.props

    const isSubscriptionExpired = checkSubscriptionExpired(accountUser)

    if (isSubscriptionExpired) {
      return
    }

    if (myProfile) {
      this.props.listPosts(true, profile.postsDetails.next)
    } else {
      this.props.getUserPosts(username, profile.postsDetails.next)
    }
  }

  onFollowClick = () => {
    this.props.onClickFollow({
      username: this.props.username,
      peopleYouMayKnow: this.props.myProfile,
    })
  }

  onUploadBackground = event => {
    const { files, value } = event.target

    const filePath = value
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.bmp)$/i

    if (!allowedExtensions.exec(filePath)) {
      toast(<Toastr theme={ToastrTheme.ERROR} content="Please select an image file." />)
    } else if (files && files[0]) {
      this.setState(
        {
          coverPhoto: files[0],
          uploadCoverPhoto: true,
        },
        () => {
          this.props.updateUser({ coverPhoto: this.state.coverPhoto })
        }
      )
    }
  }

  onUploadProfilePicture = event => {
    const { files, value } = event.target

    const filePath = value
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.bmp)$/i
    if (!allowedExtensions.exec(filePath)) {
      toast(<Toastr theme={ToastrTheme.ERROR} content="Please select an image file." />)
    } else if (files && files[0]) {
      this.setState(
        {
          userPhoto: files[0],
          isUploadingProfilePicture: true,
        },
        () => {
          this.toggleEditImageModal()
        }
      )
    }
  }

  onSaveUserPhoto = file => {
    this.props.updateUser({ photo: file })
  }

  setBackgroundFileRef = ref => {
    this.fileBackgroundRef = ref
  }

  setProfilePictureFileRef = ref => {
    this.fileRef = ref
  }

  selectProfilePictureImage = () => {
    this.fileRef.click()
  }

  selectBackgroundImage = () => {
    this.fileBackgroundRef.click()
  }

  toggleModal = type => {
    const details = {
      title: isNotMessageModal(type) ? MODAL_TYPE_TITLE[type] : MODAL_TYPE_TITLE.MESSAGE,
      content: '',
      name: isNotMessageModal(type) ? MODAL_TYPE_NAME[type] : MODAL_TYPE_NAME.MESSAGE,
      modalType:
        type === MODAL_TYPE.VISION || type === MODAL_TYPE.LEGACY ? type : MODAL_TYPE.MESSAGE,
      buttonLabel: type === MODAL_TYPE.VISION || type === MODAL_TYPE.LEGACY ? 'Save' : 'Send',
    }

    if (type === MODAL_TYPE.VISION) {
      const detailsModified = { ...details, content: this.props.profile.visionStatement }
      this.setState({ ...detailsModified, isModalOpen: true })
      return
    }

    if (type === MODAL_TYPE.LEGACY) {
      const detailsModified = { ...details, content: this.props.profile.legacyStatement }
      this.setState({ ...detailsModified, isModalOpen: true })
      return
    }

    this.setState({ ...details, isModalOpen: true })
  }

  handleSubmit = message => {
    const { modalType, name } = this.state
    const { user } = this.props

    if (modalType === MODAL_TYPE.LEGACY || modalType === MODAL_TYPE.VISION) {
      this.props.updateMyFuture({ [name]: message })
      return
    }

    this.props.sendDirectMessage({
      message,
      username: user.username,
      name: user.name,
      photo: user.photo,
      id: user.id,
    })
  }

  myProfileCoverPhoto = () => {
    const { isLoadingPicture, user } = this.props
    const { uploadCoverPhoto } = this.state

    return (
      <button type="button" onClick={this.selectBackgroundImage} className={styles['upload-image']}>
        {isLoadingPicture &&
          uploadCoverPhoto && (
            <div className={styles['cover-loading']}>
              <ClipLoader color={LoadingColor} />
            </div>
          )}
        <img
          src={user.coverPhoto || CoverPhotoPlaceHolder}
          alt={`${user.name} Cover`}
          className={styles.cover}
        />
        {this.renderBackground()}
      </button>
    )
  }

  shazamPost = payload => this.props.shazam(payload)

  unshazamPost = payload => this.props.unshazam(payload)

  toggleEditImageModal = () => {
    this.setState(prevState => ({
      isEditImageModalOpen: !prevState.isEditImageModalOpen,
    }))
  }

  renderProfileSections = () => {
    const {
      myProfile,
      selectedPost,
      username,
      profile,
      isPosting,
      postingError,
      accountUser,
    } = this.props

    if (selectedPost) {
      return (
        <SelectedPost
          myProfile={myProfile}
          className={styles.post}
          post={selectedPost}
          user={accountUser}
          onSubmit={this.onPostComment}
          onPostEdit={this.onPostEdit}
          onPostDelete={this.onPostDelete}
          isPosting={isPosting}
          postingError={postingError}
          shazam={this.shazamPost}
          unshazam={this.unshazamPost}
          onHidePosts={this.onHidePosts}
          onBlockUser={this.onBlockUser}
          onFlagPost={this.onFlagPost}
        />
      )
    }

    return (
      <>
        <Goals
          myProfile={myProfile}
          className={styles.goals}
          username={username}
          goalCategories={profile.goalCategories}
        />
        {myProfile && (
          <PeopleEnrolledSection
            title="People you may know"
            className={styles['people-you-may-know']}
            usersEnrolled={profile.peopleYouMayKnow}
            onFollow={this.onFollowPeopleYouMayKnow}
            onUnfollow={this.onUnfollowPeoplyYouMayKnow}
          />
        )}
        <PostSection
          myProfile={myProfile}
          className={styles.post}
          posts={profile.postsDetails.posts}
          user={accountUser}
          onSubmit={this.onPostComment}
          onPostEdit={this.onPostEdit}
          onPostDelete={this.onPostDelete}
          isPosting={isPosting}
          postingError={postingError}
          hasMore={!!profile.postsDetails.next}
          onLoadMore={this.onLoadMorePosts}
          shazam={this.shazamPost}
          unshazam={this.unshazamPost}
        />
      </>
    )
  }

  renderMyProfilePicture = () => {
    const { isLoadingPicture, user } = this.props
    const { isUploadingProfilePicture, avatarSize } = this.state

    return (
      <button
        type="button"
        onClick={this.selectProfilePictureImage}
        className={styles['upload-profile-image']}
      >
        <div className={classnames(styles.loading, styles.avatar, styles['avatar-border'])}>
          <ProfileAvatar
            className={styles.avatar}
            avatarClassName={styles['avatar-border']}
            isResponsive
            username={user.username}
            roleType={user.role}
            size={avatarSize}
            isLoading={isLoadingPicture && isUploadingProfilePicture}
            src={user.photo}
          />
        </div>

        {this.renderProfilePicture()}
      </button>
    )
  }

  renderProfilePicture = () => {
    const extensions = ['.jpg', '.jpeg', '.png', '.bmp', '.gif']
    return (
      <input
        type="file"
        accept={extensions.toString()}
        ref={this.setProfilePictureFileRef}
        className={styles.input}
        onChange={this.onUploadProfilePicture}
      />
    )
  }

  renderBackground = () => {
    const extensions = ['.jpg', '.jpeg', '.png', '.bmp', '.gif']
    return (
      <input
        type="file"
        accept={extensions.toString()}
        ref={this.setBackgroundFileRef}
        className={styles.input}
        onChange={this.onUploadBackground}
      />
    )
  }

  render() {
    const { className, myProfile, user, profile, selectedPost, accountUser } = this.props

    const {
      avatarSize,
      isModalOpen,
      title,
      buttonLabel,
      content,
      isEditImageModalOpen,
      userPhoto,
      isLoadingPicture,
    } = this.state

    const hasLegacyAndVisionStatements = myProfile && !selectedPost

    const isSubscriptionExpired = checkSubscriptionExpired(accountUser)

    return (
      <>
        <section className={classnames(styles.container, className)}>
          {myProfile ? (
            this.myProfileCoverPhoto()
          ) : (
            <img
              src={user.coverPhoto || CoverPhotoPlaceHolder}
              alt={`${user.name} Cover`}
              className={styles.cover}
            />
          )}
          <div className={styles.content}>
            <div className={styles.leader}>
              {myProfile ? (
                this.renderMyProfilePicture()
              ) : (
                <ProfileAvatar
                  className={styles.avatar}
                  avatarClassName={styles['avatar-border']}
                  isResponsive
                  username={user.username}
                  src={user.photo}
                  roleType={user.role}
                  size={avatarSize}
                />
              )}
              <div className={styles.profile}>
                <div className={styles.user}>
                  <h2 className={styles.name}>{user.name}</h2>
                  <div className={styles['user-information']}>
                    <p className={styles.info}>@{user.username}</p>
                    {user.company && (
                      <>
                        <div className={styles.dot} />
                        <p className={styles.info}>{user.company}</p>
                      </>
                    )}
                    {user.title && (
                      <>
                        <div className={styles.dot} />
                        <p className={styles.info}>{user.title}</p>
                      </>
                    )}
                  </div>
                </div>
                <div className={styles.actions}>
                  {myProfile ? (
                    <Button
                      className={styles['my-profile-button']}
                      theme={ButtonTheme.PRIMARY}
                      label="MANAGE YOUR ACCOUNT"
                      onClick={this.props.onClickManageAccount}
                    />
                  ) : (
                    <>
                      <Button
                        theme={ButtonTheme.SECONDARY}
                        label={profile.following ? 'UNFOLLOW' : 'FOLLOW'}
                        onClick={profile.following ? this.onUnfollowClick : this.onFollowClick}
                      />
                      <Button
                        theme={ButtonTheme.PRIMARY}
                        label="MESSAGE"
                        onClick={this.toggleModal}
                      />
                    </>
                  )}
                </div>
              </div>
            </div>
            {isSubscriptionExpired ? (
              <>
                {myProfile && (
                  <Goals
                    myProfile={myProfile}
                    className={styles['subscription-expired-goals']}
                    username={user.username}
                    goalCategories={profile.goalCategories}
                    isSubscriptionExpired={isSubscriptionExpired}
                  />
                )}
                <BlockedDashboard
                  className={styles.post}
                  heading="Activate Your Future "
                  description="Keep your vision and goals alive with automatic reminders and accountability from the community"
                />
              </>
            ) : (
              <>
                {(hasLegacyAndVisionStatements ||
                  (profile.legacyStatement && profile.legacyStatement.length !== 0)) && (
                  <ProfileInformationCard
                    onEditClick={this.toggleModal}
                    type={MODAL_TYPE.LEGACY}
                    myProfile={myProfile}
                    className={
                      myProfile || profile.legacyStatement.length !== 0 ? styles.left : styles.right
                    }
                    content={profile.legacyStatement}
                  />
                )}
                {(hasLegacyAndVisionStatements ||
                  (profile.visionStatement && profile.visionStatement.length !== 0)) && (
                  <ProfileInformationCard
                    onEditClick={this.toggleModal}
                    type={MODAL_TYPE.VISION}
                    myProfile={myProfile}
                    title="Vision statement"
                    content={profile.visionStatement}
                    className={
                      myProfile || profile.visionStatement.length !== 0 ? styles.right : styles.left
                    }
                  />
                )}
                {this.renderProfileSections()}
              </>
            )}
          </div>
          <Footer />
        </section>
        {isModalOpen && (
          <Modal isOpen onClose={this.onClose}>
            <ModalTextarea
              onSubmit={this.handleSubmit}
              title={title}
              buttonLabel={buttonLabel}
              value={content}
              onClose={this.onClose}
              isLoading={this.props.isUpdatingMyFuture || this.props.isLoading}
              onChange={this.updateText}
            />
          </Modal>
        )}
        {isEditImageModalOpen && (
          <EditImageModal
            closeModal={this.toggleEditImageModal}
            originalImage={userPhoto}
            onSavePicture={this.onSaveUserPhoto}
            isLoading={isLoadingPicture}
          />
        )}
      </>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
