import React, { useCallback, useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate } from '@reach/router'

import LightyearLogo from '_assets/logo.svg'
import MessagesIcon from '_assets/icons/menu-chat.svg'
import NotificationsIcon from '_assets/icons/menu-notifications.svg'
import SearchInput, { SearchType } from '_components/search-input'
import SidebarMenu from '_components/sidebar-menu'
import { queryParam } from '_utils/helpers'
import { directMessageShape, userShape, notificationsShape } from '_utils/proptypes'
import { User } from '_models/'

import HeaderDropdown from './header-dropdown'
import ProfileDropdown from './profile-dropdown'
import styles from './styles.css'

const Header = ({
  className,
  location,
  suspended,
  directMessages,
  notifications,
  user,
  loadMore,
  hasMore,
  messageRedirectUrl,
}) => {
  const [query, setQuery] = useState(queryParam(location.search, 'query') || '')

  const onChange = useCallback(event => {
    const { value } = event.target
    setQuery(value)
  }, [])

  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      navigate([`/search?query=${query}`])
    },
    [query]
  )

  const unreadMessagesCount = useMemo(
    () => (suspended ? 0 : directMessages.filter(message => !message.read).length),
    [directMessages, suspended]
  )

  const unreadNotificationsCount = useMemo(
    () => (suspended ? 0 : notifications.filter(notification => !notification.read).length),
    [notifications, suspended]
  )

  const directMessagesList = useMemo(() => (suspended ? [] : directMessages.slice(0, 4)), [
    directMessages,
    suspended,
  ])

  const notificationsList = useMemo(() => (suspended ? [] : notifications), [
    notifications,
    suspended,
  ])

  return (
    <header className={classnames(styles.container, className)}>
      <section className={styles['logo-container']}>
        <svg
          alt="Lightyear logo"
          viewBox={LightyearLogo.viewBox}
          aria-label="Lightyear logo"
          role="img"
        >
          <use xlinkHref={`#${LightyearLogo.id}`} />
        </svg>
      </section>
      <section className={styles['search-container']}>
        <form className={styles['search-form']} onSubmit={onSubmit}>
          <SearchInput
            type={SearchType.EMBEDDED}
            placeholder="Search"
            onChange={onChange}
            value={query}
          />
        </form>
      </section>
      <section className={styles['menu-actions']}>
        <SidebarMenu
          className={styles.menu}
          unreadMessagesCount={unreadMessagesCount}
          messageRedirectUrl={messageRedirectUrl}
          subscriptionExpired={suspended}
          isHorizontalMenu
        />
        {!suspended && (
          <HeaderDropdown
            messages={directMessagesList}
            icon={MessagesIcon}
            className={styles.messages}
            isMessage
            unread={unreadMessagesCount}
          />
        )}
        <HeaderDropdown
          notifications={notificationsList}
          icon={NotificationsIcon}
          className={styles.notifications}
          unread={unreadNotificationsCount}
          user={user}
          loadMore={loadMore}
          hasMore={hasMore}
          disabled={suspended}
        />
        <ProfileDropdown className={styles.profile} />
      </section>
    </header>
  )
}

Header.propTypes = {
  className: PropTypes.string,
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
  suspended: PropTypes.bool,
  directMessages: PropTypes.arrayOf(directMessageShape),
  notifications: PropTypes.arrayOf(notificationsShape),
  user: userShape,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  messageRedirectUrl: PropTypes.string,
}

Header.defaultProps = {
  className: '',
  suspended: false,
  notifications: [],
  directMessages: [],
  user: new User(),
  loadMore: () => {},
  hasMore: false,
  messageRedirectUrl: '/',
}
export default React.memo(Header)
