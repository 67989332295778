import { List, Map } from 'immutable'
import { schema } from 'normalizr'

import Model from './model'

class Class extends Model({
  type: undefined,
  category: undefined,
  channel: undefined,
  courseName: undefined,
  coursePicture: undefined,
  courseSlug: undefined,
  coverPhoto: undefined,
  customTrialDays: undefined,
  id: undefined,
  isInstructor: undefined,
  isMainInstructor: undefined,
  instructor: undefined,
  instructors: undefined,
  intention: undefined,
  peopleEnrolled: List(),
  assignments: List(),
  company: undefined,
  completed: undefined,
  completePercentage: undefined,
  courseDescription: undefined,
  courseDuration: undefined,
  startDate: undefined,
  endDate: undefined,
  completedDate: undefined,
  dueDate: undefined,
  nextAssignment: Map(),
  description: undefined,
  price: undefined,
  name: undefined,
  sections: List(),
  enrolled: undefined,
}) {}

const classNewSchema = new schema.Entity(
  'result',
  {},
  {
    idAttribute: 'id',
    processStrategy: ({ id, ...entity }) => new Class({ id, ...entity }),
  }
)

export const classSchema = [classNewSchema]

export { Class as default }
