import cookies from 'react-cookies'
import humps from 'humps'
import { fromJS } from 'immutable'

import { User } from '_models/'
import { getPage } from '_utils/helpers'
import { createReducer } from '_utils/redux'
import { INITIAL_ENROLL } from '_modules/class/actions'
import { WEBSOCKET_SUSPENDED, WEBSOCKET_UNSUSPENDED } from '_modules/websocket/actions'

import {
  LOGIN,
  FACEBOOK_LOGIN,
  GOOGLE_LOGIN,
  SIGN_UP,
  UPDATE_USER,
  GET_USER,
  GET_PAYMENT_HISTORY,
  RESTART_SUBSCRIPTION,
  CANCEL_SUBSCRIPTION,
  UNAUTHORIZED,
  LINK_ZOOM,
  GET_HIDDEN_USERS,
  SHOW_POSTS,
  DEACTIVATE_ZOOM,
  CHECK_SOCIAL_SIGN_UP,
  START_MEMBERSHIP,
  GET_AVAILABLE_MEMBERSHIPS,
} from './actions'

const INITIAL_STATE = new User()

const saveUserData = payload => {
  const { authToken, ...data } = humps.camelizeKeys(payload)
  cookies.save('authToken', authToken, { path: '/', maxAge: 60 * 60 * 24 * 6 })
  // 60 * 60 * 24 * 6 expires in the next 6 days
  cookies.save('user', data, { path: '/', maxAge: 60 * 60 * 24 * 6 })
  return new User({
    ...data,
    authToken,
  })
}

const user = createReducer(INITIAL_STATE, {
  [LOGIN.FULFILLED]: (state, { payload }) =>
    saveUserData({ ...payload.user, authToken: payload.key }),

  [SIGN_UP.FULFILLED]: (state, { payload }) =>
    saveUserData({ ...payload.user, authToken: payload.key }),

  [FACEBOOK_LOGIN.FULFILLED]: (state, { payload }) =>
    saveUserData({ ...payload.user, authToken: payload.key }),

  [GOOGLE_LOGIN.FULFILLED]: (state, { payload }) =>
    saveUserData({ ...payload.user, authToken: payload.key }),

  [UPDATE_USER.FULFILLED]: (state, { payload }) => saveUserData({ ...state.toJS(), ...payload }),

  [START_MEMBERSHIP.FULFILLED]: (state, { payload }) =>
    saveUserData({ ...state.toJS(), ...payload }),

  [GET_AVAILABLE_MEMBERSHIPS.FULFILLED]: (state, { payload }) =>
    saveUserData({
      ...state.toJS(),
    }).set(
      'membershipOptions',
      fromJS([
        {
          type: 'monthly',
          token: payload.available_subscriptions[0].monthly[0],
          price: payload.available_subscriptions[0].monthly[1],
        },
        {
          type: 'yearly',
          token: payload.available_subscriptions[1].yearly[0],
          price: payload.available_subscriptions[1].yearly[1],
        },
      ])
    ),

  [RESTART_SUBSCRIPTION.FULFILLED]: (state, { payload }) =>
    saveUserData({ ...state.toJS(), ...payload }),

  [CANCEL_SUBSCRIPTION.FULFILLED]: (state, { payload }) =>
    saveUserData({ ...state.toJS(), ...payload }),

  [GET_PAYMENT_HISTORY.FULFILLED]: (state, { payload }) =>
    saveUserData({
      ...state.toJS(),
    }).set(
      'payments',
      fromJS({
        count: payload.count,
        next: payload.next ? getPage(payload.next) : undefined,
        previous: payload.previous ? getPage(payload.previous) : undefined,
        paymentHistory: humps.camelizeKeys(payload.results),
      })
    ),

  [INITIAL_ENROLL.FULFILLED]: state => saveUserData({ ...state.toJS(), completedOnboarding: true }),

  [GET_USER.FULFILLED]: (state, { payload }) => saveUserData({ ...state.toJS(), ...payload }),

  [WEBSOCKET_SUSPENDED]: state => saveUserData({ ...state.toJS(), suspended: true }),

  [WEBSOCKET_UNSUSPENDED]: state => saveUserData({ ...state.toJS(), suspended: false }),

  [UNAUTHORIZED]: () => INITIAL_STATE,

  [LINK_ZOOM.FULFILLED]: state => saveUserData({ ...state.toJS(), hasZoom: true }),

  [GET_HIDDEN_USERS.FULFILLED]: (state, { payload }) =>
    saveUserData({ ...state.toJS() }).set('hiddenUsers', fromJS(humps.camelizeKeys(payload))),

  [SHOW_POSTS.FULFILLED]: (state, { meta }) =>
    saveUserData({ ...state.toJS() }).update('hiddenUsers', hiddenUsers =>
      hiddenUsers.filter(hiddenUser => hiddenUser.get('username') !== meta.username)
    ),

  [DEACTIVATE_ZOOM.FULFILLED]: state => saveUserData({ ...state.toJS(), hasZoom: false }),

  [CHECK_SOCIAL_SIGN_UP]: state => saveUserData({ ...state.toJS(), isSocialLogin: true }),
})

export default user
