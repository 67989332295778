import React from 'react'

import { coachesPropTypes } from '_utils/proptypes'
import { COACH_LIST_SIZE } from '_utils/constants'

import styles from './styles.css'

const CoachName = ({ coaches }) => {
  if (coaches.size >= COACH_LIST_SIZE.THREE) {
    const firstCoaches = coaches.slice(0, 3)
    return (
      <span className={styles.coaches}>
        <span className={styles['coaches-name-bold']}>
          {firstCoaches.map(
            (coach, index) => `${coach.get('name')}${firstCoaches.size - 1 === index ? ' ' : ', '}`
          )}
        </span>
        {coaches.size - 3 > 0 ? `and ${coaches.size - 3} more` : ''}
      </span>
    )
  }

  if (coaches.size === COACH_LIST_SIZE.TWO) {
    return (
      <span className={styles.coaches}>
        <span className={styles['coaches-name-bold']}>{coaches.first().get('name')}</span> and{' '}
        <span className={styles['coaches-name-bold']}>{coaches.get(1).get('name')}</span>
      </span>
    )
  }

  if (coaches.size === COACH_LIST_SIZE.ONE) {
    return <span className={styles['coaches-name-bold']}>{coaches.first().get('name')}</span>
  }

  return null
}

CoachName.propTypes = {
  coaches: coachesPropTypes,
}

CoachName.defaultProps = {
  coaches: [],
}

export default React.memo(CoachName)
