import VisaLogo from '_assets/icons/visa.png'
import DinersLogo from '_assets/icons/diners.png'
import DiscoverLogo from '_assets/icons/discover.png'
import EmptyLogo from '_assets/icons/empty.png'
import MastercardLogo from '_assets/icons/mastercard.png'
import JBCLogo from '_assets/icons/jbc.png'

const VISA = 'Visa'
const DINERS = 'Diners'
const DISCOVER = 'Discover'
const MASTERCARD = 'Mastercard'
const JBC = 'Jbc'

export const BrandIcon = brandIcon => {
  switch (brandIcon) {
    case VISA:
      return VisaLogo
    case DINERS:
      return DinersLogo
    case DISCOVER:
      return DiscoverLogo
    case MASTERCARD:
      return MastercardLogo
    case JBC:
      return JBCLogo
    default:
      return EmptyLogo
  }
}
