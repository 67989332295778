import * as searchService from '_services/search'
import { defineAction } from '_utils/redux'
import { shazam, unShazam } from '_services/post'

export const SEARCH = defineAction('SEARCH')
export const CLEAR_QUERY = 'CLEAR_QUERY'
export const SHAZAM_SEARCH_POST = defineAction('SHAZAM_SEARCH_POST')
export const UNSHAZAM_SEARCH_POST = defineAction('UNSHAZAM_SEARCH_POST')

export const search = term => (dispatch, getState) => {
  dispatch({
    type: SEARCH.ACTION,
    payload: searchService.search(getState().user.authToken)(term),
  })
}

export const clearQuery = () => ({
  type: CLEAR_QUERY,
})

export const shazamSearchPost = ({ postId, ...rest }) => (dispatch, getState) => {
  dispatch({
    type: SHAZAM_SEARCH_POST.ACTION,
    payload: shazam(getState().user.authToken)(postId),
    meta: {
      postId,
      ...rest,
    },
  })
}

export const unShazamSearchPost = ({ postId, ...rest }) => (dispatch, getState) => {
  dispatch({
    type: UNSHAZAM_SEARCH_POST.ACTION,
    payload: unShazam(getState().user.authToken)(postId),
    meta: {
      postId,
      ...rest,
    },
  })
}
