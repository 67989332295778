import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'
import cookies from 'react-cookies'

import FreeMembershipIcon from '_assets/icons/ic_free_membership.svg'
import Modal, { MOBILE_THEME } from '_components/modal-card'
import Button, { ButtonTheme } from '_components/button'
import ErrorAlertComponent from '_components/error-alert-component'

import styles from './styles.css'

const FreeMembershipTrialModal = ({ onClose }) => {
  const scrollToState = useMemo(
    () => ({
      billing: true,
    }),
    []
  )

  const onCloseModal = useCallback(
    () => {
      cookies.save('subscriptionExpiredDismissed', true, { path: '/' })

      onClose()
    },
    [onClose]
  )

  return (
    <Modal
      isOpen
      onClose={onClose}
      hasCloseButton={false}
      className={styles.modal}
      childrenClassName={styles.content}
      theme={MOBILE_THEME.FIXED_HEIGHT}
    >
      <svg
        className={styles['free-membership-icon']}
        aria-hidden="true"
        viewBox={FreeMembershipIcon.viewBox}
      >
        <use xlinkHref={`#${FreeMembershipIcon.id}`} />
      </svg>
      <h1 className={styles.title}>Your Lightyear Membership has ended</h1>
      <p className={styles.description}>
        Renew your membership to continue having access to our membership benefits, such as the
        Gallery, Lightyear Community and much more.
      </p>
      <ErrorAlertComponent errorMessage="Without a membership, you'll still have access to your classes." />
      <Button
        className={styles['renew-button']}
        theme={ButtonTheme.PRIMARY}
        state={scrollToState}
        to="/account"
        onClick={onCloseModal}
        label="RENEW YOUR MEMBERSHIP"
      />
      <Button
        className={styles['close-button']}
        theme={ButtonTheme.TRANSPARENT_BACKGROUND}
        onClick={onCloseModal}
        label="DISMISS"
      />
    </Modal>
  )
}

FreeMembershipTrialModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default React.memo(FreeMembershipTrialModal)
