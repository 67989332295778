import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { connect, useSelector } from 'react-redux'
import { useLocation } from '@reach/router'
import { List } from 'immutable'

import Footer from '_components/footer'
import Button, { ButtonTheme } from '_components/landing-components/button'
import { getCategories, getCompanyClasses } from '_modules/course/actions'
import { getFormattedCompanyCourses } from '_modules/course/selectors'
import { getClasses, GET_CLASSES } from '_modules/class/actions'
import { getMyClasses, isGettingClassesSelector } from '_modules/class/selectors'
import { userShape, courseShape, classShape } from '_utils/proptypes'
import UpcomingClassesScrollList from '_components/upcoming-classes-scroll-list'
import {
  getAvailableClassesListSelector,
  isGetAvailableClassesLoadingSelector,
} from '_modules/public-classes/selectors'
import { getAvailableClasses } from '_modules/public-classes/actions'
import RocketLoader from '_components/rocket-loader'
import useFetchCall from '_hooks/use-fetch-call'
import { CLASS_FILTERS } from '_utils/constants'

import CompanyCoursesListSection from './company-courses-section'
import MyClassesSection from './my-classes-section'
import styles from './styles.css'

const mapDispatchToProps = {
  getAllCompanyCourses: getCompanyClasses,
  getClassesList: getClasses,
  getCourseCategories: getCategories,
  getUpcomingAvailableClasses: getAvailableClasses,
}

const mapStateToProps = state => ({
  classes: getMyClasses(state),
  companyCoursesList: getFormattedCompanyCourses(state),
  user: state.user,
  categories: state.courses.get('categories').toJS(),
  upcomingClassesList: getAvailableClassesListSelector(state),
})

const UPCOMING_CLASSES_PARAMS = { organizations: false }

const Courses = ({
  getAllCompanyCourses,
  getClassesList,
  getCourseCategories,
  companyCoursesList,
  classes,
  user,
  upcomingClassesList,
  getUpcomingAvailableClasses,
}) => {
  const { state } = useLocation()
  const isGettingClasses = useSelector(isGettingClassesSelector)
  const isLoadingUpcomingClasses = useSelector(isGetAvailableClassesLoadingSelector)
  const [isFirstLoad, setIsFirstLoad] = useState(true)
  const [filter, setFilter] = useState(CLASS_FILTERS[0].ordering)

  useEffect(
    () => {
      if (user.belongsToACompany) {
        getAllCompanyCourses()
      }
      getClassesList({ status: CLASS_FILTERS[0].ordering })
      setFilter(CLASS_FILTERS[0].ordering)
      getUpcomingAvailableClasses(UPCOMING_CLASSES_PARAMS)
    },
    [
      getAllCompanyCourses,
      state,
      getClassesList,
      getCourseCategories,
      getUpcomingAvailableClasses,
      user.belongsToACompany,
    ]
  )

  const onGetClassesSuccess = useCallback(() => {
    setIsFirstLoad(false)
  }, [])

  useFetchCall(GET_CLASSES, onGetClassesSuccess)

  const onGetClasses = useCallback(
    params => {
      const { status } = params
      setFilter(status)
      getClassesList(params)
    },
    [getClassesList]
  )

  return (
    <div className={styles.container}>
      {(isGettingClasses || isLoadingUpcomingClasses) && isFirstLoad ? (
        <div className={styles['loader-wrapper']}>
          <RocketLoader />
        </div>
      ) : (
        <>
          <div className={styles.content}>
            <MyClassesSection
              className={styles['my-courses']}
              title="My classes"
              filter={filter}
              classes={classes.classes}
              onFilterChange={onGetClasses}
              nextPage={classes.next}
              user={user}
            />
            {user.belongsToACompany &&
              companyCoursesList.length > 0 && (
                <CompanyCoursesListSection
                  className={styles['company-courses']}
                  title={`${user.company}`}
                  courses={companyCoursesList}
                />
              )}
            <div className={styles['upcoming-classes']}>
              <div className={styles['upcoming-classes-header']}>
                <h2 className={styles['upcoming-classes-title']}>Explore Upcoming Classes</h2>
                <Button
                  url="/explore-courses"
                  className={styles['see-all-classes-button']}
                  theme={ButtonTheme.TRANSPARENT}
                >
                  See all
                </Button>
              </div>
              <UpcomingClassesScrollList
                classesList={upcomingClassesList}
                params={UPCOMING_CLASSES_PARAMS}
                defaultArrows
              />
            </div>
          </div>
          <Footer />
        </>
      )}
    </div>
  )
}

Courses.propTypes = {
  getAllCompanyCourses: PropTypes.func.isRequired,
  getClassesList: PropTypes.func.isRequired,
  getCourseCategories: PropTypes.func.isRequired,
  companyCoursesList: PropTypes.arrayOf(courseShape).isRequired,
  classes: PropTypes.shape({
    classes: PropTypes.arrayOf(classShape),
    count: PropTypes.number,
    next: PropTypes.number,
    previous: PropTypes.number,
  }).isRequired,
  user: userShape.isRequired,
  upcomingClassesList: PropTypes.instanceOf(List).isRequired,
  getUpcomingAvailableClasses: PropTypes.func.isRequired,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Courses)
