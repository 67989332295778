import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button from '_components/landing-components/button'

import styles from './styles.css'

const CarouselDots = ({ size, currentIndex, className, onDotClick }) => {
  const renderDots = useMemo(
    () => {
      const listSize = Array(size).fill(null)
      const dotsList = listSize.map((item, index) => index)
      return dotsList
    },
    [size]
  )

  return (
    <div className={classnames(styles['carousel-dots-container'], className)}>
      {renderDots.map((value, index) => (
        <Button
          key={value}
          data-slideindex={index}
          onClick={onDotClick}
          className={classnames(styles['carousel-dot-button'], {
            [styles['dot-active']]: currentIndex === index,
          })}
        />
      ))}
    </div>
  )
}

CarouselDots.propTypes = {
  size: PropTypes.number.isRequired,
  currentIndex: PropTypes.number.isRequired,
  className: PropTypes.string,
  onDotClick: PropTypes.func,
}

CarouselDots.defaultProps = {
  className: '',
  onDotClick: () => {},
}

export default CarouselDots
