import React from 'react'

const onClick = event => {
  event.preventDefault()
  window.close()
}

const Zoom = () => (
  <>
    <p>Account connected to Zoom</p>
    <button type="button" onClick={onClick}>
      Close window
    </button>
  </>
)

export default React.memo(Zoom)
