import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button, { ButtonTheme } from '_components/button'
import HashtagIcon from '_assets/icons/hashtag.svg'

import styles from './styles.css'

const ChannelJoin = ({
  id,
  name,
  joined,
  className,
  onJoinClick,
  numberOfMembers,
  innerRef,
  isLoading,
}) => {
  const onJoinChannelClick = useCallback(() => onJoinClick(id), [id, onJoinClick])

  return (
    <div className={classnames(styles.channel, className)} ref={innerRef}>
      <p className={styles.name}>
        <svg role="img" viewBox={HashtagIcon.viewBox} aria-label="Channel icon">
          <use xlinkHref={`#${HashtagIcon.id}`} />
        </svg>
        {name}
      </p>
      <p>
        {numberOfMembers} {numberOfMembers === 1 ? 'member' : 'members'} {joined && '• Joined'}
      </p>
      {!joined && (
        <Button
          className={styles.button}
          label="Join"
          theme={ButtonTheme.SECONDARY}
          onClick={onJoinChannelClick}
          disabled={isLoading}
        />
      )}
    </div>
  )
}
ChannelJoin.propTypes = {
  joined: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  numberOfMembers: PropTypes.number.isRequired,
  onJoinClick: PropTypes.func,
  innerRef: PropTypes.func,
  id: PropTypes.number.isRequired,
  isLoading: PropTypes.bool,
}

ChannelJoin.defaultProps = {
  className: '',
  onJoinClick: () => {},
  innerRef: () => {},
  isLoading: false,
}

export default React.memo(ChannelJoin)
