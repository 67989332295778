import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'

import DefaultAvatar from '_assets/icons/empty-photo.svg'
import { Suggestion } from '_models/'
import { userShape } from '_utils/proptypes'
import { isSearchSuggestionsLoadingSelector } from '_modules/suggestion/selectors'

import styles from './styles.css'

const TaggingUserList = ({
  suggestionRef,
  handleTextareaInput,
  buttonRef,
  suggestedUser,
  className,
}) => {
  const isLoading = useSelector(isSearchSuggestionsLoadingSelector)

  const renderContent = useMemo(
    () => {
      if (suggestedUser.length) {
        return suggestedUser.map(userTagged => (
          <button
            key={userTagged.username}
            type="button"
            aria-label={userTagged.username}
            className={styles.user}
            onClick={handleTextareaInput}
            value={userTagged.username}
            ref={buttonRef}
          >
            <div className={styles.flexbox}>
              {userTagged.photo === null ? (
                <svg
                  aria-label={`${userTagged.name}'s avatar`}
                  role="img"
                  viewBox={DefaultAvatar.viewBox}
                  className={styles.avatar}
                >
                  <use xlinkHref={`#${DefaultAvatar.id}`} />
                </svg>
              ) : (
                <img className={styles.avatar} src={userTagged.photo} alt="profile" />
              )}
              <h5>{userTagged.name}</h5>
              {userTagged.online && <div className={styles.online} />}
              <p>@{userTagged.username}</p>
            </div>
          </button>
        ))
      }
      if (isLoading) {
        return (
          <div className={styles['loading-container']}>
            <div className={styles.loading} />
          </div>
        )
      }

      return <h5 className={styles['no-results']}>No results</h5>
    },
    [buttonRef, handleTextareaInput, isLoading, suggestedUser]
  )

  return (
    <div className={classnames(styles['user-position'], className)} ref={suggestionRef}>
      <div className={styles.overflow}>{renderContent}</div>
    </div>
  )
}

TaggingUserList.propTypes = {
  className: PropTypes.string,
  suggestedUser: PropTypes.oneOfType([
    PropTypes.instanceOf(Suggestion),
    PropTypes.arrayOf(userShape),
  ]).isRequired,
  suggestionRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
  buttonRef: PropTypes.shape({ current: PropTypes.object }).isRequired,
  handleTextareaInput: PropTypes.func.isRequired,
}

TaggingUserList.defaultProps = {
  className: '',
}

export default React.memo(TaggingUserList)
