import React, { useCallback, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import moment from 'moment'
import { StripeProvider, Elements } from 'react-stripe-elements'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { toast } from 'react-toastify'

import Button, { ButtonTheme } from '_components/button'
import Card, { CardSize } from '_components/card'
import CreditCard from '_components/credit-card'
import AddIcon from '_assets/icons/add-no-border.svg'
import Modal from '_components/modal'
import ConfirmationModal from '_components/confirmation-modal'
import {
  ADD_NEW_CARD,
  listCards,
  makeCardDefault,
  removeCard,
  addCard,
  MAKE_DEFAULT,
} from '_modules/card/actions'
import {
  CANCEL_SUBSCRIPTION,
  RESTART_SUBSCRIPTION,
  paymentHistory,
  cancelSubscription,
  restartSubscription,
} from '_modules/user/actions'
import { cardShape, userShape } from '_utils/proptypes'
import { usePrevious } from '_utils/hooks'
import Toastr, { ToastrTheme } from '_components/toastr'
import useStripe from '_hooks/use-stripe'
import { checkSubscriptionExpired } from '_utils/subscription'
import useFetchCall from '_hooks/use-fetch-call'

import styles from './styles.css'
import PaymentHistory from './payment-history'
import AddNewCreditCard from './add-new-card'

const mapStateToProps = ({ cards, loading, error }) => ({
  cards: cards.reduce((acc, curr) => {
    acc.push(curr)
    return acc
  }, []),
  isAddingNewCard: !!loading.get(ADD_NEW_CARD.ACTION),
  isUpdatingSubscription:
    !!loading.get(CANCEL_SUBSCRIPTION.ACTION) || !!loading.get(RESTART_SUBSCRIPTION.ACTION),
  updatingSubscriptionError:
    error.get(CANCEL_SUBSCRIPTION.ACTION) || error.get(RESTART_SUBSCRIPTION.ACTION),
  addNewCardError: error.get(ADD_NEW_CARD.ACTION),
})

const mapDispatchToProps = {
  getCardsList: listCards,
  createCard: addCard,
  makeDefault: makeCardDefault,
  removeSelectedCard: removeCard,
  getPaymentHistory: paymentHistory,
  removeSubscription: cancelSubscription,
  renewSubscription: restartSubscription,
}

const BillingCard = ({
  getCardsList,
  cards,
  removeSelectedCard,
  makeDefault,
  createCard,
  isAddingNewCard,
  isUpdatingSubscription,
  updatingSubscriptionError,
  addNewCardError,
  getPaymentHistory,
  renewSubscription,
  removeSubscription,
  user,
}) => {
  const { stripe } = useStripe()
  const prevIsAddingNewCard = usePrevious(isAddingNewCard)
  const prevIsUpdatingSubscription = usePrevious(isUpdatingSubscription)
  const [isModalOpen, setModalOpen] = useState(false)
  const [isCancelModalOpen, setCancelModalOpen] = useState(false)
  const isActive = useMemo(() => !checkSubscriptionExpired(user), [user])
  const toggleModal = useCallback(() => setModalOpen(!isModalOpen), [isModalOpen])
  const toggleCancelModal = useCallback(() => setCancelModalOpen(!isCancelModalOpen), [
    isCancelModalOpen,
  ])
  const closeModal = useCallback(() => {
    setCancelModalOpen(false)
    setModalOpen(false)
  }, [])

  useEffect(
    () => {
      getCardsList()
      getPaymentHistory()
    },
    [getCardsList, getPaymentHistory]
  )

  useEffect(() => {
    if (prevIsAddingNewCard && !isAddingNewCard) {
      if (addNewCardError.size === 0) {
        toast(<Toastr theme={ToastrTheme.SUCCESS} content="New card added!" />)
        toggleModal()
        return
      }

      toast(
        <Toastr
          theme={ToastrTheme.ERROR}
          content="Error adding new card, please try again later."
        />
      )
    }
  })

  useEffect(() => {
    if (prevIsUpdatingSubscription && !isUpdatingSubscription) {
      if (updatingSubscriptionError.size === 0) {
        toast(<Toastr theme={ToastrTheme.SUCCESS} content="Subscription updated!" />)
        closeModal()
      } else {
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content={`Error updating subscription. ${updatingSubscriptionError.first()}`}
          />
        )
      }
    }
  })

  const loadMore = useCallback(
    () => {
      getPaymentHistory(user.payments.next)
    },
    [getPaymentHistory, user.payments.next]
  )

  const subscriptionClick = useCallback(
    () => {
      if (isActive && !user.subscriptionIsCanceling) {
        toggleCancelModal()
      } else {
        renewSubscription()
      }
    },
    [isActive, renewSubscription, toggleCancelModal, user.subscriptionIsCanceling]
  )

  const confirmCancelation = useCallback(() => removeSubscription(), [removeSubscription])

  const onDefaultClick = useCallback(
    id => {
      makeDefault(id)
    },
    [makeDefault]
  )

  const onRemoveClick = useCallback(
    id => {
      removeSelectedCard(id)
    },
    [removeSelectedCard]
  )

  const onAddNewCard = useCallback(card => createCard(card), [createCard])

  const onMakeDefaultSuccess = useCallback(() => {
    toast(<Toastr content="Card successfully set as default" />)
  }, [])

  useFetchCall(MAKE_DEFAULT, onMakeDefaultSuccess)

  let subscriptionDetailLine = ''
  const subscriptionStart = new Date(user.subscriptionStart)
  const subscriptionEnd =
    user.subscriptionPlanPeriod === 'yearly'
      ? subscriptionStart.setFullYear(subscriptionStart.getFullYear() + 1)
      : subscriptionStart.setMonth(subscriptionStart.getMonth() + 1)
  const subscriptionEndText = moment(subscriptionEnd).format('llll [GMT] Z')
  const subscriptionCurrentPeriodEndText = moment(user.subscriptionCurrentPeriodEnd).format(
    'llll [GMT] Z'
  )

  if (user.subscriptionIsCanceling) {
    subscriptionDetailLine = `Your subscription is currently canceled and will expire on ${subscriptionCurrentPeriodEndText} - Your local date and time`
  } else if (user.subscriptionPlanPeriod === 'yearly') {
    subscriptionDetailLine = `Your subscription is yearly, and will renew on ${subscriptionEndText} - Your local date and time`
  } else if (user.subscriptionPlanPeriod === 'monthly') {
    subscriptionDetailLine = `Your subscription is monthly, and will renew on ${subscriptionEndText} - Your local date and time`
  } else {
    subscriptionDetailLine = `You are on a trial subscription, which ends on ${subscriptionCurrentPeriodEndText} - Your local date and time`
  }

  return (
    <>
      <Card className={styles.card} size={CardSize.LARGE}>
        <h2>Payment Settings</h2>
        <div className={styles.container}>
          <div className={styles.cards}>
            {cards.length !== 0 &&
              cards.map(card => (
                <CreditCard
                  key={card.id}
                  cardId={card.id}
                  expirationDate={`${card.expMonth}/${card.expYear}`}
                  cardNumber={`XXXX XXXX XXXX ${card.last4}`}
                  isDefault={card.default}
                  brandIcon={card.brand}
                  onDefaultClick={onDefaultClick}
                  onRemoveClick={onRemoveClick}
                />
              ))}
            <div className={styles['add-card-wrapper']}>
              <button type="button" className={styles['add-new-credit-card']} onClick={toggleModal}>
                <svg className={styles['add-icon']} viewBox={AddIcon.viewBox} role="img">
                  <use xlinkHref={`#${AddIcon.id}`} />
                </svg>
                <p className={styles['add-card-text']}>ADD NEW CARD</p>
              </button>
            </div>
          </div>
          <div className={styles.subscription}>
            <h3>Subscription Status</h3>
            {isActive ? (
              <p className={styles.text}>{subscriptionDetailLine}</p>
            ) : (
              <p className={styles.text}>
                You are currently not subscribed. To resubscribe, add a default credit card and
                restart your subscription.
              </p>
            )}
            <Button
              onClick={subscriptionClick}
              label={
                isActive && !user.subscriptionIsCanceling
                  ? 'Cancel subscription'
                  : 'Restart subscription'
              }
              theme={ButtonTheme.PRIMARY}
              className={styles.cancel}
              loading={isUpdatingSubscription}
              disabled={!cards.length}
            />
          </div>
          <div className={styles['purchased-hitory']}>
            <h3>Purchase history</h3>
            <PaymentHistory paymentHistory={user.payments.paymentHistory} />
          </div>
          {user.payments.next && (
            <Button
              label="Load more"
              theme={ButtonTheme.PRIMARY}
              className={styles.button}
              onClick={loadMore}
            />
          )}
        </div>
      </Card>
      {isModalOpen && (
        <Modal isOpen onClose={toggleModal}>
          <StripeProvider {...stripe}>
            <Elements>
              <AddNewCreditCard
                onClose={toggleModal}
                onAddNewCard={onAddNewCard}
                loading={isAddingNewCard}
              />
            </Elements>
          </StripeProvider>
        </Modal>
      )}
      {isCancelModalOpen && (
        <Modal isOpen onClose={toggleCancelModal}>
          <ConfirmationModal
            onClose={toggleCancelModal}
            onActionClick={confirmCancelation}
            loading={isUpdatingSubscription}
          />
        </Modal>
      )}
    </>
  )
}

BillingCard.propTypes = {
  user: userShape.isRequired,
  getPaymentHistory: PropTypes.func.isRequired,
  getCardsList: PropTypes.func.isRequired,
  removeSubscription: PropTypes.func.isRequired,
  renewSubscription: PropTypes.func.isRequired,
  createCard: PropTypes.func.isRequired,
  removeSelectedCard: PropTypes.func.isRequired,
  makeDefault: PropTypes.func.isRequired,
  cards: PropTypes.arrayOf(cardShape).isRequired,
  addNewCardError: ImmutablePropTypes.map,
  updatingSubscriptionError: ImmutablePropTypes.map,
  isAddingNewCard: PropTypes.bool,
  isUpdatingSubscription: PropTypes.bool,
}
BillingCard.defaultProps = {
  addNewCardError: new Map(),
  isAddingNewCard: false,
  updatingSubscriptionError: new Map(),
  isUpdatingSubscription: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BillingCard)
