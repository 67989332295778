import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch } from 'react-redux'
import { navigate } from '@reach/router'

import Button, { ButtonTheme } from '_components/button'
import Input from '_components/input'
import { RESET_PASSWORD, resetPassword } from '_modules/user/actions'
import useFetchCall from '_hooks/use-fetch-call'
import { validateEmail } from '_utils/helpers'

import styles from './styles.css'

const ForgotPassword = ({ className }) => {
  const dispatch = useDispatch()
  const [email, setEmail] = useState('')
  const [error, setError] = useState('')

  const onChangeInput = useCallback(event => {
    const { value } = event.target
    setEmail(value)
  }, [])

  const onResetPasswordSuccess = useCallback(() => {
    navigate(['email-sent'])
  }, [])

  const onSubmit = useCallback(
    event => {
      event.preventDefault()

      setError('')

      if (!validateEmail(email) || email === '') {
        setError('Please enter a valid email')
        return
      }

      dispatch(resetPassword({ email }))
    },
    [dispatch, email]
  )

  const [loading, resetPasswordError] = useFetchCall(RESET_PASSWORD, onResetPasswordSuccess)

  return (
    <form className={classnames(styles.container, className)} onSubmit={onSubmit}>
      <p className={styles.text}>
        Gee-dangit. Enter your email address and we&apos;ll send you an email to reset your
        password.
      </p>
      <Input
        disabled={loading}
        className={styles.input}
        label="Email address"
        name="email"
        type="email"
        onChange={onChangeInput}
        value={email}
        errorMessage={error || (resetPasswordError.size > 1 && resetPasswordError.first().get(0))}
      />
      <Button
        loading={loading}
        className={styles.button}
        theme={ButtonTheme.DEFAULT}
        label="REQUEST NEW PASSWORD"
        type="submit"
      />
    </form>
  )
}

ForgotPassword.propTypes = {
  className: PropTypes.string,
}

ForgotPassword.defaultProps = {
  className: '',
}

export default ForgotPassword
