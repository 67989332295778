import React, { useCallback, useEffect, useMemo, useReducer } from 'react'
import PropTypes from 'prop-types'

import Button, { ButtonTheme } from '_components/button'
import Card, { CardSize } from '_components/card'
import Input from '_components/input'
import { userShape } from '_utils/proptypes'
import { checkIfNameIsValid } from '_utils/helpers'

import { reducer, INITIAL_STATE, UPDATE_STATE } from './reducer'
import styles from './styles.css'

const PublicProfileCard = ({ user, onClick, loading }) => {
  const [{ name, username, company, title, location, errorMessage }, localDispatch] = useReducer(
    reducer,
    INITIAL_STATE
  )

  const initialState = useMemo(
    () => ({
      username: user.username || '',
      name: user.name || '',
      company: user.company || '',
      title: user.title || '',
      location: user.location || '',
      errorMessage: '',
    }),
    [user.company, user.location, user.name, user.title, user.username]
  )

  const onInputChange = useCallback(event => {
    const { name: inputName, value } = event.target
    const payload = {
      [inputName]: value,
    }

    localDispatch({
      type: UPDATE_STATE,
      payload,
    })
  }, [])

  const onNameError = useCallback(payload => {
    localDispatch({
      type: UPDATE_STATE,
      payload,
    })
  }, [])

  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      const isNameValid = checkIfNameIsValid(name, onNameError)
      if (isNameValid) {
        onClick({
          username,
          name,
          company,
          title,
          location,
        })
      }
    },
    [company, location, name, onClick, onNameError, title, username]
  )

  useEffect(
    () => {
      localDispatch({
        type: UPDATE_STATE,
        payload: initialState,
      })
    },
    [initialState]
  )

  return (
    <Card className={styles.card} size={CardSize.LARGE}>
      <h2>Public profile</h2>
      <form onSubmit={onSubmit} className={styles['form-container']}>
        <Input
          onChange={onInputChange}
          value={name}
          name="name"
          label="Name"
          errorMessage={errorMessage}
          labelClassName={styles['label-title']}
          disabled={loading}
        />
        <Input
          onChange={onInputChange}
          value={username}
          name="username"
          label="Username"
          errorMessage={errorMessage}
          labelClassName={styles['label-title']}
          disabled={loading}
        />
        <Input
          onChange={onInputChange}
          value={company}
          name="company"
          label="Company"
          labelClassName={styles['label-title']}
          disabled={loading}
        />
        <Input
          onChange={onInputChange}
          value={title}
          name="title"
          label="Title"
          labelClassName={styles['label-title']}
          disabled={loading}
        />
        <Input
          onChange={onInputChange}
          value={location}
          name="location"
          label="Location"
          labelClassName={styles['label-title']}
          disabled={loading}
        />
        <Button
          label="Save changes"
          theme={ButtonTheme.PRIMARY}
          className={styles.button}
          type="submit"
          loading={loading}
        />
      </form>
    </Card>
  )
}

PublicProfileCard.propTypes = {
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  user: userShape.isRequired,
}

PublicProfileCard.defaultProps = {
  loading: false,
}

export default PublicProfileCard
