import * as postService from '_services/post'
import { defineAction } from '_utils/redux'

export default infix => {
  const GET_POSTS = defineAction(`GET_${infix}_POSTS`)
  const GET_FOLLOWING_POSTS = defineAction(`GET_${infix}_FOLLOWING_POSTS`)
  const CREATE_POST = defineAction(`CREATE_${infix}_POST`)
  const EDIT_POST = defineAction(`EDIT_${infix}_POST`)
  const SHAZAM_POST = defineAction(`SHAZAM_${infix}_POST`)
  const UNSHAZAM_POST = defineAction(`UNSHAZAM_${infix}_POST`)
  const DELETE_POST = defineAction(`DELETE_${infix}_POST`)
  const FLAG_POST = defineAction(`FLAG_${infix}_POST`)

  return {
    GET_POSTS,
    getPosts: (payload = {}) => (dispatch, getState) => {
      dispatch({
        type: GET_POSTS.ACTION,
        payload: postService.getPosts(getState().user.authToken)(payload),
        meta: {
          isNextPage: payload.page,
        },
      })
    },
    GET_FOLLOWING_POSTS,
    getFollowedPosts: payload => (dispatch, getState) => {
      dispatch({
        type: GET_FOLLOWING_POSTS.ACTION,
        payload: postService.getPosts(getState().user.authToken)(payload),
        meta: {
          isNextPage: payload.page,
        },
      })
    },
    CREATE_POST,
    createPost: payload => (dispatch, getState) => {
      dispatch({
        type: CREATE_POST.ACTION,
        payload: postService.createPost(getState().user.authToken)(payload),
        meta: {
          fatherPostId: payload.fatherPost,
          assignmentId: payload.assignment,
          classId: payload.classId,
        },
      })
    },
    EDIT_POST,
    editPost: ({ classId, assignmentId, postId, ...payload }) => (dispatch, getState) => {
      dispatch({
        type: EDIT_POST.ACTION,
        payload: postService.editPost(getState().user.authToken)(payload, postId),
        meta: {
          classId,
          assignmentId,
          postId,
        },
      })
    },
    SHAZAM_POST,
    shazam: ({ postId, ...rest }) => (dispatch, getState) => {
      dispatch({
        type: SHAZAM_POST.ACTION,
        payload: postService.shazam(getState().user.authToken)(postId),
        meta: {
          postId,
          ...rest,
        },
      })
    },
    UNSHAZAM_POST,
    unshazam: ({ postId, ...rest }) => (dispatch, getState) => {
      dispatch({
        type: UNSHAZAM_POST.ACTION,
        payload: postService.unShazam(getState().user.authToken)(postId),
        meta: {
          postId,
          ...rest,
        },
      })
    },
    DELETE_POST,
    deletePost: ({ postId, classId, assignmentId }) => (dispatch, getState) => {
      dispatch({
        type: DELETE_POST.ACTION,
        payload: postService.deletePost(getState().user.authToken)(postId),
        meta: {
          postId,
          classId,
          assignmentId,
        },
      })
    },
    FLAG_POST,
    flagPost: postId => (dispatch, getState) => {
      dispatch({
        type: FLAG_POST.ACTION,
        payload: postService.flagPost(getState().user.authToken)(postId),
      })
    },
  }
}
