import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

const ModalFooter = ({ loading, buttonLabel, className }) => (
  <div className={classnames(styles['button-wrapper'], className)}>
    <Button
      loading={loading}
      className={styles.button}
      theme={ButtonTheme.PRIMARY}
      label={buttonLabel}
      type="submit"
    />
  </div>
)
ModalFooter.propTypes = {
  buttonLabel: PropTypes.string,
  loading: PropTypes.bool,
  className: PropTypes.string,
}

ModalFooter.defaultProps = {
  buttonLabel: '',
  loading: false,
  className: '',
}

export default ModalFooter
