import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Checkbox from '_components/checkbox'

import styles from './styles.css'

export const CHECKBOX_GROUP_VARIANT = {
  DEFAULT: 'default',
  DOUBLE_COLUMNS: 'double-columns',
  COLUMNS: 'columns',
}

const CheckboxGroup = ({
  id,
  optionsList,
  onChange,
  className,
  legend,
  legendClassName,
  variant,
}) => (
  <fieldset className={classnames(styles.fieldset, className)} onChange={onChange}>
    <legend className={classnames(styles.legend, legendClassName)}>{legend}</legend>
    <div className={styles[variant]}>
      {optionsList?.map((option, index) => (
        <Checkbox
          key={`checkbox-${id}-${index + 1}`}
          id={`checkbox-${id}-${index}`}
          name={option.name}
          value={option.name}
          label={option.name}
          checked={option.checked}
          {...option}
        />
      ))}
    </div>
  </fieldset>
)
CheckboxGroup.propTypes = {
  id: PropTypes.string.isRequired,
  className: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  legend: PropTypes.string.isRequired,
  legendClassName: PropTypes.string,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      checked: PropTypes.bool,
    })
  ).isRequired,
  variant: PropTypes.oneOf(Object.values(CHECKBOX_GROUP_VARIANT)),
}

CheckboxGroup.defaultProps = {
  className: '',
  legendClassName: '',
  variant: CHECKBOX_GROUP_VARIANT.DEFAULT,
}

export default CheckboxGroup
