import { defineAction } from '_utils/redux'
import * as prismicServices from '_services/prismic'

export const GET_PRISMIC_INFORMATION = defineAction('GET_PRISMIC_INFORMATION')
export const GET_CLASSES_LIST_SLICE_POSITION = defineAction('GET_CLASSES_LIST_SLICE_POSITION')
export const DISMISS_HIGHLIGHT_MESSAGE = defineAction('DISMISS_HIGHLIGHT_MESSAGE')

export const getPrismicInformation = payload => dispatch =>
  dispatch({
    type: GET_PRISMIC_INFORMATION,
    payload: prismicServices.getPrismicInformation()(payload),
    meta: {
      section: payload,
    },
  })

export const classesListSlicePosition = payload => dispatch =>
  dispatch({
    type: GET_CLASSES_LIST_SLICE_POSITION.ACTION,
    payload,
  })

export const dismissHighlightMessage = payload => dispatch =>
  dispatch({
    type: DISMISS_HIGHLIGHT_MESSAGE.ACTION,
    payload,
  })
