import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { RoleType } from '_utils/constants'

import styles from './styles.css'

export const RoleStickerSize = {
  EXTRA_SMALL: 'extra-small',
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

const getRole = type => {
  switch (type) {
    case RoleType.ADMIN:
      return 'A'
    case RoleType.GUIDER:
      return 'G'
    case RoleType.LEADER:
      return 'L'
    case RoleType.SUSPENDED:
      return 'S'
    default:
      return 'E'
  }
}

const RoleSticker = ({ className, size, type, isResponsive }) => (
  <div
    className={classnames(styles.sticker, styles[size], styles[type.toLowerCase()], className, {
      [styles.responsive]: isResponsive,
    })}
  >
    {getRole(type)}
  </div>
)

RoleSticker.propTypes = {
  className: PropTypes.string,
  size: PropTypes.string,
  type: PropTypes.string,
  isResponsive: PropTypes.bool,
}

RoleSticker.defaultProps = {
  className: '',
  size: RoleStickerSize.SMALL,
  type: RoleType.EXPLORER,
  isResponsive: false,
}

export default RoleSticker
