import React, { useCallback, useMemo, useEffect, useState } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import moment from 'moment'

import BlockedDashboard from '_components/join-lightyear-community'
import POST_ACTIONS from '_modules/post/actions'
import CreatePost from '_components/create-post'
import UpcomingGoals from '_components/upcoming-goals'
import AnnouncementsCard from '_components/announcements-card'
import LiveCallBanner from '_components/live-call-banner'
import Footer from '_components/footer'
import { getAnnouncements } from '_modules/dashboard/actions'
import { announcementsSelector, dashboardSelector } from '_modules/dashboard/selectors'
import { getClassesNew } from '_modules/class/actions'
import { myClassesSelector, getClassesNewLoadingSelector } from '_modules/class/selectors'
import { getAvailableClassesListSelector } from '_modules/public-classes/selectors'
import { getAvailableClasses } from '_modules/public-classes/actions'
import { checkSubscriptionExpired } from '_utils/subscription'
import { formatUrl } from '_utils/helpers'
import { getUserSelector } from '_modules/user/selectors'
import OngoingCourses from '_components/ongoing-courses'
import UpcomingClassesScrollList from '_components/upcoming-classes-scroll-list'
import usePrevious from '_hooks/use-previous'

import styles from './styles.css'
import TimelineSection from './timeline-section'

const { createPost, editPost } = POST_ACTIONS

const Dashboard = () => {
  const dashboard = useSelector(dashboardSelector)
  const user = useSelector(getUserSelector)
  const username = user.get('username')
  const announcementsList = useSelector(announcementsSelector)
  const upcomingCall = dashboard?.summary?.upcomingCalls?.[0]
  const upcomingMeeting = upcomingCall?.meeting
  const isSubscriptionExpired = checkSubscriptionExpired(user)
  const yourOngoingCourses = useSelector(myClassesSelector)
  const isGettingOngoingClasses = useSelector(getClassesNewLoadingSelector)
  const wasGettingOngoingClasses = usePrevious(isGettingOngoingClasses)
  const upcomingClasses = useSelector(getAvailableClassesListSelector)

  const dispatch = useDispatch()

  const [isUpcomingClassesSection, setUpcomingClassesSection] = useState(false)

  useEffect(() => {
    if (!isSubscriptionExpired) {
      dispatch(getAnnouncements())
    }
    dispatch(getClassesNew({ status: 'ongoing' }))
    // eslint-disable-next-line
  }, [])

  useEffect(
    () => {
      if (wasGettingOngoingClasses && !isGettingOngoingClasses && !yourOngoingCourses.size) {
        setUpcomingClassesSection(true)
        dispatch(getAvailableClasses())
      }
    },
    [dispatch, isGettingOngoingClasses, wasGettingOngoingClasses, yourOngoingCourses.size]
  )

  const callTimeLeft = useMemo(
    () => {
      const upcomingCallTime = upcomingMeeting?.startTime

      if (!upcomingCallTime) {
        return 0
      }

      const now = moment()
      const momentCallTime = moment(upcomingCallTime)
      const timeDifference = moment.duration(momentCallTime.diff(now)).asMinutes()

      return timeDifference
    },
    [upcomingMeeting]
  )

  const showLiveCallBanner = useMemo(
    () => {
      if (!upcomingCall) {
        return false
      }
      const isClassLeader = upcomingCall.leaderId === user.id

      if (isClassLeader) {
        return !!callTimeLeft && callTimeLeft < 11 && !upcomingMeeting?.ended
      }
      return upcomingMeeting?.started
    },
    [callTimeLeft, upcomingCall, upcomingMeeting, user.id]
  )

  const onCreatePost = useCallback(payload => dispatch(createPost(payload)), [dispatch])

  const onEditPost = useCallback(
    (payload, postId) => {
      dispatch(editPost({ ...payload, postId, classId: null, assignmentId: null }))
    },
    [dispatch]
  )

  const renderClasses = useMemo(
    () => {
      if (isUpcomingClassesSection) {
        return (
          <div className={styles['upcoming-classes']}>
            <h1 className={styles['upcoming-classes-title']}>EXPLORE UPCOMING CLASSES</h1>
            <UpcomingClassesScrollList
              classesList={upcomingClasses}
              className={styles['upcoming-classes-list']}
              defaultArrows
            />
          </div>
        )
      }
      return <OngoingCourses />
    },
    [isUpcomingClassesSection, upcomingClasses]
  )

  const renderCreatePost = useMemo(
    () => <CreatePost className={styles['create-post']} onPostClick={onCreatePost} />,
    [onCreatePost]
  )

  return (
    <div className={styles['dashboard-container']}>
      <div className={styles['mobile-wrapper']}>
        <div className={styles['live-call-wrapper']}>
          {showLiveCallBanner && (
            <LiveCallBanner
              className={styles.banner}
              callTime={callTimeLeft}
              callLink={formatUrl(upcomingMeeting?.joinUrl)}
              classStarted={upcomingMeeting?.callStarted || upcomingMeeting?.started}
              call={upcomingMeeting}
              classId={upcomingCall.classId}
              leaderId={upcomingCall.leaderId}
            />
          )}
          {renderClasses}
        </div>
        {renderCreatePost}
      </div>
      <div
        className={classnames(styles.wrapper, {
          [styles['blocked-dashboard-wrapper']]: isSubscriptionExpired,
        })}
      >
        <div className={styles['course-overview']}>
          {showLiveCallBanner && (
            <LiveCallBanner
              className={styles.banner}
              callTime={callTimeLeft}
              callLink={upcomingMeeting?.joinUrl}
              classStarted={upcomingMeeting?.callStarted || upcomingMeeting?.started}
              call={upcomingMeeting}
              classId={upcomingCall.classId}
              leaderId={upcomingCall.leaderId}
            />
          )}
          {renderClasses}
        </div>
        {isSubscriptionExpired ? (
          <BlockedDashboard
            className={styles['blocked-dashboard']}
            title="YOUR FEED"
            heading="THE LIGHTYEAR COMMUNITY IS HERE!"
            description="Access exclusive content, support fellow movers and shakers, and stay motivated towards your vision and goals."
          />
        ) : (
          <>
            {renderCreatePost}
            <div className={styles['right-column']}>
              {announcementsList?.map(announcement => (
                <AnnouncementsCard key={announcement.get('id')} announcement={announcement} />
              ))}
              <UpcomingGoals
                className={styles['upcoming-goals']}
                goals={dashboard.upcomingGoals}
                username={username}
              />
            </div>
            <TimelineSection
              className={styles['timeline-section']}
              onEditPost={onEditPost}
              onCreatePost={onCreatePost}
            />
          </>
        )}
      </div>

      <Footer className={styles.footer} />
    </div>
  )
}

export default React.memo(Dashboard)
