import React from 'react'
import Proptypes from 'prop-types'
import classnames from 'classnames'

import RocketLoaderGif from '_assets/gifs/rocket.gif'

import styles from './styles.css'

const RocketLoader = ({ className, text }) => (
  <div className={classnames(styles.container, className)}>
    <img src={RocketLoaderGif} alt="Loader" className={styles.loader} />
    <p className={styles['loading-message']}>{text}</p>
  </div>
)

RocketLoader.propTypes = {
  className: Proptypes.string,
  text: Proptypes.string,
}

RocketLoader.defaultProps = {
  className: '',
  text: 'Fetching your future from space!',
}

export default React.memo(RocketLoader)
