import React, { useMemo } from 'react'
import { useSelector } from 'react-redux'
import classnames from 'classnames'

import CoursesIcon from '_assets/icons/menu-courses.svg'
import { myClassesSelector, getClassesNewLoadingSelector } from '_modules/class/selectors'
import Button, { ButtonTheme } from '_components/button'
import RocketLoader from '_components/rocket-loader'

import ClassCard from './class-card'
import styles from './styles.css'

const OngoingCourses = () => {
  const classes = useSelector(myClassesSelector)
  const isGettingClasses = useSelector(getClassesNewLoadingSelector)

  const displayedClasses = useMemo(() => classes?.valueSeq()?.slice(0, 2), [classes])

  const renderContent = useMemo(
    () => {
      if (displayedClasses.size) {
        return displayedClasses.map(myClass => <ClassCard course={myClass} key={myClass.id} />)
      }

      return (
        <div className={styles['empty-container']}>
          <svg className={styles['courses-icon']} viewBox={CoursesIcon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${CoursesIcon.id}`} />
          </svg>
          <p className={styles.description}>You have no classes in progress.</p>
          <Button label="EXPLORE CLASSES" className={styles['explore-courses']} to="/courses" />
        </div>
      )
    },
    [displayedClasses]
  )

  return (
    <section
      className={classnames(styles.wrapper, {
        [styles['single-assignment-wrapper']]: displayedClasses.size === 1,
      })}
    >
      <div className={styles.top}>
        <h1 className={styles.title}>YOUR ONGOING CLASSES</h1>
        <Button
          theme={ButtonTheme.TRANSPARENT_BACKGROUND}
          label="SEE ALL"
          className={styles['see-all']}
          to="/courses"
        />
      </div>
      {isGettingClasses ? <RocketLoader className={styles.loader} /> : renderContent}
    </section>
  )
}

export default React.memo(OngoingCourses)
