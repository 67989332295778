import PeopleIcon from '_assets/icons/people-icon.svg'
import PersonIcon from '_assets/icons/person-icon.svg'

export const CLASS_TYPE = {
  COACH_LED: {
    name: 'Coach-led',
    slug: 'COACH_LED',
    icon: PersonIcon,
  },
  SELF_PACED: {
    name: 'Self-paced',
    slug: 'SELF_PACED',
    icon: PersonIcon,
  },
  COMMUNITY_LED: {
    name: 'Community-led',
    slug: 'COMMUNITY_LED',
    icon: PeopleIcon,
  },
}
