import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { userShape } from '_utils/proptypes'

import PersonCard from '../person-card'

import styles from './styles.css'

const FollowingEmptyState = ({ className, peopleYouMayKnow, onFollowUser, isFollowing }) => (
  <div className={classnames(styles.container, className)}>
    <h2>PEOPLE YOU MAY KNOW</h2>
    <ul className={styles.list}>
      {peopleYouMayKnow.map(person => (
        <li key={person.id}>
          <PersonCard
            username={person.username}
            photo={person.photo}
            name={person.name}
            company={person.company}
            onFollowClick={onFollowUser}
            isFollowing={isFollowing}
          />
        </li>
      ))}
    </ul>
  </div>
)

FollowingEmptyState.propTypes = {
  className: PropTypes.string,
  peopleYouMayKnow: PropTypes.arrayOf(userShape).isRequired,
  onFollowUser: PropTypes.func,
  isFollowing: PropTypes.bool,
}

FollowingEmptyState.defaultProps = {
  className: '',
  onFollowUser: () => {},
  isFollowing: false,
}

export default FollowingEmptyState
