import { List } from 'immutable'

import Model from './model'

class Course extends Model({
  id: undefined,
  name: undefined,
  picture: undefined,
  slug: undefined,
  rank: undefined,
  duration: undefined,
  creator: undefined,
  categories: List(),
  createdAt: undefined,
  updatedAt: undefined,
  classes: List(),
  sections: List(),
  favorite: undefined,
  peopleEnrolled: List(),
  description: undefined,
  company: undefined,
  isBothCompanyAndCategory: undefined,
}) {}

export { Course as default }
