import { createSelector } from 'reselect'
import { Map } from 'immutable'

import PROFILE_ACTIONS from './actions'

const getProfilePosts = profile => profile.postsDetails.get('posts')
const getParam = (profile, param) => param

export const getSelectedPost = createSelector([getProfilePosts, getParam], (posts, postId) => {
  const selectedPost = posts.find(post => post.get('id') === postId)

  return selectedPost ? selectedPost.toJS() : null
})

export const isUpdateGoalLoadingSelector = state =>
  !!state.loading.get(PROFILE_ACTIONS.UPDATE_GOAL.ACTION)

export const updateGoalErrorSelector = state =>
  state.error.get(PROFILE_ACTIONS.UPDATE_GOAL.ACTION, Map())
