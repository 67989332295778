import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'

import Testimonial from '_components/landing-components/testimonial'
import Button, { ButtonTheme } from '_components/landing-components/button'
import GetInTouchModal from '_components/landing-components/get-in-touch-modal'

import styles from './styles.css'

const CompaniesSection = ({ slice, id }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const handleToggleModal = useCallback(() => {
    setModalOpen(prevState => !prevState)
  }, [])

  return (
    <section className={styles.section} id={id}>
      <div className={styles.content}>
        <div className={styles['testimonials-grid']}>
          {slice.items.map(testimonial => (
            <Testimonial key={`testimonial-${testimonial.testimonial}`} testimonial={testimonial} />
          ))}
        </div>
        <Button
          theme={ButtonTheme.MALIBU}
          onClick={handleToggleModal}
          className={styles['for-your-business-button']}
        >
          For your business
        </Button>
        {isModalOpen && <GetInTouchModal isOpen onClose={handleToggleModal} />}
      </div>
    </section>
  )
}

CompaniesSection.propTypes = {
  slice: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({
        testimonial: PropTypes.string,
        userAvatar: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        }),
        userName: PropTypes.string,
        userJob: PropTypes.string,
      })
    ),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(CompaniesSection)
