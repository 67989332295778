import React, { useReducer, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements'
import { toast } from 'react-toastify'

import CloseIcon from '_assets/icons/close.svg'
import Button, { ButtonTheme } from '_components/button'
import CreditCard from '_components/credit-card'
import Input from '_components/input'
import CreditCardIcons from '_assets/icons/creditcardempty-96-px.svg'
import ErrorTooltip from '_components/error-tooltip'
import { cardShape } from '_utils/proptypes'
import { StripeOptions } from '_utils/constants'
import useFetchCall from '_hooks/use-fetch-call'
import Toastr from '_components/toastr'
import { MAKE_DEFAULT } from '_modules/card/actions'

import styles from './styles.css'

const initialState = {
  name: '',
  cardNumber: '',
  cardExpiry: '',
  cardCvc: '',
  errors: {
    fullName: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  },
}

const INPUT_CHANGE = 'INPUT_CHANGE'
const RESET = 'RESET'

const reducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE: {
      return {
        ...state,
        [action.name]: action.value,
        errors: {
          ...state.errors,
          [action.name]: action.error,
        },
      }
    }
    case RESET: {
      return initialState
    }
    default:
      return state
  }
}

const EnrollCourse = ({
  isFormOpen,
  cards,
  onAddNewCard,
  toggleAddNewCard,
  onClose,
  onCardClick,
  onRemoveCard,
  onDefaultCard,
  stripe,
}) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const onInputChange = useCallback(event => {
    const { name, value } = event.target
    let error = ''

    if (name === 'name') {
      error = /\d/.test(value) ? 'Enter a valid card holder name.' : ''
    }

    dispatch({
      type: INPUT_CHANGE,
      name,
      value,
      error,
    })
  }, [])

  const onStripeElementChange = useCallback(event => {
    const { elementType, error, value } = event

    dispatch({
      type: INPUT_CHANGE,
      name: elementType,
      value,
      error: error ? error.message : '',
    })
  }, [])

  const onDefaultClick = useCallback(id => onDefaultCard(id), [onDefaultCard])

  const onRemoveClick = useCallback(id => onRemoveCard(id), [onRemoveCard])

  const onAddNewCardClick = useCallback(
    event => {
      event.preventDefault()
      const { errors, ...card } = state
      stripe.createToken(card).then(({ token }) => {
        onAddNewCard({ cardToken: token.id })
      })
      dispatch({
        type: RESET,
      })
    },
    [onAddNewCard, state, stripe]
  )

  const isFormDisabled = useMemo(
    () => {
      const { errors } = state
      return Object.values(errors).some(error => error.length > 0)
    },
    [state]
  )

  const onMakeDefaultSuccess = useCallback(() => {
    toast(<Toastr content="Card successfully set as default" />)
  }, [])

  useFetchCall(MAKE_DEFAULT, onMakeDefaultSuccess)

  return (
    <>
      <div
        className={classnames(styles.header, {
          [styles['add-new-card']]: isFormOpen,
        })}
      >
        <h2 className={styles.title}>ENROLL IN THIS COURSE</h2>
        <button type="button" onClick={onClose} className={styles['close-action']}>
          <svg
            className={styles.close}
            aria-label="Close icon"
            role="img"
            viewBox={CloseIcon.viewBox}
          >
            <use xlinkHref={`#${CloseIcon.id}`} />
          </svg>
        </button>
      </div>
      <p
        className={classnames(styles.information, {
          [styles['add-new-card']]: isFormOpen,
        })}
      >
        To get started, select your preferred payment method or add a new one.
      </p>
      <div
        className={classnames(styles.cards, {
          [styles['add-new-card']]: isFormOpen,
        })}
      >
        {cards.length ? (
          cards.map(card => (
            <CreditCard
              key={card.id}
              cardId={card.id}
              cardNumber={`XXXX XXXX XXXX ${card.last4}`}
              expirationDate={`${card.expMonth}/${card.expYear}`}
              isDefault={card.default}
              brandIcon={card.brand}
              onDefaultClick={onDefaultClick}
              onRemoveClick={onRemoveClick}
              onCardClick={onCardClick}
            />
          ))
        ) : (
          <div className={styles['empty-card']}>
            <svg aria-label="Credit Card Image" viewBox={CreditCardIcons.viewBox} role="img">
              <use xlinkHref={`#${CreditCardIcons.id}`} />
            </svg>
            <p> You have no credit card registered yet.</p>
          </div>
        )}
      </div>
      {isFormOpen && (
        <div className={styles['new-card-section']}>
          <h3>ADD A NEW PAYMENT METHOD</h3>
          <Input
            label="FULL NAME"
            name="name"
            onChange={onInputChange}
            value={state.name}
            errorMessage={state.errors.name}
          />
          <div className={styles['form-group']}>
            <span className={styles.label}>Card Number</span>
            <CardNumberElement
              className={classnames(styles['stripe-input'], {
                [styles.error]: state.errors.cardNumber,
              })}
              {...StripeOptions}
              onChange={onStripeElementChange}
              placeholder=""
            />
            {state.errors.cardNumber && (
              <ErrorTooltip className={styles['error-icon']} message={state.errors.cardNumber} />
            )}
          </div>
          <div className={styles['form-group-wrapper']}>
            <div className={styles['form-group']}>
              <span className={styles.label}>Card Expiration</span>
              <CardExpiryElement
                className={classnames(styles['stripe-input'], {
                  [styles.error]: state.errors.cardExpiry,
                })}
                {...StripeOptions}
                onChange={onStripeElementChange}
                placeholder=""
              />
              {state.errors.cardExpiry && (
                <ErrorTooltip className={styles['error-icon']} message={state.errors.cardExpiry} />
              )}
            </div>
            <div className={styles['form-group']}>
              <span className={styles.label}>Security Code</span>
              <CardCVCElement
                className={classnames(styles['stripe-input'], {
                  [styles.error]: state.errors.cardCvc,
                })}
                {...StripeOptions}
                onChange={onStripeElementChange}
                placeholder=""
              />
              {state.errors.cardCvc && (
                <ErrorTooltip className={styles['error-icon']} message={state.errors.cardCvc} />
              )}
            </div>
          </div>
        </div>
      )}
      <div className={styles.footer}>
        {isFormOpen ? (
          <div className={styles['align-right']}>
            <Button theme={ButtonTheme.SECONDARY} label="CANCEL" onClick={toggleAddNewCard} />
            <Button
              theme={ButtonTheme.PRIMARY}
              label="ADD NEW CARD"
              onClick={onAddNewCardClick}
              disabled={isFormDisabled}
            />
          </div>
        ) : (
          <Button
            theme={ButtonTheme.DEFAULT}
            label="+ Add new payment method"
            onClick={toggleAddNewCard}
          />
        )}
      </div>
    </>
  )
}

EnrollCourse.propTypes = {
  isFormOpen: PropTypes.bool,
  cards: PropTypes.arrayOf(cardShape).isRequired,
  onAddNewCard: PropTypes.func,
  toggleAddNewCard: PropTypes.func,
  onClose: PropTypes.func,
  onCardClick: PropTypes.func,
  onRemoveCard: PropTypes.func,
  onDefaultCard: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  stripe: PropTypes.object.isRequired /* stripe injected prop */,
}

EnrollCourse.defaultProps = {
  isFormOpen: false,
  onAddNewCard: () => {},
  toggleAddNewCard: () => {},
  onClose: () => {},
  onCardClick: () => {},
  onRemoveCard: () => {},
  onDefaultCard: () => {},
}

export default injectStripe(React.memo(EnrollCourse))
