import React, { useState, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { navigate } from '@reach/router'

import AddIcon from '_assets/icons/add.svg'
import StatusIcon from '_components/status-icon'
import Modal from '_components/modal'
import ParticipantsModal, { ParticipantModalTheme } from '_components/participants-modal'
import { onMouseDown, isKeyboardOrClick } from '_utils/aria'
import { directMessageShape, userShape } from '_utils/proptypes'
import DefaultAvatar from '_assets/icons/empty-photo.svg'

import styles from './styles.css'

const DirectMessages = ({
  isInline,
  messages,
  onDirectMessageClick,
  selectedUsername,
  getAvailableUsers,
  availableUsers,
  isLoadingUsersToChat,
  newPreDirectMessage,
  shouldRedirect,
  isLimited,
}) => {
  const [showModal, setShowModal] = useState(false)

  const toggleModal = useCallback(() => setShowModal(!showModal), [showModal])

  const onButtonClick = useCallback(
    event => {
      const { id: username, key } = event.currentTarget
      if (isKeyboardOrClick(key) && isInline) {
        navigate(`/messages?directMessage=${username}`)
        return
      }

      onDirectMessageClick(username)
    },
    [isInline, onDirectMessageClick]
  )

  const onLoadMore = useCallback(
    () => getAvailableUsers({ page: availableUsers.next, search: null }),
    [availableUsers.next, getAvailableUsers]
  )

  const onSubmitSearch = useCallback(
    search => {
      getAvailableUsers({ page: null, search })
    },
    [getAvailableUsers]
  )

  const onParticipantClick = useCallback(
    payload => {
      const hasDirectMessage = messages.some(chat => chat.user.id === payload.id)

      setShowModal(false)

      if (hasDirectMessage) {
        onDirectMessageClick(payload.username)
        return
      }

      // create a new chat on reducer
      newPreDirectMessage(payload)

      if (shouldRedirect) {
        navigate(`/messages/?directMessage=${payload.username}`)
      }
    },
    [messages, newPreDirectMessage, onDirectMessageClick, shouldRedirect]
  )

  useEffect(
    () => {
      if (showModal) {
        getAvailableUsers({ page: null, search: null })
      }
    },
    [getAvailableUsers, showModal]
  )

  return (
    <>
      <div
        className={classnames(styles.container, {
          [styles.inline]: isInline,
        })}
      >
        <div className={styles.section}>
          <p>DIRECT MESSAGES</p>
          <button
            type="button"
            onMouseDown={onMouseDown}
            className={styles.action}
            onClick={toggleModal}
          >
            <svg aria-label="Create a new message" role="img" viewBox={AddIcon.viewBox}>
              <use xlinkHref={`#${AddIcon.id}`} />
            </svg>
          </button>
        </div>
        <ul
          className={classnames({
            [styles['inline-list']]: isInline,
            [styles['block-list']]: !isInline,
          })}
        >
          {messages.map(message => (
            <li
              key={message.user.id}
              className={classnames(styles.item, {
                [styles.active]: message.user.username === selectedUsername && !isInline,
              })}
            >
              {isInline ? (
                <div
                  role="button"
                  tabIndex="0"
                  onClick={onButtonClick}
                  onKeyPress={onButtonClick}
                  onMouseDown={onMouseDown}
                  id={message.user.username}
                >
                  <StatusIcon className={styles['status-icon']} isOnline={message.user.online} />
                  <p
                    className={classnames({
                      [styles.unread]: message.user.username !== selectedUsername && !message.read,
                    })}
                  >
                    {message.user.name}
                  </p>
                </div>
              ) : (
                <div
                  role="button"
                  tabIndex="0"
                  onClick={onButtonClick}
                  onKeyPress={onButtonClick}
                  onMouseDown={onMouseDown}
                  id={message.user.username}
                >
                  <div className={styles['avatar-wrapper']}>
                    {message.user.photo ? (
                      <img
                        src={message.user.photo}
                        alt={`${message.user.name} avatar`}
                        className={styles.avatar}
                      />
                    ) : (
                      <svg
                        viewBox={DefaultAvatar.viewBox}
                        aria-label="User avatar"
                        role="img"
                        className={styles.avatar}
                      >
                        <use xlinkHref={`#${DefaultAvatar.id}`} />
                      </svg>
                    )}
                    {message.user.online && (
                      <StatusIcon
                        className={styles['status-icon']}
                        isOnline={message.user.online}
                      />
                    )}
                  </div>
                  <div className={styles.profile}>
                    <p
                      className={classnames(styles.name, {
                        [styles.unread]:
                          message.user.username !== selectedUsername && !message.read,
                      })}
                    >
                      {message.user.name}
                    </p>
                    {message.lastMessage.createdAt && (
                      <p className={styles.date}>
                        {moment(message.lastMessage.createdAt).fromNow(true)}
                      </p>
                    )}
                  </div>
                  <p className={styles.message}>
                    {message.lastMessage.filename ||
                      message.lastMessage.message ||
                      (message.lastMessage.meeting &&
                        `${message.lastMessage.meeting.topic} video call`)}
                  </p>
                </div>
              )}
            </li>
          ))}
        </ul>
      </div>
      {showModal && (
        <Modal isOpen onClose={toggleModal} className={styles['messages-modal']}>
          <ParticipantsModal
            members={availableUsers.users}
            onClose={toggleModal}
            theme={ParticipantModalTheme.NEW_DIRECT_MESSAGE}
            hasMore={!!availableUsers.next}
            onLoadMore={onLoadMore}
            onSubmit={onSubmitSearch}
            isLoading={isLoadingUsersToChat}
            onParticipantClick={onParticipantClick}
          />
        </Modal>
      )}
    </>
  )
}

DirectMessages.propTypes = {
  isInline: PropTypes.bool,
  messages: PropTypes.arrayOf(directMessageShape).isRequired,
  onDirectMessageClick: PropTypes.func,
  selectedUsername: PropTypes.string,
  getAvailableUsers: PropTypes.func,
  availableUsers: PropTypes.shape({
    count: PropTypes.number,
    next: PropTypes.number,
    previous: PropTypes.number,
    users: PropTypes.arrayOf(userShape),
  }).isRequired,
  isLoadingUsersToChat: PropTypes.bool,
  newPreDirectMessage: PropTypes.func,
  shouldRedirect: PropTypes.bool,
  isLimited: PropTypes.bool,
}

DirectMessages.defaultProps = {
  isInline: false,
  onDirectMessageClick: () => {},
  selectedUsername: null,
  getAvailableUsers: () => {},
  isLoadingUsersToChat: false,
  newPreDirectMessage: () => {},
  shouldRedirect: false,
  isLimited: false,
}

export default React.memo(DirectMessages)
