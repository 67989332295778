import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { prismicTitlePropType } from '_utils/proptypes'
import { TEXT_TYPE } from '_constants/prismic'

import styles from './styles.css'

const SectionTitle = ({ title, className, isSmall, isCustom, style, boldClassName }) => (
  <h1
    className={classnames(
      styles.title,
      {
        [styles.small]: isSmall,
        [styles.custom]: isCustom,
      },
      className
    )}
    style={style}
  >
    {title.map((text, index) => (
      // Using index as key since the arrays aren't going to change
      <Fragment key={`title-${index + 1}`}>
        {text.map((slice, spanIndex) =>
          slice.map(({ text: textValue, type }) => {
            if (type === TEXT_TYPE.STRONG) {
              return (
                <span
                  key={`span-${textValue}-${spanIndex + 1}`}
                  className={classnames(styles['bold-text'], boldClassName)}
                >
                  {textValue}
                </span>
              )
            }
            return textValue
          })
        )}
        <br />
      </Fragment>
    ))}
  </h1>
)

SectionTitle.propTypes = {
  title: prismicTitlePropType.isRequired,
  className: PropTypes.string,
  isSmall: PropTypes.bool,
  isCustom: PropTypes.bool,
  style: PropTypes.shape(),
  boldClassName: PropTypes.string,
}

SectionTitle.defaultProps = {
  className: '',
  isSmall: false,
  isCustom: false,
  style: {},
  boldClassName: '',
}
export default React.memo(SectionTitle)
