export const INITIAL_STATE = {
  name: '',
  username: '',
  picture: { blob: null, file: null },
  errors: {
    name: '',
    username: '',
  },
}

export const UPDATE_STATE = 'UPDATE_STATE'
export const RESET = 'RESET'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE: {
      return {
        ...state,
        ...action.payload,
      }
    }
    case RESET: {
      return INITIAL_STATE
    }
    default:
      return state
  }
}
