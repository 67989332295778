import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Svg from '_components/svg'
import SelfPacedAstronautIcon from '_assets/icons/self-paced-astronaut.svg'

import styles from './styles.css'

const SelfPacedCard = ({ className }) => (
    <article className={classnames(styles['self-paced-card-container'], className)}>
      <Svg className={styles['self-paced-icon']} icon={SelfPacedAstronautIcon} />

      <h1 className={styles['self-paced-title']}>Self-paced</h1>
      <p className={styles['self-paced-description']}>
        Navigate the sessions at your own pace, completing and checking things off as you go. In
        addition you will also have access to complimentary light hours, video chats with Susanne
        and faculty members, to clarify any questions you may have.
      </p>
    </article>
  )

SelfPacedCard.propTypes = {
  className: PropTypes.string,
}

SelfPacedCard.defaultProps = {
  className: '',
}

export default React.memo(SelfPacedCard)
