import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { navigate } from '@reach/router'
import { Map } from 'immutable'
import { toast } from 'react-toastify'

import { LOGIN, SIGN_UP, FACEBOOK_LOGIN, GOOGLE_LOGIN, login, signUp } from '_modules/user/actions'
import { cleanAuthErrors } from '_modules/error/actions'
import { userShape } from '_utils/proptypes'
import Toastr, { ToastrTheme } from '_components/toastr'
import { redirectUrl } from '_utils/enroll-course-redirect'

import ForgotPassword from '../forgot-password'
import SocialMedia from '../social-media'
import EmailSent from '../email-sent'
import SignInFooter from '../sign-in-footer'
import FormLogin from '../form-login'

import styles from './styles.css'

const mapStateToProps = ({ user, company, loading, error }) => ({
  loading: !!loading.get(LOGIN.ACTION) || !!loading.get(SIGN_UP.ACTION),
  facebookLoading: !!loading.get(FACEBOOK_LOGIN.ACTION),
  googleLoading: !!loading.get(GOOGLE_LOGIN.ACTION),
  apiError: error.get(LOGIN.ACTION) || error.get(SIGN_UP.ACTION),
  company: company.toJS(),
  user: user.toJS(),
  socialError: error.get(FACEBOOK_LOGIN.ACTION) || error.get(GOOGLE_LOGIN.ACTION),
})

const mapDispatchToProps = {
  login,
  signUp,
  cleanAuthErrors,
}

const validateEmail = email => {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(String(email).toLowerCase())
}

class SignInCard extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    login: PropTypes.func.isRequired,
    signUp: PropTypes.func.isRequired,
    apiError: ImmutablePropTypes.map,
    socialError: ImmutablePropTypes.map,
    loading: PropTypes.bool,
    facebookLoading: PropTypes.bool,
    googleLoading: PropTypes.bool,
    pathname: PropTypes.string,
    redirectTo: PropTypes.string,
    company: PropTypes.shape({
      email: PropTypes.string,
      organization: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
      }),
    }).isRequired,
    isCompany: PropTypes.bool.isRequired,
    user: userShape.isRequired,
    cleanAuthErrors: PropTypes.func.isRequired,
    organizationHash: PropTypes.string,
    companyId: PropTypes.number,
  }

  static defaultProps = {
    className: '',
    pathname: '',
    redirectTo: null,
    loading: false,
    facebookLoading: false,
    googleLoading: false,
    apiError: Map(),
    socialError: Map(),
    organizationHash: null,
    companyId: null,
  }

  constructor(props) {
    super(props)
    this.state = this.initialState()
  }

  componentDidUpdate(prevProps) {
    const { redirectTo, pathname } = this.props

    const stoppedLoading =
      (prevProps.loading && !this.props.loading) ||
      (prevProps.facebookLoading && !this.props.facebookLoading) ||
      (prevProps.googleLoading && !this.props.googleLoading)

    if (prevProps.pathname !== pathname && this.props.apiError.size > 0) {
      this.props.cleanAuthErrors()
    }

    if (
      prevProps.loading &&
      !this.props.loading &&
      this.props.apiError.size !== 0 &&
      this.props.apiError.first().get(0) === 'Please reset your password to login.'
    ) {
      toast(
        <Toastr
          theme={ToastrTheme.INFO}
          content="Please reset your password (and yes, you can use your previous password)
      to join us on the new and improved Lightyear Hub. Lightyear has taken the upgrade! Click “Forgot Password” below to complete the reset."
        />,
        {
          autoClose: false,
        }
      )
    }

    if (!stoppedLoading || this.props.apiError.size !== 0 || this.props.socialError.size !== 0) {
      return
    }

    if (this.props.user.completedOnboarding && this.props.user.name) {
      const redirectRoute = redirectUrl(redirectTo)

      navigate(redirectRoute.redirectTo || '/dashboard', {
        state: { classId: redirectRoute.classId },
      })
      return
    }

    navigate(redirectTo || '/onboarding')
  }

  initialState = () => {
    const { company, isCompany } = this.props
    return {
      email: isCompany ? company.email : '',
      password: '',
      confirmEmail: '',
      name: '',
      errors: {
        email: '',
        password: '',
        confirmEmail: '',
        name: '',
      },
    }
  }

  handleInput = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }

  handleOnSignUp = event => {
    event.preventDefault()
    if (this.checkIfInputsAreValid('sign-up')) {
      const { email, password, confirmEmail, name } = this.state
      const { organizationHash } = this.props

      const payload = {
        email,
        password1: password,
        confirmEmail,
        name,
      }

      if (organizationHash) {
        payload.organization = organizationHash
      }

      this.props.signUp(payload)
    }
  }

  handleOnSignIn = event => {
    event.preventDefault()
    if (this.checkIfInputsAreValid()) {
      const { email, password } = this.state
      this.props.login({ email, password })
    }
  }

  checkIfInputsAreValid = (type = '') => {
    const { email, password, confirmEmail, name } = this.state

    const errors = {
      email: '',
      password: '',
    }

    if (!validateEmail(email) || email === '') {
      errors.email = 'Email is invalid'
    }

    if (!name) {
      errors.name = 'This field is required'
    }

    if (password.length < 8) {
      errors.password = 'Minimum 8 characters'
    }

    if (type === 'sign-up') {
      if (!validateEmail(confirmEmail) || confirmEmail === '') {
        errors.confirmEmail = 'Email is invalid'
      } else if (email !== confirmEmail) {
        errors.confirmEmail = 'Email confirmation must match'
      }
    }

    this.setState({ errors })

    return Object.values(errors).every(value => value.length === 0)
  }

  render() {
    const {
      className,
      loading,
      pathname,
      apiError,
      facebookLoading,
      googleLoading,
      isCompany,
      organizationHash,
      companyId,
      redirectTo,
    } = this.props

    const { errors, email, password, confirmEmail, name } = this.state

    switch (pathname) {
      case '/create-account':
        return (
          <form className={classnames(styles.container, className)} onSubmit={this.handleOnSignUp}>
            {!companyId && (
              <SocialMedia
                signUp
                loading={loading}
                facebookLoading={facebookLoading}
                googleLoading={googleLoading}
                isCompany={isCompany}
              />
            )}
            <FormLogin
              signUp
              loading={loading || facebookLoading || googleLoading}
              handleInput={this.handleInput}
              name={name}
              email={email}
              errors={errors}
              password={password}
              confirmEmail={confirmEmail}
              apiError={apiError}
              isCompany={isCompany}
            />
            <SignInFooter
              show={!organizationHash}
              disabled={facebookLoading || googleLoading}
              loading={loading}
              buttonLabel="SIGN UP"
              moreDetails="Already have an account?"
              to="/login"
              linkLabel="Login"
              redirectTo={redirectTo}
            />
          </form>
        )

      case '/login':
        return (
          <form className={classnames(styles.container, className)} onSubmit={this.handleOnSignIn}>
            <SocialMedia
              loading={loading}
              facebookLoading={facebookLoading}
              googleLoading={googleLoading}
            />
            <FormLogin
              loading={loading || facebookLoading || googleLoading}
              handleInput={this.handleInput}
              email={email}
              errors={errors}
              password={password}
              apiError={apiError}
            />
            <SignInFooter
              disabled={facebookLoading || googleLoading}
              loading={loading}
              buttonLabel="LOGIN"
              moreDetails="Don't have an account?"
              to="/sign-up"
              linkLabel="Sign up"
              redirectTo={redirectTo}
            />
          </form>
        )

      case '/forgot-password':
        return <ForgotPassword className={className} />

      case '/email-sent':
        return <EmailSent className={className} />

      default:
        return null
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SignInCard)
