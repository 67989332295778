import React, { useEffect, useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import noScroll from 'no-scroll'
import { navigate, Link } from '@reach/router'
import { StripeProvider, Elements } from 'react-stripe-elements'
import moment from 'moment'

import Button, { ButtonTheme } from '_components/button'
import PaymentContainer from '_components/payment-container'
import Modal from '_components/modal'
import { classShape } from '_utils/proptypes'
import Tabs from '_components/tabs'
import useStripe from '_hooks/use-stripe'
import { CLASS_TYPE } from '_constants/class'

import LeaderLedClassSection from './leader-led-class-section'
import styles from './styles.css'

const CourseClasses = ({
  className,
  course,
  isOnboarding,
  target,
  isCompany,
  onClassEnroll,
  onFollowAction,
  onUnfollowAction,
  onMessageClick,
  redirectClass,
}) => {
  const { stripe } = useStripe()

  const tabs = useMemo(() => course.type && [course.type], [course.type])

  const [isModalOpen, setModal] = useState(false)

  useEffect(() => {
    if (redirectClass) {
      setModal(true)
    }
    // eslint-disable-next-line
    },[])

  const onEnrollClick = useCallback(
    classId => event => {
      event.preventDefault()
      if (!isOnboarding && !isCompany) {
        setModal(true)
        return
      }

      if (isCompany) {
        onClassEnroll(classId)
        return
      }

      navigate([`${target}/class/${classId}`])
    },
    [isOnboarding, target, isCompany, onClassEnroll]
  )

  const onModalClose = useCallback(() => {
    setModal(false)
  }, [])

  const onUserFollow = useCallback(username => onFollowAction(username), [onFollowAction])

  const onUserUnfollow = useCallback(username => onUnfollowAction(username), [onUnfollowAction])

  useEffect(
    () => {
      if (isModalOpen) {
        noScroll.on()
      } else {
        noScroll.off()
      }
    },
    [isModalOpen]
  )
  return (
    <>
      <div className={classnames(styles.container, className)}>
        <div className={styles.tabs}>
          <Tabs tabs={tabs} />
        </div>
        <div className={styles.content}>
          <div className={styles['class-content']}>
            {course.type === CLASS_TYPE.COACH_LED.slug && (
              <LeaderLedClassSection
                courseClass={course}
                isOnboarding={isOnboarding}
                onUserUnfollow={onUserUnfollow}
                onUserFollow={onUserFollow}
                onMessageClick={onMessageClick}
              />
            )}
            <div className={styles.information}>
              <div className={styles.dates}>
                {course.type !== CLASS_TYPE.SELF_LED?.slug ||
                course.type !== CLASS_TYPE.SELF_PACED?.slug ? (
                  <>
                    {course.startDate && (
                      <h3>Starts {moment(course.startDate).format('MMMM Do YYYY, LT')}</h3>
                    )}
                    <p className={styles.detail}>
                      Ends {moment(course.endDate).format('MMMM Do YYYY, LT')}
                    </p>
                  </>
                ) : (
                  <>
                    <h3>SELF-LED PROGRAM</h3>
                    <p className={styles.detail}>Move at your own pace</p>
                    <p className={styles['self-led-availability']}>
                      Available to start immediately
                    </p>
                  </>
                )}
              </div>
              {course.enrolled ? (
                <Link to={`/class/${course.enrolled}`} className={styles.enroll}>
                  VIEW COURSE
                </Link>
              ) : (
                <Button
                  label={`Enroll now - $${isCompany ? 0 : course.price}`}
                  theme={ButtonTheme.PRIMARY}
                  onClick={onEnrollClick(course.id)}
                />
              )}
              {(course.type === CLASS_TYPE.SELF_LED?.slug ||
                course.type !== CLASS_TYPE.SELF_PACED?.slug) && (
                <p className={styles.availability}>Available to start immediately</p>
              )}
            </div>
          </div>
        </div>
      </div>
      {isModalOpen && (
        <Modal isOpen onClose={onModalClose}>
          <StripeProvider {...stripe}>
            <Elements>
              <PaymentContainer
                onClose={onModalClose}
                courseClass={course}
                onEnroll={onClassEnroll}
              />
            </Elements>
          </StripeProvider>
        </Modal>
      )}
    </>
  )
}

CourseClasses.propTypes = {
  className: PropTypes.string,
  course: classShape.isRequired,
  isOnboarding: PropTypes.bool,
  isCompany: PropTypes.bool,
  onClassEnroll: PropTypes.func,
  target: PropTypes.string,
  courseName: PropTypes.string,
  onFollowAction: PropTypes.func,
  onUnfollowAction: PropTypes.func,
  onMessageClick: PropTypes.func,
  redirectClass: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
}

CourseClasses.defaultProps = {
  className: '',
  isOnboarding: false,
  target: '',
  isCompany: false,
  onClassEnroll: () => {},
  courseName: '',
  onFollowAction: () => {},
  onUnfollowAction: () => {},
  onMessageClick: () => {},
  redirectClass: undefined,
}

export default React.memo(CourseClasses)
