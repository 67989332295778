import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import EllipsisIcon from '_assets/icons/ellipsis.svg'
import { isKeyboardOrClick, onMouseDown } from '_utils/aria'
import { postShape } from '_utils/proptypes'
import { PostActionType } from '_components/post-actions-modal'
import useModal from '_hooks/use-modal'

import styles from './styles.css'

const renderOption = (title, subtitle, onClick, modalType, isDanger = false) => (
  <li className={styles.item}>
    <button
      type="button"
      onClick={onClick}
      onMouseDown={onMouseDown}
      className={classnames(styles.action, {
        [styles.danger]: isDanger,
      })}
      id={modalType}
    >
      <p className={styles['action-title']}>{title}</p>
      <p className={styles.description}>{subtitle}</p>
    </button>
  </li>
)

const PostDropdown = ({ className, post, isOwnPost, removeHideOption, openModal, flagPost }) => {
  const [isOpen, onToggle] = useModal()
  const [dropdownRef, setDropdownRef] = useState()

  const setRef = useCallback(ref => {
    setDropdownRef(ref)
  }, [])

  const handleOutsideClick = useCallback(
    event => {
      if (dropdownRef.contains(event.target) && !event.target.href) {
        return
      }

      onToggle()
      document.removeEventListener('click', handleOutsideClick, false)
    },
    [dropdownRef, onToggle]
  )

  const onHandleToggle = useCallback(
    event => {
      const { key } = event

      if (isKeyboardOrClick(key)) {
        if (!isOpen) {
          document.addEventListener('click', handleOutsideClick, false)
        } else {
          document.removeEventListener('click', handleOutsideClick, false)
        }

        onToggle()
      }
    },
    [handleOutsideClick, isOpen, onToggle]
  )

  const onOptionClick = useCallback(
    event => {
      event.preventDefault()
      const { id } = event.currentTarget

      if (id === PostActionType.EDIT) {
        openModal(id, post.message)
        return
      }

      openModal(id)
    },
    [openModal, post.message]
  )

  useEffect(
    () => () => {
        document.removeEventListener('click', handleOutsideClick, false)
      },
    [handleOutsideClick]
  )

  return (
    <div className={className} ref={setRef}>
      <div className={classnames(styles.container, { [styles.open]: isOpen })}>
        <button
          type="button"
          onClick={onHandleToggle}
          className={styles.actions}
          onMouseDown={onMouseDown}
        >
          <svg aria-label="Post options" role="img" viewBox={EllipsisIcon.viewBox}>
            <use xlinkHref={`#${EllipsisIcon.id}`} />
          </svg>
        </button>
        <div className={styles.dropdown}>
          {isOwnPost ? (
            <ul className={styles.list}>
              {renderOption(
                'Edit this post',
                'It will be marked as edited',
                onOptionClick,
                PostActionType.EDIT
              )}
              {renderOption(
                'Delete this post',
                'It will no longer be visible',
                onOptionClick,
                PostActionType.DELETE,
                true
              )}
            </ul>
          ) : (
            <ul className={styles.list}>
              {!removeHideOption &&
                renderOption(
                  'Hide posts from user',
                  'Stop seeing posts but still follow',
                  onOptionClick,
                  PostActionType.IGNORE
                )}
              {renderOption(
                'Block this user',
                'They won’t see your content',
                onOptionClick,
                PostActionType.BLOCK
              )}
              {renderOption(
                'Flag this content',
                'This explorer is out off course',
                flagPost,
                PostActionType.FLAG,
                true
              )}
            </ul>
          )}
        </div>
      </div>
    </div>
  )
}

PostDropdown.propTypes = {
  className: PropTypes.string,
  post: postShape.isRequired,
  isOwnPost: PropTypes.bool,
  removeHideOption: PropTypes.bool,
  openModal: PropTypes.func,
  flagPost: PropTypes.func,
}

PostDropdown.defaultProps = {
  className: '',
  isOwnPost: false,
  removeHideOption: false,
  openModal: () => {},
  flagPost: () => {},
}

export default PostDropdown
