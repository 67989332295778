import React, { useMemo, useCallback } from 'react'
import PropTypes from 'prop-types'

import CommunityIcon from '_assets/icons/ic_community.svg'
import Button, { ButtonTheme } from '_components/button'
import { setSubscriptionExpiringCookie } from '_utils/subscription-expire'

import styles from './styles.css'

const SubscriptionEndingBanner = ({ onDismissClick, daysLeft }) => {
  const memberShipScrollToState = useMemo(
    () => ({
      billing: true,
    }),
    []
  )

  const bannerTitle = useMemo(
    () => `Your Lightyear Membership ends in ${daysLeft} day${daysLeft === 1 ? '' : 's'}`,
    [daysLeft]
  )

  const onDismiss = useCallback(
    () => {
      setSubscriptionExpiringCookie(daysLeft)
      onDismissClick()
    },
    [daysLeft, onDismissClick]
  )

  return (
    <section className={styles['banner-container']}>
      <div className={styles['banner-content']}>
        <svg className={styles.icon} viewBox={CommunityIcon.viewBox} aria-hidden="true">
          <use xlinkHref={`#${CommunityIcon.id}`} />
        </svg>
        <h1 className={styles.title}>{bannerTitle}</h1>
        <p className={styles.description}>
          To continue to have access to the Lightyear Community and our content gallery, enable the
          membership renewal!
        </p>
        <Button
          className={styles['membership-button']}
          theme={ButtonTheme.SECONDARY_TRANSPARENT_BACKGROUND}
          label="Manage membership"
          state={memberShipScrollToState}
          to="/account"
          onClick={onDismiss}
        />
        <Button
          className={styles['dismiss-button']}
          theme={ButtonTheme.SECONDARY_TRANSPARENT_BACKGROUND}
          label="Dismiss"
          onClick={onDismiss}
        />
      </div>
    </section>
  )
}

SubscriptionEndingBanner.propTypes = {
  daysLeft: PropTypes.number.isRequired,
  onDismissClick: PropTypes.func.isRequired,
}
export default React.memo(SubscriptionEndingBanner)
