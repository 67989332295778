import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'

import { logout } from '_modules/user/actions'
import Toastr, { ToastrTheme } from '_components/toastr'

const mapDispatchToProps = { userLogout: logout }

const EmailChange = ({ status, userLogout }) => {
  useEffect(
    () => {
      if (status === 'success') {
        toast(<Toastr theme={ToastrTheme.SUCCESS} content="Your email was changed successfully!" />)
        userLogout()
        navigate('/sign-in')
        return
      }

      if (status === 'failed') {
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content="We couldn't confirm your new email, please try again!"
          />
        )
      }
      navigate('/dashboard')
    },
    [status, userLogout]
  )
  return <div />
}

EmailChange.propTypes = {
  status: PropTypes.string.isRequired,
  userLogout: PropTypes.func.isRequired,
}

export default connect(
  null,
  mapDispatchToProps
)(EmailChange)
