import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { useWindowSize } from '@reach/window-size'

import { formatDate } from '_utils/helpers'
import CalendarIcon from '_assets/icons/calendar.svg'
import PersonIcon from '_assets/icons/person.svg'
import { DESKTOP_THRESHOLD } from '_config/media-queries'
import Svg from '_components/svg'

import styles from './styles.css'

export const CLASS_PERIOD_THEME = {
  INDIVIDUAL_CLASS_DETAIL: 'individual-class-detail',
  CLASS_LIST_DETAIL: 'class-list-detail',
}

const ClassPeriodCard = ({ startDate, endDate, showEndDate, theme, className }) => {
  const { width } = useWindowSize()
  const renderEndDate = width > DESKTOP_THRESHOLD || showEndDate

  const handleClassPeriodType = useMemo(
    () => {
      if (startDate || endDate) {
        return {
          icon: CalendarIcon,
          startDate: startDate
            ? `Starts on ${formatDate(startDate, 'MMM dd, yyyy')}`
            : 'There is no start date defined',
          endDate: endDate
            ? `Expect to end on ${formatDate(endDate, 'MMM dd, yyyy')}`
            : 'There is no end date defined',
        }
      }

      return {
        icon: PersonIcon,
        text: 'Self-paced',
        description: 'Take this class in your own time',
      }
    },
    [endDate, startDate]
  )

  return (
    <div className={classnames(styles['class-period'], styles[theme], className)}>
      <div className={styles['calendar-icon-wrapper']}>
        <Svg icon={handleClassPeriodType.icon} className={styles['calendar-icon']} />
      </div>

      <div className={styles['class-period-content']}>
        {startDate || endDate ? (
          <>
            <p className={styles['class-top-content']}>{handleClassPeriodType.startDate}</p>
            {renderEndDate && (
              <p className={styles['class-bottom-content']}>{handleClassPeriodType.endDate}</p>
            )}
          </>
        ) : (
          <>
            <p className={styles['class-top-content']}>{handleClassPeriodType.text}</p>
            {theme === CLASS_PERIOD_THEME.INDIVIDUAL_CLASS_DETAIL && (
              <p className={styles['class-bottom-content']}>{handleClassPeriodType.description}</p>
            )}
          </>
        )}
      </div>
    </div>
  )
}

ClassPeriodCard.propTypes = {
  startDate: PropTypes.string,
  endDate: PropTypes.string,
  showEndDate: PropTypes.bool,
  className: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(CLASS_PERIOD_THEME)),
}

ClassPeriodCard.defaultProps = {
  className: '',
  showEndDate: false,
  theme: CLASS_PERIOD_THEME.CLASS_LIST_DETAIL,
  startDate: '',
  endDate: '',
}

export default React.memo(ClassPeriodCard)
