import { createSelector } from 'reselect'
import { Map } from 'immutable'

import {
  BLOCK_USER,
  LINK_ZOOM,
  LOGIN,
  GOOGLE_LOGIN,
  RESET_PASSWORD,
  CONFIRM_RESET_PASSWORD,
  SIGN_UP,
  UPDATE_USER,
  CHANGE_PASSWORD,
  START_MEMBERSHIP,
  GET_USER,
} from '_modules/user/actions'

const isLinkingZoom = ({ loading }) => !!loading.get(LINK_ZOOM.ACTION)
const isBlockingUser = ({ loading }) => !!loading.get(BLOCK_USER.ACTION)
const blockUserError = ({ error }) => error.get(BLOCK_USER.ACTION)
export const errorLinkingZoom = ({ error }) => error.get(LINK_ZOOM.ACTION)

export const checkIsLinkingZoom = createSelector([isLinkingZoom], isLoading => isLoading)

export const checkIsBlockingUser = createSelector([isBlockingUser], isLoading => isLoading)

export const errorBlockUser = createSelector([blockUserError], error => error)

export const authTokenSelector = state => state.user.authToken

export const userIdSelector = state => state.user.id

export const hasCompletedOnboardingSelector = state => state.user.completedOnboarding

export const hasCompletedProfileSelector = state => state.user.completedProfile

export const userBelongsToACompanySelector = state => state.user.belongsToACompany

export const getUserSelector = state => state.user

export const isGettingUserData = state => !!state.loading.get(GET_USER.ACTION)

export const isLoginLoadingSelector = state => !!state.loading.get(LOGIN.ACTION)

export const loginErrorSelector = state => state.error.get(LOGIN.ACTION, Map())

export const isGoogleLoginLoadingSelector = state => !!state.loading.get(GOOGLE_LOGIN.ACTION)

export const googleLoginErrorSelector = state => state.error.get(GOOGLE_LOGIN.ACTION, Map())

export const isResettingPasswordSelector = state => state.loading.get(RESET_PASSWORD.ACTION)

export const resetPasswordErrorSelector = state => state.error.get(RESET_PASSWORD.ACTION, Map())

export const isConfirmResetPasswordLoadingSelector = state =>
  !!state.loading.get(CONFIRM_RESET_PASSWORD.ACTION)

export const confirmResetPasswordErrorSelector = state =>
  state.error.get(CONFIRM_RESET_PASSWORD.ACTION, Map())

export const isSignUpLoadingSelector = state => !!state.loading.get(SIGN_UP.ACTION)

export const signUpErrorSelector = state => state.error.get(SIGN_UP.ACTION, Map())

export const isUpdatingUserSelector = state => !!state.loading.get(UPDATE_USER.ACTION)

export const isStartingMembershipSelector = state => !!state.loading.get(START_MEMBERSHIP.ACTION)

export const startMembershipErrorSelector = state => state.error.get(START_MEMBERSHIP.ACTION, Map())

export const updateUserErrorSelector = state => state.error.get(UPDATE_USER.ACTION, Map())

export const subscriptionTrialEndSelector = state => state.user.get('subscriptionTrialEnd')

export const changePasswordLoadingSelector = state => state.loading.get(CHANGE_PASSWORD.ACTION)

export const changePasswordErrorSelector = state => state.error.get(CHANGE_PASSWORD.ACTION, Map())

export const subscriptionStatusSelector = state => state.user.get('subscriptionStatus')

export const membershipPriceSelector = state => state.user.get('membershipPrice')

export const membershipOptionsSelector = state => state.user.get('membershipOptions')

export const availableMembershipsSelector = state => state.user.get('availableMemberships')
