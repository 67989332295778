import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation } from '@reach/router'
import { Map } from 'immutable'
import hoistNonReactStatics from 'hoist-non-react-statics'
import { useWindowSize } from '@reach/window-size'

import { getPrismicInformation, GET_PRISMIC_INFORMATION } from '_modules/prismic/actions'
import { landingPageSelector } from '_modules/prismic/selectors'
import BaseApp from '_components/landing-components/base-app'
import { getPublicCourse } from '_modules/public-courses/actions'
import SEO from '_components/seo'
import { getPrismicInformation as getPrismicInformationService } from '_services/prismic'
import PrismicReducer from '_modules/prismic/reducer'
import { getServerOriginSelector } from '_modules/server/selectors'
import { hasLeaderLedCoursesSelector } from '_modules/public-courses/selectors'
import scrollToSection from '_utils/scroll-to-section'

import { getSlices, views, getCurrentView } from './constants'
import styles from './styles.css'

const Landing = ({ children }) => {
  const { pathname, state: locationState } = useLocation()
  const currentView = getCurrentView(pathname)
  const { width } = useWindowSize()
  const isPrismic = !children
  const isMembershipsView = currentView === views.memberships

  const landing = useSelector(state => landingPageSelector(state, currentView))?.toJS()
  const origin = useSelector(getServerOriginSelector)
  const hasLeaderLedClasses = useSelector(hasLeaderLedCoursesSelector)
  const dispatch = useDispatch()
  const hasFinishedGettingPrismicInfo = Boolean(landing)

  useEffect(
    () => {
      if (!hasFinishedGettingPrismicInfo && isPrismic) {
        dispatch(
          getPrismicInformation({
            documentType: views[currentView],
          })
        )
      }
    },
    [currentView, dispatch, isPrismic, hasFinishedGettingPrismicInfo]
  )

  useEffect(
    () => {
      if (landing?.category) {
        dispatch(getPublicCourse(landing.category))
      }
    },
    [dispatch, landing]
  )

  useEffect(
    () => {
      if (
        hasFinishedGettingPrismicInfo &&
        locationState?.ScrollToSectionId &&
        document.querySelector(`section[id^=${locationState?.ScrollToSectionId}`)
      ) {
        scrollToSection(locationState?.ScrollToSectionId, width)
      }
    },
    [hasFinishedGettingPrismicInfo, locationState, width]
  )

  return (
    <>
      <SEO
        title={landing?.seo?.title}
        description={landing?.seo?.description}
        image={landing?.seo?.image}
        contentType={landing?.seo?.contentType}
        keywords={landing?.seo?.keywords}
        twitterTitle={landing?.seo?.twitterTitle}
        twitterDescription={landing?.seo?.twitterDescription}
        twitterImage={landing?.seo?.twitterImage}
        cardType={landing?.seo?.cardType}
        url={origin}
      />
      <BaseApp pathname={pathname}>
        {isPrismic ? (
          <>
            {landing?.title && <h1 className={styles.hidden}>{landing.title}</h1>}
            {landing?.slices?.map(
              (slice, index) =>
                getSlices(slice, index, landing.courseSlug, hasLeaderLedClasses, isMembershipsView)[
                  slice.sliceType
                ]
            )}
          </>
        ) : (
          children
        )}
      </BaseApp>
    </>
  )
}

Landing.fetchData = ({ path }) => {
  const currentView = getCurrentView(path)
  return [
    getPrismicInformationService()({
      documentType: views[currentView],
    }).then(payload => ({
      prismic: PrismicReducer(Map(), {
        type: GET_PRISMIC_INFORMATION.FULFILLED,
        payload: { ...payload, path },
      }),
    })),
    getPrismicInformationService()({
      documentType: 'base_app',
    }).then(payload => ({
      prismic: PrismicReducer(Map(), {
        type: GET_PRISMIC_INFORMATION.FULFILLED,
        payload: { ...payload, path },
      }),
    })),
  ]
}

Landing.propTypes = {
  children: PropTypes.node,
}

Landing.defaultProps = {
  children: null,
}

export default hoistNonReactStatics(React.memo(Landing), Landing)
