import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate } from '@reach/router'

import ArrowLeftIcon from '_assets/icons/arrow-left.svg'

import styles from './styles.css'

const StepHeader = ({ className, step, stepTitle, belongsToACompany, hasBackButton, id }) => {
  const onBackClick = useCallback(
    event => {
      event.preventDefault()
      const basepath = '/onboarding'

      if (step === 2) {
        navigate([basepath])
        return
      }

      navigate([`${basepath}/course/${id}`])
    },
    [id, step]
  )

  return (
    <div className={classnames(styles.container, className)}>
      {hasBackButton && (
        <svg
          className={styles['back-icon']}
          onClick={onBackClick}
          onKeyPress={onBackClick}
          tabIndex="0"
          aria-label="Back icon"
          viewBox={ArrowLeftIcon.viewBox}
          role="button"
        >
          <use xlinkHref={`#${ArrowLeftIcon.id}`} />
        </svg>
      )}
      <p className={styles.steps}>
        Step {step} of {belongsToACompany ? 3 : 4}
      </p>
      <p className={styles.title}>{stepTitle}</p>
    </div>
  )
}

StepHeader.propTypes = {
  className: PropTypes.string,
  hasBackButton: PropTypes.bool,
  stepTitle: PropTypes.string,
  step: PropTypes.number,
  belongsToACompany: PropTypes.bool.isRequired,
  id: PropTypes.number,
}

StepHeader.defaultProps = {
  className: '',
  hasBackButton: false,
  stepTitle: '',
  step: 1,
  id: null,
}

export default StepHeader
