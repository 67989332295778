import { Map, List } from 'immutable'

import Model from './model'

import { User } from '.'

class Message extends Model({
  author: new User(),
  createdAt: undefined,
  file: undefined,
  filename: undefined,
  id: undefined,
  message: undefined,
  userName: undefined,
  meeting: Map({
    id: undefined,
    topic: undefined,
    startTime: undefined,
    duration: undefined,
    joinUrl: undefined,
    ended: undefined,
    recordings: List(),
  }),
}) {}

export default Message
