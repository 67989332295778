import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Modal from '_components/modal'
import useBodyOverflow from '_hooks/use-body-overflow'
import SectionContent from '_components/landing-components/section-content'
import { prismicMemberProTypes } from '_utils/proptypes'
import CloseIcon from '_assets/icons/close.svg'
import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

const LeaderProfileModal = ({ isOpen, onClose, member }) => {
  useBodyOverflow(isOpen)

  const hasContent = useMemo(() => member.information?.length > 0, [member.information])

  return (
    <Modal isOpen={isOpen} onClose={onClose} className={styles.modal}>
      <div className={styles.content}>
        <button
          type="button"
          className={styles['close-button']}
          onClick={onClose}
          aria-label={`Close ${member.name} Profile Modal`}
          onMouseDown={onMouseDown}
        >
          <svg className={styles['close-icon']} viewBox={CloseIcon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${CloseIcon.id}`} />
          </svg>
        </button>
        <h1 className={styles.name}>{member.name}</h1>
        <p className={styles.position}>{member.position}</p>
        <div className={styles['member-info']}>
          {hasContent && (
            <div className={styles.information}>
              {member.information.map((paragraph, index) => (
                <SectionContent
                  // setting key as index since this array is not going to be changed
                  key={`content-${index + 1}`}
                  text={paragraph}
                  className={classnames({ [styles.break]: index > 0 })}
                />
              ))}
            </div>
          )}
          {member.pictureModal?.url && (
            <img
              src={member.pictureModal.url}
              alt={member.pictureModal.alt || `${member.name} Profile`}
              className={styles.image}
            />
          )}
        </div>
      </div>
    </Modal>
  )
}

LeaderProfileModal.propTypes = {
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  member: prismicMemberProTypes.isRequired,
}

LeaderProfileModal.defaultProps = {
  isOpen: false,
  onClose: () => {},
}

export default React.memo(LeaderProfileModal)
