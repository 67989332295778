import { OrderedMap, Map, List, fromJS } from 'immutable'
import humps from 'humps'
import queryString from 'query-string'

import { Class, Post } from '_models/'
import { createReducer } from '_utils/redux'
import {
  GET_CLASSES,
  GET_MORE_CLASSES,
  GET_CLASS,
  UPDATE_ASSIGNMENT,
  BULK_UPDATE_ANSWER,
  UPDATE_INTENTION,
  GET_ALL_PARTICIPANTS,
  START_CALL,
  ENABLE_ASSIGNMENT_VIDEO_CALL,
  VALIDATE_DISCOUNT_COUPON,
  GET_CLASSES_NEW,
  CLEAR_COUPON_STATE,
  ENROLL,
  GET_CLASSES_BY_COURSE,
} from '_modules/class/actions'
import ASSIGNMENT_ACTIONS from '_modules/assignment/actions'
import { getPage } from '_utils/helpers'
import {
  WEBSOCKET_VIDEO_ASSIGNMENT_STARTED,
  WEBSOCKET_VIDEO_ASSIGNMENT_ENDED,
  WEBSOCKET_NEW_ASSIGNMENT_POST,
  WEBSOCKET_NEW_ASSIGNMENT_COMMENT,
  WEBSOCKET_ASSIGNMENT_POST_UPDATED,
  WEBSOCKET_ASSIGNMENT_COMMENT_UPDATED,
  WEBSOCKET_ASSIGNMENT_POST_SHAZAMED,
  WEBSOCKET_ASSIGNMENT_POST_UNSHAZAMED,
  WEBSOCKET_ASSIGNMENT_COMMENT_SHAZAMED,
  WEBSOCKET_ASSIGNMENT_COMMENT_UNSHAZAMED,
} from '_modules/websocket/actions'
import { FOLLOW_LEADER } from '_modules/user/actions'

const { CREATE_POST, EDIT_POST, DELETE_POST, SHAZAM_POST, UNSHAZAM_POST } = ASSIGNMENT_ACTIONS

const INITIAL_STATE = Map({
  count: undefined,
  next: undefined,
  previous: undefined,
  classes: OrderedMap(),
  availableClasses: List(),
  peopleEnrolled: Map({
    count: undefined,
    next: undefined,
    previous: undefined,
    participants: List(),
  }),
  couponDiscount: Map(),
  couponType: Map(),
})

const classes = createReducer(INITIAL_STATE, {
  [GET_CLASSES.FULFILLED]: (state, { payload }) =>
    state
      .set(
        'classes',
        OrderedMap(payload.results.map(courseClass => [courseClass.id, new Class(courseClass)]))
      )
      .set('next', payload.next ? getPage(payload.next) : undefined)
      .set('previous', payload.previous ? getPage(payload.previous) : undefined)
      .set('count', payload.count),

  [GET_MORE_CLASSES.FULFILLED]: (state, { payload }) => state
      .mergeIn(
        ['classes'],
        OrderedMap(payload.results.map(courseClass => [courseClass.id, new Class(courseClass)]))
      )
      .set('next', payload.next ? getPage(payload.next) : undefined)
      .set('previous', payload.previous ? getPage(payload.previous) : undefined)
      .set('count', payload.count),

  [GET_CLASS.FULFILLED]: (state, { payload }) =>
    state.mergeIn(['classes'], OrderedMap([[payload.id, new Class(payload)]])),

  [SHAZAM_POST.FULFILLED]: (state, { meta }) => {
    try {
      const assignmentIndex = state
        .getIn(['classes', meta.classId, 'assignments'])
        .findIndex(assignment => assignment.get('id') === meta.assignmentId)

      if (meta.fatherPostId) {
        const postIndex = state
          .getIn(['classes', meta.classId, 'assignments', assignmentIndex, 'posts'])
          .findIndex(post => post.get('id') === meta.fatherPostId)

        const commentIndex = state
          .getIn([
            'classes',
            meta.classId,
            'assignments',
            assignmentIndex,
            'posts',
            postIndex,
            'comments',
          ])
          .findIndex(comment => comment.get('id') === meta.postId)

        return state.updateIn(
          [
            'classes',
            meta.classId,
            'assignments',
            assignmentIndex,
            'posts',
            postIndex,
            'comments',
            commentIndex,
          ],
          comment => comment.set('shazams', comment.get('shazams') + 1).set('shazamedByYou', true)
        )
      }

      const postIndex = state
        .getIn(['classes', meta.classId, 'assignments', assignmentIndex, 'posts'])
        .findIndex(post => post.get('id') === meta.postId)

      return state.updateIn(
        ['classes', meta.classId, 'assignments', assignmentIndex, 'posts', postIndex],
        post => post.set('shazams', post.get('shazams') + 1).set('shazamedByYou', true)
      )
    } catch (err) {
      return state
    }
  },

  [UNSHAZAM_POST.FULFILLED]: (state, { meta }) => {
    try {
      const assignmentIndex = state
        .getIn(['classes', meta.classId, 'assignments'])
        .findIndex(assignment => assignment.get('id') === meta.assignmentId)

      if (meta.fatherPostId) {
        const postIndex = state
          .getIn(['classes', meta.classId, 'assignments', assignmentIndex, 'posts'])
          .findIndex(post => post.get('id') === meta.fatherPostId)

        const commentIndex = state
          .getIn([
            'classes',
            meta.classId,
            'assignments',
            assignmentIndex,
            'posts',
            postIndex,
            'comments',
          ])
          .findIndex(comment => comment.get('id') === meta.postId)

        return state.updateIn(
          [
            'classes',
            meta.classId,
            'assignments',
            assignmentIndex,
            'posts',
            postIndex,
            'comments',
            commentIndex,
          ],
          comment => comment.set('shazams', comment.get('shazams') - 1).set('shazamedByYou', false)
        )
      }

      const postIndex = state
        .getIn(['classes', meta.classId, 'assignments', assignmentIndex, 'posts'])
        .findIndex(post => post.get('id') === meta.postId)

      return state.updateIn(
        ['classes', meta.classId, 'assignments', assignmentIndex, 'posts', postIndex],
        post => post.set('shazams', post.get('shazams') - 1).set('shazamedByYou', false)
      )
    } catch (err) {
      return state
    }
  },

  [UPDATE_ASSIGNMENT.FULFILLED]: (state, { payload, meta }) => {
    const index = state
      .getIn(['classes', meta.classId, 'assignments'])
      .findIndex(assignment => assignment.get('id') === meta.assignmentId)

    return state.setIn(
      ['classes', meta.classId, 'assignments', index, 'completed'],
      payload.completed
    )
  },

  [BULK_UPDATE_ANSWER.FULFILLED]: (state, { payload, meta }) => {
    const assignmentIndex = state
      .getIn(['classes', meta.classId, 'assignments'])
      .findIndex(assignment => assignment.get('id') === meta.assignmentId)

    return state.setIn(
      ['classes', meta.classId, 'assignments', assignmentIndex],
      fromJS(humps.camelizeKeys(payload))
    )
  },

  [UPDATE_INTENTION.FULFILLED]: (state, { payload, meta }) =>
    state.setIn(['classes', meta.classId], new Class(payload)),

  [CREATE_POST.FULFILLED]: (state, { payload, meta }) => {
    const newPost = new Post(payload)

    const assignmentIndex = state
      .getIn(['classes', meta.classId, 'assignments'])
      .findIndex(assignment => assignment.get('id') === meta.assignmentId)

    const postsList = state.getIn([
      'classes',
      meta.classId,
      'assignments',
      assignmentIndex,
      'posts',
    ])

    if (meta.fatherPostId) {
      const fatherPostIndex = postsList.findIndex(post => post.get('id') === meta.fatherPostId)

      return state.setIn(
        ['classes', meta.classId, 'assignments', assignmentIndex, 'posts', fatherPostIndex],
        newPost
      )
    }

    return state.setIn(
      ['classes', meta.classId, 'assignments', assignmentIndex, 'posts'],
      postsList.unshift(newPost)
    )
  },

  [EDIT_POST.FULFILLED]: (state, { payload, meta }) => {
    const assignmentIndex = state
      .getIn(['classes', meta.classId, 'assignments'])
      .findIndex(assignment => assignment.get('id') === meta.assignmentId)

    const postIndex = state
      .getIn(['classes', meta.classId, 'assignments', assignmentIndex, 'posts'])
      .findIndex(post => post.get('id') === payload.id)

    return state.setIn(
      ['classes', meta.classId, 'assignments', assignmentIndex, 'posts', postIndex],
      new Post(payload)
    )
  },

  [DELETE_POST.FULFILLED]: (state, { meta }) => {
    const assignmentIndex = state
      .getIn(['classes', meta.classId, 'assignments'])
      .findIndex(assignment => assignment.get('id') === meta.assignmentId)

    return state.setIn(
      ['classes', meta.classId, 'assignments', assignmentIndex, 'posts'],
      state
        .getIn(['classes', meta.classId, 'assignments', assignmentIndex, 'posts'])
        .filter(post => post.get('id') !== meta.postId)
    )
  },

  [GET_ALL_PARTICIPANTS.FULFILLED]: (state, { payload, meta: { page } }) => {
    if (!page) {
      return state.set(
        'peopleEnrolled',
        Map({
          count: payload.count,
          next: payload.next ? getPage(payload.next) : undefined,
          previous: payload.previous ? getPage(payload.previous) : undefined,
          participants: List(payload.results),
        })
      )
    }

    return state
      .setIn(['peopleEnrolled', 'next'], payload.next ? getPage(payload.next) : undefined)
      .setIn(
        ['peopleEnrolled', 'previous'],
        payload.previous ? getPage(payload.previous) : undefined
      )
      .mergeIn(['peopleEnrolled', 'participants'], payload.results)
  },

  [START_CALL.FULFILLED]: (state, { meta }) => {
    const assignmentIndex = state
      .getIn(['classes', Number(meta.classId), 'assignments'])
      ?.findIndex(assignment => assignment.get('id') === meta.assignmentId)

    if (!assignmentIndex) {
      return state
    }

    return state.setIn(
      [
        'classes',
        Number(meta.classId),
        'assignments',
        assignmentIndex,
        'assignment',
        'meeting',
        'started',
      ],
      true
    )
  },

  [ENABLE_ASSIGNMENT_VIDEO_CALL]: (state, { meta: { classId, assignmentId } }) => {
    const assignmentIndex = state
      .getIn(['classes', classId, 'assignments'])
      .findIndex(assignment => assignment.get('id') === assignmentId)

    return state.setIn(
      ['classes', classId, 'assignments', assignmentIndex, 'assignment', 'meeting', 'canStart'],
      true
    )
  },

  [WEBSOCKET_VIDEO_ASSIGNMENT_STARTED]: (state, { payload: { classId, assignmentId } }) => {
    const assignmentIndex = state
      .getIn(['classes', classId, 'assignments'])
      ?.findIndex(assignment => assignment.get('id') === assignmentId)

    return state.setIn(
      ['classes', classId, 'assignments', assignmentIndex, 'assignment', 'meeting', 'started'],
      true
    )
  },

  [WEBSOCKET_VIDEO_ASSIGNMENT_ENDED]: (state, { payload: { classId, assignmentId } }) => {
    const assignmentIndex = state
      .getIn(['classes', classId, 'assignments'])
      ?.findIndex(assignment => assignment.get('id') === assignmentId)

    return state.setIn(
      ['classes', classId, 'assignments', assignmentIndex, 'assignment', 'meeting', 'ended'],
      true
    )
  },

  [WEBSOCKET_NEW_ASSIGNMENT_POST]: (state, { payload: { classId, assignmentId, post } }) => {
    const assignmentIndex = state
      .getIn(['classes', classId, 'assignments'])
      .findIndex(assignment => assignment.get('id') === assignmentId)

    return state.setIn(
      ['classes', classId, 'assignments', assignmentIndex, 'posts'],
      state
        .getIn(['classes', classId, 'assignments', assignmentIndex, 'posts'])
        .push(new Post(post))
    )
  },

  [WEBSOCKET_NEW_ASSIGNMENT_COMMENT]: (
    state,
    { payload: { classId, assignmentId, postId, comment } }
  ) => {
    try {
      const assignmentIndex = state
        .getIn(['classes', classId, 'assignments'])
        .findIndex(assignment => assignment.get('id') === assignmentId)

      const postIndex = state
        .getIn(['classes', classId, 'assignments', assignmentIndex, 'posts'])
        .findIndex(post => post.get('id') === postId)

      return state.setIn(
        ['classes', classId, 'assignments', assignmentIndex, 'posts', postIndex, 'comments'],
        state
          .getIn([
            'classes',
            classId,
            'assignments',
            assignmentIndex,
            'posts',
            postIndex,
            'comments',
          ])
          .push(new Post(comment))
      )
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_ASSIGNMENT_POST_UPDATED]: (state, { payload }) => {
    try {
      const assignmentIndex = state
        .getIn(['classes', payload.classId, 'assignments'])
        .findIndex(assignment => assignment.get('id') === payload.assignmentId)

      const postIndex = state
        .getIn(['classes', payload.classId, 'assignments', assignmentIndex, 'posts'])
        .findIndex(post => post.get('id') === payload.postId)

      return state.setIn(
        ['classes', payload.classId, 'assignments', assignmentIndex, 'posts', postIndex],
        new Post(payload.post)
      )
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_ASSIGNMENT_COMMENT_UPDATED]: (
    state,
    { payload: { classId, assignmentId, postId, comment } }
  ) => {
    try {
      const assignmentIndex = state
        .getIn(['classes', classId, 'assignments'])
        .findIndex(assignment => assignment.get('id') === assignmentId)

      const postIndex = state
        .getIn(['classes', classId, 'assignments', assignmentIndex, 'posts'])
        .findIndex(post => post.get('id') === postId)

      const commentIndex = state
        .getIn(['classes', classId, 'assignments', assignmentIndex, 'posts', postIndex, 'comments'])
        .findIndex(post => post.get('id') === postId)

      return state.setIn(
        [
          'classes',
          classId,
          'assignments',
          assignmentIndex,
          'posts',
          postIndex,
          'comments',
          commentIndex,
        ],
        new Post(comment)
      )
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_ASSIGNMENT_POST_SHAZAMED]: (state, { payload }) => {
    try {
      const assignmentIndex = state
        .getIn(['classes', payload.classId, 'assignments'])
        .findIndex(assignment => assignment.get('id') === payload.assignmentId)

      const postIndex = state
        .getIn(['classes', payload.classId, 'assignments', assignmentIndex, 'posts'])
        .findIndex(post => post.get('id') === payload.post.id)

      return state.setIn(
        ['classes', payload.classId, 'assignments', assignmentIndex, 'posts', postIndex],
        new Post(payload.post)
      )
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_ASSIGNMENT_POST_UNSHAZAMED]: (state, { payload }) => {
    try {
      const assignmentIndex = state
        .getIn(['classes', payload.classId, 'assignments'])
        .findIndex(assignment => assignment.get('id') === payload.assignmentId)

      const postIndex = state
        .getIn(['classes', payload.classId, 'assignments', assignmentIndex, 'posts'])
        .findIndex(post => post.get('id') === payload.post.id)
      return state.setIn(
        ['classes', payload.classId, 'assignments', assignmentIndex, 'posts', postIndex],
        new Post(payload.post)
      )
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_ASSIGNMENT_COMMENT_SHAZAMED]: (state, { payload }) => {
    try {
      const assignmentIndex = state
        .getIn(['classes', payload.classId, 'assignments'])
        .findIndex(assignment => assignment.get('id') === payload.assignmentId)

      const postIndex = state
        .getIn(['classes', payload.classId, 'assignments', assignmentIndex, 'posts'])
        .findIndex(post => post.get('id') === payload.postId)

      const commentIndex = state
        .getIn([
          'classes',
          payload.classId,
          'assignments',
          assignmentIndex,
          'posts',
          postIndex,
          'comments',
        ])
        .findIndex(comment => comment.get('id') === payload.comment.id)

      return state.setIn(
        [
          'classes',
          payload.classId,
          'assignments',
          assignmentIndex,
          'posts',
          postIndex,
          'comments',
          commentIndex,
        ],
        new Post(payload.comment)
      )
    } catch (err) {
      return state
    }
  },

  [WEBSOCKET_ASSIGNMENT_COMMENT_UNSHAZAMED]: (state, { payload }) => {
    try {
      const assignmentIndex = state
        .getIn(['classes', payload.classId, 'assignments'])
        .findIndex(assignment => assignment.get('id') === payload.assignmentId)

      const postIndex = state
        .getIn(['classes', payload.classId, 'assignments', assignmentIndex, 'posts'])
        .findIndex(post => post.get('id') === payload.postId)

      const commentIndex = state
        .getIn([
          'classes',
          payload.classId,
          'assignments',
          assignmentIndex,
          'posts',
          postIndex,
          'comments',
        ])
        .findIndex(comment => comment.get('id') === payload.comment.id)

      return state.setIn(
        [
          'classes',
          payload.classId,
          'assignments',
          assignmentIndex,
          'posts',
          postIndex,
          'comments',
          commentIndex,
        ],
        new Post(payload.comment)
      )
    } catch (err) {
      return state
    }
  },

  [VALIDATE_DISCOUNT_COUPON.FULFILLED]: (state, { payload, meta: { classId } }) => {
    const camelizedPayload = humps.camelizeKeys(payload)
    const discountPercentage = camelizedPayload.percentOff
    const discountAmount = camelizedPayload.amountOff

    const discountType = discountAmount ? 'amount' : 'percentage'

    return state
      .setIn(
        ['couponDiscount', classId],
        discountType === 'amount' ? discountAmount : discountPercentage
      )
      .setIn(['couponType', classId], discountType)
  },
  [CLEAR_COUPON_STATE]: (state, { meta: { classId } }) => state.setIn(['couponDiscount', classId], null).setIn(['couponType', classId], ''),

  [GET_CLASSES_NEW.FULFILLED]: (state, { payload }) =>
    state
      .set(
        'classes',
        OrderedMap(payload.results.map(courseClass => [courseClass.id, new Class(courseClass)]))
      )
      .set('next', payload.next ? getPage(payload.next) : undefined)
      .set('previous', payload.previous ? getPage(payload.previous) : undefined)
      .set('count', payload.count),

  [ENROLL.FULFILLED]: state => state,
  [GET_CLASSES_BY_COURSE.FULFILLED]: (state, { payload }) => {
    const classesByCourse = payload.results
    const classesIdsList = payload.results.map(currentClass => currentClass.id)

    const nextUrl = payload.next ? new URL(payload.next) : {}
    const nextPage = fromJS(
      humps.camelizeKeys(queryString.parse(nextUrl.search, { arrayFormat: 'bracket' }))
    )

    const formattedClasses = {}
    Object.keys(classesByCourse).forEach(item => {
      formattedClasses[classesByCourse[item].id] = new Class(classesByCourse[item])
    })

    return state
      .set('classes', OrderedMap(formattedClasses))
      .set('next', nextPage.get('page'))
      .setIn(['search', 'results'], classesIdsList)
      .setIn(['search', 'totalClasses'], payload.count)
  },
  [FOLLOW_LEADER.FULFILLED]: (state, { meta }) => {
    const classId = meta?.classId
    const userId = meta?.userId
    const following = Map({ following: true })

    const instructorIndex = state.getIn(['classes', classId, 'instructors']).findIndex(coach => coach.get('id') === Number(userId))

    return state.mergeIn(['classes', classId, 'instructors', instructorIndex], following)
  },
})

export default classes
