import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import SectionTitle from '_components/landing-components/section-title'
import Button, { ButtonTheme } from '_components/landing-components/button'
import { prismicTitlePropType } from '_utils/proptypes'

import styles from './styles.css'

const NewToLightyearSection = ({ slice, id }) => {
  const backgroundStyles = useMemo(
    () => {
      if (slice.backgroundImage?.thumbnail) {
        return {
          backgroundImage: `url(${slice.backgroundImage.thumbnail})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }
      }
      return {}
    },
    [slice.backgroundImage]
  )

  const titleStyle = useMemo(
    () =>
      slice.titleColor
        ? {
            color: slice.titleColor,
          }
        : {},
    [slice.titleColor]
  )

  const textStyle = useMemo(
    () =>
      slice.textColor
        ? {
            color: slice.textColor,
          }
        : {},
    [slice.textColor]
  )
  return (
    <section className={styles.section} style={backgroundStyles} id={id}>
      <div className={styles.content}>
        {slice.sectionTitle && (
          <SectionTitle style={titleStyle} title={slice.sectionTitle} className={styles.title} />
        )}
        {slice.sectionText && (
          <p className={styles['section-text']} style={textStyle}>
            {slice.sectionText}
          </p>
        )}
        {slice.actionButton?.url && (
          <Button
            url={slice.actionButton.url}
            theme={ButtonTheme.MALIBU}
            className={styles['action-buttons']}
          >
            {slice.actionButton?.label}
          </Button>
        )}
      </div>
    </section>
  )
}

NewToLightyearSection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    actionButton: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
    sectionText: PropTypes.string,
    backgroundImage: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(NewToLightyearSection)
