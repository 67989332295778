import React from 'react'
import PropTypes from 'prop-types'

import CardModal from '_components/modal-card'
import RocketIcon from '_assets/icons/rocket-icon.svg'

import styles from './styles.css'

const EMAIL = 'hello@lightyear.co'
const ForgotYourPasswordModal = ({ isOpen, onClose }) => (
    <CardModal isOpen={isOpen} onClose={onClose} childrenClassName={styles.modal}>
      <svg className={styles['rocket-icon']} viewBox={RocketIcon.viewBox} aria-hidden="true">
        <use xlinkHref={`#${RocketIcon.id}`} />
      </svg>
      <h1 className={styles['modal-title']}>Your reset password email is on its way!</h1>
      <p className={styles.description}>
        If you don&apos;t receive an email within 5 minutes, please email{' '}
        <a className={styles.link} href={`mailto:${EMAIL}`}>
          {EMAIL}
        </a>
      </p>
    </CardModal>
  )

ForgotYourPasswordModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
}

export default React.memo(ForgotYourPasswordModal)
