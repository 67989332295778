import React, { useMemo, useState, useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import useWindowSize from '_hooks/use-window-size'
import { MOBILE_WIDTH, MEDIUM_WIDTH } from '_utils/constants'
import Button, { ButtonTheme } from '_components/landing-components/button'
import { prismicMemberProTypes } from '_utils/proptypes'
import LeaderProfileModal from '_components/landing-components/leader-profile-modal'

import styles from './styles.css'

const MemberCard = ({ member, className }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const { width } = useWindowSize()

  const image = useMemo(
    () => {
      if (width <= MOBILE_WIDTH && member.pictureMobile) {
        return member.pictureMobile
      }
      return member.picture
    },
    [member.picture, member.pictureMobile, width]
  )

  const onToggleModal = useCallback(
    () => {
      setModalOpen(prevState => !prevState)
    },
    [setModalOpen]
  )

  return (
    <article className={classnames(styles.member, className)}>
      {image && (
        <img
          src={image.url}
          className={styles.picture}
          alt={image.alt || `${member.name} picture`}
        />
      )}
      <div className={styles['member-info']}>
        <h1 className={styles['member-name']}>{member.name}</h1>
        <p className={styles['member-position']}>{member.position}</p>
        <Button
          theme={ButtonTheme.MORE}
          className={styles['view-full-profile-button']}
          onClick={onToggleModal}
        >
          {width <= MEDIUM_WIDTH ? 'View Profile' : 'View Full profile'}
        </Button>
      </div>
      <LeaderProfileModal isOpen={isModalOpen} onClose={onToggleModal} member={member} />
    </article>
  )
}

MemberCard.propTypes = {
  member: prismicMemberProTypes.isRequired,
  className: PropTypes.string,
}

MemberCard.defaultProps = {
  className: '',
}

export default React.memo(MemberCard)
