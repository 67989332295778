import { createSelector } from 'reselect'

import { GET_PUBLIC_COACHES } from './actions'

export const publicCoachesListSelector = state => state.publicCoaches.get('coaches')

export const isPublicCoachesLoadingSelector = state =>
  !!state.loading.get(GET_PUBLIC_COACHES.ACTION)

export const getPublicCoachesIdsList = state => state.publicCoaches.getIn(['search', 'results'])

export const publicCoachesList = createSelector(
  publicCoachesListSelector,
  getPublicCoachesIdsList,
  (coachesList, coachesIds) => coachesIds.map(coach => coachesList.get(coach.toString()))
)
