import React from 'react'
import PropTypes from 'prop-types'
import Switch from 'react-switch'

const SwitchButton = ({ className, onChange, checked, disabled }) => (
  <Switch
    className={className}
    onChange={onChange}
    checked={checked}
    checkedIcon={false}
    uncheckedIcon={false}
    offColor="#bbbcc3"
    onColor="#2bc989"
    height={24}
    width={40}
    handleDiameter={20}
    disabled={disabled}
  />
)

SwitchButton.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  checked: PropTypes.bool,
  disabled: PropTypes.bool,
}

SwitchButton.defaultProps = {
  className: '',
  onChange: () => {},
  checked: false,
  disabled: false,
}

export default SwitchButton
