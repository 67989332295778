import React, { useCallback, useReducer } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classnames from 'classnames'
import { parseISO } from 'date-fns'

import Input, { INPUT_MASKS } from '_components/landing-components/input'
import { goalShape } from '_utils/proptypes'
import ErrorTooltip from '_components/error-tooltip'
import SwitchButton from '_components/switch-button'

import ModalHeader from '../modal-header'
import ModalFooter from '../modal-footer'

import styles from './styles.css'

const initialState = {
  description: '',
  dueDate: '',
  private: false,
}

const INPUT_CHANGE = 'INPUT_CHANGE'
const INPUT_NAMES = {
  dueDate: 'dueDate',
  description: 'description',
}

const reducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE: {
      return {
        ...state,
        [action.name]: action.value,
        errors: {
          ...state.errors,
          ...action.errors,
        },
      }
    }
    default:
      return state
  }
}

const AddOrEditGoals = ({ edit, onSubmit, onClose, loading, editedGoal, categoryId }) => {
  const [state, dispatch] = useReducer(
    reducer,
    edit
      ? {
          ...editedGoal,
          dueDate: moment(new Date(parseISO(editedGoal.dueDate))).format('MM/DD/YYYY'),
          errors: { ...initialState },
        }
      : {
          ...initialState,
          errors: { ...initialState },
        }
  )

  const onInputChange = useCallback(event => {
    event.preventDefault()
    const { name, value } = event.target

    const errors = {}

    if (name === INPUT_NAMES.description && value !== '') {
      errors.description = ''
    }

    if (name === INPUT_NAMES.dueDate && value !== '') {
      errors.dueDate = ''
    }

    dispatch({ type: INPUT_CHANGE, name, value, errors })
  }, [])

  const onCheckboxChange = useCallback(
    () => {
      dispatch({ type: INPUT_CHANGE, name: 'private', value: !state.private })
    },
    [state.private]
  )

  const validadeOnSubmit = useCallback(
    () => {
      const errors = { description: '', dueDate: '' }
      if (state.description === '') {
        errors.description = 'Please enter a goal description.'
      }
      if (!moment(new Date(state.dueDate)).isValid()) {
        errors.dueDate = 'Please set a date in the format MM/DD/YYYY.'
      } else if (moment(new Date(state.dueDate)).isSameOrBefore(moment(), 'days')) {
        errors.dueDate = 'Please set a date that is further in the future.'
      }

      dispatch({ type: INPUT_CHANGE, errors })

      return Object.values(errors).every(value => value.length === 0)
    },
    [state]
  )

  const onFormSubmit = useCallback(
    event => {
      event.preventDefault()
      if (validadeOnSubmit()) {
        const { errors, ...data } = state
        onSubmit(categoryId, { ...data, dueDate: moment(data.dueDate).format('YYYY-MM-DD') }, edit)
      }
    },
    [categoryId, edit, onSubmit, state, validadeOnSubmit]
  )

  return (
    <form className={styles.container} onSubmit={onFormSubmit}>
      <ModalHeader title={edit ? 'EDIT GOAL' : 'ADD A GOAL'} onClose={onClose} />
      <div className={styles.content}>
        <div className={styles['text-area']}>
          <p className={styles.label}>Description</p>
          {!!state.errors.description && (
            <ErrorTooltip className={styles['error-icon']} message={state.errors.description} />
          )}
          <textarea
            className={classnames(styles['description-content'], {
              [styles.error]: !!state.errors.description,
            })}
            placeholder=""
            name={INPUT_NAMES.description}
            onChange={onInputChange}
            value={state.description}
            disabled={loading}
          />
        </div>
        <Input
          name={INPUT_NAMES.dueDate}
          id={INPUT_NAMES.dueDate}
          value={state.dueDate}
          onChange={onInputChange}
          placeholder="MM/DD/YYYY"
          mask={INPUT_MASKS.DATE}
          label="Due date"
          error={state.errors.dueDate}
          helperText={state.errors.dueDate}
        />
        <div className={styles.checkbox}>
          <p className={styles.label}>Private</p>
          <SwitchButton onChange={onCheckboxChange} checked={state.private} />
        </div>
      </div>
      <ModalFooter loading={loading} buttonLabel="Save Changes" />
    </form>
  )
}

AddOrEditGoals.propTypes = {
  edit: PropTypes.bool,
  editedGoal: goalShape,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  categoryId: PropTypes.number.isRequired,
}

AddOrEditGoals.defaultProps = {
  loading: false,
  edit: false,
  onClose: () => {},
  onSubmit: () => {},
  editedGoal: {},
}

export default AddOrEditGoals
