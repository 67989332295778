import React from 'react'
import PropTypes from 'prop-types'
import ReactPlayer from 'react-player'

import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

const videoConfig = { vimeo: { preload: true } }

const WELCOME_VIDEO_URL = 'https://player.vimeo.com/video/490357770'

const FirstStep = ({ onContinueClick }) => (
    <div className={styles.section}>
      <h1 className={styles.title}>Welcome to Lightyear!</h1>
      <div className={styles.video}>
        <ReactPlayer
          config={videoConfig}
          url={WELCOME_VIDEO_URL}
          width="100%"
          height="100%"
          controls
        />
      </div>
      <p className={styles.description}>
        We are so thrilled you are here! Take a moment to fill out your profile so we can get to
        know you {'\uD83D\uDE42'}Then, get to exploring and connecting with the community and
        Lightyear curriculum!
      </p>
      <Button
        className={styles['continue-button']}
        theme={ButtonTheme.PRIMARY}
        onClick={onContinueClick}
        label="Continue"
      />
    </div>
  )

FirstStep.propTypes = {
  onContinueClick: PropTypes.func.isRequired,
}

export default React.memo(FirstStep)
