import React, { PureComponent, createRef } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classnames from 'classnames'
import { toast } from 'react-toastify'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import InputTrigger from 'react-input-trigger'
import debounce from 'lodash.debounce'
import Textarea from 'react-textarea-autosize'

import TaggingUserList from '_components/tagging-user'
import AddPhotoIcon from '_assets/icons/addphoto-24-px.svg'
import Modal from '_components/modal'
import AddVideoModal from '_components/add-video-modal'
import Button, { ButtonTheme } from '_components/button'
import VideoIcon from '_assets/icons/add-video-24-px.svg'
import PhotoIcon from '_assets/icons/photo.svg'
import CloseIcon from '_assets/icons/close.svg'
import { onMouseDown } from '_utils/aria'
import Toastr, { ToastrTheme } from '_components/toastr'
import POST_ACTIONS from '_modules/post/actions'
import ASSIGNMENT_ACTIONS from '_modules/assignment/actions'
import { Suggestion } from '_models/'
import { searchSuggestions } from '_modules/suggestion/actions'
import { MEDIUM_WIDTH } from '_utils/constants'
import { userShape } from '_utils/proptypes'

import styles from './styles.css'

const BACKSPACE = 8

const mapStateToProps = ({ loading, error, suggestion }) => ({
  isPosting:
    !!loading.get(POST_ACTIONS.CREATE_POST.ACTION) ||
    !!loading.get(ASSIGNMENT_ACTIONS.CREATE_POST.ACTION),
  postingError:
    error.get(POST_ACTIONS.CREATE_POST.ACTION) || error.get(ASSIGNMENT_ACTIONS.CREATE_POST.ACTION),
  suggestedUser: suggestion.results.toJS(),
})

const mapDispatchToProps = {
  showSuggestions: searchSuggestions,
}

const trigger = {
  keyCode: 50,
  shiftKey: typeof window !== 'undefined' && window.innerWidth > MEDIUM_WIDTH,
}
class CreatePost extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    onPostClick: PropTypes.func,
    isPosting: PropTypes.bool,
    postingError: ImmutablePropTypes.map,
    suggestedUser: PropTypes.oneOfType([
      PropTypes.instanceOf(Suggestion),
      PropTypes.arrayOf(userShape),
    ]).isRequired,
    showSuggestions: PropTypes.func.isRequired,
  }

  static defaultProps = {
    className: '',
    title: 'Create a Post',
    placeholder: `What's on your mind?`,
    onPostClick: () => {},
    isPosting: false,
    postingError: Map(),
  }

  constructor(props) {
    super(props)

    this.messageRef = createRef()
    this.suggestionRef = createRef()
    this.buttonRef = createRef()

    this.setUsernameDebounced = debounce(this.props.showSuggestions, 250)

    this.state = {
      message: '',
      fileName: null,
      isVideoModalOpen: false,
      videoLink: null,
      haveImageOrVideo: false,
      photo: null,
      showSuggestor: false,
      characterPosition: 0,
      username: '',
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.isPosting && !this.props.isPosting && this.props.postingError.size === 0) {
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        message: '',
        fileName: null,
        isVideoModalOpen: false,
        videoLink: null,
        haveImageOrVideo: false,
        photo: null,
        username: '',
      })
    }

    if (prevState.showSuggestor && !this.state.showSuggestor) {
      this.messageRef.current.focus()
      document.removeEventListener('click', this.handleOutsideClick, false)
    }

    if (!prevState.showSuggestor && this.state.showSuggestor) {
      document.addEventListener('click', this.handleOutsideClick, false)
    }
    if (this.state.showSuggestor && prevState.username !== this.state.username) {
      this.setUsernameDebounced(this.state.username)
    }
  }

  onChange = event => {
    const { value, selectionStart } = event.target
    const { showSuggestor, message, characterPosition } = this.state
    this.setState({
      message: value,
      characterPosition: selectionStart,
    })

    if (showSuggestor) {
      if (value.charAt(characterPosition) !== '@' && message.length !== selectionStart) {
        this.setState(prevState => {
          if (value[characterPosition]) {
            return {
              username: prevState.username + value[characterPosition],
            }
          }

          return {
            username: prevState.username,
          }
        })
      }
    } else if (!showSuggestor) {
      this.setState({
        username: '',
      })
    }
  }

  onUploadImage = event => {
    const { files, value } = event.target

    const filePath = value
    const allowedExtensions = /(\.jpg|\.jpeg|\.png|\.gif|\.bmp)$/i

    if (!allowedExtensions.exec(filePath)) {
      toast(<Toastr theme={ToastrTheme.ERROR} content="Please select an image file." />)
    } else if (files && files[0]) {
      this.setState({
        photo: files[0],
        fileName: files[0].name,
        haveImageOrVideo: true,
      })
    }
  }

  onRemoveAsset = () => {
    this.setState({
      photo: null,
      fileName: null,
      videoLink: null,
      haveImageOrVideo: false,
    })
  }

  onAddVideo = video => {
    this.setState({
      videoLink: video,
      haveImageOrVideo: true,
    })
  }

  onCreatePostClick = event => {
    event.preventDefault()

    const { message, videoLink, photo, haveImageOrVideo } = this.state

    if (!haveImageOrVideo && !message.length) {
      toast(
        <Toastr
          theme={ToastrTheme.ERROR}
          content="Please enter some text into the post box or attach an image."
        />
      )

      return
    }

    this.props.onPostClick({
      message,
      videoLink,
      photo,
    })
  }

  setRef = ref => {
    this.messageRef.current = ref
  }

  setSuggestionRef = ref => {
    this.suggestionRef.current = ref
  }

  setButtonRef = ref => {
    this.buttonRef.current = ref
  }

  setInputTriggerRef = () => this.messageRef

  checkBackSpace = event => {
    const { username, showSuggestor, message, characterPosition } = this.state

    if (event.keyCode === BACKSPACE && showSuggestor) {
      const deletedValue = username.substring(0, username.length - 1)
      this.setState({
        username: deletedValue,
      })

      if (message[characterPosition - 2] === '@') {
        this.setState({
          username: deletedValue,
        })
      }
    }
  }

  toggleVideoModal = () => {
    this.setState(prevState => ({
      isVideoModalOpen: !prevState.isVideoModalOpen,
    }))
  }

  toggleSuggestor = metaInformation => {
    const { hookType } = metaInformation
    const { message, characterPosition } = this.state
    const mobileOrDesktop = window.innerWidth < MEDIUM_WIDTH ? 0 : 1

    if (hookType === 'start') {
      if (
        message[characterPosition - mobileOrDesktop - 1] === ' ' ||
        characterPosition === mobileOrDesktop
      ) {
        this.props.showSuggestions()
        this.setState({
          showSuggestor: true,
        })
      } else {
        this.endHandler()
        this.setState({
          showSuggestor: false,
        })
      }
    } else if (hookType === 'cancel') {
      this.setState({
        showSuggestor: false,
      })
    }
  }

  handleTextareaInput = event => {
    event.preventDefault()
    const { value } = event.currentTarget
    const { characterPosition, message, username } = this.state
    const startTagging = characterPosition - username.length
    const newMessage = `${message.substring(0, startTagging)}${value} ${message.substring(
      characterPosition
    )}`

    if (this.messageRef.current) {
      this.messageRef.current.selectionStart = characterPosition + value.length + 1
    }

    this.setState(prevState => ({
      message: newMessage,
      showSuggestor: false,
      username: '',
      characterPosition: prevState.characterPosition + value.length + 1,
    }))
    this.endHandler()
  }

  handleOutsideClick = e => {
    if (this.suggestionRef.current && this.suggestionRef.current.contains(e.target)) {
      return
    }

    this.setState({
      showSuggestor: false,
    })

    this.endHandler()
  }

  handleEndTrigger = ref => {
    this.endHandler = ref
  }

  render() {
    const { className, title, placeholder, isPosting, suggestedUser } = this.props
    const {
      message,
      fileName,
      isVideoModalOpen,
      haveImageOrVideo,
      videoLink,
      showSuggestor,
    } = this.state

    return (
      <div className={classnames(styles.container, className)}>
        <p className={styles.title}>{title}</p>
        <InputTrigger
          trigger={trigger}
          onStart={this.toggleSuggestor}
          onCancel={this.toggleSuggestor}
          endTrigger={this.handleEndTrigger}
          className={styles.content}
          inputRef={this.setInputTriggerRef}
        >
          <Textarea
            id="textarea-create-post"
            className={styles['post-content']}
            placeholder={placeholder}
            onChange={this.onChange}
            value={message}
            inputRef={this.setRef}
            onKeyDown={this.checkBackSpace}
          />
        </InputTrigger>
        {showSuggestor && (
          <TaggingUserList
            suggestedUser={suggestedUser}
            buttonRef={this.setButtonRef}
            handleTextareaInput={this.handleTextareaInput}
            suggestionRef={this.setSuggestionRef}
            className={styles['tagging-user']}
          />
        )}
        <div className={classnames(styles.actions, { [styles['with-picture']]: haveImageOrVideo })}>
          {haveImageOrVideo && (
            <div
              className={classnames(styles['uploaded-picture-container'], {
                [styles['uploaded-video-container']]: videoLink,
              })}
            >
              {fileName && (
                <svg aria-label="Button icon" role="img" viewBox={PhotoIcon.viewBox}>
                  <use xlinkHref={`#${PhotoIcon.id}`} />
                </svg>
              )}
              {videoLink ? (
                <a
                  className={styles.asset}
                  href={videoLink}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {videoLink}
                </a>
              ) : (
                <p className={styles.asset}>{fileName}</p>
              )}
              <button
                onClick={this.onRemoveAsset}
                aria-label="close button"
                onMouseDown={onMouseDown}
                className={styles.button}
                disabled={isPosting}
              >
                <svg viewBox={CloseIcon.viewBox} aria-hidden="true">
                  <use xlinkHref={`#${CloseIcon.id}`} />
                </svg>
              </button>
            </div>
          )}
          {!haveImageOrVideo && (
              <>
                <Button
                  theme={ButtonTheme.DEFAULT}
                  icon={VideoIcon}
                  iconOnly
                  label="Add Video"
                  onClick={this.toggleVideoModal}
                  tooltip
                />
                <Button
                  theme={ButtonTheme.DEFAULT}
                  icon={AddPhotoIcon}
                  uploadImage
                  label="Add Photo"
                  onUploadImage={this.onUploadImage}
                  tooltip
                />
              </>
            )}
            <Button
              theme={ButtonTheme.PRIMARY}
              label="Post"
              onClick={this.onCreatePostClick}
              loading={isPosting}
            />
        </div>
        {isVideoModalOpen && (
          <Modal isOpen onClose={this.toggleVideoModal} className={styles['video-modal']}>
            <AddVideoModal onCloseModal={this.toggleVideoModal} onAddClick={this.onAddVideo} />
          </Modal>
        )}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePost)
