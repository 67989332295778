import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button, { ButtonTheme } from '_components/button'
import VideoCall from '_assets/icons/videocall.svg'
import { MessageTheme } from '_utils/constants'

import styles from './styles.css'

const ZoomMessage = ({ theme, meeting, endMeeting, loading }) => {
  const endMeetingClick = useCallback(() => endMeeting({ meetingId: meeting.id }), [
    endMeeting,
    meeting.id,
  ])

  const renderMeetingLink = useMemo(
    () => {
      if (meeting.ended) {
        return null
      }

      return (
        <a href={meeting.joinUrl} target="_blank" rel="noopener noreferrer">
          {meeting.joinUrl}
        </a>
      )
    },
    [meeting.ended, meeting.joinUrl]
  )

  const meetingTitle = useMemo(
    () => (meeting.ended ? `${meeting.topic} video call has ended.` : meeting.topic),
    [meeting.ended, meeting.topic]
  )

  const renderActions = useMemo(
    () => {
      if (meeting.ended) {
        return null
      }

      if (theme === MessageTheme.OWN_MESSAGE) {
        return (
          <Button
            className={styles.button}
            label="End meeting"
            theme={ButtonTheme.PRIMARY}
            onClick={endMeetingClick}
            loading={loading}
          />
        )
      }

      return (
        <a
          href={meeting.joinUrl}
          target="_blank"
          rel="noopener noreferrer"
          className={classnames(styles.button, styles.link)}
          label="Join"
        >
          JOIN
        </a>
      )
    },
    [endMeetingClick, loading, meeting.ended, meeting.joinUrl, theme]
  )

  return (
    <div
      className={classnames(styles.message, {
        [styles['member-message']]: theme === MessageTheme.USER_MESSAGE,
        [styles.ended]: meeting.ended,
      })}
    >
      <svg
        aria-label="Video Call Icon"
        className={styles.icon}
        viewBox={VideoCall.viewBox}
        role="img"
      >
        <use xlinkHref={`#${VideoCall.id}`} />
      </svg>
      <h3>{meetingTitle}</h3>
      {renderMeetingLink}
      {renderActions}
    </div>
  )
}

ZoomMessage.propTypes = {
  meeting: PropTypes.shape({
    id: PropTypes.number,
    topic: PropTypes.string,
    startTime: PropTypes.string,
    duration: PropTypes.number,
    joinUrl: PropTypes.string,
    ended: PropTypes.bool,
  }).isRequired,
  theme: PropTypes.string.isRequired,
  endMeeting: PropTypes.func,
  loading: PropTypes.bool,
}

ZoomMessage.defaultProps = {
  endMeeting: () => {},
  loading: false,
}

export default ZoomMessage
