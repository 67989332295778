import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import FileIcon from '_assets/icons/file.svg'
import Button, { ButtonTheme } from '_components/landing-components/button'
import useWindowSize from '_hooks/use-window-size'
import ResourcesModal from '_components/class-attachments/resources-modal'
import ActionCard, { ACTION_CARD_TYPE } from '_components/action-card'
import useModal from '_hooks/use-modal'
import useBodyOverflow from '_hooks/use-body-overflow'

import styles from './styles.css'
import ClassAttachmentsList from './class-attachments-list'

export const CLASS_ATTACHMENTS_THEME = {
  DEFAULT: 'default',
  CARD_ON_MOBILE: 'card-on-mobile',
}

const ClassAttachments = ({ attachmentList, listClassName, theme }) => {
  const { isMobile } = useWindowSize()
  const [isResourcesModalOpen, onToggleResourcesModal] = useModal()
  useBodyOverflow(isResourcesModalOpen)
  const handleList = useMemo(() => attachmentList?.slice(0, 4), [attachmentList])

  const attachmentListSize = useMemo(
    () => `${attachmentList.length} file${attachmentList.length === 1 ? '' : 's'}`,
    [attachmentList.length]
  )

  if (isMobile && theme === CLASS_ATTACHMENTS_THEME.CARD_ON_MOBILE) {
    return (
      <>
        <ActionCard
          icon={FileIcon}
          onCardClick={onToggleResourcesModal}
          title="Attachments"
          type={ACTION_CARD_TYPE.TOGGLE}
          information={attachmentListSize}
        />
        {isResourcesModalOpen && (
          <ResourcesModal attachmentsList={attachmentList} onClose={onToggleResourcesModal} />
        )}
      </>
    )
  }

  return (
    <section>
      <h1 className={styles['class-attachments-title']}>Attachments</h1>
      <p className={styles['attachment-quantity']}>{attachmentListSize}</p>
      {attachmentListSize.length > 0 && (
        <ClassAttachmentsList fileList={handleList} className={listClassName} />
      )}

      {attachmentList?.length > 4 && (
        <Button
          className={styles['view-all-button']}
          onClick={onToggleResourcesModal}
          theme={ButtonTheme.TRANSPARENT_GRAY}
        >
          View all
        </Button>
      )}

      {isResourcesModalOpen && (
        <ResourcesModal attachmentsList={attachmentList} onClose={onToggleResourcesModal} />
      )}
    </section>
  )
}

ClassAttachments.propTypes = {
  attachmentList: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      filename: PropTypes.string,
    })
  ),
  listClassName: PropTypes.string,
  theme: PropTypes.oneOf(Object.values(CLASS_ATTACHMENTS_THEME)),
}

ClassAttachments.defaultProps = {
  attachmentList: [],
  listClassName: '',
  theme: CLASS_ATTACHMENTS_THEME.DEFAULT,
}

export default ClassAttachments
