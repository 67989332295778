import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { useLocation } from '@reach/router'

import SectionTitle from '_components/landing-components/section-title'
import Button from '_components/landing-components/button'
import { prismicTitlePropType } from '_utils/proptypes'
import SectionContent from '_components/landing-components/section-content'
import { enrollRedirectUrl } from '_utils/enroll-course-redirect'
import { authTokenSelector, hasCompletedOnboardingSelector } from '_modules/user/selectors'
import { formatPathname } from '_utils/location'
import { getSlugSelector } from '_modules/prismic/selectors'
import { courseClassesSelector } from '_modules/public-courses/selectors'
import { getCourseBySlug } from '_modules/course/selectors'

import styles from './styles.css'

const JoinUsToday = ({ slice, id }) => {
  const { pathname } = useLocation()

  const authToken = useSelector(authTokenSelector)
  const hasUserCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)
  const currentView = useMemo(() => formatPathname(pathname), [pathname])
  const courseSlug = useSelector(state => getSlugSelector(state, currentView))
  const classes = useSelector(courseClassesSelector)
  const course = useSelector(state => getCourseBySlug(state, courseSlug))

  const isUserEnrolledInClass = useMemo(() => course?.classes?.some(item => !!item.enrolled), [
    course,
  ])

  const backgroundStyles = useMemo(
    () => {
      if (slice.backgroundImage?.thumbnail) {
        return {
          backgroundImage: `url(${slice.backgroundImage.thumbnail})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }
      }
      return {}
    },
    [slice.backgroundImage]
  )

  const textStyle = useMemo(
    () =>
      slice.textColor
        ? {
            color: slice.textColor,
          }
        : {},
    [slice.textColor]
  )

  const firstAvailableClassId = useMemo(() => classes?.[0]?.firstAvailableClass?.get('id'), [
    classes,
  ])

  const buttonLabel = useMemo(
    () => {
      if (isUserEnrolledInClass) {
        return 'VIEW COURSE'
      }

      if (slice.actionButton?.label) {
        return slice.actionButton?.label
      }

      return 'Enroll Now'
    },
    [isUserEnrolledInClass, slice.actionButton]
  )

  const buttonAction = useMemo(
    () => {
      if (isUserEnrolledInClass) {
        return `/class/${firstAvailableClassId}`
      }

      if (authToken) {
        return enrollRedirectUrl({
          authToken,
          classId: firstAvailableClassId,
          hasCompletedOnboarding: hasUserCompletedOnboarding,
        })
      }

      return '?sign-up'
    },
    [authToken, firstAvailableClassId, hasUserCompletedOnboarding, isUserEnrolledInClass]
  )
  return (
    <section className={styles.section} style={backgroundStyles} id={id}>
      <div className={styles.content}>
        {slice.sectionTitle && <SectionTitle title={slice.sectionTitle} className={styles.title} />}
        {slice?.content?.map((paragraph, index) => (
          <SectionContent
            // setting key as index since this array is not going to be changed
            key={`content-${index + 1}`}
            text={paragraph}
            className={styles['section-content']}
            textClassName={classnames(styles['content-text'])}
          />
        ))}
        {slice.sectionText && (
          <p className={styles['section-text']} style={textStyle}>
            {slice.sectionText}
          </p>
        )}
        <Button url={buttonAction} className={styles['action-buttons']}>
          {buttonLabel}
        </Button>
      </div>
    </section>
  )
}

JoinUsToday.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    actionButton: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
    sectionText: PropTypes.string,
    backgroundImage: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
    textColor: PropTypes.string,
    titleColor: PropTypes.string,
    content: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(JoinUsToday)
