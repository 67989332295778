import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import Helmet from 'react-helmet'

import LightyearLogo from '_assets/full-logo.svg'
import Button, { ButtonTheme } from '_components/button'
import authRoute from '_hocs/auth-route'
import { logout } from '_modules/user/actions'
import favicon16 from '_assets/public/favicon-16x16.png'

import styles from './styles.css'

class Onboarding extends PureComponent {
  static propTypes = {
    children: PropTypes.node.isRequired,
    location: PropTypes.shape().isRequired,
    logout: PropTypes.func.isRequired,
  }

  componentDidUpdate(prevProps) {
    const { location } = this.props

    if (location.key !== prevProps.location.key) {
      window.scrollTo(0, 0)
    }
  }

  render() {
    const { children } = this.props
    return (
      <div className={styles.container}>
        <Helmet
          title="Lightyear"
          link={[{ rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16 }]}
        />
        <div className={styles.content}>
          <div className={styles.header}>
            <svg
              className={styles.logo}
              viewBox={LightyearLogo.viewBox}
              aria-label="Lightyear logo"
              role="img"
            >
              <use xlinkHref={`#${LightyearLogo.id}`} />
            </svg>
            <Button
              className={styles['header-button']}
              theme={ButtonTheme.DEFAULT}
              label="LOG OUT"
              onClick={this.props.logout}
            />
          </div>
          <div className={styles.main}>{children}</div>
        </div>
      </div>
    )
  }
}

const mapDispatchToProps = { logout }

export default connect(
  null,
  mapDispatchToProps
)(authRoute(Onboarding))
