import React, { useMemo } from 'react'
import Slider from 'react-slick'

import CourseTypeCard from '_components/landing-components/course-type-card'
import { COURSE_TYPES, CORE_PROGRAMS } from '_constants/course'
import useWindowSize from '_hooks/use-window-size'
import SliderArrow, { ARROW_DIRECTION } from '_components/slider-arrow'

import styles from './styles.css'

const availableCourseTypes = Object.values(CORE_PROGRAMS)
const SLIDES_TO_SHOW_SCROLL = {
  MEDIUM: 2,
  MOBILE: 1,
}

const CourseTypesElements = () => {
  const { isMedium, isMobile } = useWindowSize()

  const settings = useMemo(
    () => ({
      infinite: true,
      speed: 500,
      slidesToShow: isMedium ? SLIDES_TO_SHOW_SCROLL.MEDIUM : SLIDES_TO_SHOW_SCROLL.MOBILE,
      slidesToScroll: isMedium ? SLIDES_TO_SHOW_SCROLL.MEDIUM : SLIDES_TO_SHOW_SCROLL.MOBILE,
      nextArrow: (
        <SliderArrow
          arrowDirection={ARROW_DIRECTION.RIGHT}
          shouldUseLibClassName
          iconClassName={styles['arrow-icon']}
        />
      ),
      prevArrow: (
        <SliderArrow
          arrowDirection={ARROW_DIRECTION.LEFT}
          shouldUseLibClassName
          iconClassName={styles['arrow-icon']}
        />
      ),
    }),
    [isMedium]
  )

  if (isMedium || isMobile) {
    return (
      <Slider {...settings} className={styles['slider-wrapper']}>
        {availableCourseTypes.map(
          course =>
            course.slug !== COURSE_TYPES.CORE_WORKSHOPS && (
              <CourseTypeCard
                className={styles[course.className]}
                title={course.name}
                description={course.description}
                key={course.name}
                tags={course.courseTags.GENERAL_TAGS}
                type={course.slug}
                url={course.path}
                isSliderItem
              />
            )
        )}
      </Slider>
    )
  }

  return (
    <div className={styles['courses-wrapper']}>
      {availableCourseTypes.map(
        course =>
          course.slug !== COURSE_TYPES.CORE_WORKSHOPS && (
            <CourseTypeCard
              className={styles[course.className]}
              title={course.name}
              description={course.description}
              key={course.name}
              tags={course.courseTags.GENERAL_TAGS}
              type={course.slug}
              url={course.path}
            />
          )
      )}
    </div>
  )
}

export default React.memo(CourseTypesElements)
