import React, { useMemo, useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SectionContent from '_components/landing-components/section-content'
import SectionTitle from '_components/landing-components/section-title'
import {
  prismicTitlePropType,
  prismicContentPropType,
  prismicImagePropTypes,
  prismicMemberProTypes,
} from '_utils/proptypes'
import useWindowSize from '_hooks/use-window-size'
import { MOBILE_WIDTH, MEDIUM_WIDTH } from '_utils/constants'
import Button, { ButtonTheme } from '_components/landing-components/button'
import LeaderProfileModal from '_components/landing-components/leader-profile-modal'

import styles from './styles.css'

const AboutSusanne = ({ slice, id }) => {
  const [isModalOpen, setModalOpen] = useState(false)

  const { width } = useWindowSize()

  const hasContent = useMemo(() => slice.content?.length > 0, [slice.content])

  const image = useMemo(
    () => {
      if (width <= MOBILE_WIDTH && slice.contentImageMobile) {
        return slice.contentImageMobile
      }

      if (width <= MEDIUM_WIDTH && slice.contentImageTablet) {
        return slice.contentImageTablet
      }

      return slice.contentImage
    },
    [slice.contentImage, slice.contentImageMobile, slice.contentImageTablet, width]
  )

  const onToggleModal = useCallback(() => {
    setModalOpen(prevState => !prevState)
  }, [])

  return (
    <section className={styles.section} id={id}>
      <div className={styles.container}>
        {slice.sectionTitle && (
          <SectionTitle
            title={slice.sectionTitle}
            className={styles.title}
            boldClassName={styles['bold-text']}
          />
        )}
        {hasContent && (
          <div className={styles.content}>
            {slice.content.map((paragraph, index) => (
              <SectionContent
                // setting key as index since this array is not going to be changed
                key={`content-${index + 1}`}
                text={paragraph}
                className={classnames({ [styles.break]: index > 0 })}
              />
            ))}
            <Button
              theme={ButtonTheme.MORE}
              className={styles['read-more-button']}
              onClick={onToggleModal}
            >
              Read More
            </Button>
          </div>
        )}
        {image && (
          <img src={image.url} className={styles.picture} alt={image.alt || 'Our Shareback'} />
        )}
      </div>
      <LeaderProfileModal isOpen={isModalOpen} onClose={onToggleModal} member={slice.member} />
    </section>
  )
}

AboutSusanne.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    content: prismicContentPropType,
    contentImage: prismicImagePropTypes,
    contentImageTablet: prismicImagePropTypes,
    contentImageMobile: prismicImagePropTypes,
    member: prismicMemberProTypes,
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(AboutSusanne)
