import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import VisaLogo from '_assets/icons/visa.png'
import DinersLogo from '_assets/icons/diners.png'
import DiscoverLogo from '_assets/icons/discover.png'
import EmptyLogo from '_assets/icons/empty.png'
import MastercardLogo from '_assets/icons/mastercard.png'
import JBCLogo from '_assets/icons/jbc.png'
import noOutlineAction from '_hocs/no-outline-action'

import styles from './styles.css'

const VISA = 'Visa'
const DINERS = 'Diners'
const DISCOVER = 'Discover'
const MASTERCARD = 'Mastercard'
const JBC = 'Jbc'

const BrandIcon = brandIcon => {
  switch (brandIcon) {
    case VISA:
      return VisaLogo
    case DINERS:
      return DinersLogo
    case DISCOVER:
      return DiscoverLogo
    case MASTERCARD:
      return MastercardLogo
    case JBC:
      return JBCLogo
    default:
      return EmptyLogo
  }
}

const CreditCard = ({
  cardNumber,
  expirationDate,
  isDefault,
  onRemoveClick,
  onDefaultClick,
  onCardClick,
  brandIcon,
  cardId,
}) => {
  const onClick = useCallback(
    event => {
      event.preventDefault()
      onCardClick(cardId)
    },
    [cardId, onCardClick]
  )

  const onRemove = useCallback(
    event => {
      event.stopPropagation()
      onRemoveClick(cardId)
    },
    [cardId, onRemoveClick]
  )
  const onDefault = useCallback(
    event => {
      event.stopPropagation()
      onDefaultClick(cardId)
    },
    [cardId, onDefaultClick]
  )

  return (
    <div
      role="button"
      tabIndex="0"
      className={styles.container}
      onClick={onClick}
      onKeyPress={onCardClick}
    >
      <div className={styles.info}>
        <p>{cardNumber}</p>
        <p>{expirationDate}</p>
      </div>
      <div className={styles.footer}>
        <div className={styles.actions}>
          {isDefault ? (
            <p className={styles.text}>DEFAULT</p>
          ) : (
            <>
              <button type="button" className={styles['make-default']} onClick={onDefault}>
                MAKE DEFAULT
              </button>
              <button type="button" className={styles['remove-card']} onClick={onRemove}>
                REMOVE
              </button>
            </>
          )}
        </div>
        <img src={BrandIcon(brandIcon)} alt="Card brand icon" className={styles.brand} />
      </div>
    </div>
  )
}

CreditCard.propTypes = {
  cardNumber: PropTypes.string,
  expirationDate: PropTypes.string,
  isDefault: PropTypes.bool,
  onRemoveClick: PropTypes.func,
  onDefaultClick: PropTypes.func,
  onCardClick: PropTypes.func,
  brandIcon: PropTypes.string,
  cardId: PropTypes.string,
}

CreditCard.defaultProps = {
  cardNumber: '',
  expirationDate: '',
  isDefault: false,
  onRemoveClick: () => {},
  onDefaultClick: () => {},
  onCardClick: () => {},
  brandIcon: '',
  cardId: null,
}

export default noOutlineAction(CreditCard)
