import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import humps from 'humps'

import {
  userConnected,
  userDisconnected,
  directMessageCreated,
  channelMessageCreated,
  channelDisabled,
  channelEnabled,
  channelMessageDeleted,
  newNotification,
  newAlert,
  expiringAlert,
  userSuspended,
  userUnsuspended,
  userRemovedFromChannel,
  makeAdmin,
  revokeAdmin,
  updateChannelMeeting,
  updateMessageMeeting,
  addUserToChannel,
  blockUser,
  deleteNotification,
  videoAssignmentStarting,
  videoAssignmentStarted,
  videoAssignmentEnded,
  newPost,
  newComment,
  postUpdate,
  commentUpdate,
  shazamPost,
  unshazamPost,
  shazamComment,
  unshazamComment,
  newAssignmentPost,
  newAssignmentComment,
  assignmentPostUpdate,
  assignmentCommentUpdate,
  assignmentPostShazam,
  assignmentPostUnshazam,
  assignmentCommentShazam,
  assignmentCommentUnshazam,
  finishLiveCall,
} from '_modules/websocket/actions'
import { WSS_URL } from '_config/environment'

const MESSAGE_TYPE = {
  DIRECT_MESSAGE_CREATED: 'direct_message_created',
  CHANNEL_MESSAGE_CREATED: 'channel_message_created',
  CHANNEL_MESSAGE_DELETED: 'channel_message_deleted',
  CHANNEL_DISABLED: 'channel_disabled',
  CHANNEL_ENABLED: 'channel_enabled',
  REMOVED_FROM_CHANNEL: 'removed_from_channel',
  ADDED_TO_CHANNEL: 'added_to_channel',
  MAKE_ADMIN: 'make_admin',
  REVOKE_ADMIN: 'revoke_admin',
  CHANNEL_MESSAGE_UPDATED: 'channel_message_updated', // Called when a meeting ends
  DIRECT_MESSAGE_UPDATED: 'direct_message_updated', // Called when a meeting ends
  NEW_ALERT: 'new_alert',
  EXPIRING_ALERT: 'expiring_alert',
  USER_ONLINE: 'user_online',
  USER_OFFLINE: 'user_offline',
  NOTIFICATION_CREATED: 'notification_created',
  SUSPENDED: 'suspended',
  UNSUSPENDED: 'unsuspended',
  BLOCK_USER: 'blocked',
  NOTIFICATION_DELETED: 'notification_deleted',
  VIDEO_ASSIGNMENT_STARTING: 'video_assignment_starting',
  VIDEO_ASSIGNMENT_STARTED: 'video_assignment_started',
  VIDEO_ASSIGNMENT_ENDED: 'video_assignment_ended',
  NEW_POST: 'new_post',
  NEW_COMMENT: 'new_comment',
  POST_UPDATED: 'post_updated',
  COMMENT_UPDATED: 'comment_updated',
  SHAZAM_POST: 'shazam_post',
  UNSHAZAM_POST: 'unshazam_post',
  SHAZAM_COMMENT: 'shazam_comment',
  UNSHAZAM_COMMENT: 'unshazam_comment',
  NEW_ASSIGNMENT_POST: 'new_post_on_assignment',
  NEW_ASSIGNMENT_COMMENT: 'new_comment_on_assignment',
  POST_UPDATED_ON_ASSIGNMENT: 'post_updated_on_assignment',
  COMMENT_UPDATED_ON_ASSIGNMENT: 'comment_updated_on_assignment',
  SHAZAM_POST_ON_ASSIGNMENT: 'shazam_post_on_assignment',
  SHAZAM_COMMENT_ON_ASSIGNMENT: 'shazam_comment_on_assignment',
  UNSHAZAM_POST_ON_ASSIGNMENT: 'unshazam_post_on_assignment',
  UNSHAZAM_COMMENT_ON_ASSIGNMENT: 'unshazam_comment_on_assignment',
}

let webSocket
let reconnectTimeout

const onOpen = () => {
  // Everything is looking good
  clearTimeout(reconnectTimeout)
}

const onError = () => {
  webSocket.close()
}

const onMessage = ({
  userId,
  connectedUser,
  disconnectedUser,
  messageReceived,
  channelMessageReceived,
  newNotificationReceived,
  newAlertReceived,
  expiringAlertReceived,
  disableChannel,
  enableChannel,
  suspended,
  unsuspended,
  deleteMessage,
  removedUserFromChannel,
  makeAdminOfChannel,
  revokeAdminOfChannel,
  channelMeetingUpdate,
  messageMeetingUpdate,
  addedToChannel,
  userBlock,
  deleteNotificationReceived,
  videoAssignmentCanStart,
  videoAssignmentHasStarted,
  videoAssignmentHasEnded,
  newPostCreated,
  newCommentCreated,
  postUpdated,
  commentUpdated,
  postShazamed,
  postUnshazamed,
  commentShazamed,
  commentUnshazamed,
  newAssignmentPostCreated,
  newAssignmentCommentCreated,
  assignmentPostUpdated,
  assignmentCommentUpdated,
  assignmentPostShazamed,
  assignmentPostUnshazamed,
  assignmentCommentShazamed,
  assignmentCommentUnshazamed,
  endLiveCall,
}) => event => {
  try {
    const message = humps.camelizeKeys(JSON.parse(event.data))
    switch (message.type) {
      case MESSAGE_TYPE.USER_ONLINE: {
        connectedUser(message)
        return
      }
      case MESSAGE_TYPE.USER_OFFLINE: {
        disconnectedUser(message)
        return
      }
      case MESSAGE_TYPE.DIRECT_MESSAGE_CREATED: {
        messageReceived(message)
        return
      }
      case MESSAGE_TYPE.CHANNEL_MESSAGE_CREATED: {
        channelMessageReceived(message)
        return
      }
      case MESSAGE_TYPE.NOTIFICATION_CREATED: {
        newNotificationReceived(message)
        return
      }
      case MESSAGE_TYPE.NOTIFICATION_DELETED: {
        deleteNotificationReceived(message)
        return
      }
      case MESSAGE_TYPE.NEW_ALERT: {
        newAlertReceived(message)
        return
      }
      case MESSAGE_TYPE.EXPIRING_ALERT: {
        expiringAlertReceived(message)
        return
      }
      case MESSAGE_TYPE.CHANNEL_DISABLED: {
        disableChannel(message)
        return
      }
      case MESSAGE_TYPE.CHANNEL_ENABLED: {
        enableChannel(message)
        return
      }
      case MESSAGE_TYPE.SUSPENDED: {
        suspended(message)
        return
      }
      case MESSAGE_TYPE.UNSUSPENDED: {
        unsuspended(message)
        return
      }
      case MESSAGE_TYPE.CHANNEL_MESSAGE_DELETED: {
        deleteMessage(message)
        return
      }
      case MESSAGE_TYPE.REMOVED_FROM_CHANNEL: {
        removedUserFromChannel({ ...message, isOwnUser: userId === message.removedId })
        return
      }
      case MESSAGE_TYPE.MAKE_ADMIN: {
        makeAdminOfChannel({ ...message, isOwnUser: userId === message.userId })
        return
      }
      case MESSAGE_TYPE.REVOKE_ADMIN: {
        revokeAdminOfChannel({ ...message, isOwnUser: userId === message.userId })
        return
      }
      case MESSAGE_TYPE.CHANNEL_MESSAGE_UPDATED: {
        channelMeetingUpdate(message)
        return
      }
      case MESSAGE_TYPE.DIRECT_MESSAGE_UPDATED: {
        messageMeetingUpdate(message)
        return
      }
      case MESSAGE_TYPE.ADDED_TO_CHANNEL: {
        addedToChannel({ ...message, isOwnUser: userId === message.addedId })
        return
      }
      case MESSAGE_TYPE.BLOCK_USER: {
        userBlock(message)
        return
      }
      case MESSAGE_TYPE.VIDEO_ASSIGNMENT_STARTING: {
        videoAssignmentCanStart(message)
        return
      }
      case MESSAGE_TYPE.VIDEO_ASSIGNMENT_STARTED: {
        videoAssignmentHasStarted(message)
        return
      }
      case MESSAGE_TYPE.VIDEO_ASSIGNMENT_ENDED: {
        videoAssignmentHasEnded(message)
        return
      }
      case MESSAGE_TYPE.NEW_POST: {
        newPostCreated(message)
        return
      }
      case MESSAGE_TYPE.NEW_COMMENT: {
        newCommentCreated(message)
        return
      }
      case MESSAGE_TYPE.POST_UPDATED: {
        postUpdated(message)
        return
      }
      case MESSAGE_TYPE.COMMENT_UPDATED: {
        commentUpdated(message)
        return
      }
      case MESSAGE_TYPE.SHAZAM_POST: {
        postShazamed(message)
        return
      }
      case MESSAGE_TYPE.UNSHAZAM_POST: {
        postUnshazamed(message)
        return
      }
      case MESSAGE_TYPE.SHAZAM_COMMENT: {
        commentShazamed(message)
        return
      }
      case MESSAGE_TYPE.UNSHAZAM_COMMENT: {
        commentUnshazamed(message)
        return
      }
      case MESSAGE_TYPE.NEW_ASSIGNMENT_POST: {
        newAssignmentPostCreated(message)
        return
      }
      case MESSAGE_TYPE.NEW_ASSIGNMENT_COMMENT: {
        newAssignmentCommentCreated(message)
        return
      }
      case MESSAGE_TYPE.POST_UPDATED_ON_ASSIGNMENT: {
        assignmentPostUpdated(message)
        return
      }
      case MESSAGE_TYPE.COMMENT_UPDATED_ON_ASSIGNMENT: {
        assignmentCommentUpdated(message)
        return
      }
      case MESSAGE_TYPE.SHAZAM_POST_ON_ASSIGNMENT: {
        assignmentPostShazamed(message)
        return
      }
      case MESSAGE_TYPE.UNSHAZAM_POST_ON_ASSIGNMENT: {
        assignmentPostUnshazamed(message)
        return
      }
      case MESSAGE_TYPE.SHAZAM_COMMENT_ON_ASSIGNMENT: {
        assignmentCommentShazamed(message)
        return
      }
      case MESSAGE_TYPE.UNSHAZAM_COMMENT_ON_ASSIGNMENT: {
        assignmentCommentUnshazamed(message)
        return
      }
      case MESSAGE_TYPE.FINISH_LIVE_CALL: {
        endLiveCall(message)
        // eslint-disable-next-line no-useless-return
        return
      }
      default: {
        break
      }
    }
  } catch (error) {
    // TO-DO: Throw an error message
  }
}

const initWebSocket = ({ authToken, userId, ...actions }) => {
  if (webSocket) return

  webSocket = typeof WebSocket !== 'undefined' && new WebSocket(`${WSS_URL}/?token=${authToken}`)
  webSocket.addEventListener('open', onOpen)
  webSocket.addEventListener('close', () => {
    reconnectTimeout = setTimeout(() => {
      webSocket = null
      initWebSocket({ authToken, userId, ...actions })
    }, 5000)
  })
  webSocket.addEventListener('error', onError)
  webSocket.addEventListener('message', onMessage({ userId, ...actions }))
}

const withWebSocket = WrappedComponent => {
  const mapStateToProps = ({ user }) => ({
    authToken: user.authToken,
    userId: user.id,
  })

  const mapDispatchToProps = {
    connectedUser: userConnected,
    disconnectedUser: userDisconnected,
    messageReceived: directMessageCreated,
    channelMessageReceived: channelMessageCreated,
    newNotificationReceived: newNotification,
    disableChannel: channelDisabled,
    enableChannel: channelEnabled,
    deleteMessage: channelMessageDeleted,
    newAlertReceived: newAlert,
    expiringAlertReceived: expiringAlert,
    suspended: userSuspended,
    unsuspended: userUnsuspended,
    removedUserFromChannel: userRemovedFromChannel,
    makeAdminOfChannel: makeAdmin,
    revokeAdminOfChannel: revokeAdmin,
    channelMeetingUpdate: updateChannelMeeting,
    messageMeetingUpdate: updateMessageMeeting,
    addedToChannel: addUserToChannel,
    userBlock: blockUser,
    deleteNotificationReceived: deleteNotification,
    videoAssignmentCanStart: videoAssignmentStarting,
    videoAssignmentHasStarted: videoAssignmentStarted,
    videoAssignmentHasEnded: videoAssignmentEnded,
    newPostCreated: newPost,
    newCommentCreated: newComment,
    postUpdated: postUpdate,
    commentUpdated: commentUpdate,
    postShazamed: shazamPost,
    postUnshazamed: unshazamPost,
    newAssignmentPostCreated: newAssignmentPost,
    newAssignmentCommentCreated: newAssignmentComment,
    assignmentPostUpdated: assignmentPostUpdate,
    assignmentCommentUpdated: assignmentCommentUpdate,
    assignmentPostShazamed: assignmentPostShazam,
    assignmentPostUnshazamed: assignmentPostUnshazam,
    assignmentCommentShazamed: assignmentCommentShazam,
    assignmentCommentUnshazamed: assignmentCommentUnshazam,
    commentShazamed: shazamComment,
    commentUnshazamed: unshazamComment,
    endLiveCall: finishLiveCall,
  }

  const WebSocketComponent = ({
    userId,
    authToken,
    connectedUser,
    disconnectedUser,
    messageReceived,
    channelMessageReceived,
    newNotificationReceived,
    disableChannel,
    enableChannel,
    deleteMessage,
    newAlertReceived,
    expiringAlertReceived,
    suspended,
    unsuspended,
    removedUserFromChannel,
    makeAdminOfChannel,
    revokeAdminOfChannel,
    channelMeetingUpdate,
    messageMeetingUpdate,
    addedToChannel,
    userBlock,
    deleteNotificationReceived,
    videoAssignmentCanStart,
    videoAssignmentHasStarted,
    videoAssignmentHasEnded,
    newPostCreated,
    newCommentCreated,
    postUpdated,
    commentUpdated,
    postShazamed,
    postUnshazamed,
    commentShazamed,
    commentUnshazamed,
    newAssignmentPostCreated,
    newAssignmentCommentCreated,
    assignmentPostUpdated,
    assignmentCommentUpdated,
    assignmentPostShazamed,
    assignmentPostUnshazamed,
    assignmentCommentShazamed,
    assignmentCommentUnshazamed,
    endLiveCall,
    ...props
  }) => {
    if (typeof WebSocket !== 'undefined') {
      initWebSocket({
        authToken,
        userId,
        connectedUser,
        disconnectedUser,
        messageReceived,
        channelMessageReceived,
        newNotificationReceived,
        disableChannel,
        enableChannel,
        deleteMessage,
        newAlertReceived,
        expiringAlertReceived,
        suspended,
        unsuspended,
        removedUserFromChannel,
        revokeAdminOfChannel,
        makeAdminOfChannel,
        channelMeetingUpdate,
        messageMeetingUpdate,
        addedToChannel,
        userBlock,
        deleteNotificationReceived,
        videoAssignmentCanStart,
        videoAssignmentHasStarted,
        videoAssignmentHasEnded,
        newPostCreated,
        newCommentCreated,
        postUpdated,
        commentUpdated,
        postShazamed,
        postUnshazamed,
        commentShazamed,
        commentUnshazamed,
        newAssignmentPostCreated,
        newAssignmentCommentCreated,
        assignmentPostUpdated,
        assignmentCommentUpdated,
        assignmentPostShazamed,
        assignmentPostUnshazamed,
        assignmentCommentShazamed,
        assignmentCommentUnshazamed,
        endLiveCall,
      })
    }

    useEffect(() => () => {
        webSocket.close()
        clearTimeout(reconnectTimeout)
      }, [])

    return <WrappedComponent {...props} />
  }

  WebSocketComponent.propTypes = {
    userId: PropTypes.number,
    authToken: PropTypes.string,
    connectedUser: PropTypes.func.isRequired,
    disconnectedUser: PropTypes.func.isRequired,
    messageReceived: PropTypes.func.isRequired,
    channelMessageReceived: PropTypes.func.isRequired,
    newNotificationReceived: PropTypes.func.isRequired,
    disableChannel: PropTypes.func.isRequired,
    enableChannel: PropTypes.func.isRequired,
    deleteMessage: PropTypes.func.isRequired,
    newAlertReceived: PropTypes.func.isRequired,
    expiringAlertReceived: PropTypes.func.isRequired,
    suspended: PropTypes.func.isRequired,
    unsuspended: PropTypes.func.isRequired,
    removedUserFromChannel: PropTypes.func.isRequired,
    makeAdminOfChannel: PropTypes.func.isRequired,
    revokeAdminOfChannel: PropTypes.func.isRequired,
    channelMeetingUpdate: PropTypes.func.isRequired,
    messageMeetingUpdate: PropTypes.func.isRequired,
    addedToChannel: PropTypes.func.isRequired,
    userBlock: PropTypes.func.isRequired,
    deleteNotificationReceived: PropTypes.func.isRequired,
    videoAssignmentCanStart: PropTypes.func.isRequired,
    videoAssignmentHasStarted: PropTypes.func.isRequired,
    videoAssignmentHasEnded: PropTypes.func.isRequired,
    newPostCreated: PropTypes.func.isRequired,
    newCommentCreated: PropTypes.func.isRequired,
    postUpdated: PropTypes.func.isRequired,
    commentUpdated: PropTypes.func.isRequired,
    postShazamed: PropTypes.func.isRequired,
    postUnshazamed: PropTypes.func.isRequired,
    commentShazamed: PropTypes.func.isRequired,
    commentUnshazamed: PropTypes.func.isRequired,
    newAssignmentPostCreated: PropTypes.func.isRequired,
    newAssignmentCommentCreated: PropTypes.func.isRequired,
    assignmentPostUpdated: PropTypes.func.isRequired,
    assignmentCommentUpdated: PropTypes.func.isRequired,
    assignmentPostShazamed: PropTypes.func.isRequired,
    assignmentPostUnshazamed: PropTypes.func.isRequired,
    assignmentCommentShazamed: PropTypes.func.isRequired,
    assignmentCommentUnshazamed: PropTypes.func.isRequired,
    endLiveCall: PropTypes.func.isRequired,
  }

  WebSocketComponent.defaultProps = {
    userId: null,
    authToken: null,
  }

  return connect(
    mapStateToProps,
    mapDispatchToProps
  )(WebSocketComponent)
}

export default withWebSocket
