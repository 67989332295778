import React from 'react'
import PropTypes from 'prop-types'

import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

const ConfirmEnrollment = ({
  courseName,
  price,
  type,
  availability,
  onCancelClick,
  onEnrollClick,
  isEnrolling,
}) => (
  <>
    <div className={styles.header}>
      <h2 className={styles.title}>CONFIRM YOUR ENROLLMENT</h2>
    </div>
    <p className={styles.information}>
      {`We will send you a confirmation upon the completion of this order. If your class begins
    at a future date, we will send you another email once it's about to start.`}
    </p>
    <div className={styles.summary}>
      <h5>ORDER SUMMARY</h5>
      <div className={styles.course}>
        <h2 className={styles.name}>{courseName}</h2>
        <p className={styles.price}>${price}</p>
        <p className={styles.type}>{type}</p>
        <p className={styles.availability}>{availability}</p>
      </div>
    </div>
    <div className={styles.footer}>
      <div className={styles['align-right']}>
        <Button
          theme={ButtonTheme.SECONDARY}
          label="CANCEL"
          onClick={onCancelClick}
          disabled={isEnrolling}
        />
        <Button
          theme={ButtonTheme.PRIMARY}
          label="ENROLL NOW"
          onClick={onEnrollClick}
          disabled={isEnrolling}
        />
      </div>
    </div>
  </>
)

ConfirmEnrollment.propTypes = {
  onCancelClick: PropTypes.func,
  onEnrollClick: PropTypes.func,
  courseName: PropTypes.string,
  price: PropTypes.string,
  type: PropTypes.string,
  availability: PropTypes.string,
  isEnrolling: PropTypes.bool,
}

ConfirmEnrollment.defaultProps = {
  onCancelClick: () => {},
  onEnrollClick: () => {},
  courseName: null,
  price: null,
  type: null,
  availability: null,
  isEnrolling: false,
}

export default ConfirmEnrollment
