import React, { useCallback, useReducer } from 'react'
import PropTypes from 'prop-types'

import Button, { ButtonTheme } from '_components/button'
import Card, { CardSize } from '_components/card'
import RadioButton from '_components/radio-button'
import { userShape } from '_utils/proptypes'

import styles from './styles.css'
import NotificationSwitch from './notification-switch'

const EMAIL_SUMMARY_FREQUENCY_DAILY = 'DAILY'
const EMAIL_SUMMARY_FREQUENCY_WEEKLY = 'WEEKLY'

const MAIN_NOTIFICATIONS = [
  {
    tag: 'emailMessages',
    name: 'Messages',
  },
  {
    tag: 'emailCourseAdd',
    name: 'Course add',
  },
]
const SUMMARY = [
  {
    tag: 'emailSummaryAddedToChannels',
    name: 'Added to channels',
  },
  {
    tag: 'emailSummaryMessagesInChannels',
    name: 'Messages in channels',
  },
  {
    tag: 'emailSummaryPostComments',
    name: 'Post comments',
  },
  {
    tag: 'emailSummaryPostLikes',
    name: 'Post likes',
  },
  {
    tag: 'emailSummaryGoalLikes',
    name: 'Goal likes',
  },
  {
    tag: 'emailSummaryNewFollowers',
    name: 'New followers',
  },
  {
    tag: 'emailSummaryFriendSuggestions',
    name: 'Friend suggestions',
  },
]
const FREQUENCY_OPTIONS = [
  {
    tag: EMAIL_SUMMARY_FREQUENCY_DAILY,
    name: 'Daily',
  },
  {
    tag: EMAIL_SUMMARY_FREQUENCY_WEEKLY,
    name: 'Weekly',
  },
]

const CHANGE_SWITCH = 'CHANGE_SWITCH'
const CHANGE_FREQUENCY = 'CHANGE_FREQUENCY'
const DISABLE_ALL = 'DISABLE_ALL'

const reducer = (state, action) => {
  switch (action.type) {
    case CHANGE_SWITCH: {
      return {
        ...state,
        [action.category]: state[action.category].map(
          item => (item.name === action.name ? { ...item, active: action.value } : item)
        ),
      }
    }
    case CHANGE_FREQUENCY: {
      return {
        ...state,
        frequency: state.frequency.map(item => ({ ...item, active: item.name === action.name })),
      }
    }
    case DISABLE_ALL: {
      return {
        ...state,
        disableAll: action.value,
      }
    }
    default:
      return state
  }
}

const EmailNotificationsCard = ({ onClick, user, loading }) => {
  const [state, dispatch] = useReducer(reducer, {
    notifications: MAIN_NOTIFICATIONS.map(item => ({
      ...item,
      active: user[item.tag],
    })),
    summary: SUMMARY.map(item => ({
      ...item,
      active: user[item.tag],
    })),
    frequency: FREQUENCY_OPTIONS.map(option => ({
      ...option,
      active: user.emailSummaryFrequency === option.tag,
    })),
    disableAll: user.emailDisableAll,
  })

  const toggleDisable = useCallback(value => dispatch({ type: DISABLE_ALL, value }), [])

  const onChange = useCallback(
    ({ category, name, value }) => dispatch({ type: CHANGE_SWITCH, category, name, value }),
    []
  )

  const radioChange = useCallback(name => dispatch({ type: CHANGE_FREQUENCY, name }), [])

  const onFormSubmit = useCallback(
    event => {
      event.preventDefault()
      const { notifications, summary, disableAll, frequency } = state
      onClick({
        ...notifications.reduce((acc, curr) => ({ ...acc, [curr.tag]: curr.active }), {}),
        ...summary.reduce((acc, curr) => ({ ...acc, [curr.tag]: curr.active }), {}),
        emailDisableAll: disableAll,
        emailSummaryFrequency: frequency.find(item => item.active).tag,
      })
    },
    [onClick, state]
  )

  return (
    <Card className={styles.card} size={CardSize.LARGE}>
      <h2>Email notification settings</h2>
      <form onSubmit={onFormSubmit} className={styles['form-container']}>
        <div className={styles['notification-wrapper']}>
          {state.notifications &&
            state.notifications.map(notification => (
              <NotificationSwitch
                key={notification.name}
                name={notification.name}
                active={notification.active && !state.disableAll}
                category="notifications"
                onChange={onChange}
              />
            ))}
        </div>
        <div className={styles.divider} />
        <h3 className={styles.subtitle}>SUMMARY</h3>
        <div className={styles['notification-wrapper']}>
          {state.summary &&
            state.summary.map(item => (
              <NotificationSwitch
                key={item.name}
                name={item.name}
                active={item.active && !state.disableAll}
                category="summary"
                onChange={onChange}
              />
            ))}
          <div className={styles['radio-wrapper']}>
            <p className={styles.label}>Frequency</p>
            <RadioButton optionsList={state.frequency} onChange={radioChange} />
          </div>
        </div>
        <div className={styles.divider} />
        <NotificationSwitch name="Disable all" active={state.disableAll} onChange={toggleDisable} />
        <Button
          label="Save changes"
          theme={ButtonTheme.PRIMARY}
          className={styles.button}
          type="submit"
          loading={loading}
        />
      </form>
    </Card>
  )
}

EmailNotificationsCard.propTypes = {
  user: userShape.isRequired,
  onClick: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
}

export default EmailNotificationsCard
