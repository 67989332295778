import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

const TooltipContent = React.forwardRef(
  ({ onCloseClick, cardInfo: { title, description, icon } }, ref) => {
    const memberShipScrollToState = useMemo(
      () => ({
        billing: true,
      }),
      []
    )

    return (
      <div ref={ref} className={styles.tooltip}>
        <svg aria-hidden="true" viewBox={icon.viewBox} className={styles.icon}>
          <use xlinkHref={`#${icon.id}`} />
        </svg>
        <h1 className={styles.title}>{title}</h1>
        <p className={styles.description}>{description}</p>
        <Button
          theme={ButtonTheme.PRIMARY}
          className={styles['tooltip-button']}
          label="ACTIVATE YOUR MEMBERSHIP"
          to="/account"
          state={memberShipScrollToState}
          onClick={onCloseClick}
        />
        <Button
          theme={ButtonTheme.TRANSPARENT_BACKGROUND}
          className={styles['dismiss-button']}
          label="NOT NOW"
          onClick={onCloseClick}
        />
      </div>
    )
  }
)

TooltipContent.propTypes = {
  cardInfo: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
    icon: PropTypes.shape({
      viewBox: PropTypes.string,
      id: PropTypes.string,
    }),
  }).isRequired,
  onCloseClick: PropTypes.func.isRequired,
}

export default React.memo(TooltipContent)
