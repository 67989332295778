import { createSelector } from 'reselect'

import { toArray } from '_utils/helpers'
import {
  GET_MY_CHATS,
  GET_CHANNEL_MESSAGES,
  GET_DIRECT_MESSAGES,
  SEND_CHANNEL_MESSAGE,
  SEND_DIRECT_MESSAGE,
  GET_CHANNEL_USERS,
  GET_USERS_AVAILABLE_TO_INVITE,
  INVITE_TO_CHANNEL,
  START_CHANNEL_VIDEO_CHAT,
  START_DIRECT_MESSAGE_VIDEO_CHAT,
  REMOVE_USER_FROM_CHANNEL,
  CREATE_PRE_DIRECT_MESSAGE,
} from '_modules/chat/actions'

const getChats = ({ chat }) => chat
export const isLoadingChats = ({ loading }) => !!loading.get(GET_MY_CHATS.ACTION)
export const loadingChatsError = ({ error }) => error.get(GET_MY_CHATS.ACTION)
export const isLoadingMessages = ({ loading }) => !!loading.get(GET_CHANNEL_MESSAGES.ACTION)
export const isLoadingDirect = ({ loading }) => !!loading.get(GET_DIRECT_MESSAGES.ACTION)
export const isStartingVideoCall = ({ loading }) =>
  !!loading.get(START_CHANNEL_VIDEO_CHAT.ACTION) ||
  !!loading.get(START_DIRECT_MESSAGE_VIDEO_CHAT.ACTION)
export const isSendingMessage = ({ loading }) =>
  !!loading.get(SEND_CHANNEL_MESSAGE.ACTION) || !!loading.get(SEND_DIRECT_MESSAGE.ACTION)
export const sendMessageError = ({ error }) =>
  error.get(SEND_CHANNEL_MESSAGE.ACTION) || error.get(SEND_DIRECT_MESSAGE.ACTION)
export const isLoadingChatUsers = ({ loading }) => !!loading.get(GET_CHANNEL_USERS.ACTION)
export const isLoadingUsersToInvite = ({ loading }) =>
  !!loading.get(GET_USERS_AVAILABLE_TO_INVITE.ACTION)
export const isRemovingFromChannel = ({ loading }) => !!loading.get(REMOVE_USER_FROM_CHANNEL.ACTION)
export const removingFromChannelError = ({ error }) => error.get(REMOVE_USER_FROM_CHANNEL.ACTION)
export const isInvitingToChannel = ({ loading }) => !!loading.get(INVITE_TO_CHANNEL.ACTION)
export const inviteToChannelError = ({ error }) => error.get(INVITE_TO_CHANNEL.ACTION)
export const isCreatingPreDirectMessage = ({ loading }) =>
  !!loading.get(CREATE_PRE_DIRECT_MESSAGE.ACTION)
export const creatingPreDirectMessageError = ({ error }) =>
  error.get(CREATE_PRE_DIRECT_MESSAGE.ACTION)
export const creatingDirectMessageCallError = ({ error }) =>
  error.get(START_DIRECT_MESSAGE_VIDEO_CHAT.ACTION)
export const creatingChannelCallError = ({ error }) => error.get(START_CHANNEL_VIDEO_CHAT.ACTION)

export const getAllChats = createSelector([getChats], chats => {
  const preDirectMessage = chats.get('preDirectMessage').toJS()
  const hasNewDirectMessage = preDirectMessage.user && !!preDirectMessage.user.id
  const directMessagesList = toArray(chats.get('directMessages'))
  const channels = toArray(chats.get('channels'))
  const usersAvailableToInvite = chats.get('usersAvailableToInvite').toJS()

  return {
    channels,
    directMessages: hasNewDirectMessage
      ? [preDirectMessage, ...directMessagesList]
      : directMessagesList,
    preDirectMessage,
    hasNewDirectMessage,
    usersAvailableToInvite,
  }
})

export const checkIsRemovingFromChannel = createSelector(
  [isRemovingFromChannel],
  isLoading => isLoading
)

export const errorRemovingFromChannel = createSelector([removingFromChannelError], error => error)

export const errorCreatingChannelCall = createSelector([creatingChannelCallError], error => error)

export const errorCreatingDirectMessagelCall = createSelector(
  [creatingDirectMessageCallError],
  error => error
)
