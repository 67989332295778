import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { Link } from '@reach/router'

import PersonIcon from '_assets/icons/person-icon.svg'
import CalendarIcon from '_assets/icons/calendar-outlined-icon.svg'
import ArrowIcon from '_assets/icons/angle-down.svg'
import Svg from '_components/svg'
import IconButton from '_components/icon-button'

import styles from './styles.css'

const ViewClassesLink = ({
  availableClasses,
  customClass,
  className,
  title,
  url,
  isSliderItem,
  initialSearchState,
}) => {
  const viewClassText = useMemo(
    () => {
      if (customClass) {
        return `${availableClasses} workshop${availableClasses !== 1 ? 's' : ''} available`
      }

      return `${availableClasses} class${availableClasses !== 1 ? 'es' : ''} available`
    },
    [availableClasses, customClass]
  )

  return (
    <div className={classnames(styles['view-classes-container'], className)}>
      {!customClass && (
        <div className={classnames(styles['icon-wrapper'], { [styles.slider]: isSliderItem })}>
          <Svg icon={PersonIcon} className={styles['person-icon']} aria-label="A person icon" />
        </div>
      )}
      <div
        className={classnames(styles['icon-wrapper'], {
          [styles['icon-position']]: !customClass,
          [styles.slider]: isSliderItem,
        })}
      >
        <Svg
          icon={CalendarIcon}
          aria-label={`${title} planet`}
          className={styles['calendar-icon']}
        />
      </div>

      <span className={styles['class-quantities']}>{viewClassText}</span>
      <IconButton
        className={styles.button}
        aria-label={`Access ${title} courses`}
        icon={ArrowIcon}
        iconClassName={styles['arrow-icon']}
      />
      <Link className={styles['view-classes-link']} to={url} state={initialSearchState} />
    </div>
  )
}

ViewClassesLink.propTypes = {
  availableClasses: PropTypes.number,
  customClass: PropTypes.bool,
  className: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
  isSliderItem: PropTypes.bool,
  initialSearchState: PropTypes.shape({
    search: PropTypes.string,
  }),
}

ViewClassesLink.defaultProps = {
  availableClasses: 0,
  customClass: false,
  className: '',
  title: '',
  isSliderItem: false,
  initialSearchState: null,
}

export default React.memo(ViewClassesLink)
