import React, { useCallback, useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import AvatarEditor from 'react-avatar-editor'
import loadImage from 'blueimp-load-image'
import { extension } from 'mime-types'

import Button, { ButtonTheme } from '_components/button'
import { onMouseDown } from '_utils/aria'
import CloseIcon from '_assets/icons/close.svg'
import PictureIcon from '_assets/icons/photo.svg'

import styles from './styles.css'

const DEFAULT_ZOOM = 1.1
const CropPicture = ({ handleCancelClick, originalImage, onSavePicture, isLoadingPicture }) => {
  const editorRef = useRef(null)
  const [tempFile, setTempFile] = useState(null)
  const [zoomScale, setZoomScale] = useState(DEFAULT_ZOOM)

  useEffect(
    () => {
      const handleImage = canvas => canvas.toBlob?.(blob => {
          setTempFile(new File([blob], originalImage.name, { type: originalImage.type }))
        })
      loadImage(originalImage, handleImage, { orientation: true, canvas: true })
    },
    [originalImage, originalImage.name, originalImage.type]
  )

  const handleSliderChange = useCallback(event => setZoomScale(Number(event.target.value)), [])

  const handleCropImage = useCallback(
    () => {
      if (editorRef.current) {
        const fileType = tempFile?.type
        editorRef.current.getImage().toBlob(
          blob => {
            const fileName = `avatar.${extension(fileType)}`

            const newFile = new File([blob], fileName, { type: fileType })

            onSavePicture({ file: newFile, blob })
          },
          fileType,
          0.5
        )
      }
    },
    [onSavePicture, tempFile]
  )

  return (
    <div className={styles.container}>
      <div className={styles['title-container']}>
        <h1 className={styles.title}>CROP YOUR PHOTO</h1>
        <button
          type="button"
          onClick={handleCancelClick}
          className={styles['cancel-button']}
          aria-label="Cancel cropping picture"
          onMouseDown={onMouseDown}
        >
          <svg className={styles['close-icon']} viewBox={CloseIcon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${CloseIcon.id}`} />
          </svg>
        </button>
      </div>
      <div className={styles['avatar-wrapper']}>
        <AvatarEditor
          ref={editorRef}
          image={tempFile}
          className={styles['avatar-editor']}
          border={60}
          borderRadius={160}
          rotate={0}
          scale={zoomScale}
          color={[255, 255, 255, 0.5]}
        />
      </div>
      <div className={styles['slider-wrapper']}>
        <svg className={styles['zoom-out-icon']} viewBox={PictureIcon.viewBox} aria-hidden="true">
          <use xlinkHref={`#${PictureIcon.id}`} />
        </svg>
        <input
          type="range"
          min="1"
          max="2.5"
          step="0.01"
          value={zoomScale}
          onChange={handleSliderChange}
          className={styles.slider}
        />
        <svg className={styles['zoom-in-icon']} viewBox={PictureIcon.viewBox} aria-hidden="true">
          <use xlinkHref={`#${PictureIcon.id}`} />
        </svg>
      </div>
      <Button
        onClick={handleCropImage}
        theme={ButtonTheme.PRIMARY}
        label="SAVE"
        className={styles['save-button']}
        loading={isLoadingPicture}
      />
    </div>
  )
}

CropPicture.propTypes = {
  originalImage: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
  }).isRequired,
  handleCancelClick: PropTypes.func.isRequired,
  onSavePicture: PropTypes.func.isRequired,
  isLoadingPicture: PropTypes.bool,
}

CropPicture.defaultProps = {
  isLoadingPicture: false,
}
export default React.memo(CropPicture)
