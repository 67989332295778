import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { Waypoint } from 'react-waypoint'
import { Link, useLocation } from '@reach/router'

import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import CloseIcon from '_assets/icons/close.svg'
import NotificationsEmptyIcon from '_assets/icons/notifications-empty.svg'
import { userShape } from '_utils/proptypes'
import { User } from '_models/'
import { onMouseDown } from '_utils/aria'
import { formatUrl } from '_utils/helpers'

import styles from './styles.css'

export const NotificationType = {
  GOAL_DUE_DATE: 'GOAL_DUE_DATE',
  GOAL_EXPIRED: 'GOAL_EXPIRED',
  SHAZAM_GOAL: 'SHAZAM_GOAL',
  FOLLOW: 'FOLLOW',
  SHAZAM_COMMENT: 'SHAZAM_COMMENT',
  COMMENT_ON_POST: 'COMMENT_ON_POST',
  SHAZAM_POST: 'SHAZAM_POST',
  SHAZAM_POST_ON_ASSIGNMENT: 'SHAZAM_POST_ON_ASSIGNMENT',
  SHAZAM_COMMENT_ON_ASSIGNMENT: 'SHAZAM_COMMENT_ON_ASSIGNMENT',
  MENTION_ON_CHANNEL: 'MENTION_ON_CHANNEL',
  MENTION_ON_POST: 'MENTION_ON_POST',
  MENTION_ON_COMMENT: 'MENTION_ON_COMMENT',
  COMMENT_ON_POST_ON_ASSIGNMENT: 'COMMENT_ON_POST_ON_ASSIGNMENT',
  MENTION_ON_POST_ON_ASSIGNMENT: 'MENTION_ON_POST_ON_ASSIGNMENT',
  MENTION_ON_COMMENT_ON_ASSIGNMENT: 'MENTION_ON_COMMENT_ON_ASSIGNMENT',
  NEW_GOAL: 'NEW_GOAL',
  GOAL_COMPLETED: 'GOAL_COMPLETED',
  VISION_STATEMENT_CREATED: 'VISION_STATEMENT_CREATED',
  VISION_STATEMENT_UPDATED: 'VISION_STATEMENT_UPDATED',
  LEGACY_STATEMENT_CREATED: 'LEGACY_STATEMENT_CREATED',
  LEGACY_STATEMENT_UPDATED: 'LEGACY_STATEMENT_UPDATED',
}

const NotificationCard = ({ onCloseClick, notifications, user, loadMore, hasMore }) => {
  const { pathname } = useLocation()

  const chooseNotificationText = useCallback(notification => {
    switch (notification.type) {
      case NotificationType.GOAL_DUE_DATE:
        return `${notification.data.categoryTitle} goal is due in ${notification.data.days} ${
          notification.data.days <= 1 ? 'day' : 'days'
        }`
      case NotificationType.GOAL_EXPIRED:
        return `${notification.data.categoryTitle} goal has expired.`
      case NotificationType.SHAZAM_GOAL:
        return `${notification.data.user.name} shazam'd your goal.`
      case NotificationType.FOLLOW:
        return `${notification.data.user.name} follows you.`
      case NotificationType.SHAZAM_COMMENT:
        return `${notification.data.user.name} shazam'd your comment.`
      case NotificationType.COMMENT_ON_POST:
        return `${notification.data.user.name} commented on your post.`
      case NotificationType.SHAZAM_POST:
        return `${notification.data.user.name} shazam'd your post.`
      case NotificationType.SHAZAM_POST_ON_ASSIGNMENT:
        return `${notification.data.user.name} shazam'd your post in ${
          notification.data.assignmentName
        } in ${notification.data.courseName}`
      case NotificationType.SHAZAM_COMMENT_ON_ASSIGNMENT:
        return `${notification.data.user.name} shazam'd your comment in ${
          notification.data.assignmentName
        } in ${notification.data.courseName}`
      case NotificationType.MENTION_ON_CHANNEL:
        return `${notification.data.author.name} mentioned you in the channel '${
          notification.data.channelName
        }'.`
      case NotificationType.MENTION_ON_POST:
        return `${notification.data.user.name} mentioned you on their Dashboard post.`
      case NotificationType.MENTION_ON_COMMENT:
        return `${notification.data.user.name} mentioned you in a comment on the Dashboard.`
      case NotificationType.COMMENT_ON_POST_ON_ASSIGNMENT:
        return `${notification.data.user.name} commented on your post in ${
          notification.data.assignmentName
        } in ${notification.data.courseName}`
      case NotificationType.MENTION_ON_POST_ON_ASSIGNMENT:
        return `${notification.data.user.name} mentioned you in a post for ${
          notification.data.assignmentName
        } in ${notification.data.courseName}`
      case NotificationType.MENTION_ON_COMMENT_ON_ASSIGNMENT:
        return `${notification.data.user.name} mentioned you in their comment in ${
          notification.data.assignmentName
        } in ${notification.data.courseName}`
      case NotificationType.NEW_GOAL:
        return `${notification.data.user?.name ||
          notification.data.name} added a new goal. Check it out!`
      case NotificationType.GOAL_COMPLETED:
        return `${notification.data.user?.name ||
          notification.data.name} has completed a goal. Check it out!`
      case NotificationType.VISION_STATEMENT_CREATED:
        return `${notification.data.user?.name ||
          notification.data.name} added a vision statement. Check it out!`
      case NotificationType.VISION_STATEMENT_UPDATED:
        return `${notification.data.user?.name ||
          notification.data.name} has updated their vision statement. Check it out!`
      case NotificationType.LEGACY_STATEMENT_CREATED:
        return `${notification.data.user?.name ||
          notification.data.name} added a legacy statement. Check it out!`
      case NotificationType.LEGACY_STATEMENT_UPDATED:
        return `${notification.data.user?.name ||
          notification.data.name} updated their legacy statement. Check it out!`
      default:
        return 'none'
    }
  }, [])

  const chooseLink = useCallback(
    notification => {
      switch (notification.type) {
        case NotificationType.GOAL_DUE_DATE:
        case NotificationType.GOAL_EXPIRED:
        case NotificationType.SHAZAM_GOAL:
          return `user/${user.username}/goals`
        case NotificationType.FOLLOW:
          return `user/${notification.data.user.username}`
        case NotificationType.NEW_GOAL:
        case NotificationType.GOAL_COMPLETED:
        case NotificationType.VISION_STATEMENT_CREATED:
        case NotificationType.VISION_STATEMENT_UPDATED:
        case NotificationType.LEGACY_STATEMENT_CREATED:
        case NotificationType.LEGACY_STATEMENT_UPDATED:
          return formatUrl(notification.data.link)
        case NotificationType.SHAZAM_POST_ON_ASSIGNMENT:
        case NotificationType.SHAZAM_COMMENT_ON_ASSIGNMENT:
        case NotificationType.COMMENT_ON_POST_ON_ASSIGNMENT:
        case NotificationType.MENTION_ON_POST_ON_ASSIGNMENT:
        case NotificationType.MENTION_ON_COMMENT_ON_ASSIGNMENT:
          return `class/${notification.data.classId}/assignment/${notification.data.assignmentId}`
        case NotificationType.SHAZAM_POST:
        case NotificationType.SHAZAM_COMMENT:
        case NotificationType.COMMENT_ON_POST:
        case NotificationType.MENTION_ON_POST:
        case NotificationType.MENTION_ON_COMMENT:
          return `user/${notification.data.postAuthor.username}/post/${notification.data.postId}`
        case NotificationType.MENTION_ON_CHANNEL:
          return `messages?channelId=${notification.data.channelId}`
        default:
          return '/dashboard'
      }
    },
    [user.username]
  )

  const onLinkClick = useCallback(
    event => {
      const { id } = event.currentTarget
      if (pathname === id) {
        window.location.reload()
      }

      return null
    },
    [pathname]
  )

  const linkState = useCallback(notification => {
    if (
      (notification.type === NotificationType.NEW_GOAL ||
        notification.type === NotificationType.GOAL_COMPLETED) &&
      notification.data.goalId
    ) {
      return {
        goalId: notification.data.goalId,
      }
    }

    return null
  }, [])

  const getNotificationUsername = useCallback(
    notification => {
      let userName = ''

      if (notification.data?.user?.username) {
        userName = notification.data?.user?.username
      } else if (notification.data?.author?.username) {
        userName = notification.data?.author?.username
      } else {
        userName = user.username
      }

      switch (notification.type) {
        case NotificationType.NEW_GOAL:
        case NotificationType.GOAL_COMPLETED:
        case NotificationType.VISION_STATEMENT_CREATED:
        case NotificationType.VISION_STATEMENT_UPDATED:
        case NotificationType.LEGACY_STATEMENT_CREATED:
        case NotificationType.LEGACY_STATEMENT_UPDATED:
          return notification.data.user?.name || user.data?.name
        default:
          return userName
      }
    },
    [user]
  )

  const getNotificationPicture = useCallback(
    notification => {
      let userPhoto = ''

      if (notification.data?.user?.photo) {
        userPhoto = notification.data?.user?.photo
      } else if (notification.data?.author?.photo) {
        userPhoto = notification.data?.author?.photo
      } else {
        userPhoto = user.photo
      }

      switch (notification.type) {
        case NotificationType.NEW_GOAL:
        case NotificationType.GOAL_COMPLETED:
        case NotificationType.VISION_STATEMENT_CREATED:
        case NotificationType.VISION_STATEMENT_UPDATED:
        case NotificationType.LEGACY_STATEMENT_CREATED:
        case NotificationType.LEGACY_STATEMENT_UPDATED:
          return notification.data.user?.photo
        default:
          return userPhoto
      }
    },
    [user.photo]
  )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <h3 className={styles.title}>NOTIFICATIONS</h3>
        <button type="button" onClick={onCloseClick} onMouseDown={onMouseDown}>
          <svg
            className={styles.close}
            aria-label="close notifications"
            viewBox={CloseIcon.viewBox}
          >
            <use xlinkHref={`#${CloseIcon.id}`} />
          </svg>
        </button>
      </div>
      {notifications.length ? (
        <div className={styles.notifications}>
          {notifications.map((notification, index) => (
            <Link
              to={chooseLink(notification)}
              className={styles.link}
              key={notification.id}
              id={chooseLink(notification)}
              state={linkState(notification)}
              onClick={onLinkClick}
            >
              <div className={styles.notification}>
                <ProfileAvatar
                  className={styles.picture}
                  username={getNotificationUsername(notification)}
                  src={getNotificationPicture(notification)}
                  size={AvatarSize.SMALL}
                  disabledLink
                />
                <p className={classnames(styles.text, { [styles.unread]: !notification.read })}>
                  {chooseNotificationText(notification)}
                </p>
                <p className={styles.time}>{moment(notification.createdAt).fromNow(true)}</p>
                {index + 1 === notifications.length &&
                  hasMore && <Waypoint key="waypoint" onEnter={loadMore} />}
              </div>
            </Link>
          ))}
        </div>
      ) : (
        <div className={styles.empty}>
          <svg className={styles.icon} viewBox={NotificationsEmptyIcon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${NotificationsEmptyIcon.id}`} />
          </svg>
          <p className={styles.text}>No notifications</p>
        </div>
      )}
    </div>
  )
}

NotificationCard.propTypes = {
  onCloseClick: PropTypes.func,
  notifications: PropTypes.arrayOf(
    PropTypes.shape({
      picture: PropTypes.string,
      text: PropTypes.string,
      time: PropTypes.string,
      unread: PropTypes.bool,
    })
  ).isRequired,
  user: userShape,
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
}

NotificationCard.defaultProps = {
  onCloseClick: () => {},
  user: new User(),
  loadMore: () => {},
  hasMore: false,
}

export default NotificationCard
