import React, { useState, useMemo, useCallback, useEffect } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import classnames from 'classnames'
import { navigate } from '@reach/router'
import { connect } from 'react-redux'
import { Map } from 'immutable'
import { toast } from 'react-toastify'

import PeopleEnrolledSection from '_components/people-enrolled-section'
import ArrowLeftIcon from '_assets/icons/arrow-left.svg'
import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import { linearGradientBg, RoleType } from '_utils/constants'
import Button, { ButtonTheme } from '_components/button'
import CourseIntention from '_components/course-intention'
import Footer from '_components/footer'
import CourseSection from '_components/course-section'
import { classShape } from '_utils/proptypes'
import { groupAssignmentBySection } from '_utils/helpers'
import {
  updateAssignment,
  UPDATE_ASSIGNMENT,
  updateIntention,
  UPDATE_INTENTION,
  startAssignmentVideo,
  START_CALL,
  enableAssignmentVideoCall,
} from '_modules/class/actions'
import Toastr, { ToastrTheme } from '_components/toastr'
import { usePrevious } from '_utils/hooks'
import { onMouseDown } from '_utils/aria'
import Modal from '_components/modal'
import ModalTextarea from '_components/modal-textarea'
import { sendDirectMessage, SEND_DIRECT_MESSAGE } from '_modules/chat/actions'

import styles from './styles.css'

const onBackClick = event => {
  event.preventDefault()
  navigate('/courses')
}

const onManageAccountClick = () => navigate('/account')

const MODAL_TYPE = {
  EDIT_COURSE_INTENTION: 'edit-course-intention',
}
const mapStateToProps = ({ loading, error }) => ({
  isUpdatingAssignment: !!loading.get(UPDATE_ASSIGNMENT.ACTION),
  updateAssigmentError: error.get(UPDATE_ASSIGNMENT.ACTION),
  isUpdatingIntention: !!loading.get(UPDATE_INTENTION.ACTION),
  updateIntentionError: error.get(UPDATE_INTENTION.ACTION),
  isSendingMessage: !!loading.get(SEND_DIRECT_MESSAGE.ACTION),
  sendMessageError: error.get(SEND_DIRECT_MESSAGE.ACTION),
  isStartingCall: !!loading.get(START_CALL.ACTION),
})

const mapDispatchToProps = {
  updateAssignmentStatus: updateAssignment,
  updateIntentionClass: updateIntention,
  sendMessage: sendDirectMessage,
  startAssignmentCall: startAssignmentVideo,
  enableVideoCall: enableAssignmentVideoCall,
}

const LeaderLedClass = ({
  className,
  myClass,
  userId,
  updateAssignmentStatus,
  isUpdatingAssignment,
  updateAssigmentError,
  onFollow,
  onUnfollow,
  updateIntentionClass,
  isUpdatingIntention,
  updateIntentionError,
  sendMessage,
  isSendingMessage,
  sendMessageError,
  startAssignmentCall,
  isStartingCall,
  enableVideoCall,
}) => {
  const [state, setState] = useState({
    title: '',
    buttonLabel: '',
    isModalOpen: false,
    modalType: null,
  })

  const mainCoach = useMemo(
    () => myClass?.instructors?.find(instructor => instructor.isMainCoach),
    [myClass]
  )

  const openModal = useCallback((modalType = null) => {
    const modalInfo = {
      title: 'Send a message',
      buttonLabel: 'Send',
    }
    if (modalType === MODAL_TYPE.EDIT_COURSE_INTENTION) {
      modalInfo.title = 'Edit course intention'
      modalInfo.buttonLabel = 'Save'
    }
    setState({ ...modalInfo, isModalOpen: true, modalType })
  }, [])

  const closeModal = useCallback(() => setState({ ...state, isModalOpen: false }), [state])

  const wasUpdatingAssignment = usePrevious(isUpdatingAssignment)

  const wasUpdatingIntention = usePrevious(isUpdatingIntention)

  const wasSendingMessage = usePrevious(isSendingMessage)

  const formattedSections = useMemo(
    () => {
      const secs = groupAssignmentBySection(myClass.assignments, 'sectionName')

      return Object.keys(secs).reduce((acc, curr, index) => {
        acc.push({
          title: String(curr),
          order: index,
          assignments: secs[curr],
        })

        return acc
      }, [])
    },
    [myClass.assignments]
  )

  const peopleEnrolled = useMemo(() => myClass.peopleEnrolled.filter(user => user.id !== userId), [
    myClass.peopleEnrolled,
    userId,
  ])

  const onCompleteTask = useCallback(
    (assignmentId, payload) => {
      updateAssignmentStatus(myClass.id, assignmentId, payload)
    },
    [myClass.id, updateAssignmentStatus]
  )

  const onUnfollowClick = useCallback(() => onUnfollow({ username: mainCoach.username }), [
    mainCoach.username,
    onUnfollow,
  ])

  const onFollowClick = useCallback(() => onFollow({ username: mainCoach.username }), [
    mainCoach.username,
    onFollow,
  ])

  const coursePicture = useMemo(
    () => ({
      backgroundImage: `${linearGradientBg}, url('${myClass.coursePicture}')`,
    }),
    [myClass.coursePicture]
  )

  const getModalValue = useCallback(
    () => {
      if (state.modalType === MODAL_TYPE.EDIT_COURSE_INTENTION) {
        return myClass.intention || ''
      }

      return ''
    },
    [myClass.intention, state.modalType]
  )

  const onModalSubmit = useCallback(
    message => {
      if (state.modalType === MODAL_TYPE.EDIT_COURSE_INTENTION) {
        updateIntentionClass({ classId: myClass.id, intention: message })
        return
      }

      sendMessage({
        message,
        username: mainCoach.username,
        name: mainCoach.name,
        photo: mainCoach.photo,
        id: mainCoach.id,
      })
    },
    [
      mainCoach.id,
      mainCoach.name,
      mainCoach.photo,
      mainCoach.username,
      myClass.id,
      sendMessage,
      state.modalType,
      updateIntentionClass,
    ]
  )

  const isInstructor = useMemo(() => myClass.isMainInstructor, [myClass])

  useEffect(
    () => {
      if (wasUpdatingAssignment && !isUpdatingAssignment && updateAssigmentError.size !== 0) {
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content="The assignment couldn't be updated, please, try again."
          />
        )

        return
      }

      if (wasUpdatingIntention && !isUpdatingIntention && updateIntentionError.size === 0) {
        closeModal()
      }

      if (wasSendingMessage && !isSendingMessage && sendMessageError.size === 0) {
        closeModal()
        navigate(`/messages/?directMessage=${mainCoach.username}`)
      }
    },
    [
      closeModal,
      isSendingMessage,
      isUpdatingAssignment,
      isUpdatingIntention,
      mainCoach.username,
      myClass,
      sendMessageError.size,
      updateAssigmentError.size,
      updateIntentionError.size,
      wasSendingMessage,
      wasUpdatingAssignment,
      wasUpdatingIntention,
    ]
  )

  const isSectionBlocked = useCallback((section, prevSection) => {
    if (prevSection && prevSection.assignments[prevSection.assignments.length - 1].completed) {
      return false
    }

    return !!prevSection
  }, [])

  const onStartMeetingClick = useCallback(
    payload => startAssignmentCall({ ...payload, classId: myClass.id }),
    [myClass.id, startAssignmentCall]
  )

  const onEnableAssignmentVideoCall = useCallback(
    assignmentId => enableVideoCall({ classId: myClass.id, assignmentId }),
    [enableVideoCall, myClass.id]
  )

  return (
    <>
      <section className={classnames(styles.container, className)}>
        <div className={styles.header} style={myClass.coursePicture && coursePicture}>
          <div className={styles['title-wrapper']}>
            <button
              type="button"
              onClick={onBackClick}
              onMouseDown={onMouseDown}
              className={styles['back-button']}
            >
              <svg
                className={styles['back-icon']}
                aria-label="Back icon"
                role="img"
                viewBox={ArrowLeftIcon.viewBox}
              >
                <use xlinkHref={`#${ArrowLeftIcon.id}`} />
              </svg>
            </button>
            <h1 className={styles.title}>{myClass.name}</h1>
          </div>
        </div>
        <div className={styles.content}>
          <div className={styles.leader}>
            <ProfileAvatar
              className={styles.avatar}
              avatarClassName={styles['avatar-border']}
              username={mainCoach.username}
              src={mainCoach.photo}
              roleType={RoleType[mainCoach.role]}
              size={AvatarSize.LARGE}
              isResponsive
            />
            <div className={styles.profile}>
              <div className={styles.user}>
                <h2 className={styles.name}>{mainCoach.name}</h2>
                <p className={styles.username}>@{mainCoach.username}</p>
              </div>
              <div className={styles.actions}>
                {!isInstructor ? (
                  <>
                    <Button
                      theme={mainCoach.following ? ButtonTheme.SECONDARY : ButtonTheme.PRIMARY}
                      label={mainCoach.following ? 'FOLLOWING' : 'FOLLOW'}
                      onClick={mainCoach.following ? onUnfollowClick : onFollowClick}
                    />
                    <Button theme={ButtonTheme.PRIMARY} label="MESSAGE" onClick={openModal} />
                  </>
                ) : (
                  <Button
                    theme={ButtonTheme.PRIMARY}
                    label="MANAGE YOUR ACCOUNT"
                    onClick={onManageAccountClick}
                  />
                )}
              </div>
            </div>
          </div>
          <p className={styles.description}>{myClass.courseDescription}</p>
          <CourseIntention
            className={styles['course-intention']}
            onClick={openModal}
            intention={myClass.intention}
            id={MODAL_TYPE.EDIT_COURSE_INTENTION}
          />
          <PeopleEnrolledSection
            className={styles['enrolled-people']}
            isEnrolled
            usersEnrolled={peopleEnrolled}
            onFollow={onFollow}
            onUnfollow={onUnfollow}
            classId={myClass.id}
          />
          {formattedSections.map((section, index, array) => (
            <CourseSection
              key={`${section.title}-${section.order}`}
              className={styles['course-assignments']}
              title={section.title}
              isLeaderLed
              assignments={section.assignments}
              onCompleteTask={onCompleteTask}
              isInstructor={isInstructor}
              blockedSection={isSectionBlocked(section, array[index - 1])}
              onStartMeeting={onStartMeetingClick}
              isStartingCall={isStartingCall}
              enableAssignmentVideoCall={onEnableAssignmentVideoCall}
            />
          ))}
        </div>
        <Footer />
      </section>
      {state.isModalOpen && (
        <Modal isOpen onClose={closeModal}>
          <ModalTextarea
            title={state.title}
            buttonLabel={state.buttonLabel}
            onSubmit={onModalSubmit}
            onClose={closeModal}
            value={getModalValue()}
            isLoading={isUpdatingIntention || isSendingMessage}
          />
        </Modal>
      )}
    </>
  )
}

LeaderLedClass.propTypes = {
  className: PropTypes.string,
  myClass: classShape.isRequired,
  userId: PropTypes.number.isRequired,
  updateAssignmentStatus: PropTypes.func.isRequired,
  updateIntentionClass: PropTypes.func.isRequired,
  isUpdatingAssignment: PropTypes.bool.isRequired,
  isUpdatingIntention: PropTypes.bool.isRequired,
  updateAssigmentError: ImmutablePropTypes.map,
  updateIntentionError: ImmutablePropTypes.map,
  onFollow: PropTypes.func,
  onUnfollow: PropTypes.func,
  sendMessage: PropTypes.func.isRequired,
  isSendingMessage: PropTypes.bool.isRequired,
  sendMessageError: ImmutablePropTypes.map,
  startAssignmentCall: PropTypes.func.isRequired,
  isStartingCall: PropTypes.bool.isRequired,
  enableVideoCall: PropTypes.func,
}

LeaderLedClass.defaultProps = {
  className: '',
  updateAssigmentError: Map(),
  updateIntentionError: Map(),
  onFollow: () => {},
  onUnfollow: () => {},
  sendMessageError: Map(),
  enableVideoCall: () => {},
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(LeaderLedClass))
