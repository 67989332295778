import React, { useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import download from 'downloadjs'
import { navigate } from '@reach/router'
import Helmet from 'react-helmet'

import Header from '_components/header'
import authRoute from '_hocs/auth-route'
import Card from '_components/card'
import Button, { ButtonTheme } from '_components/button'
import { exportGoals } from '_services/user'
import Footer from '_components/footer'
import withWebSocket from '_hocs/websocket'
import PROFILE_ACTIONS from '_modules/profile/actions'
import { goalCategoryShape } from '_utils/proptypes'
import favicon16 from '_assets/public/favicon-16x16.png'

import styles from './styles.css'

const { getSystemUser } = PROFILE_ACTIONS

const mapStateToProps = ({ user, profile }) => ({
  authToken: user.authToken,
  username: user.username,
  goalCategories: profile.get('goalCategories').toJS(),
})

const mapDispatchToProps = {
  getUserProfile: getSystemUser,
}

const SubscriptionExpired = ({ authToken, location, username, getUserProfile, goalCategories }) => {
  useEffect(
    () => {
      getUserProfile(username)
    },
    [getUserProfile, username]
  )

  const onExportGoalToPDFClick = useCallback(
    () => {
      exportGoals(authToken)
        .then(blob => {
          download(blob, 'my-future.pdf')
        })
        .catch(res => {
          console.warn(res.statusText)
        })
    },
    [authToken]
  )

  const onEditPaymentInfo = useCallback(() => {
    navigate('/account', { state: { billing: true } })
  }, [])

  const hasGoals = useMemo(() => goalCategories.some(goal => goal.goals.length), [goalCategories])

  return (
    <div className={styles['suspended-container']}>
      <Helmet
        title="Lightyear"
        link={[{ rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16 }]}
      />
      <Header className={styles.header} location={location} suspended />
      <div className={styles.container}>
        <Card className={styles.card}>
          <div className={styles['card-header']}>
            <h2 className={styles.title}>OOPS!</h2>
          </div>
          <p>
            Looks like your payment method has expired. Please update your payment information, and
            we&apos;ll start your access back up immediately.
          </p>
          <p>
            If you do not wish to continue with your program, you can download a PDF of your Vision
            and Goals to take with you. Come back whenever you&apos;re ready!
          </p>
          <div className={styles['buttons-container']}>
            <Button
              theme={ButtonTheme.PRIMARY}
              label="Edit payment info"
              onClick={onEditPaymentInfo}
            />
            {hasGoals && (
              <Button
                theme={ButtonTheme.SECONDARY}
                label="View Goals PDF"
                onClick={onExportGoalToPDFClick}
              />
            )}
          </div>
        </Card>
      </div>
      <Footer className={styles.footer} />
    </div>
  )
}

SubscriptionExpired.propTypes = {
  authToken: PropTypes.string.isRequired,
  location: PropTypes.shape().isRequired,
  username: PropTypes.string.isRequired,
  getUserProfile: PropTypes.func.isRequired,
  goalCategories: goalCategoryShape.isRequired,
}

export default authRoute(
  withWebSocket(
    connect(
      mapStateToProps,
      mapDispatchToProps
    )(React.memo(SubscriptionExpired))
  )
)
