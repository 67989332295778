import moment from 'moment'

export const getDateAndTime = date => {
  if (!date) {
    return {
      date: '',
      time: '',
    }
  }
  const momentDate = moment(date)

  const formattedDate = momentDate.format('MMM Do, YYYY')
  const formattedTime = momentDate.format('LT')

  return {
    date: formattedDate,
    time: formattedTime,
  }
}

export const getFormattedDate = (startDate, endDate) => {
  if (!startDate && !endDate) {
    return ''
  }

  if ((startDate && !endDate) || startDate === endDate) {
    return startDate
  }

  if (!startDate && endDate) {
    return endDate
  }

  const startYear = startDate.split(',')[1]
  const endYear = endDate.split(',')[1]
  const start = startDate.split(',')[0]
  const end = endDate.split(',')[0]

  if (startYear === endYear) {
    return `${start} - ${end}, ${startYear}`
  }

  return `${startDate} - ${endDate}`
}

export const getFormattedTime = (startTime, endTime) => {
  if (startTime && endTime) {
    return `${startTime} - ${endTime}`
  }

  if (startTime) {
    return startTime
  }

  if (endTime) {
    return endTime
  }

  return ''
}
