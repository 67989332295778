import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const WelcomeSection = ({ className, isCompany }) => (
  <div className={classnames(styles.container, className)}>
    <p className={styles.title}>Welcome to the Lightyear Hub!</p>
    <p className={styles.text}>
      Sign up now and get started in just {isCompany ? 3 : 4} simple steps. Get ready for an
      experience unlike anything you have ever had.
    </p>
    <p className={styles.text}>Step inside if you are looking for:</p>
    <ul className={classnames(styles.text, styles.list)}>
      <li className={styles['list-item']}>A new adventure</li>
      <li className={styles['list-item']}>
        Adventure, exploration and discovery of both the world and yourself
      </li>
      <li className={styles['list-item']}>A community to accelerate your dreams and goals</li>
    </ul>
    <p className={styles.text}>
      Join now and start your journey with the Vision + Goals course. Everyone from Lightyear
      Founder, Susanne Conrad to the newest members of The Hub started their journey with Vision +
      Goals. It helps to set you on the path ahead to exploration and adventure!
    </p>
    <p className={styles.text}>Enroll and explore today.</p>
    <div className={styles.divider} />
    <p className={styles['more-details']}>
      Still not sure if Lightyear is for you? Try our free{' '}
      <a
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        href="https://lightyear.co/for-you"
      >
        Power Your Future
      </a>{' '}
      course and get a glimpse of Susanne Conrad and the Lightyear work or learn more at{' '}
      <a
        className={styles.link}
        target="_blank"
        rel="noopener noreferrer"
        href="https://lightyear.co/"
      >
        www.lightyear.co
      </a>
    </p>
  </div>
)

WelcomeSection.propTypes = {
  className: PropTypes.string,
  isCompany: PropTypes.bool.isRequired,
}

WelcomeSection.defaultProps = {
  className: '',
}

export default WelcomeSection
