import React, { useCallback, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import InputTrigger from 'react-input-trigger'
import classnames from 'classnames'
import Textarea from 'react-textarea-autosize'

import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import { MEDIUM_WIDTH } from '_utils/constants'

import styles from './styles.css'

const trigger = {
  keyCode: 50,
  shiftKey: typeof window !== 'undefined' && window.innerWidth > MEDIUM_WIDTH,
}

const MAX_ROWS = 20

const TaggingInput = ({
  avatarUrl,
  onChange,
  value,
  className,
  label,
  labelClassName,
  errorMessage,
  inputClassName,
  disabled,
  name,
  toggleSuggester,
  endHandler,
  inputRef,
  checkBackSpace,
  id,
  onTextAreaKeyPress,
  containerClassName,
}) => {
  const [isInputFocused, setInputFocused] = useState(false)
  const messageRef = useRef(null)

  const setRef = useCallback(
    ref => {
      messageRef.current = ref
      inputRef(ref)
    },
    [inputRef]
  )

  const inputTriggerRef = useCallback(() => messageRef.current, [messageRef])

  const toggleInputFocus = useCallback(() => {
    setInputFocused(prevState => !prevState)
  }, [])

  return (
    <div className={classnames(styles.container, containerClassName)}>
      <ProfileAvatar
        src={avatarUrl}
        size={AvatarSize.EXTRA_SMALL}
        className={styles['user-avatar']}
      />
      <div className={classnames(styles['container-input'], className)}>
        {!!label && (
          <p
            className={classnames(styles.label, labelClassName, {
              [styles['disabled-label']]: disabled,
            })}
          >
            {label}
          </p>
        )}
        <div
          className={classnames(styles['tooltip-wrapper'], inputClassName, {
            [styles['input-focus']]: isInputFocused,
            [styles['input-disabled']]: disabled,
            [styles.error]: errorMessage,
          })}
        >
          <InputTrigger
            trigger={trigger}
            onStart={toggleSuggester}
            onCancel={toggleSuggester}
            endTrigger={endHandler}
            className={styles.content}
            inputRef={inputTriggerRef}
          >
            <Textarea
              id={id}
              disabled={disabled}
              name={name}
              className={styles.input}
              placeholder="Leave a comment"
              onChange={onChange}
              value={value}
              inputRef={setRef}
              onKeyDown={checkBackSpace}
              onKeyPress={onTextAreaKeyPress}
              rows={MAX_ROWS}
              onFocus={toggleInputFocus}
              onBlur={toggleInputFocus}
            />
          </InputTrigger>
        </div>
      </div>
    </div>
  )
}
TaggingInput.propTypes = {
  avatarUrl: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  className: PropTypes.string,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  errorMessage: PropTypes.string,
  name: PropTypes.string,
  toggleSuggester: PropTypes.func,
  disabled: PropTypes.bool,
  endHandler: PropTypes.func.isRequired,
  inputRef: PropTypes.func.isRequired,
  checkBackSpace: PropTypes.func,
  id: PropTypes.number.isRequired,
  onTextAreaKeyPress: PropTypes.func,
  containerClassName: PropTypes.string,
}

TaggingInput.defaultProps = {
  avatarUrl: null,
  onChange: () => {},
  value: '',
  className: '',
  label: '',
  labelClassName: '',
  inputClassName: '',
  errorMessage: '',
  name: '',
  toggleSuggester: () => {},
  disabled: false,
  checkBackSpace: () => {},
  containerClassName: '',
  onTextAreaKeyPress: () => {},
}

export default React.memo(TaggingInput)
