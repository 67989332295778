import * as dashboardService from '_services/dashboard'
import { defineAction } from '_utils/redux'

export const GET_SUMMARY = defineAction('GET_SUMMARY')
export const GET_UPCOMING_GOALS = defineAction('GET_UPCOMING_GOALS')
export const GET_PEOPLE_YOU_MAY_KNOW = defineAction('GET_PEOPLE_YOU_MAY_KNOW')
export const GET_ANNOUNCEMENTS = defineAction('GET_ANNOUNCEMENTS')

export const getSummary = () => (dispatch, getState) => {
  dispatch({
    type: GET_SUMMARY.ACTION,
    payload: dashboardService.getSummary(getState().user.get('authToken')),
  })
}

export const getUpcomingGoals = () => (dispatch, getState) => {
  dispatch({
    type: GET_UPCOMING_GOALS.ACTION,
    payload: dashboardService.getUpcomingGoals(getState().user.get('authToken')),
  })
}

export const getPeopleYouMayKnow = () => (dispatch, getState) => {
  dispatch({
    type: GET_PEOPLE_YOU_MAY_KNOW.ACTION,
    payload: dashboardService.getPeopleYouMayKnow(getState().user.get('authToken')),
  })
}

export const getAnnouncements = () => (dispatch, getState) =>
  dispatch({
    type: GET_ANNOUNCEMENTS.ACTION,
    payload: dashboardService.getAnnouncements(getState().user.get('authToken')),
  })
