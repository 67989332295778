import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import Header from '_components/header'
import authRoute from '_hocs/auth-route'
import Footer from '_components/footer'
import withWebSocket from '_hocs/websocket'
import favicon16 from '_assets/public/favicon-16x16.png'

import styles from './styles.css'

const SuspendedUser = ({ location }) => (
    <div className={styles['suspended-container']}>
      <Helmet
        title="Lightyear"
        link={[{ rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16 }]}
      />
      <Header className={styles.header} location={location} suspended />
      <div className={styles.suspended}>
        <h2>YOUR MEMBERSHIP HAS BEEN SUSPENDED</h2>
        <p>
          If you’re not sure why, you may find more information in the page. If you believe this is
          a mistake, please contact support for more information.
        </p>
      </div>
      <Footer className={styles.footer} />
    </div>
  )

SuspendedUser.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
}

export default authRoute(withWebSocket(React.memo(SuspendedUser)))
