import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import classnames from 'classnames'
import { navigate } from '@reach/router'

import PostCard from '_components/post-card'
import { postShape, userShape } from '_utils/proptypes'
import Button, { ButtonTheme, ButtonSize } from '_components/button'

import styles from './styles.css'

const SelectedPost = ({
  className,
  post,
  user,
  myProfile,
  onSubmit,
  onPostEdit,
  onPostDelete,
  isPosting,
  postingError,
  shazam,
  unshazam,
  onHidePosts,
  onBlockUser,
  onFlagPost,
}) => {
  const onSeeProfileClick = useCallback(
    () => {
      navigate(`/user/${user.username}`)
    },
    [user.username]
  )

  return (
    <section className={classnames(styles.container, className)}>
      <h2 className={styles.title}>Selected Post</h2>
      <Button
        className={styles.button}
        theme={ButtonTheme.DEFAULT}
        size={ButtonSize.SMALL}
        label="See profile and recent posts"
        onClick={onSeeProfileClick}
      />
      <PostCard
        className={styles.content}
        key={post.id}
        id={post.id}
        post={post}
        user={user}
        isOwnPost={myProfile}
        onSubmit={onSubmit}
        onEditPost={onPostEdit}
        onDeletePost={onPostDelete}
        isPosting={isPosting}
        postingError={postingError}
        shazam={shazam}
        unshazam={unshazam}
        onHidePosts={onHidePosts}
        onBlockUser={onBlockUser}
        onFlagPost={onFlagPost}
      />
    </section>
  )
}

SelectedPost.propTypes = {
  className: PropTypes.string,
  myProfile: PropTypes.bool,
  post: postShape,
  user: userShape.isRequired,
  onSubmit: PropTypes.func,
  onPostEdit: PropTypes.func,
  onPostDelete: PropTypes.func,
  isPosting: PropTypes.bool.isRequired,
  postingError: ImmutablePropTypes.map,
  shazam: PropTypes.func,
  unshazam: PropTypes.func,
  onHidePosts: PropTypes.func,
  onBlockUser: PropTypes.func,
  onFlagPost: PropTypes.func,
}

SelectedPost.defaultProps = {
  className: '',
  myProfile: false,
  post: null,
  onSubmit: () => {},
  onPostEdit: () => {},
  onPostDelete: () => {},
  postingError: Map(),
  shazam: () => {},
  unshazam: () => {},
  onHidePosts: () => {},
  onBlockUser: () => {},
  onFlagPost: () => {},
}

export default React.memo(SelectedPost)
