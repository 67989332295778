import React, { useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'

import CourseTypesElements from '_components/course-types-elements'
import Card from '_components/card'
import CourseTag from '_components/course-tag'
import Svg from '_components/svg'
import ViewClassesLink from '_components/view-classes-link'
import { COURSE_TYPES, COURSE_TYPES_INFO, MAIN_PROGRAM_COURSES_NAMES } from '_constants/course'
import Button, { ButtonTheme } from '_components/landing-components/button'
import SectionTitle from '_components/landing-components/section-title'
import DotHorizontalLineIcon from '_assets/icons/dot-horizontal-line-icon.svg'
import { getCategoryClassesTotalCountSelector } from '_modules/public-categories/selectors'
import { getCategoryClassesTotalCount } from '_modules/public-categories/actions'

import styles from './styles.css'

const CourseTypes = ({ id, slice }) => {
  const workshopsAvailable = useSelector(state =>
    getCategoryClassesTotalCountSelector(state, COURSE_TYPES_INFO[COURSE_TYPES.CORE_WORKSHOPS].name)
  )

  const initialWorkshopSearch = useMemo(
    () => ({
      ...COURSE_TYPES_INFO.WORKSHOPS.state,
    }),
    []
  )

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(getCategoryClassesTotalCount({ names: MAIN_PROGRAM_COURSES_NAMES }))
  }, []) // eslint-disable-line

  return (
    <section id={id} className={styles['course-types-container']}>
      <Svg icon={DotHorizontalLineIcon} className={styles['dot-icon']} />
      <SectionTitle className={styles['course-types-title']} title={slice?.sectionTitle} />

      <CourseTypesElements />

      <Card className={styles['workshop-card']}>
        <Svg className={styles['workshop-icon']} icon={COURSE_TYPES_INFO.CORE_WORKSHOPS.icon} />
        {COURSE_TYPES_INFO.CORE_WORKSHOPS.courseTags.GENERAL_TAGS.map(tag => (
          <CourseTag className={styles['course-tags']} key={tag} text={tag} />
        ))}
        <h1 className={styles['workshop-title']}>{COURSE_TYPES_INFO.CORE_WORKSHOPS.name}</h1>
        <span className={styles['workshop-text']}>
          {COURSE_TYPES_INFO.CORE_WORKSHOPS.description}
        </span>
        <ViewClassesLink
          className={styles['view-classes-link']}
          customClass
          availableClasses={workshopsAvailable}
          url="/classes/search"
          initialSearchState={initialWorkshopSearch}
        />
      </Card>

      <Button
        className={styles['look-courses-button']}
        theme={ButtonTheme.MALIBU}
        url="/classes/search"
      >
        Look at programs
      </Button>
    </section>
  )
}

CourseTypes.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.arrayOf(
        PropTypes.arrayOf(
          PropTypes.arrayOf(
            PropTypes.shape({
              sectionTitle: PropTypes.string,
            })
          )
        )
      ),
    ]),
  }).isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
}

export default CourseTypes
