import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import InputMask from 'react-input-mask'

import ShowPasswordIcon from '_assets/icons/password-show.svg'
import HidePasswordIcon from '_assets/icons/password-hide.svg'
import HelperIcon from '_assets/icons/exclamation-mark.svg'
import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

export const INPUT_TYPE = {
  TEXT: 'text',
  PASSWORD: 'password',
  SEARCH: 'search',
}

export const INPUT_MASKS = {
  DATE: '99/99/9999',
  DEFAULT: '',
}

const Input = ({
  className,
  inputClassName,
  icon,
  type,
  helperText,
  hasHelperText,
  error,
  disabled,
  mask,
  ...props
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const onToggleShowPassword = useCallback(() => {
    setShowPassword(prevState => !prevState)
  }, [])

  const hasIcon = useMemo(() => !!Object.keys(icon).length, [icon])
  return (
    <div
      className={classnames(styles['input-container'], className, {
        [styles.error]: error,
        [styles['no-helper-text']]: !helperText && hasHelperText,
        [styles.disabled]: disabled,
      })}
    >
      <div
        className={classnames(styles['input-wrapper'], {
          [styles['icon-input']]: hasIcon,
          [styles['password-input']]: type === INPUT_TYPE.PASSWORD,
        })}
      >
        {hasIcon && (
          <svg className={styles.icon} viewBox={icon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${icon.id}`} />
          </svg>
        )}
        {mask ? (
          <InputMask
            mask={mask}
            className={classnames(
              styles.input,
              { [styles['search-input']]: type === INPUT_TYPE.SEARCH },
              inputClassName
            )}
            disabled={disabled}
            {...props}
          />
        ) : (
          <input
            className={classnames(
              styles.input,
              { [styles['search-input']]: type === INPUT_TYPE.SEARCH },
              inputClassName
            )}
            type={showPassword ? INPUT_TYPE.TEXT : type}
            disabled={disabled}
            {...props}
          />
        )}
        {type === INPUT_TYPE.PASSWORD && (
          <button
            type="button"
            onClick={onToggleShowPassword}
            aria-label="Show password"
            className={styles['show-password-button']}
            onMouseDown={onMouseDown}
            disabled={disabled}
          >
            <svg
              className={styles['show-password-icon']}
              viewBox={showPassword ? HidePasswordIcon.viewBox : ShowPasswordIcon.viewBox}
              aria-hidden="true"
            >
              <use xlinkHref={`#${showPassword ? HidePasswordIcon.id : ShowPasswordIcon.id}`} />
            </svg>
          </button>
        )}
      </div>
      {helperText && (
        <p className={styles['helper-text']}>
          <svg className={styles['helper-icon']} viewBox={HelperIcon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${HelperIcon.id}`} />
          </svg>
          {helperText}
        </p>
      )}
    </div>
  )
}

Input.propTypes = {
  className: PropTypes.string,
  inputClassName: PropTypes.string,
  icon: PropTypes.shape({
    viewBox: PropTypes.string,
    id: PropTypes.string,
  }),
  type: PropTypes.string,
  helperText: PropTypes.string,
  hasHelperText: PropTypes.bool,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  mask: PropTypes.oneOf(Object.values(INPUT_MASKS)),
}

Input.defaultProps = {
  className: '',
  inputClassName: '',
  icon: {},
  type: 'text',
  helperText: '',
  hasHelperText: false,
  error: false,
  disabled: false,
  mask: INPUT_MASKS.DEFAULT,
}

export default Input
