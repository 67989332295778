import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { useSelector } from 'react-redux'

import { FACEBOOK_APP_ID } from '_config/environment'
import DefaultImage from '_assets/public/lightyear-seo-picture.png'
import { getPathSelector } from '_modules/prismic/selectors'

const DEFAULT_SEO = {
  TITLE: 'Lightyear Leadership',
  DESCRIPTION: 'Tools to put your future in your hands and start living a life you love - today.',
  IMAGE: DefaultImage,
  KEYWORDS:
    'Lightyear Leadership, Power My Future, Vision + Goals, Susanne Conrad, Integrated Leadership, Lululemon, self-leadership, self-love, vision, goals,',
  CARD_TYPE: 'standard_summary_card',
}

const getUrl = url => {
  if (!url) {
    return 'https://www.lightyear.co'
  }

  if (!url.includes('lightyear')) {
    return 'http://localhost:3000'
  }

  if (url.includes('staging')) {
    return 'https://staging.lightyear.co'
  }

  return 'https://www.lightyear.co'
}

const SEO = ({
  schema,
  title,
  description,
  cardType,
  url,
  contentType,
  image,
  twitterDescription,
  twitterTitle,
  twitterImage,
  keywords,
}) => {
  const path = useSelector(getPathSelector)
  const appUrl = useMemo(() => getUrl(url), [url])
  const completeUrl = useMemo(() => `${appUrl}${path}`, [appUrl, path])

  const seo = useMemo(
    () => ({
      title: title || DEFAULT_SEO.TITLE,
      description: description || DEFAULT_SEO.DESCRIPTION,
      image: image || `${appUrl}${DEFAULT_SEO.IMAGE}`,
      keywords: keywords || DEFAULT_SEO.KEYWORDS,
      cardType: cardType || DEFAULT_SEO.CARD_TYPE,
      twitterTitle: twitterTitle || title || DEFAULT_SEO.TITLE,
      twitterDescription: twitterDescription || description || DEFAULT_SEO.DESCRIPTION,
      twitterImage: twitterImage || image || `${appUrl}${DEFAULT_SEO.IMAGE}`,
    }),
    [
      appUrl,
      cardType,
      description,
      image,
      keywords,
      title,
      twitterDescription,
      twitterImage,
      twitterTitle,
    ]
  )

  const getMetaTags = useMemo(
    () => [
      { itemprop: 'name', content: seo.title },
      { itemprop: 'description', content: seo.description },
      { itemprop: 'image', content: seo.image },
      { name: 'description', content: seo.description },
      { name: 'keywords', content: seo.keywords },
      { name: 'twitter:card', content: seo.cardType },
      { name: 'twitter:title', content: seo.twitterTitle },
      { name: 'twitter:description', content: seo.twitterDescription },
      { name: 'twitter:image:src', content: seo.twitterImage },
      { property: 'og:title', content: seo.title },
      { property: 'og:locale', content: 'en_US' },
      { property: 'og:type', content: contentType },
      { property: 'og:url', content: completeUrl },
      { property: 'og:image', content: seo.image },
      { property: 'og:image:secure_url', content: seo.image },
      { property: 'og:image:type', content: 'image/png' },
      { property: 'og:description', content: seo.description },
      { property: 'og:site_name', content: 'Lightyear' },
      { property: 'fb:app_id', content: FACEBOOK_APP_ID },
    ],
    [seo, contentType, completeUrl]
  )

  return (
    <Helmet
      htmlAttributes={{
        lang: 'en',
        itemscope: undefined,
        itemtype: `http://schema.org/${schema}`,
      }}
      title={seo.title}
      link={[{ rel: 'canonical', href: completeUrl }]}
      meta={getMetaTags}
    />
  )
}

SEO.propTypes = {
  schema: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  cardType: PropTypes.string,
  url: PropTypes.string,
  contentType: PropTypes.string,
  image: PropTypes.string,
  twitterDescription: PropTypes.string,
  twitterTitle: PropTypes.string,
  twitterImage: PropTypes.string,
  keywords: PropTypes.string,
}

SEO.defaultProps = {
  schema: '',
  title: '',
  description: '',
  cardType: '',
  url: '',
  contentType: 'website',
  image: '',
  twitterDescription: '',
  twitterTitle: '',
  twitterImage: '',
  keywords: '',
}

export default SEO
