import React from 'react'

import ConductItems from '../conduct-item'

import styles from './styles.css'

const WhatToDo = {
  title: 'What to do',
  items: [
    'Open yourself up to the possibilities of your future and allow those around you to support and lift you up!',
    'Be vulnerable, be big, and be kind to others that are doing the same.',
    'Your communications and contributions will be received and integrated by other people on the Hub, and you in turn, will be elevated by the contributions of others. So reach out and engage with both familiar and unfamiliar faces that inspire or interest you.',
    'Form groups and start conversations that put you out of your comfort zone and elevate and support your vision and goals.',
    'Engage and be thoughtful with others, as you desire them to be with you.',
    'Treat your future as something that is even more precious, inspiring, important, and something to be proud of, than the digital story you have woven of your past-for your future will soon become your chosen legacy.',
  ],
  paragraph: [
    'Go forth explorers and collaborate, push your boundaries, be vulnerable, and set your future in motion!',
  ],
}

const Helpful = {
  title: 'Among many other ways to participate wholly, we see the following as especially helpful:',
  items: [
    'Inspire by example',
    'Generate vs. consume',
    'Speak from I',
    'Keep it real and always be real',
    'Listen fully',
    'Believe in yourself and others',
    'Stand and speak up and out for yourself and others',
    'Always honor choice, commit fully (99% equals zero)',
    'Do the work!',
    'Lift each other up!',
    'Be kind not nice',
    'Find people to support your goals',
    'Plant your roots and create your community!',
  ],
}

const WhatNotToDo = {
  title: 'What NOT to do',
  paragraphBefore: [
    'You already know this part. You wouldn’t be here if you didn’t. And for good measure, we have gone ahead and jotted down some basics of what it looks like to be in violation of a space that fosters safe, whole, and correct freedom of expression.',
  ],
  items: [
    'This space is here for us all equally.',
    'Be conscious of taking up the correct amount of it and know when to leave someones space alone.',
    'Be kind and don’t violate anyone, including yourself, through language, action, or intention.',
  ],
  paragraphAfter: [
    'The following behavior is unacceptable towards anyone, including yourself, when left in a comment, in a message, on a chat, in the spirit or any other realm. None of it is good and human, and it will not be tolerated:',
    'Ridiculing or harassing, solicitation, creepy sexual weirdness of any nature, stalking or behaviors that could be interpreted as stalking, and the posting of non-contributing clutter including explicit comments, political opinions and assertions, or any other behaviors that would be deemed inappropriate, harmful, or otherwise unnecessary by the common standards of being a real, good, and kind human.',
    'This is not the place to sell yourself, your business, your services, or your devices. We ask that you please keep any inflammatory and irrelevant political remarks to yourself. If required, there is a place for that—it is called Facebook.',
    'This is now your room, his room, her room, their room, all of our room. With that, remember, “we are all responsible for the energy we bring into the room”. See you in there!',
  ],
}

const CodeOfConduct = () => (
  <div className={styles.main}>
    <h1>Code of Conduct</h1>
    <h4>Last updated January 01, 2018</h4>
    <div className={styles.items}>
      <ConductItems
        title={WhatToDo.title}
        dotItems={WhatToDo.items}
        paragraphAfter={WhatToDo.paragraph}
      />
      <ConductItems title={Helpful.title} dotItems={Helpful.items} />
      <ConductItems
        title={WhatNotToDo.title}
        paragraphBefore={WhatNotToDo.paragraphBefore}
        dotItems={WhatNotToDo.items}
        paragraphAfter={WhatNotToDo.paragraphAfter}
      />
    </div>
  </div>
)

export default CodeOfConduct
