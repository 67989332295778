import { MOBILE_THRESHOLD } from '_config/media-queries'

const MOBILE_LANDING_HEADER_SIZE = 72
const LANDING_HEADER_SIZE = 80

export default (sectionId, width) => {
  const element = document.querySelector(`section[id^=${sectionId}`)
  const elementTop = element?.getBoundingClientRect()?.top

  const headerSize = width <= MOBILE_THRESHOLD ? MOBILE_LANDING_HEADER_SIZE : LANDING_HEADER_SIZE
  const slicePosition = elementTop + window.scrollY - headerSize

  window.scrollTo({
    top: slicePosition,
    behavior: 'smooth',
  })
}
