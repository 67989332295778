import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import HelperIcon from '_assets/icons/exclamation-mark.svg'

import styles from './styles.css'

const ErrorAlertComponent = ({ className, errorMessage, isError }) => (
  <div
    className={classnames(styles['account-alert'], { [styles['error-alert']]: isError }, className)}
  >
    <svg className={styles['helper-icon']} viewBox={HelperIcon.viewBox} aria-hidden="true">
      <use xlinkHref={`#${HelperIcon.id}`} />
    </svg>
    <p className={styles['alert-text']}>{errorMessage}</p>
  </div>
)

ErrorAlertComponent.propTypes = {
  className: PropTypes.string,
  errorMessage: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  isError: PropTypes.bool,
}
ErrorAlertComponent.defaultProps = {
  className: '',
  isError: false,
}

export default React.memo(ErrorAlertComponent)
