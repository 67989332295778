import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const AnswerTextArea = ({
  type,
  className,
  questionClassName,
  question,
  placeholder,
  onChange,
  value,
  rows,
  id,
}) => (
    <div className={classnames(styles['answer-container'], className)}>
      <h3 className={classnames(styles.label, questionClassName)}>{question}</h3>
      <textarea
        className={styles['post-content']}
        onChange={onChange}
        value={value}
        placeholder={placeholder}
        id={id}
        rows={rows}
        type={type}
      />
    </div>
  )

AnswerTextArea.propTypes = {
  type: PropTypes.string,
  className: PropTypes.string,
  questionClassName: PropTypes.string,
  placeholder: PropTypes.string,
  question: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  id: PropTypes.number,
  rows: PropTypes.number,
}

AnswerTextArea.defaultProps = {
  type: 'text',
  className: '',
  questionClassName: '',
  placeholder: '',
  question: '',
  value: '',
  id: null,
  rows: 1,
  onChange: () => {},
}

export default AnswerTextArea
