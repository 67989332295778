import React, { useCallback, useReducer, useMemo, useEffect, useState } from 'react'
import { Link } from '@reach/router'
import { useWindowSize } from '@reach/window-size'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { toast } from 'react-toastify'

import LightyearLogo from '_assets/full-logo.svg'
import CheckIcon from '_assets/icons/check-green.svg'
import Input from '_components/landing-components/input'
import Button, { ButtonSize } from '_components/landing-components/button'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import { getPresetPasswordErrors } from '_utils/helpers'
import { confirmResetPassword } from '_modules/user/actions'
import {
  isConfirmResetPasswordLoadingSelector,
  confirmResetPasswordErrorSelector,
} from '_modules/user/selectors'
import usePrevious from '_hooks/use-previous'
import Toastr, { ToastrTheme } from '_components/toastr'

import styles from './styles.css'
import { reducer, INITIAL_STATE, UPDATE_STATE, RESET_ERRORS } from './reducer'

const ResetPassword = ({ uid, token }) => {
  const isResettingPassword = useSelector(isConfirmResetPasswordLoadingSelector)
  const wasResettingPassword = usePrevious(isResettingPassword)
  const resetPasswordError = useSelector(confirmResetPasswordErrorSelector)

  const { height, width } = useWindowSize()
  const dispatch = useDispatch()

  const [isPasswordUpdated, setPasswordUpdated] = useState(false)

  const [
    { password, passwordConfirmation, passwordError, passwordConfirmationError },
    dispatchState,
  ] = useReducer(reducer, INITIAL_STATE)

  const viewStyle = useMemo(
    () => {
      if (width <= MOBILE_THRESHOLD) {
        return {
          maxHeight: height,
        }
      }
      return {}
    },
    [height, width]
  )

  const onInputChange = useCallback(event => {
    const { name, value } = event.target
    const payload = {
      [name]: value,
    }

    dispatchState({
      type: UPDATE_STATE,
      payload,
    })
  }, [])

  const onContinueClick = useCallback(
    () => {
      const errors = getPresetPasswordErrors(password, passwordConfirmation)

      if (Object.values(errors).some(value => !!value)) {
        dispatchState({
          type: UPDATE_STATE,
          payload: { ...errors },
        })
      } else {
        dispatchState({
          type: RESET_ERRORS,
        })
        const payload = {
          uid,
          token,
          newPassword1: password,
          newPassword2: passwordConfirmation,
        }

        dispatch(confirmResetPassword(payload))
      }
    },
    [dispatch, password, passwordConfirmation, token, uid]
  )

  useEffect(
    () => {
      if (wasResettingPassword && !isResettingPassword) {
        if (resetPasswordError.size) {
          toast(<Toastr theme={ToastrTheme.ERROR} content={resetPasswordError} />)
        } else {
          setPasswordUpdated(true)
        }
      }
    },
    [isResettingPassword, resetPasswordError, resetPasswordError.size, wasResettingPassword]
  )

  return (
    <main className={styles.main} style={viewStyle}>
      <div className={styles.content}>
        <Link to="/">
          <svg
            className={styles.logo}
            alt="Lightyear logo"
            viewBox={LightyearLogo.viewBox}
            aria-label="Lightyear logo"
            role="img"
          >
            <use xlinkHref={`#${LightyearLogo.id}`} />
          </svg>
        </Link>
        <div className={styles.card}>
          {!isPasswordUpdated ? (
            <>
              <h1 className={styles.title}>Create a new password</h1>
              <Input
                className={styles.input}
                value={password}
                onChange={onInputChange}
                type="password"
                name="password"
                placeholder="New Password"
                error={!!passwordError}
                helperText={passwordError || 'Your password should have at least 8 characters.'}
                hasHelperText
              />
              <Input
                className={styles.input}
                value={passwordConfirmation}
                onChange={onInputChange}
                type="password"
                name="passwordConfirmation"
                placeholder="Confirm new Password"
                error={!!passwordConfirmationError}
                helperText={passwordConfirmationError}
                hasHelperText
              />
              <Button
                className={styles.button}
                type="submit"
                size={ButtonSize.BIG}
                isLoading={isResettingPassword}
                onClick={onContinueClick}
              >
                Continue
              </Button>
            </>
          ) : (
            <div className={styles['password-updated']}>
              <svg
                className={styles['check-icon']}
                alt="Lightyear logo"
                viewBox={CheckIcon.viewBox}
                aria-label="Lightyear logo"
                role="img"
              >
                <use xlinkHref={`#${CheckIcon.id}`} />
              </svg>
              <h1 className={styles.title}>Password updated!</h1>
              <p className={styles.description}>
                Please login with your new password to access your dashboard.
              </p>
              <Button className={styles.button} type="submit" size={ButtonSize.BIG} url="/login">
                Login
              </Button>
            </div>
          )}
        </div>
      </div>
    </main>
  )
}

ResetPassword.propTypes = {
  uid: PropTypes.string,
  token: PropTypes.string,
}

ResetPassword.defaultProps = {
  uid: '',
  token: '',
}

export default React.memo(ResetPassword)
