import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { formatDate } from '_utils/helpers'
import { assignmentShape } from '_utils/proptypes'
import CallIcon from '_assets/icons/call.svg'
import CameraIcon from '_assets/icons/videocall.svg'
import Svg from '_components/svg'
import Card from '_components/card'

import styles from './styles.css'

const CallAssignment = ({ content, className, minuteCountdown, timeToEndMeeting }) => {
  const meeting = useMemo(
    () => ({
      started: content?.assignment?.meeting?.started,
      ended: content?.assignment?.meeting?.ended,
      startDate: formatDate(content?.assignment?.meeting?.startTime, 'MMM dd, yyyy'),
      startTime: formatDate(content?.assignment?.meeting?.startTime, 'p'),
      duration: `${content?.assignment?.meeting?.duration} minutes`,
    }),
    [content]
  )

  const callDateText = useMemo(
    () => {
      if (meeting.ended) {
        return `Call ended on`
      }

      if (meeting.started) {
        return `Live now`
      }

      return `Starts in`
    },
    [meeting.ended, meeting.started]
  )

  const callBannerText = useMemo(
    () => {
      if (meeting?.started) {
        return (
          <p>
            The call is <span className={styles['call-start-time-difference']}>live now!</span>
          </p>
        )
      }

      return (
        <p>
          This call starts {minuteCountdown <= 0 ? '' : 'in '}
          <span className={styles['call-start-time-difference']}>
            {minuteCountdown <= 0
              ? `now`
              : `${minuteCountdown} minute${minuteCountdown > 1 ? 's' : ''}`}
          </span>
        </p>
      )
    },
    [meeting, minuteCountdown]
  )

  return (
    <div className={classnames(styles['call-assignment-container'], className)}>
      <Card className={styles['call-assignment-box']}>
        <div className={styles['icon-wrapper']}>
          <Svg className={styles['call-icon']} icon={CallIcon} />
        </div>
        <div className={styles['call-assignment-content']}>
          <p className={styles['assignment-description']}>
            {callDateText} {meeting.startDate}
          </p>
          <p>
            {meeting?.startTime} • {meeting?.duration} - Your local date and time
          </p>
        </div>
        {timeToEndMeeting >= 0 &&
          minuteCountdown < 10 &&
          !meeting.ended && (
            <div className={styles['call-banner']}>
              <Svg className={styles['video-call-icon']} icon={CameraIcon} />
              {callBannerText}
            </div>
          )}
      </Card>
    </div>
  )
}

CallAssignment.propTypes = {
  content: assignmentShape.isRequired,
  className: PropTypes.string,
  minuteCountdown: PropTypes.string.isRequired,
  timeToEndMeeting: PropTypes.string.isRequired,
}

CallAssignment.defaultProps = {
  className: '',
}

export default CallAssignment
