import React from 'react'
import { Router as ReachRouter } from '@reach/router'

import ClassHubDetails from '_views/class-hub-details'
import Landing from '_views/landing'
import EmailChange from '_views/email-change'
import App from '_views/app'
import NotFoundPage from '_views/not-found'
import Dashboard from '_views/dashboard'
import Courses from '_views/courses'
import CourseDetails from '_views/course-details'
import Class from '_views/class'
import AssignmentDetails from '_views/assignment-details'
import Messages from '_views/messages'
import SignInSignUp from '_views/sign-in-sign-up'
import Onboarding from '_views/onboarding'
import CourseList from '_views/onboarding/course-list'
import Course from '_views/onboarding/course'
import Profile from '_views/onboarding/profile'
import Search from '_views/search'
import AccountSettings from '_views/account-settings'
import Participants from '_views/participants'
import TermPrivacyCode from '_views/terms-policy-code'
import TermsOfUse from '_views/terms-policy-code/terms-of-use'
import CodeOfConduct from '_views/terms-policy-code/code-of-conduct'
import PrivacyPolicy from '_views/terms-policy-code/privacy-policy'
import UserProfile from '_views/user-profile'
import Zoom from '_views/zoom'
import SubscriptionExpired from '_views/subscription-expired'
import SuspendedUser from '_views/suspended-user'
import ResetPassword from '_views/reset-password'
import ClassDetails from '_views/class-details'
import AllClasses from '_views/all-classes'
import { FLAG_CLASS_HUB_DETAILS } from '_config/environment'

const Router = () => (
  <ReachRouter>
    <Landing path="/" />
    <Landing path="/memberships" />
    <App path="/">
      <Dashboard path="/dashboard" />
      <Courses path="courses" />
      <CourseDetails path="course/:classId" />
      {FLAG_CLASS_HUB_DETAILS ? (
        <ClassHubDetails path="class/:classId" />
      ) : (
        <Class path="class/:classId" />
      )}
      <Participants path="class/:classId/participants" />
      <AssignmentDetails path="class/:classId/assignment/:assignmentId" />
      <AssignmentDetails path="class/:classId/assignment/:assignmentId/responses" />
      <Messages path="messages" />
      <Search path="search" />
      <AccountSettings path="account" />
      <UserProfile path="/user/:username/goals" />
      <UserProfile path="/user/:username" />
      <UserProfile path="/user/:username/post/:postId" />
      <NotFoundPage default />
    </App>
    <SuspendedUser path="suspended" />
    <SubscriptionExpired path="subscription-expired" />
    <EmailChange path="email-change/:status" />
    <SignInSignUp path="create-account" />
    <ResetPassword path="forgot-password/:uid/:token" />
    <Onboarding path="onboarding">
      <CourseList path="/" />
      <Course path="class/:id" />
      <Profile path="profile" />
      <NotFoundPage default />
    </Onboarding>
    <TermPrivacyCode path="policy">
      <TermsOfUse path="terms" />
      <CodeOfConduct path="code" />
      <PrivacyPolicy path="privacy" />
      <NotFoundPage default />
    </TermPrivacyCode>
    <Zoom path="zoom" />
    <Landing path="/course" />
    <Landing path="/courses/power-my-future" />
    <Landing path="/courses/vision-plus-goals" />
    <Landing path="/courses/integrated-leadership" />
    <Landing path="/courses/lightyear-coach-training" />
    <Landing path="/courses/workshops" />
    <Landing path="/courses/whole-prosperity" />
    <Landing path="/courses/lightyear-principles" />
    <Landing path="/courses/get-there-now" />
    <Landing path="/courses/legado-personal" />
    <Landing path="/about-us" />
    <Landing path="/login" />
    <Landing path="/sign-up" />
    <Landing path="/forgot-password" />
    <Landing path="/email-sent" />
    <Landing path="/payments" />
    <Landing path="/explore-courses" />
    <Landing path="/classes">
      <ClassDetails path="/:classId" />
      <AllClasses path="/search" />
    </Landing>
  </ReachRouter>
)

export default Router
