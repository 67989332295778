import * as notificationsService from '_services/notifications'
import { defineAction } from '_utils/redux'

export const GET_NOTIFICATIONS = defineAction('GET_NOTIFICATIONS')
export const READ_NOTIFICATIONS = defineAction('READ_NOTIFICATIONS')

export const getNotifications = (page = null) => (dispatch, getState) => {
  dispatch({
    type: GET_NOTIFICATIONS.ACTION,
    payload: notificationsService.getNotifications(getState().user.get('authToken'))(page),
    meta: {
      page,
    },
  })
}

export const readNotifications = () => (dispatch, getState) => {
  dispatch({
    type: READ_NOTIFICATIONS.ACTION,
    payload: notificationsService.readNotifications(getState().user.get('authToken')),
  })
}
