import React, { useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import { navigate, Redirect } from '@reach/router'
import { toast } from 'react-toastify'
import Helmet from 'react-helmet'

import LightyearLogo from '_assets/full-logo.svg'
import {
  VALIDATE_TOKEN,
  validateToken,
  validateOrganization,
  VALIDATE_HASH,
} from '_modules/company/actions'
import { usePrevious } from '_utils/hooks'
import Toastr, { ToastrTheme } from '_components/toastr'
import { FACEBOOK_LOGIN, GOOGLE_LOGIN } from '_modules/user/actions'
import Footer from '_components/footer'
import RocketLoader from '_components/rocket-loader'
import favicon16 from '_assets/public/favicon-16x16.png'

import SignInCard from './sign-in-card'
import WelcomeSection from './welcome-section'
import styles from './styles.css'

const mapStateToProps = ({ company, loading, error }) => ({
  company: company.toJS(),
  isVerifyingToken: !!loading.get(VALIDATE_TOKEN.ACTION),
  verifyingTokenError: error.get(VALIDATE_TOKEN.ACTION),
  facebookError: error.get(FACEBOOK_LOGIN.ACTION),
  googleError: error.get(GOOGLE_LOGIN.ACTION),
  isVerifyingHash: !!loading.get(VALIDATE_HASH.ACTION),
  verifyingHashError: error.get(VALIDATE_HASH.ACTION),
})

const mapDispatchToProps = {
  verifyToken: validateToken,
  verifyOrganizationHash: validateOrganization,
}

const SignInSignUp = ({
  location,
  company,
  isVerifyingToken,
  verifyToken,
  verifyingTokenError,
  facebookError,
  googleError,
  isVerifyingHash,
  verifyingHashError,
  verifyOrganizationHash,
}) => {
  const prevVerifyingToken = usePrevious(isVerifyingToken)
  const prevVerifyingHash = usePrevious(isVerifyingHash)

  const token = useMemo(
    () => {
      const result = location.search.match(/token=([^&]*)/)
      return result ? result[1] : ''
    },
    [location.search]
  )

  const organizationHash = useMemo(
    () => {
      const result = location.search.match(/organization=([^&]*)/)
      return result ? result[1] : ''
    },
    [location.search]
  )

  const isCompanyEmpty = useMemo(() => Object.keys(company).length === 0 || !company.email, [
    company,
  ])

  useEffect(
    () => {
      if (token.length) {
        verifyToken(token)
      }
    },
    [token, verifyToken]
  )

  useEffect(
    () => {
      if (organizationHash.length) {
        verifyOrganizationHash(organizationHash)
      }
    },
    [organizationHash, verifyOrganizationHash]
  )

  useEffect(
    () => {
      if (prevVerifyingToken && !isVerifyingToken && verifyingTokenError.size !== 0) {
        navigate('/create-account')
        toast(<Toastr theme={ToastrTheme.ERROR} content="Invalid token" />)
      }
    },
    [isVerifyingToken, prevVerifyingToken, verifyingTokenError, verifyingTokenError.size]
  )

  useEffect(
    () => {
      if (prevVerifyingHash && !isVerifyingHash && verifyingHashError.size !== 0) {
        navigate('/create-account')
        toast(<Toastr theme={ToastrTheme.ERROR} content="Invalid organization link" />)
      }
    },
    [isVerifyingHash, prevVerifyingHash, verifyingHashError.size]
  )

  useEffect(
    () => {
      if (facebookError.size !== 0) {
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content="Unable to login with Facebook, please try again."
          />
        )
      }
      if (googleError.size !== 0) {
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content="Unable to login with Google, please try again."
          />
        )
      }
    },
    [facebookError.size, googleError.size]
  )

  if (token && isCompanyEmpty) {
    return null
  }

  if (!organizationHash) {
    return <Redirect to="/" noThrow />
  }

  if (organizationHash && !company.id) {
    return (
      <div className={styles.centered}>
        <RocketLoader />
      </div>
    )
  }

  return (
    <div className={styles.container}>
      <Helmet
        title="Lightyear"
        link={[{ rel: 'icon', type: 'image/png', sizes: '16x16', href: favicon16 }]}
      />
      <div className={styles.content}>
        <div className={styles.header}>
          <svg viewBox={LightyearLogo.viewBox} aria-label="Lightyear logo" role="img">
            <use xlinkHref={`#${LightyearLogo.id}`} />
          </svg>
          {company.logo && (
            <div className={styles['partner-wrapper']}>
              <div className={styles.divider} />
              <img className={styles['partner-logo']} src={company.logo} alt={company.name} />
            </div>
          )}
        </div>
        <WelcomeSection className={styles['welcome-section']} isCompany={!isCompanyEmpty} />
        <SignInCard
          className={styles.sign}
          pathname={location.pathname}
          redirectTo={location.state?.redirectTo}
          isCompany={!isCompanyEmpty}
          organizationHash={organizationHash}
          companyId={company.id}
        />
        <Footer className={styles.footer} />
      </div>
    </div>
  )
}

SignInSignUp.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({
      redirectTo: PropTypes.string,
    }),
  }).isRequired,
  company: PropTypes.shape({
    id: PropTypes.number,
    logo: PropTypes.string,
    name: PropTypes.string,
    organization: PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
    }),
    email: PropTypes.string,
  }).isRequired,
  isVerifyingToken: PropTypes.bool.isRequired,
  verifyingTokenError: PropTypes.instanceOf(Map),
  verifyToken: PropTypes.func.isRequired,
  facebookError: PropTypes.instanceOf(Map),
  googleError: PropTypes.instanceOf(Map),
  isVerifyingHash: PropTypes.bool.isRequired,
  verifyingHashError: PropTypes.instanceOf(Map),
  verifyOrganizationHash: PropTypes.func.isRequired,
}

SignInSignUp.defaultProps = {
  verifyingTokenError: Map(),
  facebookError: Map(),
  googleError: Map(),
  verifyingHashError: Map(),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(SignInSignUp))
