import { Map, List } from 'immutable'

import { createReducer } from '_utils/redux'
import { getPage } from '_utils/helpers'
import { Post } from '_models/'
import { HIDE_POSTS, BLOCK_USER } from '_modules/user/actions'
import {
  WEBSOCKET_BLOCKED_USER,
  WEBSOCKET_NEW_POST,
  WEBSOCKET_NEW_COMMENT,
  WEBSOCKET_POST_UPDATED,
  WEBSOCKET_COMMENT_UPDATED,
  WEBSOCKET_POST_SHAZAMED,
  WEBSOCKET_POST_UNSHAZAMED,
  WEBSOCKET_COMMENT_SHAZAMED,
  WEBSOCKET_COMMENT_UNSHAZAMED,
} from '_modules/websocket/actions'

import POST_ACTIONS from './actions'

const {
  GET_POSTS,
  GET_FOLLOWING_POSTS,
  CREATE_POST,
  EDIT_POST,
  SHAZAM_POST,
  UNSHAZAM_POST,
  DELETE_POST,
} = POST_ACTIONS

const INITIAL_STATE = Map({
  count: undefined,
  next: undefined,
  previous: undefined,
  posts: List(),
  following: List(),
})

const transformShazamedPost = (state, meta, shazamed) => {
  const searchPostId = meta.fatherPostId ? meta.fatherPostId : meta.postId
  const postIndex = state.get('posts').findIndex(post => post.get('id') === searchPostId)

  if (meta.fatherPostId) {
    const commentIndex = state
      .getIn(['posts', postIndex, 'comments'])
      .findIndex(comment => comment.get('id') === meta.postId)

    const newComment = state
      .getIn(['posts', postIndex, 'comments', commentIndex])
      .set('shazamedByYou', shazamed)
      .set('shazams', meta.shazams)

    return state.setIn(['posts', postIndex, 'comments', commentIndex], newComment)
  }

  const newPost = state
    .getIn(['posts', postIndex])
    .set('shazamedByYou', shazamed)
    .set('shazams', meta.shazams)

  return state.setIn(['posts', postIndex], newPost)
}

const returnNewPostsState = (state, payload, meta, key) => {
  const newPostsList = List(payload.results.map(post => new Post(post)))

  const newPostsState = meta.isNextPage ? state.get(key).concat(newPostsList) : newPostsList

  return state
    .set('count', payload.count)
    .set('next', payload.next ? getPage(payload.next) : undefined)
    .set('previous', payload.previous ? getPage(payload.previous) : undefined)
    .set(key, newPostsState)
}

const updateComment = (state, payload) => {
  try {
    const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.postId)

    const commentIndex = state
      .getIn(['posts', postIndex, 'comments'])
      .findIndex(comment => comment.get('id') === payload.comment.id)

    return state.setIn(['posts', postIndex, 'comments', commentIndex], new Post(payload.comment))
  } catch (err) {
    return state
  }
}

const posts = createReducer(INITIAL_STATE, {
  [GET_POSTS.FULFILLED]: (state, { payload, meta }) =>
    returnNewPostsState(state, payload, meta, 'posts'),

  [GET_FOLLOWING_POSTS.FULFILLED]: (state, { payload, meta }) =>
    returnNewPostsState(state, payload, meta, 'following'),

  [CREATE_POST.FULFILLED]: (state, { payload, meta }) => {
    const newPost = new Post(payload)

    if (meta.fatherPostId) {
      const fatherPostIndex = state
        .get('posts')
        .findIndex(post => post.get('id') === meta.fatherPostId)

      return state.setIn(['posts', fatherPostIndex], newPost)
    }

    const postsList = List([newPost, ...state.get('posts')])

    return state.set('posts', postsList).set('count', postsList.size)
  },

  [EDIT_POST.FULFILLED]: (state, { payload }) => {
    const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.id)

    return state.setIn(['posts', postIndex], new Post(payload))
  },

  [SHAZAM_POST.FULFILLED]: (state, { meta }) => transformShazamedPost(state, meta, true),

  [UNSHAZAM_POST.FULFILLED]: (state, { meta }) => transformShazamedPost(state, meta, false),

  [DELETE_POST.FULFILLED]: (state, { meta }) => state
      .set('posts', state.get('posts').filter(post => post.get('id') !== meta.postId))
      .set('count', state.get('count') - 1),

  [HIDE_POSTS.FULFILLED]: (state, { meta }) => {
    const newPosts = state
      .get('posts')
      .filter(post => post.getIn(['author', 'username']) !== meta.username)
    return state.set('posts', newPosts).set('count', state.get('count') - newPosts.size)
  },

  [BLOCK_USER.FULFILLED]: (state, { meta }) => {
    if (meta.isTimeline) {
      return state.set(
        'posts',
        state.get('posts').filter(post => {
          if (post.getIn(['author', 'id']) !== meta.userId) {
            return post
              .get('comments')
              .filter(comment => comment.getIn(['author', 'id']) !== meta.userId)
          }

          return false
        })
      )
    }

    return state
  },

  [WEBSOCKET_BLOCKED_USER]: (state, { payload }) =>
    state.set(
      'posts',
      state.get('posts').filter(post => {
        if (post.getIn(['author', 'id']) !== payload.blockedById) {
          return post
            .get('comments')
            .filter(comment => comment.getIn(['author', 'id']) !== payload.blockedById)
        }

        return false
      })
    ),

  [WEBSOCKET_NEW_POST]: (state, { payload }) =>
    state.set('posts', state.get('posts').unshift(new Post(payload.post))),

  [WEBSOCKET_NEW_COMMENT]: (state, { payload }) => {
    const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.postId)

    if (postIndex >= 0) {
      return state.setIn(
        ['posts', postIndex, 'comments'],
        state.getIn(['posts', postIndex, 'comments']).push(new Post(payload.comment))
      )
    }

    return state
  },

  [WEBSOCKET_POST_UPDATED]: (state, { payload }) => {
    const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.post.id)

    if (postIndex >= 0) {
      return state.setIn(['posts', postIndex], new Post(payload.post))
    }

    return state
  },

  [WEBSOCKET_COMMENT_UPDATED]: (state, { payload }) => {
    const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.postId)

    if (postIndex >= 0) {
      const commentIndex = state
        .getIn(['posts', postIndex, 'comments'])
        .findIndex(comment => comment.get('id') === payload.comment.id)

      if (commentIndex >= 0) {
        return state.setIn(
          ['posts', postIndex, 'comments', commentIndex],
          new Post(payload.comment)
        )
      }
    }

    return state
  },

  [WEBSOCKET_POST_SHAZAMED]: (state, { payload }) => {
    const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.post.id)

    if (postIndex >= 0) {
      return state.setIn(
        ['posts', postIndex, 'shazams'],
        state.getIn(['posts', postIndex, 'shazams']) + 1
      )
    }

    return state
  },

  [WEBSOCKET_POST_UNSHAZAMED]: (state, { payload }) => {
    const postIndex = state.get('posts').findIndex(post => post.get('id') === payload.post.id)

    if (postIndex >= 0) {
      return state.setIn(
        ['posts', postIndex, 'shazams'],
        state.getIn(['posts', postIndex, 'shazams']) - 1
      )
    }

    return state
  },

  [WEBSOCKET_COMMENT_SHAZAMED]: (state, { payload }) => updateComment(state, payload),

  [WEBSOCKET_COMMENT_UNSHAZAMED]: (state, { payload }) => updateComment(state, payload),
})

export default posts
