import { get } from './requests'

export const getListCategoryAvailableClasses = (key, categoryName, params) => {
  const newParams = params
  if ('pageSize' in params) {
    newParams['page-size'] = params.pageSize

    delete newParams.pageSize
  }
  return get(['new', 'public', 'courses', categoryName, 'classes'], {
    key,
    params: {
      ...newParams,
    },
  })
}

export const getCategoryClassesTotalCount = params => get(['new', 'public', 'courses'], { params })
