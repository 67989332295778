import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import classnames from 'classnames'

import CloseIcon from '_assets/icons/close.svg'
import SearchInput, { SearchType } from '_components/search-input'
import { userShape } from '_utils/proptypes'
import { RoleType } from 'utils/constants'
import RocketLoader from '_components/rocket-loader'

// TODO: fix no-cycle error
// eslint-disable-next-line
import Participant from './participant'
import styles from './styles.css'

export const ParticipantModalTheme = {
  VIEW_PARTICIPANTS: 'VIEW PARTICIPANTS',
  NEW_DIRECT_MESSAGE: 'NEW DIRECT MESSAGE',
  ADD_PARTICIPANT: 'ADD PARTICIPANT',
}

const MINIMUM_MEMBERS = 3

const ParticipantsModal = ({
  className,
  onClose,
  members,
  hasMore,
  onLoadMore,
  theme,
  isAdmin,
  onSubmit,
  isLoading,
  onParticipantClick,
  onMakeAdmin,
  onRevokeAdmin,
  onRemoveFromChannel,
  onNewDirectMessage,
  numberOfMembers,
  onInviteToChannel,
}) => {
  const [search, setSearch] = useState('')

  const onSearchChange = useCallback(event => {
    setSearch(event.target.value)
  }, [])

  const onSubmitSearch = useCallback(
    event => {
      event.preventDefault()
      onSubmit(search || null)
    },
    [onSubmit, search]
  )

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.header}>
        <h2 className={styles.title}>{theme}</h2>
        <button type="button" onClick={onClose} className={styles.close}>
          <svg aria-label="Close icon" role="img" viewBox={CloseIcon.viewBox}>
            <use xlinkHref={`#${CloseIcon.id}`} />
          </svg>
        </button>
        {theme === ParticipantModalTheme.VIEW_PARTICIPANTS && (
          <p className={styles.members}>
            {numberOfMembers} {numberOfMembers === 1 ? 'member' : 'members'}
          </p>
        )}
      </div>
      <form className={styles.search} onSubmit={onSubmitSearch}>
        <SearchInput
          type={SearchType.MODAL}
          onChange={onSearchChange}
          value={search}
          disabled={isLoading}
        />
      </form>
      <div className={styles.results}>
        {isLoading && members.length === 0 ? (
          <RocketLoader />
        ) : (
          members.map((member, index) => {
            const isChannelAdmin =
              member.role === RoleType.ADMIN.toUpperCase() ||
              member.role === RoleType.LEADER.toUpperCase()

            if (index + 1 === members.length && hasMore) {
              return (
                <Waypoint key={member.id} onEnter={onLoadMore}>
                  <Participant
                    key={member.id}
                    className={styles.participant}
                    participant={member}
                    theme={theme}
                    isBottom={
                      members.length > MINIMUM_MEMBERS && index >= members.length - MINIMUM_MEMBERS
                    }
                    canMakeAdmin={isAdmin}
                    isChannelAdmin={isChannelAdmin}
                    onClick={onParticipantClick}
                    onMakeAdmin={onMakeAdmin}
                    onRevokeAdmin={onRevokeAdmin}
                    onRemoveFromChannel={onRemoveFromChannel}
                    onNewDirectMessage={onNewDirectMessage}
                    onInviteToChannel={onInviteToChannel}
                    isLoading={isLoading}
                  />
                </Waypoint>
              )
            }
            return (
              <Participant
                key={member.id}
                className={styles.participant}
                participant={member}
                theme={theme}
                isBottom={
                  members.length > MINIMUM_MEMBERS && index >= members.length - MINIMUM_MEMBERS
                }
                canMakeAdmin={isAdmin}
                isChannelAdmin={isChannelAdmin}
                onClick={onParticipantClick}
                onMakeAdmin={onMakeAdmin}
                onRevokeAdmin={onRevokeAdmin}
                onRemoveFromChannel={onRemoveFromChannel}
                onNewDirectMessage={onNewDirectMessage}
                onInviteToChannel={onInviteToChannel}
                isLoading={isLoading}
              />
            )
          })
        )}
      </div>
      <div className={styles.footer} />
    </div>
  )
}

ParticipantsModal.propTypes = {
  onClose: PropTypes.func,
  members: PropTypes.arrayOf(
    PropTypes.shape({
      userShape,
      following: PropTypes.bool,
    })
  ),
  hasMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  onSubmit: PropTypes.func,
  onParticipantClick: PropTypes.func,
  theme: PropTypes.string,
  isAdmin: PropTypes.bool,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  onMakeAdmin: PropTypes.func,
  onRevokeAdmin: PropTypes.func,
  onRemoveFromChannel: PropTypes.func,
  onNewDirectMessage: PropTypes.func,
  numberOfMembers: PropTypes.number,
  onInviteToChannel: PropTypes.func,
}

ParticipantsModal.defaultProps = {
  onClose: () => {},
  onLoadMore: () => {},
  onSubmit: () => {},
  onParticipantClick: () => {},
  onMakeAdmin: () => {},
  onRevokeAdmin: () => {},
  onRemoveFromChannel: () => {},
  onNewDirectMessage: () => {},
  hasMore: false,
  theme: ParticipantModalTheme.VIEW_PARTICIPANTS,
  isAdmin: false,
  members: [],
  className: '',
  isLoading: false,
  numberOfMembers: 0,
  onInviteToChannel: () => {},
}

export default ParticipantsModal
