import React, { useCallback, useState, useEffect } from 'react'
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'
import { navigate, useLocation } from '@reach/router'

import PlusIcon from '_assets/icons/plus-icon.svg'
import PaymentsForm from '_components/payments-form'
import Button from '_components/landing-components/button'
import { cardListSelector } from '_modules/card/selectors'
import PaymentsFormFields from '_components/payments-form/form-fields'
import CreditCard from '_components/checkout-modal/credit-card'
import usePrevious from '_hooks/use-previous'
import ErrorAlertComponent from '_components/error-alert-component'
import { startMembership } from '_modules/user/actions'
import MembershipPaymentsForm from '_components/memberships-checkout-modal/membership-payments-form'
import { isStartingMembershipSelector, startMembershipErrorSelector } from '_modules/user/selectors'

import styles from './styles.css'

const MembershipsPaymentForm = ({ selectedMembership }) => {
  const cardList = useSelector(cardListSelector)
  const [isAddCardOpen, setAddCardOpen] = useState(false)
  const [selectedCard, setSelectedCard] = useState('')
  const [hasError, setHasError] = useState(false)
  const [isAddNewCardLoading, setAddNewCardLoading] = useState(false)
  const [cardholderName, setCardholderName] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [addCardError, setAddCardError] = useState('')
  const [isFirstCardFetch, setFirstCardFetch] = useState(true)

  const isStartingMembershipPurchase = useSelector(isStartingMembershipSelector)
  const wasStartingMembershipPurchase = usePrevious(isStartingMembershipPurchase)
  const membershipPurchaseError = useSelector(startMembershipErrorSelector)

  useEffect(
    () => {
      if (isFirstCardFetch && cardList?.[0]) {
        setFirstCardFetch(false)
        setSelectedCard(cardList[0].id)
      }
    },
    [cardList, isFirstCardFetch]
  )

  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const onCardholderNameChange = useCallback(name => {
    setCardholderName(name)
  }, [])

  const setError = useCallback(value => {
    setHasError(value)
  }, [])

  const onSelectCard = useCallback(event => {
    const { id } = event.target
    setSelectedCard(id)
  }, [])

  const onToggleAddCard = useCallback(
    () => {
      setAddCardOpen(prevState => !prevState)
      const card = selectedCard ? '' : cardList?.[0]?.id

      setSelectedCard(card)
    },
    [cardList, selectedCard]
  )

  const onPurchaseMembershipClick = useCallback(
    payload => {
      dispatch(startMembership(payload))
    },
    [dispatch]
  )
  const onSubmitForm = useCallback(
    event => {
      event.preventDefault()

      if (addCardError) {
        setAddCardError('')
      }

      if (selectedCard) {
        const payload = {
          card_token: selectedCard,
          plan_period: selectedMembership.type,
        }

        onPurchaseMembershipClick(payload)
      } else {
        const cardElement = elements.getElement(CardNumberElement)
        setAddNewCardLoading(true)
        setLoading(true)
        stripe
          .createToken(cardElement, { name: cardholderName })
          .then(({ token }) => {
            setAddNewCardLoading(false)
            if (token.id) {
              dispatch(
                onPurchaseMembershipClick({
                  card_token: selectedCard,
                  plan_period: selectedMembership.type,
                })
              )
            } else {
              setLoading(false)
            }
          })
          .catch(() => {
            setAddNewCardLoading(false)
            setLoading(false)
          })
      }
    },
    [
      addCardError,
      cardholderName,
      dispatch,
      elements,
      onPurchaseMembershipClick,
      selectedCard,
      selectedMembership.type,
      stripe,
    ]
  )

  useEffect(
    () => {
      if (
        wasStartingMembershipPurchase &&
        !isStartingMembershipPurchase &&
        !membershipPurchaseError.size
      ) {
        setLoading(false)
        setAddNewCardLoading(false)
        navigate('/memberships')
      }
    },
    [wasStartingMembershipPurchase, isStartingMembershipPurchase, membershipPurchaseError.size]
  )

  return cardList.length ? (
    <form className={styles.form} onSubmit={onSubmitForm}>
      <h2 className={styles.title}>Select a credit card</h2>
      {membershipPurchaseError?.first() && (
        <ErrorAlertComponent
          className={styles['error-alert']}
          errorMessage={membershipPurchaseError.first()}
          isError
        />
      )}
      <div className={styles.cards}>
        {cardList.map(card => (
          <CreditCard
            key={card.id}
            id={card.id}
            card={card}
            checked={selectedCard === card.id}
            onChange={onSelectCard}
            className={styles.card}
          />
        ))}
        {isAddCardOpen ? (
          <div className={styles['add-new-card']}>
            <div className={styles['subtitle-container']}>
              <h3 className={styles.subtitle}>Add a credit card</h3>
              <button type="button" onClick={onToggleAddCard} className={styles['cancel-button']}>
                Cancel
              </button>
            </div>
            <PaymentsFormFields
              onNameChange={onCardholderNameChange}
              className={styles['membership-payment-form']}
              setError={setError}
            />
          </div>
        ) : (
          <button
            type="button"
            onClick={onToggleAddCard}
            className={styles['add-credit-card-button']}
          >
            <svg className={styles['plus-icon']} viewBox={PlusIcon.viewBox} aria-hidden="true">
              <use xlinkHref={`#${PlusIcon.id}`} />
            </svg>
            ADD A CREDIT CARD
          </button>
        )}
        {addCardError && <p className={styles['card-error']}>{addCardError}</p>}
        <Button
          className={styles['enroll-button']}
          type="submit"
          disabled={hasError || (isAddCardOpen && !cardholderName)}
          isLoading={isAddNewCardLoading || isStartingMembershipPurchase || isLoading}
        >
          PURCHASE MEMBERSHIP
        </Button>
      </div>
    </form>
  ) : (
    <div className={styles.form}>
      <h2 className={styles.title}>Enter a credit card</h2>
      <MembershipPaymentsForm selectedMembership={selectedMembership} />
    </div>
  )
}

MembershipsPaymentForm.propTypes = {
  selectedMembership: PropTypes.shape({
    type: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
}

export default React.memo(MembershipsPaymentForm)
