import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import AttachIcon from '_assets/icons/attach.svg'

// Todo: Fix this error
// eslint-disable-next-line
import { MessageTheme } from '../direct-message'

import styles from './styles.css'

const FileMessage = ({ theme, fileName, file }) => (
  <div
    className={classnames(styles['own-message'], {
      [styles['sent-message']]: theme === MessageTheme.USER_MESSAGE,
    })}
  >
    <svg
      className={styles.upload}
      aria-label="Uploaded file"
      role="img"
      viewBox={AttachIcon.viewBox}
    >
      <use xlinkHref={`#${AttachIcon.id}`} />
    </svg>
    <h3>
      <a href={file} target="_blank" rel="noopener noreferrer">
        {fileName}
      </a>
    </h3>
  </div>
)

FileMessage.propTypes = {
  fileName: PropTypes.string.isRequired,
  file: PropTypes.string.isRequired,
  theme: PropTypes.string.isRequired,
}

export default React.memo(FileMessage)
