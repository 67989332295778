import React, { useCallback, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { Link, navigate } from '@reach/router'

import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import ArrowIcon from '_assets/icons/arrow-down.svg'
import Button, { ButtonTheme } from '_components/button'
import CloseIcon from '_assets/icons/close.svg'
import { logout } from '_modules/user/actions'
import { onMouseDown, isKeyboardOrClick } from '_utils/aria'
import useModal from '_hooks/use-modal'
import { getUserSelector } from '_modules/user/selectors'

import styles from './styles.css'

const ProfileDropdown = ({ className }) => {
  const dispatch = useDispatch()
  const user = useSelector(getUserSelector)
  const [isOpen, onToggle] = useModal()
  const [dropdownRef, setDropdownRef] = useState()

  const onLogout = useCallback(
    () => {
      dispatch(logout())
    },
    [dispatch]
  )

  const setRef = useCallback(ref => {
    setDropdownRef(ref)
  }, [])

  const handleOutsideClick = useCallback(
    event => {
      if (dropdownRef.contains(event.target) && !event.target.href) {
        return
      }

      onToggle()
      document.removeEventListener('click', handleOutsideClick, false)
    },
    [dropdownRef, onToggle]
  )

  const onHandleToggle = useCallback(
    event => {
      const { key } = event

      if (isKeyboardOrClick(key)) {
        if (!isOpen) {
          document.addEventListener('click', handleOutsideClick, false)
        } else {
          document.removeEventListener('click', handleOutsideClick, false)
        }

        onToggle()
      }
    },
    [handleOutsideClick, isOpen, onToggle]
  )

  const onViewMyFutureClick = useCallback(
    () => {
      onToggle()
      document.removeEventListener('click', handleOutsideClick, false)
      navigate(`/user/${user.username}`)
    },
    [handleOutsideClick, onToggle, user.username]
  )

  useEffect(
    () => () => {
        document.removeEventListener('click', handleOutsideClick, false)
      },
    [handleOutsideClick]
  )

  return (
    <div className={classnames(styles.container, className, isOpen && styles.open)} ref={setRef}>
      <div
        role="button"
        tabIndex="0"
        className={styles['profile-wrapper']}
        onClick={onHandleToggle}
        onKeyPress={onHandleToggle}
        onMouseDown={onMouseDown}
      >
        <ProfileAvatar
          className={styles['header-avatar']}
          src={user.photo}
          size={AvatarSize.HEADER}
        />
        <div className={styles.profile}>
          <h4 className={styles.name}>{user.name}</h4>
          <h4 className={styles.company}>{user.company}</h4>
        </div>
        <svg className={styles.arrow} viewBox={ArrowIcon.viewBox}>
          <use xlinkHref={`#${ArrowIcon.id}`} />
        </svg>
      </div>
      <div className={styles.dropdown}>
        <div className={styles['profile-container']}>
          <ProfileAvatar
            className={styles['avatar-container']}
            username={user.username}
            src={user.photo}
            roleType={user.role}
            size={AvatarSize.MEDIUM}
          />
          <div className={styles['profile-details']}>
            <h3 className={styles.name}>{user.name}</h3>
            <p className={styles.occupation}>{user.title}</p>
            <p className={styles.organization}>{user.company}</p>
          </div>
          <svg
            className={styles.close}
            tabIndex="0"
            onClick={onHandleToggle}
            onKeyPress={onHandleToggle}
            viewBox={CloseIcon.viewBox}
          >
            <use xlinkHref={`#${CloseIcon.id}`} />
          </svg>
        </div>
        <div className={styles.actions}>
          <Button
            className={styles['view-future']}
            theme={ButtonTheme.PRIMARY}
            label="VIEW MY FUTURE"
            onClick={onViewMyFutureClick}
          />
          <Link to="/courses">View my classes</Link>
          <Link to="/account">Account Settings</Link>
          <button
            className={styles.logout}
            onClick={onLogout}
            type="button"
            onMouseDown={onMouseDown}
          >
            <p>Log Out</p>
          </button>
        </div>
      </div>
    </div>
  )
}

ProfileDropdown.propTypes = {
  className: PropTypes.string,
}

ProfileDropdown.defaultProps = {
  className: '',
}

export default ProfileDropdown
