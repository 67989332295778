import React, { useState, useCallback, useMemo } from 'react'
import classnames from 'classnames'
import { useWindowSize } from '@reach/window-size'
import ReactModal from 'react-modal'
import { useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import FirstStep from '_components/welcome-modal/step-one'
import SecondStep from '_components/welcome-modal/step-two'
import useBodyOverflow from '_hooks/use-body-overflow'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import CropPicture from '_components/crop-picture'
import { updateUser } from '_modules/user/actions'

import styles from './styles.css'

const STEPS = {
  WELCOME: 0,
  ADD_PROFILE: 1,
  CROP_PICTURE: 2,
}

const WelcomeModal = ({ closeModal }) => {
  useBodyOverflow(true)
  const { height, width } = useWindowSize()
  const dispatch = useDispatch()

  const [currentStep, setCurrentStep] = useState(STEPS.WELCOME)
  const [selectedPicture, setSelectedPicture] = useState('')
  const [pictureBlob, setPictureBlob] = useState(null)
  const [previousSelectedPicture, setPreviousSelectedPicture] = useState('')
  const [formValues, setFormValues] = useState({ name: '', username: '' })

  const modalStyles = useMemo(
    () => {
      if (width <= MOBILE_THRESHOLD) {
        return {
          maxHeight: height * 0.9,
        }
      }

      return {}
    },
    [height, width]
  )

  const onContinueClick = useCallback(() => setCurrentStep(prevStep => prevStep + 1), [])

  const onSaveClick = useCallback(
    values => {
      const payload = {
        name: values.name,
        username: values.username,
        completedProfile: true,
      }

      if (pictureBlob?.file) {
        payload.photo = pictureBlob.file
      }
      dispatch(updateUser(payload))
    },
    [dispatch, pictureBlob]
  )

  const onSelectedPictureFile = useCallback((file, values) => {
    setSelectedPicture(prevState => {
      if (prevState) {
        setPreviousSelectedPicture(prevState)
      }
      return file
    })
    setFormValues(values)
    setCurrentStep(STEPS.CROP_PICTURE)
  }, [])

  const handleCancelClick = useCallback(
    () => {
      if (previousSelectedPicture) {
        setSelectedPicture(previousSelectedPicture)
      }
      setCurrentStep(STEPS.ADD_PROFILE)
    },
    [previousSelectedPicture]
  )

  const savePicture = useCallback(blob => {
    setPictureBlob(blob)
    setCurrentStep(STEPS.ADD_PROFILE)
  }, [])

  const content = useMemo(
    () => {
      switch (currentStep) {
        case STEPS.WELCOME:
          return <FirstStep onContinueClick={onContinueClick} />
        case STEPS.CROP_PICTURE:
          return (
            <CropPicture
              handleCancelClick={handleCancelClick}
              originalImage={selectedPicture}
              onSavePicture={savePicture}
            />
          )
        default:
          return (
            <SecondStep
              onSaveProfile={onSaveClick}
              onSelectedPictureFile={onSelectedPictureFile}
              pictureBlob={pictureBlob}
              formValues={formValues}
              closeModal={closeModal}
            />
          )
      }
    },
    [
      currentStep,
      onContinueClick,
      handleCancelClick,
      selectedPicture,
      savePicture,
      onSaveClick,
      onSelectedPictureFile,
      pictureBlob,
      formValues,
      closeModal,
    ]
  )

  return (
    <ReactModal
      isOpen
      className={styles.modal}
      overlayClassName={styles.overlay}
      shouldCloseOnOverlayClick
      ariaHideApp={false}
    >
      <section style={modalStyles} className={styles['modal-content']}>
        {content}
        {currentStep !== 2 && (
          <div className={styles['step-indicator-container']}>
            {[...Array(2)].map((step, index) => (
              <div
                // eslint-disable-next-line
            key={index}
                className={classnames(styles.indicator, {
                  [styles['selected-indicator']]: index === currentStep,
                })}
              />
            ))}
          </div>
        )}
      </section>
    </ReactModal>
  )
}

WelcomeModal.propTypes = {
  closeModal: PropTypes.func.isRequired,
}

export default React.memo(WelcomeModal)
