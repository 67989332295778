import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import styles from './styles.css'

const RadioButton = ({ optionsList, className, onChange }) => {
  const onRadioChange = useCallback(
    event => {
      event.preventDefault()
      onChange(event.target.value)
    },
    [onChange]
  )

  const isActive = useMemo(() => optionsList.find(option => option.active).name, [optionsList])

  return (
    <div className={classnames(styles.container, className)}>
      {optionsList.map(option => (
        <div key={option.name} className={styles['radio-wrapper']}>
          <label htmlFor={option.name} className={styles.label}>
            {option.name}
            <input
              id={option.name}
              type="radio"
              className={styles.radio}
              value={option.name}
              checked={option.name === isActive}
              onChange={onRadioChange}
            />
            <span
              className={classnames(styles.checkmark, {
                [styles.checked]: option.name === isActive,
              })}
            />
          </label>
        </div>
      ))}
    </div>
  )
}

RadioButton.propTypes = {
  className: PropTypes.string,
  onChange: PropTypes.func,
  optionsList: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      active: PropTypes.bool,
    })
  ),
}

RadioButton.defaultProps = {
  className: '',
  optionsList: [],
  onChange: () => {},
}

export default RadioButton
