import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import StartQuoteIcon from '_assets/icons/quote-start.svg'
import EndQuoteIcon from '_assets/icons/quote-end.svg'
import { MEDIUM_WIDTH } from '_utils/constants'
import useWindowSize from '_hooks/use-window-size'

import styles from './styles.css'

const QuoteSection = ({ slice, id }) => {
  const size = useWindowSize()
  const isDesktop = useMemo(() => size.width > MEDIUM_WIDTH, [size.width])

  const getUserPictureStyle = useMemo(
    () => {
      if (slice.userPicture?.thumbnail) {
        return {
          backgroundImage: `url(${
            isDesktop ? slice.userPicture.thumbnail : slice.userPicture.thumbnailTablet
          })`,
        }
      }

      return {}
    },
    [isDesktop, slice.userPicture]
  )

  return (
    <section
      className={classnames(styles.section, {
        [styles['no-highlighted-user']]: !slice.isUserHighlighted,
      })}
      id={id}
    >
      <div
        className={classnames(styles.content, { [styles['max-width']]: !slice.isUserHighlighted })}
      >
        <div className={styles['quote-section']}>
          <svg
            aria-label="quote"
            role="img"
            className={classnames(styles['quote-icon'], styles.desktop)}
            viewBox={StartQuoteIcon.viewBox}
          >
            <use xlinkHref={`#${StartQuoteIcon.id}`} />
          </svg>
          <div className={styles.quote}>
            {slice.quote.map((text, index) => (
              <p className={styles['quote-text']} key={text}>
                {index === 0 && (
                  <svg
                    aria-label="quote"
                    role="img"
                    className={classnames(styles['quote-icon'], styles.mobile)}
                    viewBox={StartQuoteIcon.viewBox}
                  >
                    <use xlinkHref={`#${StartQuoteIcon.id}`} />
                  </svg>
                )}
                {text}
                {!!index && index + 1 !== slice.quote.length && <br />}
                {index + 1 === slice.quote.length && (
                  <svg
                    aria-label="quote"
                    role="img"
                    className={classnames(styles['quote-icon'], styles['end-quote'])}
                    viewBox={EndQuoteIcon.viewBox}
                  >
                    <use xlinkHref={`#${EndQuoteIcon.id}`} />
                  </svg>
                )}
              </p>
            ))}
          </div>
        </div>
        {slice.isUserHighlighted ? (
          <div className={styles['user-info']} style={getUserPictureStyle}>
            <p className={styles['user-name']}>{slice.userName}</p>
            <p className={styles['user-job']}>{slice.userJob}</p>
          </div>
        ) : (
          <div className={styles['quote-author']}>
            {slice.userPicture?.avatar && (
              <img
                alt={slice.userPicture.alt}
                src={slice.userPicture.avatar}
                className={styles.avatar}
              />
            )}
            <p className={styles['user-name']}>{slice.userName}</p>
            <p className={styles['user-job']}>{slice.userJob}</p>
          </div>
        )}
      </div>
    </section>
  )
}

QuoteSection.propTypes = {
  slice: PropTypes.shape({
    isUserHighlighted: PropTypes.bool,
    userName: PropTypes.string,
    userJob: PropTypes.string,
    userPicture: PropTypes.shape({
      alt: PropTypes.string,
      avatar: PropTypes.string,
      thumbnail: PropTypes.string,
      thumbnailTablet: PropTypes.string,
    }),
    quote: PropTypes.arrayOf(PropTypes.string),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(QuoteSection)
