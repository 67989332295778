import React, { useState, useRef, useEffect, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { Link } from '@reach/router'

import EllipsisIcon from '_assets/icons/ellipsis.svg'
import DefaultAvatar from '_assets/icons/empty-photo.svg'
// TODO: fix no-cycle error
// eslint-disable-next-line
import { ParticipantModalTheme } from '_components/participants-modal'
import Button, { ButtonTheme } from '_components/button'
import { userShape } from '_utils/proptypes'
import { RoleType } from '_utils/constants'
import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

const mapStateToProps = ({ user }) => ({
  user: user.toJS(),
})

const Participant = ({
  className,
  participant,
  theme,
  user,
  isBottom,
  canMakeAdmin,
  isChannelAdmin,
  innerRef,
  onClick,
  onMakeAdmin,
  onRevokeAdmin,
  onRemoveFromChannel,
  onNewDirectMessage,
  onInviteToChannel,
  isLoading,
}) => {
  const [isMenuOpen, setMenuOpen] = useState(false)

  const userRef = useRef(null)

  const openMenu = useCallback(status => {
    setMenuOpen(status)
  }, [])

  const setRef = useCallback(
    ref => {
      userRef.current = ref
      innerRef(ref)
    },
    [innerRef]
  )

  const handleOutsideClick = useCallback(
    event => {
      event.stopPropagation()
      // ignore clicks on the component itself
      if (userRef.current.contains(event.target)) {
        return
      }
      openMenu(false)
    },
    [openMenu]
  )

  const onAdminButtonClick = useCallback(
    event => {
      event.preventDefault()

      if (participant.admin) {
        onRevokeAdmin(participant.id)
        return
      }

      onMakeAdmin(participant.id)
    },
    [onMakeAdmin, onRevokeAdmin, participant.admin, participant.id]
  )

  useEffect(
    () => {
      if (isMenuOpen) {
        document.addEventListener('click', handleOutsideClick, false)
      }

      return () => {
        document.removeEventListener('click', handleOutsideClick, false)
      }
    },
    [handleOutsideClick, isMenuOpen]
  )

  const isAdmin = useMemo(
    () => user.role === RoleType.LEADER || user.role === RoleType.ADMIN || canMakeAdmin,
    [canMakeAdmin, user.role]
  )

  const details = useMemo(
    () => {
      if (participant.company) {
        return `${participant.company}${participant.title ? ` • ${participant.title}` : ''}`
      }

      return participant.title || ''
    },
    [participant.company, participant.title]
  )

  const onParticipantClick = useCallback(
    event => {
      event.preventDefault()
      onClick(participant)
    },
    [onClick, participant]
  )

  const onNewDirectMessageClick = useCallback(
    event => {
      event.preventDefault()
      onNewDirectMessage(participant)
    },
    [onNewDirectMessage, participant]
  )

  const onRemoveFromChannelClick = useCallback(
    event => {
      event.preventDefault()
      onRemoveFromChannel({ userId: participant.id })
    },
    [onRemoveFromChannel, participant.id]
  )

  const onAddUserClick = useCallback(
    () => {
      onInviteToChannel({ user: participant.id })
    },
    [onInviteToChannel, participant.id]
  )

  const renderOptions = useMemo(
    () => (
      <div className={classnames(styles['options-container'], { [styles['is-bottom']]: isBottom })}>
        <Link to={`/user/${participant.username}`} className={styles.button}>
          <h5>VIEW FUTURE</h5>
          <p>See their posts, goals and more</p>
        </Link>
        <button type="button" className={styles.button} onClick={onNewDirectMessageClick}>
          <h5>START A CHAT</h5>
          <p>Talk with them one to one</p>
        </button>
        {isAdmin &&
          !isChannelAdmin && (
            <>
              <button type="button" className={styles.button} onClick={onAdminButtonClick}>
                <h5>
                  <span role="img" aria-label="hidden">
                    👑
                  </span>
                  {`${participant.admin ? 'REVOKE' : 'MAKE'} ADMIN`}
                </h5>
                <p>You can change this later</p>
              </button>
              <button type="button" className={styles.button} onClick={onRemoveFromChannelClick}>
                <h5 className={styles.danger}>
                  <span role="img" aria-label="hidden">
                    👑
                  </span>
                  REMOVE FROM CHANNEL
                </h5>
                <p>You can add them back later</p>
              </button>
            </>
          )}
      </div>
    ),
    [
      isAdmin,
      isBottom,
      isChannelAdmin,
      onAdminButtonClick,
      onNewDirectMessageClick,
      onRemoveFromChannelClick,
      participant.admin,
      participant.username,
    ]
  )

  return (
    <button
      type="button"
      className={classnames(styles.action, className)}
      onMouseDown={onMouseDown}
      onClick={onParticipantClick}
    >
      <div ref={setRef} className={styles.container}>
        {participant.photo ? (
          <img
            src={participant.photo}
            alt={`${participant.name}'s avatar`}
            className={styles.avatar}
          />
        ) : (
          <svg
            aria-label={`${participant.name}'s avatar`}
            role="img"
            viewBox={DefaultAvatar.viewBox}
            className={styles.avatar}
          >
            <use xlinkHref={`#${DefaultAvatar.id}`} />
          </svg>
        )}
        {theme === ParticipantModalTheme.VIEW_PARTICIPANTS && (
          <button type="button" className={styles.options} onClick={openMenu}>
            <svg aria-label="Options" role="img" viewBox={EllipsisIcon.viewBox}>
              <use xlinkHref={`#${EllipsisIcon.id}`} />
            </svg>
            {isMenuOpen && renderOptions}
          </button>
        )}
        {theme === ParticipantModalTheme.ADD_PARTICIPANT && (
          <Button
            theme={ButtonTheme.PRIMARY}
            className={styles['add-button']}
            label="ADD"
            onClick={onAddUserClick}
            loading={isLoading}
          />
        )}
        <p className={styles.name}>{participant.name}</p>
        <p className={styles.details}>{details}</p>
      </div>
    </button>
  )
}

Participant.propTypes = {
  className: PropTypes.string,
  participant: userShape.isRequired,
  theme: PropTypes.string.isRequired,
  user: userShape.isRequired,
  isBottom: PropTypes.bool.isRequired,
  canMakeAdmin: PropTypes.bool.isRequired,
  isChannelAdmin: PropTypes.bool.isRequired,
  innerRef: PropTypes.func,
  onClick: PropTypes.func,
  onMakeAdmin: PropTypes.func,
  onRevokeAdmin: PropTypes.func,
  onRemoveFromChannel: PropTypes.func,
  onNewDirectMessage: PropTypes.func,
  onInviteToChannel: PropTypes.func,
  isLoading: PropTypes.bool,
}

Participant.defaultProps = {
  className: '',
  innerRef: () => {},
  onClick: () => {},
  onMakeAdmin: () => {},
  onRevokeAdmin: () => {},
  onRemoveFromChannel: () => {},
  onNewDirectMessage: () => {},
  onInviteToChannel: () => {},
  isLoading: false,
}

export default connect(mapStateToProps)(React.memo(Participant))
