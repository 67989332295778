import moment from 'moment'
import { createSelector } from 'reselect'

import { CLASS_TYPE } from '_constants/class'

import { GET_PUBLIC_COURSE } from './actions'

export const courseSessionsSelector = state => state.publicCourse.get('sections')

export const courseNameSelector = state => state.publicCourse.get('name')

export const getTotalClasses = state => state.publicCourse.getIn(['search', 'totalClasses'])

const getPriceValues = array => {
  const prices = array.map(item => item.get('price'))
  if (prices.size === 1) {
    return prices.first()
  }

  return {
    max: Math.max(...prices),
    min: Math.min(...prices),
  }
}

export const formatDate = date =>
  date
    ? moment(date, 'YYY-MM-DD')
        .format('MMM D')
        .toUpperCase()
    : null

const getClassesByType = (classes, typeName) => {
  const firstAvailableClass = classes.first()

  return {
    title: typeName,
    price: getPriceValues(classes),
    firstAvailableClass,
    hasMoreAvailableDates: classes.size > 1,
    classes,
  }
}

export const courseClassesSelector = state => {
  const classes = state.publicCourse.get('classes')
  const selfLed = classes.get('selfLed')
  const selfPaced = classes.get('selfPaced')
  const leaderLed = classes.get('coachLed')
  const communityLed = classes.get('communityLed')

  const formattedClasses = []

  if (leaderLed?.size) {
    formattedClasses.push(getClassesByType(leaderLed, CLASS_TYPE.COACH_LED.name))
  }

  if (selfLed?.size) {
    formattedClasses.push(getClassesByType(selfLed, CLASS_TYPE.SELF_LED?.name))
  }

  if (selfPaced?.size) {
    formattedClasses.push(getClassesByType(selfPaced, CLASS_TYPE.SELF_PACED?.name))
  }

  if (communityLed?.size) {
    formattedClasses.push(getClassesByType(communityLed, CLASS_TYPE.COMMUNITY_LED.name))
  }
  return formattedClasses
}

export const getCourseLoadingSelector = state => !!state.loading.get(GET_PUBLIC_COURSE.ACTION)

export const getCourseErrorSelector = state => state.error.get(GET_PUBLIC_COURSE.ACTION)

export const hasLeaderLedCoursesSelector = createSelector(
  [courseClassesSelector],
  courses => !!courses?.find(course => course.title === CLASS_TYPE.COACH_LED.name)?.classes?.size
)
