import React from 'react'

import LightyearLogo from '_assets/logo.svg'
import Button, { ButtonTheme } from '_components/landing-components/button'
import Svg from '_components/svg'

import styles from './styles.css'

const FOOTER_LINKS = [
  {
    label: 'Terms and Conditions',
    to: '/policy/terms',
  },
  {
    label: 'Privacy Policy',
    to: '/policy/privacy',
  },
  {
    label: 'Code of Conduct',
    to: '/policy/code',
  },
]

const HubFooter = () => (
    <footer className={styles['hub-footer']}>
      <Svg className={styles['lightyear-logo']} icon={LightyearLogo} />

      {FOOTER_LINKS.map(link => (
        <Button
          className={styles['link-button']}
          theme={ButtonTheme.TRANSPARENT}
          url={link.to}
          key={link.label}
        >
          {link.label}
        </Button>
      ))}
    </footer>
  )

export default HubFooter
