import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SectionContent from '_components/landing-components/section-content'
import SectionTitle from '_components/landing-components/section-title'
import { prismicTitlePropType, prismicContentPropType } from '_utils/proptypes'
import Button from '_components/landing-components/button'

import styles from './styles.css'

const MiniCourseSection = ({ slice, id }) => {
  const backgroundStyles = useMemo(
    () => {
      if (slice.backgroundColor) {
        return {
          background: slice.backgroundColor,
        }
      }
      return {}
    },
    [slice.backgroundColor]
  )

  return (
    <section className={styles.section} style={backgroundStyles} id={id}>
      <div className={styles.container}>
        {slice.sectionTitle && (
          <SectionTitle
            title={slice.sectionTitle}
            boldClassName={styles['bold-text']}
            className={styles.title}
          />
        )}
        {slice.content?.map((paragraph, index) => (
          <SectionContent
            // setting key as index since this array is not going to be changed
            key={`content-${index + 1}`}
            text={paragraph}
            className={styles.content}
          />
        ))}
        {slice.items?.length > 0 && (
          <ul
            className={classnames(styles.list, {
              [styles['vertical-list']]: slice.isList,
            })}
          >
            {slice.items?.map(item => (
              <li
                className={classnames(styles.item, {
                  [styles['smaller-item']]: slice.hasSmallerItems,
                })}
                key={item.itemDescription}
              >
                {item.itemIcon?.url && (
                  <img
                    src={item.itemIcon.url}
                    alt={item.itemIcon.alt || 'Item Picture'}
                    className={styles['item-picutre']}
                  />
                )}
                {item.itemDescription && (
                  <p
                    className={classnames(styles['item-text'], {
                      [styles['bold-text']]: item.itemDescriptionBold,
                    })}
                  >
                    {item.itemDescription}
                  </p>
                )}
              </li>
            ))}
          </ul>
        )}
        {slice.additionalContent?.map((paragraph, index) => (
          <SectionContent
            // setting key as index since this array is not going to be changed
            key={`additional-content-${index + 1}`}
            text={paragraph}
            className={styles['additional-content']}
          />
        ))}
        {slice.actionButton?.url && (
          <Button url={slice.actionButton.url} className={styles['action-buttons']}>
            {slice.actionButton?.label}
          </Button>
        )}
      </div>
    </section>
  )
}

MiniCourseSection.propTypes = {
  slice: PropTypes.shape({
    actionButton: PropTypes.shape({
      url: PropTypes.string,
      label: PropTypes.string,
    }),
    sectionTitle: prismicTitlePropType,
    content: prismicContentPropType,
    additionalContent: prismicContentPropType,
    hasSmallerItems: PropTypes.bool,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        itemIcon: PropTypes.shape({
          url: PropTypes.string,
          alt: PropTypes.string,
        }),
        itemDescription: PropTypes.string,
      })
    ),
    sectionText: PropTypes.string,
    isList: PropTypes.bool,
    backgroundColor: PropTypes.string,
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(MiniCourseSection)
