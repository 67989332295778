import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import Button, { ButtonTheme } from '_components/button'
import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'

import styles from './styles.css'

const HiddenProfile = ({ profilePicture, name, company, role, className, onClick, username }) => {
  const onButtonClick = useCallback(
    () => {
      onClick(username)
    },
    [onClick, username]
  )

  return (
    <div className={classnames(styles['profile-button-flexbox'], className)}>
      <div className={styles['profile-container']}>
        <ProfileAvatar className={styles.picture} size={AvatarSize.SMALL} src={profilePicture} />
        <div className={styles['user-information']}>
          <h4>{name}</h4>
          <h5>
            {company} • {role}
          </h5>
        </div>
      </div>

      <Button
        label="Unhide"
        theme={ButtonTheme.PRIMARY}
        type="button"
        className={styles.button}
        onClick={onButtonClick}
      />
    </div>
  )
}

HiddenProfile.propTypes = {
  profilePicture: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  company: PropTypes.string.isRequired,
  role: PropTypes.string.isRequired,
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  username: PropTypes.string.isRequired,
}

HiddenProfile.defaultProps = {
  className: '',
}

export default React.memo(HiddenProfile)
