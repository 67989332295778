import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { Link } from '@reach/router'

import { onMouseDown } from '_utils/aria'
import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import Like from '_components/like'
import { commentShape } from '_utils/proptypes'
import { PostActionType } from '_components/post-actions-modal'
import { tagsRegex } from '_utils/constants'

import styles from './styles.css'

const Commentary = ({ commentary, isEditable, openModal, shazam, isShazamLoading }) => {
  const renderCommentWithTags = useCallback(
    () => {
      const breakCommentLines = commentary?.message?.split('\n')

      if (!breakCommentLines?.length) {
        return null
      }

      return breakCommentLines.map((line, lineIndex) => {
        const splittedComment = line.split(' ')

        if (!splittedComment) {
          return null
        }
        return (
          <p key={`${line.length}-${lineIndex + 1}`} className={styles.text}>
            {splittedComment.map((word, index) => {
              if (tagsRegex.test(word) && word.indexOf('@') === 0) {
                return (
                  <Link
                    to={`/user/${word.split('@')[1]}`}
                    className={styles.tag}
                    key={`${index + 1}-${word}`}
                  >
                    {` ${word}`}
                  </Link>
                )
              }

              return ` ${word} `
            })}
          </p>
        )
      })
    },
    [commentary]
  )

  const onEditCommentClick = useCallback(
    event => {
      event.preventDefault()
      openModal(PostActionType.EDIT, commentary.message, commentary.id)
    },
    [commentary.id, commentary.message, openModal]
  )

  const onShazamClick = useCallback(
    () => {
      shazam({
        postId: commentary.id,
        shazamedByYou: commentary.shazamedByYou,
        isComment: true,
        shazams: commentary.shazamedByYou ? commentary.shazams - 1 : commentary.shazams + 1,
      })
    },
    [commentary.id, commentary.shazamedByYou, commentary.shazams, shazam]
  )

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <ProfileAvatar
          username={commentary.author.username}
          src={commentary.author.photo}
          size={AvatarSize.EXTRA_SMALL}
        />
        <div className={styles.author}>
          <Link className={styles.name} to={`/user/${commentary.author.username}`}>
            {commentary.author.name}
          </Link>
          <Link className={styles.username} to={`/user/${commentary.author.username}`}>
            @{commentary.author.username}
          </Link>
        </div>
      </div>
      <div className={styles.content}>
        {renderCommentWithTags()}
        <div className={styles.details}>
          <div className={styles.actions}>
            <Like
              count={commentary.shazams}
              onClick={onShazamClick}
              shazamedByYou={commentary.shazamedByYou}
              isShazamLoading={isShazamLoading}
            />
            {isEditable && (
              <>
                <div className={styles.dot} />
                <button
                  type="button"
                  className={styles.edit}
                  onClick={onEditCommentClick}
                  onMouseDown={onMouseDown}
                >
                  EDIT
                </button>
              </>
            )}
          </div>
          <div className={styles.dot} />{' '}
          <p className={styles.date}>{moment(commentary.createdAt).format('MMMM D [at] LT')}</p>
          {moment(commentary.createdAt).isBefore(commentary.updatedAt, 'second') && (
            <>
              <div className={styles.dot} /> <p className={styles.edited}>Edited</p>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

Commentary.propTypes = {
  commentary: commentShape.isRequired,
  isEditable: PropTypes.bool,
  openModal: PropTypes.func,
  shazam: PropTypes.func,
  isShazamLoading: PropTypes.bool.isRequired,
}

Commentary.defaultProps = {
  isEditable: false,
  openModal: () => {},
  shazam: () => {},
}

export default Commentary
