import { OrderedMap, Map, List } from 'immutable'
import moment from 'moment'

import { getPage } from '_utils/helpers'
import { createReducer } from '_utils/redux'
import { Channel, DirectMessage, Message } from '_models/'
import { GET_USERS_AVAILABLE_TO_CHAT, BLOCK_USER } from '_modules/user/actions'
import {
  WEBSOCKET_USER_ONLINE,
  WEBSOCKET_USER_OFFLINE,
  WEBSOCKET_DIRECT_MESSAGE_CREATED,
  WEBSOCKET_CHANNEL_MESSAGE_CREATED,
  WEBSOCKET_CHANNEL_DISABLED,
  WEBSOCKET_CHANNEL_ENABLED,
  WEBSOCKET_CHANNEL_MESSAGE_DELETED,
  WEBSOCKET_USER_REMOVED_FROM_CHANNEL,
  WEBSOCKET_MAKE_ADMIN,
  WEBSOCKET_REVOKE_ADMIN,
  WEBSOCKET_CHANNEL_MEETING_UPDATED,
  WEBSOCKET_MESSAGE_MEETING_UPDATED,
  WEBSOCKET_ADDED_TO_CHANNEL,
  WEBSOCKET_BLOCKED_USER,
} from '_modules/websocket/actions'

import {
  GET_MY_CHATS,
  GET_CHANNEL_MESSAGES,
  GET_DIRECT_MESSAGES,
  SEND_DIRECT_MESSAGE,
  SEND_CHANNEL_MESSAGE,
  READ_CHANNEL_MESSAGE,
  READ_USER_MESSAGE,
  GET_CHANNEL_USERS,
  DELETE_CHANNEL_MESSAGE,
  CREATE_PRE_DIRECT_MESSAGE,
  DISABLE_CHANNEL,
  ENABLE_CHANNEL,
  UPDATE_CHANNEL,
  MAKE_ADMIN,
  REVOKE_ADMIN,
  GET_CHANNELS,
  CREATE_CHANNEL,
  GET_USERS_AVAILABLE_TO_INVITE,
  CLEAN_INITIAL_CHANNEL,
  START_CHANNEL_VIDEO_CHAT,
  START_DIRECT_MESSAGE_VIDEO_CHAT,
  STORE_LAST_CHECKPOINT,
} from './actions'

const INITIAL_STATE = Map({
  channels: OrderedMap(),
  directMessages: OrderedMap(),
  usersAvailableToChat: Map({
    count: null,
    next: null,
    previous: null,
    users: List(),
  }),
  preDirectMessage: new DirectMessage(),
  channelsAvailable: Map({
    count: null,
    next: null,
    previous: null,
    channels: List(),
  }),
  usersAvailableToInvite: Map({
    count: null,
    next: null,
    previous: null,
    users: List(),
  }),
  checkpoint: Map({
    directChatId: null,
    channelId: null,
  }),
})

const transformListOfMessages = data => List(data.map(message => new Message(message)))

const transformListOfMaps = data =>
  List(data.map(user => Map({ admin: user.admin, ...user.member })))

const reorderedDirectMessages = ({
  state,
  lastMessage,
  username,
  isNewDirectMessage,
  recipient,
}) => {
  const newMessage = new Message(lastMessage)
  /* if the user is trying to create a new conversation with a new user */
  if (isNewDirectMessage) {
    return state
      .set(
        'directMessages',
        OrderedMap([
          [
            username,
            new DirectMessage({
              count: 1,
              lastMessage,
              user: state.getIn(['preDirectMessage', 'user']).toJS(),
              messages: [lastMessage],
              read: true,
            }),
          ],
        ]).concat(state.get('directMessages'))
      )
      .set('preDirectMessage', new DirectMessage())
  }

  /* if is a new message received and the user already have a conversation with this person */
  const directMessages = state.getIn(['directMessages', username])

  if (directMessages) {
    const copiedArray = directMessages
      .update('messages', messages => messages.unshift(newMessage))
      .set('lastMessage', newMessage)
      .set('read', recipient && username === recipient.username)

    const arrayWithoutCopied = state.deleteIn(['directMessages', username])

    return state.set(
      'directMessages',
      OrderedMap([[username, copiedArray]]).concat(arrayWithoutCopied.get('directMessages'))
    )
  }

  /* if the user received a new direct message from an unknown user */
  return state.set(
    'directMessages',
    OrderedMap([
      [
        recipient ? recipient.username : lastMessage.author.username,
        new DirectMessage({
          count: 1,
          lastMessage,
          user: recipient || lastMessage.author,
          messages: [lastMessage],
          read: recipient && recipient.username !== lastMessage.author.username,
        }),
      ],
    ]).concat(state.get('directMessages'))
  )
}

const chat = createReducer(INITIAL_STATE, {
  [GET_MY_CHATS.FULFILLED]: (state, { payload }) => {
    const channels = OrderedMap(
      payload.channels.map(channel => [
        channel.id,
        new Channel({
          ...channel,
          members: {
            users: channel.members || [],
            count: payload.count,
            next: payload.next ? getPage(payload.next) : undefined,
            previous: payload.previous ? getPage(payload.previous) : undefined,
          },
        }),
      ])
    )

    const directMessages = OrderedMap(
      payload.direct
        .sort((a, b) => moment(b.last_message.created_at) - moment(a.last_message.created_at))
        .map(directMessage => [directMessage.user.username, new DirectMessage(directMessage)])
    )
    return state.set('channels', channels).set('directMessages', directMessages)
  },

  [GET_CHANNEL_MESSAGES.FULFILLED]: (state, { payload, meta }) => {
    const currentChannel = state.getIn(['channels', meta.channelId])

    let newChannel = {
      count: payload.count,
      next: payload.next ? getPage(payload.next) : undefined,
      previous: payload.previous ? getPage(payload.previous) : undefined,
    }

    if (currentChannel) {
      const channel = currentChannel.toJS()

      newChannel = {
        ...channel,
        ...newChannel,
        messages: meta.page ? [...channel.messages, ...payload.results] : payload.results,
      }
    }

    return state.setIn(['channels', meta.channelId], new Channel(newChannel))
  },

  [CLEAN_INITIAL_CHANNEL]: state => {
    const currentChannel = state.get('channels').first()

    if (!currentChannel) return state

    return state.setIn(
      ['channels', currentChannel.get('id')],
      new Channel({
        ...currentChannel.toJS(),
        count: undefined,
        next: undefined,
        previous: undefined,
        messages: [],
      })
    )
  },

  [GET_DIRECT_MESSAGES.FULFILLED]: (state, { payload, meta }) => {
    const currentDirectMessage = state.getIn(['directMessages', meta.username]).toJS()

    return state.setIn(
      ['directMessages', meta.username],
      new DirectMessage({
        ...currentDirectMessage,
        messages: meta.page
          ? [...currentDirectMessage.messages, ...payload.results]
          : payload.results,
        count: payload.count,
        next: payload.next ? getPage(payload.next) : undefined,
        previous: payload.previous ? getPage(payload.previous) : undefined,
        lastMessage: meta.page ? currentDirectMessage.lastMessage : payload.results[0],
      })
    )
  },

  [SEND_DIRECT_MESSAGE.FULFILLED]: (state, { payload, meta }) => {
    const { isNewDirectMessage, username, recipient } = meta

    return reorderedDirectMessages({
      state,
      lastMessage: payload.results[0],
      username: isNewDirectMessage
        ? state.getIn(['preDirectMessage', 'user', 'username'])
        : username,
      isNewDirectMessage,
      recipient,
    })
  },

  [SEND_CHANNEL_MESSAGE.FULFILLED]: (state, { payload, meta }) => {
    const currentChannel = state.getIn(['channels', meta.channelId]).toJS()

    const newState = state.setIn(
      ['channels', meta.channelId],
      new Channel({
        ...currentChannel,
        count: payload.count,
        next: payload.next ? getPage(payload.next) : undefined,
        previous: payload.previous ? getPage(payload.previous) : undefined,
      })
    )

    return newState.setIn(
      ['channels', meta.channelId, 'messages'],
      transformListOfMessages([payload.results[0], ...currentChannel.messages])
    )
  },

  [READ_CHANNEL_MESSAGE.FULFILLED]: (state, { meta }) =>
    state.setIn(['channels', meta.channelId, 'read'], true),

  [READ_USER_MESSAGE.FULFILLED]: (state, { meta }) =>
    state.setIn(['directMessages', meta.username, 'read'], true),

  [GET_CHANNEL_USERS.FULFILLED]: (state, { payload, meta }) => {
    const members = Map({
      count: payload.count,
      next: payload.next ? getPage(payload.next) : undefined,
      previous: payload.previous ? getPage(payload.previous) : undefined,
      users: transformListOfMaps(payload.results),
    })

    if (!meta.page) {
      return state.setIn(['channels', meta.channelId, 'members'], members)
    }

    const currentUsers = state.getIn(['channels', meta.channelId, 'members', 'users'])

    return state.setIn(
      ['channels', meta.channelId, 'members'],
      members.set('users', currentUsers.concat(members.get('users')))
    )
  },

  [DELETE_CHANNEL_MESSAGE.FULFILLED]: (state, { meta }) =>
    state.setIn(
      ['channels', meta.channelId, 'messages'],
      state
        .getIn(['channels', meta.channelId, 'messages'])
        .filter(message => message.get('id') !== meta.messageId)
    ),

  [GET_USERS_AVAILABLE_TO_CHAT.FULFILLED]: (state, { payload, meta }) => {
    const defaultValues = {
      count: payload.count,
      next: payload.next ? getPage(payload.next) : undefined,
      previous: payload.previous ? getPage(payload.previous) : undefined,
    }

    if (meta.page) {
      return state.set(
        'usersAvailableToChat',
        Map({
          ...defaultValues,
          users: state.getIn(['usersAvailableToChat', 'users']).concat(List(payload.results)),
        })
      )
    }

    return state.set(
      'usersAvailableToChat',
      Map({
        ...defaultValues,
        users: List(payload.results),
      })
    )
  },

  [CREATE_PRE_DIRECT_MESSAGE]: (state, { payload }) =>
    state.set(
      'preDirectMessage',
      new DirectMessage({
        count: 0,
        lastMessage: {
          author: {},
          createdAt: undefined,
          file: undefined,
          filename: undefined,
          id: 0,
          message: '',
          userName: undefined,
        },
        user: payload,
        messages: [],
        read: true,
      })
    ),

  [DISABLE_CHANNEL.FULFILLED]: (state, { meta }) =>
    state.setIn(['channels', meta.channelId, 'disabled'], true),

  [ENABLE_CHANNEL.FULFILLED]: (state, { meta }) =>
    state.setIn(['channels', meta.channelId, 'disabled'], false),

  [UPDATE_CHANNEL.FULFILLED]: (state, { meta }) =>
    state.setIn(['channels', meta.channelId, 'private'], meta.private),

  [MAKE_ADMIN.FULFILLED]: (state, { meta }) =>
    state.setIn(
      ['channels', meta.channelId, 'members', 'users'],
      state
        .getIn(['channels', meta.channelId, 'members', 'users'])
        .map(user => (user.get('id') === meta.userId ? user.set('admin', true) : user))
    ),

  [REVOKE_ADMIN.FULFILLED]: (state, { meta }) =>
    state.setIn(
      ['channels', meta.channelId, 'members', 'users'],
      state
        .getIn(['channels', meta.channelId, 'members', 'users'])
        .map(user => (user.get('id') === meta.userId ? user.set('admin', false) : user))
    ),

  [GET_CHANNELS.FULFILLED]: (state, { payload, meta }) => {
    const channelsList = List(payload.results.map(channel => new Channel(channel)))

    return state.set(
      'channelsAvailable',
      Map({
        count: payload.count,
        next: payload.next ? getPage(payload.next) : undefined,
        previous: payload.previous ? getPage(payload.previous) : undefined,
        channels: meta.page
          ? state.getIn(['channelsAvailable', 'channels']).concat(channelsList)
          : channelsList,
      })
    )
  },

  [CREATE_CHANNEL.FULFILLED]: (state, { payload }) =>
    state.set('channels', state.get('channels').concat([[payload.id, new Channel(payload)]])),

  [GET_USERS_AVAILABLE_TO_INVITE.FULFILLED]: (state, { payload, meta }) => {
    const newUsers = List(payload.results)

    const newState = Map({
      count: payload.count,
      next: payload.next ? getPage(payload.next) : undefined,
      previous: payload.previous ? getPage(payload.previous) : undefined,
      users: meta.page
        ? state.getIn(['usersAvailableToInvite', 'users']).concat(newUsers)
        : newUsers,
    })

    return state.set('usersAvailableToInvite', newState)
  },

  [BLOCK_USER.FULFILLED]: (state, { meta }) => state.deleteIn(['directMessages', meta.username]),

  [START_DIRECT_MESSAGE_VIDEO_CHAT.FULFILLED]: (state, { payload, meta }) =>
    state.setIn(
      ['directMessages', meta.username, 'messages'],
      state.getIn(['directMessages', meta.username, 'messages']).unshift(new Message(payload))
    ),

  [START_CHANNEL_VIDEO_CHAT.FULFILLED]: (state, { payload, meta }) =>
    state.setIn(
      ['channels', meta.channelId, 'messages'],
      state.getIn(['channels', meta.channelId, 'messages']).unshift(new Message(payload))
    ),

  [STORE_LAST_CHECKPOINT]: (state, { payload }) => state.set('checkpoint', Map(payload)),

  /* WEBSOCKET EVENTS */

  [WEBSOCKET_USER_ONLINE]: (state, { payload }) => {
    const isUserContact = state.getIn(['directMessages', payload.userId])
    return isUserContact
      ? state.setIn(['directMessages', payload.userId, 'user', 'online'], true)
      : state
  },

  [WEBSOCKET_USER_OFFLINE]: (state, { payload }) => {
    const isUserContact = state.getIn(['directMessages', payload.userId])
    return isUserContact
      ? state.setIn(['directMessages', payload.userId, 'user', 'online'], false)
      : state
  },

  [WEBSOCKET_DIRECT_MESSAGE_CREATED]: (state, { payload }) =>
    reorderedDirectMessages({
      state,
      lastMessage: payload.message,
      username: payload.username,
      isNewDirectMessage: false,
    }),

  [WEBSOCKET_CHANNEL_MESSAGE_CREATED]: (state, { payload }) => {
    const arrayOfMessages = state.getIn(['channels', payload.channelId, 'messages'])
    const newState = state
      .getIn(['channels', payload.channelId])
      .set('read', false)
      .set('messages', arrayOfMessages.unshift(new Message(payload.message)))

    return state.setIn(['channels', payload.channelId], newState)
  },

  [WEBSOCKET_CHANNEL_DISABLED]: (state, { payload }) =>
    state.setIn(['channels', payload.channelId, 'disabled'], true),

  [WEBSOCKET_CHANNEL_ENABLED]: (state, { payload }) =>
    state.setIn(['channels', payload.channelId, 'disabled'], false),

  [WEBSOCKET_CHANNEL_MESSAGE_DELETED]: (state, { payload }) =>
    state.setIn(
      ['channels', payload.channelId, 'messages'],
      state
        .getIn(['channels', payload.channelId, 'messages'])
        .filter(message => message.get('id') !== payload.message.id)
    ),

  [WEBSOCKET_USER_REMOVED_FROM_CHANNEL]: (state, { payload }) => {
    if (payload.isOwnUser) {
      return state.set(
        'channels',
        state.get('channels').filter(channel => channel.get('id') !== payload.channelId)
      )
    }

    const userIndex = state
      .getIn(['channels', payload.channelId, 'members', 'users'])
      .findIndex(user => user.get('id') === payload.removedId)

    const newState = state
      .deleteIn(['channels', payload.channelId, 'members', 'users', userIndex])
      .setIn(
        ['channels', payload.channelId, 'numberOfMembers'],
        state.getIn(['channels', payload.channelId, 'numberOfMembers']) - 1
      )

    const userIndexPreview = state
      .getIn(['channels', payload.channelId, 'membersPreview'])
      .findIndex(user => user.get('id') === payload.removedId)

    return userIndexPreview !== -1
      ? newState.deleteIn(['channels', payload.channelId, 'membersPreview', userIndex])
      : newState
  },

  [WEBSOCKET_MAKE_ADMIN]: (state, { payload }) => {
    if (payload.isOwnUser) {
      return state.setIn(['channels', payload.channelId, 'admin'], true)
    }

    return state.setIn(
      ['channels', payload.channelId, 'members', 'users'],
      state
        .getIn(['channels', payload.channelId, 'members', 'users'])
        .map(user => (user.get('id') === payload.userId ? user.set('admin', true) : user))
    )
  },

  [WEBSOCKET_REVOKE_ADMIN]: (state, { payload }) => {
    if (payload.isOwnUser) {
      return state.setIn(['channels', payload.channelId, 'admin'], false)
    }

    return state.setIn(
      ['channels', payload.channelId, 'members', 'users'],
      state
        .getIn(['channels', payload.channelId, 'members', 'users'])
        .map(user => (user.get('id') === payload.userId ? user.set('admin', false) : user))
    )
  },

  [WEBSOCKET_CHANNEL_MEETING_UPDATED]: (state, { payload }) => {
    const messageIndex = state
      .getIn(['channels', payload.channelId, 'messages'])
      .findIndex(message => message.get('id') === payload.messageId)

    if (messageIndex >= 0) {
      return state.setIn(
        ['channels', payload.channelId, 'messages', messageIndex, 'meeting', 'ended'],
        true
      )
    }

    return state
  },

  [WEBSOCKET_MESSAGE_MEETING_UPDATED]: (state, { payload }) => {
    const messageIndex = state
      .getIn(['directMessages', payload.username, 'messages'])
      .findIndex(message => message.get('id') === payload.messageId)

    if (messageIndex >= 0) {
      return state.setIn(
        ['directMessages', payload.username, 'messages', messageIndex, 'meeting', 'ended'],
        true
      )
    }

    return state
  },

  [WEBSOCKET_ADDED_TO_CHANNEL]: (state, { payload }) => {
    if (payload.isOwnUser) {
      return state.set(
        'channels',
        state
          .get('channels')
          .concat(OrderedMap([[payload.channelId, new Channel(payload.channel)]]))
          .sort((a, b) => (moment(a.createdAt).isBefore(b.createdAt) ? -1 : 1))
      )
    }

    const channel = state.getIn(['channels', payload.channelId])

    return state.setIn(
      ['channels', payload.channelId],
      new Channel({
        ...channel?.toJS(),
        numberOfMembers: channel.get('numberOfMembers') + 1,
        membersPreview:
          channel.get('membersPreview').size < 3
            ? channel
                .get('membersPreview')
                .push(Map(payload.addedUser))
                ?.toJS()
            : channel.get('membersPreview')?.toJS(),
      })
    )
  },

  [WEBSOCKET_BLOCKED_USER]: (state, { payload }) =>
    state.deleteIn(['directMessages', payload.blockedById]),
})

export default chat
