import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect } from 'react-redux'

import { userShape } from '_utils/proptypes'
import RocketLoader from '_components/rocket-loader'
import {
  deleteMessageFromChannel,
  endChannelZoomMeeting,
  endDirectMessageZoomMeeting,
  START_DIRECT_MESSAGE_VIDEO_CHAT,
  START_CHANNEL_VIDEO_CHAT,
  END_CHANNEL_MEETING,
  END_DIRECT_MESSAGE_MEETING,
} from '_modules/chat/actions'

import DirectMessage, { MessageTheme } from '../direct-message'
import ChannelMessage, { ChannelMessageTheme } from '../channel-message'

import styles from './styles.css'

const mapStateToProps = state => ({
  isStartingMessageMeeting: !!state.loading.get(START_DIRECT_MESSAGE_VIDEO_CHAT.ACTION),
  isStartingChannelMeeting: !!state.loading.get(START_CHANNEL_VIDEO_CHAT.ACTION),
  isEndingChannelMeeting: !!state.loading.get(END_CHANNEL_MEETING.ACTION),
  isEndingMessageMeeting: !!state.loading.get(END_DIRECT_MESSAGE_MEETING.ACTION),
  user: state.user.toJS(),
})

const mapDispatchToProps = {
  deleteChannelMessage: deleteMessageFromChannel,
  endChannelMeeting: endChannelZoomMeeting,
  endMessageMeeting: endDirectMessageZoomMeeting,
}

class ChatContent extends PureComponent {
  static propTypes = {
    className: PropTypes.string,
    isChannel: PropTypes.bool,
    content: PropTypes.arrayOf(PropTypes.object).isRequired,
    user: userShape.isRequired,
    loadMore: PropTypes.func.isRequired,
    isGettingMessages: PropTypes.bool,
    deleteChannelMessage: PropTypes.func.isRequired,
    hasMore: PropTypes.bool.isRequired,
    selectedChat: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isChannelAdmin: PropTypes.bool,
    loading: PropTypes.bool,
    endChannelMeeting: PropTypes.func.isRequired,
    endMessageMeeting: PropTypes.func.isRequired,
    isStartingMessageMeeting: PropTypes.bool.isRequired,
    isStartingChannelMeeting: PropTypes.bool.isRequired,
    isEndingChannelMeeting: PropTypes.bool.isRequired,
    isEndingMessageMeeting: PropTypes.bool.isRequired,
    hasGoals: PropTypes.bool.isRequired,
  }

  static defaultProps = {
    className: '',
    isChannel: false,
    isGettingMessages: false,
    isChannelAdmin: false,
    loading: false,
  }

  state = {
    messageState: {
      lastMessage: undefined,
      numberOfMessages: 0,
    },
  }

  componentDidUpdate(prevProps) {
    const {
      isGettingMessages,
      content,
      hasMore,
      selectedChat,
      isChannel,
      isStartingMessageMeeting,
      isStartingChannelMeeting,
    } = this.props
    const { messageState } = this.state

    if (prevProps.selectedChat !== selectedChat || prevProps.isChannel !== isChannel) {
      // Reset last message state, scroll to bottom and add event listener everytime chat is changed
      // eslint-disable-next-line react/no-did-update-set-state
      this.setState({
        messageState: {
          lastMessage: undefined,
          numberOfMessages: 0,
        },
      })
      window.scrollTo(0, document.documentElement.scrollHeight)
      window.addEventListener('scroll', this.onScrollMessagesList)
    }

    if (prevProps.isGettingMessages && !isGettingMessages) {
      // If finished getting messages
      if (
        content.length !== messageState.numberOfMessages &&
        messageState.lastMessage &&
        prevProps.selectedChat === selectedChat &&
        prevProps.isChannel === isChannel
      ) {
        // Check if there was a last message (i.e. it is still the same chat) and scroll to it
        const bounds = messageState.lastMessage.getBoundingClientRect()
        window.scrollTo(0, bounds.top - 216)
      } else {
        // If there was no last message, scroll to bottom
        window.scrollTo(0, document.documentElement.scrollHeight)
      }
      if (hasMore) {
        // If has more messages, add event listener back
        window.addEventListener('scroll', this.onScrollMessagesList)
      }
    }

    if (
      (prevProps.isStartingMessageMeeting && !isStartingMessageMeeting) ||
      (prevProps.isStartingChannelMeeting && !isStartingChannelMeeting)
    ) {
      window.scrollTo(0, document.documentElement.scrollHeight)
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.onScrollMessagesList)
  }

  onScrollMessagesList = () => {
    if (window.scrollY < 100 && window.scrollY >= 0) {
      if (this.props.hasMore) {
        this.loadMoreMessages()
        window.removeEventListener('scroll', this.onScrollMessagesList)
      }
    }
  }

  onMessageDeleteClick = payload =>
    this.props.deleteChannelMessage({ ...payload, channelId: this.props.selectedChat })

  onEndChannelMeeting = ({ meetingId }) =>
    this.props.endChannelMeeting({ meetingId, channelId: this.props.selectedChat })

  onEndMessageMeeting = ({ meetingId }) =>
    this.props.endMessageMeeting({ meetingId, userId: this.props.selectedChat })

  loadMoreMessages = () => {
    const { content, loadMore } = this.props
    if (content) {
      const element = document.getElementById(String(content[content.length - 1].id))

      this.setState({
        messageState: {
          lastMessage: element,
          numberOfMessages: content.length,
        },
      })
    }
    loadMore()
  }

  renderDirectMessages = () => {
    const { content, user } = this.props
    return (
      <>
        {content.map((message, index) => {
          let withAvatar = true

          if (index + 1 < content.length) {
            withAvatar = content[index + 1].author.id !== message.author.id
          }

          return (
            <DirectMessage
              key={message.id}
              id={message.id}
              theme={
                message.author.id === user.id ? MessageTheme.OWN_MESSAGE : MessageTheme.USER_MESSAGE
              }
              content={message}
              withAvatar={withAvatar}
              endMeeting={this.onEndMessageMeeting}
              loading={this.props.isEndingMessageMeeting}
            />
          )
        })}
      </>
    )
  }

  renderChannelMessages = () => {
    const { content, user, isChannelAdmin } = this.props
    return (
      <>
        {content.map((message, index) => {
          let withAvatar = true
          if (index + 1 < content.length) {
            withAvatar = content[index + 1].author.id !== message.author.id
          }

          return (
            <ChannelMessage
              key={message.id}
              id={message.id}
              theme={
                message.author.id === user.id
                  ? ChannelMessageTheme.OWN_MESSAGE
                  : ChannelMessageTheme.USER_MESSAGE
              }
              message={message}
              withAvatar={withAvatar}
              isChannelAdmin={isChannelAdmin}
              onDeleteClick={this.onMessageDeleteClick}
              endMeeting={this.onEndChannelMeeting}
              isLoadingEndMeeting={this.props.isEndingChannelMeeting}
            />
          )
        })}
      </>
    )
  }

  render() {
    const { loading, isChannel, className, hasGoals } = this.props
    return loading ? (
      <RocketLoader className={styles.loading} />
    ) : (
      <div
        className={classnames(styles.container, className, {
          [styles['has-goals']]: hasGoals,
        })}
      >
        {isChannel ? this.renderChannelMessages() : this.renderDirectMessages()}
      </div>
    )
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(React.memo(ChatContent))
