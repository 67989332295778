import React, { useReducer, useCallback, useMemo, useEffect, useState } from 'react'
import { Link, useLocation } from '@reach/router'
import classnames from 'classnames'
import { useDebouncedCallback } from 'use-debounce'
import { useDispatch, useSelector } from 'react-redux'

import NoClassesResultsIcon from '_assets/icons/no-classes-result.svg'
import ArrowIcon from '_assets/icons/arrow-right-24-px.svg'
import SearchIcon from '_assets/icons/search.svg'
import OrderingIcon from '_assets/icons/ordering.svg'
import FiltersIcon from '_assets/icons/filters-icon.svg'
import Svg from '_components/svg'
import NoSearchResultsIcon from '_assets/icons/no-search-results.svg'
import Input, { INPUT_TYPE } from '_components/landing-components/input'
import Dropdown, { DROPDOWN_THEME } from '_components/dropdown'
import RocketLoader from '_components/rocket-loader'
import ClassCard from '_components/landing-components/class-card'
import Button, { ButtonTheme } from '_components/landing-components/button'
import { getAvailableClasses } from '_modules/public-classes/actions'
import {
  getAvailableClassesListSelector,
  isGetAvailableClassesLoadingSelector,
  getTotalAvailableClassesSelector,
} from '_modules/public-classes/selectors'
import useModal from '_hooks/use-modal'
import Paginator from '_components/paginator'
import { hasHighlightMessageSelector } from '_modules/prismic/selectors'

import FiltersModal, { ALL_CLASSES } from './filters-modal'
import styles from './styles.css'
import { reducer, INITIAL_STATE, UPDATE_STATE, SEARCH_ORDER } from './reducer'

const CLASSES_PER_PAGE = 20

const AllClasses = () => {
  const classesList = useSelector(getAvailableClassesListSelector)
  const hasHighlightMessage = useSelector(hasHighlightMessageSelector)
  const totalClassesAvailable = useSelector(getTotalAvailableClassesSelector)
  const isGettingAvailableClasses = useSelector(isGetAvailableClassesLoadingSelector)
  const [tipSearchValue, setTipSearchValue] = useState('')
  const location = useLocation()

  const initialSearchValue = useMemo(
    () => ({
      search: location?.state?.search,
      categories: location?.state?.categories,
    }),
    [location]
  )

  const dispatch = useDispatch()

  const [state, localDispatch] = useReducer(reducer, INITIAL_STATE)
  const [isFiltersModalOpen, onToggleFiltersModal] = useModal()

  const handleToggleFilter = useCallback(
    filters => {
      const formattedLocalPayload = {
        categories: filters.categories?.length > 0 ? filters.categories.split(',') : [],
        types: filters.types?.length > 0 ? filters.types.split(',') : [ALL_CLASSES],
      }

      localDispatch({
        type: UPDATE_STATE,
        payload: formattedLocalPayload,
      })
      onToggleFiltersModal()
    },
    [onToggleFiltersModal]
  )

  const orderOptions = useMemo(() => Object.values(SEARCH_ORDER), [])

  useEffect(
    () => {
      dispatch(getAvailableClasses(initialSearchValue))
    },
    [dispatch, initialSearchValue]
  )

  const onGetClasses = useCallback(
    value => {
      setTipSearchValue(value)
      dispatch(getAvailableClasses({ search: value }))
    },
    [dispatch]
  )

  const debouncedSearch = useDebouncedCallback(onGetClasses, 300)

  const onChange = useCallback(
    event => {
      const { name, value } = event.target
      localDispatch({
        type: UPDATE_STATE,
        payload: { [name]: value },
      })

      debouncedSearch(value)
    },
    [debouncedSearch]
  )

  const onSelectOrdering = useCallback(
    value => {
      const selectedValue = Object.values(SEARCH_ORDER).find(item => item.value === value)

      localDispatch({
        type: UPDATE_STATE,
        payload: {
          order: selectedValue,
        },
      })

      dispatch(getAvailableClasses({ ordering: selectedValue.value }))
    },
    [dispatch]
  )

  const onPageChange = useCallback(
    selectedPage => {
      const queryParams = {
        page: selectedPage,
      }
      dispatch(getAvailableClasses(queryParams))
    },
    [dispatch]
  )

  const onApplyFilters = useCallback(
    filters => {
      const formattedLeaders = filters.leaders?.map(coach => coach.name).join(',')
      const formattedLocalPayload = {
        categories: filters.categories?.length > 0 ? filters.categories.split(',') : [],
        types: filters.types?.length > 0 ? filters.types.split(',') : [ALL_CLASSES],
        leaders: filters.leaders?.length > 0 ? filters.leaders : [],
      }

      const newPayload = {
        ...filters,
        leaders: formattedLeaders,
      }

      dispatch(getAvailableClasses(newPayload))
      localDispatch({
        type: UPDATE_STATE,
        payload: formattedLocalPayload,
      })
    },
    [dispatch]
  )

  const handleEmptyStateIcon = useMemo(
    () => (tipSearchValue ? NoSearchResultsIcon : NoClassesResultsIcon),
    [tipSearchValue]
  )

  return (
    <div
      className={classnames(styles['all-classes-wrapper'], {
        [styles['highlight-message']]: hasHighlightMessage,
      })}
    >
      <div className={styles['header-wrapper']}>
        <div className={styles['header-content']}>
          <div className={styles.breadcrumb}>
            {/* TODO: Check if we're keeping this route */}
            <Link to="/explore-courses" className={styles.link}>
              Programs
            </Link>
            <Svg className={styles.arrow} icon={ArrowIcon} />
            <p className={classnames(styles.link, styles['active-link'])}>All classes</p>
          </div>
          <h1 className={styles.title}>All Classes</h1>
        </div>
      </div>
      <div className={styles['classes-wrapper']}>
        <div className={styles['classes-list']}>
          <div className={styles['filters-container']}>
            <Input
              className={styles['search-input']}
              name="search"
              placeholder="Search for a class or a leader"
              value={state.search}
              onChange={onChange}
              type={INPUT_TYPE.SEARCH}
              icon={SearchIcon}
            />
            <Button
              theme={ButtonTheme.GHOST}
              className={styles['filters-button']}
              onClick={onToggleFiltersModal}
            >
              <Svg icon={FiltersIcon} className={styles['filters-icon']} />
              Filters
            </Button>
            <div className={styles['ordering-dropdown']}>
              <Dropdown
                className={styles['ordering-dropdown-button']}
                label={state.order.label}
                onSelect={onSelectOrdering}
                dropdownOptions={orderOptions}
                icon={OrderingIcon}
                theme={DROPDOWN_THEME.BORDERLESS}
              />
            </div>
          </div>
          {classesList?.size && !isGettingAvailableClasses ? (
            <div className={styles['classes-container']}>
              {classesList.map(availableClass => (
                <ClassCard key={availableClass.id} currentClass={availableClass} isSliderCard />
              ))}
            </div>
          ) : (
            <div className={styles['empty-container']}>
              {isGettingAvailableClasses ? (
                <div className={styles.loader}>
                  <RocketLoader />
                </div>
              ) : (
                <>
                  <Svg icon={handleEmptyStateIcon} className={styles['no-search-results-icon']} />
                  <p className={styles['empty-state-text']}>No classes found</p>
                  {!tipSearchValue && <p>There are no classes available</p>}
                  {tipSearchValue && (
                    <>
                      <p>{`There are no classes for the search ”${tipSearchValue}”`}</p>
                      <p className={styles['tip-text']}>Clear search and try again</p>
                    </>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
      {classesList.size && (
        <Paginator
          onPageChange={onPageChange}
          totalItems={totalClassesAvailable}
          itemsPerPage={CLASSES_PER_PAGE}
        />
      )}
      {isFiltersModalOpen && (
        <FiltersModal
          className={styles['filters-modal']}
          onClose={handleToggleFilter}
          initialState={state}
          onApplyFilters={onApplyFilters}
        />
      )}
    </div>
  )
}

export default React.memo(AllClasses)
