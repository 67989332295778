import React, { useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { connect } from 'react-redux'
import { navigate } from '@reach/router'
import { Map } from 'immutable'
import { toast } from 'react-toastify'

import Toastr, { ToastrTheme } from '_components/toastr'
import { updateUser, UPDATE_USER } from '_modules/user/actions'
import Footer from '_components/footer'
import { usePrevious } from '_utils/hooks'
import { userShape } from '_utils/proptypes'

import StepHeader from '../step-header'

import styles from './styles.css'
import UploadPicture from './upload-picture'
import ProfileForm from './profile-form'

const mapStateToProps = ({ user, loading, error }) => ({
  user,
  isUpdating: !!loading.get(UPDATE_USER.ACTION),
  updatingError: error.get(UPDATE_USER.ACTION),
})

const mapDispatchToProps = {
  patchUser: updateUser,
}

const Profile = ({ user, patchUser, isUpdating, updatingError }) => {
  const prevLoading = usePrevious(isUpdating)
  const [photo, setPhoto] = useState(null)

  const onChangeAvatar = useCallback(avatar => setPhoto(avatar), [])

  const onSubmit = useCallback(
    data =>
      patchUser({
        ...data,
        photo,
        completedProfile: true,
      }),
    [patchUser, photo]
  )

  useEffect(
    () => {
      if (prevLoading && !isUpdating && updatingError.size === 0) {
        navigate(['/dashboard'])
      }
      if (updatingError.size >= 1) {
        toast(
          <Toastr theme={ToastrTheme.ERROR} content="Something went wrong, please try again." />
        )
      }
    },
    [isUpdating, prevLoading, updatingError.size]
  )

  return (
    <div className={styles.container}>
      <StepHeader
        belongsToACompany={user.belongsToACompany}
        className={styles.header}
        step={user.belongsToACompany ? 3 : 4}
        stepTitle="Before you create your future"
      />
      <UploadPicture className={styles.picture} onChangeAvatar={onChangeAvatar} />
      <ProfileForm
        className={styles.profile}
        user={user}
        onSubmit={onSubmit}
        usernameError={updatingError.get('username') && updatingError.get('username').first()}
      />
      <Footer className={styles.footer} svgClassName={styles.logo} linkClassName={styles.link} />
    </div>
  )
}

Profile.propTypes = {
  user: userShape.isRequired,
  patchUser: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  updatingError: ImmutablePropTypes.map,
}

Profile.defaultProps = {
  updatingError: Map(),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Profile)
