import { List } from 'immutable'

import { createReducer } from '_utils/redux'
import { User } from '_models/'

import { GET_BLOCKED_USERS, UNBLOCK_USER } from './actions'

const INITIAL_STATE = List()

const blockedUsers = createReducer(INITIAL_STATE, {
  [GET_BLOCKED_USERS.FULFILLED]: (state, { payload }) => List(payload.map(blocked => new User(blocked))),

  [UNBLOCK_USER.FULFILLED]: (state, { meta }) => state.filter(blockedUser => blockedUser.username !== meta.username),
})

export default blockedUsers
