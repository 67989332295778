import React, { useCallback, useLayoutEffect, useMemo, useRef, useState } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import Modal from '_components/modal'
import Svg from '_components/svg'
import CloseIcon from '_assets/icons/close.svg'
import CarouselDots from '_components/carousel-dots'
import useBodyOverflow from '_hooks/use-body-overflow'

import SliderArrow, { ARROW_DIRECTION } from './slider-arrow'
import styles from './styles.css'
import SlideContent, { SLIDE_TYPE } from './slide-content'
import { pages } from './constants'

const HIDE_SLIDER_BUTTON = {
  LEFT_ON_INDEX: 0,
  RIGHT_ON_INDEX: pages.length - 1,
}

const GuideModal = ({ onClose, isOpen }) => {
  const sliderRef = useRef(null)
  useBodyOverflow(true)
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0)

  useLayoutEffect(
    () => {
      document.documentElement.style.overflow = isOpen ? 'hidden' : 'initial'
      document.body.style.overflow = isOpen ? 'hidden' : 'initial'
    },
    [isOpen]
  )

  const onSlideChange = useCallback(index => {
    setCurrentSlideIndex(index)
  }, [])

  const onDotClick = useCallback(event => {
    const {
      dataset: { slideindex },
    } = event.target
    sliderRef.current.slickGoTo(slideindex)
  }, [])

  const slideType = useCallback(index => {
    if (index === 0) {
      return SLIDE_TYPE.HOME_CONTENT
    }

    return index === pages.length - 1 ? SLIDE_TYPE.LAST_CONTENT : SLIDE_TYPE.CONTENT
  }, [])

  const settings = useMemo(
    () => ({
      slidesToShow: 1,
      infinite: false,
      afterChange: onSlideChange,
      prevArrow:
        HIDE_SLIDER_BUTTON.LEFT_ON_INDEX !== currentSlideIndex ? (
          <SliderArrow
            arrowDirection={ARROW_DIRECTION.LEFT}
            arrowClassName={styles['arrow-left']}
          />
        ) : null,
      nextArrow:
        HIDE_SLIDER_BUTTON.RIGHT_ON_INDEX !== currentSlideIndex ? (
          <SliderArrow
            arrowDirection={ARROW_DIRECTION.RIGHT}
            arrowClassName={styles['arrow-right']}
          />
        ) : null,
    }),
    [currentSlideIndex, onSlideChange]
  )

  return (
    <Modal isOpen={isOpen} className={styles.modal}>
      <button className={styles['close-button']} onClick={onClose} aria-label="close modal">
        <Svg icon={CloseIcon} className={styles.icon} />
      </button>

      <Slider {...settings} className={styles.slider} ref={sliderRef}>
        {pages.map((page, index) => (
          <div key={`page-${page.title}`} className={styles['content-wrapper']}>
            <SlideContent
              url={page.url}
              title={page.title}
              subtitle={page?.subtitle}
              tags={page?.tags}
              titleDetails={page?.titleDetails}
              details={page?.details}
              description={page.description}
              icon={page.icon}
              slideType={slideType(index)}
            />
          </div>
        ))}
      </Slider>

      <CarouselDots
        currentIndex={currentSlideIndex}
        size={pages.length}
        className={styles['dots-container']}
        onDotClick={onDotClick}
      />
    </Modal>
  )
}

GuideModal.propTypes = {
  onClose: PropTypes.func,
  isOpen: PropTypes.bool,
}

GuideModal.defaultProps = {
  onClose: () => {},
  isOpen: false,
}

export default React.memo(GuideModal)
