import { fromJS, Map } from 'immutable'
import humps from 'humps'

import { Profile } from '_models/'
import { getPage } from '_utils/helpers'
import { createReducer } from '_utils/redux'
import { FOLLOW_USER, UNFOLLOW_USER, SHAZAM_GOAL, UNSHAZAM_GOAL } from '_modules/user/actions'

import PROFILE_ACTIONS from './actions'

const {
  GET_MY_FUTURE,
  UPDATE_MY_FUTURE,
  UPDATE_GOAL,
  LIST_POSTS,
  UPDATE_GOAL_CATEGORY,
  DELETE_GOAL,
  CREATE_GOAL,
  CREATE_GOAL_CATEGORY,
  GET_SYSTEM_USER,
  GET_USER_POSTS,
  CREATE_POST,
  EDIT_POST,
  DELETE_POST,
  DELETE_GOAL_CATEGORY,
  GET_POST,
  SHAZAM_POST,
  UNSHAZAM_POST,
} = PROFILE_ACTIONS

const INITIAL_STATE = new Profile()

const updateGoalCategory = (state, payload) =>
  state.set(
    'goalCategories',
    state
      .get('goalCategories')
      .map(goal => (goal.get('id') === payload.id ? fromJS(humps.camelizeKeys(payload)) : goal))
  )

const updateFuture = (state, payload) =>
  new Profile({ ...state.toJS(), ...payload, goalCategories: payload.goals })

const updatePostList = ({ state, payload, page }) =>
  state.set(
    'postsDetails',
    Map({
      count: payload.count,
      next: payload.next ? getPage(payload.next) : undefined,
      previous: payload.previous ? getPage(payload.previous) : undefined,
      posts: page
        ? state.getIn(['postsDetails', 'posts']).concat(fromJS(humps.camelizeKeys(payload.results)))
        : fromJS(humps.camelizeKeys(payload.results)),
    })
  )

const profile = createReducer(INITIAL_STATE, {
  [GET_MY_FUTURE.FULFILLED]: (state, { payload }) => updateFuture(state, payload),

  [GET_SYSTEM_USER.FULFILLED]: (state, { payload }) =>
    new Profile({
      ...payload,
      goalCategories: payload.goals,
      user: {
        company: payload.company,
        id: payload.id,
        name: payload.name,
        title: payload.title,
        photo: payload.photo,
        role: payload.role,
        username: payload.username,
        coverPhoto: payload.cover_photo,
      },
      postsDetails: state.get('postsDetails').toJS(),
    }),

  [UPDATE_MY_FUTURE.FULFILLED]: (state, { payload }) => updateFuture(state, payload),

  [CREATE_GOAL_CATEGORY.FULFILLED]: (state, { payload }) =>
    state.set('goalCategories', state.get('goalCategories').push(fromJS(payload))),

  [UPDATE_GOAL_CATEGORY.FULFILLED]: (state, { payload }) => updateGoalCategory(state, payload),

  [CREATE_GOAL.FULFILLED]: (state, { payload }) => updateGoalCategory(state, payload),

  [UPDATE_GOAL.FULFILLED]: (state, { payload }) => updateGoalCategory(state, payload),

  [DELETE_GOAL.FULFILLED]: (state, { payload }) => updateGoalCategory(state, payload),

  [LIST_POSTS.FULFILLED]: (state, { payload, meta }) =>
    updatePostList({ state, payload, page: meta.page }),

  [GET_USER_POSTS.FULFILLED]: (state, { payload, meta }) =>
    updatePostList({ state, payload, page: meta.page }),

  [FOLLOW_USER.FULFILLED]: (state, { meta }) => {
    const userIndex = state
      .get('peopleYouMayKnow')
      .findIndex(user => user.get('username') === meta.username)

    const isUser = state.getIn(['user', 'username']) === meta.username

    if (userIndex >= 0 || isUser) {
      return meta.peopleYouMayKnow
        ? state.setIn(['peopleYouMayKnow', userIndex, 'following'], true)
        : state.set('following', true)
    }

    return state
  },

  [UNFOLLOW_USER.FULFILLED]: (state, { meta }) => {
    const isUser = state.getIn(['user', 'username']) === meta.username
    const userIndex = state
      .get('peopleYouMayKnow')
      .findIndex(user => user.get('username') === meta.username)

    if (userIndex >= 0 || isUser) {
      return meta.peopleYouMayKnow
        ? state.setIn(['peopleYouMayKnow', userIndex, 'following'], false)
        : state.set('following', false)
    }

    return state
  },

  [CREATE_POST.FULFILLED]: (state, { payload, meta }) => {
    const postIndex = state
      .getIn(['postsDetails', 'posts'])
      .findIndex(post => post.get('id') === meta.fatherPostId)

    return state.setIn(['postsDetails', 'posts', postIndex], fromJS(humps.camelizeKeys(payload)))
  },

  [EDIT_POST.FULFILLED]: (state, { payload }) => {
    const postIndex = state
      .getIn(['postsDetails', 'posts'])
      .findIndex(post => post.get('id') === payload.id)

    return state.setIn(['postsDetails', 'posts', postIndex], fromJS(humps.camelizeKeys(payload)))
  },

  [DELETE_POST.FULFILLED]: (state, { meta }) =>
    state.setIn(
      ['postsDetails', 'posts'],
      state.getIn(['postsDetails', 'posts']).filter(post => post.get('id') !== meta.postId)
    ),

  [SHAZAM_GOAL.FULFILLED]: (state, { meta }) => {
    const categoryIndex = state
      .get('goalCategories')
      .findIndex(category => category.get('id') === meta.goalCategoryId)

    const goalIndex = state
      .getIn(['goalCategories', categoryIndex, 'goals'])
      .findIndex(goal => goal.get('id') === meta.goalId)

    return state.updateIn(['goalCategories', categoryIndex, 'goals', goalIndex], goal =>
      goal.set('shazamedByYou', true).set('shazams', goal.get('shazams') + 1)
    )
  },

  [UNSHAZAM_GOAL.FULFILLED]: (state, { meta }) => {
    const categoryIndex = state
      .get('goalCategories')
      .findIndex(category => category.get('id') === meta.goalCategoryId)

    const goalIndex = state
      .getIn(['goalCategories', categoryIndex, 'goals'])
      .findIndex(goal => goal.get('id') === meta.goalId)

    return state.updateIn(['goalCategories', categoryIndex, 'goals', goalIndex], goal =>
      goal.set('shazamedByYou', false).set('shazams', goal.get('shazams') - 1)
    )
  },

  [DELETE_GOAL_CATEGORY.FULFILLED]: (state, { meta }) => state.set(
      'goalCategories',
      state.get('goalCategories').filter(goal => goal.get('id') !== meta.id)
    ),

  [GET_POST.FULFILLED]: (state, { payload }) => {
    const formattedPayload = fromJS(humps.camelizeKeys(payload))

    return state.updateIn(['postsDetails', 'posts'], posts => {
      const postIndex = posts.findIndex(post => post.get('id') === payload.id)

      if (postIndex >= 0) {
        return posts.set(postIndex, formattedPayload)
      }

      return posts.push(formattedPayload)
    })
  },

  [SHAZAM_POST.FULFILLED]: (state, { meta }) => {
    try {
      const postsList = state.getIn(['postsDetails', 'posts'])
      const postIndex = meta.fatherPostId
        ? postsList.findIndex(post => post.get('id') === meta.fatherPostId)
        : postsList.findIndex(post => post.get('id') === meta.postId)

      if (meta.fatherPostId) {
        const commentIndex = state
          .getIn(['postsDetails', 'posts', postIndex, 'comments'])
          .findIndex(comment => comment.get('id') === meta.postId)
        return state.updateIn(
          ['postsDetails', 'posts', postIndex, 'comments', commentIndex],
          comment => comment.set('shazamedByYou', true).set('shazams', meta.shazams)
        )
      }

      return state.updateIn(['postsDetails', 'posts', postIndex], post =>
        post.set('shazamedByYou', true).set('shazams', meta.shazams)
      )
    } catch (error) {
      return state
    }
  },

  [UNSHAZAM_POST.FULFILLED]: (state, { meta }) => {
    try {
      const postsList = state.getIn(['postsDetails', 'posts'])
      const postIndex = meta.fatherPostId
        ? postsList.findIndex(post => post.get('id') === meta.fatherPostId)
        : postsList.findIndex(post => post.get('id') === meta.postId)

      if (meta.fatherPostId) {
        const commentIndex = state
          .getIn(['postsDetails', 'posts', postIndex, 'comments'])
          .findIndex(comment => comment.get('id') === meta.postId)
        return state.updateIn(
          ['postsDetails', 'posts', postIndex, 'comments', commentIndex],
          comment => comment.set('shazamedByYou', false).set('shazams', meta.shazams)
        )
      }

      return state.updateIn(['postsDetails', 'posts', postIndex], post =>
        post.set('shazamedByYou', false).set('shazams', meta.shazams)
      )
    } catch (error) {
      return state
    }
  },
})

export default profile
