import { validateEmail, validatePassword } from '_utils/helpers'
import { MINIMUM_PASSWORD_ERROR } from '_constants/authentication'

export const INITIAL_STATE = {
  email: '',
  password: '',
  emailError: '',
  passwordError: '',
  canUserLogin: false,
}

export const UPDATE_STATE = 'UPDATE_STATE'
export const VALIDATE_ERRORS = 'VALIDATE_ERRORS'
export const TOGGLE_LOGIN = 'TOGGLE_LOGIN'
export const LOGIN_ERROR = 'LOGIN_ERROR'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE: {
      const { name, value } = action?.payload
      return {
        ...state,
        [name]: value,
      }
    }
    case VALIDATE_ERRORS: {
      const emailError = validateEmail(state.email) ? '' : 'Email is invalid'
      const passwordError = validatePassword(state.password) ? '' : MINIMUM_PASSWORD_ERROR
      return {
        ...state,
        emailError,
        passwordError,
        canUserLogin: !emailError && !passwordError,
      }
    }
    case TOGGLE_LOGIN:
      return {
        ...state,
        canUserLogin: !state.canUserLogin,
      }
    case LOGIN_ERROR:
      return {
        ...state,
        ...action?.payload,
      }

    default:
      return state
  }
}
