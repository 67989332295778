import React, { useState, useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Shiitake from 'shiitake'

import styles from './styles.css'

const RearMoreText = ({ className, text, maxLines }) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const onReadMoreClick = useCallback(() => {
    setIsExpanded(true)
  }, [])

  return (
    <div className={classnames(styles.container, className)}>
      {isExpanded ? (
        <p>{text}</p>
      ) : (
        <>
          <Shiitake lines={maxLines}>{text}</Shiitake>
          <button onClick={onReadMoreClick} type="button" className={styles.readMoreButton}>
            Read More
          </button>
        </>
      )}
    </div>
  )
}

RearMoreText.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string.isRequired,
  maxLines: PropTypes.number.isRequired,
}

RearMoreText.defaultProps = {
  className: '',
}

export default React.memo(RearMoreText)
