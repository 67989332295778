import React, { useMemo, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useSelector } from 'react-redux'
import { List } from 'immutable'
import { useLocation } from '@reach/router'
import moment from 'moment'

import SectionContent from '_components/landing-components/section-content'
import SectionTitle from '_components/landing-components/section-title'
import Button from '_components/landing-components/button'
import { prismicTitlePropType, prismicContentPropType } from '_utils/proptypes'
import ArrowDown from '_assets/icons/angle-down.svg'
import { onMouseDown } from '_utils/aria'
import { courseClassesSelector, courseNameSelector } from '_modules/public-courses/selectors'
import AvailableClassesModal from '_components/landing-components/available-classes-modal'
import { getSlugSelector } from '_modules/prismic/selectors'
import { formatPathname } from '_utils/location'
import { authTokenSelector, hasCompletedOnboardingSelector } from '_modules/user/selectors'
import { enrollRedirectUrl } from '_utils/enroll-course-redirect'

import styles from './styles.css'

const SubscriptionTypeSection = ({ slice, id }) => {
  const { pathname } = useLocation()

  const authToken = useSelector(authTokenSelector)
  const hasUserCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)
  const classes = useSelector(courseClassesSelector)
  const currentView = useMemo(() => formatPathname(pathname), [pathname])
  const courseSlug = useSelector(state => getSlugSelector(state, currentView))
  const courseName = useSelector(courseNameSelector)

  const [subscriptionType, setSubscriptionType] = useState(List())

  const backgroundStyles = useMemo(
    () => {
      if (slice.backgroundImage?.thumbnail) {
        return {
          backgroundImage: `url(${slice.backgroundImage.thumbnail})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }
      }
      return {}
    },
    [slice.backgroundImage]
  )

  const courseSubscriptionTypes = useMemo(
    () => classes.map(item => {
        const index = slice.types.findIndex(type => type.classType === item.title)
        if (index >= 0) {
          return {
            ...item,
            description: slice.types[index].description,
          }
        }

        return item
      }),
    [classes, slice.types]
  )

  const getFormattedPrice = useCallback(value => {
    if (typeof value === 'object') {
      return `$${value.min} - $${value.max}`
    }
    return `$${value}`
  }, [])

  const onOpenModal = useCallback(
    courseClasses => () => {
      setSubscriptionType(courseClasses)
    },
    []
  )

  const onCloseModal = useCallback(() => {
    setSubscriptionType(List())
  }, [])

  const redirectState = useCallback(
    classId => ({
      classId,
      courseSlug,
    }),
    [courseSlug]
  )

  const firstAvailableDate = useCallback(
    date =>
      moment(date, 'YYY-MM-DD')
        .format('MMM D')
        .toUpperCase() || null,
    []
  )

  const enrollButtonAction = useCallback(
    type => {
      if (type.firstAvailableClass?.get('startDate')) {
        return {
          onClick: onOpenModal(type.classes),
        }
      }
      return {
        url: authToken
          ? enrollRedirectUrl({
              authToken,
              hasCompletedOnboarding: hasUserCompletedOnboarding,
              classId: type.firstAvailableClass.get('id'),
            })
          : '?sign-up',

        state: redirectState(type.firstAvailableClass.get('id')),
      }
    },
    [authToken, hasUserCompletedOnboarding, onOpenModal, redirectState]
  )

  const buttonLabel = useCallback(
    type => type.firstAvailableClass?.get('startDate')
        ? `Enroll for ${firstAvailableDate(type.firstAvailableClass?.get('startDate'))} class`
        : 'Enroll now',
    [firstAvailableDate]
  )

  return (
    <>
      {classes.length > 0 && (
        <section className={styles.section} style={backgroundStyles} id={id}>
          <div className={styles.container}>
            {slice.sectionTitle && (
              <SectionTitle title={slice.sectionTitle} className={styles.title} />
            )}
            {slice.sectionDescription && (
              <p className={styles.description}>{slice.sectionDescription}</p>
            )}
            {slice.types?.length && (
              <div
                className={classnames(styles.content, {
                  [styles['one-class']]: courseSubscriptionTypes.length === 1,
                  [styles['three-classes']]: courseSubscriptionTypes.length === 3,
                })}
              >
                {courseSubscriptionTypes.map((type, index) => (
                  // setting key as index since this array is not going to be changed
                  <article
                    className={classnames(styles.card, {
                      [styles.first]: courseSubscriptionTypes.length === 3 && index === 0,
                      [styles.second]: courseSubscriptionTypes.length === 3 && index === 1,
                      [styles.third]: courseSubscriptionTypes.length === 3 && index === 2,
                    })}
                    key={`subscription-type-${index + 1}`}
                  >
                    <h1 className={styles['card-title']}>{type.title}</h1>
                    <p className={styles['card-price']}>{getFormattedPrice(type.price)}</p>
                    {type.description?.length && (
                      <div className={styles['card-description']}>
                        {type.description.map((text, textIndex) => (
                          <SectionContent
                            text={text}
                            key={`content-${textIndex + 1}`}
                            isBulletCheckIcon
                          />
                        ))}
                      </div>
                    )}
                    <Button
                      className={classnames(styles['enroll-button'], {
                        [styles['last-element']]: !type.hasMoreAvailableDates,
                      })}
                      {...enrollButtonAction(type)}
                    >
                      {buttonLabel(type)}
                    </Button>
                    {type.hasMoreAvailableDates && (
                      <button
                        type="button"
                        onClick={onOpenModal(type.classes)}
                        className={styles['more-available-dates']}
                        onMouseDown={onMouseDown}
                        name={type.title}
                      >
                        See more available dates
                        <svg aria-hidden="true" viewBox={ArrowDown.viewBox} className={styles.icon}>
                          <use xlinkHref={`#${ArrowDown.id}`} />
                        </svg>
                      </button>
                    )}
                  </article>
                ))}
              </div>
            )}
          </div>
        </section>
      )}
      <AvailableClassesModal
        isOpen={!!subscriptionType?.size}
        onClose={onCloseModal}
        name={courseName}
        content={subscriptionType || List()}
        courseSlug={courseSlug}
        authToken={authToken}
        hasUserCompletedOnboarding={hasUserCompletedOnboarding}
      />
    </>
  )
}

SubscriptionTypeSection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    sectionDescription: PropTypes.string,
    backgroundImage: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
    types: PropTypes.arrayOf(
      PropTypes.shape({
        classType: PropTypes.string,
        description: prismicContentPropType,
      })
    ),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(SubscriptionTypeSection)
