import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import moment from 'moment'
import { navigate, Link } from '@reach/router'

import ProfileAvatar, { AvatarSize } from '_components/profile-avatar'
import MessagesEmptyIcon from '_assets/icons/messages-empty.svg'
import { isKeyboardOrClick, onMouseDown } from '_utils/aria'
import { userShape } from '_utils/proptypes'

import styles from './styles.css'

const MessagesCard = ({ messages, onCloseClick }) => {
  const onMessageClick = useCallback(
    event => {
      event.stopPropagation()
      const { key, currentTarget } = event
      if (isKeyboardOrClick(key)) {
        onCloseClick(event)
        navigate(`/messages?directMessage=${currentTarget.id}`)
      }
    },
    [onCloseClick]
  )

  return (
    <div className={styles.container}>
      <h3 className={styles.title}>MESSAGES</h3>
      {messages.length ? (
        <>
          <div className={styles.messages}>
            {messages.map(message => (
              <div
                role="button"
                onClick={onMessageClick}
                onKeyPress={onMessageClick}
                className={styles.message}
                key={message.user.id}
                onMouseDown={onMouseDown}
                tabIndex="0"
                id={message.user.username}
              >
                <ProfileAvatar
                  className={styles.picture}
                  username={message.user.username}
                  src={message.user.photo}
                  size={AvatarSize.SMALL}
                />
                <div className={classnames(styles.content, { [styles.unread]: !message.read })}>
                  <p className={styles.username}>{message.user.name}</p>
                  <p className={styles.text}>
                    {message.lastMessage.filename || message.lastMessage.message}
                  </p>
                </div>
                <p className={styles.time}>{moment(message.lastMessage.createdAt).fromNow(true)}</p>
              </div>
            ))}
          </div>
          <div className={styles.action}>
            <Link
              to={`/messages${
                messages.length ? `?directMessage=${messages[0].user.username}` : ''
              }`}
            >
              SEE ALL MESSAGES
            </Link>
          </div>
        </>
      ) : (
        <div className={styles.empty}>
          <svg className={styles.icon} viewBox={MessagesEmptyIcon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${MessagesEmptyIcon.id}`} />
          </svg>
          <p className={styles.text}>No messages yet</p>
        </div>
      )}
    </div>
  )
}

MessagesCard.propTypes = {
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      picture: PropTypes.string,
      username: PropTypes.string,
      text: PropTypes.string,
      time: PropTypes.string,
      unread: PropTypes.bool,
      user: userShape,
    })
  ).isRequired,
  onCloseClick: PropTypes.func.isRequired,
}

export default React.memo(MessagesCard)
