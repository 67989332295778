import React, { useMemo, useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from '@reach/window-size'
import { Elements } from '@stripe/react-stripe-js'
import { useSelector, useDispatch } from 'react-redux'
import { navigate, useLocation } from '@reach/router'
import { parse } from 'query-string'

import CloseIcon from '_assets/icons/close.svg'
import Modal from '_components/modal'
import useBodyOverflow from '_hooks/use-body-overflow'
import { onMouseDown } from '_utils/aria'
import { stripePromise } from '_utils/load-stripe'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import { listCards } from '_modules/card/actions'
import { isGettingCardListSelector } from '_modules/card/selectors'
import RocketLoader from '_components/rocket-loader'
import { isStartingMembershipSelector, startMembershipErrorSelector } from '_modules/user/selectors'
import usePrevious from '_hooks/use-previous'

import styles from './styles.css'
import MembershipPaymentsDetails from './membership-payments-details'
import MembershipsPaymentForm from './membership-payment-form'

const MembershipsCheckoutModal = ({ onClose, membership }) => {
  useBodyOverflow(true)
  const { width, height } = useWindowSize()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const isStartingMembershipPurchase = useSelector(isStartingMembershipSelector)
  const wasStartingMembershipPurchase = usePrevious(isStartingMembershipPurchase)
  const membershipPurchaseError = useSelector(startMembershipErrorSelector)

  // TODO replace this with memberships data
  const isGettingCardList = useSelector(isGettingCardListSelector)

  const modalStyle = useMemo(
    () => {
      if (width <= MOBILE_THRESHOLD) {
        return {
          height: height - 32,
          width,
        }
      }

      return {
        maxHeight: `${height * 0.9}px`,
      }
    },
    [height, width]
  )

  useEffect(() => {
    dispatch(listCards())
    // eslint-disable-next-line
  }, [])

  useEffect(
    () => {
      const membershipPurchaseFulfilled =
        wasStartingMembershipPurchase &&
        !isStartingMembershipPurchase &&
        !membershipPurchaseError.size

      if (membershipPurchaseFulfilled) {
        navigate('/memberships')
      }
    },
    [wasStartingMembershipPurchase, isStartingMembershipPurchase, membershipPurchaseError]
  )

  return (
    <Modal isOpen onClose={onClose} className={styles['modal-container']}>
      <section style={modalStyle} className={styles['modal-wrapper']}>
        <button
          type="button"
          onClick={onClose}
          className={styles['close-button']}
          aria-label="Close Modal"
          onMouseDown={onMouseDown}
        >
          <svg className={styles['close-icon']} viewBox={CloseIcon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${CloseIcon.id}`} />
          </svg>
        </button>
        <div className={styles.content}>
          <MembershipPaymentsDetails membershipDetails={membership} />
          <div className={styles['credit-card-container']}>
            {isGettingCardList ? (
              <div className={styles.loader}>
                <RocketLoader />
              </div>
            ) : (
              <Elements stripe={stripePromise}>
                <MembershipsPaymentForm selectedMembership={membership} />
              </Elements>
            )}
          </div>
        </div>
      </section>
    </Modal>
  )
}

MembershipsCheckoutModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  membership: PropTypes.shape({
    type: PropTypes.string.isRequired,
    price: PropTypes.number.isRequired,
  }).isRequired,
}

export default React.memo(MembershipsCheckoutModal)
