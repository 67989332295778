import React from 'react'
import PropTypes from 'prop-types'

import IconButton from '_components/icon-button'
import CloseIcon from '_assets/icons/close.svg'
import Modal from '_components/modal'
import ClassAttachmentsList from '_components/class-attachments/class-attachments-list'

import styles from './styles.css'

const ResourcesModal = ({ attachmentsList, onClose }) => (
    <Modal isOpen onClose={onClose}>
      <section className={styles['resources-modal']}>
        <div className={styles['resources-modal-header']}>
          <h1 className={styles['resources-modal-header-title']}>Resources</h1>
          <IconButton
            className={styles['close-button']}
            icon={CloseIcon}
            iconClassName={styles['close-icon']}
            onClick={onClose}
          />
          <p>
            {attachmentsList.length} file{attachmentsList.length > 1 ? 's' : ''}
          </p>
        </div>

        <ClassAttachmentsList fileList={attachmentsList} />
      </section>
    </Modal>
  )

ResourcesModal.propTypes = {
  attachmentsList: PropTypes.arrayOf(
    PropTypes.shape({
      file: PropTypes.string,
      filename: PropTypes.string,
    })
  ),
  onClose: PropTypes.func.isRequired,
}

ResourcesModal.defaultProps = {
  attachmentsList: [],
}

export default React.memo(ResourcesModal)
