import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from '@reach/window-size'
import { useSelector } from 'react-redux'

import ErrorAlertComponent from '_components/error-alert-component'
import GoogleLogin from '_components/google-login-button'
import Input from '_components/landing-components/input'
import MailIcon from '_assets/icons/mail-icon.svg'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import { googleLoginApiErrorSelector } from '_modules/general/selectors'

import styles from './styles.css'

const SignUpWithGoogle = ({ email, error }) => {
  const googleLoginApiError = useSelector(googleLoginApiErrorSelector)

  const { width, height } = useWindowSize()

  const containerStyles = useMemo(
    () => {
      if (width <= MOBILE_THRESHOLD) {
        return {
          height: height - 155,
        }
      }
      return {}
    },
    [height, width]
  )
  return (
    <div className={styles.container} style={containerStyles}>
      <Input className={styles['email-input']} value={email} icon={MailIcon} disabled />
      <ErrorAlertComponent errorMessage={error} />
      {googleLoginApiError && (
        <ErrorAlertComponent
          errorMessage={googleLoginApiError}
          isError
          className={styles['google-error-alert']}
        />
      )}
      <div className={styles['google-button']}>
        <GoogleLogin />
      </div>
    </div>
  )
}

SignUpWithGoogle.propTypes = {
  email: PropTypes.string.isRequired,
  error: PropTypes.string.isRequired,
}

export default React.memo(SignUpWithGoogle)
