import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Link } from '@reach/router'

import Button, { ButtonTheme, ButtonSize } from '_components/landing-components/button'
import StarIcon from '_assets/icons/star-icon.svg'
import { prismicContentPropType } from '_utils/proptypes'
import SectionContent from '_components/landing-components/section-content'

import styles from './styles.css'

const CourseCard = ({ className, course, isLightyearsPick, isList, handleScrollToSliceClick }) => {
  const onScrollClick = useCallback(
    () => {
      handleScrollToSliceClick(course.actionButtonScrollToSlice)
    },
    [course.actionButtonScrollToSlice, handleScrollToSliceClick]
  )

  return (
    <article
      className={classnames(
        styles.card,
        {
          [styles['featured-card']]: course.featuredCourse,
          [styles['lightyears-pick']]: isLightyearsPick,
          [styles['list-card']]: isList,
        },
        className
      )}
    >
      <div className={styles['picture-container']}>
        {isLightyearsPick || isList ? (
          <Link to={course.courseUrl}>
            <img
              src={course.coursePicture.url}
              className={styles.picture}
              alt={course.courseTitle}
            />
          </Link>
        ) : (
          <img src={course.coursePicture.url} className={styles.picture} alt={course.courseTitle} />
        )}
      </div>
      <div className={styles['card-content']}>
        {isLightyearsPick && (
          <div className={styles['lightyears-pick']}>
            <svg
              className={styles.icon}
              aria-label="close notifications"
              viewBox={StarIcon.viewBox}
            >
              <use xlinkHref={`#${StarIcon.id}`} />
            </svg>
            <p className={styles.pick}>Lightyear&apos;s Pick</p>
          </div>
        )}
        <p className={styles.level}>
          {course.courseLevel} {course.isFree && <span className={styles.free}>FREE!</span>}
        </p>
        {isLightyearsPick || isList ? (
          <Link to={course.courseUrl} className={styles.title}>
            {course.courseTitle}
          </Link>
        ) : (
          <p className={styles.title}>{course.courseTitle}</p>
        )}
        {typeof course.courseDescription === 'string' ? (
          <p className={styles.description}>{course.courseDescription}</p>
        ) : (
          <p className={styles.description}>
            {course.courseDescription.text.map(description => {
              if (description.style) {
                return (
                  <span key={description.text} style={description.style}>
                    {description.text}
                  </span>
                )
              }
              return description.text
            })}
          </p>
        )}
        <Button
          url={course.courseUrl}
          theme={isLightyearsPick || isList ? ButtonTheme.MORE_GREEN : ButtonTheme.MORE}
          size={ButtonSize.SMALL}
          className={styles['see-more']}
        >
          See more
        </Button>
        {course?.courseAdditionalInfo?.length > 0 && (
          <div className={styles['additional-info']}>
            {course?.courseAdditionalInfo?.map((paragraph, index) => (
              <SectionContent
                key={`content-${index + 1}`}
                textClassName={styles.info}
                strongClassName={styles.info}
                text={paragraph}
              />
            ))}
            <Button
              onClick={onScrollClick}
              theme={ButtonTheme.MORE_PURPLE}
              size={ButtonSize.SMALL}
              className={styles['scroll-to-button']}
            >
              See more about Lightyear Membership
            </Button>
          </div>
        )}
      </div>
    </article>
  )
}

CourseCard.propTypes = {
  className: PropTypes.string,
  course: PropTypes.shape({
    coursePicture: PropTypes.shape({
      url: PropTypes.string,
      alt: PropTypes.string,
    }),
    courseLevel: PropTypes.string,
    courseTitle: PropTypes.string,
    courseDescription: PropTypes.string,
    courseUrl: PropTypes.string,
    featuredProgram: PropTypes.bool,
    featuredCourse: PropTypes.bool,
    isFree: PropTypes.bool,
    courseAdditionalInfo: prismicContentPropType,
    actionButtonScrollToSlice: PropTypes.string,
  }).isRequired,
  isLightyearsPick: PropTypes.bool,
  isList: PropTypes.bool,
  handleScrollToSliceClick: PropTypes.func,
}

CourseCard.defaultProps = {
  className: '',
  isLightyearsPick: false,
  isList: false,
  handleScrollToSliceClick: () => {},
}

export default React.memo(CourseCard)
