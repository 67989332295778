import React, { useMemo } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import Shiitake from 'shiitake'

import { COACH_LIST_SIZE } from '_utils/constants'
import UserPlaceholderIcon from '_assets/icons/user-placeholder.svg'
import Svg from '_components/svg'

import styles from './styles.css'

const SHIITAKE_LINE_SIZE = {
  SMALL: 1,
  MEDIUM: 3,
  LARGE: 5,
}

const CoachLore = ({ coachList }) => {
  const renderLinesSize = useMemo(
    () => coachList.size > 2 ? SHIITAKE_LINE_SIZE.LARGE : SHIITAKE_LINE_SIZE.MEDIUM,
    [coachList.size]
  )

  const renderLoreSize = useMemo(
    () => {
      if (coachList.size > COACH_LIST_SIZE.THREE) {
        return 'small'
      }

      if (coachList.size > COACH_LIST_SIZE.TWO) {
        return 'medium'
      }

      return 'large'
    },
    [coachList]
  )

  return (
    <div className={styles['coaches-container']}>
      {coachList.map(coach => (
        <article key={coach.get('id')} className={styles['coach-box']}>
          {coach.get('photo') ? (
            <img
              src={coach.get('photo')}
              alt={`${coach.get('name')}'s avatar`}
              className={styles['coach-avatar']}
            />
          ) : (
            <Svg className={styles['coach-avatar']} icon={UserPlaceholderIcon} />
          )}
          <h1 className={styles['coach-name']}>{coach.get('name')}</h1>
          <p className={styles['coach-occupation']}>Certified Senior Coach</p>
          <Shiitake
            lines={renderLinesSize}
            className={classnames(styles['coach-lore'], styles[`coach-lore-${renderLoreSize}`])}
          >
            {coach.get('ownGoals')}
          </Shiitake>
        </article>
      ))}
    </div>
  )
}

CoachLore.propTypes = {
  coachList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      avatar: PropTypes.string,
    })
  ).isRequired,
}

export default CoachLore
