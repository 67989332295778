import React, { useState, useCallback, useMemo, useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWindowSize } from '@reach/window-size'
import moment from 'moment'
import { useSelector, useDispatch } from 'react-redux'

import MinusIcon from '_assets/icons/minus-remove-icon.svg'
import Input from '_components/landing-components/input'
import Button, { ButtonTheme, ButtonSize } from '_components/landing-components/button'
import { DESKTOP_THRESHOLD } from '_config/media-queries'
import { clearCouponState, validateDiscountCoupon } from '_modules/class/actions'
import {
  isGettingDiscountSelector,
  getDiscountErrorSelector,
  discountValueSelector,
  discountTypeSelector,
} from '_modules/class/selectors'
import usePrevious from '_hooks/use-previous'
import { classShape } from '_utils/proptypes'
import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

const DATE_FORMAT = 'YYYY-MM-DD'

const COUPON_TYPES = {
  PERCENTAGE: 'percentage',
  AMOUNT: 'amount',
}

const PaymentsDetails = ({ currentClass, onAddDiscount, classId }) => {
  const isGettingDiscount = useSelector(isGettingDiscountSelector)
  const wasGettingDiscount = usePrevious(isGettingDiscount)
  const getDiscountError = useSelector(getDiscountErrorSelector)
  const discountValue = useSelector(state => discountValueSelector(state, classId))
  const discountType = useSelector(state => discountTypeSelector(state, classId))

  const { width } = useWindowSize()
  const isDesktop = useMemo(() => width > DESKTOP_THRESHOLD, [width])
  const dispatch = useDispatch()

  const [discountCode, setDiscountCode] = useState('')
  const [showDetails, setShowDetails] = useState(!!isDesktop)
  const [isDiscountApplied, setDiscountApplied] = useState(false)
  const [discountError, setDiscountError] = useState('')

  const classDate = useMemo(
    () =>
      !!currentClass.startDate && moment(currentClass.startDate, DATE_FORMAT).format('MMM D, YYYY'),
    [currentClass.startDate]
  )
  const onShowDetailsClick = useCallback(() => {
    setShowDetails(prevState => !prevState)
  }, [])

  const onInputChange = useCallback(event => {
    const { value } = event.target
    setDiscountCode(value)
    if (!value) {
      setDiscountError('')
    }
  }, [])

  const discount = useMemo(
    () => {
      if (discountCode) {
        return discountType === COUPON_TYPES.PERCENTAGE
          ? currentClass.price * discountValue * 0.01
          : discountValue / 100
      }

      return null
    },
    [currentClass.price, discountCode, discountType, discountValue]
  )

  const totalValue = useMemo(
    () => {
      if (currentClass.price === 0) {
        return 0
      }

      if (discountValue) {
        if (discountType === COUPON_TYPES.PERCENTAGE) {
          return currentClass.price - discount?.toFixed(2)
        }

        return currentClass?.price - discount > 0 ? (currentClass?.price - discount).toFixed(2) : 0
      }

      return currentClass?.price?.toFixed(2)
    },
    [currentClass, discount, discountType, discountValue]
  )

  const onApplyDiscountClick = useCallback(
    () => {
      dispatch(validateDiscountCoupon({ code: discountCode, classId }))
    },
    [classId, discountCode, dispatch]
  )

  const onRemoveDiscount = useCallback(
    () => {
      setDiscountApplied(false)
      setDiscountCode('')
      setDiscountError('')
      dispatch(clearCouponState(classId))
    },
    [classId, dispatch]
  )

  useEffect(
    () => {
      if (wasGettingDiscount && !isGettingDiscount) {
        if (getDiscountError.size) {
          let errorMessage = getDiscountError.first()
          if (getDiscountError.first()?.includes('No such coupon')) {
            errorMessage = 'Invalid coupon'
          }
          setDiscountError(errorMessage)
        } else {
          setDiscountApplied(true)
          onAddDiscount(discountCode)
          if (discountError) {
            setDiscountError('')
          }
        }
      }
    },
    [
      discountCode,
      discountError,
      getDiscountError,
      isGettingDiscount,
      onAddDiscount,
      wasGettingDiscount,
    ]
  )

  return (
    <div className={styles.checkout}>
      <h1 className={styles.title}>Checkout</h1>
      <h2 className={styles['course-name']}>{currentClass.name}</h2>
      {currentClass.instructor?.name && (
        <p className={styles['class-details']}>
          with <span className={styles.bold}>{currentClass.instructor?.name}</span>
          {!!currentClass.startDate &&
            ` • Starting
        ${classDate}`}
        </p>
      )}
      {showDetails && (
        <div className={styles.details}>
          {!!currentClass.customTrialDays && (
            <p className={styles['class-details']}>
              Includes {currentClass.customTrialDays} days of Lightyear Membership for first-time
              members!
            </p>
          )}
          <div className={styles['price-container']}>
            <div className={styles.total}>
              <p className={styles.field}>Subtotal</p>
              <p className={classnames(styles.field, styles.amount)}>
                ${currentClass.price?.toFixed(2)}
              </p>
            </div>
            {isDiscountApplied && (
              <div className={classnames(styles.total, styles['discount-container'])}>
                <p className={styles.field}>Discount code</p>
                <p className={classnames(styles.field, styles.amount, styles.discount)}>
                  -${discount.toFixed(2)}
                </p>
              </div>
            )}
            <div className={styles.total}>
              <p className={styles.field}>Total</p>
              <p className={classnames(styles.field, styles.amount)}>${totalValue}</p>
            </div>
          </div>
          {isDiscountApplied ? (
            <div className={styles['discount-applied-container']}>
              <p className={styles['discount-text']}>
                Discount Applied{' '}
                <span className={styles['discount-value']}>${discount.toFixed(2)}</span>
              </p>
              <button
                type="button"
                aria-label="Remove discount"
                className={styles['remove-discount-button']}
                onClick={onRemoveDiscount}
                onMouseDown={onMouseDown}
              >
                <svg aria-hidden="true" viewBox={MinusIcon.viewBox} className={styles.icon}>
                  <use xlinkHref={`#${MinusIcon.id}`} />
                </svg>
              </button>
            </div>
          ) : (
            <div className={styles['discount-container']}>
              <Input
                className={styles['discount-input']}
                value={discountCode}
                onChange={onInputChange}
                placeholder="Discount Code"
                hasHelperText
                error={!!discountError}
                helperText={discountError}
              />
              <Button
                className={styles['discount-button']}
                onClick={onApplyDiscountClick}
                isLoading={isGettingDiscount}
                disabled={!discountCode}
              >
                APPLY
              </Button>
            </div>
          )}
        </div>
      )}
      {!isDesktop && (
        <Button
          className={styles['discount-button']}
          onClick={onShowDetailsClick}
          theme={ButtonTheme.EXPAND_GREEN}
          size={ButtonSize.SMALL}
          flipIcon={showDetails}
        >
          {showDetails ? 'HIDE DETAILS' : 'SHOW DETAILS'}
        </Button>
      )}
    </div>
  )
}

PaymentsDetails.propTypes = {
  currentClass: classShape.isRequired,
  onAddDiscount: PropTypes.func.isRequired,
  classId: PropTypes.number.isRequired,
}
export default React.memo(PaymentsDetails)
