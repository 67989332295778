import React, { useState, useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useLocation } from '@reach/router'

import SearchIcon from '_assets/icons/search.svg'
import Input, { INPUT_TYPE } from '_components/landing-components/input'

import styles from './styles.css'

const SearchHeader = ({ onFetchClass, placeholder, isSearchBarOpen, className }) => {
  const location = useLocation()
  const searchInputValue = useMemo(() => location?.state?.search, [location])
  const [searchValue, setSearchValue] = useState(searchInputValue)

  const onChange = useCallback(
    event => {
      const { value } = event.target
      setSearchValue(value)
      if (searchInputValue !== value && value === '') {
        onFetchClass('')
      }
    },
    [onFetchClass, searchInputValue]
  )

  const handleOnFetchClass = useCallback(
    event => {
      if (event.key === 'Enter') {
        onFetchClass(searchValue)
      }
    },
    [onFetchClass, searchValue]
  )

  return (
    <Input
      inputClassName={classnames(styles['search-class-input'], className)}
      className={classnames(styles['search-wrapper'], {
        [styles['search-bar-open']]: isSearchBarOpen,
      })}
      placeholder={placeholder}
      icon={SearchIcon}
      type={INPUT_TYPE.SEARCH}
      onKeyPress={handleOnFetchClass}
      value={searchValue}
      onChange={onChange}
    />
  )
}

SearchHeader.propTypes = {
  onFetchClass: PropTypes.func,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  isDesktop: PropTypes.bool,
  isSearchBarOpen: PropTypes.bool,
}

SearchHeader.defaultProps = {
  onFetchClass: () => {},
  placeholder: '',
  className: '',
  isSearchBarOpen: true,
  isDesktop: true,
}

export default SearchHeader
