import React, { useMemo, useEffect, useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import { useWindowSize } from '@reach/window-size'
import { Elements } from '@stripe/react-stripe-js'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, navigate } from '@reach/router'
import { parse } from 'query-string'

import CloseIcon from '_assets/icons/close.svg'
import Modal from '_components/modal'
import useBodyOverflow from '_hooks/use-body-overflow'
import { onMouseDown } from '_utils/aria'
import { stripePromise } from '_utils/load-stripe'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import { listCards } from '_modules/card/actions'
import { isGettingCardListSelector } from '_modules/card/selectors'
import { getPublicClass } from '_modules/class/actions'
import {
  isEnrollLoadingSelector,
  enrollErrorSelector,
  isInitialEnrollLoadingSelector,
  initialEnrollErrorSelector,
} from '_modules/class/selectors'
import RocketLoader from '_components/rocket-loader'
import usePrevious from '_hooks/use-previous'
import { getPublicClassById } from '_modules/public-classes/selectors'

import styles from './styles.css'
import PaymentDetails from './payments-details'
import PaymentForm from './payment-form'

const CheckoutModal = ({ onClose }) => {
  useBodyOverflow(true)
  const { width, height } = useWindowSize()
  const { search } = useLocation()
  const dispatch = useDispatch()

  const [discountCode, setDiscountCode] = useState('')

  const classId = useMemo(
    () => {
      const params = parse(search)
      return Number(params.class)
    },
    [search]
  )

  const isGettingCardList = useSelector(isGettingCardListSelector)
  const isEnrolling = useSelector(isEnrollLoadingSelector)
  const wasEnrolling = usePrevious(isEnrolling)
  const enrollError = useSelector(enrollErrorSelector)
  const isInitialEnrollLoading = useSelector(isInitialEnrollLoadingSelector)
  const wasInitialEnrollLoading = usePrevious(isInitialEnrollLoading)
  const initialEnrollError = useSelector(initialEnrollErrorSelector)

  const currentClass = useSelector(state => getPublicClassById(state, classId))

  useEffect(
    () => {
      if (classId) {
        dispatch(getPublicClass(classId))
      }
    },
    [classId, dispatch]
  )

  const modalStyle = useMemo(
    () => {
      if (width <= MOBILE_THRESHOLD) {
        return {
          height: height - 32,
          width,
        }
      }

      return {
        maxHeight: `${height * 0.9}px`,
      }
    },
    [height, width]
  )

  useEffect(() => {
    dispatch(listCards())
    // eslint-disable-next-line
  }, [])

  const onAddDiscount = useCallback(code => {
    setDiscountCode(code)
  }, [])

  useEffect(
    () => {
      const initialEnrollFulfilled =
        wasInitialEnrollLoading && !isInitialEnrollLoading && !initialEnrollError.size
      const enrollFulfilled = wasEnrolling && !isEnrolling && !enrollError.size
      if (initialEnrollFulfilled || enrollFulfilled) {
        navigate('/courses', {
          state: {
            welcomeModalOpen: initialEnrollFulfilled,
            subscriptionTrialDays: initialEnrollFulfilled && currentClass?.customTrialDays,
            subscriptionTrialCourseName: initialEnrollFulfilled && currentClass?.name,
          },
        })
      }
    },
    [
      currentClass,
      enrollError.size,
      initialEnrollError.size,
      isEnrolling,
      isInitialEnrollLoading,
      wasEnrolling,
      wasInitialEnrollLoading,
    ]
  )

  return (
    <Modal isOpen onClose={onClose} className={styles['modal-container']}>
      <section style={modalStyle} className={styles['modal-wrapper']}>
        <button
          type="button"
          onClick={onClose}
          className={styles['close-button']}
          aria-label="Close Modal"
          onMouseDown={onMouseDown}
        >
          <svg className={styles['close-icon']} viewBox={CloseIcon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${CloseIcon.id}`} />
          </svg>
        </button>
        <div className={styles.content}>
          {currentClass &&
            Object.keys(currentClass).length && (
              <PaymentDetails
                currentClass={currentClass}
                onAddDiscount={onAddDiscount}
                classId={classId}
              />
            )}
          <div className={styles['credit-card-container']}>
            {isGettingCardList ? (
              <div className={styles.loader}>
                <RocketLoader />
              </div>
            ) : (
              <Elements stripe={stripePromise}>
                <PaymentForm classId={classId} discountCode={discountCode} />
              </Elements>
            )}
          </div>
        </div>
      </section>
    </Modal>
  )
}

CheckoutModal.propTypes = {
  onClose: PropTypes.func.isRequired,
}

export default React.memo(CheckoutModal)
