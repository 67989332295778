import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { MOBILE_WIDTH } from '_utils/constants'
import {
  calculateRadius,
  calculateCircumference,
  calculateStrokeVal,
  calculateDashVal,
  getTrackStyle,
  getIndicatorStyle,
  calculateRotateVal,
} from '_utils/donut-math-helpers'

import styles from './styles.css'

const DonutChart = ({
  size,
  value,
  strokeWidth,
  showValue,
  className,
  trackClassName,
  keepSize,
}) => {
  const [donutSize, setDonutSize] = useState(size)
  const [donutStrokeWidth, setDonutStrokeWidth] = useState(strokeWidth)

  const renderedMobileAndNotKeepSize = useMemo(
    () => typeof window !== 'undefined' && window.innerWidth <= MOBILE_WIDTH && !keepSize,
    [keepSize]
  )

  const changeSize = useCallback(
    () => {
      if (renderedMobileAndNotKeepSize && size === 95) {
        setDonutSize(75)
        setDonutStrokeWidth(4)
      } else if (typeof window !== 'undefined' && window.innerWidth > MOBILE_WIDTH && size === 75) {
        setDonutSize(95)
        setDonutStrokeWidth(8)
      }
    },
    [renderedMobileAndNotKeepSize, size]
  )

  const halfSize = useMemo(() => size * 0.5, [size])

  const radius = useMemo(() => calculateRadius(halfSize, donutStrokeWidth), [
    donutStrokeWidth,
    halfSize,
  ])

  const circumference = useMemo(() => calculateCircumference(radius), [radius])

  const strokeVal = useMemo(() => calculateStrokeVal(value, circumference), [circumference, value])

  const dashVal = useMemo(() => calculateDashVal(strokeVal, circumference), [
    circumference,
    strokeVal,
  ])

  const trackStyle = useMemo(() => getTrackStyle(donutStrokeWidth), [donutStrokeWidth])

  const indicatorStyle = useMemo(() => getIndicatorStyle(donutStrokeWidth, dashVal), [
    dashVal,
    donutStrokeWidth,
  ])

  const rotateVal = useMemo(() => calculateRotateVal(halfSize), [halfSize])

  useEffect(
    () => {
      if (renderedMobileAndNotKeepSize) {
        setDonutSize(75)
        setDonutStrokeWidth(4)
      }
    },
    [renderedMobileAndNotKeepSize, keepSize]
  )

  useEffect(
    () => {
      if (typeof window !== 'undefined') {
        window.addEventListener('resize', changeSize, { passive: true })
      }

      return () => {
        window.removeEventListener('resize', changeSize, { passive: true })
      }
    },
    [changeSize]
  )

  return (
    <svg width={donutSize} height={donutSize} className={classnames(styles.donutchart, className)}>
      <circle
        r={radius}
        cx={halfSize}
        cy={halfSize}
        transform={rotateVal}
        style={trackStyle}
        className={classnames(styles['donutchart-track'], trackClassName)}
      />
      <circle
        r={radius}
        cx={halfSize}
        cy={halfSize}
        transform={rotateVal}
        style={indicatorStyle}
        className={styles['donutchart-indicator']}
      />
      {showValue && (
        <text
          className={styles['donutchart-text']}
          x={halfSize}
          y={halfSize + 8}
          style={{ textAnchor: 'middle' }}
        >
          <tspan className={styles['donutchart-text-val']}>{value >= 0 ? value : '-'}</tspan>
          {value >= 0 && <tspan className={styles['donutchart-text-percent']}>%</tspan>}
        </text>
      )}
    </svg>
  )
}

DonutChart.propTypes = {
  size: PropTypes.number,
  value: PropTypes.number,
  strokeWidth: PropTypes.number,
  showValue: PropTypes.bool,
  className: PropTypes.string,
  trackClassName: PropTypes.string,
  keepSize: PropTypes.bool,
}

DonutChart.defaultProps = {
  size: 95,
  value: 0,
  strokeWidth: 8,
  showValue: true,
  className: '',
  trackClassName: '',
  keepSize: false,
}

export default React.memo(DonutChart)
