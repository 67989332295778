import { validateEmail, validatePassword } from '_utils/helpers'
import { MINIMUM_PASSWORD_ERROR } from '_constants/authentication'

export const INITIAL_STATE = {
  name: '',
  email: '',
  confirmEmail: '',
  password: '',
  nameError: '',
  emailError: '',
  confirmEmailError: '',
  passwordError: '',
  canUserSignUp: false,
  error: '',
}

export const UPDATE_STATE = 'UPDATE_STATE'
export const VALIDATE_ERRORS = 'VALIDATE_ERRORS'
export const TOGGLE_SIGN_UP = 'TOGGLE_SIGN_UP'
export const SIGN_UP_ERROR = 'SIGN_UP_ERROR'

export const reducer = (state, action) => {
  switch (action.type) {
    case UPDATE_STATE: {
      return {
        ...state,
        ...action?.payload,
      }
    }
    case VALIDATE_ERRORS: {
      const nameError = state.name ? '' : 'Required field'
      const emailError = validateEmail(state.email) ? '' : 'Email is invalid'
      const confirmEmailError = state.email === state.confirmEmail ? '' : 'Emails must match'
      const passwordError = validatePassword(state.password) ? '' : MINIMUM_PASSWORD_ERROR
      return {
        ...state,
        nameError,
        emailError,
        confirmEmailError,
        passwordError,
        canUserSignUp: !nameError && !emailError && !confirmEmailError && !passwordError,
      }
    }
    case TOGGLE_SIGN_UP:
      return {
        ...state,
        canUserSignUp: !state.canUserSignUp,
      }
    case SIGN_UP_ERROR:
      return {
        ...state,
        ...action?.payload,
      }
    default:
      return state
  }
}
