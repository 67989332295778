import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { TEXT_TYPE } from '_constants/prismic'

import styles from './styles.css'

const SectionContent = ({ text, className, isBulletCheckIcon, textClassName, strongClassName }) => {
  const renderFormattedText = useCallback(
    (paragraph, index) => {
      if (!paragraph.text) {
        // List are not going to change, so I'm keeping the indexes as keys
        return <br key={`break-${index + 1}`} />
      }

      if (paragraph.type === TEXT_TYPE.LIST_ITEM || paragraph.type === TEXT_TYPE.BOLD_LIST_ITEM) {
        return (
          <li
            className={classnames(styles['list-item'], {
              [styles['strong-list-item']]: paragraph.type === TEXT_TYPE.BOLD_LIST_ITEM,
            })}
            key={`${paragraph.text}-${index + 1}`}
          >
            {paragraph.text}
          </li>
        )
      }

      if (paragraph.type === TEXT_TYPE.STRONG) {
        return (
          <span
            className={classnames(styles.strong, strongClassName)}
            key={`span-${paragraph.text}-${index + 1}`}
          >
            {paragraph.text}
          </span>
        )
      }

      if (paragraph.type === TEXT_TYPE.HYPERLINK) {
        return (
          <a
            href={paragraph.url}
            key={`hyperlink-${paragraph.text}-${index + 1}`}
            className={styles.hyperlink}
          >
            {paragraph.text}
          </a>
        )
      }

      return paragraph.text
    },
    [strongClassName]
  )

  const renderListItems = useCallback(
    listItem =>
      listItem.map(item =>
        item.map((textSlice, listItemIndex) => renderFormattedText(textSlice, listItemIndex))
      ),
    [renderFormattedText]
  )

  const renderParagraphs = useCallback(
    paragraphs =>
      paragraphs.map((paragraph, paragraphIndex) => {
        if (paragraph.type === TEXT_TYPE.LIST) {
          return (
            <ul
              className={classnames(styles.list, {
                [styles['check-icon-item']]: isBulletCheckIcon,
              })}
              key={`list-${paragraph}-${paragraphIndex + 1}`}
            >
              {paragraph.listItems.map(listItem => renderListItems(listItem))}
            </ul>
          )
        }

        return renderFormattedText(paragraph, paragraphIndex)
      }),
    [isBulletCheckIcon, renderFormattedText, renderListItems]
  )

  return (
    <div className={className}>
      {text.map((paragraphs, index) => (
          <span
            className={classnames(styles.text, textClassName)}
            key={`span-${paragraphs}-${index + 1}`}
          >
            {renderParagraphs(paragraphs)}
          </span>
        ))}
    </div>
  )
}

SectionContent.propTypes = {
  text: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        text: PropTypes.string,
        type: PropTypes.string,
      })
    )
  ).isRequired,
  className: PropTypes.string,
  isBulletCheckIcon: PropTypes.bool,
  textClassName: PropTypes.string,
  strongClassName: PropTypes.string,
}

SectionContent.defaultProps = {
  className: '',
  isBulletCheckIcon: false,
  textClassName: '',
  strongClassName: '',
}

export default React.memo(SectionContent)
