import React from 'react'
import { Location } from '@reach/router'
import ReactGA from 'react-ga4'

import { GA_ID } from '_config/environment'

import Router from './router'

const fireTracking = () => {
  if ((GA_ID && !window.GA_ID) || (!GA_ID && window.GA_ID)) {
    ReactGA.send({ hitType: 'pageview', page: window.location.pathname + window.location.search })
  }
}

const RouterWithTracking = () => (
  <Location>
    {() => {
      fireTracking()
      return <Router />
    }}
  </Location>
)

export default RouterWithTracking
