import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

export const MODAL_TEXT = {
  CANCEL_SUBSCRIPTION:
    "cancel your subscription? If you choose to cancel, don't forget to download your Vision and Goals PDF",
  DELETE_MESSAGE: 'delete this message?',
  LEAVE_CHANNEL: 'leave this channel?',
  DEACTIVATE_ZOOM: 'deactivate zoom?',
}

const ConfirmationModal = ({ onClose, text, onActionClick, loading, content }) => {
  const buttonLabel = useMemo(
    () => {
      if (text === MODAL_TEXT.DELETE_MESSAGE) {
        return 'YES, DELETE'
      }

      if (text === MODAL_TEXT.CANCEL_SUBSCRIPTION) {
        return 'YES, CANCEL'
      }

      if (text === MODAL_TEXT.DEACTIVATE_ZOOM) {
        return 'YES, DEACTIVATE'
      }

      return 'YES, LEAVE'
    },
    [text]
  )

  return (
    <div className={styles.container}>
      <h4>{`Are you sure that you would like to ${text}`}</h4>
      {content && <p>{content}</p>}
      <div className={styles.buttons}>
        <Button
          onClick={onClose}
          label="NO, GO BACK"
          theme={ButtonTheme.SECONDARY}
          className={styles.back}
        />
        <Button
          label={buttonLabel}
          theme={ButtonTheme.PRIMARY}
          className={styles.cancel}
          loading={loading}
          onClick={onActionClick}
        />
      </div>
    </div>
  )
}

ConfirmationModal.propTypes = {
  onClose: PropTypes.func,
  loading: PropTypes.bool,
  onActionClick: PropTypes.func,
  text: PropTypes.string,
  content: PropTypes.string,
}

ConfirmationModal.defaultProps = {
  loading: false,
  onClose: () => {},
  onActionClick: () => {},
  text: MODAL_TEXT.CANCEL_SUBSCRIPTION,
  content: '',
}

export default ConfirmationModal
