import React, { useCallback, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useDispatch } from 'react-redux'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import { toast } from 'react-toastify'

import { CHANGE_PASSWORD, changePassword } from '_modules/user/actions'
import Toastr, { ToastrTheme } from '_components/toastr'
import Button, { ButtonTheme } from '_components/button'
import Card, { CardSize } from '_components/card'
import Input from '_components/input'
import useFetchCall from '_hooks/use-fetch-call'
import { checkIfPasswordInputsAreValid } from '_utils/helpers'

import { reducer, INITIAL_STATE, UPDATE_STATE, RESET_STATE } from './reducer'
import styles from './styles.css'

const ChangePasswordCard = () => {
  const dispatch = useDispatch()
  const [{ confirmPassword, oldPassword, newPassword, passwordErrors }, localDispatch] = useReducer(
    reducer,
    INITIAL_STATE
  )

  const onInputChange = useCallback(event => {
    const { name, value } = event.target
    const payload = {
      [name]: value,
    }

    localDispatch({
      type: UPDATE_STATE,
      payload,
    })
  }, [])

  const onNewPasswordErrors = useCallback(errors => {
    const payload = {
      passwordErrors: {
        ...errors,
      },
    }

    localDispatch({
      type: UPDATE_STATE,
      payload,
    })
  }, [])

  const onSubmit = useCallback(
    event => {
      event.preventDefault()
      const isValid = checkIfPasswordInputsAreValid({
        confirmPassword,
        oldPassword,
        newPassword,
        callback: onNewPasswordErrors,
      })
      if (isValid) {
        dispatch(
          changePassword({
            oldPassword,
            newPassword1: newPassword,
            newPassword2: confirmPassword,
          })
        )
      }
    },
    [confirmPassword, dispatch, newPassword, oldPassword, onNewPasswordErrors]
  )

  const onChangePasswordSuccess = useCallback(() => {
    toast(<Toastr theme={ToastrTheme.SUCCESS} content="Password updated successfully" />)
    localDispatch({
      type: RESET_STATE,
    })
  }, [])

  const [loading, changePasswordError] = useFetchCall(CHANGE_PASSWORD, onChangePasswordSuccess)

  return (
    <Card className={styles.card} size={CardSize.LARGE}>
      <h2>Change Password</h2>
      <form onSubmit={onSubmit} className={styles['form-container']}>
        <Input
          onChange={onInputChange}
          value={oldPassword}
          name="oldPassword"
          label="Old Password"
          type="password"
          labelClassName={styles['label-title']}
          disabled={loading}
          errorMessage={
            passwordErrors.old ||
            (changePasswordError.size > 0 && changePasswordError.first().get(0))
          }
        />
        <Input
          onChange={onInputChange}
          value={newPassword}
          disabled={loading}
          name="newPassword"
          label="New Password"
          type="password"
          labelClassName={styles['label-title']}
          errorMessage={passwordErrors.new}
        />
        <Input
          onChange={onInputChange}
          value={confirmPassword}
          disabled={loading}
          name="confirmPassword"
          label="Confirm new Password"
          type="password"
          labelClassName={styles['label-title']}
          errorMessage={passwordErrors.confirm}
        />
        <Button
          label="Save changes"
          theme={ButtonTheme.PRIMARY}
          className={styles.button}
          loading={loading}
          type="submit"
        />
      </form>
    </Card>
  )
}

ChangePasswordCard.defaultProps = {
  loading: PropTypes.bool,
  changePasswordError: ImmutablePropTypes.map,
}

ChangePasswordCard.defaultProps = {
  changePasswordError: Map(),
  loading: false,
}

export default ChangePasswordCard
