import React, { useMemo, useCallback, useRef, useState, useEffect } from 'react'
import { useParams } from '@reach/router'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import moment from 'moment'

import ClassAttachmentsList from '_components/class-attachments/class-attachments-list'
import Button, { ButtonTheme } from '_components/landing-components/button'
import { assignmentShape } from '_utils/proptypes'
import ArrowLeft from '_assets/icons/arrow-left.svg'
import IconButton from '_components/icon-button'
import { getClassById, isStartingCallSelector } from '_modules/class/selectors'
import BackgroundPlaceholder from '_assets/images/course-cover.png'
import CallAssignment from '_components/call-assignment'
import { startAssignmentVideo } from '_modules/class/actions'
import { formatUrl } from '_utils/helpers'
import { CLASS_TYPE } from '_constants/class'
import usePrevious from '_hooks/use-previous'
import useCallCountdown from '_hooks/use-call-countdown'

import styles from './styles.css'

const CallDetails = ({ assignment, nextAssignmentId }) => {
  const { classId } = useParams()
  const currentClass = useSelector(state => getClassById(state, classId))
  const isStartingCall = useSelector(isStartingCallSelector)
  const wasStartingCall = usePrevious(isStartingCall)
  const [isStartCallClicked, setStartCallClicked] = useState(false)
  const dispatch = useDispatch()
  const joinCallRef = useRef(null)
  const isMainInstructor = useMemo(() => currentClass?.isMainInstructor, [currentClass])
  const meetingEnded = useMemo(() => assignment?.assignment?.meeting?.ended, [assignment])
  const meetingStarted = useMemo(() => assignment?.assignment?.meeting?.started, [assignment])

  const [minuteCountdown, timeToEndMeeting] = useCallCountdown(assignment)

  const onCallActionClick = useCallback(
    () => {
      setStartCallClicked(true)
      dispatch(
        startAssignmentVideo({
          classId,
          meetingId: assignment?.assignment?.meeting?.id,
          assignmentId: assignment?.id,
        })
      )
    },
    [assignment, classId, dispatch]
  )

  const renderCallButton = useCallback(
    () => {
      if (meetingEnded) {
        return null
      }

      const today = moment()
      const earlierStartTime = moment(assignment?.assignment?.meeting?.startTime).subtract(
        10,
        'minutes'
      )
      const dueDate = moment(assignment?.assignment?.meeting?.startTime).add(
        assignment?.assignment?.duration,
        'minutes'
      )

      if (currentClass.type === CLASS_TYPE.COACH_LED.slug && !meetingStarted) {
        let disableCallButton = true
        if (isMainInstructor) {
          disableCallButton =
            !assignment.assignment?.meeting?.canStart &&
            (today.isBefore(earlierStartTime) || today.isAfter(dueDate))
        } else {
          disableCallButton = meetingEnded || !meetingStarted
        }

        return (
          <Button
            className={styles['join-call-button']}
            theme={ButtonTheme.MELROSE}
            onClick={onCallActionClick}
            disabled={disableCallButton}
          >
            {currentClass.isMainInstructor ? 'Start call' : 'Join call'}
          </Button>
        )
      }

      if (today.isBefore(earlierStartTime) || today.isAfter(dueDate)) {
        return (
          <Button className={styles['join-call-button']} theme={ButtonTheme.MELROSE} disabled>
            JOIN CALL
          </Button>
        )
      }

      const callLink = formatUrl(assignment.assignment.meeting.joinUrl)
      return (
        <a
          className={styles['join-call-link']}
          target="_blank"
          rel="noopener noreferrer"
          href={callLink}
          ref={joinCallRef}
        >
          Join call
        </a>
      )
    },
    [
      assignment,
      currentClass.isMainInstructor,
      currentClass.type,
      isMainInstructor,
      meetingEnded,
      meetingStarted,
      onCallActionClick,
    ]
  )

  useEffect(
    () => {
      if (wasStartingCall && !isStartingCall && isStartCallClicked && joinCallRef.current) {
        setStartCallClicked(false)
        joinCallRef.current.click()
      }
    },
    [isStartCallClicked, isStartingCall, wasStartingCall]
  )

  const attachmentsAndRecordings = useMemo(
    () => assignment.attachments.concat(assignment?.assignment?.meeting?.recordings),
    [assignment]
  )

  const attachmentListSize = useMemo(
    () =>
      attachmentsAndRecordings?.length > 0
        ? `${attachmentsAndRecordings?.length} file${
            attachmentsAndRecordings?.length > 1 ? 's' : ''
          }`
        : 'No attached files',
    [attachmentsAndRecordings]
  )

  return (
    <section className={styles['call-details-container']}>
      <img
        className={styles['cover-picture']}
        src={currentClass?.coverPhoto || BackgroundPlaceholder}
        alt={`${currentClass?.courseName}'s cover`}
      />
      <div className={styles['image-overlay']} />

      <div className={styles['call-details-header']}>
        <IconButton
          className={styles['back-button']}
          icon={ArrowLeft}
          href={`/class/${currentClass?.id}`}
        />
        <h1 className={styles['course-name-title']}>{currentClass?.courseName}</h1>
      </div>
      <div className={styles['call-details-content']}>
        <h2 className={styles['call-name']}>{assignment?.assignment?.name}</h2>
        <CallAssignment
          content={assignment}
          className={styles['call-assignment']}
          minuteCountdown={minuteCountdown}
          timeToEndMeeting={timeToEndMeeting}
        />

        <div className={styles['buttons-container']}>
          {timeToEndMeeting >= 0 && minuteCountdown < 10 && renderCallButton()}
          {nextAssignmentId && (
            <Button
              theme={ButtonTheme.TRANSPARENT_BACKGROUND_MALIBU_TEXT}
              className={styles['next-assignment-button']}
              url={`/class/${currentClass?.id}/assignment/${nextAssignmentId}`}
            >
              Move to next assignment
            </Button>
          )}
        </div>
        <dl className={styles['attachments-list-container']}>
          <dt className={styles['attachments-list-title']}>Attachments</dt>
          <dd>{attachmentListSize}</dd>
          {attachmentsAndRecordings.length > 0 && (
            <ClassAttachmentsList
              className={styles['attachment-list']}
              fileList={attachmentsAndRecordings}
            />
          )}
        </dl>
      </div>
    </section>
  )
}

CallDetails.propTypes = {
  assignment: assignmentShape.isRequired,
  nextAssignmentId: PropTypes.number,
}

CallDetails.defaultProps = {
  nextAssignmentId: undefined,
}

export default CallDetails
