import humps from 'humps'

import { get, post } from './requests'

export const getCourses = key => payload =>
  get(['courses'], { key, params: humps.decamelizeKeys(payload) })

export const getCompanyClasses = key => get(['new', 'company-classes'], { key })

export const getCompanyClassById = (key, classId) =>
  get(['new', 'company-classes', classId], { key })

export const getCourse = key => (slug, params) =>
  get(['courses', slug], { key, params: humps.decamelizeKeys(params) })

export const getCategories = key => get(['course-categories'], { key })

export const favorite = key => slug => post(['courses', slug, 'favorite'], { key })

export const unfavorite = key => slug => post(['courses', slug, 'unfavorite'], { key })

export const exportCourses = key => get(['new', 'export-courses'], { key, responseType: 'blob' })
