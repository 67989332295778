import React, { useCallback, useState, useMemo, useEffect } from 'react'
import { useGoogleLogin } from '@react-oauth/google'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Map } from 'immutable'

import GoogleIcon from '_assets/icons/ic_google.svg'
import { googleLogin } from '_modules/user/actions'
import { isGoogleLoginLoadingSelector, googleLoginErrorSelector } from '_modules/user/selectors'
import { googleLoginApiErrorSelector } from '_modules/general/selectors'
import { setGoogleLoginError } from '_modules/general/actions'
import { onMouseDown } from '_utils/aria'
import Spinner from '_components/spinner'
import usePrevious from '_hooks/use-previous'

import styles from './styles.css'

const MALIBU = '#7387ff'

const NO_COOKIES_ERROR = 'Cookies are not enabled in current environment.'
export const NO_COOKIES_ERROR_MESSAGE =
  "Google login won't work because cookies are not enabled in current environment."

const GoogleLoginButton = ({ onLoginError, label, disabled, onLoginSuccess }) => {
  const isGoogleLoginLoading = useSelector(isGoogleLoginLoadingSelector)
  const wasGoogleLoginLoading = usePrevious(isGoogleLoginLoading)
  const googleLoginError = useSelector(googleLoginErrorSelector)
  const googleLoginApiError = useSelector(googleLoginApiErrorSelector)
  const [isGoogleLoading, setGoogleLoading] = useState(false)
  const isLoading = useMemo(
    () => !!(isGoogleLoading || isGoogleLoginLoading),
    [isGoogleLoading, isGoogleLoginLoading]
  )

  const [isDisabled, setDisabled] = useState(false)

  const dispatch = useDispatch()

  const handleLoginSuccess = useCallback(
    tokenResponse => {
      setGoogleLoading(false)
      const formattedPayload = { accessToken: tokenResponse.access_token }
      dispatch(googleLogin(formattedPayload))
      onLoginSuccess()
    },
    [dispatch, onLoginSuccess]
  )

  useEffect(() => {
    if (googleLoginApiError) {
      setDisabled(true)

      onLoginError(
        Map({
          isGoogleError: true,
          message: NO_COOKIES_ERROR_MESSAGE,
        })
      )
    }
  }, [googleLoginApiError, onLoginError])

  const onLoginFailure = useCallback(
    error => {
      if (error?.details === NO_COOKIES_ERROR) {
        onLoginError(
          Map({
            isGoogleError: true,
            message: NO_COOKIES_ERROR_MESSAGE,
          })
        )

        dispatch(setGoogleLoginError(NO_COOKIES_ERROR_MESSAGE))
      } else {
        onLoginError(
          Map({
            isGoogleError: true,
            message: error?.details || 'Something went wrong with google login',
          })
        )
      }
      setGoogleLoading(false)
    },
    [dispatch, onLoginError]
  )

  useEffect(() => {
    if (!isGoogleLoginLoading && wasGoogleLoginLoading) {
      if (googleLoginError.size) {
        onLoginError(googleLoginError)
      }
    }
  }, [
    googleLoginError,
    googleLoginError.size,
    isGoogleLoginLoading,
    onLoginError,
    wasGoogleLoginLoading,
  ])

  const login = useGoogleLogin({
    onSuccess: handleLoginSuccess,
    onError: onLoginFailure,
  })

  const onButtonClick = useCallback(() => {
    setGoogleLoading(true)
    login()
  }, [login])

  return (
    <button
      type="button"
      onClick={onButtonClick}
      className={classnames(styles['google-button'], {
        [styles.disabled]: isDisabled || disabled,
      })}
      disabled={isDisabled || isLoading || disabled}
      onMouseDown={onMouseDown}
    >
      {isLoading ? (
        <Spinner size={20} color={MALIBU} />
      ) : (
        <>
          <svg className={styles.icon} viewBox={GoogleIcon.viewBox} aria-hidden="true">
            <use xlinkHref={`#${GoogleIcon.id}`} />
          </svg>
          {label}
        </>
      )}
    </button>
  )
}

GoogleLoginButton.propTypes = {
  onLoginError: PropTypes.func,
  onLoginSuccess: PropTypes.func,
  label: PropTypes.string,
  disabled: PropTypes.bool,
}

GoogleLoginButton.defaultProps = {
  label: 'LOGIN WITH GOOGLE',
  onLoginError: () => {},
  onLoginSuccess: () => {},
  disabled: false,
}

export default React.memo(GoogleLoginButton)
