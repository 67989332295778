export const CONFETTI_CONFIG = {
  angle: 90,
  spread: 360,
  startVelocity: 40,
  elementCount: 90,
  dragFriction: 0.12,
  duration: 7000,
  stagger: 3,
  width: '10px',
  height: '10px',
  perspective: '500px',
  colors: [
    '#7387ff',
    '#b7c1ff',
    '#35d795',
    '#2bc989',
    '#fff',
    '#0e8849',
    '#383d5b',
    '#05152e',
    '#daeae5',
  ],
}
