import React, { useState, useCallback, useMemo, useReducer } from 'react'
import { Link } from '@reach/router'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import LightyearLogo from '_assets/full-logo.svg'
import FacebookIcon from '_assets/icons/ic-facebook-24-px.svg'
import InstagramIcon from '_assets/icons/ic-instagram-24-px.svg'
import TwitterIcon from '_assets/icons/ic-twitter-24-px.svg'
import Input from '_components/landing-components/input'
import Button, { ButtonTheme } from '_components/landing-components/button'
import NewsletterModal from '_components/landing-components/newsletter-modal'
import { subscribeToNewsletter } from '_services/user'
import { validateEmail } from '_utils/helpers'

import {
  reducer,
  INITIAL_STATE,
  UPDATE_STATE,
  VALIDATE_ERRORS,
  RESET_ERRORS,
  RESET_STATE,
} from './reducer'
import styles from './styles.css'

const Footer = ({ prismic }) => {
  const [state, dispatchState] = useReducer(reducer, INITIAL_STATE)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)

  const onEmailChange = useCallback(event => {
    const { name, value } = event.target
    dispatchState({
      type: UPDATE_STATE,
      payload: {
        [name]: value,
      },
    })

    dispatchState({ type: RESET_ERRORS })
  }, [])

  const onSubscribeClick = useCallback(
    async () => {
      if (!validateEmail(state.email) || !state.name) {
        dispatchState({
          type: VALIDATE_ERRORS,
        })
        return
      }

      dispatchState({
        type: RESET_ERRORS,
      })

      const payload = {
        name: state.name,
        email: state.email,
      }
      try {
        setIsLoading(true)
        await subscribeToNewsletter(payload)
        setModalOpen(true)
        dispatchState({ type: RESET_STATE })
        setIsLoading(false)
      } catch (e) {
        const msg = e[Object.keys(e)[0]]
        dispatchState({
          type: VALIDATE_ERRORS,
          payload: {
            email: msg,
          },
        })
        setIsLoading(false)
      }
    },
    [state.email, state.name]
  )

  const handleModalClose = useCallback(() => setModalOpen(false), [])

  const socialMedias = useMemo(
    () => {
      const links = []

      if (prismic?.facebookUrl) {
        links.push({
          name: `Lightyear's Facebook account`,
          icon: FacebookIcon,
          url: prismic.facebookUrl,
        })
      }

      if (prismic?.instagramUrl) {
        links.push({
          name: `Lightyear's Instagram account`,
          icon: InstagramIcon,
          url: prismic.instagramUrl,
        })
      }

      if (prismic?.twitterUrl) {
        links.push({
          name: `Lightyear's Twitter account`,
          icon: TwitterIcon,
          url: prismic.twitterUrl,
        })
      }

      return links
    },
    [prismic]
  )

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <section className={styles.links}>
          <h1 className={styles.hidden}>Social Media Links</h1>
          <Link to="/" aria-label="Landing Page">
            <svg className={styles.logo} viewBox={LightyearLogo.viewBox} aria-hidden="true">
              <use xlinkHref={`#${LightyearLogo.id}`} />
            </svg>
          </Link>
          <div className={styles['social-media']}>
            {socialMedias?.length > 0 &&
              socialMedias.map(({ url, name, icon }) => (
                <a
                  target="_blank"
                  rel="noopener noreferrer"
                  key={name}
                  href={url}
                  aria-label={name}
                  className={styles['social-media-link']}
                >
                  <svg
                    className={styles['social-media-icon']}
                    viewBox={icon.viewBox}
                    aria-hidden="true"
                  >
                    <use xlinkHref={`#${icon.id}`} />
                  </svg>
                </a>
              ))}
          </div>
        </section>
        <section className={styles['nav-section']}>
          <h1 className={styles.hidden}>Footer navigation links</h1>
          {prismic?.links?.length > 0 && (
            <nav className={styles.nav}>
              {prismic.links.map(({ label, url }, index) => (
                <Link
                  to={url}
                  className={classnames(styles['nav-link'], {
                    [styles['last-link']]: index + 1 === prismic.links.length,
                  })}
                  key={label}
                >
                  {label}
                </Link>
              ))}
            </nav>
          )}
        </section>
        <section className={styles['stay-up-to-date']}>
          <h1 className={styles.heading}>Stay up to date</h1>
          <p className={styles.detail}>Subscribe to our newsletter to receive our weekly feed.</p>
          <Input
            value={state.name}
            name="name"
            placeholder="Name"
            onChange={onEmailChange}
            className={styles['input-wrapper']}
            inputClassName={styles.input}
          />
          {state.errors.name && <p className={styles.error}>{state.errors.name}</p>}
          <Input
            value={state.email}
            name="email"
            placeholder="Email address"
            onChange={onEmailChange}
            className={styles['input-wrapper']}
            inputClassName={styles.input}
          />
          {state.errors.email && <p className={styles.error}>{state.errors.email}</p>}
          <Button
            theme={ButtonTheme.MALIBU}
            onClick={onSubscribeClick}
            disabled={isLoading}
            className={styles.subscribe}
          >
            Subscribe
          </Button>
        </section>
      </div>
      {isModalOpen && <NewsletterModal onClose={handleModalClose} />}
    </footer>
  )
}

Footer.propTypes = {
  prismic: PropTypes.shape({
    links: PropTypes.arrayOf(
      PropTypes.shape({
        label: PropTypes.string,
        url: PropTypes.string,
      })
    ),
    instagramUrl: PropTypes.string,
    facebookUrl: PropTypes.string,
    twitterUrl: PropTypes.string,
  }),
}

Footer.defaultProps = {
  prismic: {},
}

export default React.memo(Footer)
