import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import ArrowIcon from '_assets/icons/arrowdown-16-px.svg'
import noOutlineAction from '_hocs/no-outline-action'

import styles from './styles.css'

const MenuItem = ({ className, selected, children, onClick, id, onMouseDown, icon }) => {
  const onItemClick = useCallback(
    event => {
      event.preventDefault()
      onClick(id)
    },
    [onClick, id]
  )

  return (
    <button type="button" onClick={onItemClick} className={styles.button} onMouseDown={onMouseDown}>
      <div className={styles.left}>
        <svg
          className={classnames(styles.icon, { [styles['icon-selected']]: selected })}
          viewBox={icon.viewBox}
          aria-label={children}
          role="img"
        >
          <use xlinkHref={`#${icon.id}`} />
        </svg>
        <p className={classnames(className, styles.link, { [styles.selected]: selected })}>
          {children}
        </p>
      </div>
      <svg className={styles.arrow} viewBox={ArrowIcon.viewBox} aria-label={children} role="img">
        <use xlinkHref={`#${ArrowIcon.id}`} />
      </svg>
    </button>
  )
}

MenuItem.propTypes = {
  className: PropTypes.string,
  selected: PropTypes.bool,
  children: PropTypes.node,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func.isRequired,
  id: PropTypes.string.isRequired,
  icon: PropTypes.shape({
    viewBox: PropTypes.string,
    id: PropTypes.string,
  }).isRequired,
}

MenuItem.defaultProps = {
  className: '',
  selected: false,
  children: null,
  onClick: () => {},
}
export default noOutlineAction(MenuItem)
