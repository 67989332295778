import VisionPlusGoalsPlanetIcon from '_assets/icons/vision-plus-goals-planet-icon.svg'
import PowerMyFuturePlanetIcon from '_assets/icons/power-my-future-planet-icon.svg'
import IntegratedLeadershipPlanetIcon from '_assets/icons/integrated-leadership-planet-icon.svg'
import WholeProsperityPlanetIcon from '_assets/icons/whole-prosperity-planet-icon.svg'
import SatelliteIcon from '_assets/icons/satellite-icon.svg'

export const COURSE_TYPES = {
  POWER_MY_FUTURE: 'POWER_MY_FUTURE',
  VISION_PLUS_GOALS: 'VISION_PLUS_GOALS',
  WHOLE_PROSPERITY: 'WHOLE_PROSPERITY',
  INTEGRATED_LEADERSHIP: 'INTEGRATED_LEADERSHIP',
  WORKSHOPS: 'WORKSHOPS',
  CORE_WORKSHOPS: 'CORE_WORKSHOPS',
  LIGHTYEAR_COACH_TRAINING: 'LIGHTYEAR_COACH_TRAINING',
  LEGADO_PERSONAL: 'LEGADO_PERSONAL',
  GET_THERE_NOW: 'GET_THERE_NOW',
}

export const COURSE_TAGS = {
  [COURSE_TYPES.POWER_MY_FUTURE]: {
    COURSE_PAGE: ['Intro to Lightyear'],
    GUIDE_COURSE_MODAL: ['Free', 'Intro to Lightyear'],
    GUIDE_ALL_MODAL: ['Intro'],
    GENERAL_TAGS: ['Lightyear pick', 'Intro'],
  },
  [COURSE_TYPES.VISION_PLUS_GOALS]: {
    GENERAL_TAGS: ['Level 1'],
  },
  [COURSE_TYPES.INTEGRATED_LEADERSHIP]: {
    GENERAL_TAGS: ['Level 2'],
  },
  [COURSE_TYPES.WHOLE_PROSPERITY]: {
    GENERAL_TAGS: ['Level 3'],
  },
  [COURSE_TYPES.WORKSHOPS]: {
    GENERAL_TAGS: ['Special'],
  },
  [COURSE_TYPES.CORE_WORKSHOPS]: {
    GENERAL_TAGS: ['Special'],
  },
  [COURSE_TYPES.LIGHTYEAR_COACH_TRAINING]: {
    GENERAL_TAGS: ['Level 1 Coach Training'],
  },
  [COURSE_TYPES.LEGADO_PERSONAL]: {
    GENERAL_TAGS: ['Introducción a Lightyear'],
  },
  [COURSE_TYPES.GET_THERE_NOW]: {
    GENERAL_TAGS: ['Transform yourself and the World'],
  },
}

export const COURSE_TYPES_INFO = {
  [COURSE_TYPES.POWER_MY_FUTURE]: {
    name: 'Power My Future',
    courseTags: COURSE_TAGS.POWER_MY_FUTURE,
    slug: COURSE_TYPES.POWER_MY_FUTURE,
    camel: 'powerMyFuture',
    className: 'power-my-future',
    icon: PowerMyFuturePlanetIcon,
    description: '30 days to launch a life you love',
    descriptionGuide: 'Discover the basics of Lightyear and figure out what you want from life.',
    path: '/courses/power-my-future',
  },
  [COURSE_TYPES.VISION_PLUS_GOALS]: {
    name: 'Vision + Goals',
    courseTags: COURSE_TAGS.VISION_PLUS_GOALS,
    slug: COURSE_TYPES.VISION_PLUS_GOALS,
    camel: 'visionPlusGoals',
    className: 'vision-plus-goals',
    icon: VisionPlusGoalsPlanetIcon,
    description: 'Design a future you love',
    descriptionGuide: 'Learn how to set goals and hone your vision for your future.',
    path: '/courses/vision-plus-goals',
  },
  [COURSE_TYPES.INTEGRATED_LEADERSHIP]: {
    name: 'Integrated Leadership',
    courseTags: COURSE_TAGS.INTEGRATED_LEADERSHIP,
    slug: COURSE_TYPES.INTEGRATED_LEADERSHIP,
    camel: 'integratedLeadership',
    className: 'integrated-leadership',
    icon: IntegratedLeadershipPlanetIcon,
    description: 'Turn your big ideas into a reality',
    descriptionGuide: 'Discover the next chapter of Lightyear.',
    path: '/courses/integrated-leadership',
  },
  [COURSE_TYPES.WHOLE_PROSPERITY]: {
    name: 'Whole Prosperity',
    courseTags: COURSE_TAGS.WHOLE_PROSPERITY,
    slug: COURSE_TYPES.WHOLE_PROSPERITY,
    camel: 'wholeProsperity',
    className: 'whole-prosperity',
    icon: WholeProsperityPlanetIcon,
    description: 'Upgrade your self-worth and your relationships',
    descriptionGuide: 'Upgrade your self-worth and your relationships.',
    path: '/courses/whole-prosperity',
  },
  [COURSE_TYPES.CORE_WORKSHOPS]: {
    name: 'Workshops',
    courseTags: COURSE_TAGS.CORE_WORKSHOPS,
    slug: COURSE_TYPES.CORE_WORKSHOPS,
    camel: 'workshops',
    className: 'custom-classes',
    icon: SatelliteIcon,
    description: 'A monthly series to explore the Universal Laws, Mind Gems, and Brain Rings',
    path: '/classes/search',
    state: {
      categories: 'Workshops',
    },
  },
  [COURSE_TYPES.WORKSHOPS]: {
    name: 'Workshops',
    courseTags: COURSE_TAGS.WORKSHOPS,
    slug: COURSE_TYPES.WORKSHOPS,
    camel: 'workshops',
    className: 'custom-classes',
    description: 'A monthly series to explore the Universal Laws, Mind Gems, and Brain Rings',
    path: '/courses/workshops',
  },
  [COURSE_TYPES.LIGHTYEAR_COACH_TRAINING]: {
    name: 'Lightyear Coach Training',
    courseTags: COURSE_TAGS.LIGHTYEAR_COACH_TRAINING,
    slug: COURSE_TYPES.LIGHTYEAR_COACH_TRAINING,
    camel: 'lightyearCoachTraining',
    description: '',
    path: '/courses/lightyear-coach-training',
  },
  [COURSE_TYPES.LEGADO_PERSONAL]: {
    name: 'Legado Personal',
    courseTags: COURSE_TAGS.LEGADO_PERSONAL,
    slug: COURSE_TYPES.LEGADO_PERSONAL,
    camel: 'legadoPersonal',
    description: '',
    path: '/courses/legado-personal',
  },
  [COURSE_TYPES.GET_THERE_NOW]: {
    name: 'Get There Now',
    courseTags: COURSE_TAGS.GET_THERE_NOW,
    slug: COURSE_TYPES.GET_THERE_NOW,
    camel: 'getThereNow',
    description: '',
    path: '/courses/get-there-now',
  },
}

export const CORE_PROGRAMS = [
  COURSE_TYPES_INFO[COURSE_TYPES.POWER_MY_FUTURE],
  COURSE_TYPES_INFO[COURSE_TYPES.VISION_PLUS_GOALS],
  COURSE_TYPES_INFO[COURSE_TYPES.INTEGRATED_LEADERSHIP],
  COURSE_TYPES_INFO[COURSE_TYPES.WHOLE_PROSPERITY],
  COURSE_TYPES_INFO[COURSE_TYPES.CORE_WORKSHOPS],
]

export const CORE_PROGRAMS_PATHS = CORE_PROGRAMS.map(program => program.path)

export const MAIN_PROGRAM_COURSES_NAMES = CORE_PROGRAMS.map(course => course.name).join(',')
