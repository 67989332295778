import React, { useCallback } from 'react'
import PropTypes from 'prop-types'

import Card from '_components/card'
import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

export const PostActionType = {
  IGNORE: 'ignore',
  BLOCK: 'block',
  DELETE: 'delete',
  EDIT: 'edit',
  FLAG: 'flag',
}

const getLabel = type => {
  switch (type) {
    case PostActionType.IGNORE:
      return 'YES, IGNORE'
    case PostActionType.BLOCK:
      return 'YES, BLOCK'
    case PostActionType.DELETE:
      return 'YES, DELETE'
    default:
      return 'YES'
  }
}

const getTitle = type => {
  switch (type) {
    case PostActionType.IGNORE:
      return `Are you sure that you would like to ignore this member's posts?`
    case PostActionType.BLOCK:
      return 'Are you sure that you would like to block this member?'
    case PostActionType.DELETE:
      return 'Are you sure that you would like to delete this post?'
    default:
      return 'Are you sure that you want to perform this action?'
  }
}

const PostActionsModal = ({ onClose, type, onPostDelete, onHidePosts, onBlockUser }) => {
  const onConfirmClick = useCallback(
    () => {
      if (type === PostActionType.DELETE) {
        onPostDelete()
      }

      if (type === PostActionType.IGNORE) {
        onHidePosts()
      }

      if (type === PostActionType.BLOCK) {
        onBlockUser()
      }

      onClose()
    },
    [onBlockUser, onClose, onHidePosts, onPostDelete, type]
  )

  return (
    <Card className={styles.container}>
      <h3>{getTitle(type)}</h3>
      <div className={styles.actions}>
        <Button theme={ButtonTheme.SECONDARY} label="CANCEL" onClick={onClose} />
        <Button theme={ButtonTheme.PRIMARY} label={getLabel(type)} onClick={onConfirmClick} />
      </div>
    </Card>
  )
}

PostActionsModal.propTypes = {
  onClose: PropTypes.func,
  type: PropTypes.oneOf(Object.keys(PostActionType).map(type => PostActionType[type])),
  onPostDelete: PropTypes.func,
  onHidePosts: PropTypes.func,
  onBlockUser: PropTypes.func,
}

PostActionsModal.defaultProps = {
  onClose: () => {},
  type: PostActionType.IGNORE,
  onPostDelete: () => {},
  onHidePosts: () => {},
  onBlockUser: () => {},
}

export default PostActionsModal
