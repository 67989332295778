import humps from 'humps'

import { get, post, patch } from './requests'

export const getPublicClasses = key => (pageSize, page) =>
  get(['new', 'classes'], { key, params: { [`page-size`]: pageSize, page } })

export const getClasses = key => ordering =>
  get(['new', 'my-classes'], { key, params: { ...ordering } })

export const getPublicClass = (key, classId) => get(['new', 'classes', classId], { key })

export const initialEnroll = key => ({ classId, cardToken = null, coupon }) => {
  const payload = cardToken ? humps.decamelizeKeys({ cardToken, coupon }) : null
  return post(['new', 'classes', classId, 'initial-enroll'], { key }, payload)
}

export const enroll = key => (classId, token) => {
  const payload = token ? humps.decamelizeKeys(token) : null
  return post(['new', 'classes', classId, 'enroll'], { key }, payload)
}

export const getClass = key => classId => get(['new', 'my-classes', classId], { key })

export const updateAssignment = key => (classId, assignmentId, payload) =>
  patch(
    ['new', 'my-classes', classId, 'assignments', assignmentId],
    { key },
    humps.decamelizeKeys(payload)
  )

export const bulkUpdateAnswer = key => (classId, assignmentId, payload) =>
  patch(
    ['new', 'my-classes', classId, 'assignments', assignmentId, 'questions', 'bulk-update'],
    { key },
    humps.decamelizeKeys(payload)
  )

export const updateIntention = key => (classId, payload) =>
  patch(['new', 'my-classes', classId], { key }, humps.decamelizeKeys(payload))

export const getAllAssignmentResponses = key => (classId, assignmentId) =>
  get(['new', 'my-classes', classId, 'assignments', assignmentId, 'other-responses'], { key })

export const getAllParticipants = key => (classId, page) =>
  get(['new', 'my-classes', classId, 'participants'], { key, params: { page } })

export const startAssignmentVideo = key => meetingId =>
  post(['meetings', meetingId, 'start'], { key })

export const validateDiscountCoupon = key => code => get(['coupon'], { key, params: { code } })

// Modular classes endpoints
export const getClassesNew = key => ordering =>
  get(['new', 'my-classes'], { key, params: { ...ordering } })
