import React, { useMemo, useCallback, useState, useReducer } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useLocation } from '@reach/router'

import SectionTitle from '_components/landing-components/section-title'
import Button from '_components/landing-components/button'
import { prismicTitlePropType } from '_utils/proptypes'
import Input from '_components/input'
import { subscribeToCourse } from '_services/public-courses'
import { validateEmail } from '_utils/helpers'
import { landingPageSelector } from '_modules/prismic/selectors'
import { formatPathname } from '_utils/location'
import NewsletterModal from '_components/landing-components/newsletter-modal'

import {
  reducer,
  INITIAL_STATE,
  UPDATE_STATE,
  VALIDATE_ERRORS,
  RESET_ERRORS,
  RESET_STATE,
} from './reducer'
import styles from './styles.css'

const GetNotifiedSection = ({ slice, id }) => {
  const { pathname } = useLocation()
  const currentView = useMemo(() => formatPathname(pathname), [pathname])
  const { courseSlug } = useSelector(state => landingPageSelector(state, currentView))

  const [state, dispatchState] = useReducer(reducer, INITIAL_STATE)
  const [isLoading, setIsLoading] = useState(false)
  const [isModalOpen, setModalOpen] = useState(false)

  const backgroundStyles = useMemo(
    () => {
      if (slice.backgroundImage?.thumbnail) {
        return {
          backgroundImage: `url(${slice.backgroundImage.thumbnail})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: 'center center',
        }
      }
      return {}
    },
    [slice.backgroundImage]
  )

  const handleSubmit = useCallback(
    async event => {
      event.preventDefault()
      if (!validateEmail(state.email) || !state.name) {
        dispatchState({
          type: VALIDATE_ERRORS,
        })
        return
      }

      dispatchState({
        type: RESET_ERRORS,
      })

      const payload = {
        name: state.name,
        email: state.email,
      }
      try {
        setIsLoading(true)
        await subscribeToCourse(courseSlug, payload)
        setModalOpen(true)
        dispatchState({ type: RESET_STATE })
        setIsLoading(false)
      } catch (e) {
        const msg = e[Object.keys(e)[0]]
        dispatchState({
          type: VALIDATE_ERRORS,
          payload: {
            email: msg,
          },
        })
        setIsLoading(false)
      }
    },
    [courseSlug, state.email, state.name]
  )

  const handleInputChange = useCallback(event => {
    const { name, value } = event.target
    dispatchState({
      type: UPDATE_STATE,
      payload: {
        [name]: value,
      },
    })

    dispatchState({ type: RESET_ERRORS })
  }, [])

  const handleModalClose = useCallback(() => setModalOpen(false), [])

  return (
    <section className={styles.section} style={backgroundStyles} id={id}>
      <div className={styles.content}>
        {slice.sectionTitle && (
          <SectionTitle title={slice.sectionTitle} className={styles.title} isCustom />
        )}
        {slice.sectionText && <p className={styles['section-text']}>{slice.sectionText}</p>}
        <form onSubmit={handleSubmit} className={styles.form}>
          <Input
            onChange={handleInputChange}
            inputClassName={styles.input}
            value={state.name}
            name="name"
            placeholder="Name"
          />
          {state.errors.name && <p className={styles.error}>{state.errors.name}</p>}
          <Input
            onChange={handleInputChange}
            inputClassName={styles.input}
            value={state.email}
            name="email"
            placeholder="Email"
          />
          {state.errors.email && <p className={styles.error}>{state.errors.email}</p>}
          <Button type="submit" disabled={isLoading}>
            Submit
          </Button>
        </form>
      </div>
      {isModalOpen && (
        <NewsletterModal
          onClose={handleModalClose}
          title="Thank you!"
          paragraph="We will send you a reminder email when Application opens."
          paragraphSpan={null}
        />
      )}
    </section>
  )
}

GetNotifiedSection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    sectionText: PropTypes.string,
    backgroundImage: PropTypes.shape({
      thumbnail: PropTypes.string,
    }),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(GetNotifiedSection)
