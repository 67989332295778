import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import { coachesPropTypes } from '_utils/proptypes'

import styles from './styles.css'
import CoachName from './coach-name'
import CoachAvatar from './coach-avatar'

export const COACH_CONTENT_DIRECTION = {
  ROW: 'row',
  COLUMN: 'column',
}

const CoachDetails = ({
  coachList,
  className,
  coachesBreakLine,
  subContent,
  contentDirection,
  centralize,
}) => (
    <div className={classnames(styles['coaches-resume'], styles[contentDirection], className)}>
      <div className={styles['coach-avatar-wrapper']}>
        <CoachAvatar coaches={coachList} dynamicSize centralize={centralize} />
      </div>

      <p className={styles['coach-lead']}>
        Lead by {coachesBreakLine && <br />}
        <CoachName coaches={coachList} />
      </p>
      {subContent && <p className={styles['coach-detail-subcontent']}>{subContent}</p>}
    </div>
  )

CoachDetails.propTypes = {
  coachList: coachesPropTypes,
  className: PropTypes.string,
  coachesBreakLine: PropTypes.bool,
  subContent: PropTypes.string,
  contentDirection: PropTypes.oneOf(Object.values(COACH_CONTENT_DIRECTION)),
  centralize: PropTypes.bool,
}

CoachDetails.defaultProps = {
  coachList: [],
  className: '',
  coachesBreakLine: false,
  subContent: '',
  contentDirection: COACH_CONTENT_DIRECTION.ROW,
  centralize: false,
}

export default React.memo(CoachDetails)
