export const INITIAL_STATE = {
  modalType: null,
  editableMessage: '',
  commentId: null,
}

export const OPEN_MODAL = 'OPEN_MODAL'
export const CLOSE_MODAL = 'CLOSE_MODAL'

export const reducer = (state, action) => {
  switch (action.type) {
    case OPEN_MODAL: {
      return {
        modalType: action.modalType,
        editableMessage: action.editableMessage,
        commentId: action.commentId,
      }
    }
    case CLOSE_MODAL: {
      return INITIAL_STATE
    }
    default:
      return state
  }
}
