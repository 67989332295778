import { get, upload, patch, post, del } from './requests'

export const getPosts = key => payload => get(['posts'], { key, params: { ...payload } })

export const createPost = key => payload => {
  const data = new FormData()

  if (payload.photo) {
    data.append('photo', payload.photo)
  }

  if (payload.fatherPost) {
    data.append('father_post', payload.fatherPost)
  }

  if (payload.videoLink) {
    data.append('video_link', payload.videoLink)
  }

  if (payload.message) {
    data.append('message', payload.message)
  }

  if (payload.assignment) {
    data.append('assignment', payload.assignment)
  }

  return upload(['posts'], { key }, data)
}

export const editPost = key => (payload, postId) => patch(['posts', postId], { key }, payload)

export const shazam = key => postId => post(['posts', postId, 'shazam'], { key })

export const unShazam = key => postId => post(['posts', postId, 'unshazam'], { key })

export const deletePost = key => postId => del(['posts', postId], { key })

export const flagPost = key => postId => post(['posts', postId, 'flag'], { key })
