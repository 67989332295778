import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { connect, useSelector, useDispatch } from 'react-redux'
import { Map } from 'immutable'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { toast } from 'react-toastify'

import { usePrevious } from '_utils/hooks'
import Footer from '_components/footer'
import BillingIcon from '_assets/icons/billing-24-px.svg'
import BlockedIcon from '_assets/icons/blocked-24-px.svg'
import EmailNotificationsIcon from '_assets/icons/emailnotifications-24-px.svg'
import MessageIcon from '_assets/icons/message-24-px.svg'
import PasswordIcon from '_assets/icons/password-24-px.svg'
import ProfileIcon from '_assets/icons/profile-24-px.svg'
import HiddenPostsIcon from '_assets/icons/ignored-24-px.svg'
import ArrowIcon from '_assets/icons/arrow-left.svg'
import { onMouseDown } from '_utils/aria'
import { MOBILE_WIDTH } from '_utils/constants'
import { updateUser, UPDATE_USER, linkZoom } from '_modules/user/actions'
import Toastr, { ToastrTheme } from '_components/toastr'
import { userShape } from '_utils/proptypes'
import VideoCallIcon from '_assets/icons/videocall.svg'
import { verifyZoom, parseZoomCode } from '_utils/helpers'
import { checkIsLinkingZoom, errorLinkingZoom } from '_modules/user/selectors'

import BlockedMembers from './blocked-members'
import ChangePasswordCard from './change-password'
import EmailNotificationsCard from './email-notifications'
import ChangeEmailCard from './change-email'
import Billing from './billing'
import MenuItem from './menu-item'
import PublicProfileCard from './public-profile-card'
import HiddenPosts from './hidden-posts'
import ZoomSettings from './zoom-settings'
import styles from './styles.css'

const CardSelection = {
  PUBLIC_PROFILE: 'Profile',
  EMAIL_NOTIFICATIONS: 'Email notifications',
  CHANGE_PASSWORD: 'Change password',
  CHANGE_EMAIL: 'Change email',
  BILLING: 'Billing',
  BLOCKED: 'Blocked members',
  HIDDEN_POSTS: 'Hidden Posts',
  ACCOUNT_SETTINGS: 'Account settings',
  ZOOM: 'Zoom settings',
}

const mapStateToProps = ({ user, loading, error }) => ({
  user: user.toJS(),
  isUpdating: !!loading.get(UPDATE_USER.ACTION),
  updatingError: error.get(UPDATE_USER.ACTION),
})

const mapDispatchToProps = {
  patchUser: updateUser,
  updatingError: Map(),
}

const AccountSettings = ({
  user,
  patchUser,
  updatingError,
  isUpdating,
  className,
  blockedUser,
  location,
}) => {
  const setDefaultTab = useCallback(
    () => {
      if (!location.state) {
        return null
      }

      if (location.state.billing) {
        return CardSelection.BILLING
      }

      return typeof window !== 'undefined' && window.innerWidth < MOBILE_WIDTH
        ? CardSelection.ACCOUNT_SETTINGS
        : CardSelection.PUBLIC_PROFILE
    },
    [location]
  )

  const dispatch = useDispatch()
  const isLinkingZoom = useSelector(checkIsLinkingZoom)
  const linkingZoomError = useSelector(errorLinkingZoom)
  const wasLinkingZoom = usePrevious(isLinkingZoom)
  const prevLoading = usePrevious(isUpdating)
  const [state, setState] = useState({
    selected: setDefaultTab(),
    user: {
      name: user.name || '',
      company: user.company || '',
      title: user.title || '',
      location: user.company || '',
      oldPassword: '',
      newPassword: '',
    },
    isMenuOpen:
      typeof window !== 'undefined' &&
      window.innerWidth < MOBILE_WIDTH &&
      !location?.state?.billing,
  })

  const sizeCheck = useCallback(
    () => {
      if (
        typeof window !== 'undefined' &&
        window.innerWidth > MOBILE_WIDTH &&
        state.selected === CardSelection.ACCOUNT_SETTINGS
      ) {
        setState({
          selected: CardSelection.PUBLIC_PROFILE,
          isMenuOpen: false,
        })
      }
    },
    [state.selected]
  )

  const zoomRedirectURI = useMemo(() => `${location.origin}/account`, [location.origin])
  const wasRedirectedByZoom = useMemo(() => verifyZoom(location.search), [location.search])

  useEffect(
    () => {
      if (wasLinkingZoom && !isLinkingZoom) {
        if (!linkingZoomError || (linkingZoomError && linkingZoomError.size === 0)) {
          toast(<Toastr theme={ToastrTheme.SUCCESS} content="Zoom linked successfully" />)
          return
        }
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content="Something went wrong, please try again later."
          />
        )
      }
    },
    [isLinkingZoom, linkingZoomError, wasLinkingZoom]
  )

  useEffect(
    () => {
      if (wasLinkingZoom === undefined && wasRedirectedByZoom && !isLinkingZoom) {
        dispatch(
          linkZoom({
            code: parseZoomCode(location.search),
            redirect_uri: zoomRedirectURI,
          })
        )
      }
    },
    [dispatch, isLinkingZoom, location.search, wasLinkingZoom, wasRedirectedByZoom, zoomRedirectURI]
  )

  useEffect(
    () => {
      if (prevLoading && !isUpdating) {
        if (updatingError.size === 0) {
          toast(<Toastr theme={ToastrTheme.SUCCESS} content="Settings updated successfully!" />)
          return
        }
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content="Something went wrong, please try again later."
          />
        )
      }
    },
    [isUpdating, prevLoading, sizeCheck, updatingError.size]
  )

  useEffect(
    () => {
      window.addEventListener('resize', sizeCheck, { passive: true })

      return () => {
        window.removeEventListener('resize', sizeCheck, { passive: true })
      }
    },
    [sizeCheck]
  )

  const onMenuClick = useCallback(id => {
    setState({
      selected: id,
      isMenuOpen: false,
    })
  }, [])

  const onSubmitPatchProfile = useCallback(payload => patchUser(payload), [patchUser])

  const renderSwitch = useCallback(
    () => {
      switch (state.selected) {
        case CardSelection.PUBLIC_PROFILE:
        case CardSelection.ACCOUNT_SETTINGS:
          return (
            <PublicProfileCard user={user} onClick={onSubmitPatchProfile} loading={isUpdating} />
          )
        case CardSelection.EMAIL_NOTIFICATIONS:
          return (
            <EmailNotificationsCard
              onClick={onSubmitPatchProfile}
              user={user}
              loading={isUpdating}
            />
          )
        case CardSelection.CHANGE_PASSWORD:
          return <ChangePasswordCard />
        case CardSelection.CHANGE_EMAIL:
          return <ChangeEmailCard email={user.email} />
        case CardSelection.BILLING:
          return <Billing user={user} />
        case CardSelection.HIDDEN_POSTS:
          return <HiddenPosts />
        case CardSelection.BLOCKED:
          return <BlockedMembers />
        case CardSelection.ZOOM:
          return <ZoomSettings hasZoom={user.hasZoom} zoomRedirectURI={zoomRedirectURI} />
        default:
          return (
            <PublicProfileCard user={user} onClick={onSubmitPatchProfile} loading={isUpdating} />
          )
      }
    },
    [isUpdating, onSubmitPatchProfile, state.selected, user, zoomRedirectURI]
  )

  const toggleMenu = useCallback(() => {
    setState({
      isMenuOpen: true,
      selected: CardSelection.ACCOUNT_SETTINGS,
    })
  }, [])

  const renderMenu = useCallback(
    () => (
        <div className={styles.menu}>
          <MenuItem
            onClick={onMenuClick}
            selected={
              state.selected === CardSelection.PUBLIC_PROFILE ||
              state.selected === CardSelection.ACCOUNT_SETTINGS
            }
            id={CardSelection.PUBLIC_PROFILE}
            icon={ProfileIcon}
          >
            Profile
          </MenuItem>
          <MenuItem
            onClick={onMenuClick}
            selected={state.selected === CardSelection.EMAIL_NOTIFICATIONS}
            id={CardSelection.EMAIL_NOTIFICATIONS}
            icon={EmailNotificationsIcon}
          >
            Email notifications
          </MenuItem>
          <MenuItem
            onClick={onMenuClick}
            selected={state.selected === CardSelection.CHANGE_PASSWORD}
            id={CardSelection.CHANGE_PASSWORD}
            icon={PasswordIcon}
          >
            Change password
          </MenuItem>
          <MenuItem
            onClick={onMenuClick}
            selected={state.selected === CardSelection.CHANGE_EMAIL}
            id={CardSelection.CHANGE_EMAIL}
            icon={MessageIcon}
          >
            Change email
          </MenuItem>
          <MenuItem
            onClick={onMenuClick}
            selected={state.selected === CardSelection.BILLING}
            id={CardSelection.BILLING}
            icon={BillingIcon}
          >
            Billing
          </MenuItem>
          <MenuItem
            onClick={onMenuClick}
            selected={state.selected === CardSelection.HIDDEN_POSTS}
            id={CardSelection.HIDDEN_POSTS}
            icon={HiddenPostsIcon}
          >
            Hidden Posts
          </MenuItem>
          <MenuItem
            onClick={onMenuClick}
            selected={state.selected === CardSelection.BLOCKED}
            id={CardSelection.BLOCKED}
            icon={BlockedIcon}
          >
            Blocked members
          </MenuItem>
          <MenuItem
            onClick={onMenuClick}
            selected={state.selected === CardSelection.ZOOM}
            id={CardSelection.ZOOM}
            icon={VideoCallIcon}
          >
            Zoom settings
          </MenuItem>
        </div>
      ),
    [onMenuClick, state.selected]
  )

  return (
    <>
      <div className={classnames(styles.container, className)}>
        <h1 className={styles.title}>Account Settings</h1>
        <div className={styles['small-screen-title']}>
          <button
            type="button"
            className={classnames({
              [styles.hide]: state.selected === CardSelection.ACCOUNT_SETTINGS,
            })}
            onClick={toggleMenu}
            onMouseDown={onMouseDown}
            aria-label="Return to menu"
          >
            <svg className={styles.icon} viewBox={ArrowIcon.viewBox} aria-hidden="true">
              <use xlinkHref={`#${ArrowIcon.id}`} />
            </svg>
          </button>
          <h1 className={styles['menu-title']}>{state.selected}</h1>
        </div>
        <div className={styles['profile-content']}>
          <div className={classnames(styles.scroll, { [styles.hide]: !state.isMenuOpen })}>
            {blockedUser ? renderMenu(CardSelection.BILLING) : renderMenu(state.selected)}
          </div>
          <div className={classnames(styles.card, { [styles.hide]: state.isMenuOpen })}>
            {blockedUser ? renderSwitch(CardSelection.BILLING) : renderSwitch(state.selected)}
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

AccountSettings.propTypes = {
  className: PropTypes.string,
  user: userShape.isRequired,
  patchUser: PropTypes.func.isRequired,
  isUpdating: PropTypes.bool.isRequired,
  blockedUser: PropTypes.bool,
  updatingError: ImmutablePropTypes.map,
  location: PropTypes.shape({
    state: PropTypes.shape({
      billing: PropTypes.bool,
    }),
    origin: PropTypes.string,
    search: PropTypes.string,
  }).isRequired,
}

AccountSettings.defaultProps = {
  className: '',
  updatingError: Map(),
  blockedUser: false,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(AccountSettings)
