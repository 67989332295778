import React, { useCallback, useEffect, useMemo } from 'react'
import classnames from 'classnames'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation, navigate } from '@reach/router'
import { List } from 'immutable'

import MembershipIcon from '_assets/icons/membership-icon.svg'
import Button, { ButtonTheme } from '_components/landing-components/button'
import CoachDetails from '_views/class-details/coach-details'
import { formatClassCalls, formatDate, getCourseByCategory } from '_utils/helpers'
import Card from '_components/card'
import Accordion from '_components/accordion'
import AccordionAssignment from '_components/accordion/accordion-assignment'
import Svg from '_components/svg'
import RocketLoader from '_components/rocket-loader'
import SelfPacedCard from '_components/self-paced-card'
import ClassPeriodCard, { CLASS_PERIOD_THEME } from '_components/class-period-card'
import { enrollRedirectUrl } from '_utils/enroll-course-redirect'
import { CLASS_TYPE } from '_constants/class'
import {
  authTokenSelector,
  hasCompletedOnboardingSelector,
  isGoogleLoginLoadingSelector,
  isLoginLoadingSelector,
  isSignUpLoadingSelector,
} from '_modules/user/selectors'
import {
  getPublicClassSelector,
  isGetPublicClassLoadingSelector,
} from '_modules/public-classes/selectors'
import { getPublicClass } from '_modules/public-classes/actions'
import usePrevious from '_hooks/use-previous'
import { hasHighlightMessageSelector, highlightLinkSelector } from '_modules/prismic/selectors'

import styles from './styles.css'
import CoachLore from './coach-lore'

const ClassDetails = () => {
  const { classId } = useParams()

  const dispatch = useDispatch()

  const location = useLocation()

  const authToken = useSelector(authTokenSelector)
  const isLoadingClass = useSelector(isGetPublicClassLoadingSelector)
  const hasHighlightMessage = useSelector(hasHighlightMessageSelector)
  const highlightLink = useSelector(highlightLinkSelector)
  const hasUserCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)
  const currentClass = useSelector(state => getPublicClassSelector(state, classId))
  const currentCourseInfo = getCourseByCategory(currentClass?.category)
  const isGoogleLoginLoading = useSelector(isGoogleLoginLoadingSelector)
  const isLoadingLogin = useSelector(isLoginLoadingSelector)
  const isSignUpLoading = useSelector(isSignUpLoadingSelector)

  const wasLoadingClass = usePrevious(isLoadingClass)
  const wasGoogleLoginLoading = usePrevious(isGoogleLoginLoading)
  const wasLoadingLogin = usePrevious(isLoadingLogin)
  const wasSignUpLoading = usePrevious(isSignUpLoading)

  useEffect(
    () => {
      dispatch(getPublicClass(classId))
    },
    [classId, dispatch]
  )

  const highlightMessageStyles = useMemo(
    () => !location.pathname.includes(highlightLink) && hasHighlightMessage,
    [hasHighlightMessage, highlightLink, location.pathname]
  )

  const accordionPlaceholder = useMemo(
    () => `${currentClass.category} assignment`,
    [currentClass.category]
  )

  const classCalls = useMemo(
    () => {
      const formattedCalls = formatClassCalls(currentClass.sections)
      return formattedCalls
    },
    [currentClass.sections]
  )

  const accordionList = useMemo(
    () => {
      if (currentClass?.sections?.size === 0) {
        return List()
      }

      return currentClass?.sections?.map(section => {
        const formattedSection = section?.toJS()

        return {
          ...formattedSection,
          id: formattedSection.id,
          elementContent: formattedSection.assignments?.map(assignment => (
            <AccordionAssignment
              key={assignment.id}
              assignment={assignment}
              placeholder={accordionPlaceholder}
            />
          )),
        }
      })
    },
    [accordionPlaceholder, currentClass]
  )

  const instructorsList = useMemo(
    () => {
      if (currentClass?.instructors?.size) {
        return currentClass?.instructors
      }

      return List()
    },
    [currentClass]
  )

  const wasLoggingIn = useMemo(
    () => (
        (!isGoogleLoginLoading && wasGoogleLoginLoading) ||
        (!isLoadingLogin && wasLoadingLogin) ||
        (!isSignUpLoading && wasSignUpLoading)
      ),
    [
      isGoogleLoginLoading,
      isLoadingLogin,
      isSignUpLoading,
      wasGoogleLoginLoading,
      wasLoadingLogin,
      wasSignUpLoading,
    ]
  )

  const isEnrolling = useMemo(() => location?.state?.isEnrolling, [location])

  const redirectUrl = useCallback(
    () => {
      if (currentClass?.enrolled) {
        return `/class/${currentClass?.enrolled}`
      }

      if (authToken) {
        return `?payments&class=${currentClass?.id}`
      }
      return '?sign-up'
    },
    [authToken, currentClass]
  )

  const redirectState = useCallback(
    id => ({
      redirectTo: enrollRedirectUrl({
        classId: id,
        authToken,
        hasCompletedOnboarding: hasUserCompletedOnboarding,
      }),
      classId: id,
      isEnrolling: true,
    }),
    [authToken, hasUserCompletedOnboarding]
  )

  const classPrice = useMemo(
    () => (currentClass?.price === 0 ? 'Free' : `$${currentClass?.price?.toFixed(2)}`),
    [currentClass]
  )

  const enrollButtonSubContent = useMemo(
    () => {
      if (currentClass?.enrolled) {
        return 'Go to class'
      }

      return currentClass?.customTrialDays > 0
        ? `${currentClass?.customTrialDays} free subscription day${
            currentClass?.customTrialDays > 1 ? 's' : ''
          }`
        : ''
    },
    [currentClass]
  )

  useEffect(
    () => {
      if (wasLoggingIn) {
        dispatch(getPublicClass(classId))
      }
    },
    [classId, dispatch, wasLoggingIn]
  )

  // When the user clicks on course and login, it checks if the user is already enrolled and sends them to the correct route
  useEffect(
    () => {
      if (!isLoadingClass && wasLoadingClass && authToken && isEnrolling) {
        if (currentClass?.enrolled) {
          navigate(`/class/${currentClass?.enrolled}`)
        } else if (authToken) {
          navigate(`?payments&class=${currentClass?.id}`)
        }
      }
    },
    [authToken, currentClass, isEnrolling, isLoadingClass, wasLoadingClass]
  )

  return (
    <section
      className={classnames(styles['class-details-container'], {
        [styles['highlight-message']]: highlightMessageStyles,
      })}
    >
      {isLoadingClass ? (
        <div className={styles['loader-wrapper']}>
          <RocketLoader />
        </div>
      ) : (
        <>
          {currentClass.coverPhoto && (
            <img
              className={styles['class-cover-picture']}
              src={currentClass?.coverPhoto}
              alt={`${currentClass?.name}'s cover`}
            />
          )}

          <div className={styles['class-info']}>
            <h1 className={styles['class-title']}>{currentClass?.name}</h1>
            <ClassPeriodCard
              startDate={currentClass.startDate}
              endDate={currentClass.endDate}
              showEndDate
              theme={CLASS_PERIOD_THEME.INDIVIDUAL_CLASS_DETAIL}
            />

            {currentClass?.type === CLASS_TYPE.COACH_LED.slug && (
              <CoachDetails
                coachList={instructorsList || []}
                coachesBreakLine
                className={styles['coaches-details']}
                centralize
              />
            )}

            <p className={styles.description}>{currentClass?.description}</p>
            {classCalls.length > 0 && (
              <div className={styles['call-list']}>
                <span>Video calls on date{`${classCalls.length > 1 ? 's' : ''}`}: </span>
                {classCalls.map((call, index) => (
                  <span key={call.id}>
                    {formatDate(call.getIn(['meeting', 'startTime']), 'LLL dd, p')}
                    {classCalls.length - 1 === index ? '' : '; '}
                  </span>
                ))}
                <span> (Your date and time)</span>
              </div>
            )}
            <Button
              state={redirectState(classId)}
              url={redirectUrl(currentClass)}
              className={styles['enroll-button']}
              theme={ButtonTheme.MALIBU}
            >
              <strong className={styles.enroll}>
                {currentClass?.enrolled ? 'Already enrolled' : 'Enroll now'}
              </strong>
              <span className={styles['sub-content']}>{enrollButtonSubContent}</span>
              <span className={styles.divisor} />
              <strong className={styles.price}>{classPrice}</strong>
            </Button>

            <div
              className={classnames(styles['card-list'], {
                [styles['content-only']]: !currentCourseInfo,
              })}
            >
              <Card className={styles['course-card']}>
                {currentCourseInfo?.icon && (
                  <Svg icon={currentCourseInfo?.icon} className={styles['course-icon']} />
                )}
                <h1 className={classnames(styles['card-title'], styles['course-card-title'])}>
                  {currentClass?.category}
                </h1>
                <p className={styles['course-card-description']}>
                  You have control over your future, and you get to choose, design and create it
                  today.
                </p>

                {currentCourseInfo?.path && (
                  <Button
                    className={styles['learn-more-button']}
                    theme={ButtonTheme.GHOST}
                    url={currentCourseInfo?.path}
                  >
                    Learn more
                  </Button>
                )}
              </Card>
              <Card className={styles['membership-card']}>
                <h1 className={classnames(styles['card-title'], styles['membership-card-title'])}>
                  With community
                </h1>
                <p className={styles['membership-card-description']}>
                  Take this course with the support of the Lightyear Community.
                </p>
                <svg
                  className={styles.membership}
                  viewBox={MembershipIcon.viewBox}
                  aria-label="Class membership"
                  role="img"
                >
                  <use xlinkHref={`#${MembershipIcon.id}`} />
                </svg>
              </Card>
            </div>
          </div>
          {currentClass?.type === CLASS_TYPE.COACH_LED.slug ? (
            <div className={styles['coaches-info']}>
              <h2 className={classnames(styles.title, styles['coaches-info-title'])}>
                About the leaders
              </h2>

              <CoachLore coachList={instructorsList} />
            </div>
          ) : (
            <SelfPacedCard
              className={classnames(styles['self-paced-card'], {
                [styles['content-only']]: !currentCourseInfo,
              })}
            />
          )}
          {accordionList?.size > 0 && (
            <div className={styles.syllabus}>
              <h2 className={classnames(styles.title, styles['syllabus-title'])}>Syllabus</h2>
              <Accordion accordionList={accordionList} />
            </div>
          )}
        </>
      )}
    </section>
  )
}

export default ClassDetails
