import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import ReactTooltip from 'react-tooltip'

import ErrorIcon from '_assets/icons/error.svg'

import styles from './styles.css'

const ErrorTooltip = ({ className, message }) => {
  useEffect(
    () => {
      if (message.length > 0) {
        ReactTooltip.rebuild()
      }
    },
    [message]
  )

  return (
    <>
      <ReactTooltip className={styles.tooltip} type="light" />
      <div data-tip={message} className={styles['tooltip-trigger']}>
        <svg
          className={classnames(styles.icon, className)}
          viewBox={ErrorIcon.viewBox}
          aria-label="Error icon"
          role="img"
        >
          <use xlinkHref={`#${ErrorIcon.id}`} />
        </svg>
      </div>
    </>
  )
}

ErrorTooltip.propTypes = {
  className: PropTypes.string,
  message: PropTypes.string,
}

ErrorTooltip.defaultProps = {
  className: '',
  message: '',
}

export default ErrorTooltip
