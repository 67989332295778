import React, { useCallback, useReducer, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { useSelector, useDispatch } from 'react-redux'
import { navigate, useLocation } from '@reach/router'

import CardModal from '_components/modal-card'
import Input from '_components/landing-components/input'
import Button, { ButtonTheme, ButtonSize } from '_components/landing-components/button'
import GoogleLogin from '_components/google-login-button'
import { isSignUpLoadingSelector, signUpErrorSelector } from '_modules/user/selectors'
import { signUp } from '_modules/user/actions'
import usePrevious from '_hooks/use-previous'
import { validateToken } from '_modules/company/actions'
import {
  isValidateTokenLoadingSelector,
  validateTokenErrorSelector,
  emailSelector,
} from '_modules/company/selectors'
import RocketLoader from '_components/rocket-loader'
import {
  GOOGLE_LOGIN_EMAIL_ALREADY_EXISTS_ERROR,
  ALREADY_HAS_LIGHTYEAR_ACCOUNT,
} from '_constants/authentication'
import ErrorAlertComponent from '_components/error-alert-component'

import styles from './styles.css'
import {
  reducer,
  INITIAL_STATE,
  UPDATE_STATE,
  VALIDATE_ERRORS,
  TOGGLE_SIGN_UP,
  SIGN_UP_ERROR,
} from './reducer'

const SignUpModal = ({ isOpen, onClose }) => {
  const isSigningUp = useSelector(isSignUpLoadingSelector)
  const wasSigningUp = usePrevious(isSigningUp)
  const signUpError = useSelector(signUpErrorSelector)
  const isValidatingToken = useSelector(isValidateTokenLoadingSelector)
  const wasValidatingToken = usePrevious(isValidatingToken)
  const validateTokenError = useSelector(validateTokenErrorSelector)
  const companyEmail = useSelector(emailSelector)

  const [state, localDispatch] = useReducer(reducer, INITIAL_STATE)
  const [alreadyHasEmailAccountError, setAlreadyHasEmailAccountError] = useState(false)

  const dispatch = useDispatch()
  const { pathname, search, state: locationState } = useLocation()
  const token = useMemo(
    () => {
      if (!search) {
        return ''
      }

      const result = search.match(/token=([^&]*)/)
      return result ? result[1] : ''
    },
    [search]
  )

  useEffect(
    () => {
      if (token?.length) {
        dispatch(validateToken(token))
      }
    },
    [dispatch, token]
  )

  useEffect(
    () => {
      if (!isValidatingToken && wasValidatingToken && !validateTokenError.size && companyEmail) {
        localDispatch({
          type: UPDATE_STATE,
          payload: {
            email: companyEmail,
            confirmEmail: companyEmail,
          },
        })
      }
    },
    [isValidatingToken, wasValidatingToken, validateTokenError, companyEmail]
  )

  const onInputChange = useCallback(event => {
    const { name, value } = event.target

    localDispatch({
      type: UPDATE_STATE,
      payload: {
        [name]: value,
      },
    })
  }, [])

  const onSignUp = useCallback(
    event => {
      event.preventDefault()
      localDispatch({
        type: VALIDATE_ERRORS,
      })
      if (alreadyHasEmailAccountError) {
        setAlreadyHasEmailAccountError('')
      }
    },
    [alreadyHasEmailAccountError]
  )

  useEffect(
    () => {
      if (state.canUserSignUp) {
        localDispatch({
          type: TOGGLE_SIGN_UP,
        })
        dispatch(
          signUp({
            name: state.name,
            email: state.email,
            confirmEmail: state.confirmEmail,
            password1: state.password,
          })
        )
      }
    },
    [
      dispatch,
      state.canUserLogin,
      state.canUserSignUp,
      state.confirmEmail,
      state.email,
      state.name,
      state.password,
    ]
  )

  useEffect(
    () => {
      if (!isSigningUp && wasSigningUp) {
        if (!signUpError.size) {
          if (locationState?.classId && locationState?.courseSlug) {
            navigate(`?payments&course=${locationState.courseSlug}&class=${locationState.classId}`)
          } else if (locationState?.classId && locationState?.isEnrolling) {
            navigate(locationState?.redirectTo)
          } else {
            navigate(pathname)
          }
          return
        }

        if (signUpError.get('email')?.first() === ALREADY_HAS_LIGHTYEAR_ACCOUNT) {
          navigate('?login', {
            state: { email: state.email, error: ALREADY_HAS_LIGHTYEAR_ACCOUNT },
          })
          return
        }
        const [...keys] = signUpError.keys()
        let payload = {}

        if (keys.length) {
          keys.forEach(key => {
            payload = {
              ...payload,
              [`${key}Error`]: signUpError.get(key)?.first(),
            }
          })
        }

        localDispatch({
          type: SIGN_UP_ERROR,
          payload,
        })
      }
    },
    [isSigningUp, locationState, onClose, pathname, search, signUpError, state.email, wasSigningUp]
  )

  const onGoogleLoginError = useCallback(
    error => {
      if (
        GOOGLE_LOGIN_EMAIL_ALREADY_EXISTS_ERROR.every(item =>
          error
            .get('error')
            ?.first()
            ?.includes(item)
        )
      ) {
        const errorMessage = (
          <span>
            You have previously created your account with your email{' '}
            <span className={styles['error-alert-bold']}>{error.get('email')?.first()}</span>.
            Please sign in with your email.
          </span>
        )
        setAlreadyHasEmailAccountError(errorMessage)
      }

      if (error.get('isGoogleError')) {
        localDispatch({
          type: UPDATE_STATE,
          payload: {
            error: error.get('message'),
            confirmEmail: companyEmail,
          },
        })
      }
    },
    [companyEmail]
  )

  return (
    <CardModal
      isOpen={isOpen}
      onClose={onClose}
      className={styles.modal}
      childrenClassName={styles.modal}
    >
      {isValidatingToken ? (
        <div className={styles.loader}>
          <RocketLoader />
        </div>
      ) : (
        <>
          <h1 className={styles['modal-title']}>Create your Account</h1>
          {(!!alreadyHasEmailAccountError || !!state.error) && (
            <ErrorAlertComponent
              className={styles['alert-message']}
              errorMessage={alreadyHasEmailAccountError || state.error}
              isError={!alreadyHasEmailAccountError && !!state.error}
            />
          )}
          <form onSubmit={onSignUp} className={styles.form}>
            <Input
              className={styles['name-input']}
              value={state.name}
              onChange={onInputChange}
              name="name"
              placeholder="Name"
              error={!!state.nameError}
              helperText={state.nameError}
              hasHelperText
            />
            <Input
              className={styles['email-input']}
              value={state.email}
              onChange={onInputChange}
              name="email"
              placeholder="Email"
              error={!!state.emailError}
              helperText={state.emailError}
              hasHelperText
              disabled={!!companyEmail}
            />
            <Input
              className={styles['confirm-email-input']}
              value={state.confirmEmail}
              onChange={onInputChange}
              name="confirmEmail"
              placeholder="Confirm email"
              error={!!state.confirmEmailError}
              helperText={state.confirmEmailError}
              hasHelperText
              disabled={!!companyEmail}
            />
            <Input
              className={styles['password-input']}
              type="password"
              value={state.password}
              onChange={onInputChange}
              name="password"
              placeholder="Create password"
              error={!!state.passwordError}
              helperText={
                state.passwordError || 'Please enter a password that has at least 8 characters.'
              }
              hasHelperText
            />
            <Button
              type="submit"
              className={styles['sign-up-button']}
              theme={ButtonTheme.MALIBU}
              size={ButtonSize.BIG}
              isLoading={isSigningUp}
            >
              SIGN UP
            </Button>
          </form>
          <div className={styles['or-container']}>
            <div className={styles.line} />
            <p className={styles.or}>or</p>
            <div className={styles.line} />
          </div>
          <GoogleLogin
            onLoginError={onGoogleLoginError}
            label="SIGN UP WITH GOOGLE"
            onLoginSuccess={onClose}
          />
          <p className={styles.login}>
            Already have an account?{' '}
            <Button
              className={styles['login-button']}
              theme={ButtonTheme.TRANSPARENT_BACKGROUND_MALIBU_TEXT}
              size={ButtonSize.SMALL}
              url={`${pathname === '/sign-up' ? '/' : `${pathname}?`}login`}
              state={locationState}
            >
              Log in
            </Button>
          </p>
        </>
      )}
    </CardModal>
  )
}

SignUpModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
}

export default React.memo(SignUpModal)
