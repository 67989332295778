import humps from 'humps'

import { get, post, upload, del, patch } from './requests'

export const getMyChats = (key, limit) => get(['my-chats'], { key, params: { limit } })

export const getChannelMessages = key => (channelId, page) =>
  get(['channels', channelId, 'messages'], { key, params: { page } })

export const getDirectMessages = key => (username, page) =>
  get(['users', username, 'messages'], { key, params: { page } })

export const sendDirectMessage = key => payload => {
  const formData = new FormData()

  if (payload.file) {
    formData.append('file', payload.file)
  }

  if (payload.message) {
    formData.append('message', payload.message)
  }

  return upload(['users', payload.username, 'messages'], { key }, formData)
}

export const sendChannelMessage = key => (channelId, payload) => {
  const formData = new FormData()

  if (payload.file) {
    formData.append('file', payload.file)
  }

  if (payload.message) {
    formData.append('message', payload.message)
  }

  return upload(['channels', channelId, 'messages'], { key }, formData)
}

export const readChannelMessages = key => channelId =>
  post(['channels', channelId, 'messages', 'read'], { key })

export const readUserMessages = key => username =>
  post(['users', username, 'messages', 'read'], { key })

export const getUsersFromChannel = key => (channelId, query) =>
  get(['channels', channelId, 'users'], { key, params: { ...query } })

export const deleteMessageFromChannel = key => (channelId, messageId) =>
  del(['channels', channelId, 'messages', messageId], { key })

export const disableChannel = key => channelId => post(['channels', channelId, 'disable'], { key })

export const enableChannel = key => channelId => post(['channels', channelId, 'enable'], { key })

export const removeUserFromChannel = key => (channelId, userId) =>
  del(['channels', channelId, 'users', userId], { key })

export const updateChannel = key => ({ channelId, ...payload }) =>
  patch(['channels', channelId], { key }, payload)

export const makeAdmin = key => ({ channelId, userId }) =>
  post(['channels', channelId, 'users', userId, 'make-admin'], { key })

export const revokeAdmin = key => ({ channelId, userId }) =>
  post(['channels', channelId, 'users', userId, 'revoke-admin'], { key })

export const getChannels = key => ({ search, page }) =>
  get(['channels'], { key, params: { search, page } })

export const createChannel = key => payload => post(['channels'], { key }, payload)

export const endZoomMeeting = key => meetingId => post(['meetings', meetingId, 'end'], { key })

export const joinToChannel = key => channelId => post(['channels', channelId, 'join'], { key })

export const inviteToChannel = key => ({ channelId, user }) =>
  post(['channels', channelId, 'users', 'invite'], { key }, { user })

export const getAvailableToInvite = key => ({ channelId, page, search }) =>
  get(['channels', channelId, 'available-to-invite'], { key, params: { page, search } })

export const startVideoChannelCall = key => ({ channelId, ...payload }) =>
  post(['channels', channelId, 'messages', 'start-video'], { key }, humps.decamelizeKeys(payload))

export const startVideoDirectMessageCall = key => ({ username, ...payload }) =>
  post(['users', username, 'messages', 'start-video'], { key }, humps.decamelizeKeys(payload))
