import React, { useCallback } from 'react'
import ReactPaginate from 'react-paginate'
import PropTypes from 'prop-types'

import styles from './styles.css'

const Paginator = ({ onPageChange, totalItems, itemsPerPage, className }) => {
  const handlePageChange = useCallback(
    event => {
      const { selected } = event
      onPageChange(selected + 1)
    },
    [onPageChange]
  )

  return (
    <div className={className}>
      <ReactPaginate
        previousLabel="<"
        nextLabel=">"
        breakLabel="..."
        pageCount={totalItems / itemsPerPage}
        containerClassName={styles['paginator-container']}
        activeClassName={styles.active}
        pageClassName={styles['paginator-button']}
        nextClassName={styles['paginator-button']}
        pageLinkClassName={styles['paginator-link']}
        previousClassName={styles['paginator-button']}
        marginPagesDisplayed={1}
        pageRangeDisplayed={4}
        onPageChange={handlePageChange}
      />
    </div>
  )
}

Paginator.propTypes = {
  totalItems: PropTypes.number,
  itemsPerPage: PropTypes.number,
  onPageChange: PropTypes.func.isRequired,
  className: PropTypes.string,
}

Paginator.defaultProps = {
  className: '',
  totalItems: null,
  itemsPerPage: null,
}

export default React.memo(Paginator)
