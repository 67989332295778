import React, { useState, useCallback, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import classnames from 'classnames'
import { connect } from 'react-redux'
import { toast } from 'react-toastify'
import { navigate } from '@reach/router'

import CourseCard from '_components/course-card'
import PostCard from '_components/post-card'
import Channel from '_components/channel'
import Filter from '_components/filter'
import Chips from '_components/chips'
import Footer from '_components/footer'
import SearchInput, { SearchType } from '_components/search-input'
import { queryParam } from '_utils/helpers'
import {
  search,
  clearQuery,
  SEARCH,
  shazamSearchPost,
  unShazamSearchPost,
} from '_modules/search/actions'
import {
  userShape,
  searchChannelShape,
  searchMessageShape,
  postShape,
  courseShape,
} from '_utils/proptypes'
import Card from '_components/card'
import { usePrevious } from '_utils/hooks'
import Toastr, { ToastrTheme } from '_components/toastr'
import SearchIcon from '_assets/icons/image-search.svg'
import POST_ACTIONS from '_modules/post/actions'
import { hidePosts, blockUser } from '_modules/user/actions'
import { isSearchLoadingSelector } from '_modules/search/selectors'
import RocketLoader from '_components/rocket-loader'

import SearchFilter from './search-filter'
import styles from './styles.css'
import MessageChannelCard from './message-channel-card'
import UserCard from './user-card'

const FILTERS = {
  ANYONE: 'anyone',
  PEOPLE_YOU_KNOW: 'people-you-know',
}

const filtersList = [
  {
    label: 'Anyone',
    ordering: FILTERS.ANYONE,
  },
  {
    label: 'People you know',
    ordering: FILTERS.PEOPLE_YOU_KNOW,
  },
]

export const CATEGORIES = {
  ALL: 'All',
  PROFILES: 'Profiles',
  CLASSES: 'Classes',
  POSTS: 'Posts',
  MESSAGES: 'Messages',
  CHANNELS: 'Channels',
}

const categoriesList = [
  {
    title: CATEGORIES.ALL,
  },
  {
    title: CATEGORIES.PROFILES,
  },
  {
    title: CATEGORIES.POSTS,
  },
  {
    title: CATEGORIES.MESSAGES,
  },
  {
    title: CATEGORIES.CHANNELS,
  },
  {
    title: CATEGORIES.CLASSES,
  },
]

const { flagPost, deletePost, editPost, createPost } = POST_ACTIONS

const mapStateToProps = state => ({
  searchResults: state.search.toJS(),
  isSearching: !!state.loading.get(SEARCH.ACTION),
  searchError: state.error.get(SEARCH.ACTION),
  user: state.user,
  isSearchLoading: isSearchLoadingSelector(state),
})

const mapDispatchToProps = {
  query: search,
  removeQuery: clearQuery,
  onFlagPost: flagPost,
  shazamPost: shazamSearchPost,
  unshazamPost: unShazamSearchPost,
  postDelete: deletePost,
  hidePostsUser: hidePosts,
  userBlock: blockUser,
  postEdit: editPost,
  newPost: createPost,
}

const Search = ({
  location,
  className,
  query,
  searchResults,
  removeQuery,
  isSearching,
  searchError,
  user,
  onFlagPost,
  shazamPost,
  unshazamPost,
  postDelete,
  hidePostsUser,
  userBlock,
  postEdit,
  newPost,
  isSearchLoading,
}) => {
  const [activeCategory, setActiveCategory] = useState(
    location.state && location.state.activeCategory
      ? location.state.activeCategory
      : categoriesList[0].title
  )
  const [activeFilter, setActiveFilter] = useState(filtersList[0].ordering)
  const [searchInputQuery, setQuery] = useState(queryParam(location.search, 'query') || '')

  const wasSearching = usePrevious(isSearching)

  const onCategoryClick = useCallback(id => {
    setActiveCategory(id)
  }, [])

  const onFilterClick = useCallback(id => {
    setActiveFilter(id)
  }, [])

  const onChangeSearch = useCallback(event => {
    const { value } = event.target
    setQuery(value)
  }, [])

  const onSubmitSearch = useCallback(
    event => {
      event.preventDefault()
      navigate(`/search?query=${searchInputQuery}`)
    },
    [searchInputQuery]
  )

  const renderEmptyResults = useCallback(
    () => (
      <Card>
        <h4>No results found.</h4>
        <p className={styles.description}>Try adjusting your search.</p>
      </Card>
    ),
    []
  )

  const renderUsersResults = useCallback(
    (ignoreEmptyResult = false) => {
      if (!ignoreEmptyResult && searchResults.users.length === 0) {
        return renderEmptyResults()
      }
      return searchResults.users.map(userData => (
        <UserCard target={`/user/${userData.username}`} key={userData.id} user={userData} />
      ))
    },
    [renderEmptyResults, searchResults.users]
  )

  const onShazamClick = useCallback(
    ({ postId, fatherPostId }) => shazamPost({ postId, fatherPostId }),
    [shazamPost]
  )

  const onUnShazamClick = useCallback(
    ({ postId, fatherPostId }) => unshazamPost({ postId, fatherPostId }),
    [unshazamPost]
  )

  const onDeletePost = useCallback(
    postId => postDelete({ postId, classId: null, assignmentId: null }),
    [postDelete]
  )

  const onHidePosts = useCallback((username, userId) => hidePostsUser(username, userId), [
    hidePostsUser,
  ])

  const onBlockUserClick = useCallback(
    payload => {
      userBlock({ ...payload, isTimeline: true })
    },
    [userBlock]
  )

  const onEditPost = useCallback(
    (payload, postId) => postEdit({ ...payload, postId, classId: null, assignmentId: null }),
    [postEdit]
  )

  const onCommentPost = useCallback(payload => newPost(payload), [newPost])

  const renderPostsResults = useCallback(
    (ignoreEmptyResult = false) => {
      if (!ignoreEmptyResult && searchResults.posts.length === 0) {
        return renderEmptyResults()
      }
      return searchResults.posts.map(post => (
        <PostCard
          post={post}
          key={post.id}
          id={post.id}
          user={user}
          isOwnPost={user.id === post.author.id}
          onEditPost={onEditPost}
          shazam={onShazamClick}
          unshazam={onUnShazamClick}
          onDeletePost={onDeletePost}
          onHidePosts={onHidePosts}
          onFlagPost={onFlagPost}
          onBlockUser={onBlockUserClick}
          onSubmit={onCommentPost}
        />
      ))
    },
    [
      searchResults.posts,
      renderEmptyResults,
      user,
      onEditPost,
      onShazamClick,
      onUnShazamClick,
      onDeletePost,
      onHidePosts,
      onFlagPost,
      onBlockUserClick,
      onCommentPost,
    ]
  )

  const renderMessagesResults = useCallback(
    (ignoreEmptyResult = false) => {
      if (!ignoreEmptyResult && searchResults.messages.length === 0) {
        return renderEmptyResults()
      }
      return searchResults.messages.map(message => (
        <MessageChannelCard target="/messages" message={message} key={message.id} />
      ))
    },
    [renderEmptyResults, searchResults.messages]
  )

  const renderChannelsResults = useCallback(
    (ignoreEmptyResult = false) => {
      if (!ignoreEmptyResult && searchResults.channels.length === 0) {
        return renderEmptyResults()
      }
      return searchResults.channels.map(channel => (
        <Channel target="/messages" channel={channel} key={channel.id} />
      ))
    },
    [renderEmptyResults, searchResults.channels]
  )

  const renderCoursesResults = useCallback(
    (ignoreEmptyResult = false) => {
      if (!ignoreEmptyResult && searchResults.classes.length === 0) {
        return renderEmptyResults()
      }

      return searchResults.classes.map(currentClass => {
        const targetLink = currentClass.enrolled
          ? `/class/${currentClass.enrolled}`
          : `/classes/${currentClass.id}`
        return (
          <CourseCard
            target={targetLink}
            course={currentClass}
            isFavoritable
            key={currentClass.id}
          />
        )
      })
    },
    [renderEmptyResults, searchResults]
  )

  const renderResults = useCallback(
    () => {
      switch (activeCategory) {
        case CATEGORIES.ALL: {
          return (
            <>
              {renderUsersResults(true)}
              {renderPostsResults(true)}
              {renderMessagesResults(true)}
              {renderChannelsResults(true)}
              {renderCoursesResults(true)}
            </>
          )
        }
        case CATEGORIES.PROFILES: {
          return renderUsersResults()
        }
        case CATEGORIES.POSTS: {
          return renderPostsResults()
        }
        case CATEGORIES.MESSAGES: {
          return renderMessagesResults()
        }
        case CATEGORIES.CHANNELS: {
          return renderChannelsResults()
        }
        case CATEGORIES.CLASSES: {
          return renderCoursesResults()
        }
        default:
          return null
      }
    },
    [
      activeCategory,
      renderChannelsResults,
      renderCoursesResults,
      renderMessagesResults,
      renderPostsResults,
      renderUsersResults,
    ]
  )

  const searchText = queryParam(location, 'query')

  const isEmptyResult = useMemo(
    () => Object.keys(searchResults).every(key => searchResults[key].length === 0),
    [searchResults]
  )

  useEffect(
    () => {
      if (searchText) {
        query({ term: searchText, peopleYouKnow: activeFilter === FILTERS.PEOPLE_YOU_KNOW })
      }

      return () => {
        removeQuery()
      }
    },
    [activeFilter, query, removeQuery, searchText]
  )

  useEffect(
    () => {
      if (wasSearching && !isSearching && searchError.size !== 0) {
        toast(
          <Toastr
            theme={ToastrTheme.ERROR}
            content="We couldn't find your results, please, try again."
          />
        )
      }
    },
    [isSearching, searchError.size, wasSearching]
  )

  const renderContent = useMemo(
    () =>
      searchText ? (
        <>
          <h1 className={styles.title}>Search results for: “{searchText}”</h1>
          <SearchFilter
            className={styles['categories-filter']}
            categories={categoriesList}
            filters={filtersList}
            activeCategory={activeCategory}
            activeFilter={activeFilter}
            onCategoryClick={onCategoryClick}
            onFilterClick={onFilterClick}
          />
          <div className={styles['search-content']}>
            <div className={styles['categories-list']}>
              {categoriesList.map(category => (
                <Chips
                  className={styles.button}
                  key={category.title}
                  onClick={onCategoryClick}
                  label={category.title}
                  active={activeCategory === category.title}
                  id={category.title}
                />
              ))}
            </div>
            <Filter
              className={styles.filters}
              filters={filtersList}
              activeFilter={activeFilter}
              onClick={onFilterClick}
            />
            <div className={styles['search-results']}>
              {isEmptyResult ? renderEmptyResults() : renderResults()}
            </div>
          </div>
        </>
      ) : (
        <div className={styles.initial}>
          <svg aria-hidden="true" className={styles.icon} viewBox={SearchIcon.viewBox}>
            <use xlinkHref={`#${SearchIcon.id}`} />
          </svg>
          <p>Search for profiles, courses, posts, messages, channels, etc.</p>
        </div>
      ),
    [
      activeCategory,
      activeFilter,
      isEmptyResult,
      onCategoryClick,
      onFilterClick,
      renderEmptyResults,
      renderResults,
      searchText,
    ]
  )
  return (
    <div className={classnames(styles.container, className)}>
      <form className={styles['search-form']} onSubmit={onSubmitSearch}>
        <SearchInput
          type={SearchType.NORMAL}
          placeholder="Search"
          value={searchInputQuery}
          onChange={onChangeSearch}
          shouldShowSearchButton={!!searchInputQuery}
        />
      </form>
      {isSearchLoading ? (
        <div className={styles.loading}>
          <RocketLoader />
        </div>
      ) : (
        renderContent
      )}
      <Footer className={styles.footer} />
    </div>
  )
}

Search.propTypes = {
  location: PropTypes.shape({
    pathname: PropTypes.string,
    search: PropTypes.string,
    state: PropTypes.shape({
      activeCategory: PropTypes.bool,
    }),
  }),
  className: PropTypes.string,
  query: PropTypes.func.isRequired,
  searchResults: PropTypes.shape({
    channels: PropTypes.arrayOf(searchChannelShape),
    classes: PropTypes.arrayOf(courseShape),
    users: PropTypes.arrayOf(userShape),
    posts: PropTypes.arrayOf(postShape),
    messages: PropTypes.arrayOf(searchMessageShape),
  }).isRequired,
  removeQuery: PropTypes.func.isRequired,
  isSearching: PropTypes.bool,
  searchError: ImmutablePropTypes.map,
  user: userShape.isRequired,
  onFlagPost: PropTypes.func.isRequired,
  shazamPost: PropTypes.func.isRequired,
  unshazamPost: PropTypes.func.isRequired,
  postDelete: PropTypes.func.isRequired,
  hidePostsUser: PropTypes.func.isRequired,
  userBlock: PropTypes.func.isRequired,
  postEdit: PropTypes.func.isRequired,
  newPost: PropTypes.func.isRequired,
  isSearchLoading: PropTypes.bool.isRequired,
}

Search.defaultProps = {
  className: '',
  isSearching: false,
  searchError: Map(),
  location: { pathname: '', search: '' },
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Search)
