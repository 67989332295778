import React, { useCallback, useState, useEffect, useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Map } from 'immutable'
import { connect } from 'react-redux'
import { navigate, useLocation } from '@reach/router'
import noScroll from 'no-scroll'

import PeopleEnrolledSection from '_components/people-enrolled-section'
import {
  favorite,
  unfavorite,
  GET_COMPANY_CLASS_BY_ID,
  getCompanyClassById,
} from '_modules/course/actions'
import { companyClassSelector } from '_modules/course/selectors'
import CourseClasses from '_components/course-classes'
import ArrowLeftIcon from '_assets/icons/arrow-left.svg'
import Card from '_components/card'
import Footer from '_components/footer'
import CourseSection from '_components/course-section'
import { classShape } from '_utils/proptypes'
import { enroll, ENROLL } from '_modules/class/actions'
import { usePrevious } from '_utils/hooks'
import { FOLLOW_USER, follow, UNFOLLOW_USER, unfollow } from '_modules/user/actions'
import { SEND_DIRECT_MESSAGE, sendDirectMessage } from '_modules/chat/actions'
import { onMouseDown } from '_utils/aria'
import Modal from '_components/modal'
import ModalTextarea from '_components/modal-textarea'
import { linearGradientBg, NOT_FOUND } from '_utils/constants'
import { Class } from '_models/'

import styles from './styles.css'

const mapStateToProps = (state, { classId }) => ({
  course: companyClassSelector(state, classId),
  userId: state.user.id,
  isEnrolling: !!state.loading.get(ENROLL.ACTION),
  enrollError: state.error.get(ENROLL.ACTION),
  isFollowing: !!state.loading.get(FOLLOW_USER.ACTION),
  followError: state.error.get(FOLLOW_USER.ACTION),
  isUnfollowing: !!state.loading.get(UNFOLLOW_USER.ACTION),
  unfollowError: state.error.get(UNFOLLOW_USER.ACTION),
  isSendingMessage: !!state.loading.get(SEND_DIRECT_MESSAGE.ACTION),
  sendMessageError: state.error.get(SEND_DIRECT_MESSAGE.ACTION),
  belongsToACompany: state.user.belongsToACompany,
  isLoadingCourse: !!state.loading.get(GET_COMPANY_CLASS_BY_ID.ACTION),
  loadingCourseError: state.error.get(GET_COMPANY_CLASS_BY_ID.ACTION),
})

const mapDispatchToProps = {
  getCourseDetails: getCompanyClassById,
  favoriteCourse: favorite,
  unFavoriteCourse: unfavorite,
  classEnroll: enroll,
  followUser: follow,
  unfollowUser: unfollow,
  sendMessage: sendDirectMessage,
}

const onBackClick = event => {
  event.preventDefault()
  navigate('/courses')
}

const CourseDetails = ({
  course,
  getCourseDetails,
  classId,
  classEnroll,
  isEnrolling,
  enrollError,
  userId,
  followUser,
  unfollowUser,
  isFollowing,
  followError,
  isUnfollowing,
  unfollowError,
  isSendingMessage,
  sendMessage,
  sendMessageError,
  isLoadingCourse,
  loadingCourseError,
}) => {
  const location = useLocation()

  const redirectClass = useMemo(() => location.state?.classId, [location.state])

  const [isModalOpen, setModalStatus] = useState(false)
  const [leaderLed, setLeaderLed] = useState({
    id: null,
    username: null,
  })

  const wasEnrolling = usePrevious(isEnrolling)
  const wasFollowing = usePrevious(isFollowing)
  const wasUnfollowing = usePrevious(isUnfollowing)
  const wasSendingMessage = usePrevious(isSendingMessage)
  const wasLoadingCourse = usePrevious(isLoadingCourse)

  const onClassEnroll = useCallback((payload = null) => classEnroll(payload), [classEnroll])

  const peopleEnrolled = useMemo(
    () => course?.peopleEnrolled.filter(user => user.get('id') !== userId),
    [course, userId]
  )

  const onFollowUser = useCallback(username => followUser(username), [followUser])

  const onUnfollowUser = useCallback(username => unfollowUser(username), [unfollowUser])

  const onMessageClick = useCallback(payload => {
    setModalStatus(true)
    setLeaderLed({
      id: payload.receiverId,
      username: payload.username,
      photo: payload.photo,
      name: payload.name,
    })
  }, [])

  const onModalSubmit = useCallback(
    message => {
      sendMessage({ ...leaderLed, message })
    },
    [leaderLed, sendMessage]
  )

  const closeNewMessage = useCallback(() => setModalStatus(false), [])

  const courseBackgroundPicture = useMemo(
    () => ({
      backgroundImage: `${linearGradientBg}, url('${course?.picture}')`,
    }),
    [course]
  )

  useEffect(
    () => {
      getCourseDetails(classId)
    },
    [classId, getCourseDetails]
  )

  useEffect(
    () => {
      if (wasEnrolling && !isEnrolling && enrollError.size === 0) {
        noScroll.off()
        navigate([`/courses`])
      }
    },
    [enrollError.size, isEnrolling, wasEnrolling]
  )

  useEffect(
    () => {
      if (
        (wasFollowing && !isFollowing && followError.size === 0) ||
        (wasUnfollowing && !isUnfollowing && unfollowError.size === 0)
      ) {
        getCourseDetails(classId)
      }
    },
    [
      classId,
      followError.size,
      getCourseDetails,
      isFollowing,
      isUnfollowing,
      unfollowError.size,
      wasFollowing,
      wasUnfollowing,
    ]
  )

  useEffect(
    () => {
      if (wasSendingMessage && !isSendingMessage && sendMessageError.size === 0) {
        navigate(`/messages/?directMessage=${leaderLed.username}`)
        setLeaderLed({
          id: null,
          username: null,
        })
      }
    },
    [isSendingMessage, leaderLed.username, sendMessageError.size, wasSendingMessage]
  )

  useEffect(() => {
    if (wasLoadingCourse && !isLoadingCourse && loadingCourseError?.get('detail') === NOT_FOUND) {
      navigate('/404')
    }
  })

  if (typeof wasLoadingCourse === 'undefined' || !course || Object.keys(course).length === 0) {
    return null
  }

  return (
    <>
      <section className={styles.container}>
        <div className={styles.header} style={course.picture && courseBackgroundPicture}>
          <div className={styles['title-wrapper']}>
            <button
              type="button"
              onClick={onBackClick}
              className={classnames(styles.button, styles['back-button'])}
              onMouseDown={onMouseDown}
            >
              <svg
                className={styles.icon}
                aria-label="Back button"
                viewBox={ArrowLeftIcon.viewBox}
                role="img"
              >
                <use xlinkHref={`#${ArrowLeftIcon.id}`} />
              </svg>
            </button>
            <h1 className={styles.title}>{course.name}</h1>
          </div>
        </div>
        <div className={styles.content}>
          <Card className={styles.about}>
            <h3 className={styles.heading}>About this course</h3>
            <p className={styles.description}>{course.description}</p>
          </Card>
          <CourseClasses
            className={styles['course-classes']}
            course={course}
            courseName={course.name}
            isCompany
            onClassEnroll={onClassEnroll}
            onFollowAction={onFollowUser}
            onUnfollowAction={onUnfollowUser}
            onMessageClick={onMessageClick}
            redirectClass={redirectClass}
          />
          <PeopleEnrolledSection
            className={styles['enrolled-people']}
            usersEnrolled={peopleEnrolled?.toJS()}
            onFollow={onFollowUser}
            onUnfollow={onUnfollowUser}
          />
          {course.sections.map(section => (
            <CourseSection
              key={section.get('id')}
              className={styles['course-assignments']}
              title={section.get('name')}
              assignments={section.get('assignments').toJS()}
              readOnly
            />
          ))}
        </div>
        <Footer />
      </section>
      {isModalOpen && (
        <Modal isOpen onClose={closeNewMessage}>
          <ModalTextarea
            title="SEND A MESSAGE"
            buttonLabel="SEND"
            onSubmit={onModalSubmit}
            onClose={closeNewMessage}
            value=""
            isLoading={isSendingMessage}
          />
        </Modal>
      )}
    </>
  )
}

CourseDetails.propTypes = {
  classId: PropTypes.string.isRequired,
  getCourseDetails: PropTypes.func.isRequired,
  course: classShape,
  classEnroll: PropTypes.func.isRequired,
  isEnrolling: PropTypes.bool.isRequired,
  enrollError: PropTypes.instanceOf(Map),
  userId: PropTypes.number.isRequired,
  followUser: PropTypes.func.isRequired,
  unfollowUser: PropTypes.func.isRequired,
  isFollowing: PropTypes.bool.isRequired,
  followError: PropTypes.instanceOf(Map),
  isUnfollowing: PropTypes.bool.isRequired,
  unfollowError: PropTypes.instanceOf(Map),
  isSendingMessage: PropTypes.bool.isRequired,
  sendMessage: PropTypes.func.isRequired,
  sendMessageError: PropTypes.instanceOf(Map),
  isLoadingCourse: PropTypes.bool.isRequired,
  loadingCourseError: PropTypes.instanceOf(Map),
}

CourseDetails.defaultProps = {
  course: new Class(),
  enrollError: Map(),
  followError: Map(),
  unfollowError: Map(),
  sendMessageError: Map(),
  loadingCourseError: Map(),
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CourseDetails)
