import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import SearchIcon from '_assets/icons/search.svg'
import { onMouseDown } from '_utils/aria'

import styles from './styles.css'

export const SearchType = {
  EMBEDDED: 'embedded',
  NORMAL: 'normal',
  MODAL: 'modal',
}

const SearchInput = ({
  className,
  type,
  placeholder,
  onChange,
  value,
  shouldShowSearchButton,
  searchButtonAction,
  disabled,
}) => (
  <div className={classnames(styles.container, styles[type], className)}>
    <input
      type="text"
      placeholder={placeholder}
      className={classnames(styles.input, {
        [styles['search-button-shown']]: shouldShowSearchButton,
      })}
      onChange={onChange}
      value={value}
      disabled={disabled}
    />
    <svg className={styles.icon} aria-hidden="true" viewBox={SearchIcon.viewBox}>
      <use xlinkHref={`#${SearchIcon.id}`} />
    </svg>
    {shouldShowSearchButton && (
      <button
        onClick={searchButtonAction}
        onMouseDown={onMouseDown}
        className={styles['search-button']}
      >
        SEARCH
      </button>
    )}
  </div>
)

SearchInput.propTypes = {
  className: PropTypes.string,
  type: PropTypes.oneOf(Object.keys(SearchType).map(type => SearchType[type])),
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.string,
  shouldShowSearchButton: PropTypes.bool,
  searchButtonAction: PropTypes.func,
  disabled: PropTypes.bool,
}

SearchInput.defaultProps = {
  className: '',
  type: SearchType.NORMAL,
  placeholder: 'Search...',
  onChange: () => {},
  value: '',
  shouldShowSearchButton: false,
  searchButtonAction: () => {},
  disabled: false,
}

export default SearchInput
