import React from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'

import ChipCheckbox from '_components/chip-checkbox'

import styles from './styles.css'

const ChipCheckboxGroup = ({
  id,
  optionsList,
  legend,
  legendClassName,
  className,
  onChange,
  groupName,
  selectedValues,
}) => (
    <fieldset className={classnames(styles.fieldset, className)} onChange={onChange}>
      <legend className={classnames(styles.legend, legendClassName)}>{legend}</legend>
      <div className={styles['checkbox-wrapper']}>
        {optionsList.map((option, index) => (
          <ChipCheckbox
            key={`checkbox-${id}-${index + 1}`}
            id={`checkbox-${id}-${index}`}
            name={option.name}
            data-group={groupName}
            data-id={option.id}
            value={option.name}
            label={option.name}
            checked={selectedValues.some(item => item === option.id)}
          />
        ))}
      </div>
    </fieldset>
  )

ChipCheckboxGroup.propTypes = {
  id: PropTypes.string.isRequired,
  optionsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  legend: PropTypes.string.isRequired,
  legendClassName: PropTypes.string,
  className: PropTypes.string,
  onChange: PropTypes.func,
  groupName: PropTypes.string.isRequired,
  selectedValues: PropTypes.arrayOf(PropTypes.string).isRequired,
}

ChipCheckboxGroup.defaultProps = {
  legendClassName: '',
  className: '',
  onChange: () => {},
}

export default ChipCheckboxGroup
