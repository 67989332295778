import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'

import SliderArrow, { ARROW_DIRECTION } from '_components/slider-arrow'

import LeaderCard from './leader-card'
import styles from './styles.css'

const LeadersCarousel = ({ leadersList, label }) => {
  const settings = useMemo(
    () => ({
      dots: false,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      prevArrow: <SliderArrow arrowDirection={ARROW_DIRECTION.LEFT} />,
      nextArrow: <SliderArrow arrowDirection={ARROW_DIRECTION.RIGHT} />,
      variableWidth: true,
      swipeToSlide: true,
    }),
    []
  )

  return (
    <div className={styles['carousel-wrapper']}>
      <p className={styles.label}>{label}</p>
      <Slider {...settings}>
        {leadersList.map(leader => <LeaderCard leader={leader} key={leader.name} />)}
      </Slider>
    </div>
  )
}

LeadersCarousel.propTypes = {
  leadersList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  label: PropTypes.string,
}

LeadersCarousel.defaultProps = {
  label: '',
}

export default LeadersCarousel
