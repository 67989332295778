import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import { useGoogleLogin } from '@react-oauth/google'
import { useDispatch } from 'react-redux'

import { FACEBOOK_APP_ID } from '_config/environment'
import Button, { ButtonTheme } from '_components/button'
import { facebookLogin, googleLogin } from '_modules/user/actions'

import styles from './styles.css'

const SocialMedia = ({ loading, googleLoading, isCompany, facebookLoading, signUp }) => {
  const dispatch = useDispatch()

  const facebookCallback = useCallback(
    payload => {
      dispatch(facebookLogin({ accessToken: payload.accessToken }))
    },
    [dispatch]
  )

  const googleOnFail = useCallback(() => {}, [])
  const googleOnSuccess = useCallback(
    tokenResponse => {
      dispatch(googleLogin({ accessToken: tokenResponse.access_token }))
    },
    [dispatch]
  )

  const login = useGoogleLogin({
    onSuccess: googleOnSuccess,
    onError: googleOnFail,
  })

  const renderFacebookButton = useCallback(
    renderProps => (
      <Button
        disabled={loading || googleLoading || isCompany}
        loading={facebookLoading}
        onClick={renderProps.onClick}
        className={styles['facebook-button']}
        theme={ButtonTheme.PRIMARY}
        label={signUp ? 'SIGN UP WITH FACEBOOK' : 'LOG IN WITH FACEBOOK'}
      />
    ),
    [facebookLoading, googleLoading, isCompany, loading, signUp]
  )

  const renderGoogleButton = useCallback(
    () => (
      <Button
        disabled={loading || facebookLoading || isCompany}
        loading={googleLoading}
        className={styles['google-button']}
        onClick={login}
        theme={ButtonTheme.PRIMARY}
        label={signUp ? 'SIGN UP WITH GOOGLE' : 'LOG IN WITH GOOGLE'}
      />
    ),
    [facebookLoading, googleLoading, isCompany, loading, signUp, login]
  )

  return (
    <>
      <FacebookLogin
        appId={FACEBOOK_APP_ID}
        render={renderFacebookButton}
        callback={facebookCallback}
        fields="name,email,picture"
        isMobile={false} // Workaround: https://github.com/keppelen/react-facebook-login/issues/257
      />
      {renderGoogleButton()}
      <div className={styles['or-divider']}>
        <div className={styles.divider} />
        <p>OR</p>
        <div className={styles.divider} />
      </div>
    </>
  )
}

SocialMedia.propTypes = {
  loading: PropTypes.bool.isRequired,
  facebookLoading: PropTypes.bool.isRequired,
  googleLoading: PropTypes.bool.isRequired,
  isCompany: PropTypes.bool,
  signUp: PropTypes.bool,
}

SocialMedia.defaultProps = {
  isCompany: false,
  signUp: false,
}

export default SocialMedia
