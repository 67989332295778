import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import ClassIconsTypeChip from '_components/class-icons-type-chip'
import ClassPeriodCard from '_components/class-period-card'
import Button, { ButtonTheme } from '_components/landing-components/button'
import { coachesPropTypes } from '_utils/proptypes'

import styles from './styles.css'

const ClassElement = ({ currentClass, className }) => {
  const onClassDetailsClick = useCallback(
    () => {
      navigate(`/classes/${currentClass.id}`)
    },
    [currentClass.id]
  )

  return (
    <li className={classnames(styles['class-element-container'], className)}>
      <div className={styles['coaches-container']}>
        <ClassIconsTypeChip currentClass={currentClass} />
      </div>
      <ClassPeriodCard
        className={styles['class-period']}
        startDate={currentClass.startDate}
        endDate={currentClass.endDate}
      />
      <Button onClick={onClassDetailsClick} className={styles.button} theme={ButtonTheme.GHOST}>
        View details
      </Button>
    </li>
  )
}

ClassElement.propTypes = {
  currentClass: PropTypes.shape({
    id: PropTypes.number,
    coach: PropTypes.arrayOf(coachesPropTypes),
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    enrolled: PropTypes.bool,
  }),
  className: PropTypes.string,
}

ClassElement.defaultProps = {
  currentClass: null,
  className: '',
}

export default React.memo(ClassElement)
