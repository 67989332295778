export const TYPES = {
  TESTIMONIAL: 'testimonials_section',
  COURSES: 'courses_section', // Not Being used - Old courses view
  COMPANIES_CAROUSEL: 'companies_carousel_section',
  COMPANIES: 'companies_section',
  HERO_SECTION: 'hero_section',
  NEW_TO_LIGHTYEAR: 'new_to_lightyear',
  PERSONAL_DEVELOPMENT_COURSES: 'personal_development_courses',
  QUOTE_SECTION: 'quote_section',
  MULTIPLE_QUOTES_SECTION: 'multiple_quotes_section', // Not being used - Lightyear Coach Training view
  ABOUT_OUR_FOUNDER: 'about_our_founder',
  VIDEO_SECTION: 'video_section',
  CLARITY_SECTION: 'clarity_section',
  QUESTIONS_SECTION: 'questions_section',
  MINI_COURSE_SECTION: 'mini_course_section',
  SUBSCRIPTION_TYPE_SECTION: 'subscription_type_section', // Not being used - Legado Personal / Practice Sessions / Get There now
  GET_NOTIFIED_SECTION: 'get_notified', // Not being used - Lightyear Coach Training view
  LIGHTYEARS_PICK: 'lightyears_pick', // Not being used - Old courses view
  OUR_SHAREBACK: 'our_shareback',
  ABOUT_SUSANNE: 'about_susanne',
  THE_LIGHTYEAR_TEAM: 'the_lightyear_team',
  EXPLORE_COURSES: 'explore-courses-wrapper',
  CLASSES_SLICE: 'classes_slice',
  COURSE_TYPES: 'course_types',
  MEMBERSHIPS: 'memberships',
  MEMBERSHIPS_SLICE: 'memberships_slice',
}

const SECTION_TITLE_SLICE = 'section_title_slice'

const SEO_TYPES = {
  GENERAL_SOCIAL_CARD: 'general_social_card',
  GENERAL_CARD: 'general_card',
  TWITTER_CARD: 'twitter_card',
}

export const TEXT_TYPE = {
  STRONG: 'strong',
  LIST: 'list',
  LIST_ITEM: 'list-item',
  BOLD_LIST_ITEM: 'bold-list-item',
  HYPERLINK: 'hyperlink',
}

const quotesModel = item => ({
  isUserHighlighted: item.isQuoteAuthorHighlighted,
  userName: item.userName?.[0]?.text || '',
  userJob: item.userJob?.[0]?.text || '',
  userPicture: {
    avatar: item.userPictureMiniature?.url || '',
    thumbnail: item.userPicture?.url || '',
    thumbnailTablet: item.userPictureTablet?.url || '',
    alt:
      item.userPicture?.alt ||
      item.userPictureTablet?.alt ||
      item.userPictureMiniature?.alt ||
      'User Picture',
  },
  quote: item.userQuote?.length > 0 && item.userQuote.map(quoteSlice => quoteSlice.text),
})

const testimonialsModel = item => ({
  userName: item.userName?.[0]?.text || '',
  userJob: item.userJob?.[0]?.text || '',
  testimonial: item.testimonial?.[0]?.text,
  userAvatar: {
    url: item.userAvatar.url,
    alt: item.userAvatar.alt,
  },
})

export const sectionTitlesIndexes = data => {
  const titleIndexes = []
  data.map((slice, index) => {
    if (slice.sliceType === SECTION_TITLE_SLICE) {
      return titleIndexes.push(index)
    }
    return null
  })
  return titleIndexes
}

export const mergeSectionTitles = data => {
  const indexes = sectionTitlesIndexes(data)

  const newArray = data

  indexes.map(index => {
    newArray[index + 1] = {
      ...newArray[index + 1],
      primary: {
        ...newArray[index + 1].primary,
        sectionTitle: data[index].items,
      },
    }
    return null
  })

  return newArray.filter(slice => slice.sliceType !== SECTION_TITLE_SLICE)
}

const getTextSlices = text => {
  if (text.spans?.length > 0) {
    const newContent = text.spans.map(({ start, end, type, data }) => {
      const array = []

      const textType =
        text.type === TEXT_TYPE.LIST_ITEM && type === TEXT_TYPE.STRONG
          ? TEXT_TYPE.BOLD_LIST_ITEM
          : type

      // If span is whole text
      if (start === 0 && end === text.text.length) {
        return [
          {
            text: text.text,
            type: textType,
          },
        ]
      }

      // If span doesn't start at beginning
      if (start !== 0) {
        array.push({ text: text.text.slice(0, start) })
      }

      // If span has a url
      const textHyperlink = data?.url

      array.push({
        text: text.text.slice(start, end),
        type: textType,
        ...(textHyperlink && {
          url: textHyperlink,
        }),
      })

      // if span doesn't go till the end
      if (end !== text.text.length) {
        array.push({
          text: text.text.slice(end),
        })
      }

      return array
    })

    return newContent
  }

  return [[{ text: text.text, type: text.type }]]
}

const formatTitle = primary => {
  if (primary?.sectionTitle?.length === 0) {
    return ''
  }

  return primary.sectionTitle.map(slice => getTextSlices(slice))
}

// Will return consecutive elements grouped together
const groupLists = array =>
  array.reduce((groupedListItemArray, isElementListItem, currentIndex, isListItemArray) => {
    if (isElementListItem) {
      if (isListItemArray[currentIndex - 1] === false) {
        groupedListItemArray.push([])
      }
      groupedListItemArray[groupedListItemArray.length - 1].push(isElementListItem)
    }
    return groupedListItemArray
  }, [])

// Returns the formatted lists
const getGroupedListItems = ({ indexesArray, array, indexes }) =>
  indexesArray.map((group, index) => ({
    type: TEXT_TYPE.LIST,
    index: indexes[index],
    listItems: group.map(indexValue => array[indexValue]),
  }))

const getContentToRender = ({ content, indexes, lists }) => {
  // Will return the final array with the content, with the group elements merged together
  const array = content.reduce((total, currentValue, currentIndex) => {
    if (indexes.includes(currentIndex)) {
      const currentList = lists.find(list => list.index === currentIndex)
      total.push(currentList)
    } else if (currentValue.type !== TEXT_TYPE.LIST_ITEM) {
      total.push(currentValue)
    }
    return total
  }, [])
  return array
}

const formatTextSlice = slice => {
  if (slice.type === TEXT_TYPE.LIST) {
    return [[{ ...slice, listItems: slice.listItems.map(item => getTextSlices(item)) }]]
  }
  return getTextSlices(slice)
}

const getFormattedContent = content => {
  if (content.length === 0 || (content.length === 1 && !content?.[0]?.text)) {
    return []
  }

  const listItems = content?.map((item, index) => item.type === TEXT_TYPE.LIST_ITEM && index)

  let contentToRender = content
  if (listItems.length) {
    const groupedLists = groupLists(listItems)
    const insertIndexes = groupedLists.map(item => item[0])
    const groupedListItems = getGroupedListItems({
      indexesArray: groupedLists,
      array: content,
      indexes: insertIndexes,
    })
    const finalContent = getContentToRender({
      content,
      indexes: insertIndexes,
      lists: groupedListItems,
    })

    contentToRender = finalContent
  }

  return contentToRender.map(text => formatTextSlice(text))
}

const courseModel = item => {
  let course = {
    courseTitle: item.courseTitle[0]?.text,
    courseLevel: item.courseLevel[0]?.text,
    courseDescription: item.courseDescription[0]?.text,
    coursePicture: {
      url: item.coursePicture.url,
      alt: item.coursePicture.alt,
    },
    courseUrl: item.courseUrl?.[0]?.text,
    featuredCourse: item.featuredCourse,
    isFree: item.isFree,
  }

  if (item.courseAdditionalInfo) {
    course = {
      ...course,
      courseAdditionalInfo:
        item.courseAdditionalInfo?.length && getFormattedContent(item.courseAdditionalInfo),
    }
  }

  if (item.actionButtonScrollToSlice) {
    course = {
      ...course,
      actionButtonScrollToSlice: item.actionButtonScrollToSlice,
    }
  }

  return course
}

const memberModel = item => ({
  name: item.memberName?.[0]?.text || '',
  position: item.memberPosition?.[0]?.text || '',
  picture: {
    url: item.memberPicture?.url || '',
    alt: item.memberPicture?.alt || '',
  },
  pictureMobile: {
    url: item.memberPictureMobile?.url || '',
    alt: item.memberPictureMobile?.alt || '',
  },
  pictureModal: {
    url: item.memberPictureModal?.url || '',
    alt: item.memberPictureModal?.alt || '',
  },
  information: getFormattedContent(item.memberInformation),
})

const getSectionSlices = (slice, sections) => {
  switch (slice.sliceType) {
    case TYPES.COURSES:
      return {
        ...sections,
        isVerticalList: slice.primary?.isVerticalList,
        items: slice.items.map(item => courseModel(item)),
      }
    case TYPES.TESTIMONIAL:
    case TYPES.COMPANIES:
      return {
        ...sections,
        items: slice.items.map(item => testimonialsModel(item)),
      }
    case TYPES.ITEMS_LIST:
    case TYPES.MINI_COURSE_SECTION:
      return {
        ...sections,
        additionalContent: slice.primary?.sectionAdditionalContent
          ? getFormattedContent(slice.primary?.sectionAdditionalContent)
          : [],
        hasSmallerItems: slice.primary?.smallerItems,
        items: slice.items.map(item => ({
          itemIcon: {
            url: item.itemIcon.url,
            alt: item.itemIcon.alt,
          },
          itemDescription: item.itemDescription[0]?.text,
          itemDescriptionBold: item.itemDescription[0]?.spans[0]?.type === 'strong',
        })),
        backgroundColor: slice.primary?.backgroundColor?.[0]?.text,
      }
    case TYPES.COMPANIES_CAROUSEL:
      return {
        ...sections,
        items: slice.items.map(item => ({
          logo: item.companyLogo?.url || '',
          name: item.companyName?.[0]?.text || '',
        })),
      }
    case TYPES.HERO_SECTION:
      return {
        ...sections,
        items: slice.items.map(
          item => (item.textCarousel?.length && item.textCarousel[0].text) || ''
        ),
      }
    case TYPES.PERSONAL_DEVELOPMENT_COURSES:
      return {
        ...sections,
        sectionText: slice.items.map(item => ({
          text: item.text?.[0]?.text,
          isBold: item?.isBold,
        })),
      }
    case TYPES.QUESTIONS_SECTION: {
      return {
        ...sections,
        items: slice.items.map(item => ({
          question: item.question?.[0]?.text || '',
          answer: getFormattedContent(item.answer) || '',
        })),
      }
    }
    case TYPES.COURSE_SESSIONS_SECTION:
      return {
        ...sections,
        items: slice.items.map(item => ({
          title: item.sessionTitle?.[0]?.text || '',
          description: item.sessionDescription?.[0]?.text || '',
          numberOfAssignments: item.numberOfAssignments || 0,
          sessionNumber: item.sessionNumber?.[0]?.text || '',
        })),
      }
    case TYPES.SUBSCRIPTION_TYPE_SECTION:
      return {
        ...sections,
        types: slice.items.map(item => ({
          classType: item.classType?.[0]?.text || '',
          description: getFormattedContent(item.typeDescription),
        })),
      }

    case TYPES.MULTIPLE_QUOTES_SECTION:
      return {
        ...sections,
        items: slice.items.map(quotesModel),
      }
    case TYPES.LIGHTYEARS_PICK: {
      const { primary } = slice

      return {
        ...sections,
        course: courseModel(primary),
      }
    }
    case TYPES.THE_LIGHTYEAR_TEAM:
      return {
        ...sections,
        members: slice.items.length ? slice.items.map(member => memberModel(member)) : [],
      }
    case TYPES.ABOUT_SUSANNE: {
      const member = {
        memberName: slice.primary?.memberName,
        memberPosition: slice.primary?.memberPosition,
        memberInformation: slice.primary?.memberInformation,
        memberPictureModal: slice.primary?.memberPictureModal,
      }
      return {
        ...sections,
        member: memberModel(member),
      }
    }
    default:
      return sections
  }
}

export const formatSlices = data =>
  data.map(slice => {
    let sections = {
      sliceType: slice.sliceType,
    }

    if (slice.primary?.sectionTitle) {
      sections = {
        ...sections,
        sectionTitle: formatTitle(slice.primary),
      }
    }

    if (slice.primary?.sectionText?.[0]?.text) {
      sections = {
        ...sections,
        sectionText: slice.primary.sectionText[0].text || '',
      }
    }

    if (slice.primary?.sectionDescription) {
      sections = { ...sections, sectionDescription: slice.primary.sectionDescription[0]?.text }
    }

    if (slice.primary?.backgroundImage) {
      sections = {
        ...sections,
        backgroundImage: {
          thumbnail: slice.primary.backgroundImage.url,
          alt: slice.primary.backgroundImage.alt,
        },
      }
    }

    if (slice.primary?.contentImage) {
      sections = {
        ...sections,
        contentImage: {
          url: slice.primary?.contentImage.url,
          alt: slice.primary?.contentImage.alt,
        },
      }
    }

    if (slice.primary?.contentImageTablet) {
      sections = {
        ...sections,
        contentImageTablet: {
          url: slice.primary?.contentImageTablet.url,
          alt: slice.primary?.contentImageTablet.alt,
        },
      }
    }

    if (slice.primary?.contentImageMobile) {
      sections = {
        ...sections,
        contentImageMobile: {
          url: slice.primary?.contentImageMobile.url,
          alt: slice.primary?.contentImageMobile.alt,
        },
      }
    }

    if (slice.primary?.actionButtonLink) {
      sections = {
        ...sections,
        actionButton: {
          url:
            slice.primary?.actionButtonLink?.url ||
            slice.primary?.actionButtonLink?.[0]?.text ||
            '',
          label: slice.primary?.actionButtonLabel?.[0]?.text || '',
        },
      }
    }

    if (slice.primary?.actionButtonScrollToSlice) {
      sections = {
        ...sections,
        actionButtonScrollToSlice: {
          slice: slice.primary.actionButtonScrollToSlice,
          label: slice.primary?.actionButtonLabel?.[0]?.text || '',
        },
      }
    }

    if (slice.primary?.textColor) {
      sections = {
        ...sections,
        textColor: slice.primary.textColor,
      }
    }

    if (slice.primary?.backgroundColor) {
      sections = {
        ...sections,
        backgroundColor:
          slice.primary?.backgroundColor[0]?.text || slice.primary?.backgroundColor || '',
      }
    }

    if (slice.primary?.titleColor) {
      sections = {
        ...sections,
        titleColor: slice.primary.titleColor,
      }
    }

    if (slice.primary?.videoUrl) {
      sections = {
        ...sections,
        videoUrl: slice.primary.videoUrl?.url,
      }
    }

    if (slice.primary?.sectionContent) {
      sections = {
        ...sections,
        content: getFormattedContent(slice.primary.sectionContent),
      }
    }

    if (slice.primary?.isList) {
      sections = {
        ...sections,
        isList: slice.primary.isList,
      }
    }

    if (slice.primary?.isTitleAlignedWithText) {
      sections = {
        ...sections,
        isTitleAlignedWithText: slice.primary.isTitleAlignedWithText,
      }
    }

    if (slice.items) {
      sections = getSectionSlices(slice, sections)
    }

    if (slice.sliceType === TYPES.QUOTE_SECTION) {
      sections = {
        ...sections,
        isUserHighlighted: slice.primary?.isQuoteAuthorHighlighted,
        userName: slice.primary?.userName?.[0]?.text || '',
        userJob: slice.primary?.userJob?.[0]?.text || '',
        userPicture: {
          avatar: slice.primary?.userPictureMiniature?.url || '',
          thumbnail: slice.primary?.userPicture?.url || '',
          thumbnailTablet: slice.primary?.userPictureTablet?.url || '',
          alt:
            slice.primary?.userPicture?.alt ||
            slice.primary?.userPictureTablet?.alt ||
            slice.primary?.userPictureMiniature?.alt ||
            'User Picture',
        },
        quote:
          slice.primary?.userQuote?.length > 0 &&
          slice.primary.userQuote.map(quoteSlice => quoteSlice.text),
      }
    }

    return sections
  })

const formatCardType = value => value?.toLowerCase().replace(/ /g, '_')

const formatSEO = array => {
  const generalCard = array.find(
    item =>
      item.sliceType === SEO_TYPES.GENERAL_SOCIAL_CARD || item.sliceType === SEO_TYPES.GENERAL_CARD
  )?.primary
  const twitterCard = array.find(item => item.sliceType === SEO_TYPES.TWITTER_CARD)?.primary

  const seo = {
    title: generalCard?.title?.[0]?.text || '',
    description: generalCard?.description?.[0]?.text || '',
    image: generalCard?.image?.url || '',
    contentType: generalCard?.contentType?.[0]?.text || '',
    keywords: generalCard?.keywords?.[0]?.text || '',
    twitterTitle: twitterCard?.title?.[0]?.text || '',
    twitterDescription: twitterCard?.description?.[0]?.text || '',
    twitterImage: twitterCard?.image?.url || '',
    cardType: formatCardType(twitterCard?.cardType),
  }

  return seo
}
export const getFormattedLanding = content => {
  let landing = {}

  if (!content) {
    return landing
  }

  if (content?.title?.length !== 0) {
    landing = {
      ...landing,
      title: content?.title?.[0]?.text,
    }
  }

  if (content?.courseSlug?.length !== 0) {
    // TODO: Might need to remove course slug once modular classes go to prod
    landing = {
      ...landing,
      courseSlug: content?.courseSlug?.[0]?.text,
    }
  }
  if (content?.category?.length !== 0) {
    landing = {
      ...landing,
      category: content.category?.[0]?.text,
    }
  }

  if (content?.headerLinks) {
    landing = {
      ...landing,
      header: {
        links: content.headerLinks.map(({ linkUrl, linkLabel }) => ({
          label: linkLabel?.[0]?.text,
          url: linkUrl?.[0]?.text,
        })),
      },
    }
  }

  if (content?.headerStagingLinks) {
    landing = {
      ...landing,
      headerStaging: {
        links: content.headerStagingLinks.map(({ linkUrl, linkLabel }) => ({
          label: linkLabel?.[0]?.text,
          url: linkUrl?.[0]?.text,
        })),
      },
    }
  }

  if (content?.body) {
    const formattedSlices = formatSlices(content.body)
    landing = {
      ...landing,
      slices: formattedSlices,
    }
  }

  if (content?.footerLinks) {
    landing = {
      ...landing,
      footer: {
        links: content.footerLinks.map(({ linkUrl, linkLabel }) => ({
          label: linkLabel?.[0]?.text,
          url: linkUrl?.[0]?.text,
        })),
      },
    }
  }

  if (content?.footerLinksStaging) {
    landing = {
      ...landing,
      footerLinksStaging: {
        links: content.footerLinksStaging.map(({ linkUrl, linkLabel }) => ({
          label: linkLabel?.[0]?.text,
          url: linkUrl?.[0]?.text,
        })),
      },
    }
  }

  if (content?.highlightMessage) {
    landing = {
      ...landing,
      highlights: {
        highlightTitle: content?.highlightMessage[0]?.highlightTitle[0]?.text,
        highlightText: content?.highlightMessage[0]?.highlightText[0]?.text,
        highlightLink: content?.highlightMessage[0]?.highlightLink[0]?.text,
        highlightWarning: content?.highlightMessage[0]?.highlightWarning[0]?.text,
        highlightBackground: content?.highlightMessage[0]?.highlightBackground[0]?.text,
        highlightButtonText: content?.highlightMessage[0]?.highlightButtonText[0]?.text,
        isAlertDismissed: false,
      },
    }
  }

  if (content?.facebookUrl) {
    landing = {
      ...landing,
      footerLinksStaging: {
        ...landing.footerLinksStaging,
        facebookUrl: content.facebookUrl?.url,
      },
      footer: {
        ...landing.footer,
        facebookUrl: content.facebookUrl?.url,
      },
    }
  }

  if (content?.instagramUrl) {
    landing = {
      ...landing,
      footerLinksStaging: {
        ...landing.footerLinksStaging,
        instagramUrl: content.instagramUrl?.url,
      },
      footer: {
        ...landing.footerLinksStaging,
        instagramUrl: content.instagramUrl?.url,
      },
    }
  }

  if (content?.twitterUrl) {
    landing = {
      ...landing,
      footerLinksStaging: {
        ...landing.footer,
        twitterUrl: content.twitterUrl?.url,
      },
      footer: {
        ...landing.footer,
        twitterUrl: content.twitterUrl?.url,
      },
    }
  }

  if (content?.body1) {
    const SEO = formatSEO(content.body1)
    landing = {
      ...landing,
      seo: SEO,
    }
  }
  return landing
}
