import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { navigate } from '@reach/router'

import Button, { ButtonTheme } from '_components/button'
import { courseShape } from '_utils/proptypes'

import styles from './styles.css'

const CourseCardFooter = ({ isOnboarding, className, course, target, isCompany }) => {
  const onButtonClick = useCallback(
    () => {
      navigate(target)
    },
    [target]
  )

  const renderPrice = useCallback(
    () => {
      if (isCompany) {
        return ''
      }

      return `Available for $${course.price}`
    },
    [course.price, isCompany]
  )

  return (
    <div className={classnames(styles.container, { [styles.onboarding]: isOnboarding }, className)}>
      <div className={styles.details}>
        {/* HIDDEN FOR NOW (waiting for client decision) */}
        {/* {!isDiscoverCard && <p>{course.duration} HOURS</p>} */}
        <p className={styles.price}>{renderPrice()}</p>
      </div>
      <Button
        onClick={onButtonClick}
        theme={ButtonTheme.PRIMARY}
        label={course.enrolled ? 'ENROLLED' : 'LEARN MORE'}
        className={styles.action}
      />
    </div>
  )
}

CourseCardFooter.propTypes = {
  isOnboarding: PropTypes.bool,
  target: PropTypes.string,
  className: PropTypes.string,
  course: courseShape.isRequired,
  isCompany: PropTypes.bool.isRequired,
}

CourseCardFooter.defaultProps = {
  isOnboarding: false,
  target: '',
  className: '',
}

export default React.memo(CourseCardFooter)
