import React, { useMemo } from 'react'
import classnames from 'classnames'
import { Link } from '@reach/router'
import PropTypes from 'prop-types'

import Placeholder1 from '_assets/images/placeholder-1.png'
import UserPlaceholderIcon from '_assets/icons/user-placeholder.svg'
import ArrowIcon from '_assets/icons/arrow-right-24-px.svg'
import Check from '_assets/icons/check.svg'
import DonutChart from '_components/donut-chart'
import { ASSIGNMENT_TYPE } from '_constants/assignment'
import { FLAG_MULTIPLE_COACHES } from '_config/environment'
import Svg from '_components/svg'
import { classShape } from '_utils/proptypes'

import styles from './styles.css'

const ClassCardWithNextAssignment = ({ className, course }) => {
  const instructors = useMemo(() => course?.instructors, [course])
  const nextAssignment = useMemo(() => course?.nextAssignment?.assignment, [course])
  const isCompleted = useMemo(() => course?.completed, [course])
  const classUrl = useMemo(() => `/class/${course?.id}`, [course])

  const nextAssignmentProps = useMemo(
    () => {
      if (nextAssignment?.type === ASSIGNMENT_TYPE.ASSIGNMENT) {
        return { to: `/class/${course.id}/assignment/${course.nextAssignment?.id}` }
      }

      return {
        to: `/class/${course.id}`,
        state: { scrollTo: course.nextAssignment?.id },
      }
    },
    [course, nextAssignment]
  )

  const slicedInstructors = useMemo(
    () => {
      if (!instructors.length) {
        return null
      }

      return instructors
        ?.slice(0, 3)
        ?.map(
          instructor =>
            instructor.photo ? (
              <img
                key={`instructor-${instructor.id}`}
                alt="Coach Avatar"
                src={instructor.photo}
                className={styles['class-coach-avatar']}
              />
            ) : (
              <Svg
                icon={UserPlaceholderIcon}
                key={`instructor-${instructor.id}`}
                className={classnames(
                  styles['class-coach-avatar'],
                  styles['class-coach-avatar-placeholder']
                )}
              />
            )
        )
    },
    [instructors]
  )

  return (
    <article
      className={classnames(styles['class-wrapper'], className, {
        [styles['has-completed']]: isCompleted,
      })}
    >
      <div className={styles['content-wrapper']}>
        <h1 className={classnames(styles.text, styles['class-category'])}>{course.category}</h1>
        <h2 className={classnames(styles.text, styles['class-name'])}>{course.name}</h2>
        {FLAG_MULTIPLE_COACHES && instructors.length ? (
          <p className={styles['class-coache-wrapper']}>
            {slicedInstructors}
            <span className={classnames(styles.text, styles['class-coach-text'])}>
              with <strong>{instructors[0]?.name}</strong>{' '}
              {instructors?.length > 1 && `and ${instructors?.length - 1} more`}
            </span>
          </p>
        ) : null}
        {!isCompleted && (
          <div className={styles['class-percentage-wrapper']}>
            <p className={classnames(styles.text, styles['class-percentage'])}>
              <DonutChart
                className={styles.chart}
                trackClassName={styles['chart-track']}
                value={course.completePercentage}
                size={12}
                strokeWidth={2}
                showValue={false}
                keepSize
              />
              {course.completePercentage}% complete
            </p>
          </div>
        )}

        <Link to={classUrl} className={styles.link} />
      </div>
      <Link to={classUrl} className={styles['image-container']} aria-label="Open assignment">
        <img
          alt="Course Thumbnail"
          src={course.coverPhoto || Placeholder1}
          className={styles.image}
        />
      </Link>
      <Link {...nextAssignmentProps} className={styles['next-assignment-wrapper']}>
        {isCompleted ? (
          <>
            <svg className={styles['check-icon']} viewBox={Check.viewBox} aria-hidden="true">
              <use xlinkHref={`#${Check.id}`} />
            </svg>
            <span className={styles['completed-title']}>Completed</span>
          </>
        ) : (
          <>
            <span className={styles.next}>Next Assignment</span>
            <span className={styles.assignment}>{course?.nextAssignment?.assignment?.name}</span>
            <svg className={styles['arrow-icon']} viewBox={ArrowIcon.viewBox} aria-hidden="true">
              <use xlinkHref={`#${ArrowIcon.id}`} />
            </svg>
          </>
        )}
      </Link>
    </article>
  )
}

ClassCardWithNextAssignment.propTypes = {
  className: PropTypes.string,
  course: classShape.isRequired,
}

ClassCardWithNextAssignment.defaultProps = {
  className: '',
}

export default React.memo(ClassCardWithNextAssignment)
