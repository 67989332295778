import * as courseService from '_services/course'
import { defineAction } from '_utils/redux'

export const GET_COURSES = defineAction('GET_COURSES')
export const GET_COMPANY_COURSES = defineAction('GET_COMPANY_COURSES')
export const GET_PUBLIC_COURSES = defineAction('GET_PUBLIC_COURSES')
export const GET_COURSE = defineAction('GET_COURSE')
export const GET_CATEGORIES = defineAction('GET_CATEGORIES')
export const FAVORITE_COURSE = defineAction('FAVORITE_COURSE')
export const UNFAVORITE_COURSE = defineAction('UNFAVORITE_COURSE')
export const GET_COMPANY_CLASSES = defineAction('GET_COMPANY_CLASSES')
export const GET_COMPANY_CLASS_BY_ID = defineAction('GET_COMPANY_CLASS_BY_ID')

export const getCourses = payload => (dispatch, getState) => {
  dispatch({
    type: GET_COURSES.ACTION,
    payload: courseService.getCourses(getState().user.get('authToken'))(payload),
  })
}

export const getCompanyCourses = (isOnboarding = false) => (dispatch, getState) => {
  dispatch({
    type: GET_COMPANY_COURSES.ACTION,
    payload: courseService.getCourses(getState().user.get('authToken'))({
      company: true,
      onboarding: isOnboarding,
    }),
  })
}

export const getPublicCourses = (payload = {}, isOnboarding = false) => (dispatch, getState) => {
  dispatch({
    type: GET_PUBLIC_COURSES.ACTION,
    payload: courseService.getCourses(getState().user.get('authToken'))({
      ...payload,
      company: false,
      onboarding: isOnboarding,
    }),
  })
}

export const getCourse = (slug, params = null) => (dispatch, getState) => {
  dispatch({
    type: GET_COURSE.ACTION,
    payload: courseService.getCourse(getState().user.get('authToken'))(slug, params),
    meta: {
      belongsToACompany: getState().user.belongsToACompany,
    },
  })
}

export const favorite = (slug, courseId, isCompany = false) => (dispatch, getState) => {
  dispatch({
    type: FAVORITE_COURSE.ACTION,
    payload: courseService.favorite(getState().user.get('authToken'))(slug),
    meta: {
      courseId,
      isCompany,
    },
  })
}

export const unfavorite = (slug, courseId, isCompany = false) => (dispatch, getState) => {
  dispatch({
    type: UNFAVORITE_COURSE.ACTION,
    payload: courseService.unfavorite(getState().user.get('authToken'))(slug),
    meta: {
      courseId,
      isCompany,
    },
  })
}

export const getCategories = () => (dispatch, getState) => {
  dispatch({
    type: GET_CATEGORIES.ACTION,
    payload: courseService.getCategories(getState().user.get('authToken')),
  })
}

export const getCompanyClasses = () => (dispatch, getState) =>
  dispatch({
    type: GET_COMPANY_CLASSES.ACTION,
    payload: courseService.getCompanyClasses(getState().user.get('authToken')),
  })

export const getCompanyClassById = classId => (dispatch, getState) =>
  dispatch({
    type: GET_COMPANY_CLASS_BY_ID.ACTION,
    payload: courseService.getCompanyClassById(getState().user.get('authToken'), classId),
  })
