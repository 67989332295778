import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { useWindowSize } from '@reach/window-size'

import CoachDetails, { COACH_CONTENT_DIRECTION } from '_views/class-details/coach-details'
import Svg from '_components/svg'
import { CLASS_TYPE } from '_constants/class'
import { MOBILE_THRESHOLD } from '_config/media-queries'
import { FLAG_MULTIPLE_COACHES } from '_config/environment'

import styles from './styles.css'

export const AVATAR_SIZE = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
}

const ClassIconsTypeChip = ({ currentClass, className }) => {
  const { width } = useWindowSize()
  const isMobileView = width <= MOBILE_THRESHOLD

  const parseInstructor = useMemo(
    () => {
      if (!FLAG_MULTIPLE_COACHES && currentClass?.instructor) {
        return [currentClass?.instructor]
      }
      if (currentClass.instructors) {
        return currentClass.instructors
      }

      return []
    },
    [currentClass]
  )

  if (parseInstructor.size > 0) {
    return (
      <CoachDetails
        coachList={parseInstructor}
        className={className}
        subContent={currentClass.name}
        contentDirection={
          isMobileView ? COACH_CONTENT_DIRECTION.COLUMN : COACH_CONTENT_DIRECTION.ROW
        }
      />
    )
  }

  return (
    <div className={styles['chip-wrapper']}>
      <div className={classnames(styles['class-icons-container'], className)}>
        <Svg icon={CLASS_TYPE[(currentClass?.type)]?.icon} className={styles.icon} />
      </div>
      <p className={styles['class-type']}>{CLASS_TYPE[(currentClass?.type)]?.name}</p>
      <p className={styles['class-title']}>{currentClass?.name}</p>
    </div>
  )
}

ClassIconsTypeChip.propTypes = {
  currentClass: PropTypes.shape({
    name: PropTypes.string,
    type: PropTypes.string,
    tag: PropTypes.string,
    instructors: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        id: PropTypes.number,
        occupation: PropTypes.string,
        avatar: PropTypes.string,
      })
    ),
    instructor: PropTypes.shape({
      name: PropTypes.string,
      id: PropTypes.number,
      occupation: PropTypes.string,
      avatar: PropTypes.string,
    }),
  }).isRequired,
  className: PropTypes.string,
}

ClassIconsTypeChip.defaultProps = {
  className: '',
}

export default React.memo(ClassIconsTypeChip)
