export const INITIAL_STATE = {
  firstName: '',
  lastName: '',
  email: '',
  business: '',
  message: '',
  errors: {
    firstName: '',
    lastName: '',
    email: '',
    business: '',
    message: '',
  },
}

export const INPUT_CHANGE = 'update-field'
export const CLEAR_STATE = 'clear-state'

export default (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE: {
      return {
        ...state,
        [action.name]: action.value,
        errors: {
          ...state.errors,
          [action.name]: action.error,
        },
      }
    }
    case CLEAR_STATE: {
      return INITIAL_STATE
    }
    default:
      return state
  }
}
