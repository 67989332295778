import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Confetti from 'react-dom-confetti'

import Button, { ButtonTheme } from '_components/button'
import CloseIcon from '_assets/icons/close.svg'
import Card, { CardSize } from '_components/card'
import { CONFETTI_CONFIG } from '_constants/config'
import Modal from '_components/modal'

import styles from './styles.css'

const redirectState = {
  completedCoursesTab: true,
}

const CompletedCourseModal = ({ courseName, onClose }) => {
  const [isCelebrating, setCelebrating] = useState(false)

  useEffect(() => {
    setTimeout(() => {
      setCelebrating(true)
    }, 100)
  }, [])

  return (
    <Modal isOpen onClose={onClose}>
      <Card size={CardSize.LARGE} className={styles.container}>
        <div className={styles.header}>
          <h1 className={styles.title}>You did it!!</h1>
          <Button
            className={styles.close}
            iconOnly
            icon={CloseIcon}
            onClick={onClose}
            label="Close Modal"
          />
        </div>
        <p className={styles.content}>
          You are officially a graduate of {courseName}! Good job! We know what it takes up to show
          up and do the work, so give yourself a pat on the back for all you have accomplished.
          Remember you can always come back and review your course if you need a little refresher or
          reset {'\u2728'}
        </p>
        <div className={styles.submit}>
          <Button
            theme={ButtonTheme.PRIMARY}
            to="/courses"
            state={redirectState}
            label="View all my classes"
          />
        </div>
        <div className={styles.confetti}>
          <Confetti active={isCelebrating} config={CONFETTI_CONFIG} />
        </div>
      </Card>
    </Modal>
  )
}

CompletedCourseModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  courseName: PropTypes.string.isRequired,
}

export default React.memo(CompletedCourseModal)
