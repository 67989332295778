import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Slider from 'react-slick'
import classnames from 'classnames'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import { MOBILE_WIDTH, MEDIUM_WIDTH } from '_utils/constants'
import CompanyCard from '_components/landing-components/company-card'
import SectionTitle from '_components/landing-components/section-title'
import useWindowSize from '_hooks/use-window-size'
import { prismicTitlePropType } from '_utils/proptypes'

import styles from './styles.css'

const DESKTOP_PADDING = 48
const TABLET_PADDING = 80
const MOBILE_PADDING = 150
const MAX_SIZE = 1200
const CARD_SIZE = 252
const MINIMUM_SLIDE = 0.93
const CompaniesCarouselSection = ({ slice, id }) => {
  const { width } = useWindowSize()
  const carouselWidth = useMemo(
    () => {
      if (width >= MAX_SIZE) {
        return MAX_SIZE
      }

      if (width < MOBILE_WIDTH) {
        return width - MOBILE_PADDING
      }

      if (width <= MEDIUM_WIDTH && width > MOBILE_WIDTH) {
        return width - TABLET_PADDING
      }

      return width - DESKTOP_PADDING
    },
    [width]
  )

  const slidesToShow = useMemo(
    () => (carouselWidth / CARD_SIZE > 1 ? carouselWidth / CARD_SIZE : MINIMUM_SLIDE),
    [carouselWidth]
  )

  const settings = useMemo(
    () => ({
      className: styles.carousel,
      dots: false,
      infinite: true,
      initialSlide: 0,
      arrows: false,
      slidesToShow,
      slidesToScroll: 1,
      draggable: false,
      autoplay: true,
      speed: 2000,
      autoplaySpeed: 3000,
      responsive: [
        {
          breakpoint: MOBILE_WIDTH,
          settings: {
            centerMode: true,
          },
        },
        {
          breakpoint: 375,
          settings: {
            centerMode: false,
          },
        },
        {
          breakpoint: 10000,
          settings: {
            centerMode: false,
          },
        },
      ],
    }),
    [slidesToShow]
  )

  const carouselLength = useMemo(() => slice.items?.length, [slice.items])

  return (
    <section className={styles.section} id={id}>
      <div className={styles.content}>
        {slice.sectionTitle && <SectionTitle title={slice.sectionTitle} className={styles.title} />}
        {carouselLength < slidesToShow &&
          carouselLength !== 0 && (
            <div className={styles['no-carousel']}>
              {slice.items.map(company => (
                <div key={company.name} className={styles.card}>
                  <CompanyCard company={company} />
                </div>
              ))}
            </div>
          )}
        {carouselLength >= slidesToShow && (
          <div className={styles['carousel-container']}>
            <div className={classnames(styles.overlay, styles['overlay-left'])} />
            <Slider {...settings}>
              {slice.items.map(company => (
                <div key={company.name} className={styles.card}>
                  <CompanyCard company={company} />
                </div>
              ))}
            </Slider>
            <div className={classnames(styles.overlay, styles['overlay-right'])} />
          </div>
        )}
      </div>
    </section>
  )
}

CompaniesCarouselSection.propTypes = {
  slice: PropTypes.shape({
    sectionTitle: prismicTitlePropType,
    items: PropTypes.arrayOf(
      PropTypes.shape({
        name: PropTypes.string,
        logo: PropTypes.string,
      })
    ),
  }).isRequired,
  id: PropTypes.string.isRequired,
}

export default React.memo(CompaniesCarouselSection)
