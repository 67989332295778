import React, { useReducer, useCallback } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import {
  injectStripe,
  CardNumberElement,
  CardExpiryElement,
  CardCVCElement,
} from 'react-stripe-elements'

import Card, { CardSize } from '_components/card'
import Button, { ButtonTheme } from '_components/button'
import ErrorTooltip from '_components/error-tooltip'
import Input from '_components/input'
import CloseIcon from '_assets/icons/close.svg'
import { StripeOptions } from '_utils/constants'

import styles from './styles.css'

const initialState = {
  name: '',
  cardNumber: '',
  cardExpiry: '',
  cardCvc: '',
  errors: {
    fullName: '',
    cardNumber: '',
    cardExpiry: '',
    cardCvc: '',
  },
}

const INPUT_CHANGE = 'INPUT_CHANGE'
const RESET = 'RESET'

const reducer = (state, action) => {
  switch (action.type) {
    case INPUT_CHANGE: {
      return {
        ...state,
        [action.name]: action.value,
        errors: {
          ...state.errors,
          [action.name]: action.error,
        },
      }
    }
    case RESET: {
      return initialState
    }
    default:
      return state
  }
}

const AddNewCreditCard = ({ onClose, loading, onAddNewCard, stripe }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const onInputChange = useCallback(event => {
    const { name, value } = event.target
    let error = ''

    if (name === 'name') {
      error = /\d/.test(value) ? 'Enter a valid card holder name.' : ''
    }

    dispatch({ type: INPUT_CHANGE, name, value, error })
  }, [])

  const onStripeElementChange = useCallback(event => {
    const { elementType, error, value } = event

    dispatch({
      type: INPUT_CHANGE,
      name: elementType,
      value,
      error: error ? error.message : '',
    })
  }, [])

  const onClick = useCallback(
    event => {
      event.preventDefault()
      const { errors, ...card } = state
      stripe.createToken(card).then(({ token }) => {
        onAddNewCard({ cardToken: token.id })
      })
      dispatch({
        type: RESET,
      })
    },
    [onAddNewCard, state, stripe]
  )

  return (
    <Card size={CardSize.LARGE} className={styles.container}>
      <div className={styles.header}>
        <h2>ADD A NEW CARD</h2>
        <button onClick={onClose}>
          <svg aria-label="Close" role="img" className={styles.close} viewBox={CloseIcon.viewBox}>
            <use xlinkHref={`#${CloseIcon.id}`} />
          </svg>
        </button>
      </div>
      <Input
        errorMessage={state.errors.name}
        disabled={loading}
        label="Full Name"
        name="name"
        onChange={onInputChange}
        value={state.name}
      />
      <div className={styles['form-group']}>
        <span className={styles.label}>Card Number</span>
        <CardNumberElement
          className={classnames(styles['stripe-input'], {
            [styles.error]: state.errors.cardNumber,
          })}
          {...StripeOptions}
          onChange={onStripeElementChange}
          placeholder=""
        />
        {state.errors.cardNumber && (
          <ErrorTooltip className={styles['error-icon']} message={state.errors.cardNumber} />
        )}
      </div>
      <div className={styles['form-group-wrapper']}>
        <div className={styles['form-group']}>
          <span className={styles.label}>Card Expiration</span>
          <CardExpiryElement
            className={classnames(styles['stripe-input'], {
              [styles.error]: state.errors.cardExpiry,
            })}
            {...StripeOptions}
            onChange={onStripeElementChange}
            placeholder=""
          />
          {state.errors.cardExpiry && (
            <ErrorTooltip className={styles['error-icon']} message={state.errors.cardExpiry} />
          )}
        </div>
        <div className={styles['form-group']}>
          <span className={styles.label}>Security Code</span>
          <CardCVCElement
            className={classnames(styles['stripe-input'], {
              [styles.error]: state.errors.cardCvc,
            })}
            {...StripeOptions}
            onChange={onStripeElementChange}
            placeholder=""
          />
          {state.errors.cardCvc && (
            <ErrorTooltip className={styles['error-icon']} message={state.errors.cardCvc} />
          )}
        </div>
      </div>
      <div className={styles.button}>
        <Button
          loading={loading}
          onClick={onClick}
          theme={ButtonTheme.PRIMARY}
          label="Add new card"
          type="submit"
        />
      </div>
    </Card>
  )
}

AddNewCreditCard.propTypes = {
  onClose: PropTypes.func,
  onAddNewCard: PropTypes.func,
  loading: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  stripe: PropTypes.object.isRequired /* stripe injected prop */,
}

AddNewCreditCard.defaultProps = {
  onClose: () => {},
  onAddNewCard: () => {},
  loading: false,
}

export default injectStripe(AddNewCreditCard)
