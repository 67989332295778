import React, { useCallback } from 'react'
import classnames from 'classnames'
import PropTypes from 'prop-types'
import { navigate } from '@reach/router'

import Button, { ButtonTheme } from '_components/button'
import LockerIcon from '_assets/icons/locker.svg'
import HashtagIcon from '_assets/icons/hashtag.svg'
import { searchChannelShape } from '_utils/proptypes'

import styles from './styles.css'

const Channel = ({ className, target, channel }) => {
  const onClick = useCallback(
    () => {
      navigate(target)
    },
    [target]
  )

  return (
    <div className={classnames(styles.container, className)}>
      <div className={styles.information}>
        <p className={styles.name}>
          <svg
            className={styles.icon}
            viewBox={channel.private ? LockerIcon.viewBox : HashtagIcon.viewBox}
            role="img"
            aria-label={`${channel.private ? 'Private' : 'Public'} channel`}
          >
            <use xlinkHref={`#${channel.private ? LockerIcon.id : HashtagIcon.id}`} />
          </svg>
          {channel.name}
        </p>
        {channel.numberOfMembers && <p>{channel.numberOfMembers} participants</p>}
      </div>
      <Button
        onClick={onClick}
        theme={ButtonTheme.PRIMARY}
        label="GO TO CHANNEL"
        className={styles.action}
      />
    </div>
  )
}

Channel.propTypes = {
  className: PropTypes.string,
  target: PropTypes.string.isRequired,
  channel: searchChannelShape.isRequired,
}

Channel.defaultProps = {
  className: '',
}

export default Channel
