import React, { useCallback } from 'react'
import classnames from 'classnames'
import { navigate } from '@reach/router'
import PropTypes from 'prop-types'
import moment from 'moment'
import Shiitake from 'shiitake'

import ArrowIcon from '_assets/icons/arrowdown-16-px.svg'
import Button from '_components/button'
import { userShape } from '_utils/proptypes'

import styles from './styles.css'

const SidebarGoals = ({ className, user }) => {
  const onButtonClick = useCallback(
    () => {
      navigate([`/user/${user.username}/goals`])
    },
    [user]
  )

  return (
    <aside className={classnames(styles['user-goal'], className)}>
      <button className={styles.arrow} type="button">
        <svg role="img" viewBox={ArrowIcon.viewBox} aria-label="hide button">
          <use xlinkHref={`#${ArrowIcon.id}`} />
        </svg>
      </button>
      <div className={styles.goals}>
        <h3>
          {user.name.split(' ')[0]}
          &apos;s upcoming goals
        </h3>
        {user.goals.slice(0, 3).map(goal => (
          <div key={goal.id} className={styles.goal}>
            <Shiitake lines={6} throttleRate={200} className={styles.text} tagName="p">
              {goal.description}
            </Shiitake>
            <p className={styles['due-date']}>by {moment(goal.dueDate).format('MMMM D, YYYY')}</p>
          </div>
        ))}
      </div>
      <Button label="See all goals" onClick={onButtonClick} className={styles.button} />
    </aside>
  )
}

SidebarGoals.propTypes = {
  className: PropTypes.string,
  user: userShape.isRequired,
}

SidebarGoals.defaultProps = {
  className: '',
}

export default React.memo(SidebarGoals)
