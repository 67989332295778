import React, { useCallback, useState, useEffect } from 'react'
import { useStripe, useElements, CardNumberElement } from '@stripe/react-stripe-js'
import { useSelector, useDispatch } from 'react-redux'
import PropTypes from 'prop-types'

import PlusIcon from '_assets/icons/plus-icon.svg'
import PaymentsForm from '_components/payments-form'
import Button from '_components/landing-components/button'
import { cardListSelector } from '_modules/card/selectors'
import PaymentsFormFields from '_components/payments-form/form-fields'
import CreditCard from '_components/checkout-modal/credit-card'
import usePrevious from '_hooks/use-previous'
import {
  isEnrollLoadingSelector,
  enrollErrorSelector,
  isInitialEnrollLoadingSelector,
} from '_modules/class/selectors'
import { enroll, initialEnroll } from '_modules/class/actions'
import { hasCompletedOnboardingSelector } from '_modules/user/selectors'
import ErrorAlertComponent from '_components/error-alert-component'

import styles from './styles.css'

const PaymentForm = ({ classId, discountCode }) => {
  const cardList = useSelector(cardListSelector)
  const isEnrollLoading = useSelector(isEnrollLoadingSelector)
  const isInitialEnrollLoading = useSelector(isInitialEnrollLoadingSelector)
  const isEnrolling = isEnrollLoading || isInitialEnrollLoading
  const wasEnrollLoading = usePrevious(isEnrolling)
  const enrollError = useSelector(enrollErrorSelector)
  const hasUserCompletedOnboarding = useSelector(hasCompletedOnboardingSelector)

  const [isAddCardOpen, setAddCardOpen] = useState(false)
  const [selectedCard, setSelectedCard] = useState('')
  const [hasError, setHasError] = useState(false)
  const [isAddNewCardLoading, setAddNewCardLoading] = useState(false)
  const [cardholderName, setCardholderName] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [addCardError, setAddCardError] = useState('')
  const [isFirstCardFetch, setFirstCardFetch] = useState(true)

  useEffect(
    () => {
      if (isFirstCardFetch && cardList?.[0]) {
        setFirstCardFetch(false)
        setSelectedCard(cardList[0].id)
      }
    },
    [cardList, isFirstCardFetch]
  )

  const dispatch = useDispatch()
  const stripe = useStripe()
  const elements = useElements()

  const onCardholderNameChange = useCallback(name => {
    setCardholderName(name)
  }, [])

  const setError = useCallback(value => {
    setHasError(value)
  }, [])

  const onSelectCard = useCallback(event => {
    const { id } = event.target
    setSelectedCard(id)
  }, [])

  const onToggleAddCard = useCallback(
    () => {
      setAddCardOpen(prevState => !prevState)
      const card = selectedCard ? '' : cardList?.[0]?.id

      setSelectedCard(card)
    },
    [cardList, selectedCard]
  )

  const onEnrollClick = useCallback(
    payload => {
      if (hasUserCompletedOnboarding) {
        dispatch(enroll(classId, payload))
        return
      }

      dispatch(
        initialEnroll({
          classId,
          ...payload,
        })
      )
    },
    [classId, dispatch, hasUserCompletedOnboarding]
  )
  const onSubmitForm = useCallback(
    event => {
      event.preventDefault()

      if (addCardError) {
        setAddCardError('')
      }

      if (selectedCard) {
        const payload = {
          cardToken: selectedCard,
          coupon: discountCode.length ? discountCode : undefined,
        }

        onEnrollClick(payload)
      } else {
        const cardElement = elements.getElement(CardNumberElement)
        setAddNewCardLoading(true)
        setLoading(true)
        stripe
          .createToken(cardElement, { name: cardholderName })
          .then(({ token }) => {
            setAddNewCardLoading(false)
            if (token.id) {
              dispatch(
                onEnrollClick({
                  cardToken: token.id,
                  coupon: discountCode.length ? discountCode : undefined,
                })
              )
            } else {
              setLoading(false)
            }
          })
          .catch(() => {
            setAddNewCardLoading(false)
            setLoading(false)
          })
      }
    },
    [
      addCardError,
      cardholderName,
      discountCode,
      dispatch,
      elements,
      onEnrollClick,
      selectedCard,
      stripe,
    ]
  )

  useEffect(
    () => {
      if (wasEnrollLoading && !isEnrollLoading && !enrollError.size) {
        setLoading(false)
        setAddNewCardLoading(false)
      }
    },
    [enrollError.size, isEnrollLoading, isEnrolling, wasEnrollLoading]
  )

  return cardList.length ? (
    <form className={styles.form} onSubmit={onSubmitForm}>
      <h2 className={styles.title}>Select a credit card</h2>
      {enrollError?.first() && (
        <ErrorAlertComponent
          className={styles['error-alert']}
          errorMessage={enrollError.first()}
          isError
        />
      )}
      <div className={styles.cards}>
        {cardList.map(card => (
          <CreditCard
            key={card.id}
            id={card.id}
            card={card}
            checked={selectedCard === card.id}
            onChange={onSelectCard}
            className={styles.card}
          />
        ))}
        {isAddCardOpen ? (
          <div className={styles['add-new-card']}>
            <div className={styles['subtitle-container']}>
              <h3 className={styles.subtitle}>Add a credit card</h3>
              <button type="button" onClick={onToggleAddCard} className={styles['cancel-button']}>
                Cancel
              </button>
            </div>
            <PaymentsFormFields
              onNameChange={onCardholderNameChange}
              className={styles['payment-form']}
              setError={setError}
            />
          </div>
        ) : (
          <button
            type="button"
            onClick={onToggleAddCard}
            className={styles['add-credit-card-button']}
          >
            <svg className={styles['plus-icon']} viewBox={PlusIcon.viewBox} aria-hidden="true">
              <use xlinkHref={`#${PlusIcon.id}`} />
            </svg>
            ADD A CREDIT CARD
          </button>
        )}
        {addCardError && <p className={styles['card-error']}>{addCardError}</p>}
        <Button
          className={styles['enroll-button']}
          type="submit"
          disabled={hasError || (isAddCardOpen && !cardholderName)}
          isLoading={isEnrolling || isAddNewCardLoading || isLoading}
        >
          ENROLL NOW
        </Button>
      </div>
    </form>
  ) : (
    <div className={styles.form}>
      <h2 className={styles.title}>Enter a credit card</h2>
      <PaymentsForm isEnrolling={isEnrolling} classId={classId} discountCode={discountCode} />
    </div>
  )
}

PaymentForm.propTypes = {
  classId: PropTypes.number.isRequired,
  discountCode: PropTypes.string,
}

PaymentForm.defaultProps = {
  discountCode: '',
}

export default React.memo(PaymentForm)
