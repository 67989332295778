import React from 'react'
import PropTypes from 'prop-types'
import ImmutablePropTypes from 'react-immutable-proptypes'
import { Map } from 'immutable'
import classnames from 'classnames'

import Card from '_components/card'
import PostCard from '_components/post-card'
import { postShape, userShape } from '_utils/proptypes'
import Button, { ButtonTheme } from '_components/button'

import styles from './styles.css'

const PostSection = ({
  className,
  posts,
  user,
  myProfile,
  onSubmit,
  onPostEdit,
  onPostDelete,
  isPosting,
  postingError,
  hasMore,
  onLoadMore,
  shazam,
  unshazam,
}) => (
  <section className={classnames(styles.container, className)}>
    <h2 className={styles.title}>Posts</h2>
    {posts.length !== 0 ? (
      <>
        {posts.map(post => (
          <PostCard
            key={post.id}
            id={post.id}
            post={post}
            user={user}
            isOwnPost={myProfile}
            onSubmit={onSubmit}
            onEditPost={onPostEdit}
            onDeletePost={onPostDelete}
            isPosting={isPosting}
            postingError={postingError}
            shazam={shazam}
            unshazam={unshazam}
          />
        ))}
        {hasMore && (
          <Button theme={ButtonTheme.PRIMARY} label="show older posts" onClick={onLoadMore} />
        )}
      </>
    ) : (
      <Card>
        <div className={styles['empty-post']}>
          <p className={styles['main-text']}>
            {myProfile
              ? 'You have not posted anything yet'
              : 'This person has not posted anything yet'}
          </p>
          <p>
            {myProfile ? 'Try making a post from the home page' : 'Please come back another time'}
          </p>
        </div>
      </Card>
    )}
  </section>
)

PostSection.propTypes = {
  className: PropTypes.string,
  myProfile: PropTypes.bool,
  posts: PropTypes.arrayOf(postShape),
  user: userShape.isRequired,
  onSubmit: PropTypes.func,
  onPostEdit: PropTypes.func,
  onPostDelete: PropTypes.func,
  isPosting: PropTypes.bool.isRequired,
  postingError: ImmutablePropTypes.map,
  hasMore: PropTypes.bool,
  onLoadMore: PropTypes.func,
  shazam: PropTypes.func,
  unshazam: PropTypes.func,
}

PostSection.defaultProps = {
  className: '',
  myProfile: false,
  posts: [],
  onSubmit: () => {},
  onPostEdit: () => {},
  onPostDelete: () => {},
  postingError: Map(),
  hasMore: false,
  onLoadMore: () => {},
  shazam: () => {},
  unshazam: () => {},
}

export default React.memo(PostSection)
